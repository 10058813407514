/*=====================================
    MemberCardOrderUnpayDetail

    Author: Gray
    CreateTime: 2018 / 03 / 19
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, {
    useState,
    useEffect,
} from "react";
import actionCreators from 'actions/creators';
import styled from 'styled-components';
import GLOBAL from 'nonnmwebglobal';
import { GlobalStyle, Colors, Button } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import TimeFormat from "components/commons/TimeFormat";
import CupoyImage from "components/commons/CupoyImage";
import { useParams } from 'react-router';
import useRedux from 'actions/hooks/useRedux';
import { createSelector } from 'reselect';
import CupoyClipboard from 'components/commons/CupoyClipboard';
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';


/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
`;
const DetailContent = styled.div`
    background-color: #fff;
`;
const MainInfoContent = styled.div`
    padding: 16px;
    box-shadow: inset 0 -1px 0 0 #EEEEEE;
`;
const MainTitle = styled.div`
    line-height: 21px;
    font-size: 15px;
    color: ${Colors.Dark3};
`;
const MainDescription = styled.div`
    line-height: 17px;
    margin: 4px 0 0 0;
    font-size: 12px;
    color: ${Colors.Dark5};
`;
const InfoContent = styled.div`
    padding: 16px;
    box-shadow: inset 0 -1px 0 0 #EEEEEE;
`;
const InfoTitle = styled.div`
    line-height: 18px;
    margin: 0 0 4px 0;
    font-size: 13px;
    color: #777777;
    font-weight: 600;
`;
const InfoText = styled.div`
    line-height: 20px;
    font-size: 13px;
    color: #777777;
`;
const PaymentList = styled.div`
    display: flex;
`;
const PaymentRightText = styled.div`
    margin: 0 0 0 auto;
`;
const PaymentDivide = styled.div`
    width: 100%;
    height: 1px;
    margin: 10px 0;
    background-color: #DDD;
`;
const PriceLabel = styled.span`
    font-size: 12px;
    color: ${Colors.Orange};
`;
const PriceNumber = styled.span`
    font-size: 15px;
    color: ${Colors.Orange};
    font-weight: 600;
`;
const ColorText = styled.span`
    color: ${(props) => props.color ? props.color : 'inherit'};
    font-size: ${({ size }) => size || 'inherit'};
    line-height: 1.3em;
`;
const SubmitButton = styled(Button.Orange)`
    position: relative;
    width: 100%;
    height: 44px;
    line-height: 44px;
    margin: 16px 0 0 0;
    font-size: 16px;
`;
const SubmitLoading = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    left: 16px;
    height: 100%;
    align-items: center;
    justify-content: center;
`;
const CopyBtn = styled(Button.Blue)`
    vertical-align: middle;
    height: 28px;
    line-height: 26px;
    padding: 0 6px;
    margin: 0 0 5px 5px;
`;

/*--------------------------
    Main Component
--------------------------*/
const MemberCardOrderUnpayDetailView = props => {

    const { id } = useParams();

    const selector = createSelector(
        state => state.member.haslogin,
        state => state.cardcart.unfinishedCartMap,
        state => state.web.ALLPAY,
        state => state.web.ECPAY,
        (
            haslogin,
            unfinishedCartMap,
            ALLPAY,
            ECPAY
        ) => ({
            haslogin,
            unfinishedCart: unfinishedCartMap[id],
            ALLPAY,
            ECPAY,
        })
    );

    const [{
        haslogin,
        unfinishedCart,
        ALLPAY,
        ECPAY,
    }, {
        webActions,
        cardCartActions,
    }] = useRedux(selector, {
        webActions: actionCreators.webActionCreators,
        cardCartActions: actionCreators.cardCartActionCreators,
    });

    const [status, setStatus] = useState({
        isLoading: false,
        isError: false,
        errorMsg: '',
        isPaying: false,
    });

    const payByCreditCard = () => {
        setStatus(prev => ({
            ...prev,
            isPaying: true,
        }));
        webActions.payByCreditCard(unfinishedCart.allpaycheckoutstruct);
    };

    const payByECPayATM = () => {

        if(!unfinishedCart || !unfinishedCart.allpaycheckoutstruct || !unfinishedCart.allpaycheckoutstruct.ecpayjsonform) {
            return;
        }

        const formInfo = JSON.parse(unfinishedCart.allpaycheckoutstruct.ecpayjsonform);

        setStatus(prev => ({
            ...prev,
            isPaying: true,
        }));

        webActions.payByECPayATM(formInfo);
    };

    const renderPaymentContent = (overPayLimit, unfinishedCart, payendtime) => {

        if(overPayLimit) {
            return (
                <ColorText>訂單已逾時</ColorText>
            );
        }

        const paytypeString = GLOBAL.PAYTYPE_STRING[unfinishedCart.paytype];

        switch (unfinishedCart.paytype) {
            case GLOBAL.PAYTYPE.ECPAYATM:
                return (
                    <React.Fragment>

                        <InfoText>
                            付款金額: <ColorText color={Colors.Orange} size='20px'>{unfinishedCart.totalamt}</ColorText>元<br/>
                            付款方式: {paytypeString}<br/>
                            付款截止時間: <ColorText color={Colors.Red}><TimeFormat format="simpleFullTime" time={payendtime} /></ColorText>
                        </InfoText>

                        {!!unfinishedCart && unfinishedCart.allpaycheckoutstruct && !!unfinishedCart.allpaycheckoutstruct.ecpayjsonform && ECPAY &&(
                            <SubmitButton
                                onClick={payByECPayATM}
                                disable={status.isPaying}
                            >
                                {status.isPaying && (
                                    <SubmitLoading>
                                        <GlobalStyle.Spin color="#fff"/>
                                    </SubmitLoading>
                                )}
                                連接綠界付款
                            </SubmitButton>
                        )}
                    </React.Fragment>
                );
            case GLOBAL.PAYTYPE.ESUN:
                return (
                    <InfoText>
                        付款金額: <ColorText color={Colors.Orange} size='20px'>{unfinishedCart.totalamt}</ColorText>元<br/>
                        付款方式: {paytypeString}<br/>
                        銀行代碼: <ColorText color={Colors.Blue} size='20px'>808 (玉山銀行)</ColorText><br/>
                        銀行帳號: <ColorText color={Colors.Blue} size='20px'>{unfinishedCart.accountid}</ColorText>
                        <CupoyClipboard
                            text={unfinishedCart.accountid}
                            copiedText={`已複製銀行帳號:\n${unfinishedCart.accountid}`}
                        >
                            <CopyBtn>複製</CopyBtn>
                        </CupoyClipboard>
                        <br/>
                        付款截止時間: <ColorText color={Colors.Red}><TimeFormat format="simpleFullTime" time={payendtime} /></ColorText>
                    </InfoText>
                );
            case GLOBAL.PAYTYPE.MEGA:
                return (
                    <InfoText>
                        付款金額: <ColorText color={Colors.Orange} size='20px'>{unfinishedCart.totalamt}</ColorText>元<br/>
                        付款方式: {paytypeString}<br/>
                        銀行代碼: <ColorText color={Colors.Blue} size='20px'>017 (兆豐銀行)</ColorText><br/>
                        銀行帳號: <ColorText color={Colors.Blue} size='20px'>{unfinishedCart.accountid}</ColorText>
                        <CupoyClipboard
                            text={unfinishedCart.accountid}
                            copiedText={`已複製銀行帳號:\n${unfinishedCart.accountid}`}
                        >
                            <CopyBtn>複製</CopyBtn>
                        </CupoyClipboard>
                        <br/>
                        付款截止時間: <ColorText color={Colors.Red}><TimeFormat format="simpleFullTime" time={payendtime} /></ColorText>
                    </InfoText>
                );
            case GLOBAL.PAYTYPE.ALLPAY_CREDITCARD:

                if (!unfinishedCart.allpaycheckoutstruct) {
                    return null;
                }

                return (
                    <div>
                        <InfoText>
                            訂單金額: <ColorText color={Colors.Orange} size='20px'>{unfinishedCart.totalamt}</ColorText>元<br/>
                            刷卡手續費: {unfinishedCart.allpaycheckoutstruct.TotalAmount - unfinishedCart.totalamt}元 <br/>
                            刷卡金額: <ColorText color={Colors.Orange} size='20px'>{unfinishedCart.allpaycheckoutstruct.TotalAmount}</ColorText><br/>
                            9199信用卡交易編號: <ColorText color={Colors.Blue} size='20px'>{unfinishedCart.allpaycheckoutstruct.MerchantTradeNo}</ColorText><br/>
                            付款方式: {paytypeString}<br/>
                            付款截止時間: <ColorText color={Colors.Red}><TimeFormat format="simpleFullTime" time={payendtime} /></ColorText>
                        </InfoText>
                        {ALLPAY && (
                            <SubmitButton
                                onClick={payByCreditCard}
                                disable={status.isPaying}
                            >
                                {status.isPaying && <SubmitLoading><GlobalStyle.Spin color="#fff" /></SubmitLoading>}
                                前往付款
                            </SubmitButton>
                        )}
                    </div>
                );
            case GLOBAL.PAYTYPE.FAMIPORT:
                return (
                    <InfoText>
                        付款金額: <ColorText color={Colors.Orange} size='20px'>{unfinishedCart.totalamt}</ColorText>元 (須另外支付超商25元手續費)<br/>
                        付款方式: {paytypeString}<br/>
                        繳費代碼: <ColorText color={Colors.Blue} size='20px'>{unfinishedCart.accountid}</ColorText>
                        <CupoyClipboard
                            text={unfinishedCart.accountid}
                            copiedText={`已複製繳費代碼:\n${unfinishedCart.accountid}`}
                        >
                            <CopyBtn>複製</CopyBtn>
                        </CupoyClipboard>
                        <br/>
                        付款截止時間: <ColorText color={Colors.Red}><TimeFormat format="simpleFullTime" time={payendtime} /></ColorText>
                        <div>
                        <CupoyImage
                            style={{width: '100%'}}
                            src={`${GLOBAL.URL_PREFIX}/images/famiport_howto.jpg`}
                        />
                        </div>

                    </InfoText>
                );
            case GLOBAL.PAYTYPE.HILIFE:

                return (
                    <InfoText>
                        付款金額: <ColorText color={Colors.Orange} size='20px'>{unfinishedCart.totalamt}</ColorText>元 (須另外支付超商25元手續費)<br/>
                        付款方式: {paytypeString}<br/>
                        訂單編號: <ColorText color={Colors.Blue} size='20px'>{GLOBAL.getHILIFEPaymentId(unfinishedCart.cartid)}</ColorText>(操作時需輸入)<br/>
                        付款截止時間: <ColorText color={Colors.Red}><TimeFormat format="simpleFullTime" time={payendtime} /></ColorText>
                        <div>
                        <CupoyImage
                            style={{width: '100%'}}
                            src={`${GLOBAL.URL_PREFIX}/images/9199_hilife.jpg`}
                        />
                        </div>

                    </InfoText>
                );
            case GLOBAL.PAYTYPE.IBON:
            return (
                <InfoText>
                    付款金額: <ColorText color={Colors.Orange} size='20px'>{unfinishedCart.totalamt}</ColorText>元<br/>
                    付款方式: {paytypeString}<br/>
                    付款截止時間: <ColorText color={Colors.Red}><TimeFormat format="simpleFullTime" time={payendtime} /></ColorText>
                    <div>
                        <ColorText color={Colors.Red}>確認繳費[訂單明細]</ColorText> 螢幕畫面如下，請選擇您在9199產生的訂單，即可列印繳費單!
                    </div>
                    <div>
                        <CupoyImage
                            style={{width: '100%'}}
                            src={`${GLOBAL.URL_PREFIX}/images/ibon_howto.jpg`}
                        />
                    </div>
                </InfoText>
            );
            case GLOBAL.PAYTYPE.CATHYATM:
                return (
                    <InfoText>
                        付款金額: <ColorText color={Colors.Orange} size='20px'>{unfinishedCart.totalamt}</ColorText>元<br/>
                        付款方式: {paytypeString}<br/>
                        銀行代碼: <ColorText color={Colors.Blue} size='20px'>013 (國泰世華銀行)</ColorText><br/>
                        銀行帳號: <ColorText color={Colors.Blue} size='20px'>{unfinishedCart.accountid}</ColorText>
                        <CupoyClipboard
                            text={unfinishedCart.accountid}
                            copiedText={`已複製銀行帳號:\n${unfinishedCart.accountid}`}
                        >
                            <CopyBtn>複製</CopyBtn>
                        </CupoyClipboard>
                        <br/>
                        付款截止時間: <ColorText color={Colors.Red}><TimeFormat format="simpleFullTime" time={payendtime} /></ColorText>
                    </InfoText>
                );
            case GLOBAL.PAYTYPE.ALLPAY_BANK:
            case GLOBAL.PAYTYPE.ALLPAY_STORE:
            default:
                return null;
        }
    };

    const renderUnfinishedCartStatus = (overPayLimit, unfinishedCart) => {

        const int_orderstatus = Number(unfinishedCart.orderstatus);

        if(overPayLimit && int_orderstatus === GLOBAL.CARTORDER_STATUS.UNPAY) {
            return (<ColorText>已過期</ColorText>);
        }

        const text = GLOBAL.CARTORDER_STATUS_STRING[int_orderstatus];

        let color = undefined;
        switch(int_orderstatus) {
            case GLOBAL.CARTORDER_STATUS.UNPAY:
                color = Colors.Red;
                break;
            case GLOBAL.CARTORDER_STATUS.SUCCESS:
                color = Colors.Green;
                break;
            case GLOBAL.CARTORDER_STATUS.FAIL:
            case GLOBAL.CARTORDER_STATUS.PARTFAIL:
                color = Colors.Red;
                break;
            default:
                break;
        }

        return (<ColorText color={color}>{text}</ColorText>);
    };

    const renderDetailContent = () => {

        if (!unfinishedCart) {
            return null;
        }

        const createtime = unfinishedCart.createtime;
        const payendtime = unfinishedCart.createtime + GLOBAL.PAYMENT_LIMIT_HOUR * 1000 * 60 * 60;
        const paytypeString = GLOBAL.PAYTYPE_STRING[unfinishedCart.paytype];
        const nowtime = Date.now();
        const overPayLimit = nowtime > payendtime;
        const hasProduct = unfinishedCart.pdtinfo && Array.isArray(unfinishedCart.pdtinfo.pdtlist) && unfinishedCart.pdtinfo.pdtlist.length > 0;
        const int_orderstatus = Number(unfinishedCart.orderstatus);

        return (
            <DetailContent>
                <MainInfoContent>
                    <MainTitle>
                        購物編號: {unfinishedCart.cartid}
                    </MainTitle>
                    <MainDescription>
                        建立時間: <TimeFormat format="simpleFullTime" time={createtime} />
                    </MainDescription>
                    <MainDescription>
                        有效期限: <TimeFormat format="simpleFullTime" time={payendtime} /><br/>
                    </MainDescription>
                    <MainDescription>
                        訂單狀態: {renderUnfinishedCartStatus(overPayLimit, unfinishedCart)}
                    </MainDescription>
                    <MainDescription>
                        付款方式: {paytypeString}
                    </MainDescription>
                </MainInfoContent>
                <InfoContent>
                    <InfoTitle>交易明細: </InfoTitle>
                    <InfoText>
                        {hasProduct && (
                            <div>
                                {unfinishedCart.pdtinfo.pdtlist.map((product, index) => {
                                    return (
                                        <PaymentList key={index}>
                                            {product.productname} x {product.count}
                                            <PaymentRightText>{product.sum}元</PaymentRightText>
                                        </PaymentList>
                                    );
                                })}
                            </div>
                        )}
                        <PaymentDivide></PaymentDivide>
                        <PaymentList>
                            <PaymentRightText>
                                <PriceLabel>總價: </PriceLabel><PriceNumber>{unfinishedCart.totalamt}元</PriceNumber>
                            </PaymentRightText>
                        </PaymentList>
                    </InfoText>
                </InfoContent>

                {int_orderstatus === GLOBAL.CARTORDER_STATUS.UNPAY && (
                    <InfoContent>
                        <InfoTitle>繳費資訊: </InfoTitle>
                        {renderPaymentContent(overPayLimit, unfinishedCart, payendtime)}
                    </InfoContent>
                )}
            </DetailContent>
        );
    };

    const checkWebState = () => {
        webActions.checkWebState();
    };

    const payCardCartUnfinishedOrder = () => {

        setStatus(prev => ({
            ...prev,
            isLoading: true,
            isError: false,
            errorMsg: '',
        }));

        cardCartActions.payCardCartUnfinishedOrder({
            cartid: id,
        }).then(() => {
            setStatus(prev => ({
                ...prev,
                isLoading: false,
            }));
        }).catch(error =>  {

            let message = "無法取得訂單資訊，請重新整理後再嘗試。";
            if(error && error.message) {
                message = error.message;
            }

            setStatus(prev => ({
                ...prev,
                isLoading: false,
                isError: true,
                errorMsg: message,
            }));
        });
    }

    useEffect(() => {

        if (!id) {
            return;
        }

        checkWebState();
        payCardCartUnfinishedOrder();
    }, [id]);

    let content = null;
    if (!haslogin) {
        content = <NeedLoginBlock />;
    } else {

        if (!id) {
            content = (
                <div>
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>找不到該網頁</GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>您的網址可能有誤，請確認網址是否正確</GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                </div>
            );
        } else if (!unfinishedCart && !status.isError && !status.isLoading) {
            content = null;
        } else {
            content = (
                <Content>
    
                    {!status.isLoading && !status.isError && renderDetailContent()}
    
                    {status.isLoading && (
                        <GlobalStyle.LoadingContainer>
                            <GlobalStyle.Spin />
                        </GlobalStyle.LoadingContainer>
                    )}
    
                    {status.isError && status.errorMsg && (
                        <GlobalStyle.ErrorContainer>
                            <GlobalStyle.ErrorContainerDesription>
                                {status.errorMsg}
                            </GlobalStyle.ErrorContainerDesription>
                        </GlobalStyle.ErrorContainer>
                    )}
                </Content>
            );
        }
    }

    return (
        <GlobalStyle.Container
            hasFixedTopbar
            hasFixedTabbar={false}
            backgroundColor='#F5F5F5'
        >
            <TopbarView
                isFixed
                leftComponent={
                    <CupoySrefView
                        statename={CupoyRouter.STATES.MEMBER_CARD_ORDER_LIST_CART}
                        stylecomponent={GlobalStyle.TopbarButton}
                    >
                        <GlobalStyle.TopbarIcon>
                            <i className="zmdi zmdi-chevron-left"></i>
                        </GlobalStyle.TopbarIcon>
                        點卡購物車
                    </CupoySrefView>
                }
                middleComponent="購買資料"
                rightComponent={
                    <CupoySrefView 
                        statename={CupoyRouter.STATES.HOME}
                        stylecomponent={GlobalStyle.TopbarButton}
                    >   
                        <GlobalStyle.TopbarIcon>
                            <i className="zmdi zmdi-chevron-left"></i>
                        </GlobalStyle.TopbarIcon>
                        首頁
                    </CupoySrefView>
                }

            />
            {content}
        </GlobalStyle.Container>
    );
};

/*--------------------------
    Export
--------------------------*/
export default MemberCardOrderUnpayDetailView;