import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GLOBAL from 'nonnmwebglobal';
import { bindActionCreators } from 'redux';
import actionCreators from 'actions/creators';
import { GlobalStyle, TabActivity } from 'stylecomponents';
import CupoyRouter from "components/commons/router/CupoyRouter";
import styled from 'styled-components';
import CupoySrefView from 'components/commons/router/CupoySrefView';
import CupoyImage from 'components/commons/CupoyImage';
import CupoyLink from 'components/commons/CupoyLink';

/**
|--------------------------------------------------
| Styled
|--------------------------------------------------
*/
const ProductList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background: #FFFFFF;
  box-shadow: inset 0 -1px 0 0 #EEEEEE;
`;

const ProductImage = styled.div`
  min-width: 80px;
  height: 60px;
  margin-right: 16px;
  background-image: url('${props => props.imageUrl ? props.imageUrl : null}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const ProductName = styled.div`
  padding-bottom: 8px;
  font-size: 14px;
  color: #333333;
`;
const StatusContainer = styled.div`
  height: calc(100vh - 44px - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #737373;
  font-size: 16px;
`;
const TabLinkItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50px;

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #777777;
  }
`;

/**
|--------------------------------------------------
| Main Component
|--------------------------------------------------
*/
class VitemProducts extends PureComponent {

  static propTypes = {
    gameid: PropTypes.string.isRequired,
    mobileWebEventLinkUrl: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.changeTabToBonus = this.changeTab.bind(this, GLOBAL.PRODUCT_CLASS_ID.BONUS_PRODUCT);
    this.changeTabToCard = this.changeTab.bind(this, GLOBAL.PRODUCT_CLASS_ID.POINTCARD_PRODUCT);
    this.changeTabToVitem = this.changeTab.bind(this, GLOBAL.PRODUCT_CLASS_ID.VITEM_PRODUCT);
    this.changeTabToFree = this.changeTab.bind(this, GLOBAL.PRODUCT_CLASS_ID.FREE_PRODUCT);

    this.state = {
        pclassid: GLOBAL.PRODUCT_CLASS_ID.VITEM_PRODUCT,
        isLoading: false,
        isEmpty: false,
        isError: false,
      };
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    this.getGameMappingProducts();
  }

  listGames = () => {
    this.props.gameActions.listGames();
  }

  getGameMappingProducts = () => {

    const { gameid } = this.props;

    const {
      pclassid,
      isLoading,
    } = this.state;

    if(isLoading) return;

    this.setState({
      isLoading: true,
      isError: false,
      isEmpty: false,
    }, () => {
      this.props.gameActions.getGameMappingProducts({
        pclassid,
        gameid,
      }).then(({ resultSize = 0 } = {}) => {
        this.setState({
          isLoading: false,
          isEmpty: resultSize === 0,
        });
      }).catch(err => {
        console.log(err);
        this.setState({
          isLoading: false,
          isError: true,
        });
      });
    });
  }

  changeTab = pclassid => {
    this.setState({ pclassid }, () => {
      this.getGameMappingProducts();
    });
  }

  render() {

    const {
        pclassid,
        isLoading,
        isEmpty,
        isError,
      } = this.state;

      const {
        gameid,
        gameMappingProductIdsCache,
        gameMappingProductMap,
      } = this.props;

      const key = this.props.gameActions.getGameMappingProductsCacheKey({ pclassid, gameid });
      const gameMappingProductIds = gameMappingProductIdsCache[key] || {};
      const { ids = [] } = gameMappingProductIds;

    return (
      <GlobalStyle.Container backgroundColor='#f5f5f5'>

        {this.renderTab(pclassid)}

        {
          !isLoading && !isEmpty && !isError && ids.map(productid => {

            const product = gameMappingProductMap[productid];
    
            return this.renderProduct(product);
          })
        }

        {isLoading && <StatusContainer><GlobalStyle.Spin /></StatusContainer>}
        {isEmpty && <StatusContainer>尚無商品</StatusContainer>}
        {isError && <StatusContainer>發生錯誤，請稍後重新整理後再嘗試</StatusContainer>}

      </GlobalStyle.Container>
    )
  }

  renderProduct = product => {

    let {
      productid,
      productname = '',
      thumbimg = '',
    } = product;

    productname = productname.trim();
    thumbimg = thumbimg.trim();

    const imageSrc = `${GLOBAL.imgSrcRootURL}/goodimgs/${productid}/${thumbimg}`;
    
    return (
      <CupoySrefView 
        statename={CupoyRouter.STATES.CARDINFORMATION}
        stylecomponent={ProductList}
        key={productid}
        params={{ id: productid }}
      >
        <CupoyImage
          stylecomponent={ProductImage}
          src={imageSrc}
          isBackgroundImage
        />
        <ProductInfo>
          <ProductName>
            {productname}
          </ProductName>
        </ProductInfo>
      </CupoySrefView>
    );
  }

  renderTab = pclassid => {

    const {
      mobileWebEventLinkUrl,
    } = this.props;

    return (
      <TabActivity.TabActivityWrapper>
        <TabActivity.TabActivityItem
          active={pclassid === GLOBAL.PRODUCT_CLASS_ID.BONUS_PRODUCT}
          onClick={this.changeTabToBonus}
        >
          發財商城
        </TabActivity.TabActivityItem>
        <TabActivity.TabActivityItem
          active={pclassid === GLOBAL.PRODUCT_CLASS_ID.POINTCARD_PRODUCT}
          onClick={this.changeTabToCard}
        >
          點數卡
        </TabActivity.TabActivityItem>
        <TabActivity.TabActivityItem
          active={pclassid === GLOBAL.PRODUCT_CLASS_ID.VITEM_PRODUCT}
          onClick={this.changeTabToVitem}
        >
          虛寶包
        </TabActivity.TabActivityItem>
        <TabActivity.TabActivityItem
          active={pclassid === GLOBAL.PRODUCT_CLASS_ID.FREE_PRODUCT}
          onClick={this.changeTabToFree}
        >
          免費序號
        </TabActivity.TabActivityItem>

        {!!mobileWebEventLinkUrl && (
          <TabLinkItem>
            <CupoyLink
              rel='noopener noreferrer'
              target='_blank'
              href={mobileWebEventLinkUrl}
            >
              好康活動
            </CupoyLink>
          </TabLinkItem>
        )}
      </TabActivity.TabActivityWrapper>
    );
  }
}

const mapStateToProps = state => ({
  gameMappingProductIdsCache: state.game.gameMappingProductIdsCache,
  gameMappingProductMap: state.game.gameMappingProductMap,
});

const mapDispatchToProps = dispatch => ({
  gameActions: bindActionCreators(actionCreators.gameActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VitemProducts);
