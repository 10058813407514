import GLOBAL from 'nonnmwebglobal';

const PRODUCT_TYPE = {
    CARD: 1, // 點數卡
    VITEM: 2, // 虛寶包
    FREE: 3, // 免費序號
    BONUS: 4, // 發財商城
    LOTTERY: 5, // 抽獎商品
};

export const PRODUCT_COVER_SIZE = {
    LARGE: '_320',
    SMALL: '',
};

/**
 * 取得商品封面
 */
function getProductCover(size = PRODUCT_COVER_SIZE.SMALL, productid, thumbimg) {

    if (!productid || !thumbimg) {
        return '';
    }

    let _thumbimg = thumbimg.replace('.jpg', `${size}.jpg`);
    _thumbimg = _thumbimg.replace('.png', `${size}.png`);
    _thumbimg = _thumbimg.replace('.gif', `${size}.gif`);

    const src = `${GLOBAL.imgSrcRootURL}/goodimgs/${productid}/${_thumbimg}`;

    return src;
}

export default {
    PRODUCT_TYPE, // 商品類別
    getProductCover, // 取得商品封面
};