export default {
 
    listGames: 'listGames',
    listGamesStarted: 'listGamesStarted',
    listGamesSuccess: 'listGamesSuccess',
    listGamesFailed: 'listGamesFailed',

    getGameServerList: 'getGameServerList',
    getGameServerListStarted: 'getGameServerListStarted',
    getGameServerListSuccess: 'getGameServerListSuccess',
    getGameServerListFailed: 'getGameServerListFailed',
    getGameMappingProductsStarted: 'getGameMappingProductsStarted',
    getGameMappingProductsSuccess: 'getGameMappingProductsSuccess',
    getGameMappingProductsFailed: 'getGameMappingProductsFailed',
};
