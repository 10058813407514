/*--------------------------
    Import 
--------------------------*/
import styled from 'styled-components';

/*--------------------------
    Style Variables
--------------------------*/

/*--------------------------
    Style Component
--------------------------*/

const ClearFloat = styled.div`

    &:after{
        content :'';
        display: block;
        clear: both;
    }

`;

const RectangleProductItem = styled.div`
    position: relative;
    float: left;
    width: 50%;
    padding-bottom: 52%;
    cursor: pointer;
    background: #FFFFFF;
    border-top: 1px solid #EEEEEE;
    
    &:not(:first-child) {
        border-left: 1px solid #EEEEEE;
  }
`;

const ProductContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 12px;
`;

const ProductImageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 75%;
`;

const ProductImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('${props => props.imageUrl ? props.imageUrl : null}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;

const ProductName = styled.div`
    height: 32px;
    margin-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    line-height: 16px;
    font-size: 14px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media only screen and (max-width: 320px){
        font-size: 12px;
        height: 28px;
        line-height: 14px;
    }
`;

const ProductItem ={

    RectangleProductItem,
    ProductContent,
    ProductImageWrapper,
    ProductImage,
    ProductName,
    ClearFloat,

}

export default ProductItem;
