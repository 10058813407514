import GlobalStyle from './globalstyle';
import Colors from './colors';
import styled from 'styled-components';
import GLOBAL from 'nonnmwebglobal';

const NewsItem = styled.div`
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-top: 10px;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: inset 0 1px 0 0 #EEEEEE;
`;

const NewsTag = styled.div`
    width: 40px;
    height: 18px;
    margin-right: 10px;
    border-radius: 2px;
    font-size: ${GlobalStyle.ViewSpace}px;
    text-align: center;
    color: #FFFFFF;
    background-color: ${({ bulletinType }) => {
        switch (bulletinType) {
            case GLOBAL.BULLETIN_TYPE.TRANSACTION:
                return '#F75C2F';
            case GLOBAL.BULLETIN_TYPE.EVENT:
                return '#2E7AC3';
            case GLOBAL.BULLETIN_TYPE.WEB:
                return '#00BCD4';
            case GLOBAL.BULLETIN_TYPE.NEWS:
                return '#00d400';
            case GLOBAL.BULLETIN_TYPE.SUSPENSION_USAGE_RIGHT:
                return '#009688';
            case GLOBAL.BULLETIN_TYPE.FEATURE:
                return '#009688';
            
            default:
                return '#4A90E2';
        }
    }};
`;

const NewsTagIcon = styled.span`
    display: inline-block;
    font-size: 12px;
    transform: scale(${9/12});
`;

const NewsText = styled.div`
    color: ${({ isTopBulletin }) => isTopBulletin ? '#f00' : Colors.Dark2};
    width: 100%;
`;

const NewsTime = styled.span`
    opacity: 0.5;
    font-size: 11px;
    color: #000000;
`;

const NewsList = {
    NewsItem,
    NewsTag,
    NewsTagIcon,
    NewsText,
    NewsTime,
}

export default NewsList;
