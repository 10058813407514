/*=====================================
    虛寶市集_出售區_留言板

    Author: Ac
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import Recaptcha from "react-grecaptcha";
import MediaQuery from 'react-responsive';
import GLOBAL from '../../nonnmwebglobal';
import { GlobalStyle } from 'stylecomponents';
import styled from 'styled-components';
import TimeFormat from "components/commons/TimeFormat";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
import CupoyRouter from 'components/commons/router/CupoyRouter';
/*--------------------------
    Styled Component
--------------------------*/

const WriteMessageWrapper = styled.div`

    padding: 16px;
    margin-bottom: 10px;
    background: #FFFFFF;

`;

const MessageName = styled.div`

    padding-bottom: 16px;
    font-size: 12px;
    color: #232423;

`;

const WantToMessage = styled.div`

    padding-bottom: 8px;
    font-size: 12px;
    color: #232423;

`;

const MessageArea = styled.textarea.attrs({

    placeholder: '9199提醒您：\n網路匿名發言仍須負相關法律責任，請勿發表誹謗等違法言論！'
})`
    &::placeholder{
        opacity: 0.5;
        font-size: 15px;
        color: #222328;
    }
    padding-top: 12px;
    width: 100%;
    height: 117px;
    background: #FAFAFA;
    border: 1px solid ${props => props.error ? 'red' : '#DDDDDD'};
    border-radius: 2px;

`;

const SubmitButtonWrapper = styled.div`
    text-align: center;
    opacity: ${props => props.error ? '0.6' : '1'};
    cursor: pointer;
`;

const SubmitButton = styled.button`

    margin: 16px;
    padding: 8px 36px;
    color: #FEFEFE;
    font-size: 14px;
    background: #F75C2F;
    border-radius: 3px;

`;

const MessageList = styled.div`

    padding-left: 16px;
    padding-right: 16px;
    background: #FFFFFF;
    
`;

const MessageItem = styled.div`

    padding-top: 11px;
    padding-bottom: 17px;    
    border-top: ${props => props.index === 0 ? '0' : '1px solid #DDDDDD'};

`;

const MessageInfo = styled.div`

    display: flex;
    justify-content: space-between;
    font-size: 11px;
    color: #757474;

`;

const MessageTime = styled.span`

    font-size: 11px;
    color: #B0ADAD;

`;

const MessageText = styled.div`

    padding-top: 6px;
    font-size: 12px;
    color: #4A4A4A;

`;

const GoogleCertifiedWrapper = styled.div`

    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 16px 16px 0 16px;
    border: 1px solid ${props => props.error ? 'red' : 'none'};

`;

const GoogleCertified = styled.div`
    margin: 0 0 32px 0;
`;

const GenderRadio = styled.div`

    display: flex;
    align-items: center;
    font-size: 12px;
    color: #232423;
    padding-bottom: 16px;
    border: 1px solid ${props => props.error ? 'red' : 'none'};

`;

const LabelWapper = styled.label`

    display: inline-flex;
    justify-content: center;
    align-items: center;

`;

const RadioDoc = styled.div`

    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #fff;

`;

const Radio = styled.span`

    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid rgb(167,167,167);
    margin-left: 5px;
    margin-right: 5px;
    background-color: ${props => props.active ? 'rgb(51,143,252)' : '#fff'};

`;

/*--------------------------
    Main Component
--------------------------*/
class ProductMessageView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        const routerState = CupoyRouter.getCurrent();
        const { params } = routerState;

        const {
            gameid,
            viprovid,
            serverid,
        } = params;

        this.state = {
            comment: '',
            gender: 'male',
            recaptchaResponse: '',
            isCommentError: false,

            viprovid,
            gameid,
            gameserverid: serverid,
        };

        this.addCommenting = false;

        this.callback = this.callback.bind(this);
        this.expiredCallback = this.expiredCallback.bind(this);
        this.addComment = this.addComment.bind(this);
        this.swichGender = this.swichGender.bind(this);
        this.renderMessageList = this.renderMessageList.bind(this);
        this.enterCommnet = this.enterCommnet.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
        this.listVprovMsgContents = this.listVprovMsgContents.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.listVprovMsgContents({ refresh: true });
    }

    listVprovMsgContents ({ refresh = false } = {}){

        const {    
            viprovid,
        } = this.state;

        const {
            isLoading,
            msgIdsByVprovIds: msgIdsByVprovIdsCache,
        } = this.props;

        const msgIdsByVprovIds = msgIdsByVprovIdsCache[viprovid] || [];
        const nextStart = msgIdsByVprovIds.length;

        if (isLoading){
            return;
        }

        this.props.guestbookActions.listVprovMsgContents({
            viprovid,
            start: !refresh ? nextStart : 0,
            refresh,
        });

    }

    swichGender(gender){
        this.setState({
            gender,
        });
    }
    enterCommnet(event){
        this.setState({
            comment: event.target.value,
        });
    }

    addComment(){
        
        const {
            comment,
            gender,
            recaptchaResponse,
            addCommenting,
        } = this.state;
        
        let isError = false;
        let isGnederError = false;
        let isCommentError = false;
        let isRecaptchaResponseError = false;

        if (addCommenting){ // 避免重複觸發
            return;
        }

        if(!gender){
            isError = true;
        }
        if(!comment){
            isError = true;
            isCommentError = true;
        }
        if(!GLOBAL.isAppFile && !recaptchaResponse){
            isError = true;
        }
        this.setState({
            isCommentError,
        });
        
        if(isError){
            if(isGnederError){

            }else if(isCommentError){

            }else if(isRecaptchaResponseError){

            }

        } else {
            const {
                comment,
                gender,
                recaptchaResponse,
                gameid,
                gameserverid,
                viprovid,
            } = this.state;

            this.setState({
                addCommenting: true,
            }, () => {
                this.props.guestbookActions.addVprovMsgContent({
                    'passcode': recaptchaResponse,
                    'viprovid': viprovid,
                    'gameid': gameid,
                    'gameserverid': gameserverid,
                    'sex': gender,
                    'msgcontent': comment,
                }).then(() => {
                    this.setState({
                        addCommenting: false,
                        comment: '',
                    }, () => {
                        !GLOBAL.isAppFile && window.grecaptcha.reset();
                        this.props.webActions.cupoyStateGo(
                            CupoyRouter.STATES.TRANSACTIONLIST_SELL,
                            {
                                gameid,
                                serverid: gameserverid,
                            }
                        );
                        // this.listVprovMsgContents({ refresh: true });
                    });
                }, (error) => {
                    var errorMsg = '發生無法預期的錯誤';
                    var errorDescription = '';

                    if(error && error.code) {
                        switch(error.code) {
                            case 'error.privilege.noLogin':
                                return;
                            default:
                                errorMsg = error.message;
                                break;
                        }
                    }
                    this.props.webActions.openAlertDialog({
                        title: errorMsg,
                        description: errorDescription,
                    });
                    this.setState({ addCommenting: false });
                });
            });
        }
    }
    // ------------------------------
    // google callback
    // ------------------------------
    callback(response) {

        this.setState({
            recaptchaResponse: response,
        });
    }

    // ------------------------------
    // google expiredCallback
    // ------------------------------
    expiredCallback() {
        
        this.props.webActions.openAlertDialog({
            title: '安全認證已過期',
        });
    }
    // ------------------------------
    // render
    // ------------------------------
    render() {
        const {membername} = this.props.memberinfo;
        const {
            gender,
            isCommentError,
            recaptchaResponse,
        } = this.state;
        return (
            <div>
                <WriteMessageWrapper>
                    <MessageName>暱稱：{membername}</MessageName>
                    <GenderRadio>
                        性別：
                        <LabelWapper onClick={()=> this.swichGender('male')}>
                            男<Radio active={gender === 'male'}><RadioDoc /></Radio>
                        </LabelWapper>
                        <LabelWapper onClick={()=> this.swichGender('female')}>
                            女<Radio active={gender === 'female'}><RadioDoc /></Radio>
                        </LabelWapper>
                    </GenderRadio>
                    <WantToMessage>我要留言</WantToMessage>
                    <MessageArea
                        onChange={this.enterCommnet}
                        ref={_commentTextarea => this.commentTextarea = _commentTextarea}
                        error={isCommentError}
                    />
                    {GLOBAL.isAppFile ?
                        null
                        :
                        <GoogleCertifiedWrapper>
                            <GoogleCertified>
                                <MediaQuery minWidth={0} maxWidth={360}>
                                    <Recaptcha
                                        sitekey={GLOBAL.grecaptcha_key}
                                        callback={this.callback}
                                        expiredCallback={this.expiredCallback}
                                        locale="zh-TW"
                                        className="g-recaptcha"
                                        style={{
                                            transform: 'scale(0.9)',
                                            WebkitTransform: 'scale(0.9)',
                                            transformOrigin: '0 0',
                                            WebkitTransformOrigin: '0 0',
                                        }}
                                    />

                                </MediaQuery>
                                <MediaQuery minWidth={361}>
                                    <Recaptcha
                                        sitekey={GLOBAL.grecaptcha_key}
                                        callback={this.callback}
                                        expiredCallback={this.expiredCallback}
                                        locale="zh-TW"
                                        className="g-recaptcha"
                                    />
                                </MediaQuery>
                            </GoogleCertified>
                        </GoogleCertifiedWrapper>
                    }
                    <SubmitButtonWrapper onClick={this.addComment} error={!GLOBAL.isAppFile && !recaptchaResponse}>
                        <SubmitButton>送出</SubmitButton>
                    </SubmitButtonWrapper>
                </WriteMessageWrapper>
                {this.renderMessageList()}
                {this.props.isLoading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }
            </div>
        );
    }
    renderMessageList(){

        const {
            viprovid
        } = this.state;
        
        const {
            msgIdsByVprovIds: msgIdsByVprovIdsCache,
        } = this.props;

        const msgIdsByVprovIds = msgIdsByVprovIdsCache[viprovid] || [];

        return (
            <div>
                <CupoyListRepeat
                    ref={_ref => (this.list = _ref)}
                    rowRenderer={(item, listWidth, listHeight) => { return this.rowRenderer(item, listWidth, listHeight, msgIdsByVprovIds)}}
                    listId={this.constructor.name}
                    items={msgIdsByVprovIds}
                    isLoading={this.props.isLoading}
                    useWindowScroll={true}
                    restoreWindowScroll={true}
                    loadMoreRows={this.listVprovMsgContents}
                />
            </div>
        )
    }

    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it),
        parent,
        measure,
        columnIndex,
        updateRowSize,
        measureCache,
        listWidth,
    }, width, listHeight,msgIdsByVprovIds){
        
        const msgIds = msgIdsByVprovIds[index];
        const msgMap = this.props.msgMap[msgIds];
        
        return (
            <div style={style} key={index}>
                <MessageList key={index}>
                    <MessageItem index={index}>
                        <MessageInfo>
                            留言者：{msgMap.memberid}
                            <MessageTime>
                                <TimeFormat
                                    format="timeStringDash"
                                    time={msgMap.createtime}
                                />
                            </MessageTime>
                        </MessageInfo>
                        <MessageText>{msgMap.msgcontent}</MessageText>
                    </MessageItem>
                </MessageList>
            </div>
        )
    }
}




/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        memberinfo: state.member.memberinfo,
        sellerMap: state.vitem.sellerMap,
        msgIdsByVprovIds: state.guestbook.msgIdsByVprovIds,
        msgMap: state.guestbook.msgMap,
        isLoading: state.guestbook.isloading,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        guestbookActions: bindActionCreators(actionCreators.guestbookActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(ProductMessageView);