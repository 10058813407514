import actionTypes from "actions/types/vitemActionTypes";
import GLOBAL from '../../nonnmwebglobal';
import customAxios from 'api/customAxios';

const GAME_API_URL = GLOBAL.URL_PREFIX + '/GameAction.do';
const VITEM_ORDER_API_URL = GLOBAL.URL_PREFIX + '/VItemOrderAction.do';
const VITEM_CART_API_URL = GLOBAL.URL_PREFIX + '/VItemCartAction.do';
const VITEM_PURCHASE_API_URL = GLOBAL.URL_PREFIX + '/VItemPurchaseAction.do';

function getVItemTopSellerListForGameStarted(payload) {
    return { type: actionTypes.getVItemTopSellerListForGameStarted, payload };
}
function getVItemTopSellerListForGameSuccess(payload) {
    return { type: actionTypes.getVItemTopSellerListForGameSuccess, payload };
}
function getVItemTopSellerListForGameFailed(payload) {
    return { type: actionTypes.getVItemTopSellerListForGameFailed, payload };
}
function getVItemTopSellerListForGame(payload = {}) {
    return dispatch => {

        dispatch(getVItemTopSellerListForGameStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            let form = new FormData();
            form.append('op', 'getVItemTopSellerListForGame');
            form.append('gameid', payload.gameid);
            form.append('gameserverid', payload.gameserverid);

            customAxios(GAME_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(getVItemTopSellerListForGameSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(getVItemTopSellerListForGameFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(getVItemTopSellerListForGameFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function getVItemSellerListForGameStarted(payload) {
    return { type: actionTypes.getVItemSellerListForGameStarted, payload };
}
function getVItemSellerListForGameSuccess(payload) {
    return { type: actionTypes.getVItemSellerListForGameSuccess, payload };
}
function getVItemSellerListForGameFailed(payload) {
    return { type: actionTypes.getVItemSellerListForGameFailed, payload };
}
function getVItemSellerListForGame(payload = {}) {
    return dispatch => {

        dispatch(getVItemSellerListForGameStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'getVItemSellerListForGame');
            form.append('gameid', payload.gameid);
            form.append('gameserverid', payload.gameserverid);
            form.append('start', payload.start);

            customAxios(GAME_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(getVItemSellerListForGameSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(getVItemSellerListForGameFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(getVItemSellerListForGameFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function initOrderStarted(payload) {
    return { type: actionTypes.initOrderStarted, payload };
}
function initOrderSuccess(payload) {
    return { type: actionTypes.initOrderSuccess, payload };
}
function initOrderFailed(payload) {
    return { type: actionTypes.initOrderFailed, payload };
}
function initOrder(payload = {}) {
    return dispatch => {

        dispatch(initOrderStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'initOrder');
            form.append('gameid', payload.gameid);
            form.append('gameserverid', payload.gameserverid);
            form.append('viprovid', payload.viprovid);
            
            customAxios(VITEM_ORDER_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                dispatch(initOrderFailed());
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                dispatch(initOrderFailed(result.error));
                                return 
                            }

                            dispatch(initOrderSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(initOrderFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(initOrderFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function buildVItemCartOrderStarted(payload) {
    return { type: actionTypes.buildVItemCartOrderStarted, payload };
}
function buildVItemCartOrderSuccess(payload) {
    return { type: actionTypes.buildVItemCartOrderSuccess, payload };
}
function buildVItemCartOrderFailed(payload) {
    return { type: actionTypes.buildVItemCartOrderFailed, payload };
}
function buildVItemCartOrder(payload = {}) {
    return dispatch => {

        dispatch(buildVItemCartOrderStarted(payload)); // dispatch synced action to update loading status
        
        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'buildVItemCartOrder');
            form.append('amount', payload.amount);
            form.append('vitemamount', payload.vitemamount);
            form.append('gameid', payload.gameid);
            form.append('gamename', payload.gamename);
            form.append('gameserverid', payload.gameserverid);
            form.append('viprovid', payload.viprovid);
            form.append('txtcontent', payload.txtcontent);
            form.append('viprovname', payload.viprovname);
            

            customAxios(VITEM_CART_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                dispatch(buildVItemCartOrderFailed());
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                dispatch(buildVItemCartOrderFailed(result.error));
                                return 
                            }

                            dispatch(buildVItemCartOrderSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(buildVItemCartOrderFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(buildVItemCartOrderFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function payVItemCartStarted(payload) {
    return { type: actionTypes.payVItemCartStarted, payload };
}
function payVItemCartSuccess(payload) {
    return { type: actionTypes.payVItemCartSuccess, payload };
}
function payVItemCartFailed(payload) {
    return { type: actionTypes.payVItemCartFailed, payload };
}
function payVItemCart(payload = {}) {
    return dispatch => {

        dispatch(payVItemCartStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            let form = new FormData();
            form.append('op', 'payVItemCart');
            form.append('paytype', payload.paytype);

            customAxios(VITEM_CART_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(payVItemCartSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(payVItemCartFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(payVItemCartFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function listTopPurchasesStarted(payload) {
    return { type: actionTypes.listTopPurchasesStarted, payload };
}
function listTopPurchasesSuccess(payload) {
    return { type: actionTypes.listTopPurchasesSuccess, payload };
}
function listTopPurchasesFailed(payload) {
    return { type: actionTypes.listTopPurchasesFailed, payload };
}
function listTopPurchases(payload = {}) {
    
    return dispatch => {

        dispatch(listTopPurchasesStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            let form = new FormData();
            form.append('op', 'listTopPurchases');
            form.append('gameid', payload.gameid);
            form.append('gameserverid', payload.gameserverid);

            customAxios(VITEM_PURCHASE_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                dispatch(listTopPurchasesFailed());
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                dispatch(listTopPurchasesFailed(result.error));
                                return 
                            }
                            dispatch(listTopPurchasesSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listTopPurchasesFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listTopPurchasesFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function listPurchasesStarted(payload) {
    return { type: actionTypes.listPurchasesStarted, payload };
}
function listPurchasesSuccess(payload) {
    return { type: actionTypes.listPurchasesSuccess, payload };
}
function listPurchasesFailed(payload) {
    return { type: actionTypes.listPurchasesFailed, payload };
}
function listPurchases(payload = {}) {
    return dispatch => {

        dispatch(listPurchasesStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {


            let form = new FormData();
            form.append('op', 'listPurchases');
            form.append('gameid', payload.gameid);
            form.append('gameserverid', payload.gameserverid);

            customAxios(VITEM_PURCHASE_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listPurchasesSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listPurchasesFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listPurchasesFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function addPurchaseOrderStarted(payload) {
    return { type: actionTypes.addPurchaseOrderStarted, payload };
}
function addPurchaseOrderSuccess(payload) {
    return { type: actionTypes.addPurchaseOrderSuccess, payload };
}
function addPurchaseOrderFailed(payload) {
    return { type: actionTypes.addPurchaseOrderFailed, payload };
}
function addPurchaseOrder(payload = {}) {
    return dispatch => {

        dispatch(addPurchaseOrderStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            let form = new FormData();
            form.append('op', 'addPurchaseOrder');
            form.append('purchaseid', payload.purchaseid);
            form.append('gameid', payload.gameid);
            form.append('itemprice', payload.itemprice);
            form.append('vprovmemberid', payload.vprovmemberid);
            form.append('vprovmrscid', payload.vprovmrscid);
            form.append('defaultgrade', payload.defaultgrade);
            form.append('serverrole', payload.serverrole);
            form.append('ordertime', payload.ordertime);
            form.append('orderprocess', payload.orderprocess);
            form.append('bankcode', payload.bankcode);
            form.append('bank', payload.bank);
            form.append('subbank', payload.subbank);
            form.append('bankaccount', payload.bankaccount);



            customAxios(VITEM_PURCHASE_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(addPurchaseOrderSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(addPurchaseOrderFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(addPurchaseOrderFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function initPurchaseOrderStarted(payload) {
    return { type: actionTypes.initPurchaseOrderStarted, payload };
}
function initPurchaseOrderSuccess(payload) {
    return { type: actionTypes.initPurchaseOrderSuccess, payload };
}
function initPurchaseOrderFailed(payload) {
    return { type: actionTypes.initPurchaseOrderFailed, payload };
}
function initPurchaseOrder(payload = {}) {
    return dispatch => {

        dispatch(initPurchaseOrderStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {


            let form = new FormData();
            form.append('op', 'initPurchaseOrder');
            form.append('purchaseid', payload.purchaseid);
            form.append('gameid', payload.gameid);
            form.append('gameserverid', payload.gameserverid);
            form.append('vprovmrscid', payload.viprovid);

            customAxios(VITEM_PURCHASE_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(initPurchaseOrderSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(initPurchaseOrderFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(initPurchaseOrderFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

const actions = {
 
    getVItemTopSellerListForGame, // 取得虛寶市集置頂賣家
    getVItemSellerListForGame, // 取得虛寶市集賣家列表
    initOrder, // 虛寶市集賣家準備訂單頁面（輸入購買數量與資訊）
    buildVItemCartOrder, // 建立訂單
    payVItemCart, // 訂單付款
    listTopPurchases, // 取得虛寶市集置頂收購單列表
    listPurchases, // 取得虛寶市集收購單列表
    initPurchaseOrder, // 取得po單前收購單所需資訊
    addPurchaseOrder, // 新增收購單交易(po單)

};

export default actions;