/*=====================================
    Mobile Tab

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React from "react";
import styled from 'styled-components';
import { GlobalStyle, Colors } from 'stylecomponents';
import { useRouteMatch } from 'react-router';
import { createSelector } from 'reselect';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import useRedux from 'actions/hooks/useRedux';
import GLOBAL from 'nonnmwebglobal';

/*--------------------------
    Style Component
--------------------------*/
const NavContent = styled.ul`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
`;
const NavIcon = styled.div`
	margin: 4px 0 2px 0;
    font-size: 20px;
`;
const NavText = styled.div`
    font-size: 12px;
    transform: scale(${10/12});
`;
const NavItem = styled.li`
	flex: 1;
    height: 100%;
    cursor: pointer;
`;
const NavItemContent = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
	justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    color: ${({ active }) => active ? Colors.Orange : Colors.Dark5};
`;
const NavHint = styled.div`
    position: absolute;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    background-color: ${Colors.Main};
    font-size: 12px;
    line-height: 24px;
    font-weight: 600;
    transform: scale(.95) translate3d(16px, -11px, 0px);
`;

const TabView = () => {

    const selector = createSelector(
        state => state.member.haslogin,
        state => state.cardcart.cartItems,
        (haslogin, cartItems) => ({
            haslogin,
            cartItems,
        })
    );

    const [{
        haslogin,
        cartItems = [],
    }] = useRedux(selector);

    const cartCount = cartItems.length;

    const isAtClubHome = !!useRouteMatch({ path: CupoyRouter.PATHS.HOME, exact: true });
    const isAtCardPointcard = !!useRouteMatch({ path: CupoyRouter.PATHS.CARD_POINTCARD });
    const isAtVitem = !!useRouteMatch({ path: CupoyRouter.PATHS.VITEM });
    const isAtCart = !!useRouteMatch({ path: CupoyRouter.PATHS.CART });
    const isAtMember = !!useRouteMatch({ path: CupoyRouter.PATHS.MEMBER });

    return (
        <GlobalStyle.Tabbar>
            <NavContent>

                <CupoySrefView
                    statename={CupoyRouter.STATES.HOME}
                    stylecomponent={NavItem}
                >
                    <NavItemContent active={isAtClubHome}>
                        <NavIcon><i className="icon-home"></i></NavIcon>
                        <NavText>首頁</NavText>
                    </NavItemContent>
                </CupoySrefView>

                <CupoySrefView
                    statename={CupoyRouter.STATES.CARD_POINTCARD}
                    stylecomponent={NavItem}
                >
                    <NavItemContent active={isAtCardPointcard}>
                        <NavIcon><i className="icon-gift"></i></NavIcon>
                        <NavText>點卡產包</NavText>
                    </NavItemContent>
                </CupoySrefView>

                <CupoySrefView
                    statename={CupoyRouter.STATES.VITEM}
                    stylecomponent={NavItem}
                >
                    <NavItemContent active={isAtVitem}>
                        <NavIcon><i className="icon-market"></i></NavIcon>
                        <NavText>{GLOBAL.VITEM_DISPLAY_TEXT}</NavText>
                    </NavItemContent>
                </CupoySrefView>

                <CupoySrefView
                    statename={CupoyRouter.STATES.CART}
                    stylecomponent={NavItem}
                >
                    <NavItemContent active={isAtCart}>
                        <NavIcon><i className="icon-cart"></i></NavIcon>
                        <NavText>購物車</NavText>

                        {haslogin && cartCount > 0 && (
                            <NavHint>
                                {cartCount > 9
                                    ? '9+'
                                    : cartCount}
                            </NavHint>
                        )}
                    </NavItemContent>
                </CupoySrefView>

                <CupoySrefView
                    statename={CupoyRouter.STATES.MEMBER}
                    stylecomponent={NavItem}
                >
                    <NavItemContent active={isAtMember}>
                        <NavIcon><i className="icon-user"></i></NavIcon>
                        <NavText>會員中心</NavText>
                    </NavItemContent>
                </CupoySrefView>

            </NavContent>
        </GlobalStyle.Tabbar>
    );
};

/*--------------------------
    export
--------------------------*/
export default TabView;