import actionTypes from "actions/types/profitEventActionTypes";
import convetDBResultSetToArray from "actions/util/convetDBResultSetToArray";
import {uniq} from 'lodash';
/*==========================
    Reducers
==========================*/
const reducers = {
    [actionTypes.listProfitEventsSuccess]: function(state, action) {
        return listProfitEventsSuccess(state, action);
    },
    [actionTypes.logProfitEventClickSuccess]: function(state, action) {
        return logProfitEventClickSuccess(state, action);
    },
};



/*==========================
    Method
==========================*/

function listProfitEventsSuccess (state, action) {
    const dbresult = convetDBResultSetToArray(action.payload);

    const itemMap = dbresult.reduce((map, item)=>{
        let bulletinid = undefined;
        if(item.clickurl_mweb){
            const clickurl_mweb = new URL(item.clickurl_mweb);
            const searchParams = new URLSearchParams(clickurl_mweb.search);
            bulletinid = searchParams.get('bulletinid');    
        }


        map[item.eventid] = Object.assign({}, state.itemMap[item.eventid], item, {bulletinid});
        return map;
    }, {});
    

    const ids = dbresult.map((item, idx)=>{
        return item.eventid;
    });

    const newids = uniq([...state.ids, ...ids]);

    const newItemMap = Object.assign({}, state.itemMap, itemMap);

    return Object.assign({}, state, {
        ids: newids,
        itemMap: newItemMap,
        isloading: false,
	});
}

function logProfitEventClickSuccess (state, action) {
    return Object.assign({}, state, {

        isloading: false,
	});
}



// 操作失敗
function actionFailed (state, action) {

    if(state.error === action.payload){
        return state;
    }

    const error = action.payload;

    return Object.assign({}, state, {
        isloading: false,
        error,
	});


}


// 操作中
function actionStarted (state, action) {

    if(state.isloading){
        return state;
    }

    return Object.assign({}, state, {
        isloading: true,
	});


}


/*==========================
    Export
==========================*/
export default function createReducers (initialState) {
	return function reducer(state = initialState, action) {
        if(!actionTypes.hasOwnProperty(action.type)){
            return state;
        }
		if (reducers.hasOwnProperty(action.type)) {
            return reducers[action.type](state, action);
		} else if(action.type.lastIndexOf('Started') !== -1){
			return actionStarted(state, action);            
		} else if(action.type.lastIndexOf('Failed') !== -1){
			return actionFailed(state, action);            
        } else {
			return state;
		}
	};
}