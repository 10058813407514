/*=====================================
    MemberComplaint

    Author: Gray
    CreateTime: 2018 / 03 / 13
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import GLOBAL from 'nonnmwebglobal';
import styled from 'styled-components';
import { GlobalStyle, Colors } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import TimeFormat from "components/commons/TimeFormat";
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
    margin: 16px 0 0 0;
    padding: 16px;
    background-color: #fff;
`;
const MetaHeader = styled.div`
    display: flex;
    margin: 0 0 10px 0;
    align-items: center;
`;
const Status = styled.div`
    display: inline-block;
    height: 18px;
    line-height: 18px;
    padding: 0 8px;
    font-size: 12px;
    color: #FFFFFF;
    background-color: ${(props) => props.color ? props.color : Colors.Blue};
    transform: scale(11/12);
`;
const TimeWrapper = styled.div`
    margin: 0 0 0 auto;
    font-size: 12px;
    color: #B0ADAD;
    letter-spacing: 0;
    transform: scale(11/12);
`;
const MetaInfo = styled.div`
    line-height: 18px;
    font-size: 12px;
    color: #4A4A4A;
    letter-spacing: 0;
`;
const Divide = styled.div`
    width: 100%;
    height: 1px;
    margin: 12px 0;
    background-color: #DDDDDD;
`;
const Title = styled.div`
    margin: 0 0 6px 0;
    font-size: 11px;
    color: #757474;
    letter-spacing: 0;
    transform: scale(11/12);
`;
const Description = styled.div`
    line-height: 18px;
    font-size: 12px;
    color: #4A4A4A;
    letter-spacing: 0;
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberComplaintView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            isLoading: false,
            isError: false,
            isLoaded: false,
        };
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props, true);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        this.init(this.props);
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props, isInit) {

        const {
            haslogin,
            csClassMap,
        } = this.props;

        if(haslogin) {
            this.getCustomerCaseReply();
        }

        if(isInit && !csClassMap[0]) {
            this.props.memberActions.listCustomerCaseClasses();
        }
    }

    // ------------------------------
    // 取得詳細資料
    // ------------------------------
    getCustomerCaseReply() {

        const current = CupoyRouter.getCurrent();

        const {
            params,
        } = current;

        const {
            id,
            createtime,
        } = params;

        const {
            isLoading,
            isError,
            isLoaded,
        } = this.state;

        if(!id || !createtime || isLoading || isError || isLoaded) {
            return;
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.memberActions.getCustomerCaseReply({
                caseid: id,
                ctime: createtime,
            }).then((result) => {
                this.setState({
                    isLoading: false,
                    isLoaded: true,
                });
            }, (error) => {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            })
        })
    }

    // ------------------------------
    // renderCaseStatus
    // ------------------------------
    renderCaseStatus(csCase) {

        const int_casestatus = Number(csCase.casestatus);
        const text = GLOBAL.CASESTATUS_STRING[int_casestatus];
        var color = undefined;

        switch(int_casestatus) {
            case GLOBAL.CASESTATUS.NOTPROCESSED:
                color = Colors.Red;
                break;
            default:
                color = Colors.Blue;
                break;
        }

        return (<Status color={color}>{text}</Status>);
    }

    // ------------------------------
    // renderCaseClass
    // ------------------------------
    renderCaseClass(csCase) {

        const {
            csClassMap,
        } = this.props;

        const csclassid = csCase.csclassid;

        if(!csClassMap[0]) {
            return '';
        }

        var csclass = csClassMap[csclassid];
        var result = '';

        if(csclass) {
            result = csclass.csclassname;

            if(csclass.bindclassid > 0) {
                var parent_csclass =  csClassMap[csclass.bindclassid];

                if(parent_csclass) {
                    result = parent_csclass.csclassname + ' > ' + result;
                }
            }
        }

        return result;
    }

    // ------------------------------
    // renderCaseReply
    // ------------------------------
    renderCaseReply(csCase) {

        const int_casestatus = Number(csCase.casestatus);
        var text = csCase.method;

        var textArr = text ? text.split('\r\n') : [];

        if(int_casestatus !== GLOBAL.CASESTATUS.SUCCESS_REPLY) {
            textArr = ['尚未回覆。']
        }

        return (
            <div>
                <Title>客服中心 回覆：</Title>
                <Description>{textArr.map((text) => { return(<div>{text}</div>)})}</Description>
            </div>
        );
    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return <NeedLoginBlock />;
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            isLoading,
            isError,
        } = this.state;

        const {
            csCaseMap,
        } = this.props;
        

        if(isLoading) {
            return (
                <GlobalStyle.LoadingContainer>
                    <GlobalStyle.Spin />
                </GlobalStyle.LoadingContainer>
            );
        } else if(isError) {
            return (
                <GlobalStyle.ErrorContainer>
                    <GlobalStyle.ErrorContainerTitle>
                        查無客訴紀錄
                    </GlobalStyle.ErrorContainerTitle>
                    <GlobalStyle.ErrorContainerDesription>
                        無法取得客訴記錄，請確定網址是否正確。
                    </GlobalStyle.ErrorContainerDesription>
                </GlobalStyle.ErrorContainer>
            );
        }

        const current = CupoyRouter.getCurrent();

        const {
            params,
        } = current;

        const {
            id,
        } = params;

        const csCase = csCaseMap[id];

        if(!csCase) {
            return null;
        }

        return (
            <Content>
                <MetaHeader>
                    {this.renderCaseStatus(csCase)}
                    <TimeWrapper>
                        <TimeFormat
                            format="simpleFullTime"
                            time={csCase.createtime}
                        />                    
                    </TimeWrapper>
                </MetaHeader>
                <MetaInfo>
                    問題種類: {this.renderCaseClass(csCase)}
                </MetaInfo>
                <Divide/>
                <Title>客訴單號 {csCase.caseid}：</Title>
                <Description>{csCase.comment}</Description>
                <Divide/>
                {this.renderCaseReply(csCase)}
            </Content>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER_COMPLAINT_LOGS}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            客訴記錄
                        </CupoySrefView>
                    }
                    middleComponent="客訴進度"
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        csCaseMap: state.member.csCaseMap,
        csClassMap: state.member.csClassMap,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberComplaintView);