/*=====================================
    TransactionSecurityView

    Author: Ac
    CreateTime: 2018 / 10 / 17
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, {
    useEffect,
} from 'react';
import CupoyRouter from 'components/commons/router/CupoyRouter';
import CupoySrefView from 'components/commons/router/CupoySrefView';
import TopbarView from "components/commons/TopbarView";
import {
    HelpStyle,
    GlobalStyle,
} from 'stylecomponents';

/*--------------------------
    Main Component
--------------------------*/
const TransactionSecurityView = props => {

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    return (
        <GlobalStyle.Container
            hasFixedTopbar
            hasFixedTabbar
            backgroundColor="#f5f0f0"
        >
            <TopbarView
                isFixed
                leftComponent={
                <CupoySrefView
                    statename={CupoyRouter.STATES.HELP}
                    stylecomponent={GlobalStyle.TopbarButton}
                >
                    <GlobalStyle.TopbarIcon>
                        <i className="zmdi zmdi-chevron-left"></i>
                    </GlobalStyle.TopbarIcon>
                    返回
                </CupoySrefView>
                }
                middleComponent="交易安全"
            />

            <HelpStyle.Container>
                <HelpStyle.Title>交易安全</HelpStyle.Title> 
                <ul>
                    <li>
                        <HelpStyle.Section>
                            <HelpStyle.SubTitle>1. 填寫真實身份</HelpStyle.SubTitle>
                            <HelpStyle.SubSection>
                                1-1. 申請加入本站會員請務必使用真實姓名、身分證號碼、電子郵件、手機號碼等本人真實資料，<br/>
                                以利未來交易過程與使用本站各項服務時，客服人員可快速與本人聯繫並加速問題處理。<br/>
                                1-2. 嚴禁會員註冊帳號資料轉讓或租借予他人使用，如經審查監督與事故糾紛產生，將立即予以會員停權處份。<br/>
                                1-3. 當會員進行交易保證金提款申請時，所提供之指定匯款銀行資料，<br/>
                                請務必與註冊會員姓名與身分證字號相同，避免與真實身份資料不符而無法順利進行提領動作。
                            </HelpStyle.SubSection>
                        </HelpStyle.Section>
                    </li>
                    <li>
                        <HelpStyle.Section>
                            <HelpStyle.SubTitle>2. 會員安全驗證</HelpStyle.SubTitle>
                            <HelpStyle.SubSection>
                                <HelpStyle.BoldText>2-1.「行動電話」：註冊會員時請務必輸入正確「行動電話」資料，以利購買商品與登入會員驗證之用。</HelpStyle.BoldText>
                                驗證流程說明如下：
                                <HelpStyle.SubSection>
                                    2-1-1. 在註冊會員時，填寫行動電話號碼。<br/>
                                    2-1-2. 按下﹝發送行動電話驗證碼﹞後，9199將會發送簡訊至您所填寫的行動電話號碼上。<br/>
                                    2-1-3. 將簡訊內的驗證碼填寫在"驗證碼"欄位，即完成驗證流程。
                                </HelpStyle.SubSection>
                                <HelpStyle.BoldText>2-2.「電子信箱」：註冊會員時請務必輸入正確「電子信箱」資料。</HelpStyle.BoldText>
                                驗證流程說明如下：
                                <HelpStyle.SubSection>
                                    2-2-1. 成功註冊會員後，9199會發送E-Mail驗證信至您填寫的信箱。<br/>
                                    2-2-2. 將信件內容的驗證碼填寫於9199會員中心"電子信箱驗證碼"欄位，即完成驗證流程。
                                </HelpStyle.SubSection>
                                <HelpStyle.BoldText>2-3.「身分證字號」：註冊會員時請務必輸入正確「身分證字號」資料，以利交易保證金提款與會員驗證之用。</HelpStyle.BoldText>
                                請勿嘗試錯誤資料，系統將會鎖定該身分證字號，如連續三次輸入錯誤資料，需等24小時後才可再進行驗證註冊。
                            </HelpStyle.SubSection>
                        </HelpStyle.Section>
                    </li>
                    <li>
                        <HelpStyle.Section>
                            <HelpStyle.SubTitle>3. 防騙交易指南</HelpStyle.SubTitle>
                            <HelpStyle.SubSection>
                                1. 9199全體人員並不會在各遊戲內進行叫賣，也不會與會員在遊戲內進行客服服務，<br/>
                                以及要求提供遊戲商品給予9199任何人員，且9199網站並不會註冊任何遊戲會員帳號提供遊戲內網站服務。<br/>
                                2. 所有訂單交易於遊戲內進行交易時，建議會員與供應商交易時進行遊戲畫面截圖，<br/>
                                以保障雙方交易！避免交易爭議時使用與備查，9199網站也不會在遊戲內要求會員更改交易地點與歸還遊戲幣。<br/>
                                3. 於9199網站進行線上繳費與交易，網站人員與供應商均不會主動要求會員使用其他非9199指定之
                                <HelpStyle.BlueText>交易保證金繳費方式</HelpStyle.BlueText>，<br/>
                                包含手機電話、電子郵件、即時通訊…等方式聯繫方式。<br/>
                                4. 9199網站與供應商並不會要求會員進行私下交易，以及9199網站以外其他平台進行交易與面交服務，<br/>
                                請會員小心防騙，保障自身權益，使用9199交易平台－安全平台交易久久。
                            </HelpStyle.SubSection>
                        </HelpStyle.Section>
                    </li>
                </ul>
            </HelpStyle.Container>
        </GlobalStyle.Container>
    );
};

/*--------------------------
    Export
--------------------------*/
export default TransactionSecurityView;