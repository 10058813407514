/*=====================================
    好康活動

    Author: Ac
    createtime: 2018 / 03 / 01
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import TopbarView from "components/commons/TopbarView";
import { GlobalStyle, NewsList} from 'stylecomponents';
import styled from 'styled-components';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import TimeFormat from "components/commons/TimeFormat";
import GLOBAL from '../../nonnmwebglobal';
import CupoyLink from 'components/commons/CupoyLink';
/*--------------------------
    styled
--------------------------*/

const PromotionsSeeMore = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    color: #9B9B9B;

`;

const NewsImgListItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: #fff;
    box-shadow: inset 0 1px 0 0 #EEEEEE;
`;

const ListImage = styled.div`
    /* 圖片 280X160 1:1.75 */
    margin-right: 12px;
    min-width: 91px; 
    height: 52px;
    background-size: cover;
    background-position: center;
    background-image: url('${props => props.imageUrl ? props.imageUrl : null}');
    background-repeat: no-repeat;

`;


/* 最新消息: */
/*--------------------------
    Main Component
--------------------------*/

class GoodPromotionsView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);
        this.renderList = this.renderList.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        this.props.profitEventActions.listProfitEvents();
    }

    toProfitEvent = eventid => {
        this.props.profitEventActions.logProfitEventClick({
            eventid,
        });
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        if(!Object.keys(this.props.itemMap) > 0){
            return null;
        }
        
        return (
            <GlobalStyle.Container
                hasFixedTopbar={true}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.HOME}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            首頁
                        </CupoySrefView>
                    }
                    middleComponent="好康活動"
                />
                {this.renderList()}
                {this.props.isloading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }  
            </GlobalStyle.Container>
        );
    }
    renderList() {

        const ids = this.props.ids;
        const itemMap = this.props.itemMap;
        if(!Array.isArray(ids) && ids > 0){
            return null;
        }
        if(!Object.keys(itemMap).length > 0){
            return null;
        }
        
        return (
            <div>
                {ids.map(eventId => {   
                    
                    const item = itemMap[eventId];

                    const {
                        eventid,
                        createtime,
                        title,
                        eventimg,
                    } = item;

                    let {
                        clickurl_mweb = '',
                    } = item;

                    clickurl_mweb = clickurl_mweb.trim() || 'about:blank';

                    return (
                        <CupoyLink
                            href={clickurl_mweb}
                            key={eventid}
                        >
                            <NewsImgListItem
                                // onClick={() => this.toProfitEvent(eventid)}
                            >
                                <ListImage imageUrl={`${GLOBAL.imgSrcRootURL}/profiteventimgs/${eventid}/${eventimg}`}/>
                                    <NewsList.NewsText>
                                        <div>{title}</div>
                                        <NewsList.NewsTime br={true}>
                                            <TimeFormat
                                                format="timeStringDash"
                                                time={createtime}
                                            />
                                        </NewsList.NewsTime>
                                    </NewsList.NewsText>
                                <PromotionsSeeMore><i className="icon-chevron-right"></i></PromotionsSeeMore>
                            </NewsImgListItem>

                        </CupoyLink>
                    );
                })}
            </div>
        )
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        itemMap: state.profitevent.itemMap,
        isloading: state.profitevent.isloading,
        ids: state.profitevent.ids,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        profitEventActions: bindActionCreators(actionCreators.profitEventActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(GoodPromotionsView);