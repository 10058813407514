/*=====================================
    常用商品

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import TopbarView from "components/commons/TopbarView";
import { GlobalStyle } from 'stylecomponents';
import styled from 'styled-components';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
/*--------------------------
    styled
--------------------------*/
const CommodityList = styled.div`

    display: flex;
    justify-content: center;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #EEEEEE, inset 0 1px 0 0 #EEEEEE;
    cursor: pointer;

`;

const CommodityImage = styled.div`

    min-width: 86px;
    height: 66px;
    margin-right: 16px;
    background-color: #369;

`;

const CommodityInfo = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

`;

const CommodityName = styled.div`

    padding-bottom: 8px;
    font-size: 14px;
    color: #333333;

`;

const Price = styled.del`

    display: inline-block;
    font-size: 11px;
    color: #808180;

`;

const NewPrice = styled.span`

    margin-left: 8px;
    font-size: 11px;
    color: #F75C2F;

`;

const SpreadWrapper = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    color: #DDDDDD;

`;

const MemberPrice = styled.span`

    padding-left: 5px;
    font-weight: bolder;
    font-size: 14px;
    color: #F75C2F;

`;

/* 最新消息: */
/*--------------------------
    Main Component
--------------------------*/

class MyCommodityView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {

        };
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

    }

    // ------------------------------
    // render
    // ------------------------------
    render() {
        
        return (
            <GlobalStyle.Container
                hasFixedTabbar={true}
                hasFixedTopbar={true}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            返回
                        </CupoySrefView>
                    }
                    middleComponent="常用商品管理"
                />
                <CupoySrefView 
                    statename={CupoyRouter.STATES.CARDINFORMATION}
                    stylecomponent={CommodityList}
                    params={{}}
                >
                    <CommodityImage />
                    <CommodityInfo>
                        <CommodityName>Game淘卡500點</CommodityName>
                        <div>
                            <Price>
                                原價:500元
                            </Price>
                            <NewPrice>
                                會員:
                                <MemberPrice>465元</MemberPrice>
                            </NewPrice>
                        </div>
                    </CommodityInfo>
                    <SpreadWrapper>
                        <i className="zmdi zmdi-more"></i>
                    </SpreadWrapper>
                </CupoySrefView>
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MyCommodityView);