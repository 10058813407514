import React from 'react';
import styled from 'styled-components';
import CupoyRouter from 'components/commons/router/CupoyRouter';
import CupoySrefView from "components/commons/router/CupoySrefView";
import TopbarView from "components/commons/TopbarView";
import {
    Colors,
    GlobalStyle,
    MemberStyle,
} from 'stylecomponents';

/**
|--------------------------------------------------
| Varibales
|--------------------------------------------------
*/
const NAV_LIST_DATA = [
    {
        sectionTitle: '新手上路',
        list: [
            {
                title: '如何升級會員',
                iconElement: <i className='zmdi zmdi-account' />,
                iconSize: 16,
                routerState: CupoyRouter.STATES.UPGRADE_MEMBERSHIP,
            },
            {
                title: '會員停權',
                iconElement: <i className='zmdi zmdi-lock' />,
                iconSize: 16,
                routerState: CupoyRouter.STATES.MEMBER_SUSPENSION_OF_POWER,
            },
        ],
    },
    {
        sectionTitle: '交易須知',
        list: [
            {
                title: '交易安全',
                iconElement: <i className='zmdi zmdi-shield-security' />,
                iconSize: 16,
                routerState: CupoyRouter.STATES.TRANSACTION_SECURITY,
            },
            {
                title: '繳費說明',
                iconElement: <i className='zmdi zmdi-file' />,
                iconSize: 16,
                routerState: CupoyRouter.STATES.TRANSACTION_ORDER,
            },
        ],
    },
    {
        sectionTitle: '發財金',
        list: [
            {
                title: '發財金說明',
                routerState: CupoyRouter.STATES.BONUS_MANUAL,
                iconElement: <i className='zmdi zmdi-money'></i>,
                iconSize: 16,
            },
        ],
    }
];
/**
|--------------------------------------------------
| Styled
|--------------------------------------------------
*/
const SectionTitle = styled.div`
    color: ${Colors.Dark3};
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
`;
/**
|--------------------------------------------------
| Main Component
|--------------------------------------------------
*/
const HelpView = props => {
    return (
        <GlobalStyle.Container
            hasFixedTopbar
            hasFixedTabbar
            backgroundColor="#f5f0f0"
        >

            <TopbarView
                isFixed
                leftComponent={
                <CupoySrefView
                    statename={CupoyRouter.STATES.MEMBER}
                    stylecomponent={GlobalStyle.TopbarButton}
                >
                    <GlobalStyle.TopbarIcon>
                        <i className="zmdi zmdi-chevron-left"></i>
                    </GlobalStyle.TopbarIcon>
                    返回
                </CupoySrefView>
                }
                middleComponent="幫助中心"
            />

            {NAV_LIST_DATA.map((navData, parentIdx) => {

                const {
                    sectionTitle,
                    list,
                } = navData;

                return (
                    <React.Fragment key={parentIdx}>

                        <MemberStyle.RowItemContainer isToprow={parentIdx > 0}>
                            <MemberStyle.RowItemLeftContainer>
                                <SectionTitle>{sectionTitle}</SectionTitle>
                            </MemberStyle.RowItemLeftContainer>
                        </MemberStyle.RowItemContainer>

                        <MemberStyle.RowItemBorder/>

                        {list.map((item, idx) => {
                    
                            const {
                                title,
                                iconElement,
                                routerState,
                                iconSize,
                            } = item;

                            return (
                                <React.Fragment key={idx}>
                                    <CupoySrefView statename={routerState}>
                                        <MemberStyle.RowItemContainer>
                                            <MemberStyle.RowItemLeftContainer>
                                                <MemberStyle.GrayIcon iconSize={iconSize}>
                                                    {iconElement}
                                                </MemberStyle.GrayIcon>
                                                <MemberStyle.FuntionTitle>{title}</MemberStyle.FuntionTitle>
                                            </MemberStyle.RowItemLeftContainer>
                                            <MemberStyle.RowItemRightContainer>
                                                <i className="icon-chevron-right" />
                                            </MemberStyle.RowItemRightContainer >
                                        </MemberStyle.RowItemContainer>
                                    </CupoySrefView>
                                    <MemberStyle.RowItemBorder/>
                                </React.Fragment>
                            );
                        })}
                    </React.Fragment>
                )
            })}

        </GlobalStyle.Container>
    );
};

export default HelpView;

