/*=====================================
    MemberVitemOrderListSell

    Author: Gray
    CreateTime: 2018 / 03 / 15
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import GLOBAL from 'nonnmwebglobal';
import styled from 'styled-components';
import { GlobalStyle, Colors, MemberStyle } from 'stylecomponents';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
import TimeFormat from "components/commons/TimeFormat";
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
    padding: 0 0 10px 0;
`;
const ColorText = styled.span`
    color: ${(props) => props.color ? props.color : 'inherit'};
    font-weight: ${(props) => props.weight ? props.weight : 'inherit'};
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberVitemOrderListSellView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            start: Array.isArray(this.props.orderIds) ? this.props.orderIds.length : 0,
            isLoading: false,
            isError: false,
            isEmpty: false,
            isNoMore: false,
        };

        this.listBuyVItemOrders = this.listBuyVItemOrders.bind(this);
        this.renderOrderTag = this.renderOrderTag.bind(this);
        this.renderOrderStatus = this.renderOrderStatus.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        if (prevProps.haslogin !== this.props.haslogin) {
            this.init(this.props);
        }
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props) {

        const {
            haslogin,
            orderIds,
        } = this.props;

        if (haslogin && (!Array.isArray(orderIds) || orderIds.length === 0)) {
            this.listBuyVItemOrders();
        }
    }

    // ------------------------------
    // 取得虛寶出售區列表
    // ------------------------------
    listBuyVItemOrders() {

        const {
            start,
            isLoading,
            isEmpty,
            isNoMore,
            isError,
        } = this.state;

        if (isLoading || isEmpty || isNoMore || isError) {
            return;
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.vitemOrderActions.listBuyVItemOrders({
                start: start,
            }).then((result) => {
                const orderIds = this.props.orderIds;
                
                var newState = {
                    isLoading: false,
                    start: orderIds.length,
                };

                if (!Array.isArray(orderIds) || orderIds.length === 0) {
                    newState.isEmpty = true;
                } else if (start === newState.start) {
                    newState.isNoMore = true;
                }

                this.setState(newState);
            }, (error) => {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            })
        })
    }

    // ------------------------------
    // renderOrderTag
    // ------------------------------
    renderOrderTag(order) {

        const int_orderstatus = Number(order.orderstatus);
        const text = GLOBAL.B2C_ORDERSTATUS_STRING[int_orderstatus];

        switch(int_orderstatus) {
            case GLOBAL.B2C_ORDERSTATUS.NOTPROCESSED:
                return <MemberStyle.LogsTag color={Colors.Red}>{text}</MemberStyle.LogsTag>;
            default:
                return '';
        }
    }

    // ------------------------------
    // renderOrderStatus
    // ------------------------------
    renderOrderStatus(order) {

        const int_orderstatus = Number(order.orderstatus);
        const text = GLOBAL.B2C_ORDERSTATUS_STRING[int_orderstatus];
        var color = undefined;
        let weight = undefined;

        switch(int_orderstatus) {
            case GLOBAL.B2C_ORDERSTATUS.NOTPROCESSED:
                color = Colors.Red;
                break;
            case GLOBAL.B2C_ORDERSTATUS.SUCCESS:
                color = Colors.Green;
                break;
            case GLOBAL.B2C_ORDERSTATUS.PROCESSED:
                color = Colors.Blue;
                weight = 'bold';
                break;
            default:
                break;
        }

        return (<ColorText color={color} weight={weight}>{text}</ColorText>);
    }

    // ------------------------------
    // rowRenderer
    // ------------------------------
    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it),
        parent,
        measure,
        columnIndex,
        updateRowSize,
        measureCache,
        listWidth,
    }, width, listHeight) {

        const {
            orderIds,
            orderMap,
        } = this.props;

        const orderId = orderIds[index];
        const order = orderMap[orderId];

        if (!order) {
            return null;
        }

        return (
            <CupoySrefView
                statename={CupoyRouter.STATES.MEMBER_VITEM_ORDER_SELL}
                params={{
                    id: orderId,
                }}
                key={index}
                style={style}
            >
                <MemberStyle.LogsItem hasArrow={true}>
                    <MemberStyle.LogsTitle>
                        {order.nickname}{order.servername ? '-' + order.servername : null}
                    </MemberStyle.LogsTitle>
                    <MemberStyle.LogsDescription>
                        建立時間: <TimeFormat format="simpleFullTime" time={order.createtime} />
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription>
                        訂單編號: {order.orderid}
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription>
                        虛幣金額: <ColorText color={Colors.Blue}>{order.vitemamount}</ColorText>
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription>
                        訂單狀態: {this.renderOrderStatus(order)}
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription isLast={true}>
                        評價狀態: {order.isgrade ? '已評價' : '尚未評價'}
                    </MemberStyle.LogsDescription>
                    {this.renderOrderTag(order)}
                    {order.amount > 0 ?
                        <MemberStyle.LogsStatus>金額：{order.amount} 元</MemberStyle.LogsStatus>
                        :
                        null
                    }
                    <MemberStyle.LogsRightArrow><i className="icon-chevron-right"/></MemberStyle.LogsRightArrow>
                </MemberStyle.LogsItem>
            </CupoySrefView>
        )
    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return <NeedLoginBlock />;
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            isLoading,
            isError,
            isEmpty,
        } = this.state;

        const {
            orderIds,
        } = this.props;

        return (
            <Content>
                {Array.isArray(orderIds) && orderIds.length > 0 ?
                    <CupoyListRepeat
                        listId={this.constructor.name}
                        rowRenderer={this.rowRenderer}
                        items={orderIds}
                        isLoading={isLoading}
                        useWindowScroll={true}
                        restoreWindowScroll={true}
                        loadMoreRows={() => this.listBuyVItemOrders()}
                    />
                    :
                    null
                }

                {isLoading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }

                {isError ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            發生錯誤
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無法取得出售區訂單，請重新整理後再嘗試。
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }

                {isEmpty ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            無任何出售區訂單
                        </GlobalStyle.ErrorContainerTitle>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }
            </Content>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        if (haslogin) {
            return this.renderLoginContent();
        } else {
            return this.renderNoLoginContent();
        }
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        orderMap: state.vitemorder.orderMap,
        orderIds: state.vitemorder.orderIds,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        vitemOrderActions: bindActionCreators(actionCreators.vitemOrderActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberVitemOrderListSellView);