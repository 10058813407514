/*=====================================
    MemberCardOrderListFinish

    Author: Gray
    CreateTime: 2018 / 03 / 19
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import styled, { keyframes } from 'styled-components';
import { GlobalStyle, Colors, MemberStyle } from 'stylecomponents';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
import TimeFormat from "components/commons/TimeFormat";

/**
 * Keyframes
 */
const rotate = keyframes`
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
`;

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
    padding: 44px 0 10px;
`;
const ColorText = styled.span`
    color: ${(props) => props.color ? props.color : 'inherit'};
`;
const RefreshBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 44px;
    border-bottom: 1px solid #ddd;
    position: fixed;
    z-index: 100;
    top: 94px;
    left: 0;
    background-color: #fff;
    user-select: none;
    cursor: pointer;

    > i {
        margin-right: 8px;
        font-size: 16px;
        animation-name: ${rotate};
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-duration: 750ms;
        color: ${({ isRefreshing }) => isRefreshing ? Colors.Main : 'inherit'};
        animation-play-state: ${({ isRefreshing }) => isRefreshing ? 'running' : 'paused'};
    }
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberCardOrderListFinishView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            start: 0,
            isLoading: false,
            isRefreshing: false,
            isError: false,
            isEmpty: false,
            isNoMore: false,
            cartid : undefined,
        };

        this.listCardOrders = this.listCardOrders.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
        this.refreshList = this.refreshList.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        this.init();
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init() {

        const current = CupoyRouter.getCurrent();
        
        const {
            querys,
        } = current;

        let cartid;

        if(querys && querys.cartid) {
            cartid = querys.cartid;
        }

        this.setState({
            cartid,
        }, () => {
            this.listCardOrders();
        });
    }

    // ------------------------------
    // 取得點卡/產包訂單列表
    // ------------------------------
    listCardOrders({ refresh = false } = {}) {

        const {
            start,
            cartid,
            isLoading,
            isEmpty,
            isNoMore,
            isError,
        } = this.state;

        if (!refresh && (isLoading || isEmpty || isNoMore || isError)) {
            return;
        }

        this.setState({
            isLoading: true,
            isRefreshing: refresh,
        }, () => {
            this.props.cardOrderActions.listCardOrders({
                start: refresh ? 0 : start,
                cartid,
                refresh: start === 0 || refresh,
            }).then(result => {

                const orderIdsMap = this.props.orderIdsMap;
                const listkey = cartid ? cartid : 'all';
                const orderIds = orderIdsMap[listkey];

                const newState = {
                    isLoading: false,
                    isRefreshing: false,
                    start: orderIds ? orderIds.length : 0,
                };

                if (!Array.isArray(orderIds) || orderIds.length === 0) {
                    newState.isEmpty = true;
                } else if (start === newState.start) {
                    newState.isNoMore = true;
                }

                this.setState(newState, () => {
                    if (refresh) {
                        this.props.webActions.showSimpleAlertDialog('已重新整理完成');
                    }
                });
            }, (error) => {
                this.setState({
                    isLoading: false,
                    isRefreshing: false,
                    isError: true,
                });
            })
        })
    }

    // ------------------------------
    // rowRenderer
    // ------------------------------
    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it),
        parent,
        measure,
        columnIndex,
        updateRowSize,
        measureCache,
        listWidth,
    }, width, listHeight) {

        const {
            cartid,
        } = this.state;

        const {
            orderIdsMap,
            orderMap,
        } = this.props;

        const listkey = cartid ? cartid : 'all';
        const orderIds = orderIdsMap[listkey];
        const orderId = orderIds[index];
        const order = orderMap[orderId];

        if (!order) {
            return null;
        }

        return (
            <CupoySrefView
                statename={CupoyRouter.STATES.MEMBER_CARD_ORDER_DETAIL_LIST}
                params={{
                    id: order.orderid,
                }}
                key={index}
                style={style}
            >
                <MemberStyle.LogsItem hasArrow>
                    <MemberStyle.LogsTitle>
                        產品名稱: {order.productname}
                    </MemberStyle.LogsTitle>
                    <MemberStyle.LogsDescription>
                        建立時間: <TimeFormat format="simpleFullTime" time={order.saletime} />
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription>
                        <ColorText color={Colors.Blue}>訂單編號: {order.orderid}</ColorText>
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription isLast>
                        數量: {order.count}
                    </MemberStyle.LogsDescription>
                    {order.ordersum >= 0 ?
                        <MemberStyle.LogsStatus>金額：{order.ordersum} 元</MemberStyle.LogsStatus>
                        :
                        null
                    }
                    <MemberStyle.LogsRightArrow><i className="icon-chevron-right"/></MemberStyle.LogsRightArrow>
                </MemberStyle.LogsItem>
            </CupoySrefView>
        )
    }

    refreshList() {

        window.scrollTo({
            top: 0,
            behavior: 'instant',
        });

        this.listCardOrders({
            refresh: true,
        });
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            cartid,
            isLoading,
            isRefreshing,
            isError,
            isEmpty,
        } = this.state;

        const {
            orderIdsMap,
        } = this.props;

        const listkey = cartid ? cartid : 'all';
        const orderIds = orderIdsMap[listkey];

        return (
            <React.Fragment>

                {(isRefreshing || (Array.isArray(orderIds) && orderIds.length > 0)) && (
                    <RefreshBtn isRefreshing={isRefreshing} onClick={this.refreshList}>
                        <i className="zmdi zmdi-refresh"></i>
                        立即重新整理
                    </RefreshBtn>
                )}

                <Content>

                    {Array.isArray(orderIds) && orderIds.length > 0 && (
                        <CupoyListRepeat
                            listId={this.constructor.name}
                            rowRenderer={this.rowRenderer}
                            items={orderIds}
                            isLoading={isLoading}
                            useWindowScroll
                            restoreWindowScroll
                            loadMoreRows={this.listCardOrders}
                        />
                    )}

                    {isLoading && (
                        <GlobalStyle.LoadingContainer>
                            <GlobalStyle.Spin />
                        </GlobalStyle.LoadingContainer>
                    )}

                    {isError && (
                        <GlobalStyle.ErrorContainer>
                            <GlobalStyle.ErrorContainerTitle>
                                發生錯誤
                            </GlobalStyle.ErrorContainerTitle>
                            <GlobalStyle.ErrorContainerDesription>
                                無法取得點卡產包訂單，請重新整理後再嘗試。
                            </GlobalStyle.ErrorContainerDesription>
                        </GlobalStyle.ErrorContainer>
                    )}

                    {isEmpty && (
                        <GlobalStyle.ErrorContainer>
                            <GlobalStyle.ErrorContainerTitle>
                                無任何點卡產包訂單
                            </GlobalStyle.ErrorContainerTitle>
                        </GlobalStyle.ErrorContainer>
                    )}
                </Content>

            </React.Fragment>
        )
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        orderMap: state.cardorder.orderMap,
        orderIdsMap: state.cardorder.orderIdsMap,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        cardOrderActions: bindActionCreators(actionCreators.cardOrderActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberCardOrderListFinishView);