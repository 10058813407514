import actionTypes from "actions/types/registerActionTypes";
import GLOBAL from '../../nonnmwebglobal';
import customAxios from 'api/customAxios';

const API_URL = GLOBAL.URL_PREFIX + '/MemberRegisterAction.do';

function registerMemberStarted(payload) {
    return { type: actionTypes.registerMemberStarted, payload };
}
function registerMemberSuccess(payload) {
    return { type: actionTypes.registerMemberSuccess, payload };
}
function registerMemberFailed(payload) {
    return { type: actionTypes.registerMemberFailed, payload };
}
function registerMember(payload = {}) {
    return dispatch => {

        dispatch(registerMemberStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            // TODO: 記得改API_URL與form內容
            let form = new FormData();
            form.append('op', 'registerMember');
            form.append('idnumber', payload.idnumber);
            form.append('passwd', payload.passwd);
            form.append('memberid', payload.memberid);
            form.append('name', payload.name);
            form.append('nickname', payload.nickname);
            form.append('mobilephone', payload.mobilephone);
            form.append('smsauth', payload.smsauth);
            form.append('email', payload.email);
            form.append('birthday', payload.birthday);
            form.append('city', payload.city);
            form.append('region', payload.region);
            form.append('postalcode', payload.postalcode);
            form.append('address', payload.address);
            form.append('isdonate', payload.isdonate);
            form.append('npoban', payload.npoban);
            form.append('carrierid', payload.carrierid);
            form.append('carriertype', payload.carriertype);
            form.append('invoicetype', payload.invoicetype);
            form.append('crn', payload.crn);
            form.append('companytitle', payload.companytitle);
            form.append('shownametype', payload.shownametype);


            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(registerMemberSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(registerMemberFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(registerMemberFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function checkMemberID(payload) {
    return () => {

        const {
            memberid,
            cancelToken,
        } = payload;

        const url = `${API_URL}?op=checkMemberID`;

        const form = new FormData();
        form.append('memberid', memberid);
        
        const promise = new Promise((resolve, reject) => {
        
            customAxios(url, {
                method: 'POST',
                body: form,
                cancelToken,
            }).then(response => {
                if (response.status === 503) {
                    // AWS server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                    reject();
                    return;
                }
        
                response.json().then(result => {
                    if (result.error) {
                        reject(result.error);
                        return;
                    }

                    resolve(result);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                if (error.name === 'AbortError') {
                    return;
                }
                // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                reject(error);
            });
        });
        return promise;
    }
}


function checkPhoneStarted(payload) {
    return { type: actionTypes.checkPhoneStarted, payload };
}
function checkPhoneSuccess(payload) {
    return { type: actionTypes.checkPhoneSuccess, payload };
}
function checkPhoneFailed(payload) {
    return { type: actionTypes.checkPhoneFailed, payload };
}
function checkPhone(payload = {}) {
    return dispatch => {

        dispatch(checkPhoneStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'checkPhone');
            form.append('mobilephone', payload.mobilephone);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(checkPhoneSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(checkPhoneFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(checkPhoneFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function checkEmailStarted(payload) {
    return { type: actionTypes.checkEmailStarted, payload };
}
function checkEmailSuccess(payload) {
    return { type: actionTypes.checkEmailSuccess, payload };
}
function checkEmailFailed(payload) {
    return { type: actionTypes.checkEmailFailed, payload };
}
function checkEmail(payload = {}) {
    return dispatch => {

        dispatch(checkEmailStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            // TODO: 記得改API_URL與form內容
            let form = new FormData();
            form.append('op', 'checkEmail');
            form.append('email', payload.email);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(checkEmailSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(checkEmailFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(checkEmailFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function sendSmsAuthCodeStarted(payload) {
    return { type: actionTypes.sendSmsAuthCodeStarted, payload };
}
function sendSmsAuthCodeSuccess(payload) {
    return { type: actionTypes.sendSmsAuthCodeSuccess, payload };
}
function sendSmsAuthCodeFailed(payload) {
    return { type: actionTypes.sendSmsAuthCodeFailed, payload };
}
function sendSmsAuthCode(payload = {}) {
    return dispatch => {

        dispatch(sendSmsAuthCodeStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {


            let form = new FormData();
            form.append('op', 'sendSmsAuthCode');
            form.append('mobilephone', payload.mobilephone);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(sendSmsAuthCodeSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(sendSmsAuthCodeFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(sendSmsAuthCodeFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function verifyRegisterSmsStarted(payload) {
    return { type: actionTypes.verifyRegisterSmsStarted, payload };
}
function verifyRegisterSmsSuccess(payload) {
    return { type: actionTypes.verifyRegisterSmsSuccess, payload };
}
function verifyRegisterSmsFailed(payload) {
    return { type: actionTypes.verifyRegisterSmsFailed, payload };
}
function verifyRegisterSms(payload = {}) {
    return dispatch => {

        dispatch(verifyRegisterSmsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {
            
            let form = new FormData();
            form.append('op', 'verifyRegisterSms');
            form.append('mobilephone', payload.mobilephone);
            form.append('smsauth', payload.smsauth);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(verifyRegisterSmsSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(verifyRegisterSmsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(verifyRegisterSmsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function verifyRegisterEmailStarted(payload) {
    return { type: actionTypes.verifyRegisterEmailStarted, payload };
}
function verifyRegisterEmailSuccess(payload) {
    return { type: actionTypes.verifyRegisterEmailSuccess, payload };
}
function verifyRegisterEmailFailed(payload) {
    return { type: actionTypes.verifyRegisterEmailFailed, payload };
}
function verifyRegisterEmail(payload = {}) {
    return dispatch => {

        dispatch(verifyRegisterEmailStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            let form = new FormData();
            form.append('op', 'verifyRegisterEmail');
            form.append('mrscid', payload.mrscid);
            form.append('emailauth', payload.emailauth);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(verifyRegisterEmailSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(verifyRegisterEmailFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(verifyRegisterEmailFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

/**
 * 驗證身分證是否重複且是否實際存在
 * @param {object} payload
 *   @param {string} payload.memberid 會員帳號
 *   @param {string} payload.idNumber 身分證字號
 *   @param {number} payload.applyCode 領補換類別代碼 @see memberUtil.ID_CARD_STATUS
 *   @param {string} payload.applyYyymmdd 發證日期 yyyMMdd
 *   @param {string} payload.issueSiteId 發證地點行政區域代碼 @see memberUtil.DISTRICT_CODE
 * @author Ac
 * @time 2020 / 11 /12
 */
function verifyIdNumber(payload) {
    return () => {
        return new Promise((resolve, reject) => {

            const {
                memberid,
                idNumber,
                applyCode,
                applyYyymmdd,
                issueSiteId,
            } = payload;

            const url = '/MemberRegisterAction.do?op=verifyIdNumber';
            const formData = new FormData();
            memberid && formData.append('memberid', memberid);
            formData.append('idnumber', idNumber);
            formData.append('applyCode', applyCode);
            formData.append('applyYyymmdd', applyYyymmdd);
            formData.append('issueSiteId', issueSiteId);

            customAxios(
                url,
                {
                    method: 'POST',
                    body: formData,
                }
            ).then(response => {

                response.json().then(result => {

                    if (!result) {
                        reject();
                        return;
                    }

                    if(result.error) {
                        reject(result.error);
                        return;
                    }
                    
                    resolve(result);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        });
    };
}

const actions = {
    registerMember, // 會員註冊
    checkMemberID, // 驗證會員ID是否重複
    checkPhone, // 驗證手機號碼是否重複
    checkEmail, //  驗證email是否重複
    sendSmsAuthCode, // 發送簡訊驗證碼
    verifyRegisterSms, // 驗證註冊簡訊
    verifyRegisterEmail, // 驗證註冊email
    verifyIdNumber, // 驗證身分證是否重複且是否實際存在
};

export default actions;