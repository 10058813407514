/*=====================================
    PaymentInfo

    Author: Gray
    CreateTime: 2018 / 03 / 19
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, {
    useState,
    useEffect,
} from 'react';
import actionCreators from 'actions/creators';
import styled from 'styled-components';
import GLOBAL from 'nonnmwebglobal';
import { GlobalStyle, Colors, Button } from 'stylecomponents';
import TopbarView from 'components/commons/TopbarView';
import CupoyRouter from 'components/commons/router/CupoyRouter';
import CupoySrefView from 'components/commons/router/CupoySrefView';
import TimeFormat from 'components/commons/TimeFormat';
import CupoyImage from 'components/commons/CupoyImage';
import { createSelector } from 'reselect';
import useRedux from 'actions/hooks/useRedux';
import { useParams, useRouteMatch } from 'react-router';

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
    padding: 16px;
`;
const Description = styled.div`
    line-height: 26px;
    font-size: 12px;
    color: #595A59;
    letter-spacing:1px;
`;
const HighLightText = styled.span`
    font-size: 20px;
    color: ${Colors.Blue};
`;
const ColorText = styled.span`
    font-size: 20px;
    color: ${(props) => props.color ? props.color : 'inherit'};
`;
const SubmitButton = styled(Button.Orange)`
    position: relative;
    width: 100%;
    height: 44px;
    line-height: 44px;
    margin: 16px 0 0 0;
    font-size: 16px;
`;
const SubmitLoading = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    left: 16px;
    height: 100%;
    align-items: center;
    justify-content: center;
`;
/*--------------------------
    Main Component
--------------------------*/
const PaymentInfoView = props => {

    const { id } = useParams();
    const isAtProductPaymentInfo = !!useRouteMatch({ path: CupoyRouter.STATES.PRODUCT_PAYMENT_INFO });

    let isFromCart = true;

    if(isAtProductPaymentInfo) {
        isFromCart = false;
    }

    const selector = createSelector(
        state => state.cardcart.cartOrder,
        state => state.vitem.cartOrder,
        state => state.web.ALLPAY,
        state => state.web.ECPAY,
        // state => state.web.CATHYATM,
        (
            cartOrder,
            vitemCartOrder,
            ALLPAY,
            ECPAY
            // CATHYATM
        ) => ({
            cartOrder: isFromCart ? cartOrder : vitemCartOrder,
            ALLPAY,
            ECPAY,
            // CATHYATM,
        })
    );

    const [{
        cartOrder,
        ALLPAY,
        ECPAY,
        // CATHYATM,
    }, {
        webActions,
    }] = useRedux(selector, {
        webActions: actionCreators.webActionCreators,
    });

    const [isPaying, setIsPaying] = useState(false);

    const payByECPayATM = () => {

        const formInfo = JSON.parse(cartOrder.allpaycheckoutstruct.ecpayjsonform);

        setIsPaying(true);
        webActions.payByECPayATM(formInfo);
    };

    const payByCreditCard = () => {
        setIsPaying(true);
        webActions.payByCreditCard(cartOrder.allpaycheckoutstruct);
    };

    // const payByCathyATM = () => {
    //     setIsPaying(true);
    //     webActions.payByCathyATM(cartOrder.cathyatmcheckoutstruct);
    // };

    const checkWebState = () => {
        webActions.checkWebState();
    };

    useEffect(() => {

        // 再次check後台金流開放狀態
        checkWebState();

        if(!cartOrder || id !== cartOrder.cartid) {
            isFromCart
                ? webActions.cupoyStateReplace(CupoyRouter.STATES.MEMBER_CARD_ORDER_UNPAY_DETAIL, { id })
                : webActions.cupoyStateReplace(CupoyRouter.STATES.MEMBER);
            return;
        }
    }, [isFromCart]);

    const renderContent = () => {

        const createtime = cartOrder.createtime;
        const payendtime = cartOrder.createtime + 3 * 1000 * 60 * 60;
        const nowtime = Date.now();
        const overPayLimit = nowtime > payendtime;

        if(overPayLimit) {
            return (
                <ColorText>訂單已逾時</ColorText>
            );
        }

        switch (cartOrder.paytype) {
            case GLOBAL.PAYTYPE.ESUN:
                return (
                    <Content>
                        <Description>付款金額: <ColorText color={Colors.Orange}>{cartOrder.totalamt}</ColorText>元</Description>
                        <Description>銀行代碼: <HighLightText>808 (玉山銀行)</HighLightText></Description>
                        <Description>銀行帳號: <HighLightText>{cartOrder.accountid}</HighLightText></Description>
                        <Description>付款有效時間: <TimeFormat format="simpleFullTime" time={createtime} /> ~ <TimeFormat format="simpleFullTime" time={payendtime} /></Description>
                    </Content>
                );
            case GLOBAL.PAYTYPE.MEGA:
                return (
                    <Content>
                        <Description>付款金額: <ColorText color={Colors.Orange}>{cartOrder.totalamt}</ColorText>元</Description>
                        <Description>銀行代碼: <HighLightText>017 (兆豐銀行)</HighLightText></Description>
                        <Description>銀行帳號: <HighLightText>{cartOrder.accountid}</HighLightText></Description>
                        <Description>付款有效時間: <TimeFormat format="simpleFullTime" time={createtime} /> ~ <TimeFormat format="simpleFullTime" time={payendtime} /></Description>
                    </Content>
                );
            case GLOBAL.PAYTYPE.FAMIPORT:
                return (
                    <Content>
                        <Description>付款金額: <ColorText color={Colors.Orange}>{cartOrder.totalamt}</ColorText>元</Description>
                        <Description>繳費代碼: <HighLightText>{cartOrder.accountid}</HighLightText></Description>
                        <Description>付款有效時間: <TimeFormat format="simpleFullTime" time={createtime} /> ~ <TimeFormat format="simpleFullTime" time={payendtime} /></Description>
                        <div>
                        <CupoyImage
                            style={{width: '100%'}}
                            src={`${GLOBAL.URL_PREFIX}/images/famiport_howto.jpg`}
                        />
                        </div>
                    </Content>
                );
            case GLOBAL.PAYTYPE.HILIFE:
                return (
                    <Content>
                        <Description>付款金額: <ColorText color={Colors.Orange}>{cartOrder.totalamt}</ColorText>元</Description>
                        <Description>訂單編號: <HighLightText>{GLOBAL.getHILIFEPaymentId(cartOrder.cartid)}</HighLightText>(操作時需輸入)</Description>
                        <Description>付款有效時間: <TimeFormat format="simpleFullTime" time={createtime} /> ~ <TimeFormat format="simpleFullTime" time={payendtime} /></Description>
                        <div>
                        <CupoyImage
                            style={{width: '100%'}}
                            src={`${GLOBAL.URL_PREFIX}/images/9199_hilife.jpg`}
                        />
                        </div>
                    </Content>
                );
            case GLOBAL.PAYTYPE.IBON:
                return (
                    <Content>
                        <Description>付款金額: <ColorText color={Colors.Orange}>{cartOrder.totalamt}</ColorText>元</Description>
                        <Description>付款有效時間: <TimeFormat format="simpleFullTime" time={createtime} /> ~ <TimeFormat format="simpleFullTime" time={payendtime} /></Description>
                        <div>
                        <CupoyImage
                            style={{width: '100%'}}
                            src={`${GLOBAL.URL_PREFIX}/images/ibon_howto.jpg`}
                        />
                        </div>
                    </Content>
                );
            case GLOBAL.PAYTYPE.ALLPAY_CREDITCARD:
                return (
                    <Content>
                        <Description>訂單金額: <ColorText color={Colors.Orange}>{cartOrder.totalamt}</ColorText>元</Description>
                        <Description>刷卡手續費: <ColorText color={Colors.Orange}>{cartOrder.allpaycheckoutstruct.TotalAmount - cartOrder.totalamt}</ColorText>元</Description>
                        <Description>刷卡金額: <ColorText color={Colors.Orange}>{cartOrder.allpaycheckoutstruct.TotalAmount}</ColorText>元</Description>
                        <Description>9199信用卡交易編號: <HighLightText>{cartOrder.allpaycheckoutstruct.MerchantTradeNo}</HighLightText></Description>
                        <Description>付款有效時間: <TimeFormat format="simpleFullTime" time={createtime} /> ~ <TimeFormat format="simpleFullTime" time={payendtime} /></Description>
                        {ALLPAY && cartOrder.allpaycheckoutstruct && (
                            <SubmitButton
                                onClick={payByCreditCard}
                                disable={isPaying}
                            >
                                {isPaying && <SubmitLoading><GlobalStyle.Spin color="#fff"/></SubmitLoading>}
                                前往付款
                            </SubmitButton>
                        )}
                    </Content>
                );
            case GLOBAL.PAYTYPE.CATHYATM:
                return (
                    <Content>
                        <Description>訂單金額: <ColorText color={Colors.Orange}>{cartOrder.totalamt}</ColorText>元</Description>
                        <Description>9199交易編號: {cartOrder.cathyatmcheckoutstruct.transno}</Description>
                        <Description>銀行代碼: <HighLightText>013 (國泰世華銀行)</HighLightText></Description>
                        <Description>銀行帳號: <HighLightText>{cartOrder.accountid}</HighLightText></Description>
                        <Description>付款有效時間: <TimeFormat format="simpleFullTime" time={createtime} /> ~ <TimeFormat format="simpleFullTime" time={payendtime} /></Description>
                        {/* {CATHYATM && cartOrder.cathyatmcheckoutstruct && (
                            <SubmitButton
                                onClick={payByCathyATM}
                                disable={isPaying}
                            >
                                {isPaying && (
                                    <SubmitLoading>
                                        <GlobalStyle.Spin color="#fff"/>
                                    </SubmitLoading>
                                )}
                                前往付款
                            </SubmitButton>
                        )} */}
                    </Content>
                );
            case GLOBAL.PAYTYPE.ECPAYATM:
                return (
                    <Content>
                        <Description>訂單金額: <ColorText color={Colors.Orange}>{cartOrder.totalamt}</ColorText>元</Description>
                        <Description>9199交易編號: {cartOrder.allpaycheckoutstruct.MerchantTradeNo}</Description>
                        <Description>付款有效時間: <TimeFormat format="simpleFullTime" time={createtime} /> ~ <TimeFormat format="simpleFullTime" time={payendtime} /></Description>
                        {ECPAY && (
                            <SubmitButton
                                onClick={payByECPayATM}
                                disable={isPaying}
                            >
                                {isPaying && (
                                    <SubmitLoading>
                                        <GlobalStyle.Spin color="#fff"/>
                                    </SubmitLoading>
                                )}
                                前往付款
                            </SubmitButton>
                        )}
                    </Content>
                );
            case GLOBAL.PAYTYPE.ALLPAY_BANK:
            case GLOBAL.PAYTYPE.ALLPAY_STORE:
            default:
                return null;
        }
    };

    if(!cartOrder) {
        return null;
    }

    const paytypeString = GLOBAL.PAYTYPE_STRING[cartOrder.paytype];

    return (
        <GlobalStyle.Container
            hasFixedTopbar
            hasFixedTabbar={false}
            backgroundColor='#FFF'
        >
            <TopbarView
                isFixed
                leftComponent={
                    <CupoySrefView
                        statename={CupoyRouter.STATES.MEMBER}
                        stylecomponent={GlobalStyle.TopbarButton}
                    >
                        <GlobalStyle.TopbarIcon>
                            <i className="zmdi zmdi-chevron-left"></i>
                        </GlobalStyle.TopbarIcon>
                        返回
                    </CupoySrefView>
                }
                middleComponent={paytypeString}
            />
            {renderContent()}
        </GlobalStyle.Container>
    );
};

/*--------------------------
    Export
--------------------------*/
export default PaymentInfoView;