/*=====================================
    會員登入頁

    Author: Eileen Liao
    createtime: 2018 / 02 / 28
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import Recaptcha from "react-grecaptcha";
import MediaQuery from 'react-responsive';
import GLOBAL from '../../nonnmwebglobal';
import { GlobalStyle, Colors, MemberStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import styled from 'styled-components';

/*--------------------------
    Style Component
--------------------------*/
const Link = styled.span`
    color: ${Colors.Orange};
    text-align: center;

    &:hover {
        color: ${Colors.Orange};
        text-decoration: underline;
    }
`;
const SubmitLoading = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    left: 16px;
    height: 100%;
    align-items: center;
    justify-content: center; 
`;
const Hint = styled.div`
    text-align: center;
    font-size: 14px;
    color: #4A4A4A;
    margin-top: 10px;
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberLoginView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            memberId:'',
            password:'',
            recaptchaResponse: '',
            isLoading: false,
            isNeedToCheckIdNumber: false,
            idNumber: '',
        };

        this.callback = this.callback.bind(this);
        this.expiredCallback = this.expiredCallback.bind(this);
        this.login = this.login.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    callback (response)  {

        this.setState({
            recaptchaResponse: response,
        });
    }

    expiredCallback () {
        this.setState({
            recaptchaResponse: '',
            
        });
        this.props.webActions.openAlertDialog({
            title: '安全認證已過期',
        });
    }

    handleChange(event){
        var elename = event.target.name;
        if(elename === 'id'){
            this.setState({memberId:event.target.value});

        }
        else if(elename === 'pwd'){
            this.setState({password:event.target.value});
            
        }

    }

    loginCallback = () => {

        const history = CupoyRouter.getHistory();
        var prev;

        if(Array.isArray(history) && history.length > 1) {
            const len = history.length;

            for(var i = len - 1; i >= 0; i--) {
                prev = history[i];

                if(prev && prev.state.name !== CupoyRouter.STATES.MEMBERLOGIN) {
                    break;
                }

                prev = undefined;
            }
        }

        if(prev) {
            this.props.webActions.cupoyStateGo(prev.state.name, prev.params, prev.querys, prev.locationState);
        } else {
            this.props.webActions.cupoyStateGo(CupoyRouter.STATES.MEMBER);
        }
    }

    login() {

        const{
            memberId,
            password,
            recaptchaResponse,
            isLoading,
        } = this.state;

        if(isLoading) {
            return;
        }

        if(GLOBAL.isEmpty(memberId) || GLOBAL.isEmpty(password)){
            this.props.webActions.openAlertDialog({                    
                title: '錯誤訊息',
                description : '請輸入帳號或密碼',
                closeName: '確定',
                closeCallback: () => {
                }
            });
            return;
        }
        
        if(!GLOBAL.isAppFile && GLOBAL.isEmpty(recaptchaResponse)){
            this.props.webActions.openAlertDialog({                    
                title: '錯誤訊息',
                description : '請勾選『我不是機器人』',
                closeName: '確定',
                closeCallback: () => {
                    
                }
            });
            return;
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.memberActions.login({
                memberid: memberId,
                passwd: password,
                passcode: recaptchaResponse
            }).then(this.loginCallback, (error) =>{

                this.setState({
                    isLoading: false,
                    recaptchaResponse: '',
                }, () => {

                    !GLOBAL.isAppFile && window.grecaptcha.reset();

                    let errorMsg = '系統發生無法預期的錯誤';

                    if(error && error.code) {    
                        switch(error.code) {
                            case 'error.onemonthNoLoginError':
                                this.setState({
                                    isNeedToCheckIdNumber: true,
                                });
                                return;
                            case 'error.account.accountNotExist':
                                errorMsg = "此帳號不存在";
                                break;
                            case 'error.account.isFreeze':
                                return this.props.webActions.openAlertDialog({
                                    title: '錯誤訊息',
                                    renderDescription: closeDialog => (
                                        <React.Fragment>
                                            <span>會員停權中，請洽客服人員。</span><br/>
                                            <a
                                                onClick={() => {
                                                    closeDialog();
                                                    this.props.webActions.cupoyStateGo(CupoyRouter.STATES.NEWSCONTENT, { id: '1590395459418' });
                                                }}
                                            >
                                                查看停權原因！
                                            </a>
                                        </React.Fragment>
                                    ),
                                    closeName: '確定',
                                });
                            case 'error.formValidator.passwordFailed':
                                errorMsg = "密碼錯誤，請重新操作。";
                                break;
                            case 'error.formValidator.antiPassCodeFailed':
                                errorMsg = "安全性驗證錯誤";
                                break;
                            default:
                                errorMsg = error.message;
                                break;
                        }
                    }

                    this.props.webActions.openAlertDialog({                    
                        title: '錯誤訊息',
                        description : errorMsg,
                        closeName: '確定',
                    });
                });
            });
        })        
    }

    handleIdNumber = event => {
        this.setState({
            idNumber: event.target.value,
        });
    }

    loginAndCheckId = () => {

        const {
            memberId,
            password,
            idNumber,
            isLoading,
        } = this.state;

        if(isLoading) {
            return;
        }

        const {
            result: chkResult,
            message: chkResultMsg,
        } = GLOBAL.chkIdnumber(idNumber);

        if (!chkResult) {
            this.props.webActions.openAlertDialog({
                title: '錯誤訊息',
                description : chkResultMsg,
                closeName: '確定',
            });
            return;
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.memberActions.loginAndCheckId({
                memberId,
                password,
                idNumber,
            }).then(() => {
                this.loginCallback();
            }).catch(error => {

                this.setState({
                    isLoading: false,
                });

                this.props.webActions.openAlertDialog({
                    title: '錯誤訊息',
                    description : error && error.message
                        ? error.message
                        : '系統發生無法預期的錯誤，請聯絡客服人員或稍後再試',
                    closeName: '確定',
                });
            });
        });
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            isLoading,
            isNeedToCheckIdNumber,
            idNumber,
        } = this.state;

        return (
            <GlobalStyle.Container hasFixedTopbar={true} hasFixedTabbar={false}>
            
            <TopbarView
                isFixed={true}
                leftComponent={
                    <CupoySrefView 
                        statename={CupoyRouter.STATES.MEMBER}
                        stylecomponent={GlobalStyle.TopbarButton}
                    >   
                        <GlobalStyle.TopbarIcon>
                            <i className="zmdi zmdi-chevron-left"></i>
                        </GlobalStyle.TopbarIcon>
                        返回
                    </CupoySrefView>
                }
                middleComponent="會員登入"
            />

            <MemberStyle.MemberContent>
                {isNeedToCheckIdNumber
                    ? (
                        <>
                            <MemberStyle.InputContainer margin="0">
                                <MemberStyle.FieldTitle>身分證字號</MemberStyle.FieldTitle>
                                <MemberStyle.MemberInputBox2
                                    placeholder="請輸入身分證字號"
                                    type="text"
                                    value={idNumber}
                                    onChange={this.handleIdNumber}
                                />
                                <Hint>
                                    您的帳號超過一個月未登入，為了您的帳號安全，請輸入您的身分證字號進行驗證！
                                </Hint>
                                <MemberStyle.ConfirmBtn
                                    onClick={this.loginAndCheckId}
                                    disable={isLoading}
                                >
                                    {isLoading && (
                                        <SubmitLoading>
                                            <GlobalStyle.Spin color="#fff"/>
                                        </SubmitLoading>
                                    )}
                                    送出
                                </MemberStyle.ConfirmBtn>
                            </MemberStyle.InputContainer>
                        </>
                    ) : (
                        <>
                            <MemberStyle.InputContainer margin="0">
                                <MemberStyle.FieldTitle>帳號</MemberStyle.FieldTitle>
                                <MemberStyle.MemberInputBox2 placeholder="請輸入帳號" type="text" name="id"
                                    ref={(input) => { this.idInput = input; }} onChange={this.handleChange}/>
                            </MemberStyle.InputContainer>
                            <MemberStyle.InputContainer>
                                <MemberStyle.FieldTitle>密碼</MemberStyle.FieldTitle>
                                <MemberStyle.MemberInputBox2 placeholder="請輸入密碼" type="password" name="pwd"  onChange={this.handleChange}/>
                            </MemberStyle.InputContainer>

                            {!GLOBAL.isAppFile && (
                                <MemberStyle.InputContainer>
                                    <MemberStyle.FieldTitle>安全性驗證</MemberStyle.FieldTitle>
                                    <MediaQuery minWidth={0} maxWidth={360}>
                                        <Recaptcha
                                            sitekey={GLOBAL.grecaptcha_key}
                                            callback={this.callback}
                                            expiredCallback={this.expiredCallback}
                                            locale="zh-TW"
                                            className="g-recaptcha"
                                            style={{
                                                transform: 'scale(0.9)',
                                                WebkitTransform: 'scale(0.9)',
                                                transformOrigin: '0 0',
                                                WebkitTransformOrigin: '0 0',
                                            }}
                                        />

                                    </MediaQuery>
                                    <MediaQuery minWidth={361}>
                                        <Recaptcha
                                            sitekey={GLOBAL.grecaptcha_key}
                                            callback={this.callback}
                                            expiredCallback={this.expiredCallback}
                                            locale="zh-TW"
                                            className="g-recaptcha"
                                        />
                                    </MediaQuery>
                                </MemberStyle.InputContainer>
                            )}

                            <MemberStyle.ConfirmBtn onClick={this.login} disable={isLoading}>
                                {isLoading && (
                                    <SubmitLoading>
                                        <GlobalStyle.Spin color="#fff"/>
                                    </SubmitLoading>
                                )}
                                登入
                            </MemberStyle.ConfirmBtn>

                            <CupoySrefView
                                statename={CupoyRouter.STATES.FORGOT_ACCOUNT}
                                stylecomponent={MemberStyle.InputContainer}
                            >
                                <Link>忘記帳號</Link>
                            </CupoySrefView>

                            <CupoySrefView
                                statename={CupoyRouter.STATES.FORGETPWD}
                                stylecomponent={MemberStyle.InputContainer}
                            >
                                <Link>忘記密碼</Link>
                            </CupoySrefView>

                            <CupoySrefView
                                statename={CupoyRouter.STATES.REGISTER}
                                stylecomponent={MemberStyle.InputContainer}
                            >
                                <Link>免費註冊</Link>
                            </CupoySrefView>
                        </>
                    )
                }
            </MemberStyle.MemberContent>
 
         </GlobalStyle.Container>
            
        );
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin : state.member.haslogin,
        
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
        
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberLoginView);