export default {
	storeScrollPosition: 'storeScrollPosition',
	storeRenderedIndex: 'storeRenderedIndex',
	openConfirmDialog: 'openConfirmDialog',
	closeConfirmDialog: 'closeConfirmDialog',
	openAlertDialog: 'openAlertDialog',
	closeAlertDialog: 'closeAlertDialog',
	openInvoiceDetailDialog : 'openInvoiceDetailDialog',
	closeInvoiceDetailDialog : 'closeInvoiceDetailDialog',
	checkWebStateSuccess: 'checkWebStateSuccess',
	openBuyBonusPdtDialog: 'openBuyBonusPdtDialog',
	closeBuyBonusPdtDialog: 'closeBuyBonusPdtDialog',
	showSimpleAlertDialog: 'showSimpleAlertDialog',
	setPlatform: 'setPlatform',
};
