import React from 'react';
import Dialog from './Dialog';
import Portal from 'rc-util/lib/Portal';

class DialogWrap extends React.Component {

  static defaultProps = {
    visible: false,
  };

  constructor(props) {
    super(props);
    this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);
    this.saveDialog = this.saveDialog.bind(this);
    this.getComponent = this.getComponent.bind(this);
    this.getContainer = this.getContainer.bind(this);
  }

  shouldComponentUpdate({ visible }) {
    return !!(this.props.visible || visible);
  }

  saveDialog(node) {
    this._component = node;
  }

  getComponent(extra) {
    return (
      <Dialog
        ref={this.saveDialog}
        {...this.props}
        {...extra}
        key="dialog"
      />
    );
  }

  getContainer() {
    if (this.props.getContainer) {
      return this.props.getContainer();
    }
    const container = document.createElement('div');
    document.body.appendChild(container);
    return container;
  }

  render() {

    const { visible } = this.props;

    let portal: any = null;

    if (visible || this._component) {
      portal = (
        <Portal getContainer={this.getContainer}>
          {this.getComponent()}
        </Portal>
      );
    }

    return portal;
  }
}

export default DialogWrap;
