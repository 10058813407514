/*=====================================
    首頁

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import TopbarView from "components/commons/TopbarView";
import { GlobalStyle } from 'stylecomponents';
import styled from 'styled-components';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyNoteContent from "components/commons/CupoyNoteContent";

/*--------------------------
    styled
--------------------------*/

const ActivityContent = styled.div`

    padding: 16px;
    background-color: #fff;

`;

const LoadingContent = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 44px);
    background-color: #fff;

`;

const TopbarMiddle = styled.h3`

    height: 100%;
    line-height: 44px;
    margin: 0;
    font-size: 16px;
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
    color: #FFFFFF;
    text-align: center;

`;

/* 最新消息: */
/*--------------------------
    Main Component
--------------------------*/

class GoodPromotionsView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {
        super(props);

        const routerState = CupoyRouter.getCurrent();
        const { params } = routerState;
        const { eventid, bulletinid } = params;

        this.state = {
            eventid,
            bulletinid,
        };
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        const { eventid, bulletinid } = this.state;
        
        this.props.profitEventActions.logProfitEventClick({
            eventid,
        });
        this.props.bulletinActions.getBulletinDetail({
            bulletinid,
        });
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {
        const { bulletinid } = this.state;
        
        const {
            title,
            bulletincontent,
        } = this.props.itemMap[bulletinid] ? 
            this.props.itemMap[bulletinid]
            : {title: '發生錯誤',
                bulletincontent: '<h3>(測試中無資料)無法取得好康活動內容，請重新整理後再嘗試。</h3>'
            };

        return (
            <GlobalStyle.Container
                hasFixedTabbar={true}
                hasFixedTopbar={true}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.GOODPROMOTIONS}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            好康活動
                        </CupoySrefView>
                    }
                    middleComponent={
                        <TopbarMiddle>
                            {title}
                        </TopbarMiddle>
                    }
                />
                <ActivityContent>
                    {!this.props.isloading ?
                        <CupoyNoteContent html={bulletincontent}/>
                        :
                        null
                    }
                    {this.props.isloading ?
                            <LoadingContent>
                                <GlobalStyle.Spin />
                            </LoadingContent>
                        :
                        null
                    }
                </ActivityContent>
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        itemMap: state.profitevent.itemMap,
        isloading: state.profitevent.isloading,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        profitEventActions: bindActionCreators(actionCreators.profitEventActionCreators, dispatch),
        bulletinActions: bindActionCreators(actionCreators.bulletinActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(GoodPromotionsView);