/*=====================================
    CupoyNoteContent

    Author: Gray
    CreateTime: 2020 / 04 / 15
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { useRef } from "react";
import PropTypes from 'prop-types';
import mobileUtil from 'util/mobileUtil';
import actionCreators from 'actions/creators';
import useActions from 'actions/hooks/useActions';

/*--------------------------
    Main Component
--------------------------*/
const CupoyNoteContent = props => {

    const {
        html,
    } = props;

    const {
        webActions
    } = useActions({
        webActions: actionCreators.webActionCreators,
    });

    const contentRef = useRef(null);

    const initRef = ref => {

        if(!ref) {
            return;
        }

        contentRef.current = ref;
        
        const handleEvent = event => {
            mobileUtil.handleOpenLinkBridge(event, webActions);
        };
        
        setTimeout(() => {
            
            const atags = contentRef.current.querySelectorAll('a');

            if(atags) {
                atags.forEach((item) => {
                    item.setAttribute('target', '_blank');
                    item.setAttribute('rel', 'noopener noreferrer nofollow');
                    item.onclick = handleEvent;
                });
            }
        });
    };

    return (
        <div ref={initRef}>
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    );
};

CupoyNoteContent.propTypes = {
    html: PropTypes.string,
};

export default CupoyNoteContent;