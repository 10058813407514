import React, {
    useRef,
    useState,
    useEffect,
} from 'react';
import styled from 'styled-components';
import useRedux from 'actions/hooks/useRedux';
import { createSelector } from 'reselect';
import actionCreators from 'actions/creators';
import GLOBAL from 'nonnmwebglobal';
import CupoyRouter from 'components/commons/router/CupoyRouter';
import { GlobalStyle, Colors, Button, DialogStyle } from 'stylecomponents';

/**
 * Styled
 */
const Content = styled.div``;
const Title = styled.h2`
	line-height: 1.8em;
	margin-bottom: 12px;
	color: ${Colors.Dark};
	text-align: center;
	font-weight: 700;
	font-size: 20px;
`;
const Description = styled.p`
	line-height: 1.6em;
	margin: 0;
	color: ${Colors.Dark2};
	text-align: center;
	font-size: 14px;
`;
const FormInput = styled.label`
	display: block;
	position: relative;
    margin: 24px 0 10px 0;
    
	> input {
		width: 100%;
		height: 45px;
		margin: 0;
        padding: 0 12px;
        font-size: 14px;
        line-height: 22px;
		border: 1px solid ${Colors.Dark5};
		color: ${Colors.Dark};
		background-color: #FAFAFA;
        font-weight: 300;
        border-radius: 3px;
    }
`;
const ErrorDesc = styled.div`
	color: ${Colors.Red};
    font-size: 14px;
    line-height: 22px;
    margin-top: 10px;
    text-align: center;
`;
const SubmitBtn = styled(Button.Orange)`
	width: 100%;
	height: 44px;
	line-height: 44px;
	margin: 10px 0 0 0;
	padding: 0;
`;

/**
 * 購買發財商品 dialog
 * @author Ac
 * @time 2020 / 06 / 18
 */
const BuyBonusPdtDialog = () => {

    const selector = createSelector(
        state => state.member.haslogin,
        state => state.member.memberinfo,
        state => state.web.buyBonusPdtDialog,
        (haslogin, memberinfo, buyBonusPdtDialog) => ({
            haslogin,
            memberinfo,
            productid: buyBonusPdtDialog.productid,
            jsdproductid: buyBonusPdtDialog.jsdproductid,
        })
    );

    const [{
        haslogin,
        memberinfo,
        productid,
        jsdproductid,
    }, {
        webActions,
        cardOrderActions,
    }] = useRedux(selector, {
        webActions: actionCreators.webActionCreators,
        cardOrderActions: actionCreators.cardOrderActionCreators,
    });

    const _isUnmount = useRef(false);
    const [idNumber, setIdNumber] = useState('');
    const [status, setStatus] = useState({
        isLoading: false,
        isIdNumberError: false,
        isError: false,
        error: null,
    });

    const disableSubmit = status.isLoading || !idNumber.trim();

    /**
     * 關閉dialog
     */
    const closeDialog = () => {
        webActions.closeBuyBonusPdtDialog();
    };

    /**
     * 購買免費商品
     */
    const submit = () => {

        if (disableSubmit) {
            return;
        }

        setStatus({
            isLoading: false,
            isIdNumberError: false,
            isError: false,
            error: null,
        });

        if (!GLOBAL.chkIdnumber(idNumber).result) {
            setStatus(prev => ({
                ...prev,
                isIdNumberError: true,
            }));
            return;
        }

        setStatus(prev => ({
            ...prev,
            isLoading: true,
        }));

        cardOrderActions.buyFreeCardProduct({
            productid,
            count: 1,
            jsdproductid,
            idnumber: idNumber,
        }).then(() => {

            if (!_isUnmount.current) {
                setStatus(prev => ({
                    ...prev,
                    isLoading: false,
                }));
            }

            closeDialog();

            webActions.openAlertDialog({
                title: '成功兌換商品',
                description: `跳轉至訂單記錄`,
                closeCallback: () => webActions.cupoyStateGo(CupoyRouter.STATES.MEMBER_BONUS_ORDER),
            });
        }).catch(error => {
            if (!_isUnmount.current) {
                setStatus(prev => ({
                    ...prev,
                    isLoading: false,
                    isError: true,
                    error,
                }));
            }
        });
    };

    /**
     * 取得錯誤訊息
     * @param {object} error 
     */
    const getErrorText = error => {

        if (!error || !error.message) {
            return '發生無法預期的錯誤，請重新整理稍後再試，或聯絡我們的客服人員';
        }

        let text = '';
        switch (error.code) {
            case 'error.nonnAccountNotValidatedError':
                text = '索取權限驗證失敗';
                break;
            case 'error.NonnAccountNotAllowError':
                text = '會員停權中';
                break;
            case 'error.wrongIDNoError':
                text = '身分證號碼與註冊資料不符合';
                break;
            case '-20011':
                text = '今日已兌換三次發財商城商品，無法再兌換，每人每日限兌換三次!';
                break;
            case '-20012':
                text = '發財金餘額不足!';
                break;
            default:
                text = error.message;
                break;
        }

        return text;
    };

    useEffect(() => {

        if (!haslogin) {
            closeDialog();
            webActions.needLogin();
            return;
        }

        if (!memberinfo || !memberinfo.idvalidflag) {
            closeDialog();
            webActions.openAlertDialog({
                title: '發生錯誤',
                description: '身分證未驗證',
            });
            return;
        }

        if(!memberinfo || !memberinfo.issmsauth) {
            closeDialog();
            webActions.openAlertDialog({
                title: '發生錯誤',
                description: '行動電話未驗證',
            });
            return;
        }
    
        if(!memberinfo || !memberinfo.isemailauth) {
            closeDialog();
            webActions.openAlertDialog({
                title: '發生錯誤',
                description: '電子信箱未驗證',
            });
            return;
        }

        return () => {
            _isUnmount.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [haslogin, memberinfo]);

    return (
        <DialogStyle.Content
            // style props
            width='calc(100% - 20px)'
            maxWidth='320px'
            padding='24px'
            borderRadius='3px'
            // dialog props
            visible
            animation='zoom'
            maskAnimation='fade'
            closable
            onClose={closeDialog}
            zIndex={3000}
        >
            <Content>
                        
                <Title>
                    兌換發財商品
                </Title>

                <Description>
                    請輸入身分證字號，<br/>
                    驗證成功後方可使用發財金兌換商品
                </Description>

                <FormInput>
                    <input
                        type='text'
                        placeholder='請輸入身分證字號'
                        value={idNumber}
                        onChange={event => setIdNumber(event.target.value)}
                    />
                </FormInput>

                {status.isIdNumberError && <ErrorDesc>身分證字號輸入錯誤</ErrorDesc>}
                {status.isError && <ErrorDesc>{getErrorText(status.error)}</ErrorDesc>}

                <SubmitBtn
                    disable={disableSubmit}
                    onClick={submit}
                >
                    {status.isLoading && <GlobalStyle.Spin color='#FFF' /> }
                    確認兌換
                </SubmitBtn>
            </Content>
        </DialogStyle.Content>
    );
};

export default BuyBonusPdtDialog;
