/*=====================================
    TimeFormat

    Author: Gray
    createtime: 2018 / 02 / 23 (copy from cupoy)
=====================================*/

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// eslint-disable-next-line
import twlocale from 'moment/locale/zh-tw'; // 不加下面這一行的話，在 format('LL') 的時候會跳錯誤

const momentConfig = {};
// moment.locale('zh-tw', twlocale);
// react 會一直要求使用下面者個method才對，不然會一直跳 warning
moment.updateLocale('zh-tw', momentConfig);

export default class TimeFormat extends PureComponent {
  static propTypes = {
    time: PropTypes.number.isRequired,
    format: PropTypes.string
  };

  static defaultProps = {
    format: 'timeAgo'
  };

  constructor(props) {
    super(props);
    this.formatTime = this.formatTime.bind(this);
  }

  formatTime() {
    const time = this.props.time;
    const aDay = 86400000;
    const date = new Date(time);
    const now = new Date();

    switch (this.props.format) {
      case 'timeAgo': {
        if (date.getFullYear() === now.getFullYear()) {
          if (now.getTime() - date.getTime() >= aDay) {
            return moment(time).format('L');
          } else {
            return moment(time).fromNow();
          }
        } else {
          return moment(time).format('LL');
        }
      }
      case 'simpleTimeAgo': {
        if(date.getFullYear() === now.getFullYear()){
            if (now.getTime() - date.getTime() >= aDay) {
                return moment(time).format('YYYY-MM-DD');
            } else{
                return moment(time).fromNow();
            }
        } else{
            return moment(time).format('YYYY-MM-DD');
        }
      }
      case 'simpleFullTime': {
        return moment(time).format('YYYY/MM/DD HH:mm:ss A');
      }
      case 'formatMediaPlayTime': {

        if (!isNaN(time) && time > 0) {
          var minutes = Math.floor(time / 60);
          var seconds = Math.round(time % 60);
          var hours = Math.floor(time / 3600);

          if(hours < 1) {
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            return minutes + ":" + seconds;
          } else {
            minutes = Math.round(minutes % 60);
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            return hours + ":" + minutes + ":" + seconds;
          }
          
        }

        return "--:--";
      }
      case 'timeString': {
        return moment(time).format('YYYY.MM.DD');
      }
      case 'timeStringDash': {
        return moment(time).format('YYYY-MM-DD');
      }
      default: 
        return "";
    }
  }

  render() {
    const formatted = this.formatTime();
    return <span>{formatted}</span>;
  }
}
