/*--------------------------
    Import 
--------------------------*/
import styled from 'styled-components';

/*--------------------------
    Style Variables
--------------------------*/

/*--------------------------
    Style Component
--------------------------*/

const TabActivityWrapper = styled.div`

    display: flex;
    background: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #DDDDDD;
    font-size: 14px;

`;

const TabActivityItem = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 50px;
    color: ${props => props.active ? '#F75C2F' : '#777777'};
    cursor: pointer;

`;

const TabActivity = {

    TabActivityWrapper,
    TabActivityItem,

}

export default TabActivity;
