import actionTypes from "actions/types/cardActionTypes";
import GLOBAL from '../../nonnmwebglobal';
import customAxios from 'api/customAxios';

const API_URL = GLOBAL.URL_PREFIX + '/CardAction.do';

// 列表商品
function listCardProductsStarted(payload) {
    return { type: actionTypes.listCardProductsStarted, payload };
}
function listCardProductsSuccess(payload) {
    return { type: actionTypes.listCardProductsSuccess, payload };
}
function listCardProductsFailed(payload) {
    return { type: actionTypes.listCardProductsFailed, payload };
}
function listCardProducts(payload = {}) {
    return dispatch => {

        dispatch(listCardProductsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'listCardProducts');
            form.append('start', payload.start);
            if(payload.productgrpid)
                form.append('productgrpid', payload.productgrpid);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listCardProductsSuccess({result, productgrpid: payload.productgrpid}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listCardProductsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listCardProductsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function getProductGroupsStarted(payload) {
    return { type: actionTypes.getProductGroupsStarted, payload };
}
function getProductGroupsSuccess(payload) {
    return { type: actionTypes.getProductGroupsSuccess, payload };
}
function getProductGroupsFailed(payload) {
    return { type: actionTypes.getProductGroupsFailed, payload };
}
function getProductGroups(payload = {}) {
    return dispatch => {

        dispatch(getProductGroupsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'getProductGroups');
            

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(getProductGroupsSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(getProductGroupsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(getProductGroupsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

// 列表免費索取商品
function listFreeProductsStarted(payload) {
    return { type: actionTypes.listFreeProductsStarted, payload };
}
function listFreeProductsSuccess(payload) {
    return { type: actionTypes.listFreeProductsSuccess, payload };
}
function listFreeProductsFailed(payload) {
    return { type: actionTypes.listFreeProductsFailed, payload };
}
function listFreeProducts(payload = {}) {
    return dispatch => {

        dispatch(listFreeProductsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'listFreeProducts');
            

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listFreeProductsSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listFreeProductsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listFreeProductsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}
function listVitemProductsStarted(payload) {
    return { type: actionTypes.listVitemProductsStarted, payload };
}
function listVitemProductsSuccess(payload) {
    return { type: actionTypes.listVitemProductsSuccess, payload };
}
function listVitemProductsFailed(payload) {
    return { type: actionTypes.listVitemProductsFailed, payload };
}
function listVitemProducts(payload = {}) {
    return dispatch => {

        dispatch(listVitemProductsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'listVitemProducts');
            

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listVitemProductsSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listVitemProductsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listVitemProductsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function listBonusProductsStarted(payload) {
    return { type: actionTypes.listBonusProductsStarted, payload };
}
function listBonusProductsSuccess(payload) {
    return { type: actionTypes.listBonusProductsSuccess, payload };
}
function listBonusProductsFailed(payload) {
    return { type: actionTypes.listBonusProductsFailed, payload };
}
function listBonusProducts(payload = {}) {
    return dispatch => {

        dispatch(listBonusProductsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'listBonusProducts');
            

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listBonusProductsSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listBonusProductsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listBonusProductsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}


function getProductInfoStarted(payload) {
    return { type: actionTypes.getProductInfoStarted, payload };
}
function getProductInfoSuccess(payload) {
    return { type: actionTypes.getProductInfoSuccess, payload };
}
function getProductInfoFailed(payload) {
    return { type: actionTypes.getProductInfoFailed, payload };
}
function getProductInfo(payload = {}) {
    return dispatch => {

        dispatch(getProductInfoStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'getProductInfo');
            form.append('productid', payload.productid);

            customAxios(API_URL, {
                method: "POST",
                body: form
            }).then(response => {
                    
                if (response.status === 503) {
                    // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                    reject({
                        error: 'error.503error',
                        message: '伺服器沒有開啟'
                    });

                    return;
                }
                response.json().then(result => {

                    if (!result) {
                        reject();
                        return;
                    }

                    if(result.error) {
                        reject(result.error);
                        return;
                    }

                    dispatch(getProductInfoSuccess(result));
                    resolve(result);
                })
                .catch(error => {
                    dispatch(getProductInfoFailed(error));
                    reject(error);
                });
            }).catch(error => {
                // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                dispatch(getProductInfoFailed(error));
                reject(error);
            });
        });
        return promise;
    };
}


function listRecommendedProductsStarted(payload) {
    return { type: actionTypes.listRecommendedProductsStarted, payload };
}
function listRecommendedProductsSuccess(payload) {
    return { type: actionTypes.listRecommendedProductsSuccess, payload };
}
function listRecommendedProductsFailed(payload) {
    return { type: actionTypes.listRecommendedProductsFailed, payload };
}
function listRecommendedProducts(payload = {}) {
    return dispatch => {

        dispatch(listRecommendedProductsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {


            let form = new FormData();
            form.append('op', 'listRecommendedProducts');
            form.append('start', payload.start);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listRecommendedProductsSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listRecommendedProductsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listRecommendedProductsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function getIbonBlacklistStarted(payload) {
    return { type: actionTypes.getIbonBlacklistStarted, payload };
}
function getIbonBlacklistSuccess(payload) {
    return { type: actionTypes.getIbonBlacklistSuccess, payload };
}
function getIbonBlacklistFailed(payload) {
    return { type: actionTypes.getIbonBlacklistFailed, payload };
}
function getIbonBlacklist(payload = {}) {
    return dispatch => {

        dispatch(getIbonBlacklistStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            let form = new FormData();
            form.append('op', 'getIBonBlackList');

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response.json()
                        .then(result => {

                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            let _result = result;
                            if (!Array.isArray(_result)) {
                                _result = [];
                            }

                            dispatch(getIbonBlacklistSuccess({result: _result, ...payload}));
                            resolve(_result);
                        })
                        .catch(error => {
                            dispatch(getIbonBlacklistFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(getIbonBlacklistFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}



const actions = {
 
    listCardProducts, // 列表商品
    getProductGroups, // 取得左側商品分群
    listFreeProducts, // 列表免費索取商品
    listVitemProducts, // 列表虛寶包商品
    listRecommendedProducts, // 列表熱門商品
    listBonusProducts, // 列表發財商城商品
    getProductInfo, // 取的商品詳細資料
    getIbonBlacklist, // 取得ibon禁售清單

};

export default actions;