/*=====================================
    歷史紀錄

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle } from 'stylecomponents';
import styled from 'styled-components';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
import TimeFormat from "components/commons/TimeFormat";
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    styled
--------------------------*/
const Content = styled.div`
    padding: 0 0 10px 0;
`;
const RecordList = styled.div`

    margin-top: 10px;
    padding: 16px;
    min-height: 100px;
    background: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #EEEEEE, inset 0 1px 0 0 #EEEEEE;

`;

const MessageText = styled.div`

    margin-bottom: 7px;
    font-size: 16px;
    color: #333333;

`;

const TimeText = styled.div`

    font-size: 12px;
    color: #565151;

`;

/*--------------------------
    Main Component
--------------------------*/
class HistoryRecord extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            start: Array.isArray(this.props.loginhistorys) ? this.props.loginhistorys.length : 0,
            isLoading: false,
            isError: false,
            isEmpty: false,
            isNoMore: false,
        };

        this.listLoginHistorys = this.listLoginHistorys.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        if(prevProps.haslogin !== this.props.haslogin) {
            this.init(this.props);
        }
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props) {

        const {
            haslogin,
            loginhistorys,
        } = this.props;

        if(haslogin && ( !Array.isArray(loginhistorys) || loginhistorys.length === 0)) {
            this.listLoginHistorys();
        }
    }

    // ------------------------------
    // listLoginHistorys
    // ------------------------------
    listLoginHistorys() {

        const {
            start,
            isLoading,
            isEmpty,
            isNoMore,
            isError,
        } = this.state;

        if(isLoading || isEmpty || isNoMore || isError) {
            return;
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.memberActions.listLoginHistorys({
                start: start,
            }).then((result) => {
                const loginhistorys = this.props.loginhistorys;

                var newState = {
                    isLoading: false,
                    start: loginhistorys.length,
                };

                if(!Array.isArray(loginhistorys) || loginhistorys.length === 0) {
                    newState.isEmpty = true;
                } else if(start === newState.start) {
                    newState.isNoMore = true;
                }

                this.setState(newState);
            }, (error) => {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            })
        })
    }

    // ------------------------------
    // rowRenderer
    // ------------------------------
    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it),
        parent,
        measure,
        columnIndex,
        updateRowSize,
        measureCache,
        listWidth,
    }, width, listHeight){

        const {
            loginhistorys,
        } = this.props;

        const loginhistory = loginhistorys[index];

        if(!loginhistory) {
            return null;
        }

        return (
            <div
                key={index}
                style={style}
            >
                <RecordList>
                    <MessageText>
                        登入IP：{loginhistory.ip}
                    </MessageText>
                    <TimeText>
                        登入時間：<TimeFormat format="simpleFullTime" time={loginhistory.logintime}/>
                    </TimeText>
                    <TimeText>
                        登出時間：
                        {loginhistory.logouttime > 0 ?
                            <TimeFormat format="simpleFullTime" time={loginhistory.logouttime}/>
                            :
                            '尚未登出'
                        }
                    </TimeText>
                </RecordList>
            </div>
        )
    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {
        return <NeedLoginBlock />;
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            isLoading,
            isError,
            isEmpty,
        } = this.state;

        const {
            loginhistorys,
        } = this.props;

        return (
            <Content>
                {Array.isArray(loginhistorys) && loginhistorys.length > 0 ?
                    <CupoyListRepeat
                        listId={this.constructor.name}
                        rowRenderer={this.rowRenderer}
                        items={loginhistorys}
                        isLoading={isLoading}
                        useWindowScroll={true}
                        restoreWindowScroll={true}
                        loadMoreRows={() => this.listLoginHistorys()}
                    />
                    :
                    null
                }

                {isLoading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }

                {isError ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            發生錯誤
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無法取得歷程紀錄，請重新整理後再嘗試。
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }

                {isEmpty ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            無任何歷程紀錄
                        </GlobalStyle.ErrorContainerTitle>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }
            </Content>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            返回
                        </CupoySrefView>
                    }
                    middleComponent="歷程紀錄"
                /> 
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        loginhistorys: state.member.loginhistorys,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(HistoryRecord);