/*=====================================
    MemberVitemOrderSell

    Author: Gray
    CreateTime: 2018 / 03 / 15
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import styled from 'styled-components';
import Recaptcha from "react-grecaptcha";
import MediaQuery from 'react-responsive';
import GLOBAL from '../../../nonnmwebglobal';
import { GlobalStyle, Colors, Button } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
import TimeFormat from "components/commons/TimeFormat";
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/
const HeaderHeight = 50;
const Header = styled.div`
    display: flex;
    position: fixed;
    top: ${GlobalStyle.TopbarHeight}px;
    left: 0;
    width: 100%;
    height: ${HeaderHeight}px;
    background-color: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #DDDDDD;
    z-index: 1;
`;
const NavItem = styled.div`
    display: inline-block;
    width: 50%;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: ${(props) => props.active ? Colors.Orange : Colors.Dark5};
    text-align: center;
    cursor: pointer;
`;
const SubContent = styled.div`
    padding: ${HeaderHeight}px 0 0 0;
`;
const DetailContent = styled.div`
    background-color: #fff;
`;
const MainInfoContent = styled.div`
    padding: 16px;
`;
const MainTitle = styled.div`
    line-height: 21px;
    font-size: 15px;
    color: ${Colors.Dark3};
`;
const MainDescription = styled.div`
    line-height: 17px;
    margin: 4px 0 0 0;
    font-size: 12px;
    color: ${Colors.Dark5};
`;
const StatusContent = styled.div`
    display: flex;
    height: 76px;
    box-shadow: inset 0 -1px 0 0 #EEEEEE, inset 0 1px 0 0 #EEEEEE;
    align-items: center;
    justify-content: center;
`;
const StepBar = styled.div`
    position: relative;
    width: 70%;
    margin-top: 10px;
`;
const StepBarProgress = styled.div`
    position: relative;
    width: calc(100% - 50px);
    height: 1px;
    margin: 0 auto 10px auto;
    background-color: ${Colors.Orange};
`;
const StepList = styled.ul`
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    justify-content: space-between;
`;
const StepItem = styled.ul`
    display: inline-flex;
    position: relative;
    font-size: 12px;
    color: #777777;
    letter-spacing: 0;
    text-align: center;
    flex-wrap: wrap;
    flex-direction: column;

    &:after {
        content: '';
        position: absolute;
        top: -16px;
        left: calc(50% - 5px);
        width: 10px;
        height: 10px;
        border: 1px solid ${Colors.Orange};
        border-radius: 50%;
        background-color: ${(props) => props.active ? Colors.Orange : '#fff'};;
    }
`;
const InfoContent = styled.div`
    padding: 16px;
    box-shadow: inset 0 -1px 0 0 #EEEEEE;
`;
const InfoTitle = styled.div`
    line-height: 18px;
    margin: 0 0 4px 0;
    font-size: 13px;
    color: #777777;
    font-weight: 600;
`;
const InfoText = styled.div`
    line-height: 20px;
    font-size: 13px;
    color: #777777;
`;
const PaymentList = styled.div`
    display: flex;
`;
const PaymentRightText = styled.div`
    margin: 0 0 0 auto;
`;
const PaymentDivide = styled.div`
    width: 100%;
    height: 1px;
    margin: 10px 0;
    background-color: #DDD;
`;
const PriceLabel = styled.span`
    font-size: 12px;
    color: ${Colors.Orange};
`;
const PriceNumber = styled.span`
    font-size: 15px;
    color: ${Colors.Orange};
    font-weight: 600;
`;
const VitemPriceNumber = styled.span`
    font-size: 12px;
`;
const ColorText = styled.span`
    color: ${(props) => props.color ? props.color : 'inherit'};
`;
const GradeTitle = styled.div`
    padding: 0 0 16px 0;
    border-bottom: 1px solid #DDD;
    font-size: 20px;
    color: #333333;
`;
const SelectTitle = styled.div`
    margin: 16px 0 0 0;
    font-size: 12px;
    color: #232423;
`;
const SelectWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 44px;
    margin: 8px 0 0 0;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    background-color: #FAFAFA;
`;
const Select = styled.select`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 8px;
    border: none;
    background-color: #FAFAFA;
    font-size: 15px;
    color: #222328;
`;
const Textarea = styled.textarea`
    width: 100%;
    height: 80px;
    margin: 8px 0 0 0;
    padding: 12px;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    background-color: #FAFAFA;
    font-size: 15px;
    color: #222328;
    overflow: auto;

    ${(props) => {

        if(!props.error) {
            return ``;
        }

        return `
            border: 1px solid ${Colors.Red};
            color: ${Colors.Red};
        `;
    }}
`;
const SubmitButton = styled(Button.Orange)`
    position: relative;
    width: 100%;
    height: 44px;
    line-height: 44px;
    margin: 32px 0 0 0;
    font-size: 16px;
`;
const SubmitLoading = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    left: 16px;
    height: 100%;
    align-items: center;
    justify-content: center; 
`;
const CommentWrapper = styled.div`
    margin: 0 0 10px 0;
    padding: 16px;
    background-color: #FFFFFF;
`;
const CommentTitle = styled.div`
    font-size: 12px;
    color: #232423;
`;
const CommentTextarea = styled.textarea`
    width: 100%;
    height: 120px;
    margin: 8px 0 0 0;
    padding: 12px;
    border: 1px solid ${props => props.error ? Colors.Red : '#DDDDDD'};
    border-radius: 2px;
    background-color: #FAFAFA;
    font-size: 15px;
    color: ${props => props.error ? Colors.Red : '#222328'};;
`;
const GoogleCertified = styled.div`
    margin: 24px 0 0 0;
`;
const CommentList = styled.div`
    padding: 0 16px;
    background-color: #FFFFFF;  
`;
const CommentItem = styled.div`
    padding: 10px 0 18px 0;   
    &:not(:first-of-type) {
        border-top: 1px solid #DDD;
    }
`;
const CommentInfo = styled.div`
    display: flex;
    margin: 0 0 6px 0;
    font-size: 11px;
    color: #757474;
    justify-content: space-between;
`;
const CommentTime = styled.span`
    font-size: 11px;
    color: #B0ADAD;
`;
const CommentText = styled.div`
    font-size: 12px;
    color: #4A4A4A;
`;
const ErrorContainer = styled.div`
    width: 100%;
    padding: 30px 0;
    text-align: center;
`;
const ErrorContainerTitle = styled.div`
    padding: 0 20px;
    color: #4A4A4A;
    font-size: 14px;
`;

/*--------------------------
    Variable
--------------------------*/
const LAYOUT_TYPE = {
    DETAIL: 'detail',
    COMMENT: 'comment',
};
const REMARKTEMP = {
    1: '推薦動作迅速的賣家',
    2: '信用良好!',
    3: '效率差,不推!',
    4: '收到貨了,謝謝!',
};

/*--------------------------
    Main Component
--------------------------*/
class MemberVitemOrderSellView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            id: '',
            layout: '',
            grade: 1,
            remarkkey: 1,
            remark: REMARKTEMP[1],
            comment: '',
            recaptchaResponse: '',
            serverrole: '',
            ordertime: '',
            orderprocess: '',

            isLoading: false,
            isError: false,
            isLoaded: false,
            isAddGrade: false,
            isRemarkEmpty: false,
            isAddComment: false,
        };

        this.listOrderLogs = this.listOrderLogs.bind(this);
        this.renderDetailContent = this.renderDetailContent.bind(this);
        this.renderCommentContent = this.renderCommentContent.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onRemarkkeyChange = this.onRemarkkeyChange.bind(this);
        this.addGrade = this.addGrade.bind(this);
        this.grecaptchaCallback = this.grecaptchaCallback.bind(this);
        this.grecaptchaExpiredCallback = this.grecaptchaExpiredCallback.bind(this);
        this.addComment = this.addComment.bind(this);
        this.renderCommentList = this.renderCommentList.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props, true);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        this.init(this.props);
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props, isInit) {

        const current = CupoyRouter.getCurrent();
        const {
            params,
            querys,
        } = current;

        const id = params.id;
        var layout = '';

        if(querys) {
            layout = querys.layout;
        }

        switch(layout) {
            case LAYOUT_TYPE.DETAIL:
            case LAYOUT_TYPE.COMMENT:
                if(this.state.layout !== layout) {
                    this.setState({
                        layout: layout,
                    });
                }
                break;
            default:
                this.changeLayout(id, LAYOUT_TYPE.DETAIL);
                return;
        }
        
        if(this.state.isLoaded) {
            return;
        }

        if(!props.haslogin) {
            this.setState({
                id: id,
            });
        } else {
            this.initOrder(id);
        }
    }

    // ------------------------------
    // initOrder
    // ------------------------------
    initOrder(id) {

        if(this.state.isLoading || this.state.isError) {
            return;
        }

        this.setState({
            id: id,
            isLoading: true,
        }, () => {
            this.props.vitemOrderActions.getBuyVItemOrderInfo({
                orderid: id,
            }).then((result) => {
                this.setState({
                    isLoading: false,
                    isLoaded: true,
                });

                this.listOrderLogs(true);
            }, (error) => {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            })
        })
    }

    // ------------------------------
    // 取得留言
    // ------------------------------
    listOrderLogs(isFirstLoad) {

        const {
            id,
            isOrderLogsLoading,
            isOrderLogsEmpty,
            isOrderLogsNoMore,
            isOrderLogsError,
        } = this.state;

        if (isOrderLogsLoading || isOrderLogsEmpty || isOrderLogsNoMore || isOrderLogsError) {
            return;
        }

        const {
            orderMap,
        } = this.props;

        const order = orderMap[id];

        if(!order) {
            return;
        }

        var start = 0;

        if(Array.isArray(order.logs)) {
            start = order.logs.length;
        }

        this.setState({
            isOrderLogsLoading: true,
        }, () => {

            this.props.vitemOrderActions.listOrderLogs({
                orderid: id,
                start: start,
            }).then(() => {

                const {
                    orderMap,
                } = this.props;

                const order = orderMap[id];
                const logs = order.logs;

                var newState = Object.assign({}, this.state,  {
                    isOrderLogsLoading: false,
                });

                if (!Array.isArray(logs) || logs.length === 0) {
                    newState.isOrderLogsEmpty = true;
                    this.setState(newState);
                    return;
                }

                if (start === logs.length) {
                    newState.isOrderLogsNoMore = true;

                    if(isFirstLoad) {
                        newState.isLoading = false;
                        newState.isLoaded = true;
                        // 解析第一筆留言
                        // "<b>伺服器內角色</b> : test<br /><b>交易時間</b> : test<br /><b>交易過程詳述</b> : test"
                        // "<b>伺服器內角色</b> : 角色角色<br /><b>交易時間</b> : 11:00<br /><b>交易過程詳述</b> : 過程過程"
                        let firstIdx = 0;
                        
                        // if(logs.length < 2) {
                        //     newState.isOrderLogsEmpty = true;
                        // }

                        let firstLog = logs[firstIdx].txtcontent;
                        let textList = firstLog.split('<br />');
                        let text, index;

                        if(textList.length === 3) {
                            text = textList[0];
                            index = text.lastIndexOf(' : ');

                            if(index > -1) {
                                newState.serverrole = text.substr(index + 3);
                            }

                            text = textList[1];
                            index = text.lastIndexOf(' : ');

                            if(index > -1) {
                                newState.ordertime = text.substr(index + 3);
                            }

                            text = textList[2];
                            index = text.lastIndexOf(' : ');

                            if(index > -1) {
                                newState.orderprocess = text.substr(index + 3);
                            }   
                        }
                    }
                    this.setState(newState);
                } else if(isFirstLoad) {
                    this.setState(newState, () => {
                        this.listOrderLogs(isFirstLoad);
                    });
                } else {
                    this.setState(newState);
                }
            }, () => {
                this.setState({
                    isOrderLogsLoading: false,
                    isOrderLogsError: true,
                });
            });
        });
    }

    // ------------------------------
    // [private] 切換顯示類型
    // ------------------------------
    changeLayout(id, layout) {

        const params = {
            id: id,
        };

        const querys = {
            layout: layout,
        };

        this.props.webActions.cupoyStateReplace(CupoyRouter.STATES.MEMBER_VITEM_ORDER_SELL, params, querys);
    }

    // ------------------------------
    // Input value change
    // ------------------------------
    onInputChange(event, key) {

        this.setState({
            [key]: event.target.value,
        })
    }

    // ------------------------------
    // onRemarkkeyChange
    // ------------------------------
    onRemarkkeyChange(event) {

        const remarkkey = event.target.value;
        const remark = REMARKTEMP[remarkkey] || '';

        this.setState({
            remarkkey: Number(remarkkey),
            remark: remark,
        })
    }

    // ------------------------------
    // addGrade
    // ------------------------------
    addGrade() {
        
        const {
            id,
            isAddGrade,
            grade,
            remark,
        } = this.state;

        const {
            orderMap,
        } = this.props;

        const order = orderMap[id];

        if(isAddGrade || !order) {
            return;
        }

        const {
            viprovid,
            gamename,
            servername,
            amount,
        } = order;
        
        if(!remark || !remark.trim()) {
            this.setState({
                isRemarkEmpty: true,
            })
            return;
        }

        this.setState({
            isAddGrade: true,
            isRemarkEmpty: false,
        }, () => {
            this.props.vitemOrderActions.addGrade({
                orderid: id,
                viprovid: viprovid,
                grade: grade,
                gamename: gamename,
                servername: servername,
                amount: amount,
                remark: remark,
            }).then((result) => {

                this.setState({
                    isAddGrade: false
                });

                // 更新資料
                this.props.vitemOrderActions.getBuyVItemOrderInfo({
                    orderid: id,
                })

                this.props.webActions.openAlertDialog({
                    title: '評價已送出',
                    closeName: '關閉',
                });

            }, (error) => {

                var errorMsg = '發生無法預期的錯誤';
                var errorDescription = '';

                if(error && error.code) {
                    errorMsg = error.message;
                }

                this.props.webActions.openAlertDialog({
                    title: errorMsg,
                    description: errorDescription,
                });

                this.setState({
                    isAddGrade: false,
                });
            });
        })
    }

    // ------------------------------
    // google callback
    // ------------------------------
    grecaptchaCallback(response) {

        this.setState({
            recaptchaResponse: response,
        });
    }

    // ------------------------------
    // google expiredCallback
    // ------------------------------
    grecaptchaExpiredCallback() {

        this.props.webActions.openAlertDialog({
            title: '安全認證已過期',
        });
    }

    // ------------------------------
    // addComment
    // ------------------------------
    addComment() {

        const {
            id,
            isAddComment,
            recaptchaResponse,
            comment,
        } = this.state;

        if(isAddComment || !comment || (!GLOBAL.isAppFile && !recaptchaResponse)) {
            return;
        }

        this.setState({
            isAddComment: true,
        }, () => {
            this.props.vitemOrderActions.addOrderLog({
                orderid: id,
                txtcontent: comment,
            }).then(() => {

                this.setState({
                    isAddComment: false,
                    isOrderLogsEmpty: false,
                    isOrderLogsNoMore: false,
                    recaptchaResponse: '',
                }, () => {
                    !GLOBAL.isAppFile && window.grecaptcha.reset();
                    this.listOrderLogs();
                });

            }, () => {

                this.setState({
                    isAddComment: false,
                    recaptchaResponse: '',
                }, () => {
                    !GLOBAL.isAppFile && window.grecaptcha.reset();
                });
            });
        });
    }

    // ------------------------------
    // renderDetailContent
    // ------------------------------
    renderDetailContent() {

        const {
            id,
            grade,
            remarkkey,
            remark,
            isAddGrade,
            isRemarkEmpty,
            serverrole,
            ordertime,
            orderprocess,
        } = this.state;

        const {
            orderMap,
        } = this.props;

        const order = orderMap[id];

        if(!order) {
            return null;
        }

        const int_orderstatus = Number(order.orderstatus);
        const commission = order.amount * order.membercommission / 100;

        return (
            <DetailContent>
                <MainInfoContent>
                    <MainTitle>
                        {order.gamename}
                    </MainTitle>
                    <MainDescription>
                        建立時間: <TimeFormat format="simpleFullTime" time={order.createtime} />
                    </MainDescription>
                    <MainDescription>
                        訂單編號: {order.orderid}
                    </MainDescription>
                </MainInfoContent>
                <StatusContent>
                    <StepBar>
                        <StepBarProgress></StepBarProgress>
                        <StepList>
                            <StepItem active={int_orderstatus === GLOBAL.B2C_ORDERSTATUS.NOTPROCESSED}>
                                {GLOBAL.B2C_ORDERSTATUS_STRING[GLOBAL.B2C_ORDERSTATUS.NOTPROCESSED]}
                            </StepItem>
                            <StepItem active={int_orderstatus === GLOBAL.B2C_ORDERSTATUS.PROCESSED}>
                                {GLOBAL.B2C_ORDERSTATUS_STRING[GLOBAL.B2C_ORDERSTATUS.PROCESSED]}
                            </StepItem>
                            {int_orderstatus !== GLOBAL.B2C_ORDERSTATUS.FAIL ? 
                                <StepItem active={int_orderstatus === GLOBAL.B2C_ORDERSTATUS.SUCCESS}>
                                    {GLOBAL.B2C_ORDERSTATUS_STRING[GLOBAL.B2C_ORDERSTATUS.SUCCESS]}
                                </StepItem>
                                :
                                <StepItem active={int_orderstatus === GLOBAL.B2C_ORDERSTATUS.FAIL}>
                                    {GLOBAL.B2C_ORDERSTATUS_STRING[GLOBAL.B2C_ORDERSTATUS.FAIL]}
                                </StepItem>
                            }
                        </StepList>
                    </StepBar>
                </StatusContent>
                
                <InfoContent>
                    <InfoTitle>購買資訊</InfoTitle>
                    <InfoText>
                        遊戲供貨商: {order.nickname}<br/>
                        供貨商客服電話: {order.customertel}<br/>
                        比例(台幣：虛幣): {order.ntdollar}:{order.vitemdollar}<br/>
                        <br/>
                        <ColorText color={Colors.Green}>發財金: {Math.floor(order.amount/ 100)}點</ColorText> (若非VIP會員無積點)<br/>
                        <ColorText color={Colors.Red}>交易密碼: {order.orderpwd}</ColorText> (與供應商於遊戲內交易時，雙方請進行交易密碼確認，以保障交易安全！)<br/>
                        
                        最後更新時間: 
                        {order.lastupdtime > 0 ?
                            <TimeFormat format="simpleFullTime" time={order.lastupdtime} />
                        :null}
                        <br/>
                        操作人員: {order.operatorid}<br/>
                        <br/>
                        遊戲伺服器: {order.servername}<br/>
                        伺服器內角色: {serverrole}<br/>
                        交易時間: {ordertime}<br/>
                        交易過程詳述: {orderprocess}<br/>                        
                    </InfoText>
                </InfoContent>
                <InfoContent>
                    <InfoTitle>交易明細: </InfoTitle>
                    <InfoText>
                        <PaymentList>
                            商品金額
                            <PaymentRightText>{order.amount - commission}元</PaymentRightText>
                        </PaymentList>
                        <PaymentList>
                            手續費{order.membercommission}%
                            <PaymentRightText>{commission}元</PaymentRightText>
                        </PaymentList>
                        <PaymentDivide></PaymentDivide>
                        <PaymentList>
                            <PaymentRightText>
                                <PriceLabel>總價: </PriceLabel><PriceNumber>{order.amount}元</PriceNumber>
                            </PaymentRightText>
                        </PaymentList>
                        <PaymentList>
                            <PaymentRightText>
                                <VitemPriceNumber>虛幣: {order.vitemamount}</VitemPriceNumber>
                            </PaymentRightText>
                        </PaymentList>
                    </InfoText>
                </InfoContent>

                {order.isgrade ?
                    null
                    :
                    <InfoContent>
                        
                        <GradeTitle>評價</GradeTitle>
                        <SelectTitle>供應商評價</SelectTitle>
                        <SelectWrapper>
                            <Select
                                value={grade}
                                onChange={(event) => this.onInputChange(event, 'grade')}
                            >
                                <option value="1">良好 (+1)</option>
                                <option value="0">普通 (+0)</option>
                                <option value="-1">待加強(-1)</option>
                            </Select>
                        </SelectWrapper>
                        <SelectTitle>評價留言</SelectTitle>
                        <SelectWrapper>
                            <Select
                                value={remarkkey}
                                onChange={(event) => this.onRemarkkeyChange(event)}
                            >
                                <option value={1}>{REMARKTEMP[1]}</option>
                                <option value={2}>{REMARKTEMP[2]}</option>
                                <option value={3}>{REMARKTEMP[3]}</option>
                                <option value={4}>{REMARKTEMP[4]}</option>
                                <option value={-1}>其他(自行輸入)</option>
                            </Select>
                        </SelectWrapper>
                        {remarkkey === -1 ?
                            <Textarea
                                type="text"
                                placeholder="請寫下您的評價留言"
                                value={remark}
                                onChange={(event) => this.onInputChange(event, 'remark')}
                                error={isRemarkEmpty}
                            />
                            :
                            null
                        }
                        <SubmitButton
                            onClick={() => this.addGrade()}
                            disable={isAddGrade}
                        >
                            {isAddGrade ?
                                <SubmitLoading>
                                    <GlobalStyle.Spin color="#fff"/>
                                </SubmitLoading>
                                :
                                null
                            }
                            送出評價
                        </SubmitButton>
                    </InfoContent>
                }
            </DetailContent>
        );
    }
    
    // ------------------------------
    // renderCommentContent
    // ------------------------------
    renderCommentContent() {
        
        const {
            id,
            comment,
            isCommentEmpty,
            recaptchaResponse,
            isAddComment,
        } = this.state;

        const {
            orderMap,
        } = this.props;

        const order = orderMap[id];

        if(!order) {
            return null;
        }

        const int_orderstatus = Number(order.orderstatus);
        const canComment = !order.isgrade || int_orderstatus === GLOBAL.B2C_ORDERSTATUS.NOTPROCESSED || int_orderstatus === GLOBAL.B2C_ORDERSTATUS.FAIL;

        return (
            <div>
                {canComment ?
                    <CommentWrapper>
                        <CommentTitle>我要留言</CommentTitle>
                        <CommentTextarea
                            value={comment}
                            onChange={(event)=> this.onInputChange(event, 'comment')}
                            error={isCommentEmpty}
                        />
                        {GLOBAL.isAppFile ?
                            null
                            :
                            <GoogleCertified>
                                <MediaQuery minWidth={0} maxWidth={360}>
                                    <Recaptcha
                                        sitekey={GLOBAL.grecaptcha_key}
                                        callback={this.grecaptchaCallback}
                                        expiredCallback={this.grecaptchaExpiredCallback}
                                        locale="zh-TW"
                                        className="g-recaptcha"
                                        style={{
                                            transform: 'scale(0.9)',
                                            WebkitTransform: 'scale(0.9)',
                                            transformOrigin: '0 0',
                                            WebkitTransformOrigin: '0 0',
                                        }}
                                    />

                                </MediaQuery>
                                <MediaQuery minWidth={361}>
                                    <Recaptcha
                                        sitekey={GLOBAL.grecaptcha_key}
                                        callback={this.grecaptchaCallback}
                                        expiredCallback={this.grecaptchaExpiredCallback}
                                        locale="zh-TW"
                                        className="g-recaptcha"
                                    />
                                </MediaQuery>
                            </GoogleCertified>
                        }
                        <SubmitButton
                            onClick={this.addComment}
                            disable={isAddComment || !comment || (!GLOBAL.isAppFile && !recaptchaResponse)}
                        >
                            {isAddComment ?
                                <SubmitLoading>
                                    <GlobalStyle.Spin color="#fff"/>
                                </SubmitLoading>
                                :
                                null
                            }
                            送出留言
                        </SubmitButton>
                    </CommentWrapper>
                    :
                    null
                }

                {this.renderCommentList()}
            </div>
        );
    }

    // ------------------------------
    // renderCommentList
    // ------------------------------
    renderCommentList(){
        
        const {
            id,
            isOrderLogsLoading,
            isOrderLogsEmpty,
            isOrderLogsError,
        } = this.state;

        const {
            orderMap,
        } = this.props;

        const order = orderMap[id];

        if(!order || !Array.isArray(order.logs)) {
            return null;
        }

        const logs = order.logs;

        return (
            <CommentList>
                {Array.isArray(logs) && logs.length > 0 ?
                    <CupoyListRepeat
                        listId={this.constructor.name}
                        rowRenderer={this.rowRenderer}
                        items={logs}
                        isLoading={isOrderLogsLoading}
                        useWindowScroll
                        restoreWindowScroll
                        loadMoreRows={() => this.listOrderLogs()}
                    />
                    :
                    null
                }

                {isOrderLogsLoading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }

                {isOrderLogsError ?
                    <ErrorContainer>
                        <ErrorContainerTitle>
                            無法取得留言，請重新整理後再嘗試。
                        </ErrorContainerTitle>
                    </ErrorContainer>
                    :
                    null
                }

                {isOrderLogsEmpty ?
                    <ErrorContainer>
                        <ErrorContainerTitle>
                            無留言紀錄。
                        </ErrorContainerTitle>
                    </ErrorContainer>
                    :
                    null
                }
            </CommentList>
        )
    }

    // ------------------------------
    // rowRenderer
    // ------------------------------
    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it),
        parent,
        measure,
        columnIndex,
        updateRowSize,
        measureCache,
        listWidth,
    }, width, listHeight) {
        
        const {
            id,
        } = this.state;

        const {
            orderMap,
        } = this.props;

        const order = orderMap[id];

        if(!order || !Array.isArray(order.logs)) {
            return null;
        }

        const log = order.logs[index];
        let { txtcontent = '' } = log;
        txtcontent = txtcontent.trim();

        return (
            <CommentItem
                key={key}
                style={style}
            >
                <CommentInfo>
                    {
                        log.provider && !log.memberid ?
                        `賣家:${log.provider}說：`
                        :
                        `留言者:${log.memberid}說：`
                    }
                    <CommentTime>
                        <TimeFormat
                            format="timeStringDash"
                            time={log.createtime}
                        />
                    </CommentTime>
                </CommentInfo>
                {/* 防止是使用者自己輸入html語法 */}
                {
                    log.provider && log.memberid ?
                    <CommentText dangerouslySetInnerHTML={{ __html: txtcontent }} />
                    :
                    <CommentText>{txtcontent}</CommentText>
                }
            </CommentItem>
        )
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            id,
            layout,
            isLoading,
            isError,
        } = this.state;

        var renderSubContent;

        switch(layout) {
            case LAYOUT_TYPE.DETAIL:
                renderSubContent = this.renderDetailContent;
                break;
            case LAYOUT_TYPE.COMMENT:
                renderSubContent = this.renderCommentContent;
                break;
            default:
                renderSubContent = this.renderDetailContent;
                break;
        }

        return (
            <div>
                <Header>
                    <CupoySrefView
                        statename={CupoyRouter.STATES.MEMBER_VITEM_ORDER_SELL}
                        stylecomponent={NavItem}
                        active={layout === LAYOUT_TYPE.DETAIL}
                        params={{
                            id: id,
                        }}
                        querys={{
                            layout: LAYOUT_TYPE.DETAIL,
                        }}
                        replace
                    >
                        訂單詳情
                    </CupoySrefView>
                    <CupoySrefView
                        statename={CupoyRouter.STATES.MEMBER_VITEM_ORDER_SELL}
                        stylecomponent={NavItem}
                        active={layout === LAYOUT_TYPE.COMMENT}
                        params={{
                            id: id,
                        }}
                        querys={{
                            layout: LAYOUT_TYPE.COMMENT,
                        }}
                        replace
                    >
                        留言
                    </CupoySrefView>
                </Header>
                <SubContent>

                    {!isLoading && !isError ?
                        renderSubContent()
                        :
                        null
                    }

                    {isLoading ?
                        <GlobalStyle.LoadingContainer>
                            <GlobalStyle.Spin />
                        </GlobalStyle.LoadingContainer>
                        :
                        null
                    }

                    {isError ?
                        <GlobalStyle.ErrorContainer>
                            <GlobalStyle.ErrorContainerTitle>
                                發生錯誤
                            </GlobalStyle.ErrorContainerTitle>
                            <GlobalStyle.ErrorContainerDesription>
                                無法取得訂單資訊，請重新整理後再嘗試。
                            </GlobalStyle.ErrorContainerDesription>
                        </GlobalStyle.ErrorContainer>
                        :
                        null
                    }
                </SubContent>
            </div>
        )
    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return <NeedLoginBlock />;
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar 
                hasFixedTabbar={false}
                backgroundColor='#F5F5F5'
            >
                <TopbarView
                    isFixed
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER_VITEM_ORDER_LIST_SELL}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            虛寶出售
                        </CupoySrefView>
                    }
                    middleComponent="訂單詳情"
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        orderMap: state.vitemorder.orderMap,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        vitemOrderActions: bindActionCreators(actionCreators.vitemOrderActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberVitemOrderSellView);