import actionTypes from 'actions/types/systemMsgActionTypes';
import GLOBAL from 'nonnmwebglobal';
import customAxios from 'api/customAxios';

const API_URL = '/systemmsg/SystemMsgAction.do';

function getUnreadSystemMsgsSuccess(result) {
    return {
        type: actionTypes.getUnreadSystemMsgsSuccess,
        payload: {
            result,
        },
    };
}

function getUnreadSystemMsgs(unreadSystemMsgIds) {
    return dispatch => {

        const form = new FormData();
        form.append('op', 'getUnreadSystemMsgs');
        form.append('systemmsgids', unreadSystemMsgIds);
        
        const promise = new Promise((resolve, reject) => {
        
            customAxios(API_URL, {
                method: 'POST',
                body: form,
            }).then(response => {
                if (response.status === 503) {
                    // AWS server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                    reject();
                    return;
                }
                response.json().then(result => {
                    if (!result || result.error) {
                        reject(result && result.error);
                        return;
                    }

                    dispatch(getUnreadSystemMsgsSuccess(result));
                    resolve(result);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                reject(error);
            });
        });
        
        return promise;
    };
}


export default {
    getUnreadSystemMsgs, // 取得未讀系統簡訊
};