import React, {
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GlobalStyle, Colors, MemberStyle } from 'stylecomponents';
import memberUtil from 'util/memberUtil';
import useIDNumberVerifier from 'components/hooks/member/useIDNumberVerifier';
import useActions from 'actions/hooks/useActions';
import actionCreators from 'actions/creators';
import usePrevious from 'components/hooks/usePrevious';

/**
 * Variables
 */
const {
    ID_CARD_STATUS,
    DISTRICT_CODE,
} = memberUtil;

const ID_CARD_STATUS_KEYS = Object.keys(ID_CARD_STATUS);
const DISTRICT_CODE_KEYS = Object.keys(DISTRICT_CODE);

const twyearopt = [];
const yearNow = new Date().getFullYear() - 1911;
for (let index = 94; index <= yearNow; index++) {
    const yearText = index.toString();
    twyearopt.push(yearText.padStart(3, '0'));
}

const monthopt = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12'
];
const dayopt = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31'
];

/**
 * Styled
 */
const FieldTitle = styled.div`
    margin : 16px 0 0 0;
    font-size: 12px;
    line-height: 20px;
    color : #232423;
    display: flex;
    align-items: center;

    > span {
        display: inline-flex;
        align-items: center;
        color: ${Colors.Red};
        margin-left: 4px;
    }
`;
const Select = styled.select`
    width: 100%;
    height: 100%;
    padding: 0 8px;
    border: none;
    background-color: #FAFAFA;
    font-size: 15px;
    color: #222328;
`;
const SelectWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 44px;
    margin: 0 0 0 12px;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    background-color: #FAFAFA;

    ${(props) => {
        if(props.error) {
            return `
                border: 1px solid ${Colors.Red};
            `;
        }
    }}

    &:first-child {
        margin: 0;
    }
`;
const Notice = styled.div`
    color: ${Colors.Red};
    font-size: 14px;
    margin-top: 10px;

    > i {
        margin-right: 8px;
        font-size: 16px;
        transform: translateY(1px);
    }
`;

const MemberIdNumberVerifier = props => {

    const {
        onChange,
    } = props;

    const {
        webActions,
    } = useActions({
        webActions: actionCreators.webActionCreators,
    });

    const {
        idNumber,
        handleIdNumber,
        applyCode,
        handleApplyCode,
        applyYYY,
        handleApplyYYY,
        applyMM,
        handleApplyMM,
        applyDD,
        handleApplyDD,
        issueSiteId,
        handleIssueSiteId,

        isDisableVerify, // 是否不可驗證 ( 資料沒輸入齊全 )
        verifyStatus, // 驗證狀態
        verifyIdNumber, // 驗證身分證
    } = useIDNumberVerifier();

    const {
        passedId,
        isPass,
        isLoading,
        isError,
        error,
    } = verifyStatus;

    const prev_isPass = usePrevious(isPass);

    // 驗證成功
    useEffect(() => {

        if (isPass === prev_isPass) {
            return;
        }

        if (isPass) {
            // 開啟提醒
            webActions.openAlertDialog({
                title: '驗證成功',
                description: '身分證可用',
            });

            // 傳送已驗證成功之身分證字號
            onChange && onChange(passedId);
        } else if (!passedId) {
            // 驗證成功之身分證字號已變動
            onChange && onChange(passedId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPass, prev_isPass, passedId]);

    // 發生錯誤
    useEffect(() => {
        if (isError) {
            webActions.openAlertDialog({
                title: '發生錯誤',
                description: error && error.message
                    ? error.message
                    : '發生無法預料的錯誤，請聯繫客服人員',
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError, error]);

    return (
        <>
            <FieldTitle>身分證字號</FieldTitle>
            <MemberStyle.MemberInputBox
                type='text'
                value={idNumber}
                placeholder='填寫不實身分證將無法進行相關驗證核對'
                onChange={handleIdNumber}
            />

            <Notice>
                <i className="zmdi zmdi-info-outline"></i>
                驗證錯誤累積達3次後，該身分證字號今日將無法查詢
            </Notice>
            
            <FieldTitle>發證日期</FieldTitle>
            <MemberStyle.InputContainer
                flexdirection='row'
                justify='space-between'
                style={{alignContent:'flex-start'}}
            >
                <SelectWrapper>
                    <Select
                        value={applyYYY}
                        onChange={handleApplyYYY}
                    >
                        <option value=''>民國年</option>
                        {twyearopt.map(opt => (
                            <option key={opt} value={opt}>
                                {opt}
                            </option>
                        ))}
                    </Select>
                </SelectWrapper>

                <SelectWrapper>
                    <Select
                        value={applyMM}
                        onChange={handleApplyMM}
                    >
                        <option value=''>月</option>
                        {monthopt.map(opt => (
                            <option key={opt} value={opt}>
                                {opt}
                            </option>
                        ))}
                    </Select>
                </SelectWrapper>

                <SelectWrapper>
                    <Select
                        value={applyDD}
                        onChange={handleApplyDD}
                    >
                        <option value=''>日</option>
                        {dayopt.map(opt => (
                            <option key={opt} value={opt}>
                                {opt}
                            </option>
                        ))}
                    </Select>
                </SelectWrapper>
            </MemberStyle.InputContainer>

            <FieldTitle>發證地點</FieldTitle>
            <MemberStyle.InputContainer
                flexdirection="row"
                justify="space-between"
                style={{alignContent:"flex-start"}}
            >
                <SelectWrapper>
                    <Select
                        large
                        value={issueSiteId}
                        onChange={handleIssueSiteId}
                    >
                        <option value=''>請選擇地點</option>
                        {DISTRICT_CODE_KEYS.map(key => {

                            const value = DISTRICT_CODE[key];

                            return (
                                <option key={value} value={value}>
                                    {key}
                                </option>
                            );
                        })}
                    </Select>
                </SelectWrapper>
            </MemberStyle.InputContainer>

            <FieldTitle>領補換類別</FieldTitle>
            <MemberStyle.InputContainer
                flexdirection="row"
                justify="space-between"
                style={{alignContent:"flex-start"}}
            >
                <SelectWrapper>
                    <Select
                        large
                        value={applyCode}
                        onChange={handleApplyCode}
                    >
                        <option value=''>請選擇類別</option>
                        {ID_CARD_STATUS_KEYS.map(key => {

                            const value = ID_CARD_STATUS[key];

                            return (
                                <option key={value} value={value}>
                                    {key}
                                </option>
                            );
                        })}
                    </Select>
                </SelectWrapper>
            </MemberStyle.InputContainer>

            <MemberStyle.ConfirmBtn
                onClick={verifyIdNumber}
                disable={isDisableVerify}
            >
                {!!isLoading && <GlobalStyle.Spin color='#FFF' />}
                {isPass ? '已驗證身分證' : '驗證身分證'}
            </MemberStyle.ConfirmBtn>
        </>
    );
};

MemberIdNumberVerifier.propTypes = {
    onChange: PropTypes.func,
};

export default MemberIdNumberVerifier;