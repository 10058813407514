import React, {
    useState,
} from 'react';
import useActions from 'actions/hooks/useActions';
import actionCreators from 'actions/creators';
import { GlobalStyle, MemberStyle } from 'stylecomponents';
import TopbarView from 'components/commons/TopbarView';
import CupoyRouter from 'components/commons/router/CupoyRouter';
import CupoySrefView from 'components/commons/router/CupoySrefView';
import GLOBAL from 'nonnmwebglobal';

/**
 * Variables
 */

/**
 * 忘記帳號
 * @author Ac
 * @time 2020 / 08 / 10
 */
const ForgotAccountView = () => {

    const {
        webActions,
        memberActions,
    } = useActions({
        webActions: actionCreators.webActionCreators,
        memberActions: actionCreators.memberActionCreators,
    });

    const [isUseIdNumber, setIsUseIdNumber] = useState(!!GLOBAL.getRandomInt(0, 1)) // 0 ~ 1, 1 = 身分證, 0 = 手機
    const [value, setValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [validateResult, setValidateResult] = useState(null);
    const [email, setEmail] = useState('');
    
    const forgetAccount = () => {

        if (isLoading) {
            return;
        }

        let payload = {},
            errorMsg = '';

        if (isUseIdNumber) {

            const {
                result,
                message,
            } = GLOBAL.chkIdnumber(value);

            result
                ? payload = { idNumber: value }
                : errorMsg = message;
        } else {
            GLOBAL.chkMobile(value)
                ? payload = { mobilePhone: value }
                : errorMsg = '手機號碼輸入錯誤！';
        }

        if (errorMsg) {
            webActions.openAlertDialog({
                title: '輸入錯誤',
                description: errorMsg,
            });
            return;
        }

        setIsLoading(true);

        memberActions.forgetAccount(
            payload
        ).then(result => {
            setIsLoading(false);
            setValue('');
            setValidateResult(result);
        }).catch(error => {

            setIsLoading(false);
            setValue('');
            // 隨機重選輸入類型
            setIsUseIdNumber(!!GLOBAL.getRandomInt(0, 1));

            webActions.openAlertDialog({
                title: '發生錯誤',
                description: error && error.message
                    ? error.message
                    : '發生無法預期的錯誤，請聯絡客服或稍後再試',
            });
        });
    };

    const sendAccountEmail = () => {

        if (isLoading) {
            return;
        }

        if (!GLOBAL.isEmail(email)) {
            webActions.openAlertDialog({
                title: '輸入錯誤',
                description: '請輸入正確的Email格式！',
            });
            return;
        }

        setIsLoading(true);

        memberActions.sendAccountEmail({
            memberId: validateResult.memberid,
            token: validateResult.token,
            email,
        }).then(() => {

            setIsLoading(false);
            setValidateResult(null);
            setEmail('');

            webActions.openAlertDialog({
                title: '驗證成功',
                description: '帳號資訊已發送至您的信箱，請至您的信箱查看',
            });
        }).catch(error => {

            setIsLoading(false);

            let errorMsg = '';

            if (error && error.code === 'error.account.accountNotExist') {
                errorMsg = '您輸入的信箱不正確';
            } else if (error && error.message) {
                errorMsg = error.message;
            } else {
                errorMsg = '發生無法預期的錯誤，請聯絡客服或稍後再試';
            }

            webActions.openAlertDialog({
                title: '發生錯誤',
                description: errorMsg,
            });
        });
    };
    
    return (
        <GlobalStyle.Container
            hasFixedTopbar
            hasFixedTabbar={false}
        >
            <TopbarView
                isFixed
                leftComponent={
                    <CupoySrefView 
                        statename={CupoyRouter.STATES.MEMBERLOGIN}
                        stylecomponent={GlobalStyle.TopbarButton}
                    >   
                        <GlobalStyle.TopbarIcon>
                            <i className='zmdi zmdi-chevron-left'></i>
                        </GlobalStyle.TopbarIcon>
                        會員登入
                    </CupoySrefView>
                }
                middleComponent='忘記帳號'
            />
            <MemberStyle.MemberContent>
                {validateResult
                    ? (
                        <>
                            <MemberStyle.MemberDescription margin='0'>
                                請輸入會員信箱，系統將會把帳號發送至您的信箱！
                            </MemberStyle.MemberDescription>

                            <MemberStyle.MemberInputBox
                                placeholder='請輸入會員信箱'
                                type='text'
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                            />
                            <MemberStyle.ConfirmBtn onClick={sendAccountEmail}>
                                {isLoading && <GlobalStyle.Spin color='#FFF' />}
                                送出
                            </MemberStyle.ConfirmBtn>
                        </>
                    )
                    : (
                        <>
                            <MemberStyle.MemberInputBox
                                placeholder={isUseIdNumber ? '請輸入身分證' : '請輸入手機號碼'}
                                type='text'
                                value={value}
                                onChange={event => setValue(event.target.value)}
                            />
                            <MemberStyle.ConfirmBtn onClick={forgetAccount}>
                                {isLoading && <GlobalStyle.Spin color='#FFF' />}
                                送出
                            </MemberStyle.ConfirmBtn>
                        </>
                    )
                }
            </MemberStyle.MemberContent>
         </GlobalStyle.Container>
    );
};

export default ForgotAccountView;
