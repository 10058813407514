/*=====================================
    虛寶市集

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle, TabActivity } from 'stylecomponents';
import styled from 'styled-components';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import ProductInfoView from "components/vitem/ProductInfoView";
import ProductMessageView from "components/vitem/ProductMessageView";
import { Route } from "react-router-dom";

/*--------------------------
    Styled Component
--------------------------*/

const TopbarMiddle = styled.h3`

    height: 100%;
    line-height: 44px;
    margin: 0;
    font-size: 16px;
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
    color: #FFFFFF;
    text-align: center;

`;

/*--------------------------
    Main Component
--------------------------*/
class SellDetailView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {

        };
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        const {
            gameid, 
            serverid: gameserverid,
            viprovid,
        } = this.props.match.params;

        if(!CupoyRouter.isActive(CupoyRouter.STATES.SELLDETAIL_PRODUCT) && !CupoyRouter.isActive(CupoyRouter.STATES.SELLDETAIL_MESSAGE)) {
            this.props.webActions.cupoyStateGo(CupoyRouter.STATES.SELLDETAIL_PRODUCT,
                { gameid, serverid: gameserverid, viprovid});
        }

        const sellerListIdsByGameAndServerId = this.props.sellerListIdsByGameAndServerId;
        
        if(Object.keys(sellerListIdsByGameAndServerId).length === 0) {        
            this.props.vitemActions.initOrder({gameid, gameserverid,viprovid});
        }
        if(Object.keys(this.props.gameListMap).length === 0){
            this.props.gameActions.listGames();
        }
        
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            gameid, 
            serverid: gameserverid,
            viprovid,
        } = this.props.match.params;

        const sellerMap = this.props.sellerMap[`${viprovid},${gameid},${gameserverid}`];
        const game = this.props.gameListMap[gameid];

        const gamename = sellerMap ? sellerMap.gamename : "";
        
        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.TRANSACTIONLIST_SELL}
                            stylecomponent={GlobalStyle.TopbarButton}
                            params={{
                                gameid,
                                serverid: gameserverid,
                            }}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            出售區
                        </CupoySrefView>
                    }
                    middleComponent={
                        <TopbarMiddle>
                            {gamename}
                        </TopbarMiddle>
                    }
                />
                <TabActivity.TabActivityWrapper>
                    <CupoySrefView
                        statename={CupoyRouter.STATES.SELLDETAIL_PRODUCT}
                        stylecomponent={TabActivity.TabActivityItem}
                        active={CupoyRouter.isActive(CupoyRouter.STATES.SELLDETAIL_PRODUCT)}
                        params={{
                            gameid,
                            serverid: gameserverid,
                            viprovid,
                        }}
                    >
                        商品詳情
                    </CupoySrefView>
                    <CupoySrefView 
                        statename={CupoyRouter.STATES.SELLDETAIL_MESSAGE}
                        stylecomponent={TabActivity.TabActivityItem}
                        active={CupoyRouter.isActive(CupoyRouter.STATES.SELLDETAIL_MESSAGE)}
                        params={{
                            gameid,
                            serverid: gameserverid,
                            viprovid,
                        }}
                    >
                        我要留言
                    </CupoySrefView>
                </TabActivity.TabActivityWrapper>

                {sellerMap && game ? <Route path={CupoyRouter.PATHS.SELLDETAIL_PRODUCT} component={ProductInfoView} exact /> : null}
                {sellerMap && game ? <Route path={CupoyRouter.PATHS.SELLDETAIL_MESSAGE} component={ProductMessageView} exact/> : null}
                {this.props.isloading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }
            </GlobalStyle.Container>
        );
    }
}




/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        sellerMap: state.vitem.sellerMap,
        isloading: state.vitem.isloading,
        gameListMap: state.game.gameListMap,
        sellerListIdsByGameAndServerId: state.vitem.sellerListIdsByGameAndServerId,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        vitemActions: bindActionCreators(actionCreators.vitemActionCreators, dispatch),
        gameActions: bindActionCreators(actionCreators.gameActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(SellDetailView);