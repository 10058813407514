/*=====================================
    首頁

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle ,Colors ,NewsList ,ProductItem} from 'stylecomponents';
import styled from 'styled-components';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import TimeFormat from "components/commons/TimeFormat";
import HomeMarquee from './HomeMarquee';
import Banner from './Banner';
import GLOBAL from 'nonnmwebglobal';
import productUtil, { PRODUCT_COVER_SIZE } from 'util/productUtil';
// import openDateBank from '../../json/openDateBank';
/*--------------------------
    styled-components
--------------------------*/

const MarqueeWrapper = styled.div`

    display: flex;
    position: relative;
    height: 38px;
    margin-top: ${GlobalStyle.ViewSpace}px;
    box-shadow: inset 0 -1px 0 0 rgba(238,238,238,0.54), inset 0 1px 0 0 #EEEEEE;
    /* overflow-x: hidden; */
    line-height: 38px;
    font-size: 14px;
    background-color: #fff;
    color: ${Colors.Dark2};
    white-space: nowrap;

`;

const MarqueeList = styled.span`

    margin-left: 5px;
    margin-right: 5px;

`;

const MarqueeRed = styled.span`
    color: ${Colors.Red};
`;
const MarqueeGreen = styled.span`
    color: ${Colors.Green};
`;

const NavListWrapper = styled.nav`

    display: flex;
    justify-content: space-around;
    height: 97px;
    margin-top: ${GlobalStyle.ViewSpace}px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;

`;

const NavListItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    width: 25%;
    cursor: pointer;
`;

const NavListIcon = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.backgroundColor};
    border-radius: 8px;
    color: #fff;
    font-size: 26px;

    > i {
        font-size: 26px;
    }
`;

const NavListText = styled.div`
    margin-top: 5px;
    font-size: 13px;
    color: ${Colors.Dark2};
`;

const SectionTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 52px;
    margin-top: ${GlobalStyle.ViewSpace}px;
    padding-left: 16px;
    line-height: 52px;
    background: #FFFFFF;
    box-shadow: 0 1px 0 0 #EEEEEE; 
`;

const SectionTitle = styled.span`
    font-size: 16px;
    color: ${Colors.Dark2};
`;

const SectionTitleMore = styled.div`
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    color: #777777;
`;

/*--------------------------
    Main Component
--------------------------*/

class HomeView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);
        this.state = {};
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        
        if(!this.props.isLoadedIdxPageInfo) {
            this.props.idxPageActions.getIdxPageInfo();
            this.props.idxPageActions.getIdxPageLotteryResult();
        }
        
        if(!this.props.isLoadedIdxPageTopads) {
            this.props.idxPageActions.getTopADList();
        }
    }

    // ------------------------------
    // sliceBulletinTitle
    // ------------------------------
    sliceBulletinTitle(actionType, title){

        if(!title){
            return null;
        }

        switch (actionType) {

            case 'getTagName':
                return title.slice(1,3);
            case 'getTitle':
                return title.slice(4)
            default:
                return title
        }

    }

    renderNews() {//最新消息
        return (
            <React.Fragment>
                <SectionTitleWrapper>
                    <SectionTitle>最新消息</SectionTitle>
                    <CupoySrefView 
                        statename={CupoyRouter.STATES.NEWSLIST}
                        stylecomponent={SectionTitleMore}
                        params={{
                        }}
                    >
                        查看全部
                        <i className="icon-chevron-right" style={{fontSize: '12px', fontWeight: 'lighter'}}></i>
                    </CupoySrefView>
                </SectionTitleWrapper>

                {this.props.bulletin1s ? this.props.bulletin1s.map((bulletin, index) => {

                    const isTopBulletin = bulletin.topbulletin === 1 || bulletin.topbulletin === 3;

                    return (
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.NEWSCONTENT}
                            stylecomponent={NewsList.NewsItem}
                            params={{
                                id: bulletin.bulletinid,
                            }}
                            key={index}
                        >
                            <div>

                                <NewsList.NewsTag bulletinType={bulletin.type}>
                                    {this.sliceBulletinTitle('getTagName', bulletin.title)}
                                    <NewsList.NewsTagIcon>
                                        {bulletin.topbulletin && (
                                            <i className="icon-drawingpin"></i> 
                                        )}
                                    </NewsList.NewsTagIcon>
                                </NewsList.NewsTag>
                            </div>
                            <NewsList.NewsText isTopBulletin={isTopBulletin}>
                                {this.sliceBulletinTitle('getTitle', bulletin.title)}
                                <NewsList.NewsTime>
                                    <TimeFormat
                                        format="timeStringDash"
                                        time={bulletin.publishtime}
                                    />
                                </NewsList.NewsTime>
                            </NewsList.NewsText>
                        </CupoySrefView>
                    )

                }) : null }

                {this.props.isLoadingIdxPageInfo ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }   

                {this.props.isLoadIdxPageInfoFailed ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            發生錯誤
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無法取得最新消息，請重新整理後再嘗試。
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                } 
            </React.Fragment>
        )
    }

    renderBuyList() {//最新活動
        return (
            <React.Fragment>
                <SectionTitleWrapper>
                    <SectionTitle>最新活動</SectionTitle>
                    <CupoySrefView 
                        statename={CupoyRouter.STATES.PRODUCTBUYLIST}
                        stylecomponent={SectionTitleMore}
                    >
                        查看全部
                        <i className="icon-chevron-right" style={{fontSize: '12px', fontWeight: 'lighter'}}></i>
                    </CupoySrefView>
                </SectionTitleWrapper>

                {this.props.bulletin2s ? this.props.bulletin2s.map((bulletin, index) => {

                    const isTopBulletin = bulletin.topbulletin === 1 || bulletin.topbulletin === 3;

                    return (
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.PRODUCTBUYCONTENT}
                            stylecomponent={NewsList.NewsItem}
                            params={{
                                id: bulletin.bulletinid,
                            }}
                            key={index}
                        >
                            <div>
                                <NewsList.NewsTag bulletinType={bulletin.type}>
                                    {this.sliceBulletinTitle('getTagName', bulletin.title)}
                                    <NewsList.NewsTagIcon>
                                        {bulletin.topbulletin && (
                                            <i className="icon-drawingpin"></i> 
                                        )}
                                    </NewsList.NewsTagIcon>
                                </NewsList.NewsTag>
                            </div>
                            <NewsList.NewsText isTopBulletin={isTopBulletin}>
                                {this.sliceBulletinTitle('getTitle', bulletin.title)}
                                <NewsList.NewsTime>
                                    <TimeFormat
                                        format="timeStringDash"
                                        time={bulletin.publishtime}
                                    />
                                </NewsList.NewsTime>
                            </NewsList.NewsText>
                        </CupoySrefView>
                    )

                }) : null }
                {this.props.isLoadingIdxPageInfo ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }   

                {this.props.isLoadIdxPageInfoFailed ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            發生錯誤
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無法取得列表，請重新整理後再嘗試。
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }  
            </React.Fragment>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            platform,
        } = this.props;

        const isShowNavList = platform === 'mobileWeb' || platform === 'android';
        
        return (

            <GlobalStyle.Container hasFixedTabbar={true} backgroundColor='#f5f5f5'>

                {this.props.topads && this.props.topads.length ?
                    <Banner topADList={this.props.topads} />
                    :
                    null
                }
                
                {this.props.isLoadingIdxPageInfo ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                } 
                {this.props.isLoadIdxPageInfoFailed ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            發生錯誤
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無法取得Banner列表，請重新整理後再嘗試。
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }
                {/* <MarqueeWrapper>
                    {this.props.lotterys && this.props.lotterys.length > 0 && (
                        <HomeMarquee rate={60}>
                            {this.props.lotterys.map(({
                                nickname = '',
                                productname = ''
                            }, index) => {

                                const _nickname = nickname.trim();

                                if (!_nickname) {
                                    return null;
                                }

                                return (
                                    <MarqueeList key={index}>
                                        恭喜『
                                        <MarqueeRed>{_nickname.trim()}</MarqueeRed>
                                        』抽獎獲得《
                                        <MarqueeGreen>{productname.trim()}</MarqueeGreen>
                                        》、
                                    </MarqueeList>
                                );
                            })}
                        </HomeMarquee>
                    )}
                </MarqueeWrapper> */}
                
                {isShowNavList && (
                    <NavListWrapper>
                        {/* <CupoySrefView 
                            statename={CupoyRouter.STATES.MYCOMMODITY}
                            stylecomponent={NavListItem}
                        >
                            <NavListIcon backgroundColor="#4A90E2">
                                <i className="icon-star"></i>
                            </NavListIcon>
                            <NavListText>最愛商品</NavListText>
                        </CupoySrefView> */}
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.NEWSCONTENT}
                            params={{ id: '1590395703976' }}
                            stylecomponent={NavListItem}
                        >
                            <NavListIcon backgroundColor={Colors.Red}>
                                <i className="icon-warning-outline"></i>
                            </NavListIcon>
                            <NavListText>慎防詐騙</NavListText>
                        </CupoySrefView>
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.VITEM}
                            stylecomponent={NavListItem}
                        >
                            <NavListIcon backgroundColor="#F75C2F">
                                <i className="icon-market-full"></i>
                            </NavListIcon>
                            <NavListText>{GLOBAL.VITEM_DISPLAY_TEXT}</NavListText>
                        </CupoySrefView>
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.GOODPROMOTIONS}
                            stylecomponent={NavListItem}
                        >
                            <NavListIcon backgroundColor="#FFB433">
                                <i className="icon-bag"></i>
                            </NavListIcon>
                            <NavListText>好康活動</NavListText>
                        </CupoySrefView>
                    </NavListWrapper>
                )}
                           
                {this.renderNews()}
                {this.renderBuyList()}
                
                <SectionTitleWrapper>
                    <SectionTitle>熱門遊戲</SectionTitle>
                    <CupoySrefView 
                        statename={CupoyRouter.STATES.VITEM}
                        stylecomponent={SectionTitleMore}
                    >
                        查看全部
                        <i className="icon-chevron-right" style={{fontSize: '12px', fontWeight: 'lighter'}}></i>
                    </CupoySrefView>
                </SectionTitleWrapper>
                    <ProductItem.ClearFloat>
                        {Array.isArray(this.props.hotgames) ? this.props.hotgames.slice(0, 6).map((games, index)=>{
                            return (
                                <CupoySrefView 
                                    statename={CupoyRouter.STATES.TRANSACTIONLIST}
                                    params={{gameid: games.gameid}}
                                    key={index}
                                >
                                    <ProductItem.RectangleProductItem columns={2}>
                                        <ProductItem.ProductContent>
                                            <ProductItem.ProductImageWrapper>
                                                <ProductItem.ProductImage imageUrl={GLOBAL.imgSrcRootURL + `/gameimgs/${games.gameid}/${games.idximg}`}/>
                                            </ProductItem.ProductImageWrapper>
                                            <ProductItem.ProductName>{games.gamename}</ProductItem.ProductName>
                                        </ProductItem.ProductContent>
                                    </ProductItem.RectangleProductItem>
                                </CupoySrefView>
                            )
                        }) : null }
                    {this.props.isLoadingIdxPageInfo ?
                        <GlobalStyle.LoadingContainer>
                            <GlobalStyle.Spin />
                        </GlobalStyle.LoadingContainer>
                        :
                        null
                    }   

                    {this.props.isLoadIdxPageInfoFailed ?
                        <GlobalStyle.ErrorContainer>
                            <GlobalStyle.ErrorContainerTitle>
                                發生錯誤
                            </GlobalStyle.ErrorContainerTitle>
                            <GlobalStyle.ErrorContainerDesription>
                                無法取得熱門遊戲列表，請重新整理後再嘗試。
                            </GlobalStyle.ErrorContainerDesription>
                        </GlobalStyle.ErrorContainer>
                        :
                        null
                    }  
                    </ProductItem.ClearFloat>
                <SectionTitleWrapper>
                    <SectionTitle>熱門商品</SectionTitle>
                    <CupoySrefView 
                        statename={CupoyRouter.STATES.CARD_RECOMMENDED}
                        stylecomponent={SectionTitleMore}
                    >
                        查看全部
                        <i className="icon-chevron-right" style={{fontSize: '12px', fontWeight: 'lighter'}}></i>
                    </CupoySrefView>
                </SectionTitleWrapper>
                    <ProductItem.ClearFloat>

                        {this.props.hotcards ? this.props.hotcards.slice(0,6).map((hotcards, index)=>{
                            return (
                                <CupoySrefView 
                                    statename={CupoyRouter.STATES.CARDINFORMATION}
                                    stylecomponent={ProductItem.RectangleProductItem}
                                    params={{id: hotcards.productid}}
                                    key={index}
                                >
                                    <ProductItem.ProductContent>
                                        <ProductItem.ProductImageWrapper>
                                            <ProductItem.ProductImage
                                                imageUrl={productUtil.getProductCover(PRODUCT_COVER_SIZE.LARGE, hotcards.productid, hotcards.thumbimg)}
                                            />
                                        </ProductItem.ProductImageWrapper>
                                        <ProductItem.ProductName>{hotcards.productname}</ProductItem.ProductName>
                                    </ProductItem.ProductContent>
                                </CupoySrefView>
                            )
                        }) : null}
                        {this.props.isLoadingIdxPageInfo ?
                            <GlobalStyle.LoadingContainer>
                                <GlobalStyle.Spin />
                            </GlobalStyle.LoadingContainer>
                            :
                            null
                        }   

                        {this.props.isLoadIdxPageInfoFailed ?
                            <GlobalStyle.ErrorContainer>
                                <GlobalStyle.ErrorContainerTitle>
                                    發生錯誤
                                </GlobalStyle.ErrorContainerTitle>
                                <GlobalStyle.ErrorContainerDesription>
                                    無法取得熱門產包列表，請重新整理後再嘗試。
                                </GlobalStyle.ErrorContainerDesription>
                            </GlobalStyle.ErrorContainer>
                            :
                            null
                        }  
                    </ProductItem.ClearFloat>

                    <SectionTitleWrapper>
                    <SectionTitle>熱門發財商城</SectionTitle>
                    <CupoySrefView 
                        statename={CupoyRouter.STATES.CARD_BONUSPRODUCTS}
                        stylecomponent={SectionTitleMore}
                    >
                        查看全部
                        <i className="icon-chevron-right" style={{fontSize: '12px', fontWeight: 'lighter'}}></i>
                    </CupoySrefView>
                </SectionTitleWrapper>
                    <ProductItem.ClearFloat>

                        {Array.isArray(this.props.hotbonuscards) ? this.props.hotbonuscards.slice(0, 6).map((hotbonuscards, index)=>{
                            return (
                                <CupoySrefView 
                                    statename={CupoyRouter.STATES.CARDINFORMATION}
                                    stylecomponent={ProductItem.RectangleProductItem}
                                    params={{id: hotbonuscards.productid}}
                                    key={index}
                                >
                                    <ProductItem.ProductContent>
                                        <ProductItem.ProductImageWrapper>
                                            <ProductItem.ProductImage
                                                imageUrl={productUtil.getProductCover(PRODUCT_COVER_SIZE.LARGE, hotbonuscards.productid, hotbonuscards.thumbimg)}
                                            />
                                        </ProductItem.ProductImageWrapper>
                                        <ProductItem.ProductName>{hotbonuscards.productname}</ProductItem.ProductName>
                                    </ProductItem.ProductContent>
                                </CupoySrefView>
                            )
                        }) : null }
                        {this.props.isLoadingIdxPageInfo ?
                            <GlobalStyle.LoadingContainer>
                                <GlobalStyle.Spin />
                            </GlobalStyle.LoadingContainer>
                            :
                            null
                        }   

                        {this.props.isLoadIdxPageInfoFailed ?
                            <GlobalStyle.ErrorContainer>
                                <GlobalStyle.ErrorContainerTitle>
                                    發生錯誤
                                </GlobalStyle.ErrorContainerTitle>
                                <GlobalStyle.ErrorContainerDesription>
                                    無法取得熱門發財商城列表，請重新整理後再嘗試。
                                </GlobalStyle.ErrorContainerDesription>
                            </GlobalStyle.ErrorContainer>
                            :
                            null
                        }  
                    </ProductItem.ClearFloat>

            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        topads: state.idxpage.topads,
        topbulletin: state.idxpage.topbulletin,
        bulletin1s: state.idxpage.bulletin1s,
        bulletin2s: state.idxpage.bulletin2s,
        hotgames: state.idxpage.hotgames,
        hotcards: state.idxpage.hotcards,
        lotterys: state.idxpage.lotterys,
        hotbonuscards : state.idxpage.hotbonuscards,
        isLoadingIdxPageInfo: state.idxpage.isLoadingIdxPageInfo,
        isLoadedIdxPageInfo: state.idxpage.isLoadedIdxPageInfo,
        isLoadIdxPageInfoFailed: state.idxpage.isLoadIdxPageInfoFailed,
        isLoadedIdxPageTopads: state.idxpage.isLoadedIdxPageTopads,
        platform: state.web.platform,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        idxPageActions: bindActionCreators(actionCreators.idxPageActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(HomeView);