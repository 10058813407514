/*=====================================
    虛寶市集

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle, ProductItem } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyGridRepeat from 'components/commons/CupoyGridRepeat';
import GLOBAL from '../../nonnmwebglobal';
/*--------------------------
    Main Component
--------------------------*/
class VitemView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.renderList = this.renderList.bind(this);
        this.renderItme = this.renderItme.bind(this);
        this.getMoreListGame = this.getMoreListGame.bind(this);
        this.validateServerID = this.validateServerID.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        const gameListIds = this.props.gameListIds;
        
        if(gameListIds.length === 0){
            this.props.gameActions.listGames({start: 0});
        }
    }
    validateServerID(gameid, defaultserverid){

        const gameSeverListById = this.props.gameSeverListById;
        const {cupoyStateGo} = this.props.webActions;
        
        if(!defaultserverid && !gameSeverListById[gameid]){
            this.props.gameActions.getGameServerList({
                gameid: gameid,
            }).then(() => {
                const serverid = this.props.gameSeverListById[gameid][0].gameserverid;
                cupoyStateGo(CupoyRouter.STATES.TRANSACTIONLIST_SELL,{
                    gameid: gameid,
                    serverid,
                });
            });
        } else {
            const serverid = defaultserverid || gameSeverListById[gameid][0].gameserverid;
            cupoyStateGo(CupoyRouter.STATES.TRANSACTIONLIST_SELL,{
                gameid: gameid,
                serverid: serverid,
            });
        }
    }
    // ------------------------------
    // render
    // ------------------------------
    render() {

        return (
            <GlobalStyle.Container 
                hasFixedTopbar 
                hasFixedTabbar
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.HOME}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            首頁
                        </CupoySrefView>
                    }
                    middleComponent="所有遊戲"
                />
                <ProductItem.ClearFloat>
                    {this.renderList()}
                    {this.props.isloading ?
                        <GlobalStyle.LoadingContainer>
                            <GlobalStyle.Spin />
                        </GlobalStyle.LoadingContainer>
                        :
                        null
                    }
                </ProductItem.ClearFloat>
            </GlobalStyle.Container>
        );
    }

    renderList(){
        
        const { gameListIds = [] } = this.props;
        
        if(gameListIds.length === 0){
            return null; 
        }

        return (
            <div>
                <CupoyGridRepeat
                    ref="list"
                    listId={this.constructor.name}
                    cellRenderer={(item, listWidth, listHeight) => { return this.renderItme(item, listWidth, listHeight, gameListIds)}}
                    loadMoreRows={() => this.getMoreListGame(this.props.gameListIds)}
                    items={gameListIds}
                    restoreWindowScroll
                    useWindowScroll
                    columnCount={2}
                    isLoading={this.props.isloading}
                />            
            </div>
        )
    }

    renderItme({
            key,
            index,
            columnIndex,
            rowIndex,
            isScrolling,
            isVisible,
            style,
            parent,
            measure,
            updateRowSize,
            measureCache
        },
        listWidth,
        listHeight,
        gameListIds
    ) {
        const gameListId = gameListIds[index];
        const game = this.props.gameListMap[gameListId];

        if(!game) {
            return null;
        }
        
        return (
            <CupoySrefView 
                statename={CupoyRouter.STATES.TRANSACTIONLIST}
                stylecomponent={ProductItem.RectangleProductItem}
                params={{gameid: game.gameid}}
                key={game.gameid}
                style={style}
            >
                <ProductItem.ProductContent>
                    <ProductItem.ProductImageWrapper>
                        <ProductItem.ProductImage imageUrl={GLOBAL.imgSrcRootURL + `/gameimgs/${game.gameid}/${game.idximg}`}/>
                    </ProductItem.ProductImageWrapper>
                    <ProductItem.ProductName>{game.gamename}</ProductItem.ProductName>
                </ProductItem.ProductContent>
            </CupoySrefView>
        )
        
    }

    getMoreListGame(gameListIds) {

        if(this.props.isloading) return;
        
        this.props.gameActions.listGames({
            start: gameListIds.length
        });
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        gameListIds: state.game.gameListIds,
        gameListMap: state.game.gameListMap,
        isloading: state.game.isloading,
        gameSeverListById: state.game.gameSeverListById,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        gameActions: bindActionCreators(actionCreators.gameActionCreators, dispatch),
        vitemActions: bindActionCreators(actionCreators.vitemActionCreators, dispatch),
        vitemOrderActions: bindActionCreators(actionCreators.vitemOrderActionCreators, dispatch),
        // guestbookActions: bindActionCreators(actionCreators.guestbookActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(VitemView);