/*=====================================
    點卡產包-點數卡

    Author: ac
    createtime: 2018 / 03 / 20
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle } from 'stylecomponents';
import styled from 'styled-components';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
import GLOBAL from '../../nonnmwebglobal';

/**
|--------------------------------------------------
| Variables
|--------------------------------------------------
*/
const observerOptions = {
    threshold: 0.5,
};
const scrollIntoViewOptions = {
    behavior: 'smooth',
};
/**
|--------------------------------------------------
| Styled
|--------------------------------------------------
*/
const CardNav = styled.nav`
    position: relative;
    min-width: 100px;
    height: calc(100vh - 144px);
    overflow: auto;

`;

const CardNavItem = styled.li`

    padding-left: 12px;
    padding-right: 12px;
    height: 50px;
    line-height: 50px;
    background: ${props => props.active ? '#FFFFFF' : '#FAFAFA'};
    box-shadow: ${props => props.active ? 'inset 4px 0 0 0 #FF9800, inset 0 -1px 0 0 #EEEEEE' : 'inset 1px 0 0 0 #EEEEEE;'};
    font-size: 13px;
    color: #333333;
    cursor: pointer;

`;

const CardContent = styled.div`

    display: flex;
    flex: 1; 

`;

const ProductListWrapper = styled.div`

    flex: 1;
    height: calc(100vh - 144px);
    overflow: auto;

`;

const ProductList = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #EEEEEE;

`;

const ProductImage = styled.div`

    min-width: 80px;
    height: 60px;
    margin-right: 16px;
    background-image: url('${props => props.imageUrl ? props.imageUrl : null}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

`;

const ProductInfo = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

`;

const ProductName = styled.div`

    padding-bottom: 8px;
    font-size: 14px;
    color: #333333;

`;


const Price = styled.del`

    display: inline-block;
    font-size: 11px;
    color: #808180;
    margin-right: 8px;

`;

const PriceWrapper = styled.div`

    display: flex;
    flex-wrap: wrap;

`;

const NewPrice = styled.div`

    font-size: 11px;
    color: #F75C2F;

`;

const StrongText = styled.span`

    font-weight: bolder;

`;

const MemberPrice = styled.span`

    padding-left: 5px;
    font-size: 14px;
    color: #F75C2F;

`;
const LoadingSidebar = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    height: calc(100vh - 144px);
    background-color: #FAFAFA;

`;

const CardNavList = styled.ul`
    margin: 0;
    overflow: auto;
    height: 100%;
`;

const ScrollHint = styled.div`
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: rgba(0, 0, 0, .45);
    color: #fff;
    font-size: 24px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
`;

/*--------------------------
    Main Component
--------------------------*/
class PointCardView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);


        this.state = {
            currentGroupid: 0,
            productIds: 'all',
            isEmpty: false,
            isShowScrollHint: undefined,
        }

        this.observe = this.observe.bind(this);
        this.handleIntersection = this.handleIntersection.bind(this);
        this.scrollNavListToBottom = this.scrollNavListToBottom.bind(this);
        this.observer = undefined;
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount () {

        const {
            productGroups,
        } = this.props;

        if (!Array.isArray(productGroups) || productGroups.length === 0) {
            this.props.cardActions.getProductGroups().then(result => {
                this.switchGroup(result.grpids[0]);
            });
        } else {
            this.switchGroup(productGroups[0].grpid);
        }
    }

    componentWillUnmount () {
        if(this.observer) {
            this.observer.disconnect();
        }
    }

    switchGroup(groupid){
        
        if(groupid === this.state.currentGroupid) {
            return;
        }

        this.setState({
            sidebar: true,
            currentGroupid: groupid,
        }, () => {
            this.listCardProducts();
        })
    }

    listCardProducts() {

        const {
            currentGroupid,
            isLoading,
        } = this.state;

        if(isLoading) {
            return;
        }

        const {
            cardProductIdsByGroupId,
        } = this.props;

        var cardProductIds = cardProductIdsByGroupId[currentGroupid];

        var start = 0;

        if(Array.isArray(cardProductIds)) {
            start = cardProductIds.length;
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.cardActions.listCardProducts({
                start: start,
                productgrpid: currentGroupid
            }).then((result) => {
                const {
                    currentGroupid,
                } = this.state;
        
                const {
                    cardProductIdsByGroupId,
                } = this.props;
                let productList = cardProductIdsByGroupId[currentGroupid];
                if(Array.isArray(productList) && productList.length === 0){
                    this.setState({
                        isEmpty: true,
                    });
                }else{
                    this.setState({
                        isEmpty: false,
                    });
                }
                this.setState({
                    isLoading: false,
                });
            }, (error) => {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            });
        })
    }

    observe(ref) {

        if (this.observer) {
            return;
        }

        this.observer = new IntersectionObserver(entries => {
            const firstEntry = entries[0];
            this.handleIntersection(firstEntry.isIntersecting);
        }, observerOptions);

        this.triggerRef = ref;
        this.observer.observe(ref);
    }

    handleIntersection(isIntersecting) {
        this.setState({
            isShowScrollHint: !isIntersecting,
        });
    }

    scrollNavListToBottom () {

        if (!this.triggerRef) {
            return;
        }

        this.triggerRef.scrollIntoView(scrollIntoViewOptions);
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {
        
        const {
            currentGroupid,
            isShowScrollHint,
        } = this.state;

        const {
            cardProductIdsByGroupId,
            productGroups,
        } = this.props;
        
        let productList = cardProductIdsByGroupId[currentGroupid];
        
        return (
                <CardContent>
                    <CardNav>
                        {
                            isShowScrollHint && (
                                <ScrollHint onClick={this.scrollNavListToBottom}>
                                    <i className="zmdi zmdi-chevron-down"></i>
                                </ScrollHint>
                            )
                        }
                        <CardNavList>
                            {productGroups.map((group, index)=>{

                                if (index === productGroups.length - 1) {
                                    return (
                                        <CardNavItem 
                                            key={index}
                                            onClick={this.switchGroup.bind(this, group.grpid)}
                                            active={group.grpid === this.state.currentGroupid}
                                            ref={this.observe}
                                        >
                                            {group.grpname}
                                        </CardNavItem>
                                    );
                                }

                                return (
                                    <CardNavItem 
                                        key={index}
                                        onClick={this.switchGroup.bind(this, group.grpid)}
                                        active={group.grpid === this.state.currentGroupid}
                                    >
                                        {group.grpname}
                                    </CardNavItem>
                                );
                            })}
                        </CardNavList>
                        {this.props.groupIsloading ?
                            <LoadingSidebar>
                                <GlobalStyle.Spin />
                            </LoadingSidebar>
                            :null
                        } 
                        {this.props.groupError ?
                            <GlobalStyle.ErrorContainer>
                                <GlobalStyle.ErrorContainerTitle>
                                    發生錯誤
                                </GlobalStyle.ErrorContainerTitle>
                                <GlobalStyle.ErrorContainerDesription>
                                    無法取得列表，請重新整理後再嘗試。
                                </GlobalStyle.ErrorContainerDesription>
                            </GlobalStyle.ErrorContainer>
                            :null
                        }
                    </CardNav>
                    <ProductListWrapper>
                        {this.renderContent(productList)}
                        {this.props.isloading ?
                            <GlobalStyle.LoadingContainer>
                                <GlobalStyle.Spin />
                            </GlobalStyle.LoadingContainer>
                            :
                            null
                        }   
                        {this.props.error ?
                            <GlobalStyle.ErrorContainer>
                                <GlobalStyle.ErrorContainerTitle>
                                    發生錯誤
                                </GlobalStyle.ErrorContainerTitle>
                                <GlobalStyle.ErrorContainerDesription>
                                    無法取得熱門商品列表，請重新整理後再嘗試。
                                </GlobalStyle.ErrorContainerDesription>
                            </GlobalStyle.ErrorContainer>
                            :
                            null
                        }
                        {this.state.isEmpty ? 
                            <GlobalStyle.ErrorContainer>
                                <GlobalStyle.ErrorContainerTitle>
                                    查無資料
                                </GlobalStyle.ErrorContainerTitle>
                                <GlobalStyle.ErrorContainerDesription>
                                    目前此類查無商品
                                </GlobalStyle.ErrorContainerDesription>
                            </GlobalStyle.ErrorContainer>
                        :null
                        }
                    </ProductListWrapper>
                </CardContent>
        );
    }
    renderContent(cardProductIds){
        
        if(!Array.isArray(cardProductIds) || cardProductIds.length === 0){
            return null;
        }
        
        
        return (
            <div>
                <CupoyListRepeat
                    ref={_ref => (this.list = _ref)}
                    rowRenderer={(item, listWidth, listHeight) => { return this.rowRenderer(item, listWidth, listHeight, cardProductIds)}}
                    listId={this.constructor.name}
                    items={cardProductIds}
                    isLoading={this.props.isloading}
                    useWindowScroll={true}
                    restoreWindowScroll={true}
                    loadMoreRows={()=>this.loadMoreList(cardProductIds)}
                />
            </div>
        )
    }

    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it),
        parent,
        measure,
        columnIndex,
        updateRowSize,
        measureCache,
        listWidth,
    }, width, listHeight,cardProductIds){
        
        const {
            cardProductMap,
        } = this.props;

        const id = cardProductIds[index];
        const cardProduct = cardProductMap[id];
        
        if(!cardProduct){
            return null;
        }
        
        return (
            <CupoySrefView 
                statename={CupoyRouter.STATES.CARDINFORMATION}
                stylecomponent={ProductList}
                key={index}
                params={{id}}
            >
                <ProductImage imageUrl={GLOBAL.imgSrcRootURL +`/goodimgs/${cardProduct.productid}/${cardProduct.thumbimg}`}/>
                <ProductInfo>
                    <ProductName>
                        {cardProduct.productname}
                    </ProductName>
                    <PriceWrapper>
                        <Price>
                            原價:{cardProduct.marketprice}元
                        </Price>
                        <NewPrice>
                            會員:
                            <MemberPrice>
                                {cardProduct.memberprice}
                                <StrongText>元</StrongText>
                            </MemberPrice>
                        </NewPrice>
                    </PriceWrapper>
                </ProductInfo>
            </CupoySrefView>
        )
    }
    loadMoreList(cardProductIds){
        
        this.props.cardActions.listCardProducts({
            start: cardProductIds.length,
        });
    }

}
/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        cardProductMap: state.card.cardProductMap,
        productGroups: state.card.productGroups,
        cardProductIdsByGroupId: state.card.cardProductIdsByGroupId,
        vitemProductIds: state.card.vitemProductIds,
        freeProductIds: state.card.freeProductIds,
        bonusProductIds: state.card.bonusProductIds,

        isloading: state.card.isloading,
        error: state.card.error,
        groupIsloading: state.card.groupIsloading,
        groupError: state.card.groupError,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        cardActions: bindActionCreators(actionCreators.cardActionCreators, dispatch),
        gameActions: bindActionCreators(actionCreators.gameActionCreators, dispatch),
        
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(PointCardView);