/*=====================================
    RootSwitchView

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, {
    useRef,
    useState,
    useEffect,
} from "react";
import { Route, Switch } from "react-router-dom";
import CupoyRouter from "components/commons/router/CupoyRouter";
import WebViewBridgeUtil from '../WebViewBridgeUtil';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import GLOBAL from '../../../nonnmwebglobal';
import useActions from 'actions/hooks/useActions';
import actionCreators from 'actions/creators';
import { useRouteMatch } from 'react-router';
import HomeViewComponent from 'components/home/HomeView';
import CardInformationViewComponent from 'components/card/CardInformationView';
import CardViewComponent from 'components/card/CardView';
import VitemViewComponent from 'components/vitem/VitemView';
import TransactionListViewComponent from 'components/vitem/TransactionListView';
import SellDetailViewComponent from 'components/vitem/SellDetailView';
import SellFormViewComponent from 'components/vitem/SellFormView';
import PurchaseInfoViewComponent from 'components/vitem/PurchaseInfoView';
import BuyDetailViewComponent from 'components/vitem/BuyDetailView';
import ProductMessageViewComponent from 'components/vitem/ProductMessageView';
import CartViewComponent from 'components/cart/CartView';
import CheckoutViewComponent from 'components/commons/CheckoutView';
import PaymentInfoViewComponent from 'components/commons/PaymentInfoView';
import MemberViewComponent from 'components/member/MemberView';
import MyCommodityViewComponent from 'components/member/MyCommodityView';
import SystemMessageViewComponent from 'components/member/SystemMessageView';
import HistoryRecordViewComponent from 'components/member/HistoryRecordView';
import MemberInfoViewComponent from 'components/member/MemberInfoView';
import ForgotAccountViewComponent from 'components/member/ForgotAccountView';
import ForgetPwdComponent from 'components/member/ForgetPwdView';
import ResetPwdComponent from 'components/member/ResetPwdView';
import ResetAddressComponent from 'components/member/ResetAddressView';
import ResetNicknameComponent from 'components/member/ResetNicknameView';
import ResetMailViewComponent from 'components/member/ResetMailView';
import ResetMobileComponent from 'components/member/ResetMobileView';
import RegisterMemberComponent from 'components/member/MemberRegisterView';
import NewsListViewComponent from 'components/home/NewsListView';
import NewsContentViewComponent from 'components/home/NewsContentView';
import ProductBuyListViewComponent from 'components/home/ProductBuyListView';
import ProductBuyContentViewComponent from 'components/home/ProductBuyContentView';
import GoodPromotionsViewComponent from 'components/home/GoodPromotionsView';
import GoodPromotionsContentViewComponent from 'components/home/GoodPromotionsContentView';
import MemberLoginViewComponent from 'components/member/MemberLoginView';
import MemberBonusViewComponent from 'components/member/bonus/MemberBonusView';
import MemberBonusLogsViewComponent from 'components/member/bonus/MemberBonusLogsView';
import MemberBonusDepositViewComponent from 'components/member/bonus/MemberBonusDepositView';
import MemberBonusOrderViewComponent from 'components/member/bonus/MemberBonusOrderView';
import MemberBonusOrderDetailViewComponent from 'components/member/bonus/MemberBonusOrderDetailView';
import MemberInvoiceViewComponent from 'components/member/invoice/MemberInvoiceView';
import MemberContactServiceViewComponent from 'components/member/contact/MemberContactServiceView';
import MemberAddComplaintViewComponent from 'components/member/contact/MemberAddComplaintView';
import MemberComplaintLogsViewComponent from 'components/member/contact/MemberComplaintLogsView';
import MemberComplaintViewComponent from 'components/member/contact/MemberComplaintView';
import MemberRefundViewComponent from 'components/member/refund/MemberRefundView';
import MemberRefundApplyViewComponent from 'components/member/refund/MemberRefundApplyView';
import MemberRefundLogsViewComponent from 'components/member/refund/MemberRefundLogsView';
import MemberVitemOrderListViewComponent from 'components/member/vitem/MemberVitemOrderListView';
import MemberVitemOrderSellViewComponent from 'components/member/vitem/MemberVitemOrderSellView';
import MemberVitemOrderBuyViewComponent from 'components/member/vitem/MemberVitemOrderBuyView';
import MemberCardOrderListViewComponent from 'components/member/card/MemberCardOrderListView';
import MemberCardOrderDetailListViewComponent from 'components/member/card/MemberCardOrderDetailListView';
import MemberCardOrderDetailViewComponent from 'components/member/card/MemberCardOrderDetailView';
import MemberCardOrderUnpayDetailViewComponent from 'components/member/card/MemberCardOrderUnpayDetailView';
import AboutViewComponent from 'components/member/AboutView';
import MailIdentifyViewComponent from 'components/member/MailIdentifyView';
import UpdateInvoiceViewComponent from 'components/member/UpdateInvoiceView';
import ResetIdNumberViewComponent from 'components/member/ResetIdNumberView';
import ResetBirthdayViewComponent from 'components/member/ResetBirthdayView';
import BonusManualViewComponent from 'components/help/BonusManualView';
import MemberTermsViewComponent from 'components/help/MemberTermsView';
import DisclaimerViewComponent from 'components/help/DisclaimerView';
import PrivacyPolicyViewComponent from 'components/help/PrivacyPolicyView';
import HelpViewComponent from 'components/help/HelpView';
import UpgradeMembershipViewComponent from 'components/help/UpgradeMembershipView';
import TransactionOrderViewComponent from 'components/help/TransactionOrderView';
import MemberSuspensionOfPowerViewComponent from 'components/help/MemberSuspensionOfPowerView';
import TransactionSecurityViewComponent from 'components/help/TransactionSecurityView';

/*--------------------------
    Main Component
--------------------------*/
const CupoyRouterSwitchView = () => {

    const {
        webActions,
    } = useActions({
        webActions: actionCreators.webActionCreators,
    });

    const history = useHistory();
    const location = useLocation();
    const isHome = !!useRouteMatch({ path: CupoyRouter.PATHS.HOME, exact: true });

    const previousLocation = useRef();

    const onAppWebviewMessage = event => {

        const cmd = JSON.parse(event.data);

        if(cmd.command){
            switch(cmd.command){
                case 'setAppMd5Key':
                    GLOBAL.setAppMd5Key(cmd.key);
                    break;
                case 'setAppPlatform':
                    webActions.setPlatform(cmd.key);
                    break;
                case 'wv_goBack':
                    history.goBack();
                    break;
                case 'wv_leavePayment':
                    // 直接重新整理，但 app 的網址是 file:///，不能直接用 window.location.reload()，會閃退
                    // 所以這邊先回到上一頁再連回來
                    // history.goBack();
                    // history.goForward();
                    // WebViewBridgeUtil.postPayByCreditCardFinishedCallback();
                    const current = CupoyRouter.getCurrent();

                    history.goBack();

                    if(current) {
                        setTimeout(() => {
                            const statename = current.state.name;
                            const params = current.params;
                            const querys = current.querys;
                            const locationState = current.locationState;

                            webActions.cupoyStateGo(statename, params, querys, locationState);
                            // 重整後通知webview關閉付款畫面
                            WebViewBridgeUtil.postPayByCreditCardFinishedCallback();
                        }, 10);
                    }
                    break;
                default:
                    break;
            }
        }
    };

    const initWebViewBridge = () => {
        WebViewBridgeUtil.init(onAppWebviewMessage);
    };

    const postStateChangedMessage = () => {
        WebViewBridgeUtil.postStateChangedMessage(isHome);
    };

    useEffect(() => {
        initWebViewBridge();
    }, []);

    useEffect(() => {

        postStateChangedMessage();

        // set previousLocation if props.location is not modal
        if (history.action !== 'POP' && (!location.state || !location.state.modal)) {
            previousLocation.current = location;
        }
    }, [history, location]);

    return (
        <div>
            <Switch location={location}>
                {HomeViewComponent && <Route path={CupoyRouter.PATHS.HOME} component={HomeViewComponent} exact/>}
                {CardViewComponent && <Route path={CupoyRouter.PATHS.CARD} component={CardViewComponent} />}
                {CardInformationViewComponent && <Route path={CupoyRouter.PATHS.CARDINFORMATION} component={CardInformationViewComponent}/>}
                {VitemViewComponent && <Route path={CupoyRouter.PATHS.VITEM} component={VitemViewComponent} exact/>}
                {TransactionListViewComponent && <Route path={CupoyRouter.PATHS.TRANSACTIONLIST} component={TransactionListViewComponent} />}
                {SellDetailViewComponent && <Route path={CupoyRouter.PATHS.SELLDETAIL} component={SellDetailViewComponent} />}
                {SellFormViewComponent && <Route path={CupoyRouter.PATHS.SELLFORM} component={SellFormViewComponent} exact/>}
                {PurchaseInfoViewComponent && <Route path={CupoyRouter.PATHS.PURCHASEINFO} component={PurchaseInfoViewComponent} />}
                {BuyDetailViewComponent && <Route path={CupoyRouter.PATHS.BUYDETAIL} component={BuyDetailViewComponent} />}
                {ProductMessageViewComponent && <Route path={CupoyRouter.PATHS.PRODUCTMESSAGE} component={ProductMessageViewComponent} exact/>}
                {CartViewComponent && <Route path={CupoyRouter.PATHS.CART} component={CartViewComponent} exact/>}
                {CheckoutViewComponent && <Route path={CupoyRouter.PATHS.CARTCHECKOUT} component={CheckoutViewComponent} exact/>}
                {PaymentInfoViewComponent && <Route path={CupoyRouter.PATHS.CART_PAYMENT_INFO} component={PaymentInfoViewComponent} exact/>}
                {CheckoutViewComponent && <Route path={CupoyRouter.PATHS.PRODUCTCHECKOUT} component={CheckoutViewComponent} exact/>}
                {PaymentInfoViewComponent && <Route path={CupoyRouter.PATHS.PRODUCT_PAYMENT_INFO} component={PaymentInfoViewComponent} exact/>}                 
                {MemberViewComponent && <Route path={CupoyRouter.PATHS.MEMBER} component={MemberViewComponent} exact/>}
                {MyCommodityViewComponent && <Route path={CupoyRouter.PATHS.MYCOMMODITY} component={MyCommodityViewComponent} exact/>}
                {SystemMessageViewComponent && <Route path={CupoyRouter.PATHS.SYSTEMMESSAGE} component={SystemMessageViewComponent} exact/>}
                {HistoryRecordViewComponent && <Route path={CupoyRouter.PATHS.HISTORYRECORD} component={HistoryRecordViewComponent} exact/>}
                {ForgetPwdComponent && <Route path={CupoyRouter.PATHS.FORGOT_ACCOUNT} component={ForgotAccountViewComponent} exact/>}
                {ForgetPwdComponent && <Route path={CupoyRouter.PATHS.FORGETPWD} component={ForgetPwdComponent} exact/>}
                {MemberInfoViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_INFO} component={MemberInfoViewComponent} exact/>}
                {MemberLoginViewComponent && <Route path={CupoyRouter.PATHS.MEMBERLOGIN} component={MemberLoginViewComponent} exact/>}
                {ResetPwdComponent && <Route path={CupoyRouter.PATHS.RESETPWD} component={ResetPwdComponent} exact/>}
                {ResetAddressComponent && <Route path={CupoyRouter.PATHS.RESETADDRESS} component={ResetAddressComponent} />}
                {ResetNicknameComponent && <Route path={CupoyRouter.PATHS.RESETNICKNAME} component={ResetNicknameComponent} />}
                {ResetMailViewComponent && <Route path={CupoyRouter.PATHS.RESETMAIL} component={ResetMailViewComponent} />}
                {ResetMobileComponent && <Route path={CupoyRouter.PATHS.RESETMOBILE} component={ResetMobileComponent} />}
                {RegisterMemberComponent && <Route path={CupoyRouter.PATHS.REGISTER} component={RegisterMemberComponent} />}
                {NewsListViewComponent && <Route path={CupoyRouter.PATHS.NEWSLIST} component={NewsListViewComponent} exact/>}
                {NewsContentViewComponent && <Route path={CupoyRouter.PATHS.NEWSCONTENT} component={NewsContentViewComponent} />}
                {ProductBuyListViewComponent && <Route path={CupoyRouter.PATHS.PRODUCTBUYLIST} component={ProductBuyListViewComponent} exact/>}
                {ProductBuyContentViewComponent && <Route path={CupoyRouter.PATHS.PRODUCTBUYCONTENT} component={ProductBuyContentViewComponent}/>}
                {GoodPromotionsViewComponent && <Route path={CupoyRouter.PATHS.GOODPROMOTIONS} component={GoodPromotionsViewComponent} exact/>}
                {GoodPromotionsContentViewComponent && <Route path={CupoyRouter.PATHS.GOODPROMOTIONSCONTENT} component={GoodPromotionsContentViewComponent} />}
                {MemberBonusViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_BONUS} component={MemberBonusViewComponent} exact/>}
                {MemberBonusLogsViewComponent &&<Route path={CupoyRouter.PATHS.MEMBER_BONUS_LOGS} component={MemberBonusLogsViewComponent} exact/>}
                {MemberBonusDepositViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_BONUS_DEPOSIT} component={MemberBonusDepositViewComponent} exact/>}
                {MemberBonusOrderViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_BONUS_ORDER} component={MemberBonusOrderViewComponent} exact/>}
                {MemberBonusOrderDetailViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_BONUS_ORDER_DETAIL} component={MemberBonusOrderDetailViewComponent} exact/>}
                {MemberInvoiceViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_INVOICE} component={MemberInvoiceViewComponent} exact/>}
                {MemberContactServiceViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_CONTACT_SERVICE} component={MemberContactServiceViewComponent} exact/>}
                {MemberAddComplaintViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_ADD_COMPLAINT} component={MemberAddComplaintViewComponent} exact/>}
                {MemberComplaintLogsViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_COMPLAINT_LOGS} component={MemberComplaintLogsViewComponent} exact/>}
                {MemberComplaintViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_COMPLAINT} component={MemberComplaintViewComponent}/>}
                {MemberRefundViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_REFUND} component={MemberRefundViewComponent} exact/>}
                {MemberRefundApplyViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_REFUND_APPLY} component={MemberRefundApplyViewComponent} exact/>}
                {MemberRefundLogsViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_REFUND_LOGS} component={MemberRefundLogsViewComponent} exact/>}
                {MemberVitemOrderListViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_VITEM_ORDER_LIST} component={MemberVitemOrderListViewComponent}/>}
                {MemberVitemOrderSellViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_VITEM_ORDER_SELL} component={MemberVitemOrderSellViewComponent} exact/>}
                {MemberVitemOrderBuyViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_VITEM_ORDER_BUY} component={MemberVitemOrderBuyViewComponent} exact/>}
                {MemberCardOrderListViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_CARD_ORDER_LIST} component={MemberCardOrderListViewComponent}/>}
                {MemberCardOrderDetailListViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_CARD_ORDER_DETAIL_LIST} component={MemberCardOrderDetailListViewComponent} exact/>}
                {MemberCardOrderDetailViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_CARD_ORDER_DETAIL} component={MemberCardOrderDetailViewComponent} exact/>}
                {MemberCardOrderUnpayDetailViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_CARD_ORDER_UNPAY_DETAIL} component={MemberCardOrderUnpayDetailViewComponent} exact/>}
                {AboutViewComponent && <Route path={CupoyRouter.PATHS.ABOUT} component={AboutViewComponent} exact/>}
                {MailIdentifyViewComponent && <Route path={CupoyRouter.PATHS.MAIL_IDENTIFY} component={MailIdentifyViewComponent} exact/>}
                {MailIdentifyViewComponent && <Route path={CupoyRouter.PATHS.CHANGE_MAIL_IDENTIFY} component={MailIdentifyViewComponent} exact/>}
                {UpdateInvoiceViewComponent && <Route path={CupoyRouter.PATHS.UPDATE_INVOICE} component={UpdateInvoiceViewComponent} exact/>}
                {ResetIdNumberViewComponent && <Route path={CupoyRouter.PATHS.RESET_ID_NUMBER} component={ResetIdNumberViewComponent} exact/>}
                {ResetBirthdayViewComponent && <Route path={CupoyRouter.PATHS.RESET_BIRTHDAY} component={ResetBirthdayViewComponent} exact/>}
                {BonusManualViewComponent && <Route path={CupoyRouter.PATHS.BONUS_MANUAL} component={BonusManualViewComponent} exact/>}
                {MemberTermsViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_TERMS} component={MemberTermsViewComponent} exact/>}
                {DisclaimerViewComponent && <Route path={CupoyRouter.PATHS.DISCLAIMER} component={DisclaimerViewComponent} exact/>}
                {PrivacyPolicyViewComponent && <Route path={CupoyRouter.PATHS.PRIVACY_POLICY} component={PrivacyPolicyViewComponent} exact/>}
                {HelpViewComponent && <Route path={CupoyRouter.PATHS.HELP} component={HelpViewComponent} exact/>}
                {TransactionOrderViewComponent && <Route path={CupoyRouter.PATHS.TRANSACTION_ORDER} component={TransactionOrderViewComponent} exact/>}
                {UpgradeMembershipViewComponent && <Route path={CupoyRouter.PATHS.UPGRADE_MEMBERSHIP} component={UpgradeMembershipViewComponent} exact/>}
                {MemberSuspensionOfPowerViewComponent && <Route path={CupoyRouter.PATHS.MEMBER_SUSPENSION_OF_POWER} component={MemberSuspensionOfPowerViewComponent} exact/>}
                {TransactionSecurityViewComponent && <Route path={CupoyRouter.PATHS.TRANSACTION_SECURITY} component={TransactionSecurityViewComponent} exact/>}

            </Switch>
        </div>
    );
};

/*--------------------------
    Export
--------------------------*/
export default CupoyRouterSwitchView;