/*=====================================
    虛寶市集

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle } from 'stylecomponents';
import styled from 'styled-components';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import NumberFormat from 'react-number-format';
import GLOBAL from 'nonnmwebglobal';


/*--------------------------
    Styled Component
--------------------------*/

const TransactionList = styled.div`

    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #EEEEEE, inset 0 1px 0 0 #EEEEEE;
    cursor: pointer;

`;


const Bullets = styled.div`

    position: relative;
    padding-top: 7px;
    padding-right: 10px;
    left: 0;
    top: 0;
`;

const BulletsRound = styled.div`

    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.online ? '#54B62C' : '#D0021B'};

`;

const TransactionInfo = styled.div`
    width: 100%;
`;

const TransactionName = styled.div`

    padding-bottom: 8px;
    font-size: 16px;
    color: #333333;

`;

const StarWrapper = styled.span`
    padding-left: 8px;
`;

const StarStyle = styled.i.attrs({

    className: 'icon-star'
})`
    color: ${props => props.color};
    font-size: 12px;
    
`;

const ExchangeRate = styled.div`

    display: inline-block;
    font-size: 12px;
    color: #565151;

`;

// const RateNumber = styled.span`

//     margin-left: 8px;
//     color: #F75C2F;
//     font-weight: bolder;

// `;

const Arrow = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    color: #9B9B9B;

`;

/*--------------------------
    Main Component
--------------------------*/
class BuyView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        const routerState = CupoyRouter.getCurrent();
        const { params } = routerState;
        const { gameid, serverid } = params;

        this.state = {
            gameid,
            gameserverid: serverid,
            isEmpty: false,
        };
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        const {
            gameid,
            gameserverid,
        } = this.state;

        const purchaseListIdsByGameAndServerId = this.props.purchaseListIdsByGameAndServerId[`${gameid},${gameserverid}`];
        // console.log('BuyView componentDidMount', purchaseListIdsByGameAndServerId)
        if(!Array.isArray(purchaseListIdsByGameAndServerId) || purchaseListIdsByGameAndServerId.length === 0){
            this.props.vitemActions.listTopPurchases({
                gameid,
                gameserverid,
            });
            this.props.vitemActions.listPurchases({
                gameid,
                gameserverid,
            }).then((result)=>{
                const {
                    gameid,
                    serverid: gameserverid,
                } = this.props.match.params;
                const purchaseListIdsByGameAndServerId = this.props.purchaseListIdsByGameAndServerId[`${gameid},${gameserverid}`];
                if(!purchaseListIdsByGameAndServerId || purchaseListIdsByGameAndServerId.length === 0){
                    this.setState({
                        isEmpty: true,
                    });
                }
                
            });
        }
        
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {
        const {
            gameid,
            serverid: gameserverid,
        } = this.props.match.params;
        const purchaseListIdsByGameAndServerId = this.props.purchaseListIdsByGameAndServerId[`${gameid},${gameserverid}`];
        const purchaseMap = this.props.purchaseMap;
        let _purchaseListIdsByGameAndServerId = [];
        if(Array.isArray(purchaseListIdsByGameAndServerId)){
            _purchaseListIdsByGameAndServerId = purchaseListIdsByGameAndServerId;
        }
        
        return (
            <div>
                {_purchaseListIdsByGameAndServerId.map((key, index)=>{

                    const purchaser = purchaseMap[key];

                    const {
                        nickname,
                        ntdollar,
                        vitemdollar,
                        purchaseid,
                        gameid,
                        gameserverid,
                        viprovid,
                    } = purchaser;

                    const isOpen = GLOBAL.getVProviderIsOpen(purchaser);
                        
                    return (
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.BUYDETAIL}
                            stylecomponent={TransactionList}
                            params={{
                                purchaseid,
                                gameid,
                                gameserverid,
                                vprovmrscid: viprovid,
                            }}
                            key={index}
                        >
                            <Bullets>
                                <BulletsRound online={isOpen} />
                            </Bullets>
                            <TransactionInfo>
                                <TransactionName>
                                    {GLOBAL.VITEM_DISPLAY_TEXT}會員：{nickname}
                                    <StarWrapper>
                                        <StarStyle color="#00BCD4"></StarStyle>
                                    </StarWrapper>
                                </TransactionName>
                                <div>
                                    <ExchangeRate>
                                        比例(台幣：虛幣)
                                        <NumberFormat value={ntdollar} displayType={'text'} thousandSeparator={true} />
                                            :
                                        <NumberFormat value={vitemdollar} displayType={'text'} thousandSeparator={true} />
                                        
                                    </ExchangeRate>
                                </div>
                            </TransactionInfo>
                            <Arrow>
                                <i className="icon-chevron-right"></i>
                            </Arrow>
                        </CupoySrefView>
                    )
                })}
                {this.props.isloading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }
                {this.props.error ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            發生錯誤
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無法取得列表，請重新整理後再嘗試。
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }
                {this.state.isEmpty ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            查無資料
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無庫存資料
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }   
            </div>
        );
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        purchaseListIdsByGameAndServerId: state.vitem.purchaseListIdsByGameAndServerId,
        purchaseMap: state.vitem.purchaseMap,
        isloading: state.vitem.isloading,
        error: state.vitem.error,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        vitemActions: bindActionCreators(actionCreators.vitemActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(BuyView);