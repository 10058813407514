/*=====================================
    點卡產包-虛寶包

    Author: ac
    createtime: 2018 / 03 / 20
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle } from 'stylecomponents';
import styled from 'styled-components';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import GLOBAL from '../../nonnmwebglobal';

const ProductListWrapper = styled.div`

    flex-grow: 1;
    height: calc(100vh - 144px);
    overflow: auto;

`;

const ProductList = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #EEEEEE;

`;

const ProductImage = styled.div`

    min-width: 80px;
    height: 60px;
    margin-right: 16px;
    background-image: url('${props => props.imageUrl ? props.imageUrl : null}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

`;

const ProductInfo = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

`;

const ProductName = styled.div`

    padding-bottom: 8px;
    font-size: 14px;
    color: #333333;

`;


const Price = styled.del`

    display: inline-block;
    font-size: 11px;
    color: #808180;
    margin-right: 8px;

`;

const PriceWrapper = styled.div`

    display: flex;
    flex-wrap: wrap;

`;

const NewPrice = styled.div`

    font-size: 11px;
    color: #F75C2F;

`;

const StrongText = styled.span`

    font-weight: bolder;

`;

const MemberPrice = styled.span`

    padding-left: 5px;
    font-size: 14px;
    color: #F75C2F;

`;

/*--------------------------
    Main Component
--------------------------*/
class VitemProductsView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);
        this.state = {
            isEmpty: false,
        }
        this.renderList = this.renderList.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        const {vitemProductIds} = this.props;
        if(Array.isArray(vitemProductIds) && vitemProductIds.length === 0){
            this.props.cardActions.listVitemProducts().then(()=>{
                const {vitemProductIds} = this.props;
                if(Array.isArray(vitemProductIds) && vitemProductIds.length === 0){
                    this.setState({
                        isEmpty: true,
                    });
                }
                
            });
        }
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        return (
            <ProductListWrapper>
                {this.renderList()}
                {this.props.isloading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }
                {this.props.error ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            發生錯誤
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無法取得熱門商品列表，請重新整理後再嘗試。
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }
                {this.state.isEmpty ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            查無資料
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            目前此類查無商品
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                } 
            </ProductListWrapper>
        )
    }
    renderList(){

        const {
            vitemProductIds,
            cardProductMap,
        } = this.props;

        if(!Array.isArray(vitemProductIds) && vitemProductIds.length === 0){
            return null;
        }
        if(Object.keys(cardProductMap).length === 0){
            return null;
        }

        return (
            <div>
                {vitemProductIds.map((id, index)=>{
                    const product = cardProductMap[id];
                    return(
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.CARDINFORMATION}
                            stylecomponent={ProductList}
                            key={index}
                            params={{id}}
                        >
                            <ProductImage imageUrl={GLOBAL.imgSrcRootURL +`/goodimgs/${product.productid}/${product.thumbimg}`}/>
                            <ProductInfo>
                                <ProductName>
                                    {product.productname}
                                </ProductName>
                                <PriceWrapper>
                                    <Price>
                                        原價:
                                        {product.marketprice}元
                                    </Price>
                                    <NewPrice>
                                        會員:
                                        <MemberPrice>
                                            {product.memberprice}
                                            <StrongText>元</StrongText>
                                        </MemberPrice>
                                    </NewPrice>
                                </PriceWrapper>
                            </ProductInfo>
                        </CupoySrefView>
                    )
                })}
            </div>
        )
    }

}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        cardProductMap: state.card.cardProductMap,
        vitemProductIds: state.card.vitemProductIds,

        isloading: state.card.isloading,
        error: state.card.error,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        cardActions: bindActionCreators(actionCreators.cardActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(VitemProductsView);