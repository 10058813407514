/*=====================================
    與Webmessage 溝通相關

    Author: Ac
    CreateTime: 2020 / 07 / 06
=====================================*/
import GLOBAL from 'nonnmwebglobal';

/*--------------------------
    Private Variable
--------------------------*/
let canSendMessage = false;

/*--------------------------
    Private Method
--------------------------*/
/**
 * WebViewBridge 發送事件
 * @param {string} msg 
 */
function WKWebViewSendMessage(msg) {

    if(
        canSendMessage
        || (
            window
            && window.ReactNativeWebView
            && window.ReactNativeWebView.postMessage
        )
    ) {
        window.ReactNativeWebView.postMessage(JSON.stringify(msg));
        canSendMessage = true;
    }
}

// ------------------------------
// 發送取得AppMd5Key的事件給webview
// ------------------------------
function postGetAppMd5KeyMessage() {
    WKWebViewSendMessage({
        type: 'getAppMd5Key',
    });
}

// ------------------------------
// 發送取得App平台的事件給webview
// ------------------------------
function postGetAppPlatformMessage() {
    WKWebViewSendMessage({
        type: 'getAppPlatform',
    });
}

// ------------------------------
// 發送取得重整AppWebview的事件給webview
// ------------------------------
function postReloadAppMessage() {
    WKWebViewSendMessage({
        type: 'reloadApp',
    });
}

/*--------------------------
    Public Method
--------------------------*/
/**
 * 初始化 WebViewBridge
 */
function init(receiveMessage) {

    if (!GLOBAL.isAppFile) {
        return;
    }

    navigator.appVersion.includes('Android')
        ? document.addEventListener('message', receiveMessage, false)
        : window.addEventListener('message', receiveMessage, false);

    WKWebViewSendMessage({command:'initWebView'});
    postGetAppPlatformMessage();
    postGetAppMd5KeyMessage();
}

// ------------------------------
// 發開啟連結的事件給webview
// ------------------------------
function postLinkOpenMessage(link) {

    const msg = {
        type: 'openLink',
        link: link,
    };

    WKWebViewSendMessage(msg);
}

// ------------------------------
// 發送使用信用卡付款的事件給webview
// ------------------------------
function postPayByCreditCardMessage(form) {

    const msg = {
        type: 'payByCreditCard',
        form: form,
    };

    WKWebViewSendMessage(msg);
}

// ------------------------------
// 發送使用綠界付款的事件給webview
// ------------------------------
function postPayByECPayMessage(formInfo) {

    const msg = {
        type: 'payByECPay',
        formInfo,
    };

    WKWebViewSendMessage(msg);
}

// ------------------------------
// 發送使用信用卡付款成功的事件給webview
// ------------------------------
function postPayByCreditCardFinishedCallback() {

    const msg = {
        type: 'payByCreditCardFinishedCallback',
    };

    WKWebViewSendMessage(msg);
}

// ------------------------------
// state 更改時，吿知webview現在該頁是否為首頁
// ------------------------------
function postStateChangedMessage(isHome) {

    const msg = {
        type: 'stateChanged',
        isHome: isHome,
    };

    WKWebViewSendMessage(msg);
}

// ------------------------------
// 發送會員載具歸戶的事件給webview
// ------------------------------
function postJoinMemberCarrierMessage(formInfo) {
    WKWebViewSendMessage({
        type: 'joinMemberCarrierMessage',
        formInfo,
    });
}

/*--------------------------
    Export
--------------------------*/
export default {
    /*--------------------------
        Method
    --------------------------*/
    init, // 初始化 WebViewBridge
    postLinkOpenMessage, // 發開啟連結的事件給webview
    postPayByCreditCardMessage, // 發送使用信用卡付款的事件給webview
    postPayByECPayMessage, // 發送使用綠界付款的事件給webview
    postPayByCreditCardFinishedCallback, // 發送使用信用卡付款成功的事件給webview
    postStateChangedMessage, // state 更改時，吿知webview現在該頁是否為首頁
    postJoinMemberCarrierMessage, // 發送會員載具歸戶的事件給webview
    postReloadAppMessage, // 發送取得重整AppWebview的事件給webview
};