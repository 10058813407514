/*=====================================
    虛寶市集_出售區_商品詳情

    Author: Ac
    createtime: 2018 / 03 / 06
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { StepBottomBar } from 'stylecomponents';
import styled from 'styled-components';
import CupoyRouter from "components/commons/router/CupoyRouter";
import GLOBAL from '../../nonnmwebglobal';
import NumberFormat from 'react-number-format';
/*--------------------------
    Styled Component
--------------------------*/

const TransactionWrapper = styled.div`
    ${props => props.isPurchasePage ?
        'position: relative;background-color: #fff;z-index: 1;margin-top: -50px;'
        : null}
`;

const TransactionList = styled.div`

    display: flex;
    justify-content: center;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #EEEEEE, inset 0 1px 0 0 #EEEEEE;
`;

const TransactionImage = styled.div`

    min-width: 86px;
    height: 66px;
    margin-right: 16px;
    background-image: url('${props => props.imageUrl ? props.imageUrl : null}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

`;

const TransactionInfo = styled.div`

    width: 100%;

`;

const TransactionName = styled.div`

    padding-bottom: 8px;
    font-size: 16px;
    color: #4A4A4A;

`;

const Stock = styled.div`

    margin-right: 21px;
    font-size: 13px;
    color: #565151;

`;

const ExchangeRate = styled.div`

    display: inline-block;
    font-size: 13px;
    color: #565151;

`;

const RateNumber = styled.span`

    margin-left: 8px;
    color: #F75C2F;
    font-weight: bolder;
    font-size: 16px;

`;

const BuyQuantityList = styled.div`

    display: flex;
    justify-content: space-between;
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;

`;

const BuyTitle = styled.div`

    font-size: 14px;
    color: #333333;

`;

const BuyConversion = styled.div`

    font-size: 12px;
    color: #969494;

`;

const Amount = styled.input`

    min-width: 96px;
    height: 36px;
    border-radius: 3px;
    background-color: #ffffff;
    font-size: 14px;
    color: #333333;
    text-align: center;
    border: 1px solid ${props => props.error ? 'red' : '#CCCCCC'};

`;

const InfoContentWrapper = styled.div`
    padding: 16px;
    font-size: 13px;
    color: #969494;
`;

const InfoTitle = styled.div`
    font-weight: bolder;
    color: #777777;
`;

const InfoContent = styled.div`
    margin-bottom: 16px;
`;

const FromWrapper = styled.div`

    padding: 16px;
    background-color: #fff;
    font-size: 12px;
    color: #232423;
    
`;

const CharacterInput = styled.input.attrs({

})`
    &::placeholder{
        opacity: 0.5;
        font-size: 15px;
        color: #222328;
    }
    margin-top: 8px;
    margin-bottom: 16px;
    padding: 12px;
    width: 100%;
    height: 44px;
    background: #FAFAFA;
    border: 1px solid #DDDDDD;
    border-radius: 2px;

`;

const NarrativeTextarea = styled.textarea.attrs({

    placeholder: '請注意！伺服器內角色所填寫資料,如與實際交易角色ID不同,供應商將取消該筆訂單'
})`
    &::placeholder{
        opacity: 0.5;
        font-size: 15px;
        color: #222328;
    }
    margin-top: 8px;
    margin-bottom: 16px;
    padding: 12px;
    width: 100%;
    height: 86px;
    background: #FAFAFA;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    
`;

const ToCheckout = styled.div`

    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #F75C2F;
    border-radius: 2px;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;

`;

const Commission = styled.div`

    padding: 16px;
    font-size: 14px;
    color: #333333;
    border-bottom: 1px solid #DDDDDD;
`;

const CommissionPrice = styled.div`
    font-size: 13px;
    color: #565656;
`;

/*--------------------------
    Main Component
--------------------------*/
class ProductInfoView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {
        // floor
        super(props);

        const {
            gameid,
            serverid: gameserverid,
            viprovid,
        } = props.match.params;
        const sellerMap = props.sellerMap[`${viprovid},${gameid},${gameserverid}`];

        const {
            inventory,
            vitemdollar,
            ntdollar,
        } = sellerMap;

        const maxDollar = Math.floor(inventory / (vitemdollar / ntdollar));
        const minDollar = Math.ceil(100 / ntdollar) * ntdollar;

        this.state = {
            dollar: '',
            minDollar,
            maxDollar,
            error_dollarInput: false,
            isPurchasePage: false,
            viprovname: '',
            serverrole: '',
            tradetime: '',
            tradeprocess: '',
        };
        this.inputDollar = this.inputDollar.bind(this);
        this.validateDollar = this.validateDollar.bind(this);
        this.error_focus = this.error_focus.bind(this);
        this.updateServerRole = this.updateServerRole.bind(this);
        this.updateTradeTime = this.updateTradeTime.bind(this);
        this.updateTradeProcess = this.updateTradeProcess.bind(this);

    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        const gameListMap = this.props.gameListMap;

        if (!Object.keys(gameListMap).length > 0) {
            this.props.gameActions.listGames();
        }
    }
    inputDollar(event) {

        this.setState({ dollar: Number(event.target.value) });
    }

    error_focus() {
        this.numberInput && this.numberInput.focus();
    }

    updateViprovName(event) {
        this.setState({
            viprovname: event.target.value,
        });
    }
    updateServerRole(event) {
        this.setState({
            serverrole: event.target.value,
        });
    }

    updateTradeTime(event) {
        this.setState({
            tradetime: event.target.value,
        });
    }

    updateTradeProcess(event) {
        this.setState({
            tradeprocess: event.target.value,
        });
    }

    validateDollar(isOpen, buildOrder) {

        if (!isOpen) {
            return;
        }

        const dollar = Number(this.numberInput.value);

        const {
            gameid,
            serverid: gameserverid,
            viprovid,
        } = this.props.match.params;

        const {
            ntdollar,
            vitemdollar,
            gamename,
            commission,
            nickname,
        } = this.props.sellerMap[`${viprovid},${gameid},${gameserverid}`];

        const {
            maxDollar,
            minDollar,
            serverrole,
            tradetime,
            tradeprocess
        } = this.state;

        const {
            initOrder,
            buildVItemCartOrder,
        } = this.props.vitemActions;

        const proportion = vitemdollar / ntdollar;

        const commissionPrice = commission ?
            Math.round((this.state.dollar / 100) * commission)
            : 0;

        let totalPrice = (dollar * proportion);

        if (commission) {
            totalPrice -= (commissionPrice * proportion);
            totalPrice = Math.round(totalPrice);
        }

        const txtcontent = '<b>伺服器內角色</b> : ' + serverrole + '<br /><b>交易時間</b> : ' + tradetime + '<br /><b>交易過程詳述</b> : ' + tradeprocess;

        if (dollar >= minDollar && dollar <= maxDollar && dollar <= 10000 && dollar >= 100 && (dollar % ntdollar === 0)) {
            this.setState({
                dollar,
                error_dollarInput: false,
                isPurchasePage: true,
            });

            if (buildOrder) {
                if (!serverrole) {
                    this.props.webActions.openAlertDialog({
                        title: '錯誤訊息',
                        description: '請輸入伺服器內角色',
                        closeName: '關閉',

                    });
                    return;
                }
                // 需要建立訂單 執行buildVItemCartOrder
                buildVItemCartOrder({
                    amount: dollar, // 購買金額（NT）
                    vitemamount: totalPrice, // 購買金額（VITEM）
                    gameid,
                    gameserverid,
                    viprovid,
                    gamename,
                    txtcontent,
                    viprovname: nickname,
                }).then(() => {
                    this.props.webActions.cupoyStateGo(CupoyRouter.STATES.PRODUCTCHECKOUT);
                }, (error) => {
                    var errorMsg = '發生無法預期的錯誤';
                    var errorDescription = '';

                    if (error && error.code) {
                        switch (error.code) {
                            case 'error.privilege.noLogin':
                                return;
                            default:
                                errorMsg = error.message;
                                break;
                        }
                    }

                    this.props.webActions.openAlertDialog({
                        title: errorMsg,
                        description: errorDescription,
                    });
                })
            } else {
                //如果還沒要建立訂單 便執行initOrder
                initOrder({
                    gameid,
                    gameserverid,
                    viprovid,
                });
            }
        } else {
            this.setState({
                error_dollarInput: true,
            }, () => {

                let description = ``;

                if (dollar > 10000)
                    description = `購買金額最高不得大於一萬`;
                else if (dollar < 100)
                    description = `購買金額最低不得小於一百`;
                else if (minDollar > maxDollar)
                    description = `庫存不足`;
                else
                    description = `購買金額只能大於${minDollar}小於${maxDollar}且為${ntdollar}的倍數`;

                this.props.webActions.openAlertDialog({
                    title: '錯誤訊息',
                    description,
                    closeName: '關閉',
                    closeCallback: () => this.error_focus(),
                });
            });
        };
    }
    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            gameid,
            serverid: gameserverid,
            viprovid,
        } = this.props.match.params;

        const game = this.props.gameListMap[gameid];
        const sellerMap = this.props.sellerMap[`${viprovid},${gameid},${gameserverid}`];
        const haslogin = this.props.haslogin;

        const {
            inventory,
            nickname,
            vitemdollar,
            ntdollar,
            notice,
            commission,
        } = sellerMap;

        const isOpen = GLOBAL.getVProviderIsOpen(sellerMap);

        const proportion = vitemdollar / ntdollar;
        const commissionPrice = commission ?
            Math.round((this.state.dollar / 100) * commission)
            : 0;

        let conversionPrice = (this.state.dollar * proportion) - (commissionPrice * proportion);
        conversionPrice = Math.round(conversionPrice);

        return (
            <div>
                <TransactionWrapper isPurchasePage={this.state.isPurchasePage}>
                    <TransactionList>
                        {this.state.isPurchasePage ? null
                            : <TransactionImage imageUrl={GLOBAL.imgSrcRootURL + `/gameimgs/${game.gameid}/${game.thumbimg}`} />
                        }
                        <TransactionInfo>
                            <TransactionName>遊戲暱稱：{nickname}</TransactionName>
                            <div>
                                <ExchangeRate>
                                    比例(台幣：虛幣)
                                    <RateNumber>
                                        <NumberFormat value={ntdollar} displayType={'text'} thousandSeparator={true} />
                                    :
                                    <NumberFormat value={vitemdollar} displayType={'text'} thousandSeparator={true} />
                                    </RateNumber>
                                </ExchangeRate>
                                <NumberFormat value={inventory} displayType={'text'} thousandSeparator={true} renderText={value => <Stock>貨幣庫存：{value}</Stock>} />
                            </div>
                        </TransactionInfo>
                    </TransactionList>
                    <BuyQuantityList>
                        <div>
                            <BuyTitle>購買金額（台幣）</BuyTitle>
                            <BuyConversion>
                                換算為虛幣金額
                                <NumberFormat value={conversionPrice ? conversionPrice : 0} displayType={'text'} thousandSeparator={true} />

                            </BuyConversion>
                        </div>
                        <Amount
                            type="number"
                            min={this.state.minDollar}
                            max={this.state.maxDollar}
                            value={this.state.dollar}
                            step={ntdollar}
                            ref={(input) => { this.numberInput = input; }}
                            error={this.state.error_dollarInput}
                            onChange={(event) => this.inputDollar(event)}
                        />
                    </BuyQuantityList>
                    {this.state.isPurchasePage ? null
                        : <div>
                            <InfoContentWrapper>
                                <InfoTitle>交易時間與地點：</InfoTitle>
                                <InfoContent>{notice}</InfoContent>
                                <InfoTitle>提示：</InfoTitle>
                                <InfoContent>
                                    負擔手續費{commission}%
                                    共{commissionPrice ? commissionPrice : 0}元<br />
                                    (買家可累積發財金，索取發票中千萬)
                                </InfoContent>
                                <InfoTitle>發財金積點：</InfoTitle>
                                <InfoContent>
                                    {this.state.dollar ?
                                        Math.floor(this.state.dollar / 100)
                                        : 0}
                                    點
                                </InfoContent>
                            </InfoContentWrapper>
                            <StepBottomBar.StepBarWrapper>
                                <StepBottomBar.StepBarText>
                                    總價：
                                    <NumberFormat value={this.state.dollar ? this.state.dollar : 0} displayType={'text'} thousandSeparator={true} renderText={value => <StepBottomBar.StepBarPrice>{value}元</StepBottomBar.StepBarPrice>} />

                                </StepBottomBar.StepBarText>
                                {haslogin ?
                                    <StepBottomBar.StepBarButton
                                        onClick={() => this.validateDollar(isOpen)}
                                        disable={!isOpen}
                                    >
                                        立刻購買
                                    </StepBottomBar.StepBarButton>

                                    :

                                    <StepBottomBar.StepBarButton
                                        onClick={() => {
                                            this
                                                .props
                                                .webActions
                                                .cupoyStateGo(CupoyRouter.STATES.MEMBERLOGIN);
                                        }}
                                    >
                                        請先登入
                                </StepBottomBar.StepBarButton>


                                }
                            </StepBottomBar.StepBarWrapper>
                        </div>
                    }
                    {this.state.isPurchasePage
                        ? <div>
                            <Commission>
                                買家(會員)負擔手續費{commission}%(內含)
                                <NumberFormat value={this.state.dollar ? this.state.dollar : 0} displayType={'text'} thousandSeparator={true} renderText={value => <CommissionPrice>總價共{value}元</CommissionPrice>} />
                            </Commission>
                            <FromWrapper>
                                伺服器內角色
                            <CharacterInput
                                    onChange={(event) => this.updateServerRole(event)}
                                    value={this.state.serverole}
                                />
                            交易時間
                            <CharacterInput
                                    value={this.state.tradetime}
                                    onChange={(event) => this.updateTradeTime(event)}
                                />
                            交易過程詳述
                            <NarrativeTextarea
                                    value={this.state.tradeprocess}
                                    onChange={(event) => this.updateTradeProcess(event)}
                                />
                                <ToCheckout onClick={() => this.validateDollar(isOpen, true)}>
                                    前往結帳
                            </ToCheckout>
                            </FromWrapper>
                        </div>
                        : null
                    }
                </TransactionWrapper>
            </div>
        );
    }
}


/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function (state) {
    return {
        haslogin: state.member.haslogin,
        sellerMap: state.vitem.sellerMap,
        gameListMap: state.game.gameListMap,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function (dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        vitemActions: bindActionCreators(actionCreators.vitemActionCreators, dispatch),
        gameActions: bindActionCreators(actionCreators.gameActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(ProductInfoView);