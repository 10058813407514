/*=====================================
    確認視窗

    Author: Gray
    createtime: 2017 / 10 / 25
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from "actions/creators";
import styled from 'styled-components';
import { GlobalStyle, Colors, Button, DialogStyle } from 'stylecomponents';

/*--------------------------
    Style Component
--------------------------*/
const Title = styled.h6`
    line-height: 25px;
    font-size: 18px;
    font-weight: bold;
    color: #565151;
    text-align: center;
`;
const Description = styled.p`
    line-height: 20px;
    margin: 12px 0 0 0;
    font-size: 13px;
    color: #8A807F;
    text-align: center;
`;
const ActionWrapper = styled.div`
    margin: 12px 0 0 0;
    text-align: center;
`;
const ButtonGroup = styled.div`
    display: inline-block;
    overflow: hidden;
`;
const ConfrimButton = styled(Button.Orange)`
    width: 120px;
    margin: 0 12px 0 0;
    float: left;
`;
const CancelButton = styled(Button.Orange)`
    width: 120px;
    float: left;
`;
const ErrorMsg = styled.div`
    line-height: 20px;
    margin: 8px 0 0 0;
    font-size: 14px;
    color: ${Colors.Red};
    text-align: center;
`;

/*--------------------------
    Main Component
--------------------------*/
class ConfirmDialog extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            isLoading: false,
            errorMsg: '',
        };

        this.closeDialog = this.closeDialog.bind(this);
        this.confirm = this.confirm.bind(this);
        this.cancel = this.cancel.bind(this);
        this.close = this.close.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    // componentDidMount() {}

    // ------------------------------
    // componentDidUpdate
    // ------------------------------
    // componentDidUpdate(prevProps, prevState) {}

    // ------------------------------
    // 關閉
    // ------------------------------
    closeDialog() {

        this.props.webActions.closeConfirmDialog();
    }

    // ------------------------------
    // 確認
    // ------------------------------
    confirm() {

        if(this.state.isLoading) {
            return;
        }

        if(!this.props.confirmDialog.isPromise) {
            this.props.confirmDialog.confirmCallback && this.props.confirmDialog.confirmCallback();
            this.closeDialog();
        } else {
            this.setState({
                isLoading: true,
            }, () => {

                this.props.confirmDialog.confirmPromise().then((result) => {

                    this.setState({
                        isLoading: false,
                    });
                    this.closeDialog();

                }, (errorMsg) => {

                    this.setState({
                        isLoading: false,
                        errorMsg: errorMsg,
                    });
                });
            })
        }
    }

    // ------------------------------
    // 取消 
    // ------------------------------
    cancel() {

        this.props.confirmDialog.cancelCallback && this.props.confirmDialog.cancelCallback();
        this.closeDialog();

        if(this.state.isLoading) {
            this.setState({
                isLoading: false,
            });
        }
    }

    // ------------------------------
    // 關閉 
    // ------------------------------
    close() {

        this.props.confirmDialog.closeCallback && this.props.confirmDialog.closeCallback();
        this.closeDialog();

        if(this.state.isLoading) {
            this.setState({
                isLoading: false,
            });
        }
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            title,
            description,
            confirmName,
            cancelName,
        } = this.props.confirmDialog;

        return (
            <DialogStyle.Content
                // style props
                width="calc(100% - 20px)"
                maxWidth="320px"
                padding="20px"
                backgroundColor="#fff"
                borderRadius="3px"
                // dialog props
                visible={true}
                animation="zoom"
                maskAnimation="fade"
                closable={false}
                maskClosable={false}
                keyboard={false}
                onClose={this.close}
            >
                <DialogStyle.CloseButton onClick={this.close}>
                    <i className="zmdi zmdi-close"></i>
                </DialogStyle.CloseButton>
                <Title>
                    {title}
                </Title>
                <Description>
                    {description}
                </Description>
                <ActionWrapper>
                    <ButtonGroup>
                        <ConfrimButton onClick={this.confirm} disable={this.state.isLoading}>
                            {this.state.isLoading ?
                                <GlobalStyle.Spin size="16px" color="#fff" margin="0 10px 0 0"/>
                                :
                                null
                            }
                            {confirmName ?
                                confirmName
                                :
                                '確定'
                            }
                        </ConfrimButton>
                        <CancelButton outline={true} onClick={this.cancel}>
                            {cancelName ?
                                cancelName
                                :
                                '取消'
                            }
                        </CancelButton>
                    </ButtonGroup>
                </ActionWrapper>
                {this.state.errorMsg ? 
                    <ErrorMsg>
                        {this.state.errorMsg}
                    </ErrorMsg>
                    :
                    null
                }                
            </DialogStyle.Content>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        confirmDialog: state.web.confirmDialog,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
    };
};

export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(ConfirmDialog);
