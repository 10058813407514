/*=====================================
    Root

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';
import actionCreators from 'actions/creators';
import CupoyRouter from 'components/commons/router/CupoyRouter';
import CupoyRouterView from "components/commons/router/CupoyRouterView";
import CupoyRouterSwitchView from "components/commons/router/CupoyRouterSwitchView";
import TabView from "components/commons/TabView";
import SystemTextMsgDialog from 'components/dialog/SystemTextMsgDialog';
import "scss/style.css";
import ConfirmDialogComponent from 'components/dialog/ConfirmDialog';
import AlertDialogComponent from 'components/dialog/AlertDialog';
import InvoiceDetailDialogComponent from 'components/dialog/InvoiceDetailDialog';
import BuyBonusPdtDialogComponent from 'components/dialog/BuyBonusPdtDialog';
import RepairViewComponent from 'components/RepairView';
import SimpleAlertDialog from 'components/dialog/SimpleAlertDialog';
import GLOBAL from 'nonnmwebglobal';

/*--------------------------
    Style Component
--------------------------*/

/*--------------------------
    Variables
--------------------------*/

/*--------------------------
    Main Component
--------------------------*/
class RootView extends Component {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            isCheckLogin: false,
            isCheckWebState: false,
            ConfirmDialogComponent,
            AlertDialogComponent,
            InvoiceDetailDialogComponent,
            BuyBonusPdtDialogComponent,
            RepairViewComponent,
        };

        this.lazyLoadComponent = this.lazyLoadComponent.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.props.webActions.checkWebState();

        this.lazyLoadComponent();

        this.props.memberActions.getMemberAcntInfo().then((result) => {
            this.setState({
                isCheckLogin: true,
            })
        }, (error) => {
            this.setState({
                isCheckLogin: true,
            })
        })
    }

    componentDidUpdate(prevProps) {

        const {
            haslogin: _haslogin,
            isLoginStatusLoading: _isLoginStatusLoading
        } = prevProps;

        const {
            haslogin,
            isLoginStatusLoading,
        } = this.props;

        if (
            // 從有登入變為沒有登入
            (_haslogin !== haslogin && !haslogin)
            // 相同的話代表使用者不是主動登出，是被Server登出 (memberActions.loginStatusExpired)
            && _isLoginStatusLoading === isLoginStatusLoading
        ) {
            this.props.webActions.openAlertDialog({
                title: '登入逾時，請重新登入',
                description: '由於您一段時間沒有進行操作，為了您的使用安全已將您登出。',
                closeName: '重新登入',
                closeCallback: () => this.props.webActions.cupoyStateGo(CupoyRouter.STATES.MEMBERLOGIN),
            })
        }
    }

    // ------------------------------
    // lazyLoadComponent
    // ------------------------------
    lazyLoadComponent() {

        // import('components/dialog/ConfirmDialog').then((ConfirmDialogModule) => {
        //     this.setState({ ConfirmDialogComponent: ConfirmDialogModule.default });
        // }).catch(err => { console.log(err) });
        // import('components/dialog/AlertDialog').then((AlertDialogModule) => {
        //     this.setState({ AlertDialogComponent: AlertDialogModule.default });
        // }).catch(err => { console.log(err) });
        // import('components/dialog/InvoiceDetailDialog').then((InvoiceDetailDialogModule) => {
        //     this.setState({ InvoiceDetailDialogComponent: InvoiceDetailDialogModule.default });
        // }).catch(err => { console.log(err) });
        // import('components/dialog/BuyBonusPdtDialog').then((BuyBonusPdtDialogModule) => {
        //     this.setState({ BuyBonusPdtDialogComponent: BuyBonusPdtDialogModule.default });
        // }).catch(err => { console.log(err) });
        // import('components/RepairView').then((RepairViewModule) => {
        //     this.setState({ RepairViewComponent: RepairViewModule.default });
        // }).catch(err => { console.log(err) });
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            isCheckLogin,
            ConfirmDialogComponent,
            AlertDialogComponent,
            InvoiceDetailDialogComponent,
            BuyBonusPdtDialogComponent,
            RepairViewComponent,
        } = this.state;

        const {
            history,
            confirmDialog,
            alertDialog,
            invoiceDetailDialog,
            MOBILE,
            APP,
            unreadSystemTextMsgList,
            buyBonusPdtDialog,
        } = this.props;
        
        const isMaintain = GLOBAL.isAppFile
            ? typeof APP === 'boolean' && !APP
            : typeof MOBILE === 'boolean' && !MOBILE;

        if(isMaintain) {
            return <RepairViewComponent />;
        }

        return (
            <CupoyRouterView>
                <ConnectedRouter history={history}>
                    <div>

                        {isCheckLogin && !isMaintain && <Route component={CupoyRouterSwitchView} />}

                        <TabView />

                        <SystemTextMsgDialog list={unreadSystemTextMsgList} />

                        {confirmDialog.isOpen && ConfirmDialogComponent ? <ConfirmDialogComponent /> : null}
                        {alertDialog.isOpen && AlertDialogComponent ? <AlertDialogComponent /> : null}
                        {invoiceDetailDialog.isOpen && InvoiceDetailDialogComponent ? <InvoiceDetailDialogComponent /> : null}
                        {buyBonusPdtDialog.isOpen && BuyBonusPdtDialogComponent ? <BuyBonusPdtDialogComponent /> : null}

                        <SimpleAlertDialog />
                        
                    </div>
                </ConnectedRouter>
            </CupoyRouterView>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        isLoginStatusLoading: state.member.isloading,
        history: state.web.history,
        confirmDialog: state.web.confirmDialog,
        alertDialog: state.web.alertDialog,
        invoiceDetailDialog : state.web.invoiceDetailDialog,
        MOBILE: state.web.MOBILE,
        APP: state.web.APP,
        unreadSystemTextMsgList: state.systemMsg.unreadSystemTextMsgList,
        buyBonusPdtDialog: state.web.buyBonusPdtDialog,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(RootView);