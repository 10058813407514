/*=====================================
    更新發票資訊

    Author: Ac
    createtime: 2018 / 12 / 07
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import styled from 'styled-components';
import { GlobalStyle, MemberStyle, Colors, Button } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import GLOBAL from 'nonnmwebglobal';
import CupoyLink from 'components/commons/CupoyLink';
import NPOBAN_LIST from 'json/npobanlist.json';
import mobileUtil from 'util/mobileUtil';
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';
import { CancelToken } from 'axios';

/*--------------------------
    Style Component
--------------------------*/
const SubmitLoading = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    left: 16px;
    height: 100%;
    align-items: center;
    justify-content: center; 
`;
const RegionTitleNotice = styled.span`
    margin-left: 10px;
    color: ${Colors.Main};
    font-size: 15px;
`;
const RegionTitle = styled.div`
    align-items: center;
    margin : ${props => props.margin ? props.margin : '6px 0 0 0'};;
    font-size: 20px;
    color: #333333;
`;
const CheckBox = styled.div`
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width : 18px;
    height : 18px;
    line-height: 18px;
    border-radius: 0 3px 0 3px;  
    background-color: ${Colors.Orange};
    text-align: center;
    color: #fff;
    font-size: 16px;
`;
const CheckRetangle = styled.div`
    position: relative;
    height: 44px;
    line-height: 44px;
    border-radius: 3px;
    border : 1px solid ${(props) => props.isCheck ? Colors.Orange : '#CCC'};
    flex: 1;
    text-align: center;
    background-color: #FFFFFF;
    font-size: 14px;
    color: ${(props) => props.isCheck ? Colors.Orange : '#6A6A6A'};
    letter-spacing: 0;
    text-align: center;
    cursor : pointer;

    &:first-child {
        margin: 0 10px 0 0;
    }

    ${CheckBox} {
        ${(props) => {
            if(props.isCheck) {
                return `display: block`;
            }
        }}
    }
`;
const LineDelimeter = styled.div`
    margin : 16px 0 0 0;
    border-top : 1px solid #DDD;
    width : 100%;
    height : 1px;
`;
const InvoiceNoitceLink = styled.span`
    margin-left: 5px;
    color: ${Colors.Main};
    font-size: 12px;
`;
const FieldTitle = styled(MemberStyle.FieldTitle)`
    display: block;
    margin-top: 16px;

    > span {
        display: inline-flex;
        align-items: center;
        color: ${Colors.Red};
        margin-left: 4px;
    }
`;
const JoinMemberCarrierBtn = styled(Button.Blue)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 5px);
    margin-top: 16px;

    ${GlobalStyle.Spin} {
        margin: 0 6px 0 0;
    }
`;

/*--------------------------
    Main Component
--------------------------*/
class UpdateInvoiceView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {
        
        super(props);

        const {
            memberinfo = {},
        } = props;

        const {
            isdonate,
            npoban,
            carriertype,
            carrierid,
        } = memberinfo;

        this.state = {
            isError : false,
            isLoading: false,
            errorMap: {},
            isdonate: !!isdonate,
            npoban: npoban ? npoban.trim() : '',
            carriertype: carriertype || GLOBAL.CARRIER_TYPE.NONE,
            carrierid: carrierid ? carrierid.trim() : '',
            isChkingCarrierId: false,
            isChkCarrierIdError: false,
        }

        this.changeDonateToTrue = this.changeDonate.bind(this, true);
        this.changeDonateToFalse = this.changeDonate.bind(this, false);
        this.changeCarriertypeToMember = this.changeCarriertype.bind(this, GLOBAL.CARRIER_TYPE.MEMBER);
        this.changeCarriertypeToCommon = this.changeCarriertype.bind(this, GLOBAL.CARRIER_TYPE.COMMONS);
        this.checkCarrierId = this.checkCarrierId.bind(this);
        this.joinMemberCarrier = this.joinMemberCarrier.bind(this);

        this.chkCarrierIdController = null;
    }

    changeCarriertype = type => {

        if (this.chkCarrierIdController) {
            this.chkCarrierIdController.cancel();
        }

        this.setState({
            carriertype: type,
            carrierid: '',
            isChkingCarrierId: false,
            isChkCarrierIdError: false,
        });
    }

    changeDonate = isdonate => {

        if (isdonate === this.state.isdonate) {
            return;
        }

        if (this.chkCarrierIdController) {
            this.chkCarrierIdController.cancel();
        }

        this.setState({
            isdonate,
            carriertype: !isdonate ? GLOBAL.CARRIER_TYPE.MEMBER : GLOBAL.CARRIER_TYPE.NONE,
            npoban: isdonate ? '8585' : '',
            carrierid: '',
            isChkingCarrierId: false,
            isChkCarrierIdError: false,
        });
    }

    handleNicknameChange = event => {
        this.setState ({ nickname : event.target.value });
    }

    update = () => {

        const {
            membername,
            nickname,
            birthday,
            invoicetype,
            crn,
            companytitle,
            shownametype,
            address,
            sex,
        } = this.props.memberinfo;

        const {
            isdonate,
            npoban = '',
            carriertype,
            carrierid = '',
            isLoading,
            isChkingCarrierId,
            isChkCarrierIdError,
        } = this.state;

        if (
            isLoading
            || isChkingCarrierId
            || isChkCarrierIdError
        ) {
            return; 
        }

        let message = '';
        let isError =  false;
        let objInput = null;
        let errorMap = {};

        if(isdonate && (!NPOBAN_LIST[npoban] || GLOBAL.isEmpty(npoban))) {
            isError = true;
            message += "請輸入正確的捐贈碼\n";
            objInput = objInput || this.npobanInput;
            errorMap['npoban'] = true;
        }

        if(!isdonate && (carriertype === GLOBAL.CARRIER_TYPE.COMMONS) && GLOBAL.isEmpty(carrierid)) {
            isError = true;
            message += "請輸入共通性載具ID\n";
            errorMap['carrierid'] = true;
            objInput = objInput || this.carrieridInput;
        }

        if(isError){
            this.setState({
                errorMap: errorMap,
            }, () => {
                this.props.webActions.openAlertDialog({                    
                    title: "錯誤訊息",
                    description : message,
                    closeName: '確定',
                    closeCallback: () => {
                        objInput.focus();
                    }
                });
            })
            return;
        }

        const addressMap = GLOBAL.parseAddress(address);

        this.setState({ isLoading: true }, () => {

            this.props.memberActions.updMember({
                city: addressMap.city,
                region: addressMap.region,
                postalcode: addressMap.zipcode,
                addreGss: addressMap.address,
                name: membername,
                nickname,
                birthday,
                isdonate,
                npoban: !!isdonate ? npoban.trim() : '',
                carrierid: !!isdonate ? '' : carrierid.trim(),
                carriertype: !!isdonate ? GLOBAL.CARRIER_TYPE.NONE : carriertype,
                invoicetype,
                crn,
                companytitle,
                shownametype,
                sex,
            }).then((result) => {
                this.setState({ isLoading: false }, () => {
                    if(result)
                    this.props.webActions.openAlertDialog({
                        title: '發票資訊修改成功',
                        closeCallback: () => {
                            this.props.webActions.cupoyStateGo(CupoyRouter.STATES.MEMBER_INVOICE);
                        }
                    });
                });
            }).catch(() => { 
                this.setState({
                    isError: true,
                    isLoading: false
                }, () => {
                    this.props.webActions.openAlertDialog({
                        title: '發生錯誤',
                        description: '無法成功修改發票資訊，請稍後再嘗試。',
                    });
                });
            });
        });
    }
    
    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {
        return <NeedLoginBlock />;
    }

    handleNpobanberChange = event => {
        this.setState({
            npoban: event.target.value,
        });
    }

    checkCarrierId() {

        const {
            carrierid,
        } = this.state;
        
        const _carrierid = carrierid.trim();

        if (this.chkCarrierIdController) {
            this.chkCarrierIdController.cancel();
        }

        // 沒有id，直接顯示錯誤
        if (!_carrierid) {
            this.setState({
                isChkingCarrierId: false,
                isChkCarrierIdError: true,
            });
            return;
        }

        // 格式是合法的自然人憑證id，pass
        if (GLOBAL.isDigitalCitizenCarrierID(_carrierid)) {
            this.setState({
                isChkingCarrierId: false,
                isChkCarrierIdError: false,
            });
            return;
        }

        // 格式不是合法的手機條碼，直接不檢查，顯示錯誤
        if (!GLOBAL.isMobilePhoneCarrierID(_carrierid)) {
            this.setState({
                isChkingCarrierId: false,
                isChkCarrierIdError: true,
            });
            return;
        }

        this.chkCarrierIdController = CancelToken.source();
        const { token: cancelToken } = this.chkCarrierIdController;

        this.setState({
            isChkingCarrierId: true,
            isChkCarrierIdError: false,
        }, () => {
            this.props.webActions.checkEinvoiceMobileCarrier({
                carrierid: _carrierid,
                cancelToken,
            }).then(() => {
                this.setState({
                    isChkingCarrierId: false,
                });
            }).catch(() => {
                this.setState({
                    isChkingCarrierId: false,
                    isChkCarrierIdError: true,
                });
            });
        });
    }

    handleCarrieridChange = event => {
        this.setState({
            carrierid: event.target.value,
        }, this.checkCarrierId);
    }

    joinMemberCarrier() {

        if (this.state.isJoinMemberCarrierLoading) {
            return;
        }

        this.setState({
            isJoinMemberCarrierLoading: true,
        });

        this.props.memberActions.joinMemberCarrier(
        ).catch(error => {

            this.setState({
                isJoinMemberCarrierLoading: false,
            });

            this.props.webActions.openAlertDialog({
                title: '發生錯誤',
                description: error && error.message
                    ? error.message
                    : '發生無法預期的錯誤，請重新整理稍後再試，或聯絡我們的客服人員',
            });
        });
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            isLoading,
            errorMap,
            isdonate,
            npoban,
            carriertype,
            carrierid,
            isChkingCarrierId,
            isChkCarrierIdError,
            isJoinMemberCarrierLoading,
        } = this.state;

        const disableSubmit = isLoading
            || isChkingCarrierId
            || isChkCarrierIdError;

        return (
            <GlobalStyle.Container  hasFixedTopbar  hasFixedTabbar={false}>
            
                <TopbarView
                    isFixed
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER_INVOICE}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            發票管理
                        </CupoySrefView>
                    }
                    middleComponent="修改發票資訊"
                />

                <MemberStyle.MemberContent>

                    <RegionTitle>
                        發票資訊
                        <RegionTitleNotice>種類：兩聯式</RegionTitleNotice>
                    </RegionTitle>
                    <LineDelimeter />
                    <FieldTitle
                        style={{
                            display: 'inline-block',
                            width: 'auto',
                            marginTop: 16,
                        }}
                    >
                        發票索取
                    </FieldTitle>

                    <CupoyLink
                        target='_blank'
                        rel='noopener noreferrer'
                        href='https://m.9199.com.tw/newscontent/1412062043227'
                    >
                        <InvoiceNoitceLink>
                        【電子發票懶人包】
                        </InvoiceNoitceLink>
                    </CupoyLink>
                    <MemberStyle.InputContainer
                        flexdirection="row"
                        justify="space-between"
                    >
                        <CheckRetangle
                            isCheck={isdonate}
                            onClick={this.changeDonateToTrue}
                        >
                            <CheckBox><i className="zmdi zmdi-check"></i></CheckBox>
                            捐贈
                        </CheckRetangle>
                        <CheckRetangle
                            isCheck={!isdonate}
                            onClick={this.changeDonateToFalse}
                        >
                            <CheckBox><i className="zmdi zmdi-check"></i></CheckBox>
                            不捐贈
                        </CheckRetangle>
                    </MemberStyle.InputContainer>
                    {
                        isdonate ?
                        <React.Fragment>
                            <FieldTitle style={{ display: 'inline-block', width: 'auto' }}>
                                捐贈碼
                            </FieldTitle>

                            <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href={`${GLOBAL.URL_PREFIX}/npobanlist.pdf`}
                                onClick={mobileUtil.handleOpenLinkBridgeWithNew}
                            >
                                <InvoiceNoitceLink>
                                    【捐贈碼查詢】
                                </InvoiceNoitceLink>
                            </a>
                            
                            <MemberStyle.MemberInputBox
                                ref={(input) => {this.npobanInput = input}}
                                type="text"
                                value={npoban}
                                placeholder="愛心捐贈代碼"
                                margin="8px 0 0 0"
                                onChange={this.handleNpobanberChange}
                                error={errorMap['npoban']}
                            />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <FieldTitle>發票載具</FieldTitle>
                            <MemberStyle.InputContainer
                                flexdirection="row"
                                justify="space-between"
                            >
                                <CheckRetangle
                                    isCheck={carriertype === GLOBAL.CARRIER_TYPE.MEMBER}
                                    onClick={this.changeCarriertypeToMember}
                                >
                                    <CheckBox><i className="zmdi zmdi-check"></i></CheckBox>
                                    會員載具
                                </CheckRetangle>
                                <CheckRetangle
                                    isCheck={carriertype === GLOBAL.CARRIER_TYPE.COMMONS}
                                    onClick={this.changeCarriertypeToCommon}
                                >
                                    <CheckBox><i className="zmdi zmdi-check"></i></CheckBox>
                                    共通性載具
                                </CheckRetangle>
                            </MemberStyle.InputContainer>

                            <JoinMemberCarrierBtn
                                disable={isJoinMemberCarrierLoading}
                                onClick={this.joinMemberCarrier}
                            >
                                {isJoinMemberCarrierLoading && (
                                    <GlobalStyle.Spin size='14px' color="#fff"/>
                                )}
                                會員載具歸戶
                            </JoinMemberCarrierBtn>
                        </React.Fragment>
                    }

                    {carriertype === GLOBAL.CARRIER_TYPE.COMMONS && (
                        <React.Fragment>
                            <FieldTitle>
                                載具ID
                                {isChkingCarrierId && <span><GlobalStyle.Spin size='12px' /></span>}
                                {isChkCarrierIdError && <span>請輸入正確的手機條碼(8碼)或自然人憑證(16碼)！</span>}
                            </FieldTitle>
                            <MemberStyle.MemberInputBox
                                ref={(input) => {this.carrieridInput = input}}
                                type="text"
                                value={carrierid}
                                placeholder="請輸入手機條碼(8碼)或自然人憑證(16碼)"
                                margin="8px 0 0 0"
                                onChange={this.handleCarrieridChange}
                                error={errorMap['carrierid']}
                            />
                        </React.Fragment>
                    )}

                </MemberStyle.MemberContent>
                <MemberStyle.BottomBar>
                    <MemberStyle.ConfirmBtn
                        margin="0"
                        disable={disableSubmit}
                        onClick={this.update}
                    >
                    {isLoading && (
                        <SubmitLoading>
                            <GlobalStyle.Spin color="#fff"/>
                        </SubmitLoading>
                    )}
                        完成
                    </MemberStyle.ConfirmBtn>
                </MemberStyle.BottomBar>
            </GlobalStyle.Container>
            
        )
    };

}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {

    return {
        memberinfo : state.member.memberinfo,
        haslogin: state.member.haslogin,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(UpdateInvoiceView);