/*=====================================
    會員畫面共用樣式

    Author: Eileen Liao
    createtime: 2018 / 2 / 28
=====================================*/

/*--------------------------
    Import 
--------------------------*/
import styled from 'styled-components';
import Colors from './colors';
import Button from './button';
import GlobalStyle from './globalstyle';
import GLOBAL from 'nonnmwebglobal';
 
const BottomBarHeight = 44;
const MemberContainer = styled(GlobalStyle.Container)`
    padding-bottom: ${(props) => props.hasBottomBar ?  BottomBarHeight : 0}px;
    background-color : ${(props) => props.hasBottomBar ? Colors.Orange :  '#fff'};
	z-index: ${(props) => props.hasBottomBar ?  951 :'auto'};
`;
const MemberContent = styled.div`
    padding : 16px;
    height : ${props => props.height ? props.height : '100%'};
`;
const MemberDescription =  styled.div`
    display:flex;
    margin : ${props => props.margin ? props.margin : '16px 0 0 0'};
    width : 100%;
    font-size: 14px;
    height : 40px;
    justify-content: left;
    align-items : center;
    color: ${Colors.Dark2};
`;
const MemberInputBox = styled.input`
    margin : ${props => props.margin ? props.margin : '16px 0 0 0'};
    width :  ${props => props.width ? props.width : '100%'};
    padding : 0 12px;
    height : 44px;
    background: #FAFAFA;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    font-size: 15px;
    font-weight : normal;
    color:${Colors.Lightgray};
    
    ${(props) => {
        if(props.error) {
            return `
                border: 1px solid ${Colors.Red};
                color: ${Colors.Red};
            `;
        }
    }}
`;
const ConfirmBtn = styled(Button.Orange)`
    position: relative;
    height : 44px;
    margin : ${props => props.margin ? props.margin : '16px 0'};
    width :  ${props => props.width ? props.width : '100%'};
    display: flex;
    align-items: center;
    justify-content : center;
    border-radius: 2px;
    font-size: 16px;
    opacity: ${(props) => props.disable ? '0.5' : '1'};

    > ${GlobalStyle.Spin} {
        margin: 0 10px 0 0;
    }
`;

const FieldTitle = styled.span`
font-size: 12px;
margin:0;
/*display : ${props => props.isdisplay ? 'block' : 'none'};*/
color: ${Colors.Dark6};
width: ${props => props.width ? props.width : '100%'};

`;
const MemberInputBox2 = styled(MemberInputBox)`
    margin : 8px 0 0 0;
`;
const InputContainer = styled.div`
    margin : ${props => props.margin ? props.margin : '16px 0 0 0'};
    display : flex;
    flex-direction : ${props => props.flexdirection ? props.flexdirection : 'column'};
    align-items: ${props => props.alignItems ? props.alignItems : 'left'};;
    justify-content : ${props => props.justify ? props.justify : 'flex-start'};
`;
const BottomBar = styled.div`
    padding : 0 16px;
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
    height: 44px;
    background-color: #fff;
    z-index: ${GlobalStyle.TabbarZindex};
    transform: translateZ(0);
`;
const RowItemContainer =styled.div`
    margin : ${(props) => props.isToprow ? "10px 0 0 0" : "0" };
    display : flex;
    width : 100%;
    height : 44px;
    justify-content:space-between;
    background-color:#FFF;
    cursor: pointer;
`;
const RowItemLeftContainer =styled.div`
    display : flex;
    margin : 0 0 0 15px;
    height : 44px;
    align-items:center;
`;
const RowItemRightContainer =styled.div`
    display : flex;
    margin : 0 17px 0 0;
    height : 44px;
    justify-content:flex-end;
    align-items:center;
    font-size: 12px;
    color: #9B9B9B;
`;
const RowItemBorder = styled.div`
    height :0;
    margin : 0 0 0 60px;
    width : calc(100% - 60px);
    color : #f5f0f0;
    border-top : 1px solid #f5f0f0;
    background-color : #f5f0f0;
`;
const FunIconStyle = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    width:30px;
    height:30px;

    > i {
        color: #fff;
        ${({ iconSize }) => iconSize ? `font-size: ${iconSize}px` : ``};
    }
`;
const LightOrangeIcon = styled(FunIconStyle)`
    background-image : url(${`${GLOBAL.URL_PREFIX}/images/icons/lightorange_circle.svg`});
`;
const OrangeIcon = styled(FunIconStyle)`
    background-image : url(${`${GLOBAL.URL_PREFIX}/images/icons/orange_circle.svg`});
`;
const PinkIcon = styled(FunIconStyle)`
    background-image : url(${`${GLOBAL.URL_PREFIX}/images/icons/pink_circle.svg`});
`;
const LightGreenIcon = styled(FunIconStyle)`
    background-image : url(${`${GLOBAL.URL_PREFIX}/images/icons/lightgreen_circle.svg`});
`;
const GreenIcon = styled(FunIconStyle)`
    background-image : url(${`${GLOBAL.URL_PREFIX}/images/icons/green_circle.svg`});
`;
const LightBlueIcon = styled(FunIconStyle)`
    background-image : url(${`${GLOBAL.URL_PREFIX}/images/icons/lightblue_circle.svg`});
`;
const BlueIcon = styled(FunIconStyle)`
    background-image : url(${`${GLOBAL.URL_PREFIX}/images/icons/blue_circle.svg`});
`;
const LightPurpleIcon = styled(FunIconStyle)`
    background-image : url(${`${GLOBAL.URL_PREFIX}/images/icons/lightpurple_circle.svg`});
`;
const PurpleIcon = styled(FunIconStyle)`
    background-image : url(${`${GLOBAL.URL_PREFIX}/images/icons/purple_circle.svg`});
`;
const GrayIcon = styled(FunIconStyle)`
    background-image : url(${`${GLOBAL.URL_PREFIX}/images/icons/gray_circle.svg`});
`;
const FuntionTitle = styled.div`
    margin : 0 0 0 14px;
    font-size: 14px;
    color : #6C6666;
`;
const FuntionStatusContent = styled.div`
    margin : 0 15px 0 0;
`;
const FuntionTextStatusContent = styled(FuntionStatusContent)`
    font-size: 12px;
    color: #6A6A6A;
`;
const FuntionTagStatusContent = styled(FuntionStatusContent)`
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    border-radius: 100px;
    font-size: 12px;
    color: #fff;
    background-color: ${Colors.Orange};
    transform: scale(${10/12});
`;
const LogsStatus = styled.div`
    position: absolute;
    right: 16px;
    bottom: 16px;
    font-size: 14px;
    color: ${(props) => props.color ? props.color : Colors.Orange};
`;
const LogsTag = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    border-radius: 2px;
    background-color: ${(props) => props.color ? props.color : Colors.Blue};
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 0;
    transform: scale(11/12);
`;
const LogsItem = styled.div`
    position: relative;
    display: block;
    margin: 10px 0 0 0;
    padding: ${(props) => props.hasArrow ? '16px 40px 16px 16px' : '16px'};
    background-color: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #EEEEEE, inset 0 1px 0 0 #EEEEEE;
    
    cursor :   ${(props) => props.link  ? 'pointer' : 'default'};
    
    ${LogsStatus} {
        right: ${(props) => props.hasArrow ? '40px' : '16px'};
    }

    ${LogsTag} {
        right: ${(props) => props.hasArrow ? '40px' : '16px'};
    }
`;
const LogsTitle = styled.div`
    line-height: 22px;
    margin: 0 0 8px 0;
    font-size: 16px;
    color: ${Colors.Dark2};
    letter-spacing: 0;
`;
const LogsDescription = styled.div`
    margin: ${(props) => props.isLast ? 0 : '0 0 4px 0'};
    font-size: 12px;
    color: #565151;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const LogsRightArrow = styled.div`
    position: absolute;
    top: 50%;
    right: 16px;
    font-size: 12px;
    color: #9B9B9B;
    transform: translateY(-50%);
`;

/*--------------------------
    Export
--------------------------*/
const MemberStyle = {
    BottomBarHeight,
    MemberContainer,
    MemberContent,
	MemberDescription,
    MemberInputBox,
    MemberInputBox2,
    ConfirmBtn,
    FieldTitle,
    InputContainer,
    BottomBar,
    RowItemContainer,
    RowItemLeftContainer,
    RowItemRightContainer,
    RowItemBorder,
    FunIconStyle,
    LightOrangeIcon,
    OrangeIcon,
    PinkIcon,
    LightGreenIcon,
    GreenIcon,
    LightBlueIcon,
    BlueIcon,
    LightPurpleIcon,
    PurpleIcon,
    GrayIcon,
    FuntionTitle,
    FuntionStatusContent,
    FuntionTextStatusContent,
    FuntionTagStatusContent,
    LogsStatus,
    LogsTag,
    LogsItem,
    LogsTitle,
    LogsDescription,
    LogsRightArrow,
}

export default MemberStyle;