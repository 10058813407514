import React from 'react';
import PropTypes from 'prop-types';
import CupoyRouter from 'components/commons/router/CupoyRouter';
import CupoySrefView from 'components/commons/router/CupoySrefView';
import { GlobalStyle } from 'stylecomponents';

const NeedLoginBlock = props => (
    <GlobalStyle.ErrorContainer contentPadding={props.contentPadding}>
        <GlobalStyle.ErrorContainerTitle>
            您尚未登入
        </GlobalStyle.ErrorContainerTitle>
        <GlobalStyle.ErrorContainerDesription>
            您尚未登入，請登入後再重新操作一次。
        </GlobalStyle.ErrorContainerDesription>
        <CupoySrefView
            statename={CupoyRouter.STATES.MEMBERLOGIN}
            stylecomponent={GlobalStyle.ErrorContainerBtn}
        >
            前往登入
        </CupoySrefView>
    </GlobalStyle.ErrorContainer>
);

NeedLoginBlock.propTypes = {
    contentPadding: PropTypes.string,
};

const areEqual = () => true;
export default React.memo(NeedLoginBlock, areEqual);
