/*=====================================
    MemberRefundApply

    Author: Gray
    CreateTime: 2018 / 03 / 13
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import styled from 'styled-components';
import { GlobalStyle, Colors, Button } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import bankJSON from 'json/bank.json';
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';
import StatusBlock from 'components/commons/status/StatusBlock';

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
    padding: 16px;
`;
const SubContent = styled.div`
    margin: 0 0 32px 0;
`;
const Title = styled.div`
    font-size: 20px;
    color: ${Colors.Dark2};
`;
const Divide = styled.div`
    width: 100%;
    height: 1px;
    margin: 16px 0;
    background-color: #DDDDDD;
`;
const RefundInfo = styled.div`
    font-size: 14px;
    color: ${Colors.Dark2};
    letter-spacing: 0;
`;
const TransparentText = styled.span`
    opacity: 0;
    user-select: none;
`;
const OrangeText = styled.span`
    color: ${Colors.Orange};
`;
const Label = styled.div`
    display: flex;
    margin: 0 0 8px 0;
    font-size: 12px;
    color: ${Colors.Dark2};
    letter-spacing: 0;
    align-items: center;
`;
const Input = styled.input`
    width: 100%;
    height: 44px;
    margin: 0 0 16px 0;
    padding: 0 12px;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    background-color: #FAFAFA;
    font-size: 15px;
    color: #222328;

    ${(props) => {

        if(!props.error) {
            return ``;
        }

        return `
            border: 1px solid ${Colors.Red};
            color: ${Colors.Red};
        `;
    }}
`;
const SelectWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 44px;
    margin: 0 0 16px 0;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    background-color: #FAFAFA;
`;
const Select = styled.select`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 8px;
    border: none;
    background-color: #FAFAFA;
    font-size: 15px;
    color: #222328;
`;
const Remark = styled.div`
    line-height: 20px;
    font-size: 14px;
    color: ${Colors.Dark2};
    letter-spacing: 0;
`;
const SubmitButton = styled(Button.Orange)`
    position: relative;
    width: 100%;
    height: 44px;
    line-height: 44px;
    margin: 0;
    font-size: 16px;
`;
const SubmitLoading = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    left: 16px;
    height: 100%;
    align-items: center;
    justify-content: center; 
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberRefundApplyView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            bankData: undefined,
            bankCode: '',
            bankIndex: '',
            bankBranchIndex: '',
            atmAccount: '',
            isLoading: false,
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onBankCodeChange = this.onBankCodeChange.bind(this);
        this.onMainSelectChange = this.onMainSelectChange.bind(this);
        this.onSubSelectChange = this.onSubSelectChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props, true);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        this.init(this.props);
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props, isInit) {

        if(isInit) {
            this.setState({ bankData: bankJSON });

            // 取得會員首頁相關數據
            this.props.memberActions.getMemberPageMetrics();
        }
    }

    // ------------------------------
    // Input value change
    // ------------------------------
    onInputChange(event, key) {

        this.setState({
            [key]: event.target.value,
        })
    }

    // ------------------------------
    // Input value change
    // ------------------------------
    onBankCodeChange(event, key) {

        var {
            bankData,
            bankIndex,
            bankBranchIndex,
        } = this.state;

        const bankCode = event.target.value;
        var _bankCode, i, bank;

        if(bankCode.length < 3) {
            // nothing todo
        } else if(bankCode.length >= 3 && bankCode.length < 7) {

            _bankCode = bankCode.substr(0,3);
            var currentBank = bankData[bankIndex];

            if(!currentBank || _bankCode !== currentBank.bankcode) {
                for(i = 0; i < bankData.length; i++) {
                    bank = bankData[i];

                    if(bank.bankcode === _bankCode) {
                        bankIndex = i;
                        break;
                    }
                }
            }
        } else {
            var currentBankCode = this.getBankCodeBySelect(bankIndex, bankBranchIndex);
            _bankCode = bankCode.substr(0,3);
            var _bankBranchCode = bankCode.substr(3);

            if(currentBankCode !== bankCode) {
                for(i = 0; i < bankData.length; i++) {
                    bank = bankData[i];

                    if(bank.bankcode === _bankCode) {
                        bankIndex = i;

                        if(Array.isArray(bank.subbank) && bank.subbank.length > 0) {
                            for(var j = 0; j < bank.subbank.length; j++) {
                                var bankBranch = bank.subbank[j];
                                if(bankBranch.subcode === _bankBranchCode) {
                                    bankBranchIndex = j;
                                    break;
                                }
                            }
                        }

                        break;
                    }
                }
            }
        }

        this.setState({
            bankCode: bankCode,
            bankIndex: bankIndex,
            bankBranchIndex: bankBranchIndex,
        });
    }

    // ------------------------------
    // onMainSelectChange
    // ------------------------------
    onMainSelectChange(event) {

        const bankIndex = event.target.value;
        const bankBranchIndex = 0;

        if(bankIndex === this.state.bankIndex) {
            return;
        }

        const bankCode = this.getBankCodeBySelect(bankIndex, bankBranchIndex);

        this.setState({
            bankIndex: bankIndex,
            bankBranchIndex: bankBranchIndex,
            bankCode: bankCode,
        })
    }

    // ------------------------------
    // onSubSelectChange
    // ------------------------------
    onSubSelectChange(event) {

        const bankIndex = this.state.bankIndex;
        const bankBranchIndex = event.target.value;

        if(bankBranchIndex === this.state.bankBranchIndex) {
            return;
        }

        const bankCode = this.getBankCodeBySelect(bankIndex, bankBranchIndex);

        this.setState({
            bankBranchIndex: bankBranchIndex,
            bankCode: bankCode,
        })
    }

    // ------------------------------
    // getBankCodeBySelect
    // ------------------------------
    getBankCodeBySelect(bankIndex, bankBranchIndex) {
        
        const {
            bankData,
        } = this.state;

        const bank = bankData[bankIndex];

        if(!bank || !Array.isArray(bank.subbank)) {
            return '';
        }

        const bankBranch = bank.subbank[bankBranchIndex];

        if(!bankBranch) {
            return '';
        }

        return bank.bankcode + bankBranch.subcode;
    }

    // ------------------------------
    // submit
    // ------------------------------
    submit() {

        const {
            bankData,
            bankCode,
            bankIndex,
            bankBranchIndex,
            atmAccount,
            isLoading,
        } = this.state;

        const {
            memberinfo,
        } = this.props;

        if(!memberinfo || !bankData) {
            return null;
        }

        const {
            freezesum_new = 0,
            cancelordersum = 0,
        } = memberinfo;

        const disableSubmit = !bankCode || !atmAccount || !bankCode.trim() || !atmAccount.trim() 
                                || bankCode.trim().length < 7 || isLoading;

        if(disableSubmit) return;

        if((freezesum_new + cancelordersum) === 0) {
            this.props.webActions.openAlertDialog({
                title: '錯誤訊息',
                description: '您可退款的總金額為0。',
                closeName: '關閉',
            });
            return;
        }

        var bank = '',
            subbank = '';

        if(bankIndex) {

            var currentBank = bankData[bankIndex];

            if(currentBank) {
                bank = currentBank.bank;
            }

            if(bankBranchIndex && Array.isArray(currentBank.subbank)) {
                var currentBankBranch = currentBank.subbank[bankBranchIndex];

                if(currentBankBranch) {
                    subbank = currentBankBranch.subname;
                }
            }
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.memberActions.addRefundApplication({
                bankcode: bankCode,
                bank: bank,
                subbank: subbank,
                bankaccount: atmAccount,
            }).then(() => {

                this.setState({
                    bankCode: '',
                    bankIndex: '',
                    bankBranchIndex: '',
                    atmAccount: '',
                    isLoading: false,
                });

                this.props.webActions.openAlertDialog({
                    title: '您的申請已送出',
                    description: '我們將會儘速處理。',
                    closeName: '關閉',
                    closeCallback: () => {
                        this.props.webActions.cupoyStateGo(CupoyRouter.STATES.MEMBER);
                    }
                });

            }, (error) => {

                var errorMsg = '發生無法預期的錯誤';
                var errorDescription = '';

                if(error && error.code) {
                    errorMsg = error.message;
                }

                this.props.webActions.openAlertDialog({
                    title: errorMsg,
                    description: errorDescription,
                });

                this.setState({
                    isLoading: false,
                });
            });
        })
    }    

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return <NeedLoginBlock />;
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            bankData,
            bankCode,
            bankIndex,
            bankBranchIndex,
            atmAccount,
            isLoading,
        } = this.state;

        const {
            memberinfo,
        } = this.props;

        if(!memberinfo || !bankData) {
            return null;
        }

        const {
            membername,
            idnumber,
            freezesum_new = 0,
            cancelordersum = 0,
        } = memberinfo;

        if(!membername) {
            return null;
        }

        if (!idnumber) {
            return (
                <StatusBlock
                    iconElement={<i className='zmdi zmdi-info-outline'></i>}
                    textElement={<span>身分證尚未驗證</span>}
                />
            );
        }

        var currentBank,
            currentBankBranch = [];

        if(bankIndex >= 0) {
            currentBank = bankData[bankIndex];
        }

        if(currentBank && Array.isArray(currentBank.subbank)) {
            currentBankBranch = currentBank.subbank;
        }

        const disableSubmit = !bankCode || !atmAccount || !bankCode.trim() || !atmAccount.trim() 
                            || bankCode.trim().length < 7 || isLoading;

        return (
            <Content>
                <SubContent>
                    <Title>退款資料</Title>
                    <Divide/>
                    <RefundInfo>
                        銀行帳戶戶名：{membername}<br/>
                        身分證字號：{idnumber}<br/>
                        退款總金額：<OrangeText>{freezesum_new + cancelordersum}</OrangeText> 元<br/>
                        可退款項目：卡款金額{freezesum_new}元<br/>
                        <TransparentText>可退款項目：</TransparentText>取消交易{cancelordersum}元<br/>
                    </RefundInfo>
                </SubContent>
                <SubContent>
                    <Title>轉入銀行</Title>
                    <Divide/>
                    <Label>銀行代碼</Label>
                    <Input
                        type="tel"
                        maxLength={7}
                        placeholder="請輸入銀行代碼7碼或下方選擇"
                        value={bankCode}
                        onChange={(event) => this.onBankCodeChange(event, 'bankCode')}
                        error={false}
                    />
                    <Label>銀行總行</Label>
                    <SelectWrapper>
                        <Select
                            value={bankIndex}
                            onChange={(event) => this.onMainSelectChange(event)}
                        >
                            <option value={''}>銀行別</option>
                            {bankData.map((bank, index) => {
                                return (
                                    <option key={index} value={index}>
                                        {bank.bankcode} {bank.bank}
                                    </option>
                                );
                            })}
                        </Select>
                    </SelectWrapper>
                    <Label>分行名稱</Label>
                    <SelectWrapper>
                        <Select
                            value={bankBranchIndex}
                            onChange={(event) => this.onSubSelectChange(event)}
                        >
                            <option value={''}>分行別</option>
                            {currentBankBranch.map((bank, index) => {
                                return (
                                    <option key={index} value={index}>
                                        {bank.subcode} {bank.subname}
                                    </option>
                                );
                            })}
                        </Select>
                    </SelectWrapper>
                    <Label>銀行帳號</Label>
                    <Input
                        type="number"
                        maxLength="14"
                        min="0"
                        max="99999999999999"
                        placeholder="請輸入銀行帳號(最多14碼)"
                        value={atmAccount}
                        onChange={(event) => this.onInputChange(event, 'atmAccount')}
                        error={false}
                    />
                </SubContent>
                <SubContent>
                    <Title>備註</Title>
                    <Divide/>
                    <Remark>
                        1. 於9199完成交易之訂單金額，均需於完成交易日後「1~2日」顯示 交易完成已入帳 才可申請退款，以保障買賣雙方商品安全，當訂單狀態顯示「交易完成已入帳」即可申請退款。<br/>
                        <br/>
                        2. 各銀行會收取每筆轉帳手續費30元，舉例：退款1,000元扣除銀行手續費後，實際退款金額為970元。
                        會員帳戶姓名、身分證字號、總行、分行、銀行帳號...等資料，請務必填寫正確，如因資料有誤導致轉帳退款失敗，轉帳金額將退回至交易保證金帳戶，放款銀行仍將收取30元手續費。<br/>
                        <br/>
                        3. 轉帳退款申請將於每個工作日AM 10:00 開始處理，會員在PM 3:30可收到款項。<br/>
                        註：當日AM 10:00以後提出申請者，將在下個工作日辦理。如週五當日AM 10:00之後申請，將順延至下週一辦理。<br/>
                        <br/>
                        4. 如因銀行端因素無法正常進行轉帳，本站將發出公告通知，待銀行恢復正常後立即進行轉帳處理。<br/>
                        <br/>
                        5. 如因會員提交轉帳退款申請資料有誤，而造成無法正常收到轉帳款項，後果由會員自行負責。<br/>
                        <br/>
                        6. 如轉出銀行為「郵局」時，銀行帳號請填寫「局號+帳號」。<br/>
                        <br/>
                        7. 如果分行列表中沒有您銀行帳號所屬的分行或您的戶名和身分證字號不符，請立即與客服中心聯絡。
                    </Remark>
                </SubContent>
                <SubmitButton
                    onClick={this.submit}
                    disable={disableSubmit}
                >
                    {isLoading ?
                        <SubmitLoading>
                            <GlobalStyle.Spin color="#fff"/>
                        </SubmitLoading>
                        :
                        null
                    }
                    送出
                </SubmitButton>
            </Content>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER_REFUND}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            會員退款
                        </CupoySrefView>
                    }
                    middleComponent="我要退款"
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        memberinfo: state.member.memberinfo,
        haslogin: state.member.haslogin,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberRefundApplyView);