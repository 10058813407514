/*=====================================
    MemberRefund

    Author: Gray
    CreateTime: 2018 / 03 / 13
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { GlobalStyle, MemberStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/

/*--------------------------
    Main Component
--------------------------*/
class MemberRefundView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {

        };
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        this.init(this.props);
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props) {

    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return <NeedLoginBlock />;
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        return (
            <div>
                <CupoySrefView 
                    statename={CupoyRouter.STATES.MEMBER_REFUND_APPLY}
                >
                    <MemberStyle.RowItemContainer>
                        <MemberStyle.RowItemLeftContainer>
                            <MemberStyle.GrayIcon>
                                <i className="icon-coin" style={{color:"#fff"}}/>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>我要退款</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer>
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <CupoySrefView 
                    statename={CupoyRouter.STATES.MEMBER_REFUND_LOGS}
                >
                    <MemberStyle.RowItemContainer>
                        <MemberStyle.RowItemLeftContainer>
                            <MemberStyle.GrayIcon>
                                <i className="zmdi zmdi-trending-up" style={{color:"#fff", fontSize: '18px'}}></i>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>退款申請記錄</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer>
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
            </div>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            返回
                        </CupoySrefView>
                    }
                    middleComponent="帳戶退款"
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberRefundView);