import actionTypes from "actions/types/memberActionTypes";
import GLOBAL from '../../nonnmwebglobal';
import systemMsgActions from './systemMsgActions';
import cardCartActions from './cardCartActions';
import WebViewBridgeUtil from '../../components/commons/WebViewBridgeUtil';
import customAxios from 'api/customAxios';

const API_URL = GLOBAL.URL_PREFIX + '/MemberAction.do';
const MSGLOG_API_URL = GLOBAL.URL_PREFIX + '/MsgLogAction.do';
const MEMBER_TRADELOG_API_URL = GLOBAL.URL_PREFIX + '/MemberTradeLogAction.do';
const REFUND_API_URL = GLOBAL.URL_PREFIX + '/RefundAction.do';
const CUSTOMER_CASE_API_URL = GLOBAL.URL_PREFIX + '/CustomerAction.do';
const INVOICE_API_URL = GLOBAL.URL_PREFIX + '/InvoiceAction.do';

// 登入
function loginStarted(payload) {
    return { type: actionTypes.loginStarted, payload };
}
function loginSuccess(payload) {
    return { type: actionTypes.loginSuccess, payload };
}
function loginFailed(payload) {
    return { type: actionTypes.loginFailed, payload };
}
function login(payload = {}) {
    return dispatch => {

        dispatch(loginStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            const url = `${API_URL}?op=login`;

            let form = new FormData();
            form.append('memberid', payload.memberid);
            form.append('passwd', payload.passwd);
            form.append('passcode', payload.passcode);

            customAxios(url,
                {
                    method: "POST",
                    body: form
                }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }
                            
                            dispatch(getMemberAcntInfo());

                            // 有未讀系統簡訊再呼叫getUnreadSystemMsgs取得未讀系統簡訊列表
                            result.unread_systemmsgids
                            && (typeof result.unread_systemmsgids === 'string')
                            && dispatch(systemMsgActions.getUnreadSystemMsgs(result.unread_systemmsgids));

                            dispatch(loginSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(loginFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(loginFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

/**
 * 驗證身分證並登入 ( 超過一個月未登入要驗證 )
 * @author Ac
 * @time 2020 / 08 / 11
 */
function loginAndCheckId(payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {

            const {
                memberId,
                password,
                idNumber,
            } = payload;

            const url = `${API_URL}?op=loginAndCheckId`;
            const form = new FormData();
            form.append('memberid', memberId);
            form.append('passwd', password);
            form.append('idnumber', idNumber);

            customAxios(
                url,
                {
                    method: 'POST',
                    body: form,
                }
            ).then(response => {
                
                if (response.status === 503) {
                    // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                    reject({
                        error: 'error.503error',
                        message: '伺服器沒有開啟'
                    });
                    return;
                }

                response.json().then(result => {
                    if (!result) {
                        reject();
                        return;
                    }

                    if(result.error) {
                        reject(result.error);
                        return 
                    }

                    dispatch(getMemberAcntInfo());

                    // 有未讀系統簡訊再呼叫getUnreadSystemMsgs取得未讀系統簡訊列表
                    result.unread_systemmsgids
                    && (typeof result.unread_systemmsgids === 'string')
                    && dispatch(systemMsgActions.getUnreadSystemMsgs(result.unread_systemmsgids));

                    dispatch(loginSuccess(result));

                    resolve(result);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                reject(error);
            });
        });
    };
}

// 登入逾時 (已被server登出)
function loginStatusExpired() {
    return { type: actionTypes.loginStatusExpired };
}

// 取得會員基本資料
function getMemberAcntInfoStarted(payload) {
    return { type: actionTypes.getMemberAcntInfoStarted, payload };
}
function getMemberAcntInfoSuccess(payload) {
    return { type: actionTypes.getMemberAcntInfoSuccess, payload };
}
function getMemberAcntInfoFailed(payload) {
    return { type: actionTypes.getMemberAcntInfoFailed, payload };
}
function getMemberAcntInfo(payload = {}) {
    return dispatch => {

        dispatch(getMemberAcntInfoStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            customAxios(API_URL + '?op=getMemberAcntInfo')
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    
                    response
                        .json()
                        .then(result => {

                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return;
                            }
                            dispatch(getMemberPageMetrics());
                            dispatch(getMemberMsgUnReadcnt());
                            dispatch(cardCartActions.listCardCartItems());
                            dispatch(getMemberAcntInfoSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(getMemberAcntInfoFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(getMemberAcntInfoFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}


// 更新會員基本資料
function updMemberStarted(payload) {
    return { type: actionTypes.updMemberStarted, payload };
}
function updMemberSuccess(payload) {
    return { type: actionTypes.updMemberSuccess, payload };
}
function updMemberFailed(payload) {
    return { type: actionTypes.updMemberFailed, payload };
}
function updMember(payload = {}) {
    return dispatch => {

        dispatch(updMemberStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {
            let form = new FormData();
            form.append('op', 'updMember');            
            form.append('name', payload.name);
            form.append('nickname', payload.nickname);
            form.append('birthday', payload.birthday);
            form.append('city', payload.city);
            form.append('region', payload.region);
            form.append('postalcode', payload.postalcode);
            form.append('address', payload.addreGss);
            form.append('isdonate', payload.isdonate);
            form.append('npoban', payload.npoban);
            form.append('carrierid', payload.carrierid);
            form.append('carriertype', payload.carriertype);
            form.append('invoicetype', payload.invoicetype);
            form.append('crn', payload.crn);
            form.append('sex', payload.sex);
            form.append('companytitle', payload.companytitle);
            form.append('shownametype', payload.shownametype);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            // 重拉會員資料更新store
                            dispatch(getMemberAcntInfo()).then((metainfo) => {
                                dispatch(updMemberSuccess(result));
                                resolve(result);
                            }, (error) => {
                                reject(error);
                            });
                        })
                        .catch(error => {
                            dispatch(updMemberFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(updMemberFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

// 更新email，重送認證信
function updEMailAndSendAuthStarted(payload) {
    return { type: actionTypes.updEMailAndSendAuthStarted, payload };
}
function updEMailAndSendAuthSuccess(payload) {
    return { type: actionTypes.updEMailAndSendAuthSuccess, payload };
}
function updEMailAndSendAuthFailed(payload) {
    return { type: actionTypes.updEMailAndSendAuthFailed, payload };
}
function updEMailAndSendAuth(payload = {}) {
    return dispatch => {

        dispatch(updEMailAndSendAuthStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'updEMailAndSendAuth');
            form.append('email', payload.email);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(updEMailAndSendAuthSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(updEMailAndSendAuthFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(updEMailAndSendAuthFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

// 修改手機號碼，重送簡訊認證
function updMobilePhoneAndSendSmSAuthStarted(payload) {
    return { type: actionTypes.updMobilePhoneAndSendSmSAuthStarted, payload };
}
function updMobilePhoneAndSendSmSAuthSuccess(payload) {
    return { type: actionTypes.updMobilePhoneAndSendSmSAuthSuccess, payload };
}
function updMobilePhoneAndSendSmSAuthFailed(payload) {
    return { type: actionTypes.updMobilePhoneAndSendSmSAuthFailed, payload };
}
function updMobilePhoneAndSendSmSAuth(payload = {}) {
    return dispatch => {

        dispatch(updMobilePhoneAndSendSmSAuthStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            let form = new FormData();
            form.append('op', 'updMobilePhoneAndSendSmSAuth');
            form.append('mobilephone', payload.mobilephone);
            payload.passcode && form.append('passcode', payload.passcode);

            customAxios(API_URL,
                {
                    method: "POST",
                    body: form
                }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(updMobilePhoneAndSendSmSAuthSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(updMobilePhoneAndSendSmSAuthFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(updMobilePhoneAndSendSmSAuthFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

/**
 * 忘記帳號
 * @author Ac
 * @time 2020 / 08 / 10
 */
function forgetAccount(payload) {
    return () => {
        return new Promise((resolve, reject) => {

            const {
                idNumber,
                mobilePhone,
            } = payload;

            const url = `${API_URL}?op=forgetAccount`;
            const form = new FormData();

            idNumber && form.append('idnumber', idNumber);
            mobilePhone && form.append('mobilephone', mobilePhone);

            customAxios(
                url,
                {
                    method: 'POST',
                    body: form,
                }
            ).then(response => {
                
                if (response.status === 503) {
                    // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                    reject({
                        error: 'error.503error',
                        message: '伺服器沒有開啟'
                    });
                    return;
                }

                response.json().then(result => {
                    if (!result) {
                        reject();
                        return;
                    }

                    if(result.error) {
                        reject(result.error);
                        return 
                    }

                    resolve(result);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                console.log(error);
                // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                reject(error);
            });
        });
    };
}

/**
 * 寄送忘記帳號信
 * @author Ac
 * @time 2020 / 08 / 10
 */
function sendAccountEmail(payload) {
    return () => {
        return new Promise((resolve, reject) => {

            const {
                memberId,
                token,
                email,
            } = payload;

            const url = `${API_URL}?op=sendAccountEmail`;
            const form = new FormData();
            form.append('memberid', memberId);
            form.append('token', token);
            form.append('email', email);

            customAxios(
                url,
                {
                    method: 'POST',
                    body: form,
                }
            ).then(response => {
                
                if (response.status === 503) {
                    // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                    reject({
                        error: 'error.503error',
                        message: '伺服器沒有開啟'
                    });
                    return;
                }

                response.json().then(result => {
                    if (!result) {
                        reject();
                        return;
                    }

                    if(result.error) {
                        reject(result.error);
                        return 
                    }

                    resolve(result);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                reject(error);
            });
        });
    };
}

function forgetPasswordStarted(payload) {
    return { type: actionTypes.forgetPasswordStarted, payload };
}
function forgetPasswordSuccess(payload) {
    return { type: actionTypes.forgetPasswordSuccess, payload };
}
function forgetPasswordFailed(payload) {
    return { type: actionTypes.forgetPasswordFailed, payload };
}

//忘記密碼
function forgetPassword(payload = {}) {
    return dispatch => {

        dispatch(forgetPasswordStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {


            let form = new FormData();
            form.append('op', 'forgetPassword');
            form.append('email', payload.email);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(forgetPasswordSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(forgetPasswordFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(forgetPasswordFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}


// 重設密碼
function updPasswordStarted(payload) {
    return { type: actionTypes.updPasswordStarted, payload };
}
function updPasswordSuccess(payload) {
    return { type: actionTypes.updPasswordSuccess, payload };
}
function updPasswordFailed(payload) {
    return { type: actionTypes.updPasswordFailed, payload };
}
function updPassword(payload = {}) {
    return dispatch => {

        dispatch(updPasswordStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {


            let form = new FormData();
            form.append('op', 'updPassword');
            form.append('mrscid', payload.mrscid);
            form.append('token', payload.token);
            form.append('passwd', payload.passwd);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(updPasswordSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(updPasswordFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(updPasswordFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

// 登出
function logoutStarted(payload) {
    return { type: actionTypes.logoutStarted, payload };
}
function logoutSuccess(payload) {
    return { type: actionTypes.logoutSuccess, payload };
}
function logoutFailed(payload) {
    return { type: actionTypes.logoutFailed, payload };
}
function logout(payload = {}) {
    return dispatch => {

        dispatch(logoutStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            let form = new FormData();
            form.append('op', 'logout');

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {

                            // if (!result) {
                            //     reject();
                            //     return;
                            // }

                            // if(result.error) {
                            //     reject(result.error);
                            //     return 
                            // }

                            dispatch(logoutSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(logoutFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(logoutFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

// 驗證email
function verifyEmailAuthStarted(payload) {
    return { type: actionTypes.verifyEmailAuthStarted, payload };
}
function verifyEmailAuthSuccess(payload) {
    return { type: actionTypes.verifyEmailAuthSuccess, payload };
}
function verifyEmailAuthFailed(payload) {
    return { type: actionTypes.verifyEmailAuthFailed, payload };
}
function verifyEmailAuth(payload = {}) {
    return dispatch => {

        dispatch(verifyEmailAuthStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {


            let form = new FormData();
            form.append('op', 'verifyEmailAuth');
            form.append('emailauth', payload.emailauth);
            form.append('mrscid', payload.mrscid);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(verifyEmailAuthSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(verifyEmailAuthFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(verifyEmailAuthFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

// 驗證簡訊
function verifySmsAuthStarted(payload) {
    return { type: actionTypes.verifySmsAuthStarted, payload };
}
function verifySmsAuthSuccess(payload) {
    return { type: actionTypes.verifySmsAuthSuccess, payload };
}
function verifySmsAuthFailed(payload) {
    return { type: actionTypes.verifySmsAuthFailed, payload };
}
function verifySmsAuth(payload = {}) {
    return dispatch => {

        dispatch(verifySmsAuthStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

 
            let form = new FormData();
            form.append('op', 'verifySmsAuth');
            form.append('smsauth', payload.smsauth);
            form.append('mrscid', payload.mrscid);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return;
                            }

                            dispatch(verifySmsAuthSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(verifySmsAuthFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(verifySmsAuthFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

// 登入歷程記錄
function listLoginHistorysStarted(payload) {
    return { type: actionTypes.listLoginHistorysStarted, payload };
}
function listLoginHistorysSuccess(payload) {
    return { type: actionTypes.listLoginHistorysSuccess, payload };
}
function listLoginHistorysFailed(payload) {
    return { type: actionTypes.listLoginHistorysFailed, payload };
}
function listLoginHistorys(payload = {}) {
    return dispatch => {

        dispatch(listLoginHistorysStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            // TODO: 記得改API_URL與form內容
            let form = new FormData();
            form.append('op', 'listLoginHistorys');
            form.append('start', payload.start);
            form.append('len', 20);

            customAxios(API_URL,
                {
                    method: "POST",
                    body: form
                }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listLoginHistorysSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listLoginHistorysFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listLoginHistorysFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function listMemberMsgLogsStarted(payload) {
    return { type: actionTypes.listMemberMsgLogsStarted, payload };
}
function listMemberMsgLogsSuccess(payload) {
    return { type: actionTypes.listMemberMsgLogsSuccess, payload };
}
function listMemberMsgLogsFailed(payload) {
    return { type: actionTypes.listMemberMsgLogsFailed, payload };
}
function listMemberMsgLogs(payload = {}) {
    return dispatch => {

        dispatch(listMemberMsgLogsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {
            
            let form = new FormData();
            form.append('op', 'listMemberMsgLogs');
            form.append('start', payload.start);

            customAxios(MSGLOG_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listMemberMsgLogsSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listMemberMsgLogsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listMemberMsgLogsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function getMemberMsgLogDetailStarted(payload) {
    return { type: actionTypes.getMemberMsgLogDetailStarted, payload };
}
function getMemberMsgLogDetailSuccess(payload) {
    return { type: actionTypes.getMemberMsgLogDetailSuccess, payload };
}
function getMemberMsgLogDetailFailed(payload) {
    return { type: actionTypes.getMemberMsgLogDetailFailed, payload };
}
function getMemberMsgLogDetail(payload = {}) {
    return dispatch => {

        dispatch(getMemberMsgLogDetailStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {


            let form = new FormData();
            form.append('op', 'getMemberMsgLogDetail');
            form.append('msgid', payload.msgid);

            customAxios(MSGLOG_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(getMemberMsgLogDetailSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(getMemberMsgLogDetailFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(getMemberMsgLogDetailFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function getMemberPageMetricsStarted(payload) {
    return { type: actionTypes.getMemberPageMetricsStarted, payload };
}
function getMemberPageMetricsSuccess(payload) {
    return { type: actionTypes.getMemberPageMetricsSuccess, payload };
}
function getMemberPageMetricsFailed(payload) {
    return { type: actionTypes.getMemberPageMetricsFailed, payload };
}
function getMemberPageMetrics(payload = {}) {
    return dispatch => {

        dispatch(getMemberPageMetricsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            let form = new FormData();
            form.append('op', 'getMemberPageMetrics');

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }
                            
                            dispatch(getMemberPageMetricsSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(getMemberPageMetricsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(getMemberPageMetricsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function listTradeLogsStarted(payload) {
    return { type: actionTypes.listTradeLogsStarted, payload };
}
function listTradeLogsSuccess(payload) {
    return { type: actionTypes.listTradeLogsSuccess, payload };
}
function listTradeLogsFailed(payload) {
    return { type: actionTypes.listTradeLogsFailed, payload };
}
function listTradeLogs(payload = {}) {
    return dispatch => {

        dispatch(listTradeLogsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'listTradeLogs');
            form.append('start', payload.start);

            customAxios(MEMBER_TRADELOG_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listTradeLogsSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listTradeLogsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listTradeLogsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function listRefundApplicationsStarted(payload) {
    return { type: actionTypes.listRefundApplicationsStarted, payload };
}
function listRefundApplicationsSuccess(payload) {
    return { type: actionTypes.listRefundApplicationsSuccess, payload };
}
function listRefundApplicationsFailed(payload) {
    return { type: actionTypes.listRefundApplicationsFailed, payload };
}
function listRefundApplications(payload = {}) {
    return dispatch => {

        dispatch(listRefundApplicationsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'listRefundApplications');
            form.append('start', payload.start);

            customAxios(REFUND_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listRefundApplicationsSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listRefundApplicationsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listRefundApplicationsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function addRefundApplicationStarted(payload) {
    return { type: actionTypes.addRefundApplicationStarted, payload };
}
function addRefundApplicationSuccess(payload) {
    return { type: actionTypes.addRefundApplicationSuccess, payload };
}
function addRefundApplicationFailed(payload) {
    return { type: actionTypes.addRefundApplicationFailed, payload };
}
function addRefundApplication(payload = {}) {
    return dispatch => {

        dispatch(addRefundApplicationStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'addRefundApplication');
            form.append('bankcode', payload.bankcode);
            form.append('bank', payload.bank);
            form.append('subbank', payload.subbank);
            form.append('bankaccount', payload.bankaccount);            

            customAxios(REFUND_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(addRefundApplicationSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(addRefundApplicationFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(addRefundApplicationFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function listCustomerCases(payload = {}) {

    return () => {

        const promise = new Promise((resolve, reject) => {
            
            let form = new FormData();
            form.append('op', 'listCustomerCases');
            form.append('start', payload.start);

            customAxios(CUSTOMER_CASE_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            resolve(result);
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    reject(error);
                });
        });
        return promise;
    };
}

function getCustomerCaseReplyStarted(payload) {
    return { type: actionTypes.getCustomerCaseReplyStarted, payload };
}
function getCustomerCaseReplySuccess(payload) {
    return { type: actionTypes.getCustomerCaseReplySuccess, payload };
}
function getCustomerCaseReplyFailed(payload) {
    return { type: actionTypes.getCustomerCaseReplyFailed, payload };
}
function getCustomerCaseReply(payload = {}) {
    return dispatch => {

        dispatch(getCustomerCaseReplyStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'getCustomerCaseReply');
            form.append('caseid', payload.caseid);
            form.append('ctime', payload.ctime);

            customAxios(CUSTOMER_CASE_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(getCustomerCaseReplySuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(getCustomerCaseReplyFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(getCustomerCaseReplyFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}


function listCustomerCaseClassesStarted(payload) {
    return { type: actionTypes.listCustomerCaseClassesStarted, payload };
}
function listCustomerCaseClassesSuccess(payload) {
    return { type: actionTypes.listCustomerCaseClassesSuccess, payload };
}
function listCustomerCaseClassesFailed(payload) {
    return { type: actionTypes.listCustomerCaseClassesFailed, payload };
}
function listCustomerCaseClasses(payload = {}) {
    return dispatch => {

        dispatch(listCustomerCaseClassesStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            let form = new FormData();
            form.append('op', 'listCustomerCaseClasses');

            customAxios(CUSTOMER_CASE_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listCustomerCaseClassesSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listCustomerCaseClassesFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listCustomerCaseClassesFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function createCustomerCaseStarted(payload) {
    return { type: actionTypes.createCustomerCaseStarted, payload };
}
function createCustomerCaseSuccess(payload) {
    return { type: actionTypes.createCustomerCaseSuccess, payload };
}
function createCustomerCaseFailed(payload) {
    return { type: actionTypes.createCustomerCaseFailed, payload };
}
function createCustomerCase(payload = {}) {
    return dispatch => {

        dispatch(createCustomerCaseStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'createCustomerCase');
            form.append('passcode', payload.passcode);
            form.append('csclassid', payload.csclassid);
            form.append('contactperson', payload.contactperson);
            form.append('contactphone', payload.contactphone);
            form.append('comment', payload.comment);


            customAxios(CUSTOMER_CASE_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(createCustomerCaseSuccess({result, ...payload}));
                            // dispatch(listCustomerCases({start: 0}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(createCustomerCaseFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(createCustomerCaseFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function listInvoicesStarted(payload) {
    return { type: actionTypes.listInvoicesStarted, payload };
}
function listInvoicesSuccess(payload) {
    return { type: actionTypes.listInvoicesSuccess, payload };
}
function listInvoicesFailed(payload) {
    return { type: actionTypes.listInvoicesFailed, payload };
}
function listInvoices(payload = {}) {
    return dispatch => {

        dispatch(listInvoicesStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'listInvoices');
            form.append('start', payload.start);

            customAxios(INVOICE_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listInvoicesSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listInvoicesFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listInvoicesFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function updateMemberAcntInfoToStorge (payload = {}){
    return {
        type: actionTypes.updateMemberAcntInfoToStorge,
        payload,
    };
}

function setMemberMsgLogReadSuccess(msgids) {

    const payload = { msgids };

    return {
        type: actionTypes.setMemberMsgLogReadSuccess,
        payload,
    };
}

function setMemberMsgLogRead(payload = {}) {

    return dispatch => {

        const { msgids } = payload;

        // example: msgids=id1,id2,id3
        const msgIdsStr = msgids.reduce((str, msgid, currentIdx) => 
            currentIdx === 0 ?
                str += msgid : str += `,${msgid}`
        , '');
        
        const url = `${MSGLOG_API_URL}?op=setMemberMsgLogRead`;
        const form = new FormData();
        form.append('msgids', msgIdsStr);
        
        const promise = new Promise((resolve, reject) => {
            customAxios(url,
                {
                    method: "POST",
                    body: form
                }
            ).then(response => {

                if (response.status === 503) {
                    // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                    reject({
                        error: 'error.503error',
                        message: '伺服器沒有開啟'
                    });

                    return;
                }
                response.json().then(result => {
                    if (!result) {
                        reject();
                        return;
                    }

                    if(result.error) {
                        reject(result.error);
                        return 
                    }
                    dispatch(setMemberMsgLogReadSuccess(msgids));
                    resolve(result);
                })
                .catch(error => {
                    reject(error);
                });
            })
            .catch(error => {
                // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                reject(error);
            });
        });

        return promise;
    }
}

function getMemberMsgUnReadcnt() {
    
    return dispatch => {

        const url = `${MSGLOG_API_URL}?op=getMemberMsgUnReadcnt`;

        const promise = new Promise((resolve, reject) => {
            customAxios(url,
                { method: "GET" }
            ).then(response => {

                if (response.status === 503) {
                    // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                    reject({
                        error: 'error.503error',
                        message: '伺服器沒有開啟'
                    });

                    return;
                }
                response.text().then(result => {
                    if (!result) {
                        reject();
                        return;
                    }

                    if(result.error) {
                        reject(result.error);
                        return 
                    }

                    const _result = JSON.parse(result);

                    if(!Number.isInteger(_result)) {
                        reject();
                        return;
                    }

                    dispatch(getMemberMsgUnReadcntSuccess(_result));
                    resolve(result);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                reject(error);
            });
        });

        return promise;
    }
}

function getMemberMsgUnReadcntSuccess(payload) {
    return {
        type: actionTypes.getMemberMsgUnReadcntSuccess,
        payload,
    };
}

function updIDNumberSuccess(idnumber) {
    return {
        type: actionTypes.updIDNumberSuccess,
        payload: {
            idnumber,
        },
    };
}

function updIDNumber(idnumber) {
    return dispatch => {

        const form = new FormData();
        form.append('op', 'updIDNumber');
        form.append('idnumber', idnumber);
        
        const promise = new Promise((resolve, reject) => {
        
            customAxios(API_URL, {
                method: 'POST',
                body: form,
            }).then(response => {
                if (response.status === 503) {
                    // AWS server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                    reject();
                    return;
                }
        
                response.json().then(result => {
                    if (!result || result.error) {
                        reject(result && result.error);
                        return;
                    }
                    dispatch(updIDNumberSuccess(idnumber));
                    resolve(result);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                reject(error);
            });
        });
        return promise;
    }
}

/**
 * 會員載具歸戶 - 小平台
 * @author Ac
 * @time 2020 / 06 / 24
 */
function joinMemberCarrier() {
    return () => {
        const promise = new Promise((resolve, reject) => {

            const url = `${GLOBAL.URL_PREFIX}/MemberAction.do?op=joinMemberCarrier`;

            customAxios(url).then(response => {
                response.json().then(result => {

                    if (response.status === 503) {
                        // AWS server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({error: 'error.503error', message: '伺服器沒有開啟'});
                        return;
                    }

                    if(!result || result.error) {
                        reject(result && result.error);
                        return;
                    }

                    const {
                        action,
                        ...otherInfo
                    } = result;

                    if(GLOBAL.isAppFile) {
                        const form = {
                            method: 'POST',
                            ...result,
                        };

                        WebViewBridgeUtil.postJoinMemberCarrierMessage(form);
                        resolve(true);
                    } else {

                        const form = document.createElement('form');
                        form.setAttribute('action', action);
                        form.setAttribute('method', 'POST');
                    
                        Object.keys(otherInfo).forEach(key => {
                            const input = document.createElement('input');
                            const value = otherInfo[key];
                            input.setAttribute('name', key);
                            input.setAttribute('value', value);
                            input.setAttribute('type', 'hidden');
                            form.appendChild(input);
                        });
                    
                        document.body.appendChild(form);
                        form.submit();

                        resolve(true);
                    }
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        });
        return promise;
    };
}

const actions = {
    login, // 登入
    loginAndCheckId, // 驗證身分證並登入 ( 超過一個月未登入要驗證 )
    loginStatusExpired, // 登入逾時 (已被server登出)
    getMemberAcntInfo, // 會員資料
    updateMemberAcntInfoToStorge, // 更新在store的會員資料
    updMember, // 更新基本資料
    updEMailAndSendAuth, // 更新email重送認證信
    updMobilePhoneAndSendSmSAuth, // 更新手機重送簡訊
    forgetAccount, // 忘記帳號
    sendAccountEmail, // 寄送忘記帳號信
    forgetPassword, // 忘記密碼
    updPassword, // 重設密碼
    logout, // 登出
    verifyEmailAuth, // 驗證email
    verifySmsAuth, // 驗證簡訊
    listLoginHistorys, // 登入歷程記錄
    listMemberMsgLogs, // 系統訊息列表
    getMemberMsgLogDetail, // 取得系統訊息詳細資料
    getMemberPageMetrics, // 取得會員首頁相關數據 ( 除了系統訊息未讀數量 )
    listTradeLogs, // 取得會員退款交易記錄
    listRefundApplications, // 取得會員退款申請紀錄
    addRefundApplication, // 新增退款申請單
    listCustomerCases, // 列出客訴單
    getCustomerCaseReply, // 取得客訴單回覆內容
    listCustomerCaseClasses, // 取得客訴單分類列表
    createCustomerCase, // 新建客訴單
    listInvoices, // 列出發票
    setMemberMsgLogRead, // 將系統訊息更新為已讀
    getMemberMsgUnReadcnt, // 取得系統訊息未讀數量
    updIDNumber, // 重設身分證
    joinMemberCarrier, // 會員載具歸戶 - 小平台
};

export default actions;