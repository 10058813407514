/*=====================================
    UpgradeMembershipView

    Author: Ac
    CreateTime: 2018 / 09 / 13
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, {
    useEffect,
} from 'react';
import styled from 'styled-components';
import CupoyImage from 'components/commons/CupoyImage';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from 'components/commons/router/CupoyRouter';
import CupoySrefView from "components/commons/router/CupoySrefView";
import GLOBAL from 'nonnmwebglobal';
import {
    Colors,
    TableStyle,
    GlobalStyle,
} from 'stylecomponents';
/*--------------------------
    Styled
--------------------------*/
const Table = styled(TableStyle.Table)`
    color: #4A4A4A;
`;
const TableHeader = styled.thead`
    border-bottom: 1px solid #E8E8E8;
    td {
        /* border-bottom: 1px solid #E8E8E8; */
        background-color: #FFF;
    }
`;
const TableHeaderHeading = styled(TableStyle.TableHeading)`
    width: 25%;
    height: 60px;
    font-size: 14px;
`;
const TableHeaderHeadingContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
    }
`;
const MemberAvatar = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-right: 8px;

    @media only screen and (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 8px;
    }
`;
const TableBodyHeading = styled(TableStyle.TableHeading)`
    padding: 12px;
    min-height: 68px;
    text-align: left;
    border-right: 1px solid #E8E8E8;
`;
const TableRow = styled(TableStyle.TableRow)`
    border-bottom: 1px solid #E8E8E8;
`;
const TableData = styled(TableStyle.TableData)`
    padding: 12px;
    min-height: 68px;
    height: auto;
    text-align: ${({ justify }) => justify ? 'justify' : 'center'};
    width: ${({ count }) => count ? `${100 / count}%` : '25%'};
    background-color: #FFF;

    &:not(:last-child) {
        border-right: 1px solid #E8E8E8;
    }
`;
const TableBody = styled.tbody`
`;
const CheckIcon = styled.div`
    color: ${Colors.Main};
    font-size: 24px;
`;
const CloseIcon = styled.div`
    color: #4A4A4A;
    font-size: 23px;
`;
/*--------------------------
    Main Component
--------------------------*/
const UpgradeMembershipView = props => {

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    return (
        <GlobalStyle.Container
            hasFixedTopbar
            hasFixedTabbar
            backgroundColor="#f5f0f0"
        >

            <TopbarView
                isFixed
                leftComponent={
                <CupoySrefView
                    statename={CupoyRouter.STATES.HELP}
                    stylecomponent={GlobalStyle.TopbarButton}
                >
                    <GlobalStyle.TopbarIcon>
                        <i className="zmdi zmdi-chevron-left"></i>
                    </GlobalStyle.TopbarIcon>
                    返回
                </CupoySrefView>
                }
                middleComponent="如何升級會員"
            />

            <Table>
                <TableHeader>
                    <tr>
                        <td />
                        <TableHeaderHeading>
                            <TableHeaderHeadingContent>
                                <CupoyImage
                                    stylecomponent={MemberAvatar}
                                    src={`${GLOBAL.URL_PREFIX}/images/general_member_male_avatar.svg`}
                                />
                                <CupoyImage
                                    stylecomponent={MemberAvatar}
                                    src={`${GLOBAL.URL_PREFIX}/images/general_member_female_avatar.svg`}
                                />
                                一般會員
                            </TableHeaderHeadingContent>
                        </TableHeaderHeading>
                        <TableHeaderHeading>
                            <TableHeaderHeadingContent>
                                <CupoyImage
                                    stylecomponent={MemberAvatar}
                                    src={`${GLOBAL.URL_PREFIX}/images/full_member_male_avatar.svg`}
                                />
                                <CupoyImage
                                    stylecomponent={MemberAvatar}
                                    src={`${GLOBAL.URL_PREFIX}/images/full_member_female_avatar.svg`}
                                />
                                正式會員
                            </TableHeaderHeadingContent>
                        </TableHeaderHeading>
                        <TableHeaderHeading>
                            <TableHeaderHeadingContent>
                                <CupoyImage
                                    stylecomponent={MemberAvatar}
                                    src={`${GLOBAL.URL_PREFIX}/images/vip_member_male_avatar.svg`}
                                />
                                <CupoyImage
                                    stylecomponent={MemberAvatar}
                                    src={`${GLOBAL.URL_PREFIX}/images/vip_member_female_avatar.svg`}
                                />
                                VIP
                            </TableHeaderHeadingContent>
                        </TableHeaderHeading>
                    </tr>
                </TableHeader>
                <TableBody>
                    <TableRow>
                        <TableBodyHeading>email 驗證</TableBodyHeading>
                        <TableData><CloseIcon><i className="zmdi zmdi-close"></i></CloseIcon></TableData>
                        <TableData><CheckIcon><i className="zmdi zmdi-check"></i></CheckIcon></TableData>
                        <TableData><CheckIcon><i className="zmdi zmdi-check"></i></CheckIcon></TableData>
                    </TableRow>
                    <TableRow>
                        <TableBodyHeading>手機驗證</TableBodyHeading>
                        <TableData><CheckIcon><i className="zmdi zmdi-check"></i></CheckIcon></TableData>
                        <TableData><CheckIcon><i className="zmdi zmdi-check"></i></CheckIcon></TableData>
                        <TableData><CheckIcon><i className="zmdi zmdi-check"></i></CheckIcon></TableData>
                    </TableRow>
                    <TableRow>
                        <TableBodyHeading>身分證驗證</TableBodyHeading>
                        <TableData><CheckIcon><i className="zmdi zmdi-check"></i></CheckIcon></TableData>
                        <TableData><CheckIcon><i className="zmdi zmdi-check"></i></CheckIcon></TableData>
                        <TableData><CheckIcon><i className="zmdi zmdi-check"></i></CheckIcon></TableData>
                    </TableRow>
                    <TableRow>
                        <TableBodyHeading>VIP 會員升級</TableBodyHeading>
                        <TableData colSpan='3'>
                            三個月內消費累積金額滿3,000元 (隔日凌晨一點升級VIP)
                        </TableData>
                    </TableRow>
                    <TableRow>
                        <TableBodyHeading>發財金紅利酬賓計畫</TableBodyHeading>
                        <TableData colSpan='2'>
                            無法獲得發財金
                        </TableData>
                        <TableData justify>
                            單次消費交易金額每滿100元即可自動累積1點發財金，未滿交易金額100元部分則不予計算，亦無法累計至下次消費(含以後)合併計算。
                        </TableData>
                    </TableRow>
                    <TableRow>
                        <TableBodyHeading>發財商城免費專區</TableBodyHeading>
                        <TableData colSpan='2'>
                            無法使用發財金兌換發財商城商品
                        </TableData>
                        <TableData justify>
                            每天只限兌換三次商品(凌晨零時起算)，無法於一天內兌換商品四次以上，需於隔日才可再兌換一次。
                        </TableData>
                    </TableRow>
                </TableBody>
            </Table>

        </GlobalStyle.Container>
    );
};

export default UpgradeMembershipView;