import React, {
    useRef,
    useState,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TimeFormat from 'components/commons/TimeFormat';
import CupoyNoteContent from 'components/commons/CupoyNoteContent';
import MarkdownContentView from 'cupoy-md-editor/lib/preview';
import { useLocation } from 'react-router';
import {
    DialogStyle,
    Colors,
    Button,
} from 'stylecomponents';

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
    display: flex;
    flex-direction: column;
`;
const Title = styled.div`
    color: #4A4A4A;
    line-height: 25px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #D9D9D9;
`;
const List = styled.ul`
    margin: 0;
`;
const ListItem = styled.li`
    border-bottom: 1px solid #EEE;
    font-size: 15px;
    line-height: 20px;
    padding: 20px 0;

    img {
        max-width: 100%;
    }
`;
const Time = styled.div`
    min-width: 155px;
    color: ${Colors.Dark5};
    margin-top: 16px;
    text-align: right;
`;
const Btn = styled(Button.Orange)`
    width: 150px;
    margin: 40px auto 0;
`;

/*--------------------------
    Main Component
--------------------------*/
const SystemTextMsgDialog = props => {

    const {
        list,
    } = props;

    const location = useLocation();
    const prevLocation = useRef(location);
    const [isShow, setIsShow] = useState(Array.isArray(list) && list.length > 0);

    function closeDialog() {
        setIsShow(false);
    }

    useEffect(() => {
        if (Array.isArray(list) && list.length > 0) {
            setIsShow(true);
        }
    }, [list]);

    // 當location變動時，關閉顯示 ( 點擊dialog內容連結時會觸發此行為 ) @ac
    useEffect(() => {

        if (location !== prevLocation.current) {
            setIsShow(false);
        }

        prevLocation.current = location;
    }, [location]);

    if (!isShow) {
        return null;
    }

    return (
        <DialogStyle.Content
            // style props
            maxWidth="660px"
            width='calc(100% - 20px)'
            margin="90px auto 20px"
            padding="20px"
            borderRadius="3px"
            // dialog props
            visible
            animation="zoom"
            maskAnimation="fade"
            closable={false}
            onClose={closeDialog}
        >
            <DialogStyle.CloseButton onClick={closeDialog}>
                <i className="zmdi zmdi-close"></i>
            </DialogStyle.CloseButton>
            <Title>登入訊息通知</Title>
            <Content>
                <List>
                    {list.map(textMsg => {

                        const {
                            msgid,
                            content,
                            createtime,
                            note_editor_type,
                            markdown
                        } = textMsg;

                        return (
                            <ListItem key={msgid}>
                                {note_editor_type === 1 ?
                                    <MarkdownContentView mdcontent={markdown} />      
                                    :<CupoyNoteContent html={content} />
                                }
                                <Time>
                                    <TimeFormat
                                        format='simpleFullTime'
                                        time={createtime}
                                    />
                                </Time>
                            </ListItem>
                        );
                    })}
                </List>
                
                <Btn onClick={closeDialog}>關閉</Btn>
            </Content>
        </DialogStyle.Content>
    );
};

SystemTextMsgDialog.propTypes = {
    list: PropTypes.array,
};

SystemTextMsgDialog.defaultProps = {
    list: [],
};

export default SystemTextMsgDialog;
