/*=====================================
    虛寶市集

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle, TabActivity} from 'stylecomponents';
import styled from 'styled-components';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import SellView from "components/vitem/SellView";
import BuyView from "components/vitem/BuyView";
import { Route } from "react-router-dom";
import VitemProducts from 'components/vitem/VitemProducts';
import GLOBAL from 'nonnmwebglobal';
import CupoyLink from 'components/commons/CupoyLink';
import CupoyImage from 'components/commons/CupoyImage';

/*--------------------------
    Styled Component
--------------------------*/
const TopbarMiddle = styled.h3`
    height: 100%;
    line-height: 44px;
    margin: 0;
    font-size: 16px;
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
    color: #FFFFFF;
    text-align: center;
`;
const BannerImage = styled.img`
    display: block;
    width: 100%;
    aspect-ratio: ${750/265};
`;

/*--------------------------
    Main Component
--------------------------*/
class TransactionListView extends PureComponent {

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        
        const {
            gameid, 
            serverid,
        } = this.props.match.params;

        const gameListIds = this.props.gameListIds;

        if(gameListIds.length === 0){
            this.props.gameActions.listGames();
        }

        if(!serverid){
            
            const { gameSeverListById } = this.props;
            const { cupoyStateReplace } = this.props.webActions;

            if(!serverid && !gameSeverListById[gameid]){
                this.props.gameActions.getGameServerList({
                    gameid,
                }).then(result => {
                    cupoyStateReplace(CupoyRouter.STATES.TRANSACTIONLIST_SELL, {
                        gameid,
                        serverid: result.resultMap.gameserverid0,
                    });
                });
            } else {
                cupoyStateReplace(CupoyRouter.STATES.TRANSACTIONLIST_SELL, {
                    gameid,
                    serverid: gameSeverListById[gameid][0].gameserverid,
                });
            }
        }
    
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            gameid, 
            serverid,
        } = this.props.match.params;
        
        const gameListMap = this.props.gameListMap;
        
        if(!Object.keys(gameListMap) > 0){
            return null;
        }
        const game = gameListMap[gameid];
        
        if(!game){
            return null;
        }

        const {
            isshowpurchase,
            mobilewebeventlinkurl: mobileWebEventLinkUrl,
            upmobilewebadvimg,
            upmobileweblinkurl,
        } = game;

        return (
            <GlobalStyle.Container 
                hasFixedTopbar
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.VITEM}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            {GLOBAL.VITEM_DISPLAY_TEXT}
                        </CupoySrefView>
                    }
                    middleComponent={
                        <TopbarMiddle>
                            {game.gamename}
                        </TopbarMiddle>
                    }
                />

                {!!upmobilewebadvimg && (
                    <CupoyLink
                        rel='noopener noreferrer'
                        target='_blank'
                        href={upmobileweblinkurl}
                    >
                        <CupoyImage
                            stylecomponent={BannerImage}
                            src={`${GLOBAL.imgSrcRootURL}/gameimgs/${gameid}/${upmobilewebadvimg}`}
                            isBackgroundImage={false}
                        />
                    </CupoyLink>
                )}
                
                {
                    isshowpurchase ?
                    <React.Fragment>
                        <TabActivity.TabActivityWrapper>
                            <CupoySrefView 
                                statename={CupoyRouter.STATES.TRANSACTIONLIST_SELL}
                                stylecomponent={TabActivity.TabActivityItem}
                                active={CupoyRouter.isActive(CupoyRouter.STATES.TRANSACTIONLIST_SELL)}
                                params={{
                                    gameid, 
                                    serverid,
                                }}
                            >
                                出售區
                            </CupoySrefView>
                            <CupoySrefView 
                                statename={CupoyRouter.STATES.TRANSACTIONLIST_BUY}
                                stylecomponent={TabActivity.TabActivityItem}
                                active={CupoyRouter.isActive(CupoyRouter.STATES.TRANSACTIONLIST_BUY)}
                                params={{
                                    gameid, 
                                    serverid,
                                }}
                            >
                                收購區
                            </CupoySrefView>
                        </TabActivity.TabActivityWrapper>

                        <Route path={CupoyRouter.PATHS.TRANSACTIONLIST_SELL} component={SellView} exact/>
                        <Route path={CupoyRouter.PATHS.TRANSACTIONLIST_BUY} component={BuyView} exact/>
                    </React.Fragment>
                    :
                    <VitemProducts
                        gameid={gameid}
                        mobileWebEventLinkUrl={mobileWebEventLinkUrl}
                    />
                }

            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        sellerMap: state.vitem.sellerMap,
        isloading: state.vitem.isloading,
        gameListMap: state.game.gameListMap,
        gameListIds: state.game.gameListIds,
        gameSeverListById: state.game.gameSeverListById,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        gameActions: bindActionCreators(actionCreators.gameActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(TransactionListView);