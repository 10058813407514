// @flow
/*=====================================
    虛寶市集

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, {PureComponent} from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import actionCreators from 'actions/creators';
import {GlobalStyle, StepBottomBar} from 'stylecomponents';
import styled from 'styled-components';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyNoteContent from "components/commons/CupoyNoteContent";
import MarkdownContentView from 'cupoy-md-editor/lib/preview';
import moment from 'moment';
import GLOBAL from '../../nonnmwebglobal';
import CupoyLink from 'components/commons/CupoyLink';
/*--------------------------
    Styled Component
--------------------------*/

const ProductList = styled.div `

    display: flex;
    justify-content: center;
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    background: #FFFFFF;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.05);

`;

const ProductImage = styled.div `

    min-width: 86px;
    height: 66px;
    margin-right: 16px;
    background-size: cover;
    background-position: center;
    background-image: url('${props => props.imageUrl ? props.imageUrl : null}');
    background-repeat: no-repeat;

`;

const ProductInfo = styled.div `

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

`;

const ProductName = styled.h3 `

    padding-bottom: 8px;
    font-size: 16px;
    color: #4A4A4A;

`;

const GameHomeLink = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    color: #F75C2F;
    font-size: 24px;
    height: 100%;

`;

const BuyQuantityList = styled.div `

    display: flex;
    justify-content: space-between;
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;

`;

const BuyTitle = styled.div `

    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333333;

`;

const Price = styled.del `

    display: inline-block;
    font-size: 11px;
    color: #808180;

`;

const NewPrice = styled.span `

    margin-left: 8px;
    font-size: 11px;
    color: #F75C2F;

`;

const MemberPrice = styled.span `

    padding-left: 5px;
    font-weight: bolder;
    font-size: 14px;
    color: #F75C2F;

`;

const ChooseQuantityBox = styled.div `

    display: flex;
    height: 37px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 2px 0 0 2px;

`;

const QuantityPlus = styled.div `
    width: 24px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-left: 1px solid #CCCCCC;
    cursor: pointer;
`;

const QuantityMinus = styled(QuantityPlus)`
    border-right: 1px solid #CCCCCC;
    border-left: 0;
`;

const Quantity = styled.div `

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    padding: 10px 11px;
    font-size: 14px;
    color: #333333;

`;

const QuantityUpdateModal = styled
    .select
    .attrs({id: 'QuantityUpdateModal'})`
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

`;

const GameContent = styled.div `

    overflow-y: scroll;
    padding: 16px;
    padding-bottom: 60px;

`;

const StepBottomWrapper = styled.div `

    display: flex;

`;

const LimitedContent = styled.div `

    margin-bottom: 16px;
    font-size: 13px;
    color: #777777;

`;

const Error = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 44px);
    font-size: 20px;
    font-weight: bold;
`;

/*--------------------------
    Main Component
--------------------------*/
class CardInformationView extends PureComponent {

    // ------------------------------ constructor ------------------------------
    constructor(props) {

        super(props);
        
        this.state = {
            productid: this.props.match.params.id,
            quantity: 1,
            isLoading: true,
            isFree: false,
            isBonus: false,
            isError: false,
            errorMessage: '',
        }

        this.quantityUpdate = this
            .quantityUpdate
            .bind(this);
        this.quantityPlus = this
            .quantityPlus
            .bind(this);
        this.quantityMinus = this
            .quantityMinus
            .bind(this);
        this.buyQuantityList = this
            .buyQuantityList
            .bind(this);
        this.addCart = this
            .addCart
            .bind(this);
        this.buyFree = this
            .buyFree
            .bind(this);
        this.buyBonus = this
            .buyBonus
            .bind(this);

    }

    // ------------------------------ componentDidMount
    // ------------------------------
    componentDidMount() {

        this
            .props
            .cardActions
            .getProductInfo({productid: this.state.productid})
            .then(result => {
                const cardProduct = this.props.cardProductMap[this.state.productid];

                if (cardProduct.memberprice === 0 || cardProduct.productclassid === 3) {
                    this.setState({
                        isFree: true,
                        isLoading: false,
                    });
                    return;
                }
                
                if (cardProduct.productclassid === 4) {
                    // 再次check後台發財商城開放狀態
                    this.checkWebState();
                    this.setState({
                        isBonus: true,
                        isLoading: false,
                    });
                    return;
                }

                this.setState({
                    isLoading: false,
                });
            }).catch(error => {
                if (error.code === 'error.notAllowBoughtError') {
                    this.setState({
                        isLoading: false,
                        isError: true,
                        errorMessage: error.message,
                    });
                }

            });

    }

    checkWebState = () => {
        this.props.webActions.checkWebState();
    }

    quantityUpdate(event) {

        let quantity = event.target.value;

        this.setState({quantity});
    }

    quantityPlus() {

        const product = this.props.cardProductMap[this.state.productid];
        let {islimited} = product;

        let canbuy = islimited
            ? product.canbuy
            : 10;

        if (this.state.quantity >= canbuy) {
            return;
        }
        this.setState({
            quantity: Number(this.state.quantity) + 1
        });

    }

    quantityMinus() {
        if (this.state.quantity <= 1) {
            return;
        }
        this.setState({
            quantity: Number(this.state.quantity) - 1
        });

    }

    addCart(event, totalPrice) {
        const {
            haslogin,
        } = this.props;

        if (!haslogin) {
            this.props.webActions.needLogin();
            return;
        }
        let {productid, quantity} = this.state;
        let {addCardToCart} = this.props.cardCartActions;

        addCardToCart({productid: productid, count: quantity}).then((result) => {
            this
                .props
                .webActions
                .openAlertDialog({
                    title: '商品成功加入購物車',
                    description: `數量:${quantity}\n總價:${totalPrice}`,
                    closeCallback: () => this
                        .props
                        .webActions
                        .cupoyStateGo(CupoyRouter.STATES.CART)
                });
        }, (error) => {

            var errorMsg = '發生無法預期的錯誤';
            var errorDescription = '';

            if (error && error.code) {
                switch (error.code) {
                    case 'error.privilege.noLogin':
                        return;
                    default:
                        errorMsg = error.message;
                        break;
                }
            }

            this
                .props
                .webActions
                .openAlertDialog({title: errorMsg, description: errorDescription});
        })
    }

    buyFree(event, payload) {

        const {
            memberinfo,
            haslogin,
        } = this.props;

        if (!haslogin) {
            this.props.webActions.needLogin();
            return;
        }

        const {
            idvalidflag,
            issmsauth,
            isemailauth,
        } = memberinfo;

        if(!idvalidflag) {
            this
            .props
            .webActions
            .openAlertDialog({
                title: '發生錯誤',
                description: '身分證未驗證',
            });
            return;
        }

        if(!issmsauth) {
            this
            .props
            .webActions
            .openAlertDialog({
                title: '發生錯誤',
                description: '行動電話未驗證',
            });
            return;
        }

        if(!isemailauth) {
            this
            .props
            .webActions
            .openAlertDialog({
                title: '發生錯誤',
                description: '電子信箱未驗證',
            });
            return;
        }
        
        this
            .props
            .cardOrderActions
            .buyFreeCardProduct(payload)
            .then((result) => {
                this
                    .props
                    .webActions
                    .openAlertDialog({
                        title: '成功購買商品',
                        description: `跳轉至訂單記錄`,
                        closeCallback: () => this
                            .props
                            .webActions
                            .cupoyStateGo(CupoyRouter.STATES.MEMBER_CARD_ORDER_LIST_FINISH)
                    });
            })
            .catch((error) => {
                // console.log(this.props.webActions);

                let errorMessage = error.message;

                if (error && error.code) {

                    switch (error.code) {
                        case 'error.nonnAccountNotValidatedError':
                            errorMessage = '索取權限驗證失敗';
                            break;
                        case 'error.NonnAccountNotAllowError':
                            errorMessage = '會員停權中';
                            break;
                        default:
                            break;
                    }
                }

                this
                    .props
                    .webActions
                    .openAlertDialog({
                        title: '發生錯誤',
                        description: errorMessage,
                        closeCallback: () => this
                            .props
                            .webActions
                            .cupoyStateGo(CupoyRouter.STATES.CARD_POINTCARD)
                    });
            });
    }

    buyBonus(event, payload) {
        this.props.webActions.openBuyBonusPdtDialog(
            payload.productid,
            payload.jsdproductid
        );
    }

    // ------------------------------ render ------------------------------
    renderContent = () => {

        let {
            cardProductMap,
            haslogin,
            BONUSMALL,
        } = this.props;

        let {
            productid,
            quantity,
            isFree,
            isBonus,
        } = this.state;
        
        const product = cardProductMap[productid];

        const buyFreeFunc = isFree
            ? this.buyFree
            : isBonus
                ? this.buyBonus
                : undefined;

        let {
            canbuy,
            islimited,
            thumbimg,
            marketprice,
            memberprice,
            producturl,
            urlname,
            productinfo,
            productname,
            jsdproductid,
            agencyname,
            note_editor_type,
            markdown
        } = product;

        const history = CupoyRouter.getHistory();

        return (
            <GlobalStyle.Container hasFixedTopbar backgroundColor='#f5f5f5'>
                <TopbarView
                    isFixed
                    leftComponent={
                        history.length === 1 ?
                        <CupoySrefView
                            statename={CupoyRouter.STATES.CARD_POINTCARD}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            點卡產包
                        </CupoySrefView>
                        :
                        <GlobalStyle.TopbarButton onClick={this.props.history.goBack}>
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            回上一頁
                        </GlobalStyle.TopbarButton>
                    }
                    middleComponent={agencyname}
                />
                {
                    (isBonus && !BONUSMALL) ?
                    <Error>
                        發財商城暫停使用中
                    </Error>
                    :
                    <React.Fragment>
                        <ProductList>
                            <ProductImage
                                imageUrl={GLOBAL.imgSrcRootURL + `/goodimgs/${productid}/${thumbimg}`}
                                title={urlname}/>
                            <ProductInfo>
                                <ProductName>{productname}</ProductName>
                                {!isFree
                                    ? <div>
                                            <Price>
                                                原價:{marketprice}元
                                            </Price>
                                            <NewPrice>
                                                會員:
                                                <MemberPrice>
                                                    {memberprice}
                                                    {isBonus? '發財金':'元'}                                            
                                                </MemberPrice>
                                            </NewPrice>
                                        </div>
                                    : null
                                }
                            </ProductInfo>

                            <CupoyLink
                                href={producturl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <GameHomeLink>
                                    <i className="zmdi zmdi-home"></i>
                                </GameHomeLink>
                            </CupoyLink>

                        </ProductList>

                        {!isFree && !isBonus && this.buyQuantityList()}
                        
                        <GameContent>
                            {islimited
                                ? this.renderLimitedContent()
                                : null}
                            {productinfo ? (note_editor_type === 1 ?
                                <MarkdownContentView mdcontent={markdown} />      
                                :<CupoyNoteContent html={productinfo} />
                            ) : null}
                        </GameContent>
                        <StepBottomBar.StepBarWrapper>
                            {!isFree
                                ? <StepBottomBar.StepBarText>
                                        總價：
                                        <StepBottomBar.StepBarPrice>
                                            {memberprice * quantity}
                                        </StepBottomBar.StepBarPrice>
                                    </StepBottomBar.StepBarText>
                                : <div></div>
                            }
                            <StepBottomWrapper>
                                <StepBottomBar.StepBarButton
                                    disable={(islimited && canbuy <= 0)}
                                    onClick={!isFree && !isBonus
                                    ? (event) => this.addCart(event, memberprice * quantity)
                                    : (event) => buyFreeFunc(event, {
                                        productid,
                                        count: 1,
                                        jsdproductid
                                    })}>
                                    
                                    {isFree
                                        ? '立即領取'
                                        : isBonus
                                            ? '立即兌換'
                                            : '加入購物車'
                                    }
                                </StepBottomBar.StepBarButton>
                            </StepBottomWrapper>
                        </StepBottomBar.StepBarWrapper>
                    </React.Fragment>
                }
            </GlobalStyle.Container>
        );
    }

    renderError = () => {

        const {
            errorMessage,
        } = this.state;

        const history = CupoyRouter.getHistory();
        
        return (
            <GlobalStyle.Container hasFixedTopbar backgroundColor='#f5f5f5'>
                <TopbarView
                    isFixed
                    leftComponent={
                        history.length === 1 ?
                        <CupoySrefView
                            statename={CupoyRouter.STATES.CARD_POINTCARD}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            點卡產包
                        </CupoySrefView>
                        :
                        <GlobalStyle.TopbarButton onClick={this.props.history.goBack}>
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            回上一頁
                        </GlobalStyle.TopbarButton>
                    }
                    middleComponent='商品資訊'
                />

                <GlobalStyle.ErrorContainer>
                    <GlobalStyle.ErrorContainerTitle>
                        發生錯誤
                    </GlobalStyle.ErrorContainerTitle>
                    <GlobalStyle.ErrorContainerDesription>
                        {errorMessage}
                    </GlobalStyle.ErrorContainerDesription>
                </GlobalStyle.ErrorContainer>

            </GlobalStyle.Container>
        );
    }

    renderLoading = () => {

        const history = CupoyRouter.getHistory();
        
        return (
            <GlobalStyle.Container hasFixedTopbar backgroundColor='#f5f5f5'>
                <TopbarView
                    isFixed
                    leftComponent={
                        history.length === 1 ?
                        <CupoySrefView
                            statename={CupoyRouter.STATES.CARD_POINTCARD}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            點卡產包
                        </CupoySrefView>
                        :
                        <GlobalStyle.TopbarButton onClick={this.props.history.goBack}>
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            回上一頁
                        </GlobalStyle.TopbarButton>
                    }
                    middleComponent='商品資訊'
                />

                <GlobalStyle.LoadingContainer>
                    <GlobalStyle.Spin />
                </GlobalStyle.LoadingContainer>

            </GlobalStyle.Container>
        );
    }

    render() {

        let {
            cardProductMap,
        } = this.props;

        let {
            productid,
            isLoading,
            isError,
        } = this.state;

        if (isLoading) {
            return this.renderLoading();
        }

        if (isError) {
            return this.renderError();
        }
        
        const product = cardProductMap[productid];

        if (!product) {
            return null;
        }

        return this.renderContent();
    }
    
    buyQuantityList() {

        let {haslogin} = this.props;

        if (!haslogin) {
            return null;
        }

        const product = this.props.cardProductMap[this.state.productid];
        let {islimited} = product;
        const canbuy = islimited
            ? product.canbuy
            : 10;
        const quantity = this.state.quantity;

        return (
            <BuyQuantityList>
                <BuyTitle>購買數量</BuyTitle>
                <ChooseQuantityBox>
                    <QuantityMinus onClick={this.quantityMinus}>-</QuantityMinus>

                    <Quantity>
                        {quantity}
                        <QuantityUpdateModal onChange={(event) => this.quantityUpdate(event)}>
                            {this.renderOption(canbuy)}
                        </QuantityUpdateModal>
                    </Quantity>
                    <QuantityPlus onClick={this.quantityPlus}>+</QuantityPlus>
                </ChooseQuantityBox>
            </BuyQuantityList>
        )

    }

    renderOption(number) {
        const optionContent = [];
        for (let i = 0; i <= number; i++) {
            optionContent.push(
                <option key={i} value={i}>
                    {i}
                </option>
            )
        }

        return optionContent;
    }

    renderLimitedContent() {

        const product = this.props.cardProductMap[this.state.productid];

        let {
            buyterm,
            buycnt,
            lastbuytime,
            canbuy,
            buytermval,
        } = product;

        let {
            haslogin,
        } = this.props;

        let str;

        if (buyterm === 1) {
            str = `本商品為限定商品，1個月內，購買數量限制為 :  ${buycnt} <br />${lastbuytime > 0 ? `上次購買時間 :  ${moment(lastbuytime).format('YYYY-MM-DD')} <br />` : ''}您目前可購買剩餘數量 : ${haslogin
                ? `<span style="color:#FA0300;">${canbuy}</span>`
                : '<span style="color:#FA0300;text-decoration:underline;">您尚未登入</span>'} `;
        }
        if (buyterm === 2) {
            str = `本商品為限定商品，1天內購買數量限制為 :  ${buycnt} <br />${lastbuytime > 0 ? `上次購買時間 :  ${moment(lastbuytime).format('YYYY-MM-DD')} <br />` : ''}您目前可購買剩餘數量 : ${haslogin
                ? `<span style="color:#FA0300;">${canbuy}</span>`
                : '<span style="color:#FA0300;text-decoration:underline;">您尚未登入</span>'} `;
        }
        if (buyterm === 3) {
            str = `本商品為限定商品，7天內購買數量限制為 :  ${buycnt} <br />${lastbuytime > 0 ? `上次購買時間 :  ${moment(lastbuytime).format('YYYY-MM-DD')} <br />` : ''}您目前可購買剩餘數量 : ${haslogin
                ? `<span style="color:#FA0300;">${canbuy}</span>`
                : '<span style="color:#FA0300;text-decoration:underline;">您尚未登入</span>'} `;
        }
        if (buyterm === 4) {
            str = `本商品為限定商品， ${buytermval} 天內購買數量限制為 :  ${buycnt} <br />${lastbuytime > 0 ? `上次購買時間 :  ${moment(lastbuytime).format('YYYY-MM-DD')} <br />` : ''}您目前可購買剩餘數量 : ${haslogin
                ? `<span style="color:#FA0300;">${canbuy}</span>`
                : '<span style="color:#FA0300;text-decoration:underline;">您尚未登入</span>'} `;
        }

        return (
            <LimitedContent>
                {haslogin
                    ? <div
                            dangerouslySetInnerHTML={{
                            __html: str
                        }}></div>
                    : <a
                        onClick={() => {
                        if (!haslogin) {
                            this
                                .props
                                .webActions
                                .cupoyStateGo(CupoyRouter.STATES.MEMBERLOGIN);
                        }
                        return;
                    }}
                        dangerouslySetInnerHTML={{
                        __html: str
                    }}></a>
}

            </LimitedContent>
        )
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function (state) {
    return {
        cardProductMap: state.card.cardProductMap,
        haslogin: state.member.haslogin,
        BONUSMALL: state.web.BONUSMALL,
        memberinfo: state.member.memberinfo,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function (dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        cardActions: bindActionCreators(actionCreators.cardActionCreators, dispatch),
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
        cardCartActions: bindActionCreators(actionCreators.cardCartActionCreators, dispatch),
        cardOrderActions: bindActionCreators(actionCreators.cardOrderActionCreators, dispatch)
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {forwardRef: true})(CardInformationView);
