/*=====================================
    會員資訊頁

    Author: Eileen Liao
    createtime: 2018 / 03 / 16
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle, Colors, MemberStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import styled from 'styled-components';
import GLOBAL from 'nonnmwebglobal';
import Recaptcha from "react-grecaptcha";
import CupoyLink from 'components/commons/CupoyLink';
import NPOBAN_LIST from 'json/npobanlist.json';
import mobileUtil from 'util/mobileUtil';
import MemberIdNumberVerifier from 'components/member/commons/MemberIdNumberVerifier';
import { CancelToken } from 'axios';

/*--------------------------
    Style Component
--------------------------*/
const HotNoteDescription = styled.div`
    font-size: 14px;
    color: ${Colors.Orange};
`;
const NoteDescription =  styled.div`
    display:flex;
    margin : ${props => props.margin ? props.margin : '10px 0 0 0'};
    height : 34px;
    width : 100%;
    font-size: '12px';
    color: #595A59;
    justify-content: left;
    align-items : center;
`;
const RegionTitle = styled.div`
    align-items: center;
    margin : ${props => props.margin ? props.margin : '16px 0 0 0'};;
    font-size: 20px;
    color: #333333;
`;
const RegionTitleNotice = styled.span`
    margin-left: 10px;
    color: ${Colors.Main};
    font-size: 15px;
`;
const LineDelimeter = styled.div`
    margin : 16px 0 0 0;
    border-top : 1px solid #DDDDDD;
    width : 100%;
    height : 1px;
`;

const FieldTitle = styled.div`
    margin : 16px 0 0 0;
    font-size: 12px;
    line-height: 20px;
    color : #232423;
    display: flex;
    align-items: center;

    > span {
        display: inline-flex;
        align-items: center;
        color: ${Colors.Red};
        margin-left: 4px;
    }
`;
const CheckBox = styled.div`
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width : 18px;
    height : 18px;
    line-height: 18px;
    border-radius: 0 3px 0 3px;  
    background-color: ${Colors.Orange};
    text-align: center;
    color: #fff;
    font-size: 16px;
`;
const CheckRetangle = styled.div`
    position: relative;
    height: 44px;
    line-height: 42px;
    border-radius: 3px;
    border : 1px solid ${(props) => props.isCheck ? Colors.Orange : '#CCC'};
    flex: 1;
    text-align: center;
    background-color: #FFFFFF;
    font-size: 14px;
    color: ${(props) => props.isCheck ? Colors.Orange : '#6A6A6A'};
    letter-spacing: 0;
    text-align: center;
    cursor : pointer;

    &:first-child {
        margin: 0 10px 0 0;
    }

    ${CheckBox} {
        ${(props) => {
            if(props.isCheck) {
                return `display: block`;
            }
        }}
    }
`

const TermsTitle = styled.div`
    line-height: 20px;
    font-size: 14px;
    color: #565151;
    font-weight: 600;
    opacity: 0.9;
`;
const TermsText = styled.div`
    line-height: 20px;
    margin: ${(props) => props.noMargin ? '0' : '0 0 12px 0'};
    font-size: 12px;
    color: #232423;
    opacity: 0.9;
`;
const SelectWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 44px;
    margin: 0 0 0 12px;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    background-color: #FAFAFA;

    ${(props) => {
        if(props.error) {
            return `
                border: 1px solid ${Colors.Red};
            `;
        }
    }}

    &:first-child {
        margin: 0;
    }
`;
const Select = styled.select`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 8px;
    border: none;
    background-color: #FAFAFA;
    font-size: 15px;
    color: #222328;
`;
const SubmitLoading = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    left: 16px;
    height: 100%;
    align-items: center;
    justify-content: center; 
`;
const InvoiceNoitceLink = styled.span`
    margin-left: 5px;
    color: ${Colors.Main};
    font-size: 12px;
`;
/*--------------------------
    Var
--------------------------*/
const monthopt = [                     
    {label:"01",value:"01"},  
    {label:"02",value:"02"},  
    {label:"03",value:"03"},  
    {label:"04",value:"04"},  
    {label:"05",value:"05"},  
    {label:"06",value:"06"},  
    {label:"07",value:"07"},  
    {label:"08",value:"08"},  
    {label:"09",value:"09"},  
    {label:"10",value:"10"},
    {label:"11",value:"11"},
    {label:"12",value:"12"},
];
const dayopt = [
    {label:"01",value:"01"},
    {label:"02",value:"02"},
    {label:"03",value:"03"},
    {label:"04",value:"04"},
    {label:"05",value:"05"},
    {label:"06",value:"06"},
    {label:"07",value:"07"},
    {label:"08",value:"08"},
    {label:"09",value:"09"},
    {label:"10",value:"10"},
    {label:"11",value:"11"},
    {label:"12",value:"12"},
    {label:"13",value:"13"},
    {label:"14",value:"14"},
    {label:"15",value:"15"},
    {label:"16",value:"16"},
    {label:"17",value:"17"},
    {label:"18",value:"18"},
    {label:"19",value:"19"},
    {label:"20",value:"20"},
    {label:"21",value:"21"},
    {label:"22",value:"22"},
    {label:"23",value:"23"},
    {label:"24",value:"24"},
    {label:"25",value:"25"},
    {label:"26",value:"26"},
    {label:"27",value:"27"},
    {label:"28",value:"28"},
    {label:"29",value:"29"},
    {label:"30",value:"30"},
    {label:"31",value:"31"},
   ];

/*--------------------------
    Main Component
--------------------------*/
class MemberRegisterView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            memberid :'',
            name : '',
            nickname : '',
            birthday_yy: '',
            birthday_mm: '',
            birthday_dd: '',
            city : '',
            region : '',
            postalcode : '',
            address : '', 
            isdonate : true,
            shownametype : 1,
            password : '',
            chkpwd : '',
            mobilephone : '',
            smsauth: '',
            email : '',
            idnumber : '',
            npoban: '8585',
            carriertype: GLOBAL.CARRIER_TYPE.NONE,
            carrierid: '',
            isFinishVerifySms: false,
            isShowSMSverifyZone:false,
            isShowChkPhoneBtn : true,
            isShowSendSMSBtn : false,
            isFinishCheckEmail: false,
            isLoading: false,
            step: 1,
            isTerms: false,
            isPrivacy: false,
            errorMap: {},
            recaptchaResponse: '',

            isChkingMemberID: false,
            isChkMemberIDRepeat: false, // 會員ID重複
            isChkMemberIDError: false, // 驗證會員ID規則不通過

            isChkingCarrierId: false,
            isChkCarrierIdError: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.changeShowtype = this.changeShowtype.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.handleMobilePhoneChange = this.handleMobilePhoneChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.registerMember = this.registerMember.bind(this);
        this.changeDonat = this.changeDonat.bind(this);
        this.checkMobilePhone = this.checkMobilePhone.bind(this);
        this.sendSMSCode = this.sendSMSCode.bind(this);
        this.sendVerifycode = this.sendVerifycode.bind(this);
        this.chkEMail = this.chkEMail.bind(this);
        this.changeIsTerms = this.changeIsTerms.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.changeDonateToTrue = this.changeDonate.bind(this, true);
        this.changeDonateToFalse = this.changeDonate.bind(this, false);
        this.changeCarriertypeToMember = this.changeCarriertype.bind(this, GLOBAL.CARRIER_TYPE.MEMBER);
        this.changeCarriertypeToCommon = this.changeCarriertype.bind(this, GLOBAL.CARRIER_TYPE.COMMONS);
        this.checkMemberID = this.checkMemberID.bind(this);
        this.checkCarrierId = this.checkCarrierId.bind(this);
        this.handleIdNumber = this.handleIdNumber.bind(this);

        this.chkMemberIDController = null;
        this.chkCarrierIdController = null;
    }

    changeCarriertype = type => {

        if (this.chkCarrierIdController) {
            this.chkCarrierIdController.cancel();
        }

        this.setState({
            carriertype: type,
            carrierid: '',
            isChkingCarrierId: false,
            isChkCarrierIdError: false,
        });
    }

    changeDonate = isdonate => {

        if (isdonate === this.state.isdonate) {
            return;
        }

        if (this.chkCarrierIdController) {
            this.chkCarrierIdController.cancel();
        }

        this.setState({
            isdonate,
            carriertype: !isdonate ? GLOBAL.CARRIER_TYPE.MEMBER : GLOBAL.CARRIER_TYPE.NONE,
            npoban: isdonate ? '8585' : '',
            carrierid: '',
            isChkingCarrierId: false,
            isChkCarrierIdError: false,
        });
    }

    // ------------------------------
    // handleChange
    // ------------------------------
    handleChange(event, key) {

        const value = event.target.value;
        
        if(!key) {
            return;
        }

        if (this.state[key] === value) {
            return;
        }

        this.setState({
            [key]: value,
        });
    }
    
    // ------------------------------
    // changeShowtype
    // ------------------------------
    changeShowtype(shownametype) {
        
        this.setState({
            shownametype: shownametype,
        });
    }

    // ------------------------------
    // handleCityChange
    // ------------------------------
    handleCityChange(event) {
        
        this.setState({
            city: event.target.value
        });
    }

    // ------------------------------
    // handleRegionChange
    // ------------------------------
    handleRegionChange(event) {

        const value = event.target.value;
        const regions = GLOBAL.REGIONS[this.state.city];

        if(!regions) {
            return;
        }

        for(var i = 0; i < regions.length; i++) {
            var region = regions[i];

            if(region.value === value) {
                break;
            }

            region = undefined;
        }

        if(!region) {
            return;
        }

        this.setState({
            postalcode : region.zipcode,
            region: region.value
        });

        this.addressInput.focus();
    }

    // ------------------------------
    // handleMobilePhoneChange
    // ------------------------------
    handleMobilePhoneChange(event) {

        const mobilephone = event.target.value;

        if(mobilephone === this.state.mobilephone) {
            return;
        }

        if(!GLOBAL.chkMobile(mobilephone)){
            this.setState({
                mobilephone: mobilephone,
                smsauth: '',
                isChkingMobilePhone: true,
                isShowChkPhoneBtn: false,
                isShowSendSMSBtn: false,
                isShowSMSverifyZone: false,
                isFinishVerifySms: false,
            });
            return;
        }

        this.setState({
            mobilephone: mobilephone,
            smsauth: '',
            isChkingMobilePhone: false,
            isShowChkPhoneBtn: true,
            isShowSendSMSBtn: false,
            isShowSMSverifyZone: false,
            isFinishVerifySms: false,
        });
    }

    // ------------------------------
    // handleEmailChange
    // ------------------------------
    handleEmailChange(event) {

        const email = event.target.value; 

        if(email === this.state.email) {
            return;
        }

        this.setState({
            email: event.target.value,
            isFinishCheckEmail: false,
        });
    }
    
    // ------------------------------
    // registerMember
    // ------------------------------
    registerMember() {

        const {
            idnumber,
            memberid,
            name,
            nickname,
            password,
            chkpwd,
            mobilephone,
            smsauth,
            email,
            birthday_yy,
            birthday_mm,
            birthday_dd,
            city,
            region,
            postalcode,
            address,
            isdonate,
            npoban,
            carriertype,
            carrierid,
            shownametype,
            isFinishVerifySms,
            isFinishCheckEmail,
            isLoading,
            isChkingMemberID,
            isChkMemberIDRepeat,
            isChkMemberIDError,
            isChkingCarrierId,
            isChkCarrierIdError,
        } = this.state;

        if(
            isLoading
            || isChkingMemberID
            || isChkMemberIDRepeat
            || isChkMemberIDError
            || isChkingCarrierId
            || isChkCarrierIdError
        ) {
            return;
        }

        let message = '',
            isError =  false,
            objInput = null,
            errorMap = {};

        if(!GLOBAL.chkMemberID(memberid)){
            isError = true;
            message += "會員帳號，請輸入4-16數字、英文字\n";
            objInput = objInput || this.midInput;
            errorMap['memberid'] = true;
        }

        if(!GLOBAL.chkPassword(password)){
            isError = true;
            message += "密碼請輸入6-10數字、英文字\n";
            objInput = objInput || this.pwdInput;
            errorMap['password'] = true;
        } else if(password !== chkpwd){
            isError = true;
            message += "兩次密碼輸入不一樣，請重新輸入\n";
            objInput = objInput || this.chkpwdInput;
            errorMap['chkpwd'] = true;
        }

        if(
            GLOBAL.isEmpty(name)
            || !GLOBAL.isChinese(name)
            || !GLOBAL.checkStringLength(name, 2, 4)
        ){
            isError = true;
            message += "真實姓名限2-4個中文字\n";
            objInput = objInput || this.nameInput;
            errorMap['name'] = true;
        }

        if(
            GLOBAL.isEmpty(nickname)
            || GLOBAL.checkStringHaveSymbol(nickname)
        ) {
            isError = true;
            message += "暱稱限中文、英文、數字組成\n";
            objInput = objInput || this.nameInput;
            errorMap['nickname'] = true;
        }

        if(!isFinishVerifySms){
            isError = true;
            message += "請驗證您的手機號碼\n";
            objInput = objInput || this.phoneInput;
            errorMap['mobilephone'] = true;
            errorMap['smsauth'] = true;
        }

        if(!isFinishCheckEmail) {
            isError = true;
            message += "請驗證您的Email\n";
            objInput = objInput || this.EMailInput;
            errorMap['email'] = true;
        }

        if(
            GLOBAL.isEmpty(birthday_yy) ||
            GLOBAL.isEmpty(birthday_mm) ||
            GLOBAL.isEmpty(birthday_dd)
        ) {
            isError = true;
            message += "請選擇生日\n";
            errorMap['birthday'] = true;
        }

        if(GLOBAL.isEmpty(city) || GLOBAL.isEmpty(region) || GLOBAL.isEmpty(address) || !postalcode) {
            isError = true;
            message += "請填寫地址資訊\n";
            objInput = objInput || this.addressInput;
            errorMap['address'] = true;
        }
        
        if(!idnumber) {
            isError = true;
            message += "請驗證身分證號碼\n";
        }

        if(isdonate && (!NPOBAN_LIST[npoban] || GLOBAL.isEmpty(npoban))) {
            isError = true;
            message += "請輸入正確的捐贈碼\n";
            objInput = objInput || this.npobanInput;
            errorMap['npoban'] = true;
        }

        if(!isdonate && (carriertype === GLOBAL.CARRIER_TYPE.COMMONS) && GLOBAL.isEmpty(carrierid)) {
            isError = true;
            message += "請輸入共通性載具ID\n";
            errorMap['carrierid'] = true;
            objInput = objInput || this.carrieridInput;
        }

        if(isError){
            this.setState({
                errorMap: errorMap,
            }, () => {
                this.props.webActions.openAlertDialog({                    
                    title: "錯誤訊息",
                    description : message,
                    closeName: '確定',
                    closeCallback: () => {
                        objInput && objInput.scrollIntoView();
                    }
                });
            })
            return;
        }

        this.setState({
            errorMap: errorMap,
            isLoading: true, 
        }, () => {
            this.props.registerActions.registerMember({
                idnumber: idnumber,
                passwd: password,
                memberid: memberid,
                name: name,
                nickname: nickname,
                mobilephone: mobilephone,
                smsauth: smsauth,
                email: email,
                birthday: birthday_yy + birthday_mm + birthday_dd,
                city: city,
                region: region,
                postalcode: postalcode,
                address: address,
                shownametype: shownametype,
                isdonate,
                npoban,
                carriertype,
                carrierid,
                // 這些註冊先不給填寫，皆用預設值
                invoicetype: 1,
                crn: '',
                companytitle: '',
            }).then((result) => {
                const paramObj = {
                    id: 1572890249628,
                };

                this.props.webActions.openAlertDialog({                    
                    title: "註冊成功",
                    description : "註冊成功，並將信箱驗證信寄至您的信箱，完成信箱驗證後方可開始交易",
                    closeName: '確定',
                    closeCallback: () => {
                        this.props.webActions.cupoyStateGo(CupoyRouter.STATES.NEWSCONTENT, paramObj);
                    }
                });
            }, (error) => {
                this.props.webActions.openAlertDialog({                    
                    title: "結果訊息",
                    description : "註冊失敗，請稍候再試或洽客服人員",
                    closeName: '確定',
                });
            });
        });
    }

    // ------------------------------
    // changeDonat
    // ------------------------------
    changeDonat(isdonate) {

        this.setState({
            isdonate: isdonate,
        })
    }

    // ------------------------------
    // check 手機是否已存在,and 傳送驗證碼
    // ------------------------------
    checkMobilePhone() {

        var mobilephone  = this.state.mobilephone;

        if(!mobilephone) {
            return;
        }

        this.props.registerActions.checkPhone({
            mobilephone : mobilephone
        }).then((result)=>{
            // 手機可用
            var message = "手機已被使用，請重新填寫或洽客服人員";

            if(result){
                this.setState({
                    isShowSendSMSBtn : true,
                    isShowChkPhoneBtn: false
                });
                message = "手機可使用";
            }
            
            this.props.webActions.openAlertDialog({
                title: message,
                closeCallback: () => {
                    // this.phoneInput.focus();
                }
            });
        },(error) => {
            this.props.webActions.openAlertDialog({
                title: "手機已被使用",
                closeCallback: () => {
                    this.phoneInput.focus();
                }
            });
        }) 
    }

    // ------------------------------
    // 傳送手機驗證碼
    // ------------------------------
    sendSMSCode() {

        const { recaptchaResponse } = this.state;

        if(!GLOBAL.isAppFile && !recaptchaResponse) {
            this.props.webActions.openAlertDialog({
                title: '錯誤訊息',
                description : '請勾選『我不是機器人』',
            });
            return;
        }

        this.props.registerActions.sendSmsAuthCode({mobilephone : this.state.mobilephone}).then(() => {
            this.setState({
                isShowSendSMSBtn: true,
                isShowChkPhoneBtn: false,
                isShowSMSverifyZone: true,
                isFinishVerifySms: false,
            }, () => {
                !GLOBAL.isAppFile && window.grecaptcha.reset();
                this.props.webActions.openAlertDialog({
                    title: "驗證碼傳送成功",
                });
            });
        }, () => {
            !GLOBAL.isAppFile && window.grecaptcha.reset();
            this.props.webActions.openAlertDialog({
                title: "系統忙碌中，請稍候再試",
            });       
        });
    }

    // ------------------------------
    // 驗證註冊簡訊
    // ------------------------------
    sendVerifycode() {

        const {
            mobilephone,
            smsauth,
            isFinishVerifySms,
        } = this.state;

        if(isFinishVerifySms || !mobilephone || !smsauth) {
            return;
        }

        this.props.registerActions.verifyRegisterSms({
            mobilephone: mobilephone,
            smsauth: smsauth,
        }).then((result) => {

            const errorMap = Object.assign({}, this.state.errorMap, {
                mobilephone: false,
                smsauth: false,
            })

            this.setState({
                isFinishVerifySms: true,
                errorMap: errorMap,
            });
            this.props.webActions.openAlertDialog({
                title: "驗證碼驗證成功",
            });       
        },(error) => {
            this.props.webActions.openAlertDialog({
                title: '發生錯誤',
                description: (error && error.message) || '發生無法預期的錯誤，請重新整理稍後再試，或聯絡我們的客服人員',
            });       
        });
    }

    // ------------------------------
    // chkEMail
    // ------------------------------
    chkEMail() {

        var email  = this.state.email;

        if(!email) {
            return;
        }

        if(!GLOBAL.isEmail(email)){
            this.setState({
                isFinishCheckEmail: false,
            })

            this.props.webActions.openAlertDialog({
                title: "電子信箱輸入錯誤",
                closeCallback: () => {
                    this.EMailInput.focus();
                } 
            });
            return;
        }

        this.props.registerActions.checkEmail({email : email}).then((result) => {
            if(result){
                const errorMap = Object.assign({}, this.state.errorMap, {
                    email: false,
                })

                this.setState({
                    isFinishCheckEmail: true,
                    errorMap: errorMap,
                });
                this.props.webActions.openAlertDialog({
                    title: "EMail可使用",
                });   
            }
            else{
                this.setState({
                    isFinishCheckEmail: false,
                })
                this.props.webActions.openAlertDialog({
                    title: "EMail已被註冊",
                    closeCallback: () => {
                        this.EMailInput.focus();
                    }
                });   
            }
        },(error) =>{
            this.setState({
                isFinishCheckEmail: false,
            })
            this.props.webActions.openAlertDialog({
                title: "EMail已被註冊",
                closeCallback: () => {
                    this.EMailInput.focus();
                } 
            });   
        });
    }

    // ------------------------------
    // changeIsTerms
    // ------------------------------
    changeIsTerms(isTerms) {

        if(this.state.isTerms === isTerms) {
            return;
        }

        this.setState({
            isTerms: isTerms,
        })
    }

    // ------------------------------
    // nextStep
    // ------------------------------
    nextStep() {

        const {
            step,
            isTerms,
            isPrivacy,
        } = this.state;

        if((step === 1 && !isTerms) || (step === 2 && !isPrivacy))
            return;

        this.setState(prevState => ({
            step: prevState.step + 1,
        }), () => {
            window.scrollTo({ top: 0 });
        });
    }

    changeIsPrivacy = boolean => {
        this.setState({
            isPrivacy: boolean,
        });
    }

    checkCarrierId() {

        const {
            carrierid,
        } = this.state;
        
        const _carrierid = carrierid.trim();

        if (this.chkCarrierIdController) {
            this.chkCarrierIdController.cancel();
        }

        // 沒有id，直接顯示錯誤
        if (!_carrierid) {
            this.setState({
                isChkingCarrierId: false,
                isChkCarrierIdError: true,
            });
            return;
        }

        // 格式是合法的自然人憑證id，pass
        if (GLOBAL.isDigitalCitizenCarrierID(_carrierid)) {
            this.setState({
                isChkingCarrierId: false,
                isChkCarrierIdError: false,
            });
            return;
        }

        // 格式不是合法的手機條碼，直接不檢查，顯示錯誤
        if (!GLOBAL.isMobilePhoneCarrierID(_carrierid)) {
            this.setState({
                isChkingCarrierId: false,
                isChkCarrierIdError: true,
            });
            return;
        }

        this.chkCarrierIdController = CancelToken.source();
        const { token: cancelToken } = this.chkCarrierIdController;

        this.setState({
            isChkingCarrierId: true,
            isChkCarrierIdError: false,
        }, () => {
            this.props.webActions.checkEinvoiceMobileCarrier({
                carrierid: _carrierid,
                cancelToken,
            }).then(() => {
                this.setState({
                    isChkingCarrierId: false,
                });
            }).catch(() => {
                this.setState({
                    isChkingCarrierId: false,
                    isChkCarrierIdError: true,
                });
            });
        });
    }

    handleCarrieridChange = event => {
        this.setState({
            carrierid: event.target.value,
        }, this.checkCarrierId);
    }

    // ------------------------------
    // renderTerms
    // ------------------------------
    renderTerms() {

        const {
            isTerms,
        } = this.state;

        return (
            <GlobalStyle.Container hasFixedTopbar hasFixedTabbar={false}>
                <TopbarView
                    isFixed
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            返回
                        </CupoySrefView>
                    }
                    middleComponent="會員條款"
                />
                <MemberStyle.MemberContent>
                    <TermsText>歡迎來到『9199 交易久久』！為了保障您的權益，在註冊前請先詳細閱讀本同意書之所有內容，當您在點選「同意」後，即視為您已閱讀本同意書，並同意遵守以下所有同意書之規範。若您不同意以下所述內容，請暫時不要參與我們網站上的活動。</TermsText>
                    <TermsTitle>1.遵守會員規範</TermsTitle>
                    <TermsText>在您於『9199 交易久久』 註冊成為會員後，可以使用『9199 交易久久』 所提供之各種服務。當您使用『9199 交易久久』 時，即表示同意接受『9199 交易久久』 之會員規範及所有注意事項之約束。</TermsText>
                    <TermsTitle>2.使用服務</TermsTitle>
                    <TermsText>基於『9199 交易久久』 所提供之各項服務，您同意於註冊時提供完整詳實且符合真實之個人資料，您所登錄之資料事後若有變更時，應隨時更新。</TermsText>
                    <TermsTitle>3.個人資料之保護</TermsTitle>
                    <TermsText>
                        對於會員所登錄或留存之個人資料，『9199 交易久久』 在未獲得會員同意以前，決不對外揭露會員之姓名、地址、電子郵件地址及其他依法受保護之個人資料。下述幾種特殊情況不受此限：<br/>
                        (1)基於法律之規定<br/>
                        (2)受司法機關或其他有權機關基於法定程序之要求<br/>
                        (3)為保障『9199 交易久久』 之財產及權益<br/>
                        (4)在緊急情況下為維護其他會員或第三人之人身安全<br/>
                    </TermsText>
                    <TermsTitle>4.個人資料之運用</TermsTitle>
                    <TermsText>對於會員所登錄或留存之個人資料，『9199 交易久久』 得於合理之範圍內運用該等資料，以提供使用者其他資訊或服務，或作成會員統計資料以進行關於網路行為之調查或研究。未經使用者主動註冊所產生的資料，『9199 交易久久』 僅對使用者行為進行總和行為之分析，不會對個別使用者進行分析。</TermsText>
                    <TermsTitle>5.個人資料之填寫</TermsTitle>
                    <TermsText>您提供之個人資料若有填寫不實或原登錄之資料不實而未更新，或是有任何誤導之嫌，『9199 交易久久』 保留隨時終止您會員資格及使用『9199 交易久久』 之權利。</TermsText>
                    <TermsTitle>6.會員的義務與責任</TermsTitle>
                    <TermsText>
                        會員對本身於『9199 交易久久』或透過『9199 交易久久』傳輸的一切內容自負全責。<br/>
                        (1)會員承諾遵守中華民國相關法規及一切國際網際網路規定與慣例。<br/>
                        (2)會員同意並保證不公布或傳送任何毀謗、不實、威脅、不雅、猥褻、不法、攻擊性、毀謗性或侵害他人智慧財產權的文字，圖片或任何形式的檔案於『9199 交易久久』。<br/>
                        (3)會員同意不會於『9199 交易久久』上從事廣告或販賣商品行為。<br/>
                        (4)會員同意避免在公眾討論區討論私人事務，發表文章時，請尊重他人的權益及隱私權。<br/>
                        (5)會員同意必須充份尊重著作權，禁止發表侵害他人各項智慧財產權之文字、圖片或任何形式的檔案。<br/>
                        (6)會員同意9199可隨時因應交易安全與預防詐騙，暫時停止會員權限並進行交易抽查與實名驗證，會員同意提供身分證、健保卡、銀行存摺等個人證件供實名驗證。
                    </TermsText>
                    <TermsTitle>7.禁止從事違反法律規定之行為</TermsTitle>
                    <TermsText>『9199 交易久久』 就會員的行為是否符合會員規範有最終決定權。若『9199 交易久久』決定會員的行為違反本會員規範或任何法令，會員同意『9199 交易久久』得隨時停止帳號使用權或清除帳號，並停止使用『9199 交易久久』。會員在違反法律規定之情事，應自負法律責任。</TermsText>
                    <TermsTitle>8.服務之停止與更改</TermsTitle>
                    <TermsText>
                        (1)於發生下列情形之一時，『9199 交易久久』 有權停止或中斷提供服務：<br/>
                        * 對『9199 交易久久』 之設備進行必要之保養及施工時；<br/>
                        * 發生突發性之設備故障時：<br/>
                        * 由於『9199 交易久久』 所申請之ISP業者無法提供服務時；<br/>
                        * 因天災等不可抗力之因素致使『9199 交易久久』無法提供服務時。<br/>
                        (2)『9199 交易久久』 可能因公司或ISP業者網路系統軟硬體設備之故障、失靈或人為操作上之疏失而造成全部或部份中斷、暫時無法使用、延遲或造成資料傳輸或儲存上之錯誤、或遭第三人侵入系統篡改或偽造變造資料等，會員不得因此而要求任何補償。
                    </TermsText>
                    <TermsTitle>9.保管及通知義務</TermsTitle>
                    <TermsText>您有責任維持密碼及帳號的機密安全。您必須完全負起因利用該密碼及帳號所進行之一切行動之責任。當密碼或帳號遭到未經授權之使用，或發生其他任何安全問題時，您必須立即通知『9199 交易久久』 ，每次您連線完畢，均要結束您的帳號使用。因您未遵守本項約定所生之任何損失或損害，我們將無法亦不予負責。</TermsText>
                    <TermsTitle>10.特別同意事項</TermsTitle>
                    <TermsText>您同意於『9199 交易久久』 所發表之一切內容僅代表您個人之立場與行為，並同意承擔所有相關衍生之責任，『9199 交易久久』 不負任何責任。</TermsText>
                    <TermsTitle>11.擔保責任免除</TermsTitle>
                    <TermsText>
                        (1)『9199 交易久久』 保留隨時停止、更改各項服務內容或終止任一會員帳號使用之權利，無需事先通知會員本人。無論任何情形，就停止或更改服務或終止會員帳號使用所可能產生之困擾、不便或損害，『9199 交易久久』 對任何會員或第三人均不負任何責任。<br/>
                        (2)『9199 交易久久』 保留將來新增、修改或刪除各項服務之全部或一部之權利，且不另行個別通知，會員不得因此而要求任何補償或賠償。<br/>
                        (3)對於透過『9199 交易久久』 銷售之商品，非由『9199 交易久久』 本身提供者，『9199 交易久久』 對其交易過程及商品本身均不負任何擔保責任。會員透過『9199 交易久久』 所購得之商品或服務，完全由供應商負全責，若有任何瑕疵或擔保責任，均與『9199 交易久久』 無關。<br/>
                    </TermsText>
                    <TermsTitle>12.廣告或促銷行為</TermsTitle>
                    <TermsText>『9199 交易久久』上有關商業廣告及各種商品之促銷資訊,該等內容均係由廣告商或商品服務提供人所為，『9199 交易久久』僅係提供刊登內容之媒介。會員透過『9199 交易久久』上所提供之商品、服務資訊，所購買之任何商品或服務，其間交易關係均存在於會員與商品或服務提供人間，與『9199 交易久久』無關。</TermsText>
                    <TermsTitle>13.智慧財產權</TermsTitle>
                    <TermsText>『9199 交易久久』 刊出之所有著作及資料（例如文章、圖片等）內容，其著作權、專利權、商標權、營業秘密及其他智慧財產權，皆為『淞果數位股份有限公司』或該內容之提供者所有，且受中華民國著作權法令及國際著作權法律的保障。『9199 交易久久』畫面資料之選擇、編排之版權為『淞果數位股份有限公司』所有，且受中華民國著作權法令及國際著作權法律的保障。非經本公司書面授權同意，不得以任何形式轉載、傳輸、傳播、散布、展示、出版、再製或利用『9199 交易久久』 內容的局部、全部的內容，以免觸犯相關法律規定。未經本公司書面同意，您不得擅自複製、進行還原工程（reverse engineering）、解編（de-compile）或反向組譯（disassemble）『9199 交易久久』之任何功能或程式。</TermsText>
                    <TermsTitle>14.連結</TermsTitle>
                    <TermsText>『9199 交易久久』 在網站或相關網頁上所提供之所有連結，可能連結到其他個人、公司或組織之網站，提供該連結之目的，僅為便利站友搜集或取得資訊，『9199 交易久久』 對於被連結之該等個人、公司或組織之網站上所提供之產品、服務或資訊，既不擔保其真實性、完整性、即時性或可信度，該等個人、公司或組織亦不因此而當然與『9199 交易久久』 有任何僱佣、委任、代理、合夥或其他類似之關係。</TermsText>
                    <TermsTitle>15.損害賠償</TermsTitle>
                    <TermsText>因會員違反相關法令或違背本同意書之任一條款，致『9199 交易久久』 或其關係企業、受僱人、受託人、代理人及其他相關履行輔助人因而受有損害或支出費用（包括且不限於因進行民事、刑事及行政程序所支出之律師費用）時，會員應負擔損害賠償責任或填補其費用。</TermsText>
                    <TermsTitle>16.會員規範之修改</TermsTitle>
                    <TermsText>『9199 交易久久』保留隨時修改本會員規範之權利，『9199 交易久久』將於修改會員規範時，於網站首頁公告修改之內容，而不另對會員作個別通知。若會員不同意修改的內容，請勿繼續使用『9199 交易久久』。如果會員繼續使用『9199 交易久久』，將視為會員已同意並接受本規範等增訂或修改內容之約束。</TermsText>
                    <TermsTitle>17.個別條款之效力</TermsTitle>
                    <TermsText>本同意書所定之任何會員條款之全部或一部無效時，不影響其他條款之效力。</TermsText>
                    <TermsTitle>18. 準據法及管轄法院</TermsTitle>
                    <TermsText noMargin>本同意書之解釋及適用以及會員因使用本服務而與『9199 交易久久』 間所生之權利義務關係，應依中華民國法令解釋適用之。其因此所生之爭議，以台灣台北地方法院為第一審管轄法院。</TermsText>

                    <MemberStyle.InputContainer margin="32px 0 0 0" flexdirection="row" justify="space-between">
                        <CheckRetangle isCheck={isTerms} onClick={() => this.changeIsTerms(true)}>
                            <CheckBox><i className="zmdi zmdi-check"></i></CheckBox>
                            接受
                        </CheckRetangle>
                        <CheckRetangle isCheck={!isTerms} onClick={() => this.changeIsTerms(false)}>
                            <CheckBox><i className="zmdi zmdi-check"></i></CheckBox>
                            不接受
                        </CheckRetangle>
                    </MemberStyle.InputContainer>
                    <MemberStyle.ConfirmBtn
                        margin="32px 0 0 0"
                        onClick={this.nextStep}
                        disable={!isTerms}
                    >
                        下一步
                    </MemberStyle.ConfirmBtn>
                </MemberStyle.MemberContent>
            </GlobalStyle.Container>
        )
    }

    renderPrivacyPolicy = () => {

        const { isPrivacy } = this.state;

        return (
            <GlobalStyle.Container hasFixedTopbar hasFixedTabbar={false}>
                <TopbarView
                    isFixed
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            返回
                        </CupoySrefView>
                    }
                    middleComponent="隱私權聲明"
                />
                <MemberStyle.MemberContent>
                    <TermsTitle>9199隱私權聲明</TermsTitle>
                    <TermsText>
                        本隱私權政策聲明適用于『淞果數位股份有限公司』 於 www.9199.com.tw 網站域名及次級網域名所提供的服務。
                        在使用本網站時，你同意受本隱私權政策條款及條件所拘束，如果你不同意，請不要使用或取得我們所提供的服務
                        。 如下所述，你個人辨認資料有些部分會被揭露予第三人並且被我們及第三人使用。
                        在註冊時透過接受隱私權政策及會員合約，你明示同意我們使用及揭露你個人辨認資料。
                        這項隱私權政策被並入並且符合會員合約之條款。
                    </TermsText>
                    <TermsTitle>我們搜集的資訊</TermsTitle>
                    <TermsText>
                        1. 我們搜集個人資料之主要目的，在於為你提供順利的、有效率的及特定選項服務的體驗，這樣可以讓我們提供最符合你需要的服務及功能，並且規劃訂制我們的服務而使你的體驗更順利。<br/>
                        2. 通常，你可以瀏覽我們的網站而不用告訴我們你是誰或揭露任何個人的資料，然而一但你提供個人資料給我們，對我們而言你就不再是匿名人士。如果你選擇使用我們的服務，我們可能要求你提供聯絡及辨認資料、付款資料、裝運資料及我們網站的各類表格所要求提供的其他個人資料。我們會盡可能指出那些資料是必須提供的及那些資料是可依你選擇而提供的。你可以選擇不使用某項服務或功能而不必提供有關資訊，在某些情況下，例如，但不限於，你正在申請註冊使用一個免費電子郵件帳戶的服務，或申請註冊以便在我們的網站上進行買賣，我們便會要求進一步的財務資料。我們使用你的財務資料，包括你的銀行帳務資料來驗證你的姓名、地址及其它資料，同時向你收取使用服務費。<br/>
                        3. 我們會依據你在我們網站上的行為自動追蹤一些資料，我們使用這些資料對我們的會員人數統計、興趣及行為模式進行內部研究，以便更瞭解我們的會員，並且為你及我們的社群提供更好的服務。這些資料可能包括你剛來自於那個URL(不論這個URL是否在我們的網站上)，你接下來前往的URL(不論這個URL是否在我們的網站上)，目前使用何種瀏覽器，以及你的IP地址。<br/>
                        4. 我們在部份網頁使用像『cookies』的資料搜集裝置來幫助我們分析網頁的流動狀況、衡量促銷活動的效果，並增進信任度及安全性。『cookies』是一種儲存在你硬碟的小檔案，可以幫助我們提供特定選項的服務，我們提供只有經由使用『cookies』才能提供之一些功能，我們也使用『cookies』以允許你在某一階段內減少輸入密碼的次數，『cookies』也可以幫助我們提供符合你興趣的資料。大部分的cookies 是階段型『cookies』，意指他們會在階段終了後從你的硬碟中被自動刪除。如果你的瀏覽器允許，你永遠有權拒絕我們的『cookies』，雖然在那種情況下，你可能無法使用我們網站上一些特定功能，並且在某一階段內必須更頻繁的重新輸入密碼。<br/>
                        5. 此外，你可能會碰到由第三人在我們網站上某些網頁中存放的『cookies』或其他類似裝置，例如，如果你瀏覽一個由會員自行製作的網頁，該網頁可能就放有『cookies』，我們並不控制第三人對『cookies』的使用。<br/>
                        6. 如果你選擇在我們的網站上購買或出售物品行為，我們會搜集你的購買及出售物品行為資料，同時在我們的交易紀錄區內也會搜集相關訂單留言資料。<br/>
                        7. 如果你與我們開立一個虛擬信用帳戶以支付我們的服務費用，我們會搜集進一步的資料，包括付款位址、銀行帳戶號碼，並且根據支票或匯票追蹤一些資料。<br/>
                        8. 想知道我們向你搜集或所搜集到與你有關的詳細資料表以及誰有權取得該等資料，請參閱下列條文說明。
                    </TermsText>
                    <TermsTitle>我們對你個人資料的使用</TermsTitle>
                    <TermsText>
                        1. 我們使用你的資料以幫助我們提供你所要求的服務，我們使用為你建立之資料夾中的資料及我們從你現在或過去在網站上的行為所取得之其他資料，來解決爭議、檢修障礙問題及執行我們的會員合約。偶而，我們可能檢視多個會員以確認問題所在或解決爭議，且特別是我們可能對你的資料進行檢查以識別那些使用多個會員帳號或化名之會員。<br/>
                        2. 你同意我們使用你的個人辨認資料來加強我們的市場行銷及促銷、統計分析網站利用方式、加強我們提供的內容及産品，並且根據客戶的需求規劃訂制我們網站的內容、網頁設計及服務，這些使用改善並且調整我們的網站使其更符合你的需求。<br/>
                        3. 你同意我們可以使用你的資料與你聯絡並傳送那些可能符合你興趣的訊息給你，例如特定標題廣告、行政通知、産品內容及有關你使用網站的訊息。透過接受會員合約及隱私權政策，你明示同意收取此項訊息。
                    </TermsText>
                    <TermsTitle>我們對你個人資料的揭露</TermsTitle>
                    <TermsText>
                        1. 緣于現存的管制環境，我們無法保證所有你私人通訊及其它個人辨認資料永遠不會被以本隱私權政策所述以外之其他方式泄露。舉例而言(但不限以下所述)，在某些情況下我們可能被迫提供資料給政府或第三人，第三人可能違法攔截或存取傳輸或私人通訊，或會員可能濫用或誤用他們從我們的網站上所取得的你的資料。因此，雖然我們使用企業標準慣例來保護你的隱私權，我們並未承諾，而且你不應該期待你的個人辨認資料或私人通訊將會永遠保密。<br/>
                        2. 政策上，我們不會將與你有關的個人辨認資料出售或出租予第三人，然而，下列所述為部分可能揭露你個人辨別資料的情形。
                    </TermsText>
                    <TermsTitle>刊登廣告者</TermsTitle>
                    <TermsText>
                        我們搜集(從所有會員帳戶搜集資料)個人辨認資料，並且為市場行銷與宣傳廣告之目的以非可識別個人之方式揭露該資料予刊登廣告者及其它第三人，但即便在這種情形，我們也不會向這些企業體揭露任何可能被用來辨識你個人的資料。一些特定資料，例如你的姓名、電子郵件地址、密碼、手機號碼及銀行帳號，永遠不會被揭露予市場行銷廣告刊登者。如果你在我們網站上的廣告點按你的滑鼠右鍵並選擇內容，你可以看到該廣告提供者的URL。
                    </TermsText>
                    <TermsTitle>外在服務提供者</TermsTitle>
                    <TermsText>
                        有許多服務是由外在服務提供者所提供以幫助你使用我們的網站(例如：聯合品牌網站、刊登代理人、委託買賣服務、認證服務、調解服務)，如果你選擇使用這些選擇性服務、揭露資料予外在服務提供者及/或允許他們搜集有關你的資料，那麼他們使用你的資料應受他們的隱私權政策所規範。為了你的方便，我們可以提供你的部份個人辨認資料予提供此類服務之外在服務提供者，然而，在我們提供你的個人辨認資料給外在服務提供者前，你將會看到即將傳送的資料並有權決定是否同意揭露該資料，為了避免我們將你的個人辨認資料揭露給外在服務提供者，你可以拒絕揭露或選擇不使用他們的服務。因為我們無法控制這些第三者的隱私權政策，你應該在決定使用他們的服務以前先評估他們的實務慣例。
                    </TermsText>
                    <TermsTitle>內部服務提供者</TermsTitle>
                    <TermsText>
                        我們可能使用內部服務提供者來加強我們的服務(例如：搜尋、留言板、調查)，因此我們可能直接提供你的某些個人辨認資料給他們。在某些情形下，內部服務提供者將直接向你搜集資料(例如在我們要求內部服務提供者為我們進行調查的情形)。在這種情形下，你將會收到內部服務提供者涉入的通知，而你所有揭露資料的行為都是絕對出於個人自由的。在任何情況下，這些內部服務提供者使用我們提供給他們的資料都受到保密合約所限制，如果你直接提供額外的資料給內部服務提供者，則他們使用你的資料應受他們相關隱私權政策所規範。
                    </TermsText>
                    <TermsTitle>其他公司組織</TermsTitle>
                    <TermsText>
                        『淞果數位股份有限公司http://www.9199.com.tw』、其子公司、其合資企業、或任何此類的結合都有可能與其他企業組織合併或被收購，萬一發生這樣的結合，你應該可預見http://www. 9199.com.tw可能將你部份或全部的資料分享他人以便繼續提供服務，你將收到此類事件的通知(在它發生的程度內)，並且我們將要求新結合的組織遵循本隱私權政策所規定的慣例。
                    </TermsText>
                    <TermsTitle>合法要求</TermsTitle>
                    <TermsText>
                        『淞果數位股份有限公司http://www.9199.com.tw』與執法機關及其它第三人合作一起執行法令，例如：智慧財産權、詐欺及其它權利保護。我們可以(且你已授權我們)，依我們自己的考量認為有必要或適當者，可向執法機關或其他政府官員提供與詐欺、智慧財産權侵害、或其他不法行為或將使我們或你負擔法律責任之行為的調查有關的任何你的資料。進而，我們可以(且你已授權我們)，依我們自己的考量認為有必要或適當者，向『淞果數位股份有限公司http://www.9199.com.tw』已確認的權利所有者方案的參與者提供與詐欺、智慧財産權侵害、盜版或其他不法行為的調查有關的會員帳號、姓名、國家、地址、市、郵遞區號、電話號碼、電子郵件、銀行帳戶及公司名稱。此外，『淞果數位股份有限公司http://www.9199.com.tw』。保留(且你已授權 『淞果數位股份有限公司http://www.9199.com.tw』)，依我們自己的考量認為有必要或適當者，將任何有關於你的資料(包括但不限於你違反政策的行為、被終止的物品及物品狀況)提供給其他會員、執法機關及已認證的權利所有者方案會員的權利，以維持信任及我們社群的安全並執行我們的會員合約、隱私權政策及任何已張貼應適用於你使用我們網站內所有服務的政策或規則。
                    </TermsText>
                    <TermsTitle>你對其他會員之資料的使用</TermsTitle>
                    <TermsText>
                        1. 為了加強所有『淞果數位股份有限公司http://www.9199.com.tw』網站會員間的互動，我們的服務允許你有限度的存取其他會員的聯絡資料，站在賣方的立場，你可以取得買方的會員帳號、電子郵件位址及其它資料，而買方則可以取得賣方的會員帳號、電子郵件位址及其它聯絡資料。<br/>
                        2. 除非本款另有規定，在任何情況之下，你都不可以揭露其他會員的個人辨認資料予第三人，除非我們及該其他會員在經過充分說明後表示同意。請注意，執法人員、已認證的權利所有者方案之參與人及其它權利持有人被賦予之資料存取許可權是不相同的。
                    </TermsText>
                    <TermsTitle>密碼控制</TermsTitle>
                    <TermsText>
                        你應就以你的會員帳號及密碼所發生的所有行為負責，包括費用。因此我們並不建議你將你的『淞果數位股份有限公司http://www.9199.com.tw』密碼告知任何第三者，如果你選擇與向你提供額外的服務的第三者分享你的會員帳號及密碼或你的資料，你應就以你的會員帳號及密碼所發生的所有行為負責，因此你應審閱第三者的隱私權政策。如果你對你的密碼失去控制，你可能對你個人辨認資料失去實質的控制，而且可能受到以你名義成立的合法行為所拘束。因此，如果你的密碼因為任何原因受到危害，你必須立刻變更你的密碼。
                    </TermsText>
                    <TermsTitle>審閱及變更你個人辨認資料</TermsTitle>
                    <TermsText>
                        1. 我們提供給你可以自行登入網站並且輸入新資料的方式，自動審閱及變更你提供給我們的資料的能力。通常，我們不會依你的要求而修改你的資料，因為驗證你的帳號是非常困難的。經由到我的『淞果數位股份有限公司http://www.9199.com.tw』中的會員資料修改，你可以改變你的會員帳號、電子郵件位址、聯絡資料、財務資料、裝運資料，如果你的資料有異動，你必須即刻加以更新。<br/>
                        2. 一旦張貼，你不可以更正或移除在我們網站上或其他訊息區或信用評價區上的任何公開張貼。<br/>
                        3. 在某些情況下我們將會在我們的檔案中保留你曾經請求移除的訊息，例如為解決爭議、檢修障礙問題及執行我們的會員合約。又，基於技術及法律的限制，包括存取備份系統，該筆以前資料不會從我們的資料庫中被完全移除。因此，你不應期待所有你的個人辨認資料會如你所要求地從我們的資料庫中被完全移除。
                    </TermsText>
                    <TermsTitle>其他資料搜集者</TermsTitle>
                    <TermsText>
                        除非經過本項隱私權政策明確討論，這份文件僅針對使用及揭露我們向你搜集的資料。在你揭露你的資料給其他人的情形，在我們的網站或整個網際網路的其他網站中不論他們是出價者、買方或賣方，他們使用及揭露你所揭露給他們的資料之行為可能適用不同的法律規定。在我們使用第三者刊登廣告者的情形，他們遵守他們自己的隱私權政策。因為『淞果數位股份有限公司http://www.9199.com.tw』不能控制第三人的隱私權政策，你應該受到該第三者的隱私權政策之拘束，在你揭露個人資料給其他人之前我們鼓勵你提出問題。
                    </TermsText>
                    <TermsTitle>安全性</TermsTitle>
                    <TermsText>
                        『淞果數位股份有限公司http://www.9199.com.tw』使用企業標準慣例來保護你個人辨認資料的秘密性。『淞果數位股份有限公司http://www.9199.com.tw』將資料視為必須保護其免於滅失及未經授權而存取的資産，我們使用多項安全措施以保護此類資料免於公司內外部的會員未經授權的存取。然而，完美的安全性保護在網際網路上是不存在的。
                    </TermsText>
                    <TermsTitle>條款衝突</TermsTitle>
                    <TermsText>
                        本隱私權政策規範是我們隱私權慣例的唯一規範。如果本隱私權政策之條款與條件與其他可能在我們網站中出現的隱私權陳述(例如：隱私權工具、簡易閱讀摘要、圖表及 P3P 聲明)發生衝突時，你同意優先適用本隱私權政策中之條款及條件。
                    </TermsText>
                    <TermsTitle>通知</TermsTitle>
                    <TermsText noMargin>
                        由於科技發展的迅速，相關法規訂定未臻完備，以及未來可能難以預見的環境變遷等因素，會視需要修改我們在網站上所提供的隱私權說明，以落實保障您隱私權之立意，所有修改條款應自初次張貼於網站上起7天後自動生效。
                    </TermsText>

                    <MemberStyle.InputContainer margin="32px 0 0 0" flexdirection="row" justify="space-between">
                        <CheckRetangle isCheck={isPrivacy} onClick={() => this.changeIsPrivacy(true)}>
                            <CheckBox><i className="zmdi zmdi-check"></i></CheckBox>
                            接受
                        </CheckRetangle>
                        <CheckRetangle isCheck={!isPrivacy} onClick={() => this.changeIsPrivacy(false)}>
                            <CheckBox><i className="zmdi zmdi-check"></i></CheckBox>
                            不接受
                        </CheckRetangle>
                    </MemberStyle.InputContainer>
                    <MemberStyle.ConfirmBtn
                        margin="32px 0 0 0"
                        onClick={this.nextStep}
                        disable={!isPrivacy}
                    >
                        下一步
                    </MemberStyle.ConfirmBtn>
                </MemberStyle.MemberContent>
            </GlobalStyle.Container>
        );
    }

    // ------------------------------
    // renderRegister
    // ------------------------------
    renderRegister() {
        
        const {
            memberid,
            name,
            nickname,
            shownametype,
            birthday_yy,
            birthday_mm,
            birthday_dd,
            city,
            region,
            postalcode,
            address,
            password,
            chkpwd,
            mobilephone,
            smsauth,
            email ,
            isShowChkPhoneBtn,
            isShowSendSMSBtn,
            isShowSMSverifyZone,
            isFinishVerifySms,
            isFinishCheckEmail,
            isLoading,
            errorMap,
            isdonate,
            npoban,
            carriertype,
            carrierid,
            isChkingMemberID,
            isChkingMobilePhone,
            isChkMemberIDRepeat,
            isChkMemberIDError,
            isChkingCarrierId,
            isChkCarrierIdError,
        } = this.state;
        
        const cities = GLOBAL.CITIES;
        const regions = GLOBAL.REGIONS[city] || [];

        const disableSubmit = isLoading
            || isChkingMemberID
            || isChkingMobilePhone
            || isChkMemberIDRepeat
            || isChkMemberIDError
            || isChkingCarrierId
            || isChkCarrierIdError;

        return (
            <GlobalStyle.Container
                hasFixedTopbar
                hasFixedTabbar={false}
            >
            
                <TopbarView
                    isFixed
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            返回
                        </CupoySrefView>
                    }
                    middleComponent="註冊會員"
                />
            
                <MemberStyle.MemberContent>
                    <HotNoteDescription >新會員24小時內無法進行交易!資料填寫不實立即停權!</HotNoteDescription>
                    <RegionTitle>帳號與密碼</RegionTitle>
                    <LineDelimeter/>
                    <FieldTitle>
                        帳號
                        {isChkingMemberID && (
                            <span><GlobalStyle.Spin size='12px' /></span>
                        )}
                        {isChkMemberIDError && (
                            <span>帳號長度為4-16個英數字組成，請重新輸入帳號!</span>
                        )}
                        {isChkMemberIDRepeat && (
                            <span>帳號已被註冊，請重新輸入帳號!</span>
                        )}

                    </FieldTitle>
                    <MemberStyle.MemberInputBox
                        ref={(input) => {this.midInput = input} }
                        type="text"
                        value={memberid}
                        placeholder="可由4-16個英數字組成"
                        margin="8px 0 0 0"
                        onChange={(event) =>this.handleChange(event, "memberid")}
                        error={errorMap['memberid']}
                        onBlur={this.checkMemberID}
                    />
                    <FieldTitle>密碼</FieldTitle>
                    <MemberStyle.MemberInputBox
                        ref={(input) => {this.pwdInput = input} }
                        type="password"
                        value={password}
                        placeholder="可由6-10個英數字組成"
                        margin="8px 0 0 0"
                        onChange={(event) =>this.handleChange(event, "password")}
                        error={errorMap['password']}
                    />
                    <FieldTitle>再輸入一次</FieldTitle>
                    <MemberStyle.MemberInputBox
                        ref={(input) => {this.chkpwdInput = input} }
                        type="password"
                        value={chkpwd}
                        placeholder="密碼必須是一致的"
                        margin="8px 0 0 0"
                        onChange={(event) =>this.handleChange(event, "chkpwd")}
                        error={errorMap['chkpwd']}
                    />
                    <RegionTitle margin="32px 0 0 0">個人資料</RegionTitle>
                    <LineDelimeter/>
                    <FieldTitle>真實姓名</FieldTitle>
                    <MemberStyle.MemberInputBox
                        ref={(input) => {this.nameInput = input} }
                        type="text"
                        value={name}
                        placeholder="姓名只能2-4個中文字"
                        margin="8px 0 0 0"
                        onChange={(event) =>this.handleChange(event, "name")}
                        error={errorMap['name']}
                    />
                    <FieldTitle>暱稱</FieldTitle>
                    <MemberStyle.MemberInputBox
                        ref={(input) => {this.nicknameInput = input} }
                        type="text"
                        value={nickname}
                        placeholder="限中文、英文、數字組成"
                        margin="8px 0 0 0"
                        onChange={(event) =>this.handleChange(event, "nickname")}
                        error={errorMap['nickname']}
                    />
                    <FieldTitle>名稱顯示設定</FieldTitle>
                    <MemberStyle.InputContainer
                        flexdirection="row"
                        justify="space-between"
                    >
                        <CheckRetangle
                            isCheck={shownametype === 1}
                            onClick={() => this.changeShowtype(1)}
                        >
                            <CheckBox><i className="zmdi zmdi-check"></i></CheckBox>
                            真實姓名
                        </CheckRetangle>
                        <CheckRetangle
                            isCheck={shownametype === 2}
                            onClick={() => this.changeShowtype(2)}
                        >
                            <CheckBox><i className="zmdi zmdi-check"></i></CheckBox>
                            顯示暱稱
                        </CheckRetangle>
                    </MemberStyle.InputContainer>
                    <FieldTitle>行動電話</FieldTitle>
                    <MemberStyle.MemberInputBox
                        ref={(input) => {this.phoneInput = input} }
                        type="tel"
                        value={mobilephone}
                        placeholder="限台灣地區用戶"
                        margin="8px 0 0 0"
                        onChange={(event) =>this.handleMobilePhoneChange(event)}
                        error={errorMap['mobilephone']}
                    />
                    <FieldTitle>
                        {isChkingMobilePhone && (
                            <span>行動電話格式頭僅以09開且頭僅能輸入數字格式10碼!</span>
                        )}
                    </FieldTitle>
                    {isShowChkPhoneBtn ?
                        <MemberStyle.ConfirmBtn onClick={() => this.checkMobilePhone()} disable={!mobilephone}>查詢手機是否已註冊</MemberStyle.ConfirmBtn>
                        :
                        null
                    }
                    {isShowSendSMSBtn ?
                        <React.Fragment>
                            {
                                !GLOBAL.isAppFile && (
                                    <Recaptcha
                                        sitekey={GLOBAL.grecaptcha_key}
                                        callback={this.recaptchaVerifyCallback}
                                        expiredCallback={this.recaptchaExpiredCallback}
                                        locale="zh-TW"
                                        className="g-recaptcha"
                                        style={{
                                            transform: 'scale(0.9)',
                                            WebkitTransform: 'scale(0.9)',
                                            transformOrigin: '0 0',
                                            WebkitTransformOrigin: '0 0',
                                            marginTop: 16,
                                            marginBottom: -5,
                                        }}
                                    />
                                )
                            }
                            <NoteDescription>提示：點擊「發送驗證碼」後，我們會寄送驗證碼到您的手機。</NoteDescription>
                            <MemberStyle.ConfirmBtn onClick={this.sendSMSCode}>
                                {isShowSMSverifyZone ? '重新寄送簡訊驗證碼' : '寄送簡訊驗證碼'}
                            </MemberStyle.ConfirmBtn>
                        </React.Fragment>
                        :
                        null
                    }
                    {isShowSMSverifyZone ?
                        <div>
                            <MemberStyle.FieldTitle>簡訊驗證碼</MemberStyle.FieldTitle>
                            <MemberStyle.InputContainer
                                flexdirection="row"
                                margin="0"
                            >
                                <MemberStyle.MemberInputBox2
                                    ref={(input) => { this.codeInput = input; }}
                                    type="text"
                                    value={smsauth}
                                    width="calc( 100% - 100px )"
                                    onChange={(event) => this.handleChange(event, "smsauth")}
                                    error={errorMap['smsauth']}
                                />
                                {isFinishVerifySms ?
                                    <MemberStyle.ConfirmBtn 
                                        margin="8px 0 0 10px"
                                        width="100px"
                                        disable
                                    >
                                        已驗證
                                    </MemberStyle.ConfirmBtn>
                                    :
                                    <MemberStyle.ConfirmBtn
                                        margin="8px 0 0 10px"
                                        width="100px"
                                        onClick={() => this.sendVerifycode()}
                                        disable={!smsauth}
                                    >
                                        確定
                                    </MemberStyle.ConfirmBtn>
                                }
                            </MemberStyle.InputContainer>
                        </div>
                        : 
                        null
                    }
                    <FieldTitle>電子郵件</FieldTitle>
                    <MemberStyle.MemberInputBox
                        ref={(input) => {this.EMailInput = input;}}
                        type="email"
                        value={email}
                        placeholder="需做會員身份認證 , 建議使用gmail信箱"
                        margin="8px 0 0 0"
                        onChange={(event) =>this.handleEmailChange(event)}
                        error={errorMap['email']}
                    />
                    <NoteDescription>提示：完成註冊會員後，我們會寄送信件到您的信箱，請您至信箱點選連結啟用帳號。</NoteDescription>
                    {isFinishCheckEmail ?
                        <MemberStyle.ConfirmBtn disable>該電子郵件可使用</MemberStyle.ConfirmBtn>
                        :
                        <MemberStyle.ConfirmBtn onClick={this.chkEMail} disable={!email}>檢查電子郵件信箱是否可使用</MemberStyle.ConfirmBtn>
                    }
                    <FieldTitle>出生日期</FieldTitle>
                    <MemberStyle.InputContainer
                        flexdirection="row"
                        justify="space-between"
                        style={{alignContent:"flex-start"}}
                    >
                        <SelectWrapper error={errorMap['birthday']}>
                            <Select
                                value={birthday_yy}
                                onChange={(event) =>this.handleChange(event, "birthday_yy")}
                            >
                                <option value={''}>西元年</option>
                                {GLOBAL.getYear(1910).map((year, index) => {
                                    return (
                                        <option key={index} value={year}>
                                            {year}
                                        </option>
                                    );
                                })}
                            </Select>
                        </SelectWrapper>
                        <SelectWrapper error={errorMap['birthday']}>
                            <Select
                                value={birthday_mm}
                                onChange={(event) =>this.handleChange(event, "birthday_mm")}
                            >
                                <option value={''}>月份</option>
                                {monthopt.map((month, index) => {
                                    return (
                                        <option key={index} value={month.value}>
                                            {month.label}
                                        </option>
                                    );
                                })}
                            </Select>
                        </SelectWrapper>
                        <SelectWrapper error={errorMap['birthday']}>
                            <Select
                                value={birthday_dd}
                                onChange={(event) =>this.handleChange(event, "birthday_dd")}
                            >
                                <option value={''}>日期</option>
                                {dayopt.map((day, index) => {
                                    return (
                                        <option key={index} value={day.value}>
                                            {day.label}
                                        </option>
                                    );
                                })}
                            </Select>
                        </SelectWrapper>
                    </MemberStyle.InputContainer>
                    <FieldTitle>地址</FieldTitle>
                    <MemberStyle.InputContainer
                        flexdirection="row"
                        justify="space-between"
                    >
                        <SelectWrapper error={errorMap['address']}>
                            <Select
                                value={city}
                                onChange={(event) => this.handleCityChange(event)}
                            >
                                <option value={''}>縣市</option>
                                {cities.map((_city, index) => {
                                    return (
                                        <option key={index} value={_city.value}>
                                            {_city.label}
                                        </option>
                                    );
                                })}
                            </Select>
                        </SelectWrapper>
                        <SelectWrapper error={errorMap['address']}>
                            <Select
                                value={region}
                                onChange={(event) => this.handleRegionChange(event)}
                            >
                                <option value={''}>鄉鎮市區</option>
                                {regions.map((_region, index) => {
                                    return (
                                        <option key={index} value={_region.value}>
                                            {_region.label}
                                        </option>
                                    );
                                })}
                            </Select>
                        </SelectWrapper>
                        <MemberStyle.MemberInputBox
                            type="tel"
                            value={postalcode}
                            placeholder="郵遞區號"
                            width="90px"
                            margin="0 0 0 12px"
                            onChange={(event) =>this.handleChange(event, "postalcode")}
                            error={errorMap['address']}
                        />
                    </MemberStyle.InputContainer>
                    <MemberStyle.MemberInputBox
                        ref={(input) => { this.addressInput = input; }}
                        type="text"
                        value={address}
                        placeholder="地址"
                        margin="12px 0 0 0"
                        onChange={(event) =>this.handleChange(event, "address")}
                        error={errorMap['address']}
                    />

                    <MemberIdNumberVerifier
                        onChange={this.handleIdNumber}
                    />

                    <RegionTitle margin="32px 0 0 0">
                        發票資訊
                        <RegionTitleNotice>種類：兩聯式</RegionTitleNotice>
                    </RegionTitle>
                    <LineDelimeter/>
                    <FieldTitle style={{ display: 'inline-block' }}>發票索取</FieldTitle>

                    <CupoyLink
                        target='_blank'
                        rel='noopener noreferrer'
                        href='https://m.9199.com.tw/newscontent/1412062043227'
                    >
                        <InvoiceNoitceLink>
                            【電子發票懶人包】
                        </InvoiceNoitceLink>
                    </CupoyLink>
                    
                    <MemberStyle.InputContainer
                        flexdirection="row"
                        justify="space-between"
                    >
                        <CheckRetangle
                            isCheck={isdonate}
                            onClick={this.changeDonateToTrue}
                        >
                            <CheckBox><i className="zmdi zmdi-check"></i></CheckBox>
                            捐贈
                        </CheckRetangle>
                        <CheckRetangle
                            isCheck={!isdonate}
                            onClick={this.changeDonateToFalse}
                        >
                            <CheckBox><i className="zmdi zmdi-check"></i></CheckBox>
                            不捐贈
                        </CheckRetangle>
                    </MemberStyle.InputContainer>
                    {
                        isdonate ?
                        <React.Fragment>
                            <FieldTitle  style={{ display: 'inline-block' }}>捐贈碼</FieldTitle>

                            <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href={`${GLOBAL.URL_PREFIX}/npobanlist.pdf`}
                                onClick={mobileUtil.handleOpenLinkBridgeWithNew}
                            >
                                <InvoiceNoitceLink>
                                    【捐贈碼查詢】
                                </InvoiceNoitceLink>
                            </a>
                            
                            <MemberStyle.MemberInputBox
                                ref={(input) => {this.npobanInput = input}}
                                type="text"
                                value={npoban}
                                placeholder="愛心捐贈代碼"
                                margin="8px 0 0 0"
                                onChange={this.handleNpobanberChange}
                                error={errorMap['npoban']}
                            />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <FieldTitle>發票載具</FieldTitle>
                            <MemberStyle.InputContainer
                                flexdirection="row"
                                justify="space-between"
                            >
                                <CheckRetangle
                                    isCheck={carriertype === GLOBAL.CARRIER_TYPE.MEMBER}
                                    onClick={this.changeCarriertypeToMember}
                                >
                                    <CheckBox><i className="zmdi zmdi-check"></i></CheckBox>
                                    會員載具
                                </CheckRetangle>
                                <CheckRetangle
                                    isCheck={carriertype === GLOBAL.CARRIER_TYPE.COMMONS}
                                    onClick={this.changeCarriertypeToCommon}
                                >
                                    <CheckBox><i className="zmdi zmdi-check"></i></CheckBox>
                                    共通性載具
                                </CheckRetangle>
                            </MemberStyle.InputContainer>
                        </React.Fragment>
                    }

                    {carriertype === GLOBAL.CARRIER_TYPE.COMMONS && (
                        <React.Fragment>
                            <FieldTitle>
                                載具ID
                                {isChkingCarrierId && <span><GlobalStyle.Spin size='12px' /></span>}
                                {isChkCarrierIdError && <span>請輸入正確的手機條碼(8碼)或自然人憑證(16碼)！</span>}
                            </FieldTitle>
                            <MemberStyle.MemberInputBox
                                ref={(input) => {this.carrieridInput = input}}
                                type="text"
                                value={carrierid}
                                placeholder="請輸入手機條碼(8碼)或自然人憑證(16碼)"
                                margin="8px 0 0 0"
                                onChange={this.handleCarrieridChange}
                                error={errorMap['carrierid']}
                            />
                        </React.Fragment>
                    )}
                    
                    <MemberStyle.ConfirmBtn
                        margin="32px 0 0 0"
                        onClick={this.registerMember}
                        disable={disableSubmit}
                    >
                        {isLoading && (
                            <SubmitLoading>
                                <GlobalStyle.Spin color="#fff"/>
                            </SubmitLoading>
                        )}
                        註冊
                    </MemberStyle.ConfirmBtn>
                </MemberStyle.MemberContent>
            </GlobalStyle.Container>
        );
    }

    // ------------------------------
    // google callback
    // ------------------------------
    recaptchaVerifyCallback = response => {
        this.setState({
            recaptchaResponse: response,
        });
    }

    // ------------------------------
    // google expiredCallback
    // ------------------------------
    recaptchaExpiredCallback = () => {
        this.props.webActions.openAlertDialog({
            title: '安全認證已過期',
        });
    }

    handleNpobanberChange = event => {
        this.setState({
            npoban: event.target.value,
        });
    }

    checkMemberID() {

        const {
            memberid,
        } = this.state;

        if (!GLOBAL.chkMemberID(memberid)) {
            this.setState({
                isChkMemberIDError: true,
            });
            return;
        }

        if (this.chkMemberIDController) {
            this.chkMemberIDController.cancel();
        }

        this.chkMemberIDController = CancelToken.source();
        const { token: cancelToken } = this.chkMemberIDController;

        this.setState({
            isChkingMemberID: true,
            isChkMemberIDRepeat: false,
            isChkMemberIDError: false,
        }, () => {
            this.props.registerActions.checkMemberID({
                memberid,
                cancelToken,
            }).then(result => {
                this.chkMemberIDController = null;

                this.setState({
                    isChkingMemberID: false,
                    isChkMemberIDRepeat: !result,
                });
                
            }).catch(() => {
                this.chkMemberIDController = null;
                this.setState({
                    isChkingMemberID: false,
                });
            });
        });
    }

    handleIdNumber(idNumber) {
        this.setState({
            idnumber: idNumber,
        });
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            step,
        } = this.state;

        if(step === 1) {
            return this.renderTerms();
        } else if (step === 2) {
            return this.renderPrivacyPolicy();
        }else {
            return this.renderRegister();
        }
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        registerActions: bindActionCreators(actionCreators.registerActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberRegisterView);
