/*=====================================
    Router 相關

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import CupoyStateProvider from 'components/commons/router/CupoyStateProvider';

/*--------------------------
    Variables
--------------------------*/
const STATES = {
    HOME: 'home',
    CARD: 'card',
    CARD_RECOMMENDED: 'card.recommended',
    CARD_POINTCARD: 'card.pointcard',
    CARD_FREEPRODUCTS: 'card.freeproducts',
    CARD_VITEMPRODUCTS: 'card.vitemproducts',
    CARD_BONUSPRODUCTS: 'card.bonusproducts',
    CARDINFORMATION: 'cardinformation',
    VITEM: 'vitem',
    TRANSACTIONLIST: 'transactionlist',
    TRANSACTIONLIST_SELL: 'transactionlist.sell',
    TRANSACTIONLIST_BUY: 'transactionlist.buy',
    SELLDETAIL: 'selldetail',
    SELLDETAIL_PRODUCT: 'selldetail.product',
    SELLDETAIL_MESSAGE: 'selldetail.message',
    SELLFORM: 'sellform',
    PURCHASEINFO: 'purchaseinfo',
    BUYDETAIL: 'buydetail',
    PRODUCTMESSAGE:  'productmessage',
    CART: 'cart',
    CARTCHECKOUT: 'cartcheckout',
    CART_PAYMENT_INFO: 'cart_payment_info',
    PRODUCTCHECKOUT: 'productcheckout',
    PRODUCT_PAYMENT_INFO: 'product_payment_info',
    MEMBER: 'member',
    MYCOMMODITY: 'mycommodity',
    SYSTEMMESSAGE: 'systemmessage',
    HISTORYRECORD: 'historyrecord',
    FORGOT_ACCOUNT: 'forgot_account', // 忘記帳號
    FORGETPWD: 'forgetpwd',        // 忘記密碼
    MEMBERLOGIN: 'memberlogin',    // 登入頁
    MEMBER_INFO: 'member_info',    // 會員資訊頁
    RESETPWD:'resetpwd',           // 重設密碼
    RESETMAIL:'resetmail',         // 重設EMail
    RESETADDRESS:'resetaddress',   // 重設地址
    RESETMOBILE:'resetmobile',     // 重設手機
    RESETNICKNAME:'resetnickname', // 重設暱稱
    RESET_ID_NUMBER: 'reset_id_number', // 重設身分證
    RESET_BIRTHDAY: 'reset_birthday', // 重設生日
    MAIL_IDENTIFY:'mail_identify', // 驗證Email
    CHANGE_MAIL_IDENTIFY: 'change_mail_identify', // 修改mail的驗證
    REGISTER:'register',
    NEWSLIST: 'newslist',
    NEWSCONTENT: 'newscontent',
    PRODUCTBUYLIST: 'productbuylist',
    PRODUCTBUYCONTENT: 'productbuycontent',    
    GOODPROMOTIONS: 'goodpromotions',
    GOODPROMOTIONSCONTENT: 'goodpromotionscontent',
    MEMBER_BONUS: 'member_bonus', // 會員發財金首頁
    MEMBER_BONUS_LOGS: 'member_bonus_logs', // 會員發財金明細
    MEMBER_BONUS_DEPOSIT: 'member_bonus_deposit', // 兌換發財金
    MEMBER_BONUS_ORDER: 'member_bonus_order', // 發財金兌換記錄
    MEMBER_BONUS_ORDER_DETAIL: "member_bonus_order_detail", // 發財商城交易詳細資料
    BONUS_MANUAL: 'bonus_manual', // 發財金說明
    MEMBER_INVOICE: 'member_invoice', // 會員發票管理
    MEMBER_CONTACT_SERVICE: 'member_contact_service', // 聯絡客服首頁
    MEMBER_ADD_COMPLAINT: 'member_add_complaint', // 建立客訴單
    MEMBER_COMPLAINT_LOGS: 'member_complaint_logs', // 客訴記錄列表
    MEMBER_COMPLAINT: 'member_complaint', // 客訴詳細記錄
    MEMBER_REFUND: 'member_refund', // 帳戶退款首頁
    MEMBER_REFUND_APPLY: "member_refund_apply", // 帳戶退款申請頁
    MEMBER_REFUND_LOGS: "member_refund_logs", // 退款申請記錄
    MEMBER_VITEM_ORDER_LIST: "member_vitem_order_list", // 虛寶市集訂單首頁
    MEMBER_VITEM_ORDER_LIST_SELL: "member_vitem_order_list.sell", // 虛寶市集訂單（出售區）
    MEMBER_VITEM_ORDER_LIST_BUY: "member_vitem_order_list.buy", // 虛寶市集訂單（收購區）
    MEMBER_VITEM_ORDER_SELL: "member_vitem_order_sell", // 虛寶市集訂單（出購區詳細訂單）
    MEMBER_VITEM_ORDER_BUY: "member_vitem_order_buy", // 虛寶市集訂單（收購區詳細訂單）
    MEMBER_CARD_ORDER_LIST: "member_card_order_list", // 點卡訂單首頁
    MEMBER_CARD_ORDER_LIST_FINISH: "member_card_order_list.finish", // 點卡訂單 (完成交易列表)
    MEMBER_CARD_ORDER_LIST_CART: "member_card_order_list.cart", // 點卡訂單 (購物車列表)
    MEMBER_CARD_ORDER_DETAIL: "member_card_order_detail", // 點卡交易詳細資料
    MEMBER_CARD_ORDER_DETAIL_LIST: "member_card_order_detail_list", // 點卡交易點數列表
    MEMBER_CARD_ORDER_UNPAY_DETAIL: "member_card_order_unpay_detail", // 點卡未付款購物車詳細資訊    
    ABOUT: 'about', // 關於頁
    MEMBER_TERMS: 'member_terms', // 會員條款
    DISCLAIMER: 'disclaimer', // 免責聲明
    PRIVACY_POLICY: 'privacy_policy', // 隱私權聲明
    UPDATE_INVOICE: 'update_invoice', // 更新發票資訊

    HELP: 'help', // 幫助中心
    UPGRADE_MEMBERSHIP: 'upgrade_membership', // 如何升級會員
    MEMBER_SUSPENSION_OF_POWER: 'member_suspension_of_power', // 會員停權
    TRANSACTION_ORDER: 'transaction_order', // 繳費說明
    TRANSACTION_SECURITY: 'transaction_security', // 交易安全
};

var $stateProvider = new CupoyStateProvider();

$stateProvider.otherwise('/');
$stateProvider
    .state(STATES.HOME, '/')
    .state(STATES.CARD, '/card')
        .state(STATES.CARD_RECOMMENDED, '/recommended')
        .state(STATES.CARD_POINTCARD, '/pointcard')
        .state(STATES.CARD_FREEPRODUCTS, '/freeproducts')
        .state(STATES.CARD_VITEMPRODUCTS, '/vitemproducts')
        .state(STATES.CARD_BONUSPRODUCTS, '/bonusproducts')
    .state(STATES.CARDINFORMATION, '/cardinformation/:id')
    .state(STATES.VITEM, '/vitem')
    .state(STATES.TRANSACTIONLIST, '/transactionlist/:gameid/:serverid?')
        .state(STATES.TRANSACTIONLIST_SELL, '/sell')
        .state(STATES.TRANSACTIONLIST_BUY, '/buy')
    .state(STATES.SELLDETAIL, '/selldetail/:gameid/:serverid/:viprovid')
        .state(STATES.SELLDETAIL_PRODUCT, '/product')
        .state(STATES.SELLDETAIL_MESSAGE, '/message')
    .state(STATES.SELLFORM, '/sellform')
    .state(STATES.PURCHASEINFO, '/purchaseinfo/:gameid/:serverid/:viprovid')
    .state(STATES.BUYDETAIL, '/buydetail/:gameid/:gameserverid/:vprovmrscid/:purchaseid')
    .state(STATES.PRODUCTMESSAGE, '/productmessage')
    .state(STATES.CART, '/cart')
    .state(STATES.CARTCHECKOUT, '/cartcheckout')
    .state(STATES.CART_PAYMENT_INFO, '/cart-payment-info/:id')
    .state(STATES.PRODUCTCHECKOUT, '/productcheckout')
    .state(STATES.PRODUCT_PAYMENT_INFO, '/product-payment-info/:id')
    .state(STATES.MEMBER, '/member')
    .state(STATES.MYCOMMODITY, '/mycommodity')
    .state(STATES.SYSTEMMESSAGE, '/systemmessage')
    .state(STATES.HISTORYRECORD, '/historyrecord')
    .state(STATES.FORGOT_ACCOUNT, '/member/forgot-account')
    .state(STATES.FORGETPWD, '/member/forgetpwd')
    .state(STATES.MEMBERLOGIN, '/member/memberlogin')
    .state(STATES.RESETPWD, '/resetpwd/:token/:mrscid')
    .state(STATES.MEMBER_INFO, '/member-info')
    .state(STATES.RESETMAIL, '/member/resetmail')
    .state(STATES.RESETADDRESS, '/member/resetaddress')
    .state(STATES.RESETMOBILE, '/member/resetmobile')
    .state(STATES.RESETNICKNAME, '/member/resetnickname')
    .state(STATES.RESET_ID_NUMBER, '/member/resetidnumber')
    .state(STATES.RESET_BIRTHDAY, '/member/reset-birthday')
    .state(STATES.UPDATE_INVOICE, '/member/update-invoice')
    .state(STATES.MAIL_IDENTIFY, '/mail-identify/:emailauth/:mrscid')
    .state(STATES.CHANGE_MAIL_IDENTIFY, '/change-mail-identify/:emailauth/:mrscid')
    .state(STATES.REGISTER, '/register')
    .state(STATES.NEWSLIST, '/newslist')
    .state(STATES.NEWSCONTENT, '/newscontent/:id')
    .state(STATES.PRODUCTBUYLIST, '/productbuylist')
    .state(STATES.PRODUCTBUYCONTENT, '/productbuycontent/:id')
    .state(STATES.GOODPROMOTIONS, '/goodpromotions')
    .state(STATES.GOODPROMOTIONSCONTENT, '/goodpromotionscontent/:eventid/:bulletinid')
    .state(STATES.MEMBER_BONUS, '/member-bonus')
    .state(STATES.MEMBER_BONUS_LOGS, '/member-bonus-logs')
    .state(STATES.MEMBER_BONUS_DEPOSIT, '/member-bonus-deposit')
    .state(STATES.MEMBER_BONUS_ORDER, '/member-bonus-order')
    .state(STATES.MEMBER_BONUS_ORDER_DETAIL, '/member-bonus-order/:id')
    .state(STATES.BONUS_MANUAL, '/bonus-manual')
    .state(STATES.MEMBER_INVOICE, '/member-invoice')
    .state(STATES.MEMBER_CONTACT_SERVICE, '/member-contact-service')
    .state(STATES.MEMBER_ADD_COMPLAINT, '/member-add-complaint')
    .state(STATES.MEMBER_COMPLAINT_LOGS, '/member-complaint-logs')
    .state(STATES.MEMBER_COMPLAINT, '/member-complaint/:id/:createtime')
    .state(STATES.MEMBER_REFUND, '/member-refund')
    .state(STATES.MEMBER_REFUND_APPLY, '/member-refund-apply')
    .state(STATES.MEMBER_REFUND_LOGS, '/member-refund-logs')
    .state(STATES.MEMBER_VITEM_ORDER_LIST, '/member-vitem-order-list')
        .state(STATES.MEMBER_VITEM_ORDER_LIST_SELL, '/sell')
        .state(STATES.MEMBER_VITEM_ORDER_LIST_BUY, '/buy')
    .state(STATES.MEMBER_VITEM_ORDER_SELL, '/member-vitem-order/sell/:id')
    .state(STATES.MEMBER_VITEM_ORDER_BUY, '/member-vitem-order/buy/:id')
    .state(STATES.MEMBER_CARD_ORDER_LIST, '/member-card-order-list')
        .state(STATES.MEMBER_CARD_ORDER_LIST_FINISH, '/finish')    
        .state(STATES.MEMBER_CARD_ORDER_LIST_CART, '/cart')
    .state(STATES.MEMBER_CARD_ORDER_DETAIL, '/member-card-order-detail/:id/:jcardid')
    .state(STATES.MEMBER_CARD_ORDER_DETAIL_LIST, '/member-card-order-detail-list/:id')
    .state(STATES.MEMBER_CARD_ORDER_UNPAY_DETAIL, '/member-card-order-unpay-detail/:id')
    .state(STATES.ABOUT, '/about')
    .state(STATES.DISCLAIMER, '/disclaimer')
    .state(STATES.MEMBER_TERMS, '/member-terms')
    .state(STATES.PRIVACY_POLICY, '/privacy-policy')

    .state(STATES.HELP, '/help')
    .state(STATES.UPGRADE_MEMBERSHIP, '/upgrade_membership')
    .state(STATES.MEMBER_SUSPENSION_OF_POWER, '/member-suspension-of-power')

    .state(STATES.TRANSACTION_SECURITY, '/transaction-security')
    .state(STATES.TRANSACTION_ORDER, '/transaction-order')
;

var PATHS = {};

for(var key in STATES) {

    var statename = STATES[key];

    PATHS[key] = $stateProvider.absolutePathMap[statename];
}

/*--------------------------
    Method
--------------------------*/

// ------------------------------
// 取得 react router 所需要的陣列
// ------------------------------
function getReactRouterList() {

    return $stateProvider.getReactRouterList();
}

// ------------------------------
// 更新目前state
// ------------------------------
function updateCurrent(location, action) {

    return $stateProvider.updateCurrent(location, action);
}

// ------------------------------
// 更新目前state
// ------------------------------
function updateCurrentByPath(path, action) {

    return $stateProvider.updateCurrentByPath(path, action);
}

// ------------------------------
// 更新目前state
// ------------------------------
function updateCurrentByState(statename, params, querys, path, action) {

    return $stateProvider.updateCurrentByState(statename, params, querys, path, action);
}

// ------------------------------
// 取得目前state
// ------------------------------
function getCurrent() {

    return $stateProvider.current;
}

// ------------------------------
// 取得state實際網址（含querys）
// ------------------------------
function getUrl(statename, params, querys) {

    return $stateProvider.getUrl(statename, params, querys);
}

// ------------------------------
// 取得state實際網址（無querys）
// ------------------------------
function getPathname(statename, params) {

    return $stateProvider.getPathname(statename, params);
}

// ------------------------------
// 取得query網址
// ------------------------------
function getQueryUrl(querys) {

    return $stateProvider.getQueryUrl(querys);
}

// ------------------------------
// 傳入的statename是否為目前state的state或是子state
// ------------------------------
function includes(statename) {

    return $stateProvider.includes(statename);
}

// ------------------------------
// 是否有該state
// ------------------------------
function hasState(statename) {

    return $stateProvider.hasState(statename);
}

// ------------------------------
// 判斷是否在此頁
// ------------------------------
function isActive(statename, params, querys) {

    return $stateProvider.isActive(statename, params, querys);
}

// ------------------------------
// 判斷目前是否為未定義的頁面
// ------------------------------
function isUnknowState() {

    return $stateProvider.isUnknowState();
}

// ------------------------------
// 取得otherwise
// ------------------------------
function getOtherwise() {

    return $stateProvider.otherwisePath;
}

// ------------------------------
// 取得歷史記錄
// ------------------------------
function getHistory() {

    return $stateProvider.getHistory();
}

// ------------------------------
// 由網址取得目前state
// ------------------------------
function getStateByPath(path) {

    return $stateProvider.getStateByPath(path);
}

// ------------------------------
// 由網址取得目前querys
// ------------------------------
function getQuerysByPath(path) {

    return $stateProvider.getQuerysByPath(path);
}

const CupoyRouter = {
    /*--------------------------
        Variables
    --------------------------*/
    STATES, // Cupoy 所有state
    PATHS, // State 對應到的 path

    /*--------------------------
        Method
    --------------------------*/
    getReactRouterList, // 取得 react router 所需要的陣列
    updateCurrent, // 更新目前state
    updateCurrentByPath, // 更新目前state
    updateCurrentByState, // 更新目前state
    getCurrent, // 取得目前state
    getUrl, // 取得state實際網址（含querys）
    getPathname, // 取得state實際網址（無querys）
    getQueryUrl, // 取得query網址
    includes, // 傳入的statename是否為目前state的state或是子state
    hasState, // 是否有該state
    isActive, // 判斷是否在此頁
    isUnknowState, // 判斷目前是否為未定義的頁面
    getOtherwise, // 取得otherwise
    getHistory, // 取得歷史記錄
    getStateByPath, // 由網址取得目前state
    getQuerysByPath, // 由網址取得目前querys
};

export default CupoyRouter;
