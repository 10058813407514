/*=====================================
    全域顏色樣式

    Author: Gray
    createtime: 2017 / 12 / 21
=====================================*/

/*--------------------------
    Style Variables
--------------------------*/
const Main = '#F75C2F';
const Red = '#D0021B';
const Blue = '#4A90E2';
const Green = '#54B62C';
const Success = '#54B62C';
const Error = '#D0021B';
const Orange = '#F75C2F';

const Dark = '#000000';
const Dark2 = '#333333';
const Dark3 = '#4C4C4C';
const Dark4 = '#6A6A6A';
const Dark5 = '#777777';
const Dark6 = '#232423';
const Lightgray = '#222328';
const White = '#FFFFFF';
const ImgBackground = '#DDD';

/*--------------------------
    Style Method
--------------------------*/

// ------------------------------
// 轉換公式
// ------------------------------
function hex(x) {
	return ("0" + parseInt(x, 10).toString(16)).slice(-2);
}

// ------------------------------
// 變暗變亮 1 ~ 100 變亮, -1 ~ -100 變暗
// ------------------------------
function LightenDarkenColor(col, amt) {
  
    var usePound = false;
  
    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }
 
    var num = parseInt(col,16);
 
    var r = (num >> 16) + amt;
 
    if (r > 255) r = 255;
    else if  (r < 0) r = 0;
 
    var b = ((num >> 8) & 0x00FF) + amt;
 
    if (b > 255) b = 255;
    else if  (b < 0) b = 0;
 
    var g = (num & 0x0000FF) + amt;
 
    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    const _hex = (g | (b << 8) | (r << 16)).toString(16);
    if(_hex.length !== 6) {
    	return (usePound?"#":"") + (hex(r) + hex(g) + hex(b));
    } else {
    	return (usePound?"#":"") + _hex;
    }
}

// ------------------------------
// hex用的rgba
// ------------------------------
function RGBA(hex, opacity) {

	// http://stackoverflow.com/a/5624139
  	var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  	hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    	return r + r + g + g + b + b;
  	});

  	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  	result = result ? 'rgba(' + parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) + ',' + opacity + ')' : null;
  	return result;
}

const Colors = {
	/*--------------------------
        Variables
    --------------------------*/
    Main,
    Red,
    Blue,
    Green,
    Success,
    Error,
    Orange,
    Dark,
    Dark2,
    Dark3,
    Dark4,
    Dark5,
    Dark6,
    Lightgray,
    White,
    ImgBackground,

    /*--------------------------
        Method
    --------------------------*/
    hex,
    LightenDarkenColor,
    RGBA,
}

export default Colors;