import actionTypes from "actions/types/vitemOrderActionTypes";
import convetDBResultSetToArray from "actions/util/convetDBResultSetToArray";
import {uniq} from 'lodash';
/*==========================
    Reducers
==========================*/
const reducers = {
 [actionTypes.listBuyVItemOrdersSuccess]: function(state, action) {
     return listBuyVItemOrdersSuccess(state, action);
 },
 [actionTypes.getBuyVItemOrderInfoSuccess]: function(state, action) {
     return getBuyVItemOrderInfoSuccess(state, action);
 },
 [actionTypes.listPurchaseOrdersSuccess]: function(state, action) {
     return listPurchaseOrdersSuccess(state, action);
 },
 [actionTypes.getPurchaseOrderInfoSuccess]: function(state, action) {
     return getPurchaseOrderInfoSuccess(state, action);
 },
 [actionTypes.updPurchaseOrderStatusSuccess]: function(state, action) {
     return updPurchaseOrderStatusSuccess(state, action);
 },
 [actionTypes.getPurchaseOrderLogsSuccess]: function(state, action) {
     return getPurchaseOrderLogsSuccess(state, action);
 },
 [actionTypes.addPurchaseOrderLogSuccess]: function(state, action) {
     return addPurchaseOrderLogSuccess(state, action);
 },
 [actionTypes.replyPurchaseOrderLogSuccess]: function(state, action) {
     return replyPurchaseOrderLogSuccess(state, action);
 },
 [actionTypes.addGradeSuccess]: function(state, action) {
     return addGradeSuccess(state, action);
 },
 [actionTypes.addOrderLogSuccess]: function(state, action) {
     return addOrderLogSuccess(state, action);
 },
[actionTypes.listOrderLogsSuccess]: function(state, action) {
    return listOrderLogsSuccess(state, action);
},

};





/*==========================
    Method
==========================*/

function listOrderLogsSuccess (state, action) {
    const logs = action.payload.result.list;
    const { orderid } = action.payload;

    const orgorder = state.orderMap[orderid] || {};
    const orgorderlogs = orgorder.logs || [];

    const neworder = Object.assign({}, orgorder, {logs: [...orgorderlogs, ...logs]});

    const newordermap = Object.assign({}, state.orderMap, {[orderid]: neworder});

    return Object.assign({}, state, {
        orderMap: newordermap,
        isloading: false,
    })
}

function addOrderLogSuccess (state, action) {
    
    const { orderid } = action.payload;

    const orgorder = state.orderMap[orderid] || {};

    const neworder = Object.assign({}, orgorder, {logs: []});

    const newordermap = Object.assign({}, state.orderMap, {[orderid]: neworder});

    return Object.assign({}, state, {
        orderMap: newordermap,
        isloading: false,
	});
}

function addGradeSuccess (state, action) {
    
    return Object.assign({}, state, {
        isloading: false,
	});
}

function replyPurchaseOrderLogSuccess (state, action) {
    
    return Object.assign({}, state, {
        isloading: false,
	});
}

function addPurchaseOrderLogSuccess (state, action) {

    const { itemid } = action.payload;

    const orgorder = state.purchaseOrderMap[itemid] || {};
    const newids = [];

    const neworder = Object.assign({}, orgorder, {logids: newids});

    const newordermap = Object.assign({}, state.purchaseOrderMap, {[itemid]: neworder});

    return Object.assign({}, state, {
        purchaseOrderMap: newordermap,
        isloading: false,
    })
}

function getPurchaseOrderLogsSuccess (state, action) {
    const dbresult = action.payload.result.list;
    const { itemid } = action.payload;

    const orgorder = state.purchaseOrderMap[itemid] || {};

    const orgorderlogmap = orgorder.logmap || {};

    const logmap  = dbresult.reduce((map, item)=>{
        map[item.msgid] = Object.assign({}, orgorderlogmap[item.msgid], item);
        return map;
    }, {});

    const neworderlogmap = Object.assign({}, orgorderlogmap, logmap);

    const ids = dbresult.map((item, idx)=>{
        return item.msgid;
    });

    const orgids = orgorder.logids || [];

    const newids = uniq([...orgids, ...ids]);

    const neworder = Object.assign({}, orgorder, {logmap: neworderlogmap, logids: newids});

    const newordermap = Object.assign({}, state.purchaseOrderMap, {[itemid]: neworder});

    return Object.assign({}, state, {
        purchaseOrderMap: newordermap
    })
}

function updPurchaseOrderStatusSuccess (state, action) {

    return Object.assign({}, state, {
        isloading: false,
	});
}

function getPurchaseOrderInfoSuccess (state, action) {
    const dbresult = convetDBResultSetToArray(action.payload.result);
    const { itemid } = action.payload;



    const itemMap = dbresult.reduce((map, item)=>{
        map[itemid] = Object.assign({}, state.purchaseOrderMap[itemid], item);
        return map;
    }, {});
    
    const newItemMap = Object.assign({}, state.purchaseOrderMap, itemMap);
    return Object.assign({}, state, {
        purchaseOrderMap: newItemMap,
        isloading: false,
    });
}

function listPurchaseOrdersSuccess (state, action) {

    const dbresult = convetDBResultSetToArray(action.payload);

    const purchaseOrderMap = dbresult.reduce((map, order)=>{
        map[order.itemid] = Object.assign({}, state.purchaseOrderMap[order.itemid], order);
        return map;
    }, {});
    

    const ids = dbresult.map((order, idx)=>{
        return order.itemid;
    });

    const newids = uniq([...state.purchaseOrderIds, ...ids]);

    const newPurchaseOrderMap = Object.assign({}, state.purchaseOrderMap, purchaseOrderMap);

    return Object.assign({}, state, {
        purchaseOrderIds: newids,
        purchaseOrderMap: newPurchaseOrderMap,
        isloading: false,
    });

}

function getBuyVItemOrderInfoSuccess (state, action) {
    const dbresult = convetDBResultSetToArray(action.payload.result.order);
    const refunds = convetDBResultSetToArray(action.payload.result.refunds);

    const orderMap = dbresult.reduce((map, order)=>{
        map[order.orderid] = Object.assign({}, state.orderMap[order.orderid], order, {refunds});
        return map;
    }, {});

    const newOrderMap = Object.assign({}, state.orderMap, orderMap);

    return Object.assign({}, state, {
        orderMap: newOrderMap,
        isloading: false,
    });
}

function listBuyVItemOrdersSuccess (state, action) {
    const dbresult = convetDBResultSetToArray(action.payload);

    const orderMap = dbresult.reduce((map, order)=>{
        map[order.orderid] = Object.assign({}, state.orderMap[order.orderid], order);
        return map;
    }, {});
    

    const ids = dbresult.map((order, idx)=>{
        return order.orderid;
    });

    const newids = uniq([...state.orderIds, ...ids]);

    const newOrderMap = Object.assign({}, state.orderMap, orderMap);

    return Object.assign({}, state, {
        orderIds: newids,
        orderMap: newOrderMap,
        isloading: false,
    });
}


// 操作失敗
function actionFailed (state, action) {

    if(state.error === action.payload){
        return state;
    }

    const error = action.payload;

    return Object.assign({}, state, {
        isloading: false,
        error,
	});


}


// 操作中
function actionStarted (state, action) {

    if(state.isloading){
        return state;
    }

    return Object.assign({}, state, {
        isloading: true,
	});


}


/*==========================
    Export
==========================*/
export default function createReducers (initialState) {
	return function reducer(state = initialState, action) {
        if(!actionTypes.hasOwnProperty(action.type)){
            return state;
        }
		if (reducers.hasOwnProperty(action.type)) {
            return reducers[action.type](state, action);
		} else if(action.type.lastIndexOf('Started') !== -1){
			return actionStarted(state, action);            
		} else if(action.type.lastIndexOf('Failed') !== -1){
			return actionFailed(state, action);            
        } else {
			return state;
		}
	};
}