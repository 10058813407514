/*=====================================
    首頁

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import TopbarView from "components/commons/TopbarView";
import { GlobalStyle } from 'stylecomponents';
import styled from 'styled-components';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyNoteContent from "components/commons/CupoyNoteContent";
import MarkdownContentView from 'cupoy-md-editor/lib/preview';

/*--------------------------
    styled
--------------------------*/

const BulletinContent = styled.div`

    min-height: calc(100vh - 44px);
    background-color: #fff;
    overflow-y: scroll;
    padding: 16px;

`;

const TopbarMiddle = styled.h3`

    height: 100%;
    line-height: 44px;
    margin: 0;
    font-size: 16px;
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
    color: #FFFFFF;
    text-align: center;

`;

const LoadingContent = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 44px);
    background-color: #fff;

`;

/* 最新消息: */
/*--------------------------
    Main Component
--------------------------*/

class ProductBuyContentView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    // constructor(props) {

    //     super(props);
    // }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        this.props.bulletinActions.getBulletinDetail({
            bulletinid: this.props.match.params.id
        });
    }

    sliceBulletinTitle(actionType, title){

        if(!title){
            return null;
        }

        switch (actionType) {

            case 'getTagName':
                return title.slice(1,3);
            case 'getTitle':
                return title.slice(4)
            default:
                return title
        }

    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        if(!Object.keys(this.props.itemMap).length > 0){
            return null;
        }

        if(!this.props.itemMap[this.props.match.params.id]) {
            return null;
        }

        const {
            title,
            bulletincontent,
            note_editor_type,
            markdown,
        } = this.props.itemMap[this.props.match.params.id];
        
        return (
            <GlobalStyle.Container
                hasFixedTopbar={true}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.PRODUCTBUYLIST}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            最新活動
                        </CupoySrefView>
                    }
                    middleComponent={
                        <TopbarMiddle>
                            {this.sliceBulletinTitle('getTitle', title)}
                        </TopbarMiddle>
                    }
                />
                <BulletinContent>
                    {!this.props.isloading ?
                        note_editor_type === 1 ?
                        <MarkdownContentView mdcontent={markdown} />      
                        :
                        <CupoyNoteContent html={bulletincontent} />
                        :
                        null
                    }
                    {this.props.isloading ?
                            <LoadingContent>
                                <GlobalStyle.Spin />
                            </LoadingContent>
                        :
                        null
                    }
                </BulletinContent>
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        itemMap: state.bulletin.itemMap,
        isloading: state.bulletin.isloading,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        bulletinActions: bindActionCreators(actionCreators.bulletinActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(ProductBuyContentView);