/*--------------------------
    Import 
--------------------------*/
import styled from 'styled-components';

/*--------------------------
    Style Variables
--------------------------*/

/*--------------------------
    Style Component
--------------------------*/

const StepBarWrapper = styled.div`

    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: ${props => props.hasFixedTabbar ? '50px' : '0'};
    left: 0;
    padding: 8px;
    height: 60px;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 -1px 2px 0 rgba(0,0,0,0.05);

`;

const StepBarText = styled.div`

    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 7px;
    font-size: 14px;
    color: #4A4A4A;

`;

const StepBarPrice = styled.span`

    font-size: 16px;
    color: #F75C2F;

`;

const StepBarButton = styled.div`

    position: relative;
    filter: brightness(${props => props.disable ? '0.7' : '1'});
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 22px;
    margin-left: 12px;
    color: #FEFEFE;
    font-size: 14px;
    background: #F75C2F;
    border-radius: 2px;
    cursor: pointer;
    opacity: ${props => props.disable ? '0.8' : '1'};

`;

const StepBottomBar = {

    StepBarWrapper,
    StepBarText,
    StepBarPrice,
    StepBarButton,

}

export default StepBottomBar;
