export default {
 
    addCardToCart: 'addCardToCart',
    addCardToCartStarted: 'addCardToCartStarted',
    addCardToCartSuccess: 'addCardToCartSuccess',
    addCardToCartFailed: 'addCardToCartFailed',

    delCardFromCart: 'delCardFromCart',
    delCardFromCartStarted: 'delCardFromCartStarted',
    delCardFromCartSuccess: 'delCardFromCartSuccess',
    delCardFromCartFailed: 'delCardFromCartFailed',

    listCardCartItems: 'listCardCartItems',
    listCardCartItemsStarted: 'listCardCartItemsStarted',
    listCardCartItemsSuccess: 'listCardCartItemsSuccess',
    listCardCartItemsFailed: 'listCardCartItemsFailed',

    // delCardsFromCart: 'delCardsFromCart',
    // delCardsFromCartStarted: 'delCardsFromCartStarted',
    // delCardsFromCartSuccess: 'delCardsFromCartSuccess',
    // delCardsFromCartFailed: 'delCardsFromCartFailed',


    buildCartOrder: 'buildCartOrder',
    buildCartOrderStarted: 'buildCartOrderStarted',
    buildCartOrderSuccess: 'buildCartOrderSuccess',
    buildCartOrderFailed: 'buildCartOrderFailed',


    payCardCart: 'payCardCart',
    payCardCartStarted: 'payCardCartStarted',
    payCardCartSuccess: 'payCardCartSuccess',
    payCardCartFailed: 'payCardCartFailed',

    listCardCartUnfinishedOrders: 'listCardCartUnfinishedOrders',
    listCardCartUnfinishedOrdersStarted: 'listCardCartUnfinishedOrdersStarted',
    listCardCartUnfinishedOrdersSuccess: 'listCardCartUnfinishedOrdersSuccess',
    listCardCartUnfinishedOrdersFailed: 'listCardCartUnfinishedOrdersFailed',

    payCardCartUnfinishedOrder: 'payCardCartUnfinishedOrder',
    payCardCartUnfinishedOrderStarted: 'payCardCartUnfinishedOrderStarted',
    payCardCartUnfinishedOrderSuccess: 'payCardCartUnfinishedOrderSuccess',
    payCardCartUnfinishedOrderFailed: 'payCardCartUnfinishedOrderFailed',
    
};
