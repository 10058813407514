/*=====================================
    MemberRefundLogs

    Author: Gray
    CreateTime: 2018 / 03 / 13
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import GLOBAL from 'nonnmwebglobal';
import styled from 'styled-components';
import { GlobalStyle, Colors, MemberStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
import TimeFormat from "components/commons/TimeFormat";
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
    padding: 0 0 10px 0;
`;
const ColorText = styled.span`
    color: ${(props) => props.color ? props.color : 'inherit'};
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberRefundLogsView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            start: Array.isArray(this.props.refundApplications) ? this.props.refundApplications.length : 0,
            isLoading: false,
            isError: false,
            isEmpty: false,
            isNoMore: false,
        };

        this.listRefundApplications = this.listRefundApplications.bind(this);
        this.getRefundFlagString = this.getRefundFlagString.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        if(prevProps.haslogin !== this.props.haslogin) {
            this.init(this.props);
        }
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props) {

        const {
            haslogin,
            refundApplications,
        } = this.props;

        if(haslogin && ( !Array.isArray(refundApplications) || refundApplications.length === 0)) {
            this.listRefundApplications();
        }
    }

    // ------------------------------
    // 取得退款申請單
    // ------------------------------
    listRefundApplications() {

        const {
            start,
            isLoading,
            isEmpty,
            isNoMore,
            isError,
        } = this.state;

        if(isLoading || isEmpty || isNoMore || isError) {
            return;
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.memberActions.listRefundApplications({
                start: start,
            }).then((result) => {
                const refundApplications = this.props.refundApplications;

                var newState = {
                    isLoading: false,
                    start: refundApplications.length,
                };

                if(!Array.isArray(refundApplications) || refundApplications.length === 0) {
                    newState.isEmpty = true;
                } else if(start === newState.start) {
                    newState.isNoMore = true;
                }

                this.setState(newState);
            }, (error) => {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            })
        })
    }

    // ------------------------------
    // getRefundFlagString
    // ------------------------------
    getRefundFlagString(refund) {

        const int_refundflag = Number(refund.refundflag);
        const text = GLOBAL.REFUND_FLAG_STRING[int_refundflag];
        var color = undefined;

        switch(int_refundflag) {
            case GLOBAL.REFUND_FLAG.PROCESSED:
                color = Colors.Red;
                break;
            case GLOBAL.REFUND_FLAG.SUCCESS:
            case GLOBAL.REFUND_FLAG.REFUND_BY_ADMIN:
                color = Colors.Green;
                break;
            default:
                break;
        }

        return (<ColorText color={color}>{text}</ColorText>);
    }

    // ------------------------------
    // rowRenderer
    // ------------------------------
    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it),
        parent,
        measure,
        columnIndex,
        updateRowSize,
        measureCache,
        listWidth,
    }, width, listHeight){

        const {
            refundApplications,
        } = this.props;

        const refund = refundApplications[index];

        if(!refund) {
            return null;
        }

        return (
            <div
                key={index}
                style={style}
            >
                <MemberStyle.LogsItem>
                    <MemberStyle.LogsTitle>
                        退款編號: {refund.refundid}
                    </MemberStyle.LogsTitle>
                    <MemberStyle.LogsDescription>
                        申請時間: <TimeFormat format="simpleFullTime" time={refund.createtime}/>
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription>
                        申請狀態: {this.getRefundFlagString(refund)}
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription>
                        退款金額: <ColorText color={Colors.Orange}>{refund.amount}</ColorText>
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription>
                        入款銀行: {refund.bank} / {refund.subbank}
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription isLast={true}>
                        入款帳戶: ({refund.bankcode}) {refund.bankaccount}                        
                    </MemberStyle.LogsDescription>
                </MemberStyle.LogsItem>
            </div>
        )
    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return <NeedLoginBlock />;
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            isLoading,
            isError,
            isEmpty,
        } = this.state;

        const {
            refundApplications,
        } = this.props;

        return (
            <Content>
                {Array.isArray(refundApplications) && refundApplications.length > 0 ?
                    <CupoyListRepeat
                        listId={this.constructor.name}
                        rowRenderer={this.rowRenderer}
                        items={refundApplications}
                        isLoading={isLoading}
                        useWindowScroll={true}
                        restoreWindowScroll={true}
                        loadMoreRows={() => this.listRefundApplications()}
                    />
                    :
                    null
                }

                {isLoading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }

                {isError ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            發生錯誤
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無法取得退款申請記錄，請重新整理後再嘗試。
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }

                {isEmpty ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            無任何退款申請記錄
                        </GlobalStyle.ErrorContainerTitle>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }
            </Content>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER_REFUND}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            帳戶退款
                        </CupoySrefView>
                    }
                    middleComponent="退款申請記錄"
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        refundApplications: state.member.refundApplications,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberRefundLogsView);