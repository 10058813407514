import {
    useDispatch,
} from 'react-redux';
import { bindActionCreators } from 'redux';

/**
 * useActions
 * @param {object} actions 
 */
const useActions = actions => {
    
    const dispatch = useDispatch();

    if (actions === null || Object.is(actions, undefined)) {
        return dispatch;
    } 
    
    const actionsKeys = Object.keys(actions);

    const boundActions = actionsKeys.reduce((acc, actionsKey) => {
        acc[actionsKey] = bindActionCreators(actions[actionsKey], dispatch);
        return acc;
    }, {});
    
    return boundActions;
};

export default useActions;