/*=====================================
    CupoyListRepeat

    Author: Gray
    createtime: 2018 / 02 / 23 (copy from cupoy)
=====================================*/

import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {
    List,
    WindowScroller,
    InfiniteLoader,
    CellMeasurer,
    CellMeasurerCache,
    AutoSizer
} from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once
import throttle from 'lodash/throttle';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import actionCreators from 'actions/creators';
import PreventPageScrollingScrollbars from './PreventPageScrollingScrollbars';

const SCROLL_DIRECTION_BACKWARD = -1;
const SCROLL_DIRECTION_FORWARD = 1;
const SCROLL_POSITION_CHANGE_REASON_OBSERVED = 'observed';
const cellMeasurerCacheMap = new Map();

class CupoyListRepeat extends PureComponent {
    static propTypes = {
        listId: PropTypes.string.isRequired,
        rowRenderer: PropTypes.func.isRequired,
        restoreWindowScroll: PropTypes.bool,
        useWindowScroll: PropTypes.bool,
        items: PropTypes.array.isRequired,
        scrollTarget: PropTypes.any,
        headerRenderer: PropTypes.func,
        elementScrollerHeight: PropTypes.number,
    };
    constructor(props) {
        super(props);
        this.state = {
            isScrolled: false,
        };
        this.rowRenderer = this
            ._rowRenderer
            .bind(this);
        this.isRowLoaded = this
            ._isRowLoaded
            .bind(this);
        this.loadMoreRows = this
            ._loadMoreRows
            .bind(this);
        this.onScroll = throttle(this._onScroll.bind(this), 500);
        this.handleScroll = throttle(this.handleScroll.bind(this), 100);
        this.registerListRef = this
            ._registerListRef
            .bind(this);
        this.onRowsRendered = this
            ._onRowsRendered
            .bind(this);
        this.renderWindowScroller = this
            .renderWindowScroller
            .bind(this);
        this.renderElementScroller = this
            .renderElementScroller
            .bind(this);
        this.clearMeasureCache = this
            .clearMeasureCache
            .bind(this);

        if (cellMeasurerCacheMap.has(this.props.listId)) {
            // console.log('list measure cache exist, using old cache ', this.props.listId);
            this.cache = cellMeasurerCacheMap.get(this.props.listId);
            
        } else {
            // create new cache
            this.cache = new CellMeasurerCache({defaultHeight: 300, fixedWidth: true});
            cellMeasurerCacheMap.set(this.props.listId, this.cache);
        }
    }

    forceUpdate() {
        // console.log('CupoyListRepeat forceUpdate()');
        this
            .listRef
            .forceUpdateGrid();
    }

    recomputeRowHeights(startIdx = 0) {

        // console.log('CupoyListRepeat recomputeRowHeights()');
        this.cache && this.cache.clearAll();
        this.listRef && this.listRef.recomputeRowHeights(startIdx);
    }
    scrollToIndex(index) {

        this.listRef && this.listRef.scrollToRow(index);
    }

    clearMeasureCache(index) {

        this.cache && this.cache.clearAll();
        this.listRef && this.listRef.recomputeRowHeights(index);
    }

    _rowRenderer(
        {
            key, // Unique key within array of rows
            index, // Index of row within collection
            isScrolling, // The List is currently being scrolled
            isVisible, // This row is visible within the List (eg it is not an overscanned row)
            style, // Style object to be applied to row (to position it),
            parent
        },
        listWidth, listHeight
    ) {
        const hasHeaderRenderer = !!this.props.headerRenderer;
        const isHeader = index === 0 && hasHeaderRenderer;

        // const item = hasHeaderRenderer
        //     ? this.props.items[index - 1]
        //     : this.props.items[index];

        return (
            <CellMeasurer
                cache={this.cache}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                {({measure}) => {

                    let cell = null;
                    if(isHeader) {

                        cell = this
                                .props
                                .headerRenderer({
                                    key,
                                    index,
                                    isScrolling,
                                    isVisible,
                                    style,
                                    parent,
                                    measure,
                                    updateRowSize: () => {
                                        this.clearMeasureCache(index);
                                    },
                                    measureCache: this.cache,
                                    listWidth,
                                    listHeight,
                                });

                    } else {

                        cell = this
                                .props
                                .rowRenderer({
                                    key,
                                    index,
                                    isScrolling,
                                    isVisible,
                                    style,
                                    parent,
                                    measure,
                                    updateRowSize: () => {
                                        this.clearMeasureCache(index);
                                    },
                                    measureCache: this.cache,
                                    listWidth,
                                    listHeight,
                                });

                    }

                    return cell;
                                            
                }}
            </CellMeasurer>
        );
    }
    _isRowLoaded({index}) {
        return !!this.props.items[index];
    }
    _loadMoreRows({startIndex, stopIndex}) {
        if (!this.props.isLoading && this.props.loadMoreRows) {
            return this.props.loadMoreRows(startIndex, stopIndex);
        }
    }
    _onRowsRendered(event, onRowsRendered) {
        
        if (this.props.restoreWindowScroll && this.props.lastRenderedIndex[this.props.listId] && !this.state.isScrolled &&
        // 已render過，或是render起點為0，都要做scroll微調
        (this.renderedIndex !== undefined || this.props.lastRenderedIndex[this.props.listId].startIndex === 0)) {
            // console.log('CupoyListRepeat _onRowsRendered()2');
            
            this.setState({
                isScrolled: true
            }, () => {
                setTimeout(() => {
                    window.scrollTo(0, this.state.initWindowScroll);
                }, 10);
            });
        }
        if(this.props.restoreWindowScroll){
            this.renderedIndex = event;
        }

        if(this.props.onAllRowRendered && 
            (event.overscanStopIndex - event.overscanStartIndex) === this.props.items.length-1 ){
            // 通知所有row一口氣繪製完成
            this.props.onAllRowRendered();
        }
        
        onRowsRendered(event);
    }

    updateWindowScrollerPosition(){
        if(this.windowScrollerRef)
            this.windowScrollerRef.updatePosition();
    }

    componentWillUnmount() {
        // console.log('unmount')
        this.props.restoreWindowScroll && this
            .props
            .webActions
            .storeRenderedIndex(this.props.listId, this.renderedIndex);
    }

    _onScroll(event, onChildScroll) {
        // console.log('_onScroll');
        
        this.props.restoreWindowScroll && this
            .props
            .webActions
            .storeScrollPosition(this.props.listId, window.scrollY);
    }

    _registerListRef(ref, otherRegisters) {
        this.listRef = ref;
        otherRegisters && otherRegisters(ref);
    }

    componentDidMount() {
        if (this.props.restoreWindowScroll && this.props.lastRenderedIndex[this.props.listId]) {
            // debugger;
            this
                .listRef
                .scrollToRow(this.props.lastRenderedIndex[this.props.listId].startIndex);
        }

        if (this.props.restoreWindowScroll && this.props.lastScrollTop[this.props.listId]) {
            this.setState({
                initWindowScroll: this.props.lastScrollTop[this.props.listId]
            });
        }

        if (!this.props.restoreWindowScroll && this.props.scrollTarget === window) {
            // console.log('scroll to top'); debugger;
            window.scrollTo(0, 0);
        }
    }

    

    render() {
        if (this.props.useWindowScroll) {
            return this.renderWindowScroller();
        } else {
            return this.renderElementScroller();
        }
    }

    renderWindowScroller() {
        return (
            <WindowScroller
                ref={ref=> this.windowScrollerRef = ref}
                onScroll={this.onScroll}
                scrollingResetTimeInterval={150}>
                {({height, isScrolling, onChildScroll, scrollTop}) => (
                    <AutoSizer disableHeight
                        onResize={({height, width})=>{
                            // clear cache 
                            this.cache.clearAll();
                        }}
                    >
                        {({width}) => (
                            <InfiniteLoader
                                isRowLoaded={this.isRowLoaded}
                                loadMoreRows={this.loadMoreRows}
                                rowCount={Array.isArray(this.props.items)
                                    ? this.props.items.length * 1.5
                                    : 0}
                                minimumBatchSize={20}
                                threshold={10}>
                                {({onRowsRendered, registerChild}) => (
                                    <List
                                        autoHeight
                                        height={height}
                                        width={width}
                                        scrollToAlignment="start"
                                        ref={ref => this.registerListRef(ref, registerChild)}
                                        isScrolling={isScrolling}
                                        onScroll={onChildScroll}
                                        scrollTop={scrollTop}
                                        rowCount={Array.isArray(this.props.items)
                                            ? this.props.items.length
                                            : 0}
                                        deferredMeasurementCache={this.cache}
                                        rowHeight={this.cache.rowHeight}
                                        rowRenderer={(event) => this.rowRenderer(event, width, height)}
                                        noRowsRenderer={this.props.noRowsRenderer}
                                        overscanRowCount={10}
                                        onRowsRendered={event => this.onRowsRendered(event, onRowsRendered)}
                                    />
                                )}
                            </InfiniteLoader>
                        )}
                    </AutoSizer>
                )}
            </WindowScroller>
        );
    }

    _getScrollDirection(scrollTop) {
        const {scrollTop: oldScrollTop, scrollDirectionVertical} = this.listRef.Grid.state;

        if (scrollTop !== oldScrollTop) {
            return scrollTop > oldScrollTop
                ? SCROLL_DIRECTION_FORWARD
                : SCROLL_DIRECTION_BACKWARD;
        }

        return scrollDirectionVertical;
    }
    handleScroll(event) {
        // console.log('handleScroll');
        const {target} = event;
        const {scrollTop, scrollLeft} = target;

        const {Grid: grid} = this.listRef;

        grid._debounceScrollEnded();

        const scrollDirectionVertical = this._getScrollDirection(scrollTop);
        const totalColumnsWidth = grid
            ._columnSizeAndPositionManager
            .getTotalSize();
        const totalRowsHeight = grid
            ._rowSizeAndPositionManager
            .getTotalSize();

        grid.setState({isScrolling: true, scrollDirectionVertical, scrollTop, scrollPositionChangeReason: SCROLL_POSITION_CHANGE_REASON_OBSERVED});

        grid._invokeOnScrollMemoizer({scrollLeft, scrollTop, totalColumnsWidth, totalRowsHeight});
    }

    renderElementScroller() {

        var elementScrollStyle = {};

        if(this.props.elementScrollerHeight && this.props.elementScrollerHeight > 0) {
            elementScrollStyle.height = this.props.elementScrollerHeight;
        }

        return (
            <PreventPageScrollingScrollbars
                autoHide={true}
                hideTracksWhenNotNeeded={true}
                onScroll={this.handleScroll}
                style={elementScrollStyle}
                >
                <AutoSizer
                    onResize={({height, width})=>{
                        // clear cache 
                        this.cache.clearAll();
                    }}                
                >
                    {({height, width}) => (
                        <InfiniteLoader
                            isRowLoaded={this.isRowLoaded}
                            loadMoreRows={this.loadMoreRows}
                            rowCount={this.props.items
                                ? this.props.items.length * 2
                                : 0}
                            minimumBatchSize={20}
                            threshold={10}>
                            {({onRowsRendered, registerChild}) => (
                                <List
                                    height={height}
                                    width={width}
                                    scrollToAlignment="start"
                                    ref={ref => this.registerListRef(ref, registerChild)}
                                    rowCount={this.props.items
                                        ? this.props.items.length
                                        : 0}
                                    deferredMeasurementCache={this.cache}
                                    rowHeight={this.cache.rowHeight}
                                    rowRenderer={(event) => this.rowRenderer(event, width, height)}
                                    onRowsRendered={event => this.onRowsRendered(event, onRowsRendered)}
                                    noRowsRenderer={this.props.noRowsRenderer}
                                    overscanRowCount={10}
                                />
                            )}
                        </InfiniteLoader>
                    )}
                </AutoSizer>
            </PreventPageScrollingScrollbars>
        );
    }
}

const mapStateToProps = function (state) {
    return {lastScrollTop: state.web.scrollPosition, lastRenderedIndex: state.web.renderedIndex};
};

const mapActionToProps = function (dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch)
    };
};

export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true,
    pure: true,
    areStatesEqual: ()=> {
        // console.log('areStatesEqual')
        return true
    },
    areStatePropsEqual: () => {
        console.log('areStatePropsEqual')
        return true;
    }

})(CupoyListRepeat);
