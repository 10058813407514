/*=====================================
    虛寶市集

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle } from 'stylecomponents';
import styled from 'styled-components';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";


/*--------------------------
    Styled Component
--------------------------*/

const TransactionList = styled.div`

    display: flex;
    justify-content: center;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #EEEEEE, inset 0 1px 0 0 #EEEEEE;
`;

const TransactionInfo = styled.div`

    width: 100%;

`;

const TransactionName = styled.div`

    padding-bottom: 8px;
    font-size: 16px;
    color: #4A4A4A;

`;

const ExchangeRate = styled.div`

    display: inline-block;
    font-size: 13px;
    color: #565151;

`;

const RateNumber = styled.span`

    margin-left: 8px;
    color: #F75C2F;
    font-weight: bolder;
    font-size: 16px;

`;

const SellQuantityList = styled.div`

    display: flex;
    justify-content: space-between;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #EEEEEE;

`;

const SellTitle = styled.div`

    font-size: 14px;
    color: #333333;

`;

const SellConversion = styled.div`

    font-size: 12px;
    color: #969494;

`;

const SellConversionNumber = styled.span`

    padding-left: 4px;

`;

const ChooseQuantityBox = styled.div`

    display: flex;
    height: 37px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 2px 0 0 2px;

`;

const QuantityPlus = styled.div`

    width: 24px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-left: 1px solid #CCCCCC;
    cursor: pointer;

`;

const QuantityMinus = styled(QuantityPlus)`

    border-right: 1px solid #CCCCCC;
    border-left: 0;
`;

const Quantity = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    padding: 10px 11px;
    font-size: 14px;
    color: #333333;

`;

const FromWrapper = styled.div`

    padding: 16px;
    background-color: #fff;
    font-size: 12px;
    color: #232423;
    
`;

const FormInput = styled.input`

    &::placeholder{
        opacity: 0.5;
        font-size: 15px;
        color: #222328;
    }
    margin-top: 8px;
    margin-bottom: 16px;
    padding: 12px;
    width: 100%;
    height: 44px;
    background: #FAFAFA;
    border: 1px solid #DDDDDD;
    border-radius: 2px;

`;

const FormSelector = styled.select`

    font-size: 15px;
    color: rgba(34,35,40,0.5);
    margin-top: 8px;
    margin-bottom: 16px;
    padding: 12px;
    width: 100%;
    height: 44px;
    background: #FAFAFA;
    border: 1px solid #DDDDDD;
    border-radius: 2px;

`;

const NarrativeTextarea = styled.textarea.attrs({

    placeholder: '請注意！伺服器內角色所填寫資料,如與實際交易角色ID不同,供應商將取消該筆訂單'
})`
    &::placeholder{
        opacity: 0.5;
        font-size: 15px;
        color: #222328;
    }
    margin-top: 8px;
    padding: 12px;
    width: 100%;
    height: 86px;
    background: #FAFAFA;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    
`;

const BankFormWrapper = styled.div`

    padding: 16px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;

`;

const BankFormTitle = styled.div`

    margin-bottom: 16px;
    padding-bottom: 16px;
    font-size: 20px;
    color: #333333;
    border-bottom: 1px solid #DDDDDD;
    
`;

const BackFormItem = styled.div``;

const EvaluationTitle = styled.div`

    display: flex;
    align-items: center;
    margin-top: 16px;
    padding-bottom: 16px;
    font-size: 20px;
    color: #333333;
    border-bottom: 1px solid #DDDDDD;

`;

const EvaluationComments = styled.span`

    padding-left: 12px;
    font-size: 12px;
    color: #595A59;

`;

const ChooseEvaluation = styled.div`

    display: flex;
    justify-content: space-around;
    padding-top: 16px;
    padding-bottom: 16px;

`;

const ChooseItem = styled.div`

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 161px;
    height: 44px;
    border: ${props => props.active ? '1px solid #F75C2F' : '1px solid #CCCCCC'};
    border-radius: 3px;

`;

const CheckWrapper = styled.div`

    display: ${props => props.active ? 'block' : 'none'};
    position: absolute;
    top: -4px;
    right: 0;
    font-size: 24px;
    color: #F75C2F;
    border-radius: 0 3px 0 3px;

`;

const AgreeTitle = styled.div`

    margin-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #DDDDDD;
    font-size: 20px;
    color: #333333;

`;

const AgreeBlock = styled.div`

    display: flex;
    align-items: center;
    height: 112px;
    margin-top: 16px;
    padding: 16px 16px 16px 12px;
    background: #FAFAFA;
    border: 1px solid #D6D6D6;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.10);
    border-radius: 3px;
    cursor: pointer;

`;

const AgreeCheckbox = styled.div`

    min-width: 28px;
    height: 28px;
    margin-right: 12px;
    background: #FFFFFF;
    border: 2px solid #C1C1C1;
    border-radius: 2px;

`;

const AgreeText = styled.div`

    font-size: 14px;
    color: #000000;

`;


const SendOrder = styled.div`

    height: 44px;
    margin-top: 32px;
    line-height: 44px;
    text-align: center;
    background: #F75C2F;
    border-radius: 2px;
    font-size: 16px;
    color: #FFFFFF;

`;

/*--------------------------
    Main Component
--------------------------*/
class SellFormView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    // constructor(props) {

    //     super(props);
    // }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.SELLDETAIL}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            商品詳情
                        </CupoySrefView>
                    }
                    middleComponent="滿貫大亨"
                />
                <TransactionList>
                    <TransactionInfo>
                        <TransactionName>遊戲暱稱：滿貫金流</TransactionName>
                        <div>
                            <ExchangeRate>
                                比例(台幣：虛幣)
                                <RateNumber>1000:1,500</RateNumber>
                            </ExchangeRate>
                        </div>
                    </TransactionInfo>
                </TransactionList>
                <SellQuantityList>
                    <div>
                        <SellTitle>出售金額（台幣）</SellTitle>
                        <SellConversion>
                            換算為虛幣金額
                            <SellConversionNumber>1500</SellConversionNumber>
                        </SellConversion>
                    </div>
                    <ChooseQuantityBox>
                        <QuantityMinus>-</QuantityMinus>
                        <Quantity>1000</Quantity>
                        <QuantityPlus>+</QuantityPlus>
                    </ChooseQuantityBox>
                </SellQuantityList>
                <FromWrapper>
                    伺服器內角色
                    <FormInput />
                    交易過程詳述
                    <NarrativeTextarea />
                </FromWrapper>
                <BankFormWrapper>
                    <BankFormTitle>轉入銀行</BankFormTitle>
                    <BackFormItem>
                        銀行代碼
                        <FormInput placeholder="請輸入銀行代碼7碼" />
                    </BackFormItem>
                    <BackFormItem>
                        銀行總稱
                        <FormSelector>
                        <option>銀行別</option>
                        </FormSelector>
                    </BackFormItem>
                    <BackFormItem>
                        分行名稱
                        <FormSelector>
                        <option>分行別</option>
                        </FormSelector>
                    </BackFormItem>
                    <BackFormItem>
                        銀行帳號
                        <FormInput 
                            type="number"
                            maxLength="14"
                            min="0"
                            max="99999999999999"
                            placeholder="最多14碼"
                        />
                    </BackFormItem>
                    <EvaluationTitle>
                        評價
                        <EvaluationComments>
                            (若買方48小時內未付款取消交易時，自動給予評價)
                        </EvaluationComments>
                    </EvaluationTitle>
                    <ChooseEvaluation>
                        <ChooseItem active={true}>
                            中立評價
                            <CheckWrapper active={true}>
                                <i className="zmdi zmdi-check-square"></i>
                            </CheckWrapper>
                        </ChooseItem>
                        <ChooseItem>
                            負面評價
                            <CheckWrapper>
                                <i className="zmdi zmdi-check-square"></i>
                            </CheckWrapper>
                        </ChooseItem>
                    </ChooseEvaluation>
                    <AgreeTitle>注意事項</AgreeTitle>
                    <AgreeBlock>
                        <AgreeCheckbox></AgreeCheckbox>
                        <AgreeText>
                            我同意：9199僅提供雙方聯繫服務，下單後買家將與您聯繫交易，雙方交易商品、金額流程均與9199無任何關聯，請雙方確實進行誠信交易！
                        </AgreeText>
                    </AgreeBlock>
                    <SendOrder>送出訂單</SendOrder>
                </BankFormWrapper>
            </GlobalStyle.Container>
        );
    }
}




/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(SellFormView);