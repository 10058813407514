/*=====================================
    忘記密碼

    Author: Eileen Liao
    createtime: 2018 / 02 / 28
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle,MemberStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import GLOBAL from '../../nonnmwebglobal';

/*--------------------------
    Style Component
--------------------------*/


/*--------------------------
    Main Component
--------------------------*/
class ForgetPwdView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);
        this.state = {
            email : '',
        };
        this.handleIDChange = this.handleIDChange.bind(this);
        this.sendVerifyMail =  this.sendVerifyMail.bind(this);

    }
    sendVerifyMail(){
        if(GLOBAL.isEmpty(this.state.email)){
            this.props.webActions.openAlertDialog({                    
                title: '請輸入電子郵件信箱',
                closeName: '確定',
                closeCallback: () => {
                    this.emailInput.focus();
                }


            });
            
            return;
        }
        this.props.memberActions.forgetPassword({email : this.state.email}).then(
            (result) => {
                if(result) {
                    this.props.webActions.openAlertDialog({
                        title: '重設密碼申請成功',
                        description: '密碼重設定信件已寄出，請儘速至您的信箱確認。',
                        closeCallback: () => {
                            this.props.webActions.cupoyStateGo(CupoyRouter.STATES.HOME);
                        }
                                
                    })
                }
            },(error) =>{
                if(error && error.code) {
                    var errorMsg = '';
                    switch(error.code) {
                        case 'error.account.accountNotExist':
                            errorMsg = "此帳號不存在";
                            break;
                        default:
                            errorMsg = error.message;
                            break;
                    }
                    this.props.webActions.openAlertDialog({                    
                        title: '錯誤訊息',
                        description : errorMsg,
                        closeName: '確定',
                        closeCallback: () => {
                            this.emailInput.focus();
                        }
                    });

                }
            }
        )
        
    }
    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

    }
    handleIDChange(event){
        this.setState({email:event.target.value});

    }


    // ------------------------------
    // render
    // ------------------------------
    render() {

        return (
            <GlobalStyle.Container  hasFixedTopbar={true} hasFixedTabbar={false}>
            
            <TopbarView
            isFixed={true}
            leftComponent={
                <CupoySrefView 
                    statename={CupoyRouter.STATES.MEMBERLOGIN}
                    stylecomponent={GlobalStyle.TopbarButton}
                >   
                    <GlobalStyle.TopbarIcon>
                        <i className="zmdi zmdi-chevron-left"></i>
                    </GlobalStyle.TopbarIcon>
                    會員登入
                </CupoySrefView>
            }
            middleComponent="忘記密碼"
        />
            <MemberStyle.MemberContent>
                <MemberStyle.MemberDescription margin="0">輸入登入使用的信箱，系統將會發送一封密碼重設定信件，幫助您重新設定您的密碼！</MemberStyle.MemberDescription>
                <MemberStyle.MemberInputBox placeholder="請輸入電子郵件信箱" type="text"   ref={(input) => { this.emailInput = input; }} onChange={this.handleIDChange} />
                <MemberStyle.ConfirmBtn onClick={this.sendVerifyMail}>重設密碼信件</MemberStyle.ConfirmBtn>
            </MemberStyle.MemberContent>
         </GlobalStyle.Container>
            
        );
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(ForgetPwdView);