
// 身分證領補換類別代碼
const ID_CARD_STATUS = {
    '領證': 1,
    '補證': 2,
    '換證': 3,
};

// 行政區代碼
const DISTRICT_CODE = {
    '福建省連江縣': '09007',
    '福建省金門縣': '09020',
    '臺灣省臺北縣': '10001',
    '臺灣省宜蘭縣': '10002',
    '臺灣省桃園縣': '10003',
    '臺灣省新竹縣': '10004',
    '臺灣省苗栗縣': '10005',
    '臺灣省臺中縣': '10006',
    '臺灣省彰化縣': '10007',
    '臺灣省南投縣': '10008',
    '臺灣省雲林縣': '10009',
    '臺灣省嘉義縣': '10010',
    '臺灣省臺南縣': '10011',
    '臺灣省高雄縣': '10012',
    '臺灣省屏東縣': '10013',
    '臺灣省臺東縣': '10014',
    '臺灣省花蓮縣': '10015',
    '臺灣省澎湖縣': '10016',
    '臺灣省基隆市': '10017',
    '臺灣省新竹市': '10018',
    '臺灣省臺中市': '10019',
    '臺灣省嘉義市': '10020',
    '臺灣省臺南市': '10021',
    '臺北市': '63000',
    '高雄市': '64000',
    '新北市': '65000',
    '臺中市': '66000',
    '臺南市': '67000',
    '桃園市': '68000',
};

export default {
    ID_CARD_STATUS, // 身分證領補換類別代碼
    DISTRICT_CODE, // 行政區代碼
};