/*=====================================
    MailIdentify

    Author: Gray
    CreateTime: 2018 / 03 / 20
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";

/*--------------------------
    Styled
--------------------------*/

/*--------------------------
    Main Component
--------------------------*/
class MailIdentifyView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            isLoading: true,
        };
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        const current = CupoyRouter.getCurrent();

        const {
            state,
            params,
        } = current;

        const {
            emailauth,
            mrscid,
        } = params;

        if(!emailauth || !mrscid) {
            this.errorCallback({
                code: 'WRONG URL',
                message: '請確認網址是否正確。',
            });
            return;
        }

        if(state.name === CupoyRouter.STATES.MAIL_IDENTIFY) {
            this.props.registerActions.verifyRegisterEmail({
                emailauth: emailauth,
                mrscid: mrscid,
            }).then(result => {
                this.successCallback();
            }).catch(error => {
                this.errorCallback(error);
            });
        } else if(state.name === CupoyRouter.STATES.CHANGE_MAIL_IDENTIFY) {
            this.props.memberActions.verifyEmailAuth({
                emailauth: emailauth,
                mrscid: mrscid,
            }).then(result => {
                // 更新資料
                this.props.memberActions.getMemberAcntInfo();
                this.successCallback();
            }).catch(error => {
                this.errorCallback(error);
            });
        }
    }

    // ------------------------------
    // successCallback
    // ------------------------------
    successCallback() {
        
        this.setState({
            isLoading: false,
        }, () => {
            this.props.webActions.openAlertDialog({                    
                title: '認證成功',
                description: '認證成功 , 請重新登入',
                closeName: '關閉',
                closeCallback: () => {
                    this.props.webActions.cupoyStateGoOtherwise(true);
                }
            });
        })
    }

    // ------------------------------
    // errorCallback
    // ------------------------------
    errorCallback(error) {
        this.setState({
            isLoading: false,
        }, () => {

            const errorMsg = error && error.message
                ? error.message
                : '系統忙碌中, 請稍候再試';

            this.props.webActions.openAlertDialog({
                title: '認證失敗',
                description : errorMsg,
                closeName: '關閉',
                closeCallback: () => {
                    this.props.webActions.cupoyStateGoOtherwise(true);
                }
            });
        })
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            isLoading,
        } = this.state;

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.HOME}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            首頁
                        </CupoySrefView>
                    }
                    middleComponent="Email認證"
                />
                {isLoading ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle><GlobalStyle.Spin size="30px"/></GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>Email認證中</GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
        registerActions: bindActionCreators(actionCreators.registerActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MailIdentifyView);