/*=====================================
    MemberBonus

    Author: Gray
    CreateTime: 2018 / 03 / 12
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import styled from 'styled-components';
import { GlobalStyle, Colors, MemberStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/
const BonusInfoWrapper = styled.div`
    padding: 10px;
`; 
const BonusInfo = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 105px;
    border-radius: 8px;
    background-color: ${Colors.Orange};
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const BonusLabelIcon = styled.div`
    position: absolute;
    top: 50%;
    left: 24px;
    font-size: 32px;
    color: #fff;
    transform: translateY(-50%);
`;
const BonusInfoTitle = styled.div`
    font-size: 14px;
    color: #FFFFFF;
`;
const BonusInfoValue = styled.div`
    line-height: 48px;
    font-size: 48px;
    color: #FFFFFF;
`;
const BonusHelp = styled.div`
    display: block;
    text-align: center;
    margin-top: 30px;
    font-size: 13px;
    color: ${Colors.Orange};
    text-decoration: underline;
    cursor: pointer;
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberBonusView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {

        };
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props, true);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        this.init(this.props);
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props, isFirst) {

        if(isFirst) {
            // 取得會員首頁相關數據
            this.props.memberActions.getMemberPageMetrics();
        }
    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {
        
        return <NeedLoginBlock />;
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            memberinfo,
        } = this.props;

        return (
            <div>
                <BonusInfoWrapper>
                    <BonusInfo>
                        <BonusLabelIcon>
                            <i className="icon-coin"/>
                        </BonusLabelIcon>
                        <BonusInfoTitle>發財金餘額</BonusInfoTitle>
                        <BonusInfoValue>
                            {memberinfo.bonusremain ? memberinfo.bonusremain : 0}
                        </BonusInfoValue>
                    </BonusInfo>
                </BonusInfoWrapper>
                <CupoySrefView 
                    statename={CupoyRouter.STATES.MEMBER_BONUS_LOGS}
                >
                    <MemberStyle.RowItemContainer>
                        <MemberStyle.RowItemLeftContainer>
                            <MemberStyle.GrayIcon>
                                <i className="icon-help" style={{color:"#fff"}}/>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>發財金明細</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer>
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <CupoySrefView 
                    statename={CupoyRouter.STATES.MEMBER_BONUS_ORDER}
                >
                    <MemberStyle.RowItemContainer>
                        <MemberStyle.RowItemLeftContainer>
                            <MemberStyle.GrayIcon>
                                <i className="icon-help" style={{color:"#fff"}}/>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>發財金商品</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer>
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <CupoySrefView 
                    statename={CupoyRouter.STATES.MEMBER_BONUS_DEPOSIT}
                >
                    <MemberStyle.RowItemContainer>
                        <MemberStyle.RowItemLeftContainer>
                            <MemberStyle.GrayIcon>
                                <i className="icon-help" style={{color:"#fff"}}/>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>兌換發財金</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer>
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <CupoySrefView 
                    statename={CupoyRouter.STATES.BONUS_MANUAL}
                    stylecomponent={BonusHelp}
                >
                    如何取得發財金？
                </CupoySrefView>
            </div>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            返回
                        </CupoySrefView>
                    }
                    middleComponent="我的發財金"
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        memberinfo : state.member.memberinfo,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        memberActions : bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberBonusView);