/*=====================================
    TopbarView

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import PropTypes from 'prop-types';
import { GlobalStyle } from 'stylecomponents';

/*--------------------------
    Main Component
--------------------------*/
class TopbarView extends PureComponent {

    static propTypes = {
        isFixed: PropTypes.bool,
        leftComponent: PropTypes.node,
        middleComponent: PropTypes.node,
        rightComponent: PropTypes.node,
        isOnlyMiddle: PropTypes.bool,
    };

    // ------------------------------
    // render
    // ------------------------------
    render() {
        
        const leftComponent = this.props.leftComponent ? this.props.leftComponent : null;
        const middleComponent = this.props.middleComponent ? this.props.middleComponent : null;
        const rightComponent = this.props.rightComponent ? this.props.rightComponent : null;
        const isOnlyMiddle = this.props.isOnlyMiddle;

        return (
            <GlobalStyle.Topbar isFixed={this.props.isFixed}>
                <GlobalStyle.TopbarContent>
                    {!isOnlyMiddle ? <GlobalStyle.TopbarOtherPannel>{leftComponent}</GlobalStyle.TopbarOtherPannel> : null}
                    <GlobalStyle.TopbarMiddlePannel>{middleComponent}</GlobalStyle.TopbarMiddlePannel>
                    {!isOnlyMiddle ? <GlobalStyle.TopbarOtherPannel isRight>{rightComponent}</GlobalStyle.TopbarOtherPannel> : null}
                </GlobalStyle.TopbarContent>
            </GlobalStyle.Topbar>
        );
    }
}

/*--------------------------
    export
--------------------------*/
export default TopbarView;