/**
|--------------------------------------------------
| 免責聲明
| @author Ac
|--------------------------------------------------
*/
import React from 'react';
import CupoyRouter from 'components/commons/router/CupoyRouter';
import CupoySrefView from 'components/commons/router/CupoySrefView';
import TopbarView from "components/commons/TopbarView";
import {
  AboutStyle,
  GlobalStyle,
} from 'stylecomponents';

/**
|--------------------------------------------------
|  Variables
|--------------------------------------------------
*/
/**
|--------------------------------------------------
| Styled
|--------------------------------------------------
*/
/**
|--------------------------------------------------
| Main Component
|--------------------------------------------------
*/
function DisclaimerView () {
  return (
    <GlobalStyle.Container
      hasFixedTopbar
      hasFixedTabbar={false}
      backgroundColor='#f5f5f5'
    >
      <TopbarView
        isFixed
        leftComponent={
          <CupoySrefView
            statename={CupoyRouter.STATES.MEMBER}
            stylecomponent={GlobalStyle.TopbarButton}
          >
            <GlobalStyle.TopbarIcon>
              <i className="zmdi zmdi-chevron-left"></i>
            </GlobalStyle.TopbarIcon>
            返回
          </CupoySrefView>
        }
        middleComponent="免責聲明"
      />
      <AboutStyle.Container>
        <AboutStyle.Title>當您成為【9199交易久久】用戶後，您要明確瞭解並同意，下列情況時本網站毋需承擔任何責任： </AboutStyle.Title>
        <AboutStyle.List>
         {/* <AboutStyle.Row>
            本站為第三方交易服務平台，基於網路的性質，本站無法鑑別判斷交易方物品來源及歸屬權。<br/>
            敬請交易雙方事前辨明。本站不希望出現任何物品交易糾紛，如果出現交易歸屬權糾紛，<br/>
            請您直接與交易另一方聯絡解決。如交易一方違反法律規定而出現糾紛與及不良結果，<br/>
            由交易行爲人獨立承擔所有責任，【9199交易久久】概不負責也不承擔任何法律責任。
          </AboutStyle.Row>
          */}
          <AboutStyle.Row>
            您使用本服務之風險由您個人負擔。<br/>
            用戶同意使用【9199交易久久】各項服務系基於用戶的個人意願，並同意自負任何風險，<br/>
            包括因為自【9199交易久久】下載資料或圖片，<br/>
            或自【9199交易久久】服務中獲得之資料導致發生任何資源流失等結果。
          </AboutStyle.Row>
          <AboutStyle.Row>
            【9199交易久久】就各項服務，不負任何明示或默示之擔保責任。<br/>
            【9199交易久久】不保證各項服務之穩定、安全、無誤、及不中斷；<br/>
            用戶需承擔使用本服務之所有風險及可能發生之任何損害。
          </AboutStyle.Row>
          <AboutStyle.Row>
            若用戶在任何遊戲內之違法情事如複製道具、帳號被鎖定或關閉，均與【9199交易久久】無關，<br/>
            純屬用戶個人行為，當用戶願意並同意交易成功，包括已同意自負任何可能之風險。
          </AboutStyle.Row>
          <AboutStyle.Row>
            對於透過【9199交易久久】銷售之物件，【9199交易久久】對其交易過程及物件本身，<br/>
            均不負任何擔保責任。用戶瞭解您透過【9199交易久久】所購得之物件或服務，<br/>
            完全由物件或服務提供人負全責，若有任何瑕疵或擔保責任，均與【9199交易久久】無關。
          </AboutStyle.Row>
          <AboutStyle.Row>
            任何不正常之網路攻擊由於駭客攻擊、電腦病毒侵入或發作、<br/>
            因政府管制而造成的暫時性關閉等影響網路正常經營之不可抗力而造成的資料洩露、丟失、被盜用或被竄改等均與【9199交易久久】無關。
          </AboutStyle.Row>
          <AboutStyle.Row>
            經由與本網站連結至其他網站所造成之個人資料洩露及經由此管道而導致的任何法律爭議和後果均與【9199交易久久】無關。
          </AboutStyle.Row>
        </AboutStyle.List>
      </AboutStyle.Container>
    </GlobalStyle.Container>
  );
};

export default DisclaimerView;
