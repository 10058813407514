/*=====================================
    購物車

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle, StepBottomBar} from 'stylecomponents';
import styled from 'styled-components';
import TopbarView from 'components/commons/TopbarView';
import CupoyRouter from 'components/commons/router/CupoyRouter';
import CupoySrefView from 'components/commons/router/CupoySrefView';
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    styled Component
--------------------------*/

const CartList = styled.div`

    display: flex;
    align-items: center;
    padding: 16px;
    max-width: 100%;
    background: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #EEEEEE;

`;

const CardLinks = styled.div`

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    cursor: pointer;

`;

const CartInfo = styled.div`

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

`;

const CartName = styled.div`

    padding-bottom: 8px;
    font-size: 14px;
    color: #333333;

`;

const NewPrice = styled.span`

    font-size: 11px;
    color: #333;

`;

const StrongText = styled.span`

    font-weight: bolder;

`;

const MemberPrice = styled.span`

    padding-left: 5px;
    font-size: 14px;
    color: #333;

`;

const DeleteIcon = styled.div`
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 16px;
    color: #F75C2F;
    font-size: 22px;
    cursor: pointer;
`;
const CountWrapper = styled.div`
    
`;
const Count = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    font-size: 12px;
    color: #333;
`;
const Sum = styled.div`
    color: #F75C2F;
`;
/*--------------------------
    Main Component
--------------------------*/
class CartView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            productKeys: undefined,
            products: undefined,
            totalPrice: 0,
            isLoading: false,
            isEmpty: false,
            isError: false,
            error: null,
            isAdding: false,
        }
        this.initProducts = this.initProducts.bind(this);
        this.quantityUpdate = this.quantityUpdate.bind(this);
        this.quantityPlus = this.quantityPlus.bind(this);
        this.quantityMinus = this.quantityMinus.bind(this);
        this.renderCartList = this.renderCartList.bind(this);
        this.renderTotalPrice = this.renderTotalPrice.bind(this);
        this.getCartCount = this.getCartCount.bind(this);
        this.deleteCartItem = this.deleteCartItem.bind(this);
        this.addOrder = this.addOrder.bind(this);
        this.listCardCartItems = this.listCardCartItems.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        if(this.props.haslogin){
            const cartItems = this.props.cartItems;
            if(Array.isArray(cartItems) && cartItems.length === 0){
                this.listCardCartItems();
            } else {
                this.initProducts();
            }
        }
    }

    listCardCartItems () {

        if(this.state.isLoading) {
            return;
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.cardCartActions.listCardCartItems().then(()=>{
                this.setState({
                    isLoading: false,
                }, () => {
                    this.initProducts();
                })
            }).catch(error => {
                this.setState({
                    isLoading: false,
                    isError: true,
                    error,
                });
            });
        });
    }

    initProducts(){

        const { cartItems = [] } = this.props;
        let new_cartItems = {};

        const productKeys = cartItems.map(item => {

            const { productid, count } = item;
            const key = `${productid}-${count}`;

            new_cartItems = Object.assign({}, new_cartItems, {
                [key]: item,
            });

            return key;
        });
        
        this.setState({
            products: new_cartItems,
            productKeys,
            isEmpty: Array.isArray(cartItems) && cartItems.length === 0,
        });
        
    }

    quantityUpdate(e, ids){

        var products = this.state.products;
        var product = products[ids];

        var new_count = Number(e.target.value);
        var new_Products = Object.assign({}, products, {
            [ids]: {...product, count: new_count},
        })

        this.setState({
            products: new_Products,
        });
    }

    quantityPlus(ids){

        var products = this.state.products;
        var product = products[ids];
        
        if(product.count < 99){

            var new_count = Number(product.count) +1;
            var new_Products = Object.assign({}, products, {
                [ids]: {...product, count: new_count},
            })
            this.setState({
                products: new_Products,
            });

        }

    }

    quantityMinus(ids){
        
        var products = this.state.products;
        var product = products[ids];

        if(product.count > 1){

            var new_count = Number(product.count) -1;
            var new_Products = Object.assign({}, products, {
                [ids]: {...product, count: new_count},
            })
            
            this.setState({
                products: new_Products,
            });

        }
    }

    deleteCartItem(event, productid, createtime){
        this.props.cardCartActions.delCardFromCart({
            'productid': productid,
            'createtime': createtime,
        }).then((result)=>{
            this.initProducts();
        }, (error) => {
            var errorMsg = '發生無法預期的錯誤';
            var errorDescription = '';

            if(error && error.code) {
                switch(error.code) {
                    case 'error.privilege.noLogin':
                        return;
                    default:
                        errorMsg = error.message;
                        break;
                }
            }

            this.props.webActions.openAlertDialog({
                title: errorMsg,
                description: errorDescription,
            });
        });
    }

    addOrder(event, payload){

        const {
            products,
            productKeys,
            isAdding,
        } = this.state;
        
        if(
            !products ||
            (Array.isArray(productKeys) && productKeys.length === 0) ||
            isAdding
        ){
            return;
        }

        this.setState({
            isAdding: true,
        }, () => {
            this.props.cardCartActions.buildCartOrder().then(()=>{
                this.setState({
                    isAdding: false,
                }, () => {
                    this.props.webActions.cupoyStateGo(CupoyRouter.STATES.CARTCHECKOUT);
                });
            }, (error) => {

                this.setState({
                    isAdding: false,
                }, () => {
                    var errorMsg = '發生無法預期的錯誤';
                    var errorDescription = '';
        
                    if(error && error.code) {
                        switch(error.code) {
                            case 'error.privilege.noLogin':
                                return;
                            default:
                                errorMsg = error.message;
                                break;
                        }
                    }
        
                    this.props.webActions.openAlertDialog({
                        title: errorMsg,
                        description: errorDescription,
                    }); 
                });
            });  
        })
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            productKeys,
            isLoading,
            isAdding,
            error,
        } = this.state;

        const hasList = Array.isArray(productKeys) && productKeys.length > 0;

        return (
            <GlobalStyle.Container 
                hasFixedTopbar
                hasFixedTabbar
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.HOME}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            首頁
                        </CupoySrefView>
                    }
                    middleComponent={`購物車(${this.getCartCount() ? this.getCartCount() : 0})`}

                />
                {this.renderCartList()}

                {isLoading && (
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                )}

                {!this.props.haslogin && <NeedLoginBlock />}

                {this.state.isError && !hasList && (
                    error && error.code === 'error.privilege.noLogin'
                    ? <NeedLoginBlock />
                    : (
                        <GlobalStyle.ErrorContainer>
                            <GlobalStyle.ErrorContainerTitle>
                                發生錯誤

                            </GlobalStyle.ErrorContainerTitle>
                            <GlobalStyle.ErrorContainerDesription>
                                無法取得購物車列表，請重新整理後再嘗試。
                            </GlobalStyle.ErrorContainerDesription>
                        </GlobalStyle.ErrorContainer>
                    )
                )}

                {this.state.isEmpty && (
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            查無資料
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            您的購物車還未加入商品
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                )}   

                <StepBottomBar.StepBarWrapper hasFixedTabbar>
                    <StepBottomBar.StepBarText>
                        總價：
                        <StepBottomBar.StepBarPrice>
                            {this.renderTotalPrice()
                                ? this.renderTotalPrice()
                                : 0}
                        </StepBottomBar.StepBarPrice>
                    </StepBottomBar.StepBarText>
                    <StepBottomBar.StepBarButton
                        onClick={this.addOrder}
                        disable={!hasList || isAdding}
                    >
                        {isAdding && <GlobalStyle.Spin color='#fff' />}
                        前往結帳
                    </StepBottomBar.StepBarButton>
                </StepBottomBar.StepBarWrapper>
            </GlobalStyle.Container>
        );
    }

    getCartCount(){

        const {
            productKeys,
        } = this.state;

        if(!Array.isArray(productKeys) || productKeys.length === 0){
            return null;
        }
        
        return productKeys.length;
    }

    renderTotalPrice(){

        const {
            products,
            productKeys,
        } = this.state;

        if(!products){
            return null;
        }

        if(Array.isArray(productKeys) && productKeys.length === 0){
            return null;
        }

        const totalPrice = productKeys.reduce(function(prev, currentIds) {
            const product = products[currentIds];
            return prev + (product.count * product.memberprice);
        }, 0);
        
        return totalPrice;
    }

    renderCartList(){

        const selectList = [];

        for(let i=1;i <= 99; i++){
            selectList.push(i);
        }
        
        const {
            products,
            productKeys,
        } = this.state;
        
        if(!products){
            return null;
        }

        if(Array.isArray(productKeys) && productKeys.length === 0){
            return null;
        }

        return (
            <div>
                {productKeys.map(key => {

                    const {
                        productid,
                        productname,
                        memberprice,
                        count,
                        createtime,
                    } = products[key];

                    return (
                        <CartList key={key}>

                            <DeleteIcon
                                onClick={(event)=> this.deleteCartItem(event, productid, createtime)}
                            >
                                <i className="zmdi zmdi-delete"></i>
                            </DeleteIcon>

                            <CupoySrefView 
                                statename={CupoyRouter.STATES.CARDINFORMATION}
                                stylecomponent={CardLinks}
                                params={{
                                    id: productid,
                                }}
                            >
                                <CartInfo>
                                    <div>
                                        <CartName>{productname}</CartName>
                                        <NewPrice>
                                            會員價:
                                            <MemberPrice>
                                                <StrongText>{memberprice}元</StrongText>
                                            </MemberPrice>
                                        </NewPrice>
                                    </div>
                                    <div>
                                        <CountWrapper>
                                            <Count>
                                                數量:
                                                <StrongText>{count}</StrongText>
                                            </Count>
                                        </CountWrapper>
                                        <Sum>
                                            小計:
                                            <StrongText>{memberprice * count}元</StrongText>
                                        </Sum>
                                    </div>
                                </CartInfo>
                            </CupoySrefView>
                            {/* <ChooseQuantityBox>
                                <QuantityMinus onClick={this.quantityMinus.bind(this, ids)}>-</QuantityMinus>
                                <label htmlFor="QuantityUpdateModal">
                                    <Quantity>
                                        {count}
                                    <QuantityUpdateModal onChange={(event) => this.quantityUpdate(event, ids)}>
                                        {selectList.map((value, index) => {
                                            return <option key={value} value={value}>{value}</option>;
                                        })}
                                    </QuantityUpdateModal>
                                    </Quantity>
                                </label>
                                <QuantityPlus onClick={this.quantityPlus.bind(this, ids)}>+</QuantityPlus>
                            </ChooseQuantityBox> */}
                        </CartList>
                    )
                })}
            </div>
        )
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        cartItems: state.cardcart.cartItems,
        isloading: state.cardcart.isloading,
        error: state.cardcart.error,
        haslogin: state.member.haslogin,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        cardCartActions: bindActionCreators(actionCreators.cardCartActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(CartView);