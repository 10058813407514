/*=====================================
    重設Email Stage (寄驗證碼 and 驗證簡訊碼)

    Author: Eileen Liao
    createtime: 2018 / 03 / 02
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle,MemberStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import styled from 'styled-components';
import GLOBAL from 'nonnmwebglobal';
import Recaptcha from "react-grecaptcha";

/*--------------------------
    Style Component
--------------------------*/
const NoteDescription =  styled.div`
    display:flex;
    margin : 10px 0 0 0;
    height : 40px;
    width : 100%;
    font-size: 12px;
    color: #595A59;
    justify-content: left;
    align-items : center;
`;


/*--------------------------
    Main Component
--------------------------*/
const regexMobilephone =  /^09[0-9]{2}[0-9]{6}$/g;

class ResetMobileView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {
        super(props);

        const { memberinfo } = this.props;
        const { mobilephone, issmsauth } = memberinfo;

        this.state = {
            mobilephone: mobilephone && !issmsauth ? mobilephone : '',
            isShowChkPhoneBtn: mobilephone && !issmsauth ? false : true,
            isShowverifyZone : false,
            smsauth: '',
            disableChgPhone : false,
            checking: false,
            recaptchaResponse: '',
            lastSmsAuthTime: 0,
        }

        this.onChange = this.onChange.bind(this);
        this.smsCodeChange = this.smsCodeChange.bind(this);
        this.sendVerifycode = this.sendVerifycode.bind(this);
        this.verifySMSCode = this.verifySMSCode.bind(this);
        this.showError = this.showError.bind(this);

        this.smsAuthTimer = React.createRef();
    }

    componentDidUpdate() {

        const {
            lastSmsAuthTime,
        } = this.state;

        if (lastSmsAuthTime > 0) {
            if (!this.smsAuthTimer.current) {
                this.smsAuthTimer.current = setInterval(() => {
                    this.setState(prevState => ({
                        lastSmsAuthTime: prevState.lastSmsAuthTime - 1000,
                    }));
                }, 1000);
            }
        } else {
            if (this.smsAuthTimer.current) {
                clearTimeout(this.smsAuthTimer.current);
            }
        }
    }


    onChange(event) {
        this.setState({
            mobilephone: event.target.value,
            isShowChkPhoneBtn: true,
            isShowverifyZone : false,
        });
    }
    smsCodeChange(event){
        this.setState({
            smsauth: event.target.value,
        });

    }
    showError(message,focusObj){
        this.props.webActions.openAlertDialog({
            title: '錯誤訊息',
            description : message || '發生無法預期的錯誤，請重新整理稍後再試，或聯絡我們的客服人員',
            closeName: '關閉',
            closeCallback: focusObj ? () => {
                focusObj.focus();
            } : null,

        });
    }

    // ------------------------------
    // check 手機是否已註冊過
    // ------------------------------
    checkMobilePhone = () => {

        const { mobilephone = '', checking } = this.state;

        if(!mobilephone.trim() || checking)
            return;

        this.setState({ checking: true }, () => {
            this.props.registerActions.checkPhone({
                mobilephone: mobilephone,
            }).then((result)=>{
                // 手機可用
                let message = "手機已被使用，請重新填寫或洽客服人員";

                if(result){
                    this.setState({
                        isShowChkPhoneBtn: false,
                        checking: false,
                    });
                    message = "手機可使用";
                }

                this.props.webActions.openAlertDialog({
                    title: message,
                });
            }, () => {

                this.setState({ checking: false }, () => {
                    this.props.webActions.openAlertDialog({
                        title: "手機已被使用",
                    });
                })
            });
        })

    }

    sendVerifycode() {

        const {
            mobilephone,
            recaptchaResponse,
            lastSmsAuthTime,
        } = this.state;

        if (lastSmsAuthTime > 0) {
            return;
        }

        //check 手機號碼
        let phoneValid  = mobilephone.match(regexMobilephone);

        if (!phoneValid){
            this.showError('行動電話號碼輸入錯誤',this.phoneInput);
            return;
        }

        if(!GLOBAL.isAppFile && !recaptchaResponse) {
            this.showError('請勾選『我不是機器人』');
            return;
        }

        this.setState({
            isShowverifyZone : true,
            disableChgPhone : true,
        }, () => {
            this.props.memberActions.updMobilePhoneAndSendSmSAuth({
                mobilephone,
                passcode: recaptchaResponse,
            }).then(result => {

                this.setState({
                    recaptchaResponse: '',
                    lastSmsAuthTime: result
                        ? (result - Date.now()) + 120000
                        : 120,
                }, () => {

                    !GLOBAL.isAppFile && window.grecaptcha.reset();

                    this.props.webActions.openAlertDialog({
                        title: '簡訊驗證碼已發出',
                        closeName: '關閉',
                    });
                });
            }).catch(error => {

                this.setState(prevState => ({
                    disableChgPhone: false,
                    recaptchaResponse: '',
                    lastSmsAuthTime: error && error.lastCheckTime
                        ? (error.lastCheckTime - Date.now()) + 120000
                        : prevState.lastSmsAuthTime,
                }), () => {
                   
                    !GLOBAL.isAppFile && window.grecaptcha.reset();

                    const errorMessage = error && error.message
                        ? error.message
                        : '系統忙碌中, 請稍候再試';
                    
                    this.props.webActions.openAlertDialog({
                        title: "發生錯誤",
                        description: errorMessage,
                    });

                    this.showError(errorMessage, this.phoneInput);
                });
            });
        })
    }

    verifySMSCode(){

        const { smsauth } = this.state;

        if(!smsauth.trim()) return;

        this.props.memberActions
        .verifySmsAuth({mrscid:this.props.memberinfo.mrscid,smsauth:this.state.smsauth}).then(
            (result) => {
                // 更新資料
                this.props.memberActions.getMemberAcntInfo();
                this.props.webActions.openAlertDialog({
                    title: '手機已驗證成功',
                    closeName: '關閉',
                    closeCallback: () => {
                        this.props.webActions.cupoyStateGo(CupoyRouter.STATES.MEMBER_INFO);
                    }

                });

            },(error) => {
                this.showError(error.message,this.codeInput);

            }
        )
        .catch(err => {
            this.showError(err.message,this.codeInput);
        });
    }

    // ------------------------------
    // google callback
    // ------------------------------
    recaptchaVerifyCallback = response => {
        this.setState({
            recaptchaResponse: response,
        });
    }

    // ------------------------------
    // google expiredCallback
    // ------------------------------
    recaptchaExpiredCallback = () => {
        this.props.webActions.openAlertDialog({
            title: '安全認證已過期',
        });
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            checking,
            isShowChkPhoneBtn,
            mobilephone = '',
            smsauth = '',
            lastSmsAuthTime,
        } = this.state;

        return (
            <GlobalStyle.Container
                hasFixedTopbar
                hasFixedTabbar={false}
            >
                <TopbarView
                    isFixed
                    leftComponent={
                        <CupoySrefView
                            statename={CupoyRouter.STATES.MEMBER_INFO}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            個人資料
                        </CupoySrefView>
                    }
                    middleComponent="重設行動電話"
                />
                <MemberStyle.MemberContent>
                    <MemberStyle.MemberInputBox
                        placeholder="請輸入手機號碼"
                        type="text"
                        ref={input => { this.phoneInput = input; }}
                        onChange={!this.state.disableChgPhone ? this.onChange : null}
                        margin="0"
                        value={mobilephone}
                    />

                    {isShowChkPhoneBtn
                        ? (
                            <MemberStyle.ConfirmBtn
                                onClick={this.checkMobilePhone}
                                disable={!mobilephone.trim() || checking}
                            >
                                檢查號碼是否已使用
                            </MemberStyle.ConfirmBtn>
                        )
                        : (
                            <>
                                {!GLOBAL.isAppFile && (
                                    <Recaptcha
                                        sitekey={GLOBAL.grecaptcha_key}
                                        callback={this.recaptchaVerifyCallback}
                                        expiredCallback={this.recaptchaExpiredCallback}
                                        locale="zh-TW"
                                        className="g-recaptcha"
                                        style={{
                                            transform: 'scale(0.9)',
                                            WebkitTransform: 'scale(0.9)',
                                            transformOrigin: '0 0',
                                            WebkitTransformOrigin: '0 0',
                                            marginTop: 16,
                                            marginBottom: -5,
                                        }}
                                    />
                                )}

                                <MemberStyle.ConfirmBtn
                                    onClick={this.sendVerifycode}
                                    disable={lastSmsAuthTime > 0}
                                >
                                    {lastSmsAuthTime > 0
                                        ? <>重寄簡訊驗證碼{lastSmsAuthTime > 0 && ` (${Math.ceil(lastSmsAuthTime / 1000)}s)`}</>
                                        : '寄送驗證碼'
                                    }
                                </MemberStyle.ConfirmBtn>
                            </>
                        )
                    }

                    {!this.state.isShowChkPhoneBtn && (
                        <MemberStyle.FieldTitle>簡訊驗證碼</MemberStyle.FieldTitle>
                    )}

                    {!this.state.isShowChkPhoneBtn && (
                        <MemberStyle.InputContainer
                            flexdirection="row"
                            margin="0"
                        >
                            <MemberStyle.MemberInputBox2
                                type="text"
                                ref={(input) => { this.codeInput = input; }}
                                width="calc(100% - 100px)"
                                onChange={this.smsCodeChange}
                                value={smsauth}
                            />
                            <MemberStyle.ConfirmBtn
                                margin="8px 0 0 10px"
                                width="100px"
                                onClick={this.verifySMSCode}
                                disable={!smsauth.trim()}
                            >
                                確定
                            </MemberStyle.ConfirmBtn>
                        </MemberStyle.InputContainer>
                    )}
                    <NoteDescription>提示：點擊「發送驗證碼」後，我們會寄送驗證碼到您的手機。</NoteDescription>
                </MemberStyle.MemberContent>
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        memberinfo : state.member.memberinfo,
        haslogin : state.member.haslogin,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions : bindActionCreators(actionCreators.memberActionCreators, dispatch),
        registerActions : bindActionCreators(actionCreators.registerActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(ResetMobileView);