import {
    useRef,
    useState,
    useEffect,
    useCallback,
} from 'react';
import { createSelector } from 'reselect';
import GLOBAL from 'nonnmwebglobal';
import useRedux from 'actions/hooks/useRedux';
import actionCreators from 'actions/creators';
import useInput from 'components/hooks/useInput';
import usePrevious from 'components/hooks/usePrevious';

/**
 * Selector
 */
const selector = createSelector(
    state => state.member.memberinfo,
    memberinfo => memberinfo.memberid,
);

/**
 * 身分證驗證
 * @author Ac
 * @time 2020 / 11 / 13
 */
const useIDNumberVerifier = () => {

    const [memberid, {
        registerActions,
    }] = useRedux(selector, {
        registerActions: actionCreators.registerActionCreators,
    });

    const _isUnmount = useRef(false);

    const {
        value: idNumber,
        handler: handleIdNumber,
    } = useInput();

    const {
        value: applyCode,
        handler: handleApplyCode,
    } = useInput();

    const {
        value: applyYYY,
        handler: handleApplyYYY,
    } = useInput();

    const {
        value: applyMM,
        handler: handleApplyMM,
    } = useInput();

    const {
        value: applyDD,
        handler: handleApplyDD,
    } = useInput();

    const {
        value: issueSiteId,
        handler: handleIssueSiteId,
    } = useInput();

    const applyYyymmdd = `${applyYYY.trim()}${applyMM.trim()}${applyDD.trim()}`;

    const perv_idNumber = usePrevious(idNumber);
    const perv_applyCode = usePrevious(applyCode);
    const perv_applyYyymmdd = usePrevious(applyYyymmdd);
    const perv_issueSiteId = usePrevious(issueSiteId);

    const [verifyStatus, setVerifyStatus] = useState({
        passedId: null,
        isPass: false,
        isLoading: false,
        isError: false,
        error: null,
    });

    const isDisableVerify = verifyStatus.isLoading
        || !idNumber.trim()
        || !applyCode.trim()
        || !applyYyymmdd
        || !issueSiteId.trim();

    /**
     * 驗證身分證
     */
    const verifyIdNumber = useCallback(
        () => {

            if (isDisableVerify) {
                return;
            }
            
            setVerifyStatus({
                passedId: null,
                isPass: false,
                isLoading: true,
                isError: false,
                error: null,
            });

            const {
                result,
                message,
            } = GLOBAL.chkIdnumber(idNumber);

            if(!result) {
                setVerifyStatus(prev => ({
                    ...prev,
                    isLoading: false,
                    isError: true,
                    error: { message },
                }));
                return;
            }

            registerActions.verifyIdNumber({
                memberid,
                idNumber,
                applyCode,
                applyYyymmdd,
                issueSiteId,
            }).then(result => {

                if (_isUnmount.current) {
                    return;
                }

                if (!result) {
                    setVerifyStatus(prev => ({
                        ...prev,
                        isLoading: false,
                        isError: true,
                    }));
                    return;
                }

                const {
                    isPass,
                    resultMessage,
                } = result;

                setVerifyStatus({
                    isLoading: false,
                    isPass,
                    passedId: isPass ? idNumber : null,
                    isError: !isPass,
                    error: isPass ? null : { message: resultMessage },
                });
            }).catch(error => {

                if (_isUnmount.current) {
                    return;
                }

                setVerifyStatus(prev => ({
                    ...prev,
                    isLoading: false,
                    isError: true,
                    error,
                }));
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            memberid,
            idNumber,
            applyCode,
            applyYyymmdd,
            issueSiteId,
            isDisableVerify
        ]
    );

    useEffect(
        () => {
            if(
                verifyStatus.isPass && (
                    idNumber !== perv_idNumber
                    || applyCode !== perv_applyCode
                    || applyYyymmdd !== perv_applyYyymmdd
                    || issueSiteId !== perv_issueSiteId
                )
            ) {
                
                // 驗證成功後如輸入有變化就直接重置狀態
                setVerifyStatus({
                    passedId: null,
                    isPass: false,
                    isLoading: false,
                    isError: false,
                    error: null,
                });
            }
        },
        [
            verifyStatus.isPass,
            idNumber,
            applyCode,
            applyYyymmdd,
            issueSiteId,
            perv_idNumber,
            perv_applyCode,
            perv_applyYyymmdd,
            perv_issueSiteId
        ],
    );

    useEffect(() => {
        return () => {
            _isUnmount.current = true;
        }
    }, []);

    return {
        idNumber,
        handleIdNumber,
        applyCode,
        handleApplyCode,
        applyYYY,
        handleApplyYYY,
        applyMM,
        handleApplyMM,
        applyDD,
        handleApplyDD,
        issueSiteId,
        handleIssueSiteId,

        isDisableVerify, // 是否不可驗證 ( 資料沒輸入齊全 )
        verifyStatus, // 驗證狀態
        verifyIdNumber, // 驗證身分證
    };
};

export default useIDNumberVerifier;
