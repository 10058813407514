/*=====================================
    重設地址

    Author: Eileen Liao
    createtime: 2018 / 03 / 02
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import styled from 'styled-components';
import { GlobalStyle, MemberStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import GLOBAL from 'nonnmwebglobal';
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Style Component
--------------------------*/
const SelectWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 44px;
    margin: 0 0 0 12px;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    background-color: #FAFAFA;

    &:first-child {
        margin: 0;
    }
`;
const Select = styled.select`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 8px;
    border: none;
    background-color: #FAFAFA;
    font-size: 15px;
    color: #222328;
`;
const SubmitLoading = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    left: 16px;
    height: 100%;
    align-items: center;
    justify-content: center; 
`;
/*--------------------------
    Main Component
--------------------------*/
class ResetAddressView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {
        
        super(props);
        this.state = {
            city : GLOBAL.CITIES[0].value,
            region : "",
            zipcode : "" ,
            address : "",
            isError : false,
        }
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleZipcodeChange = this.handleZipcodeChange.bind(this);
        this.resetAddress = this.resetAddress.bind(this);
        
    }

    handleCityChange = (event) => {
        if(event) {
            this.setState({
                city: event.target.value,
                region: '',
            });
        }
    }

    handleRegionChange = (event) => {

        if(event){
            const value = event.target.value;
            const regions = GLOBAL.REGIONS[this.state.city];

            if(!regions) {
                return;
            }

            for(var i = 0; i < regions.length; i++) {
                var region = regions[i];

                if(region.value === value) {
                    break;
                }

                region = undefined;
            }

            if(!region) {
                return;
            }

            this.setState({
                zipcode : region.zipcode,
                region: region.value
            });
        }
    }

    handleAddressChange = (event) => {
        this.setState ({ address : event.target.value });
    }

    handleZipcodeChange= (event) => {
        this.setState ({ zipcode : +event.target.value });
    }
    

    resetAddress(){

        const {
            city = '',
            region = '',
            zipcode,
            address = '',
            isLoading,
        } = this.state;

        const { memberinfo } = this.props;

        if(!city.trim() || !region.trim() || !zipcode || !address.trim() || isLoading)
            return;

        const {
            membername,
            nickname,
            birthday,
            isdonate,
            npoban,
            carrierid,
            carriertype,
            invoicetype,
            crn,
            companytitle,
            shownametype,
            sex,
        } = memberinfo;

        this.setState({ isLoading: true }, () => {
            this.props.memberActions.updMember({
                city,
                region,
                postalcode: zipcode,
                addreGss: address,
                name: membername,
                sex,
                nickname,
                birthday,
                isdonate,
                npoban,
                carrierid,
                carriertype,
                invoicetype,
                crn,
                companytitle,
                shownametype,
            }).then((result) => {                
                this.setState({ isLoading: false }, () => {
                    if(result)
                    this.props.webActions.openAlertDialog({
                        title: '地址修改成功',
                        closeCallback: () => {
                            this.props.webActions.cupoyStateGo(CupoyRouter.STATES.MEMBER_INFO);
                        }
                    });
                });
            }).catch(() => { 
                this.setState({
                    isLoading: false,
                    isError: true,
                }, () => {
                    this.props.webActions.openAlertDialog({
                        title: '發生錯誤',
                        description: '無法成功修改住址，請稍後再嘗試。',
                    });
                });
            });
        });
    }
    
    init(props){

        if(props.memberinfo.address)
            this.setState(GLOBAL.parseAddress(props.memberinfo.address));
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        this.init(this.props);
    }
    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {
        return <NeedLoginBlock />;
    }

    

    // ------------------------------
    // render
    // ------------------------------
    render() {
        
        const {
            city = '',
            region = '',
            zipcode,
            address = '',
            isLoading,
        } = this.state;

        const cities = GLOBAL.CITIES;
        const regions = GLOBAL.REGIONS[city] || [];
        
        return (
            <GlobalStyle.Container  hasFixedTopbar={true}  hasFixedTabbar={false}>
            
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER_INFO}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            個人資料
                        </CupoySrefView>
                    }
                    middleComponent="重設住址"
                />

                <MemberStyle.MemberContent>

                <MemberStyle.InputContainer flexdirection="row" justify="space-between" margin="0" name="inputcontainer">
                    <SelectWrapper>
                        <Select
                            value={city}
                            onChange={this.handleCityChange}
                        >
                            <option value=''>縣市</option>
                            {cities.map((_city, index) => {
                                return (
                                    <option key={index} value={_city.value}>
                                        {_city.label}
                                    </option>
                                );
                            })}
                        </Select>
                    </SelectWrapper>
                    <SelectWrapper>
                        <Select
                            value={region}
                            onChange={this.handleRegionChange}
                        >
                            <option value=''>鄉鎮市區</option>
                            {regions.map((_region, index) => {
                                return (
                                    <option key={index} value={_region.value}>
                                        {_region.label}
                                    </option>
                                );
                            })}
                        </Select>
                    </SelectWrapper>
                    <MemberStyle.MemberInputBox id="postalcode" placeholder="郵遞區號" type="text" value={zipcode} width="90px" margin="0 0 0 12px" onChange={this.handleZipcodeChange} />

                </MemberStyle.InputContainer>

                <MemberStyle.MemberInputBox2 placeholder="地址" ref="address" type="text" width="100%"  onChange={this.handleAddressChange} value={address} />

                </MemberStyle.MemberContent>
                    <MemberStyle.BottomBar>
                        <MemberStyle.ConfirmBtn
                            margin="0"
                            onClick={this.resetAddress}
                            disable={
                                !city.trim() ||
                                !region.trim() ||
                                !zipcode ||
                                !address.trim() ||
                                isLoading
                            }
                        >
                            {
                                isLoading && (
                                    <SubmitLoading>
                                        <GlobalStyle.Spin color="#fff"/>
                                    </SubmitLoading>
                                )
                            }
                            完成
                        </MemberStyle.ConfirmBtn>
                    </MemberStyle.BottomBar>
            </GlobalStyle.Container>
        );
    }

}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        memberinfo : state.member.memberinfo,
        haslogin: state.member.haslogin,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(ResetAddressView);