import React, {
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import useActions from 'actions/hooks/useActions';
import actionCreators from 'actions/creators';

/**
 * 複製文字剪貼簿
 * @author Ac
 * @time 2020 / 04 / 24
 */
const CupoyClipboard = props => {

    const {
        text,
        children,
        copiedText,
        copiedCallback,
    } = props;

    const {
        webActions,
    } = useActions({
        webActions: actionCreators.webActionCreators,
    });

    /**
     * 複製
     */
    const copyToClipboard = useCallback(() => {

        let textArea;
      
        function isOS() {
            //can use a better detection logic here
            return navigator.userAgent.match(/ipad|iphone/i);
        }
      
        function createTextArea(text) {
            textArea = document.createElement('textArea');
            textArea.readOnly = true;
            textArea.contentEditable = true;
            textArea.value = text;
            document.body.appendChild(textArea);
        }
      
        function selectText() {

            let range, selection;
        
            if (isOS()) {
                range = document.createRange();
                range.selectNodeContents(textArea);
                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                textArea.setSelectionRange(0, 999999);
            } else {
                textArea.select();
            }
        }
      
        function copyTo() {
            document.execCommand('copy');
            document.body.removeChild(textArea);
            webActions.showSimpleAlertDialog(copiedText);
            copiedCallback && copiedCallback();
        }
      
        createTextArea(text);
        selectText();
        copyTo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text, copiedText, copiedCallback]);

    return React.cloneElement(children, { onClick: copyToClipboard });
};

CupoyClipboard.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired, // 欲複製的文字
    copiedText: PropTypes.string, // 複製成功後顯示的提醒文字
    copiedCallback: PropTypes.func, // 複製成功後的callback
};

CupoyClipboard.defaultProps = {
    copiedText: '文字已複製',
};

export default CupoyClipboard;