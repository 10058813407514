/*=====================================
    MemberSuspensionOfPowerView

    Author: Ac
    CreateTime: 2018 / 10 / 17
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, {
    useEffect,
} from 'react';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from 'components/commons/router/CupoyRouter';
import CupoySrefView from "components/commons/router/CupoySrefView";
import {
    HelpStyle,
    GlobalStyle,
} from 'stylecomponents';

const ITEMS = [
    '會員註冊使用非本人真實姓名（如：暱稱、英文、數字、他人姓名等）。',
    '會員註冊驗證的『手機電話』資料不實，非本人正常使用之手機電話。',
    '會員註冊驗證的『電子信箱』資料不實，非本人正常使用之電子信箱。',
    '會員註冊之『身分證字號』資料不實，非本人正常使用之身分證字號。',
    '其他註冊資料欄位填寫不雅文字或明顯虛假不實資料。',
    '要求供應商至其他網站交易或面交等私下交易行為產生。',
    '會員引發交易糾紛之相關行為。',
    '用不實或虛假交易行為對供應商進行信用評價製造。',
    '交易留言回覆或進行評價留言內有違反規定之內容。',
    '因政府相關檢調法務單位，要求資料提供、調查合作進行之停權。',
    '會員註冊帳號資料轉讓或租借予他人使用。',
];

/*--------------------------
    Main Component
--------------------------*/
const MemberSuspensionOfPowerView = props => {

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    return (
        <GlobalStyle.Container
            hasFixedTopbar
            hasFixedTabbar
            backgroundColor="#f5f0f0"
        >
            <TopbarView
                isFixed
                leftComponent={
                <CupoySrefView
                    statename={CupoyRouter.STATES.HELP}
                    stylecomponent={GlobalStyle.TopbarButton}
                >
                    <GlobalStyle.TopbarIcon>
                        <i className="zmdi zmdi-chevron-left"></i>
                    </GlobalStyle.TopbarIcon>
                    返回
                </CupoySrefView>
                }
                middleComponent="會員停權"
            />

            <HelpStyle.Container>
                <HelpStyle.Title>會員停權</HelpStyle.Title> 
        
                {ITEMS.map((item, index) => (
                    <HelpStyle.Section key={index}>
                        {`${index + 1}. ${item}`}
                    </HelpStyle.Section>
                ))}
                <HelpStyle.Section>
                12.禁止使用非本人資料註冊進行交易
                <HelpStyle.SubSection>
                    (1)	當您註冊並使用9199進行交易時，請確保提供的資訊其真實性與完整性。9199禁止任何人提供虛假資訊進行交易，如發現此類行為，9199將終止您的會員資格及使用『9199 交易久久』 之權利。
                    <HelpStyle.SubSection>
                        A.使用假名或虛假身份註冊多個會員帳號。<br/>
                        B.以自動化的方式進行大量註冊。<br/>
                        C.利用他人的身份註冊會員帳號。<br/>
                        D.利用不當手段繞過我們的安全機制進行大量註冊。<br/>
                        E.交易行為被判定異常。<br/>
                        F.登入行為被判定異常。<br/>
                    </HelpStyle.SubSection>
                </HelpStyle.SubSection>
                <HelpStyle.SubSection>
                (2)	申訴機制：如果您認為自己被誤判終止您的會員資格及使用『9199 交易久久』 之權利，您可以通過申訴機制進行申訴。申訴時會請提供您的「身分證正面」、「身分證背面」、「第二證件(含有個人資料頁面)」、「本人正臉與身分證正面合照」;若有交易記錄，除上述身份資料以外，另需提供購買繳費帳戶相關證明，至9199客服信箱「service@9199.com.tw」，9199會在最短時間內處理您的申訴並給予回覆。
                </HelpStyle.SubSection>
                <HelpStyle.SubSection>
                (3)	觀察名單：當您通過申訴機制，經核實後確認無誤，您的會員資格將會恢復。但您將被列為觀察名單，若再次被判定「交易異常行為」或「登入紀錄異常」，9199有權再次終止您的會員資格及使用『9199 交易久久』 之權利。
                </HelpStyle.SubSection>
            </HelpStyle.Section>
        
        
            </HelpStyle.Container>

        </GlobalStyle.Container>
    );
};

/*--------------------------
    Export
--------------------------*/
export default MemberSuspensionOfPowerView;