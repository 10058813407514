import React from 'react';
import CupoyRouter from 'components/commons/router/CupoyRouter';
import CupoySrefView from 'components/commons/router/CupoySrefView';
import TopbarView from "components/commons/TopbarView";
import {
  AboutStyle,
  GlobalStyle,
} from 'stylecomponents';

/**
|--------------------------------------------------
|  Variables
|--------------------------------------------------
*/
const DATA_LIST = [
  {
    title: '我們搜集的資訊',
    list: [
      '我們搜集個人資料之主要目的，在於為你提供順利的、有效率的及特定選項服務的體驗，這樣可以讓我們提供最符合你需要的服務及功能，並且規劃訂制我們的服務而使你的體驗更順利。',
      '通常，你可以瀏覽我們的網站而不用告訴我們你是誰或揭露任何個人的資料，然而一但你提供個人資料給我們，對我們而言你就不再是匿名人士。如果你選擇使用我們的服務，我們可能要求你提供聯絡及辨認資料、付款資料、裝運資料及我們網站的各類表格所要求提供的其他個人資料。我們會盡可能指出那些資料是必須提供的及那些資料是可依你選擇而提供的。你可以選擇不使用某項服務或功能而不必提供有關資訊，在某些情況下，例如，但不限於，你正在申請註冊使用一個免費電子郵件帳戶的服務，或申請註冊以便在我們的網站上進行買賣，我們便會要求進一步的財務資料。我們使用你的財務資料，包括你的銀行帳務資料來驗證你的姓名、地址及其它資料，同時向你收取使用服務費。',
      '我們會依據你在我們網站上的行為自動追蹤一些資料，我們使用這些資料對我們的會員人數統計、興趣及行為模式進行內部研究，以便更瞭解我們的會員，並且為你及我們的社群提供更好的服務。這些資料可能包括你剛來自於那個URL(不論這個URL是否在我們的網站上)，你接下來前往的URL(不論這個URL是否在我們的網站上)，目前使用何種瀏覽器，以及你的IP地址。',
      '我們在部份網頁使用像『cookies』的資料搜集裝置來幫助我們分析網頁的流動狀況、衡量促銷活動的效果，並增進信任度及安全性。『cookies』是一種儲存在你硬碟的小檔案，可以幫助我們提供特定選項的服務，我們提供只有經由使用『cookies』才能提供之一些功能，我們也使用『cookies』以允許你在某一階段內減少輸入密碼的次數，『cookies』也可以幫助我們提供符合你興趣的資料。大部分的cookies 是階段型『cookies』，意指他們會在階段終了後從你的硬碟中被自動刪除。如果你的瀏覽器允許，你永遠有權拒絕我們的『cookies』，雖然在那種情況下，你可能無法使用我們網站上一些特定功能，並且在某一階段內必須更頻繁的重新輸入密碼。',
      '此外，你可能會碰到由第三人在我們網站上某些網頁中存放的『cookies』或其他類似裝置，例如，如果你瀏覽一個由會員自行製作的網頁，該網頁可能就放有『cookies』，我們並不控制第三人對『cookies』的使用。',
      '如果你選擇在我們的網站上購買或出售物品行為，我們會搜集你的購買及出售物品行為資料，同時在我們的交易紀錄區內也會搜集相關訂單留言資料。',
      '如果你與我們開立一個虛擬信用帳戶以支付我們的服務費用，我們會搜集進一步的資料，包括付款位址、銀行帳戶號碼，並且根據支票或匯票追蹤一些資料。',
      '想知道我們向你搜集或所搜集到與你有關的詳細資料表以及誰有權取得該等資料，請參閱下列條文說明。',
    ],
  },
  {
    title: '我們對你個人資料的使用',
    list: [
      '我們使用你的資料以幫助我們提供你所要求的服務，我們使用為你建立之資料夾中的資料及我們從你現在或過去在網站上的行為所取得之其他資料，來解決爭議、檢修障礙問題及執行我們的會員合約。偶而，我們可能檢視多個會員以確認問題所在或解決爭議，且特別是我們可能對你的資料進行檢查以識別那些使用多個會員帳號或化名之會員。',
      '你同意我們使用你的個人辨認資料來加強我們的市場行銷及促銷、統計分析網站利用方式、加強我們提供的內容及産品，並且根據客戶的需求規劃訂制我們網站的內容、網頁設計及服務，這些使用改善並且調整我們的網站使其更符合你的需求。',
      '你同意我們可以使用你的資料與你聯絡並傳送那些可能符合你興趣的訊息給你，例如特定標題廣告、行政通知、産品內容及有關你使用網站的訊息。透過接受會員合約及隱私權政策，你明示同意收取此項訊息。',
    ],
  },
  {
    title: '我們對你個人資料的揭露',
    list: [
      '緣于現存的管制環境，我們無法保證所有你私人通訊及其它個人辨認資料永遠不會被以本隱私權政策所述以外之其他方式泄露。舉例而言(但不限以下所述)，在某些情況下我們可能被迫提供資料給政府或第三人，第三人可能違法攔截或存取傳輸或私人通訊，或會員可能濫用或誤用他們從我們的網站上所取得的你的資料。因此，雖然我們使用企業標準慣例來保護你的隱私權，我們並未承諾，而且你不應該期待你的個人辨認資料或私人通訊將會永遠保密。',
      '政策上，我們不會將與你有關的個人辨認資料出售或出租予第三人，然而，下列所述為部分可能揭露你個人辨別資料的情形。'
    ],
  },
  {
    title: '刊登廣告者',
    description: '我們搜集(從所有會員帳戶搜集資料)個人辨認資料，並且為市場行銷與宣傳廣告之目的以非可識別個人之方式揭露該資料予刊登廣告者及其它第三人，但即便在這種情形，我們也不會向這些企業體揭露任何可能被用來辨識你個人的資料。一些特定資料，例如你的姓名、電子郵件地址、密碼、手機號碼及銀行帳號，永遠不會被揭露予市場行銷廣告刊登者。如果你在我們網站上的廣告點按你的滑鼠右鍵並選擇內容，你可以看到該廣告提供者的URL。',
  },
  {
    title: '外在服務提供者',
    description: '有許多服務是由外在服務提供者所提供以幫助你使用我們的網站(例如：聯合品牌網站、刊登代理人、委託買賣服務、認證服務、調解服務)，如果你選擇使用這些選擇性服務、揭露資料予外在服務提供者及/或允許他們搜集有關你的資料，那麼他們使用你的資料應受他們的隱私權政策所規範。為了你的方便，我們可以提供你的部份個人辨認資料予提供此類服務之外在服務提供者，然而，在我們提供你的個人辨認資料給外在服務提供者前，你將會看到即將傳送的資料並有權決定是否同意揭露該資料，為了避免我們將你的個人辨認資料揭露給外在服務提供者，你可以拒絕揭露或選擇不使用他們的服務。因為我們無法控制這些第三者的隱私權政策，你應該在決定使用他們的服務以前先評估他們的實務慣例。',
  },
  {
    title: '內部服務提供者',
    description: '我們可能使用內部服務提供者來加強我們的服務(例如：搜尋、留言板、調查)，因此我們可能直接提供你的某些個人辨認資料給他們。在某些情形下，內部服務提供者將直接向你搜集資料(例如在我們要求內部服務提供者為我們進行調查的情形)。在這種情形下，你將會收到內部服務提供者涉入的通知，而你所有揭露資料的行為都是絕對出於個人自由的。在任何情況下，這些內部服務提供者使用我們提供給他們的資料都受到保密合約所限制，如果你直接提供額外的資料給內部服務提供者，則他們使用你的資料應受他們相關隱私權政策所規範。',
  },
  {
    title: '其他公司組織',
    description: '『淞果數位股份有限公司http://www.9199.com.tw』、其子公司、其合資企業、或任何此類的結合都有可能與其他企業組織合併或被收購，萬一發生這樣的結合，你應該可預見http://www. 9199.com.tw可能將你部份或全部的資料分享他人以便繼續提供服務，你將收到此類事件的通知(在它發生的程度內)，並且我們將要求新結合的組織遵循本隱私權政策所規定的慣例。',
  },
  {
    title: '合法要求',
    description: '『淞果數位股份有限公司http://www.9199.com.tw』與執法機關及其它第三人合作一起執行法令，例如：智慧財産權、詐欺及其它權利保護。我們可以(且你已授權我們)，依我們自己的考量認為有必要或適當者，可向執法機關或其他政府官員提供與詐欺、智慧財産權侵害、或其他不法行為或將使我們或你負擔法律責任之行為的調查有關的任何你的資料。進而，我們可以(且你已授權我們)，依我們自己的考量認為有必要或適當者，向『淞果數位股份有限公司http://www.9199.com.tw』已確認的權利所有者方案的參與者提供與詐欺、智慧財産權侵害、盜版或其他不法行為的調查有關的會員帳號、姓名、國家、地址、市、郵遞區號、電話號碼、電子郵件、銀行帳戶及公司名稱。此外，『淞果數位股份有限公司http://www.9199.com.tw』。保留(且你已授權 『淞果數位股份有限公司http://www.9199.com.tw』)，依我們自己的考量認為有必要或適當者，將任何有關於你的資料(包括但不限於你違反政策的行為、被終止的物品及物品狀況)提供給其他會員、執法機關及已認證的權利所有者方案會員的權利，以維持信任及我們社群的安全並執行我們的會員合約、隱私權政策及任何已張貼應適用於你使用我們網站內所有服務的政策或規則。',
  },
  {
    title: '你對其他會員之資料的使用',
    list: [
      '為了加強所有『淞果數位股份有限公司http://www.9199.com.tw』網站會員間的互動，我們的服務允許你有限度的存取其他會員的聯絡資料，站在賣方的立場，你可以取得買方的會員帳號、電子郵件位址及其它資料，而買方則可以取得賣方的會員帳號、電子郵件位址及其它聯絡資料。',
      '除非本款另有規定，在任何情況之下，你都不可以揭露其他會員的個人辨認資料予第三人，除非我們及該其他會員在經過充分說明後表示同意。請注意，執法人員、已認證的權利所有者方案之參與人及其它權利持有人被賦予之資料存取許可權是不相同的。',
    ],
  },
  {
    title: '密碼控制',
    description: '你應就以你的會員帳號及密碼所發生的所有行為負責，包括費用。因此我們並不建議你將你的『淞果數位股份有限公司http://www.9199.com.tw』密碼告知任何第三者，如果你選擇與向你提供額外的服務的第三者分享你的會員帳號及密碼或你的資料，你應就以你的會員帳號及密碼所發生的所有行為負責，因此你應審閱第三者的隱私權政策。如果你對你的密碼失去控制，你可能對你個人辨認資料失去實質的控制，而且可能受到以你名義成立的合法行為所拘束。因此，如果你的密碼因為任何原因受到危害，你必須立刻變更你的密碼。',
  },
  {
    title: '審閱及變更你個人辨認資料',
    list: [
      '我們提供給你可以自行登入網站並且輸入新資料的方式，自動審閱及變更你提供給我們的資料的能力。通常，我們不會依你的要求而修改你的資料，因為驗證你的帳號是非常困難的。經由到我的『淞果數位股份有限公司http://www.9199.com.tw』中的會員資料修改，你可以改變你的會員帳號、電子郵件位址、聯絡資料、財務資料、裝運資料，如果你的資料有異動，你必須即刻加以更新。',
      '一旦張貼，你不可以更正或移除在我們網站上或其他訊息區或信用評價區上的任何公開張貼。',
      '在某些情況下我們將會在我們的檔案中保留你曾經請求移除的訊息，例如為解決爭議、檢修障礙問題及執行我們的會員合約。又，基於技術及法律的限制，包括存取備份系統，該筆以前資料不會從我們的資料庫中被完全移除。因此，你不應期待所有你的個人辨認資料會如你所要求地從我們的資料庫中被完全移除。',
    ],
  },
  {
    title: '其他資料搜集者',
    description: '除非經過本項隱私權政策明確討論，這份文件僅針對使用及揭露我們向你搜集的資料。在你揭露你的資料給其他人的情形，在我們的網站或整個網際網路的其他網站中不論他們是出價者、買方或賣方，他們使用及揭露你所揭露給他們的資料之行為可能適用不同的法律規定。在我們使用第三者刊登廣告者的情形，他們遵守他們自己的隱私權政策。因為『淞果數位股份有限公司http://www.9199.com.tw』不能控制第三人的隱私權政策，你應該受到該第三者的隱私權政策之拘束，在你揭露個人資料給其他人之前我們鼓勵你提出問題。',
  },
  {
    title: '安全性',
    description: '『淞果數位股份有限公司http://www.9199.com.tw』使用企業標準慣例來保護你個人辨認資料的秘密性。『淞果數位股份有限公司http://www.9199.com.tw』將資料視為必須保護其免於滅失及未經授權而存取的資産，我們使用多項安全措施以保護此類資料免於公司內外部的會員未經授權的存取。然而，完美的安全性保護在網際網路上是不存在的。',
  },
  {
    title: '條款衝突',
    description: '本隱私權政策規範是我們隱私權慣例的唯一規範。如果本隱私權政策之條款與條件與其他可能在我們網站中出現的隱私權陳述(例如：隱私權工具、簡易閱讀摘要、圖表及 P3P 聲明)發生衝突時，你同意優先適用本隱私權政策中之條款及條件。',
  },
  {
    title: '通知',
    description: '由於科技發展的迅速，相關法規訂定未臻完備，以及未來可能難以預見的環境變遷等因素，會視需要修改我們在網站上所提供的隱私權說明，以落實保障您隱私權之立意，所有修改條款應自初次張貼於網站上起7天後自動生效。',
  },
];

/**
|--------------------------------------------------
|  Main Component
|--------------------------------------------------
*/
const PrivacyPolicyView = () => {
  return (
    <GlobalStyle.Container
      hasFixedTopbar
      hasFixedTabbar={false}
      backgroundColor='#f5f5f5'
    >
      <TopbarView
        isFixed
        leftComponent={
          <CupoySrefView
            statename={CupoyRouter.STATES.MEMBER}
            stylecomponent={GlobalStyle.TopbarButton}
          >
            <GlobalStyle.TopbarIcon>
              <i className="zmdi zmdi-chevron-left"></i>
            </GlobalStyle.TopbarIcon>
            返回
          </CupoySrefView>
        }
        middleComponent="隱私權聲明"
      />
      <AboutStyle.Container>
        <AboutStyle.Title>9199隱私權聲明</AboutStyle.Title>
          本隱私權政策聲明適用于『淞果數位股份有限公司』 於 www.9199.com.tw 網站域名及次級網域名所提供的服務。<br/>
          在使用本網站時，你同意受本隱私權政策條款及條件所拘束，如果你不同意，請不要使用或取得我們所提供的服務<br/>。
          如下所述，你個人辨認資料有些部分會被揭露予第三人並且被我們及第三人使用。<br/>
          在註冊時透過接受隱私權政策及會員合約，你明示同意我們使用及揭露你個人辨認資料。<br/>
          這項隱私權政策被並入並且符合會員合約之條款。 
          {
            DATA_LIST.map(({
              title,
              list,
              description,
            }, index) => (
              <AboutStyle.Section key={index}>
                <AboutStyle.Title>{title}</AboutStyle.Title>
                {
                  list ?
                  <AboutStyle.SubList>
                    {list.map((text, index) => (
                      <AboutStyle.Row
                        key={index}
                        listType='number'
                        number={index + 1}
                      >
                        {text}
                      </AboutStyle.Row>
                    ))}
                  </AboutStyle.SubList>
                  :
                  <AboutStyle.Description>{description}</AboutStyle.Description>
                }
              </AboutStyle.Section>
            ))
          }
      </AboutStyle.Container>
    </GlobalStyle.Container>
  );
}

export default PrivacyPolicyView;