import GLOBAL from 'nonnmwebglobal';
import WebViewBridgeUtil from 'components/commons/WebViewBridgeUtil';
import CupoyRouter from "components/commons/router/CupoyRouter";

// ------------------------------
// app 監控 a tag 點擊事件，要發meassage給webview
// ------------------------------
function handleOpenLinkBridge(event, webActions) {

    if(!GLOBAL.isAppFile || !event) {
        return;
    }

    const target = event.currentTarget || event.target;

    if(!target) {
        return;
    }

    const attrTarget = target.getAttribute('target') || target.target;
    const hadLowerAttrTarget = attrTarget.toLowerCase();
    const attrHref = target.getAttribute('href') || target.href;

    if(!attrHref) {
        return;
    }

    event.stopPropagation();
    event.preventDefault();

    const match_index = attrHref.indexOf(GLOBAL.URL_MATCH);

    if(match_index === -1 && hadLowerAttrTarget === '_blank') {
        WebViewBridgeUtil.postLinkOpenMessage(attrHref);
        return;
    }

    const pathIndex = attrHref.indexOf('/', 'https://'.length);
    const path = attrHref.substr(pathIndex);
    const cupoyState = CupoyRouter.getStateByPath(path);
    const querys = CupoyRouter.getQuerysByPath(path);

    if(!cupoyState) {
        return;
    }

    webActions && webActions.cupoyStateGo(cupoyState.state.name, cupoyState.params, querys);
}

/**
 * 強制開啟新分頁連結，發meassage給webview
 */
function handleOpenLinkBridgeWithNew(event) {

    if(!GLOBAL.isAppFile || !event) {
        return;
    }

    const target = event.currentTarget || event.target;

    if(!target) {
        return;
    }

    const attrHref = target.getAttribute('href') || target.href;

    if(!attrHref) {
        return;
    }

    event.stopPropagation();
    event.preventDefault();

    WebViewBridgeUtil.postLinkOpenMessage(attrHref);
}

export default {
    handleOpenLinkBridge,
    handleOpenLinkBridgeWithNew,
};