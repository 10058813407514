/*=====================================
    MemberCardOrderListCart

    Author: Gray
    CreateTime: 2018 / 03 / 19
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import styled, { keyframes } from 'styled-components';
import GLOBAL from 'nonnmwebglobal';
import { GlobalStyle, Colors, MemberStyle } from 'stylecomponents';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
import TimeFormat from "components/commons/TimeFormat";

/**
 * Keyframes
 */
const rotate = keyframes`
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
`;

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
    padding: 44px 0 10px ;
`;
const ColorText = styled.span`
    color: ${(props) => props.color ? props.color : 'inherit'};
`;
const RefreshBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 44px;
    border-bottom: 1px solid #ddd;
    position: fixed;
    z-index: 100;
    top: 94px;
    left: 0;
    background-color: #fff;
    user-select: none;
    cursor: pointer;

    > i {
        margin-right: 8px;
        font-size: 16px;
        animation-name: ${rotate};
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-duration: 750ms;
        color: ${({ isRefreshing }) => isRefreshing ? Colors.Main : 'inherit'};
        animation-play-state: ${({ isRefreshing }) => isRefreshing ? 'running' : 'paused'};
    }
`;
/*--------------------------
    Main Component
--------------------------*/
class MemberCardOrderListCartView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            start: 0,
            isLoading: false,
            isRefreshing: false,
            isError: false,
            isEmpty: false,
            isNoMore: false,
            errorMsg: '',
        };

        this.listCardCartUnfinishedOrders = this.listCardCartUnfinishedOrders.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
        this.refreshList = this.refreshList.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        this.init();
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init() {
        this.listCardCartUnfinishedOrders();
    }

    refreshList() {

        window.scrollTo({
            top: 0,
            behavior: 'instant',
        });

        this.listCardCartUnfinishedOrders({
            refresh: true,
        });
    }

    // ------------------------------
    // 取得虛寶收購區列表
    // ------------------------------
    listCardCartUnfinishedOrders({ refresh = false } = {}) {

        const {
            isLoading,
            isEmpty,
            isNoMore,
            isError,
        } = this.state;

        if (!refresh && (isLoading || isEmpty || isNoMore || isError)) {
            return;
        }

        this.setState(prevState => ({
            isLoading: true,
            isRefreshing: refresh,
            start: !refresh ? prevState.start : 0,
        }), () => {

            const {
                start,
            } = this.state;

            this.props.cardCartActions.listCardCartUnfinishedOrders({
                start,
                refresh,
            }).then(() => {

                const unfinishedCartIds = this.props.unfinishedCartIds;

                const newState = {
                    isLoading: false,
                    isRefreshing: false,
                    start: unfinishedCartIds.length,
                };

                if (!Array.isArray(unfinishedCartIds) || unfinishedCartIds.length === 0) {
                    newState.isEmpty = true;
                } else if (start === newState.start) {
                    newState.isNoMore = true;
                }

                this.setState(newState, () => {
                    if (refresh) {
                        this.props.webActions.showSimpleAlertDialog('已重新整理完成');
                    }
                });
            }, error => {

                let message = "無法取得訂單資訊，請重新整理後再嘗試。";

                if(error && error.message) {
                    message = error.message;
                }

                this.setState({
                    isLoading: false,
                    isRefreshing: false,
                    isError: true,
                    errorMsg: message,
                });
            })
        })
    }

    // ------------------------------
    // renderUnfinishedCartStatus
    // ------------------------------
    renderUnfinishedCartStatus(overPayLimit, unfinishedCart) {

        const int_orderstatus = Number(unfinishedCart.orderstatus);

        if(overPayLimit && int_orderstatus === GLOBAL.CARTORDER_STATUS.UNPAY) {
            return (<ColorText>已過期</ColorText>);
        }

        const text = GLOBAL.CARTORDER_STATUS_STRING[int_orderstatus];
        var color = undefined;

        switch(int_orderstatus) {
            case GLOBAL.CARTORDER_STATUS.UNPAY:
                color = Colors.Red;
                break;
            case GLOBAL.CARTORDER_STATUS.SUCCESS:
                color = Colors.Green;
                break;
            case GLOBAL.CARTORDER_STATUS.FAIL:
            case GLOBAL.CARTORDER_STATUS.PARTFAIL:
                color = Colors.Red;
                break;
            default:
                break;
        }

        return (<ColorText color={color}>{text}</ColorText>);
    }

    // ------------------------------
    // rowRenderer
    // ------------------------------
    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it),
        parent,
        measure,
        columnIndex,
        updateRowSize,
        measureCache,
        listWidth,
    }, width, listHeight) {

        const {
            unfinishedCartIds,
            unfinishedCartMap,
        } = this.props;

        const unfinishedCartId = unfinishedCartIds[index];
        const unfinishedCart = unfinishedCartMap[unfinishedCartId];

        if (!unfinishedCart) {
            return null;
        }

        const createtime = unfinishedCart.createtime;
        const payendtime = unfinishedCart.createtime + GLOBAL.PAYMENT_LIMIT_HOUR * 1000 * 60 * 60;        
        const paytypeString = GLOBAL.PAYTYPE_STRING[unfinishedCart.paytype];
        const nowtime = Date.now();
        const overPayLimit = nowtime > payendtime;
        const cartname = unfinishedCart.pdtinfo.pdtlist.reduce((name, pdt, idx)=>{
            if(name.length > 0){
                return name + ',' + pdt.productname
            }
            return pdt.productname
        }, '')

        return (
            <CupoySrefView
                statename={CupoyRouter.STATES.MEMBER_CARD_ORDER_UNPAY_DETAIL}
                params={{
                    id: unfinishedCart.cartid,
                }}
                key={index}
                style={style}
            >
                <MemberStyle.LogsItem hasArrow={true}>
                    <MemberStyle.LogsTitle>
                        產品名稱: {cartname}
                    </MemberStyle.LogsTitle>
                    <MemberStyle.LogsDescription>
                        建立時間: <TimeFormat format="simpleFullTime" time={createtime} />
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription>
                        截止時間: <TimeFormat format="simpleFullTime" time={payendtime} />
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription>
                        訂單狀態: {this.renderUnfinishedCartStatus(overPayLimit, unfinishedCart)}
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription>
                        付款方式: {paytypeString}
                    </MemberStyle.LogsDescription>
                    {unfinishedCart.pdtinfo && Array.isArray(unfinishedCart.pdtinfo.pdtlist) ?
                        <MemberStyle.LogsDescription>
                            商品品項: {unfinishedCart.pdtinfo.pdtlist.length}
                        </MemberStyle.LogsDescription>
                        :
                        null
                    }
                    <MemberStyle.LogsDescription isLast={true}>
                        <ColorText color={Colors.Blue}>購物車編號: {unfinishedCart.cartid}</ColorText>
                    </MemberStyle.LogsDescription>
                    {unfinishedCart.totalamt >= 0 ?
                        <MemberStyle.LogsStatus>金額：{unfinishedCart.totalamt} 元</MemberStyle.LogsStatus>
                        :
                        null
                    }
                    <MemberStyle.LogsRightArrow><i className="icon-chevron-right"/></MemberStyle.LogsRightArrow>
                </MemberStyle.LogsItem>
            </CupoySrefView>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            isLoading,
            isRefreshing,
            isError,
            isEmpty,
            errorMsg,
        } = this.state;

        const {
            unfinishedCartIds,
        } = this.props;

        return (
            <React.Fragment>

                {(isRefreshing || (Array.isArray(unfinishedCartIds) && unfinishedCartIds.length > 0)) && (
                    <RefreshBtn isRefreshing={isRefreshing} onClick={this.refreshList}>
                        <i className="zmdi zmdi-refresh"></i>
                        立即重新整理
                    </RefreshBtn>
                )}

                <Content>
                    {Array.isArray(unfinishedCartIds) && unfinishedCartIds.length > 0 && (
                        <CupoyListRepeat
                            listId={this.constructor.name}
                            rowRenderer={this.rowRenderer}
                            items={unfinishedCartIds}
                            isLoading={isLoading}
                            useWindowScroll={true}
                            restoreWindowScroll={true}
                            loadMoreRows={this.listCardCartUnfinishedOrders}
                        />
                    )}

                    {isLoading && (
                        <GlobalStyle.LoadingContainer>
                            <GlobalStyle.Spin />
                        </GlobalStyle.LoadingContainer>
                    )}

                    {isError && (
                        <GlobalStyle.ErrorContainer>
                            <GlobalStyle.ErrorContainerDesription>
                                {errorMsg}
                            </GlobalStyle.ErrorContainerDesription>
                        </GlobalStyle.ErrorContainer>
                    )}

                    {isEmpty && (
                        <GlobalStyle.ErrorContainer>
                            <GlobalStyle.ErrorContainerTitle>
                                無任何點卡購物訂單
                            </GlobalStyle.ErrorContainerTitle>
                        </GlobalStyle.ErrorContainer>
                    )}
                </Content>

            </React.Fragment>
        )
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        unfinishedCartMap: state.cardcart.unfinishedCartMap,
        unfinishedCartIds: state.cardcart.unfinishedCartIds,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        cardCartActions: bindActionCreators(actionCreators.cardCartActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberCardOrderListCartView);
