/*=====================================
    首頁

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import TopbarView from "components/commons/TopbarView";
import { GlobalStyle, NewsList } from 'stylecomponents';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import TimeFormat from "components/commons/TimeFormat";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
/*--------------------------
    styled
--------------------------*/
/*--------------------------
    Main Component
--------------------------*/

class ProductBuyListView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.renderList = this.renderList.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
        this.loadMoreList = this.loadMoreList.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        const idsByClass = this.props.idsbyclass[2];
        if(idsByClass.length === 0){
            this.props.bulletinActions.listBulletins({bclass: 2});
        }
    }

    sliceBulletinTitle(actionType, title){

        if(!title){
            return null;
        }

        switch (actionType) {

            case 'getTagName':
                return title.slice(1,3);
            case 'getTitle':
                return title.slice(4);
            default:
                return title;
        }

    }
    // ------------------------------
    // render
    // ------------------------------
    render() {

        return (
            <GlobalStyle.Container
                hasFixedTabbar={true}
                hasFixedTopbar={true}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView
                            statename={CupoyRouter.STATES.HOME}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            首頁
                        </CupoySrefView>
                    }
                    middleComponent="最新活動"
                />
                <div>{this.renderList()}</div>
                {this.props.isloading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }
            </GlobalStyle.Container>
        );
    }
    renderList(){
        const idsByClass = this.props.idsbyclass[2];
        if(idsByClass.length === 0){
            return null;
        }
        return (
            <div>
                <CupoyListRepeat
                    ref={_ref => (this.list = _ref)}
                    rowRenderer={(item, listWidth, listHeight) => { return this.rowRenderer(item, listWidth, listHeight, idsByClass)}}
                    listId={this.constructor.name}
                    items={idsByClass}
                    isLoading={this.props.isloading}
                    useWindowScroll={true}
                    restoreWindowScroll={true}
                    loadMoreRows={()=>this.loadMoreList(idsByClass)}
                />
            </div>
        )
    }
    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it),
        parent,
        measure,
        columnIndex,
        updateRowSize,
        measureCache,
        listWidth,
    }, width, listHeight,idsByClass){

        const bulletinIds = idsByClass[index];
        const bulletin = this.props.itemMap[bulletinIds];

        if(!bulletin) {
            return null;
        }

        const bulletinTitle = this.sliceBulletinTitle('getTitle', bulletin.title);
        const isTopBulletin = bulletin.topbulletin === 1 || bulletin.topbulletin === 3;

        return (
            <CupoySrefView
                statename={CupoyRouter.STATES.PRODUCTBUYCONTENT}
                stylecomponent={NewsList.NewsItem}
                params={{
                    id: bulletin.bulletinid
                }}
                key={index}
                style={style}
            >
                <div>
                    <NewsList.NewsTag bulletinType={bulletin.type}>
                        {this.sliceBulletinTitle('getTagName', bulletin.title)}
                        <NewsList.NewsTagIcon>
                            {isTopBulletin && (
                                <i className="icon-drawingpin"></i>
                            )}
                        </NewsList.NewsTagIcon>
                    </NewsList.NewsTag>
                </div>
                <NewsList.NewsText isTopBulletin={isTopBulletin}>
                    {bulletinTitle}
                    <TimeFormat
                        format="timeStringDash"
                        time={bulletin.publishtime}
                    />
                </NewsList.NewsText>
            </CupoySrefView>
        )
    }

    loadMoreList(idsByClass){
        this.props.bulletinActions.listBulletins({
            bclass: 2,
            start: idsByClass.length,
        });
    }
}
/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        itemMap: state.bulletin.itemMap,
        isloading: state.bulletin.isloading,
        idsbyclass: state.bulletin.idsbyclass,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        bulletinActions: bindActionCreators(actionCreators.bulletinActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(ProductBuyListView);