/*=====================================
    AlertDialog

    Author: Gray
    CreateTime: 2018 / 03 / 14
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from "actions/creators";
import styled from 'styled-components';
import { Button, DialogStyle } from 'stylecomponents';
import CupoyImage from 'components/commons/CupoyImage';

/*--------------------------
    Styled
--------------------------*/
const Title = styled.h6`
    line-height: 25px;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: #565151;
    text-align: center;
`;
const Description = styled.p`
    line-height: 20px;
    margin: 12px 0 0 0;
    font-size: 13px;
    color: #8A807F;
    text-align: center;
    white-space: pre-wrap;
`;
const ActionWrapper = styled.div`
    margin: 20px 0 0 0;
    text-align: center;
`;
const ButtonGroup = styled.div`
    display: inline-block;
    overflow: hidden;
`;
const CloseButton = styled(Button.Orange)`
    width: 120px;
    float: left;
`;

/*--------------------------
    Main Component
--------------------------*/
class AlertDialog extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);
        this.state = {

        };

        this.closeDialog = this.closeDialog.bind(this);
        this.close = this.close.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        this.init(this.props);
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props) {

    }

    // ------------------------------
    // 關閉
    // ------------------------------
    closeDialog() {

        this.props.webActions.closeAlertDialog();
    }

    // ------------------------------
    // 關閉
    // ------------------------------
    close() {

        this.props.alertDialog.closeCallback && this.props.alertDialog.closeCallback();
        this.closeDialog();
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            title,
            description,
            renderDescription,
            imageUrl,
            closeName,
        } = this.props.alertDialog;

        return (
            <DialogStyle.Content
                // style props
                width="calc(100% - 20px)"
                maxWidth="320px"
                padding="20px"
                backgroundColor="#fff"
                borderRadius="3px"
                // dialog props
                visible
                animation="zoom"
                maskAnimation="fade"
                closable={false}
                maskClosable={false}
                keyboard={false}
                onClose={this.close}
            >
                {/* <DialogStyle.CloseButton onClick={this.close}>
                    <i className="zmdi zmdi-close"></i>
                </DialogStyle.CloseButton> */}
                <Title>
                    {title}
                </Title>
                {description ?
                    <Description>
                        {description}
                    </Description>
                    :
                    null
                }

                {renderDescription && (
                    <Description>
                        {renderDescription(this.close)}
                    </Description>
                )}

                {imageUrl ?
                    <CupoyImage
                        style={{width: '100%'}}
                        src={imageUrl}
                    />
                : null}
                <ActionWrapper>
                    <ButtonGroup>
                        <CloseButton outline onClick={this.close}>
                            {closeName ?
                                closeName
                                :
                                '關閉'
                            }
                        </CloseButton>
                    </ButtonGroup>
                </ActionWrapper>
            </DialogStyle.Content>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        alertDialog: state.web.alertDialog,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(AlertDialog);