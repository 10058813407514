/*=====================================
    點卡產包

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle ,TabBar } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import styled from 'styled-components';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import { Route } from "react-router-dom";
import FreeProductsView from "components/card/FreeProductsView";
import VitemProductsView from "components/card/VitemProductsView";
import BonusProductsView from "components/card/BonusProductsView";
import PointCardView from "components/card/PointCardView";
import RecommendedProductsView from "components/card/RecommendedProductsView";

const CardContent = styled.div`

    display: flex;
    background: rgba(255,255,255,0.00);

`;

/*--------------------------
    Main Component
--------------------------*/
class CardView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {

        };
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        if(!CupoyRouter.isActive(CupoyRouter.STATES.CARD_POINTCARD)
            && !CupoyRouter.isActive(CupoyRouter.STATES.CARD_RECOMMENDED)
            && !CupoyRouter.isActive(CupoyRouter.STATES.CARD_VITEMPRODUCTS)
            && !CupoyRouter.isActive(CupoyRouter.STATES.CARD_BONUSPRODUCTS)
            && !CupoyRouter.isActive(CupoyRouter.STATES.CARD_FREEPRODUCTS)
        ) {
            this.props.webActions.cupoyStateReplace(CupoyRouter.STATES.CARD_RECOMMENDED);
        }
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {
        return (
            <GlobalStyle.Container
                hasFixedTopbar
                hasFixedTabbar
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed
                    leftComponent={
                        <CupoySrefView
                            statename={CupoyRouter.STATES.HOME}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            首頁
                        </CupoySrefView>
                    }
                    middleComponent="點卡產包"
                />
                <TabBar.TabBarWrapper>
                    <CupoySrefView
                        statename={CupoyRouter.STATES.CARD_RECOMMENDED}
                        stylecomponent={TabBar.TabBarItem}
                        active={CupoyRouter.isActive(CupoyRouter.STATES.CARD_RECOMMENDED)}
                    >
                        熱門商品
                    </CupoySrefView>

                    <CupoySrefView
                        statename={CupoyRouter.STATES.CARD_POINTCARD}
                        stylecomponent={TabBar.TabBarItem}
                        active={CupoyRouter.isActive(CupoyRouter.STATES.CARD_POINTCARD)}
                    >
                        點數卡
                    </CupoySrefView>

                    <CupoySrefView
                        statename={CupoyRouter.STATES.CARD_VITEMPRODUCTS}
                        stylecomponent={TabBar.TabBarItem}
                        active={CupoyRouter.isActive(CupoyRouter.STATES.CARD_VITEMPRODUCTS)}
                    >
                        虛寶包
                    </CupoySrefView>

                    <CupoySrefView
                        statename={CupoyRouter.STATES.CARD_FREEPRODUCTS}
                        stylecomponent={TabBar.TabBarItem}
                        active={CupoyRouter.isActive(CupoyRouter.STATES.CARD_FREEPRODUCTS)}
                    >
                        免費序號
                    </CupoySrefView>

                    <CupoySrefView
                        statename={CupoyRouter.STATES.CARD_BONUSPRODUCTS}
                        stylecomponent={TabBar.TabBarItem}
                        active={CupoyRouter.isActive(CupoyRouter.STATES.CARD_BONUSPRODUCTS)}
                    >
                        發財商城
                    </CupoySrefView>

                </TabBar.TabBarWrapper>
                <CardContent>
                    <Route path={CupoyRouter.PATHS.CARD_RECOMMENDED} component={RecommendedProductsView} exact/>
                    <Route path={CupoyRouter.PATHS.CARD_POINTCARD} component={PointCardView} exact/>
                    <Route path={CupoyRouter.PATHS.CARD_FREEPRODUCTS} component={FreeProductsView} exact/>
                    <Route path={CupoyRouter.PATHS.CARD_VITEMPRODUCTS} component={VitemProductsView} exact/>
                    <Route path={CupoyRouter.PATHS.CARD_BONUSPRODUCTS} component={BonusProductsView} exact/>
                </CardContent>
            </GlobalStyle.Container>
        );
    }

}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(CardView);