/*=====================================
    InvoiceDetailDialog
    發票明細Dialog
    Author: Eileen
    CreateTime: 2018 / 03 / 29
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from "actions/creators";
import styled from 'styled-components';
import { Button, DialogStyle } from 'stylecomponents';

/*--------------------------
    Styled
--------------------------*/
const Title = styled.h6`
    line-height: 25px;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: #565151;
    text-align: center;
    border-bottom : 1px inset #C7CCCC;
`;
const ActionWrapper = styled.div`
    margin: 20px 0 0 0;
    text-align: center;
`;
const ButtonGroup = styled.div`
    display: inline-block;
    overflow: hidden;
`;
const CloseButton = styled(Button.Orange)`
    width: 120px;
    float: left;
`;
const Container = styled.div`
    display:table;
    border-collapse: collapse;
    width : 100%;
    height : 100%;

`;
const TitleRow = styled.div`
    display: table-row;
    font-weight : bold;
    font-size : 12px;
    border-bottom :  1px  solid #C7CCCC;
    vertical-align : middle;
`;
const ContentRow = styled.div`
    margin : 2px 0 0 0;
    display: table-row;
    font-size : 10px;
    border-bottom :  1px  solid #C7CCCC;
    vertical-align: middle   
     
    
`;
const ContentCell = styled.div`
    display: table-cell;
    padding: 3px 0 3px 5px;
    text-align: ${(props) => props.type = "digit" ?  "right" : "center"};
    width: ${(props) => props.width ?  props.width : "75px"};
    vertical-align: middle
`;

/*--------------------------
    Main Component
--------------------------*/

class InvoiceDetailDialog extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {

        };

        this.closeDialog = this.closeDialog.bind(this);
        this.close = this.close.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        this.init(this.props);
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props) {

    }
      
      
    // ------------------------------
    // 關閉
    // ------------------------------
    closeDialog() {

        this.props.webActions.closeInvoiceDetailDialog();
    }

    // ------------------------------
    // 關閉 
    // ------------------------------
    close() {

        this.props.invoiceDetailDialog.closeCallback && this.props.invoiceDetailDialog.closeCallback();
        this.closeDialog();
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {
        const {
            invoiceDetail,
            closeName,
            
        } = this.props.invoiceDetailDialog;
        let detailObj = {};
        if(invoiceDetail)
            detailObj = JSON.parse(invoiceDetail);
        return (
            <DialogStyle.Content
                // style props
                width="calc(100% - 20px)"
                maxWidth="320px"
                padding="20px"
                backgroundColor="#fff"
                borderRadius="3px"
                // dialog props
                visible={true}
                animation="zoom"
                maskAnimation="fade"
                closable={false}
                maskClosable={false}
                keyboard={false}
                onClose={this.close}
            >
                {/* <DialogStyle.CloseButton onClick={this.close}>
                    <i className="zmdi zmdi-close"></i>
                </DialogStyle.CloseButton> */}
                <Title>
                    發票商品明細
                </Title>
                <Container>
                    <TitleRow><ContentCell width="120px">商品名稱</ContentCell><ContentCell>數量</ContentCell><ContentCell>單價(元)</ContentCell><ContentCell>小計(元)</ContentCell></TitleRow>
                    {detailObj.pdtlist.map((e,i) => {
                                return (
                                    <ContentRow key={i}>
                                    <ContentCell>{e.itemname}</ContentCell>
                                    <ContentCell type="digit">{e.count}</ContentCell>
                                    <ContentCell type="digit">{e.unitprice}</ContentCell>
                                    <ContentCell type="digit">{e.amount}</ContentCell>
                                  </ContentRow>
                                )
                            })}

                </Container>
                <ActionWrapper>
                    <ButtonGroup>
                        <CloseButton outline={true} onClick={this.close}>
                            {closeName ?
                                closeName
                                :
                                '關閉'
                            }
                        </CloseButton>
                    </ButtonGroup>
                </ActionWrapper>
            </DialogStyle.Content>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        invoiceDetailDialog: state.web.invoiceDetailDialog,

    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(InvoiceDetailDialog);