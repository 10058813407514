/*=====================================
    虛寶市集

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import styled from 'styled-components';
import { GlobalStyle } from 'stylecomponents';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
import NumberFormat from 'react-number-format';
import GLOBAL from 'nonnmwebglobal';

/*--------------------------
    Styled Component
--------------------------*/

const TransactionList = styled.div`

    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #EEEEEE, inset 0 1px 0 0 #EEEEEE;
    cursor: pointer;

`;


const Bullets = styled.div`

    position: relative;
    padding-top: 7px;
    padding-right: 10px;
    left: 0;
    top: 0;
`;

const BulletsRound = styled.div`

    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.online ? '#54B62C' : '#D0021B'};

`;

const TransactionInfo = styled.div`
    width: 100%;
`;

const TransactionName = styled.div`

    padding-bottom: 8px;
    font-size: 16px;
    color: #333333;

`;

const StarWrapper = styled.span`
    padding-left: 8px;
`;

const StarStyle = styled.i.attrs({

    className: 'icon-star'
})`
    color: ${props => props.color};
    font-size: 12px;
    
`;

const Stock = styled.div`

    display: inline-block;
    margin-right: 21px;
    font-size: 12px;
    color: #565151;

`;

const ExchangeRate = styled.div`

    display: inline-block;
    font-size: 12px;
    color: #565151;

`;

const RateNumber = styled.span`

    margin-left: 8px;
    color: #F75C2F;
    font-weight: bolder;

`;

const Arrow = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    color: #9B9B9B;

`;

/*--------------------------
    Main Component
--------------------------*/
class SellView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            topLen: 0,
            isLoadingList: false,
            isEmpty: false,
        }

        this.rowRenderer = this.rowRenderer.bind(this);
        this.loadMoreList = this.loadMoreList.bind(this);
        this.renderList = this.renderList.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        const {
            gameid, 
            serverid: gameserverid,
        } = this.props.match.params;
        const sellerListIdsByGameAndServerId = this.props.sellerListIdsByGameAndServerId[`${gameid},${gameserverid}`];
        
        
        if(!Array.isArray(sellerListIdsByGameAndServerId) || sellerListIdsByGameAndServerId.length === 0) {   
            
            this.setState({
                isLoadingList: true,
            }, () => {

                    this.props.vitemActions.getVItemTopSellerListForGame({gameid, gameserverid}).then((result) => {

                    this.setState({
                        topLen: result.resultSize,
                        isLoadingList: false,
                    }, () => {
                        this.props.vitemActions.getVItemSellerListForGame({gameid, gameserverid, start: 0}).then((result)=>{
                            const {
                                gameid, 
                                serverid: gameserverid,
                            } = this.props.match.params;
                            const sellerListIdsByGameAndServerId = this.props.sellerListIdsByGameAndServerId[`${gameid},${gameserverid}`];

                            if(!Array.isArray(sellerListIdsByGameAndServerId) || sellerListIdsByGameAndServerId.length === 0){
                                this.setState({
                                    isEmpty: true,
                                });
                            }
                        });
                    });
                })
            });
        }
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {
        

        return (
            <div>
                {this.renderList()}
                {this.props.isloading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }
                {this.props.error ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            發生錯誤
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無法取得列表，請重新整理後再嘗試。
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }
                {this.state.isEmpty ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            查無資料
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無庫存資料
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }   
            </div>
        )
    }
    renderList(){
        const {
            gameid, 
            serverid: gameserverid,
        } = this.props.match.params;

        const sellerListIdsByGameAndServerId = this.props.sellerListIdsByGameAndServerId[`${gameid},${gameserverid}`];
        let _sellerListIdsByGameAndServerId = [];
        if(Array.isArray(sellerListIdsByGameAndServerId)) {
            _sellerListIdsByGameAndServerId = sellerListIdsByGameAndServerId;
        }
        
        return (
            <div>
                <CupoyListRepeat
                    ref={_ref => (this.list = _ref)}
                    rowRenderer={(item, listWidth, listHeight) => {
                        return this.rowRenderer(item, listWidth, listHeight, _sellerListIdsByGameAndServerId, gameid, gameserverid)}}
                    listId={this.constructor.name}
                    items={_sellerListIdsByGameAndServerId}
                    isLoading={this.props.isloading}
                    useWindowScroll={true}
                    restoreWindowScroll={true}
                    loadMoreRows={()=>this.loadMoreList(_sellerListIdsByGameAndServerId, gameid, gameserverid)}
                />
            </div>
        )
    }

    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it),
        parent,
        measure,
        columnIndex,
        updateRowSize,
        measureCache,
        listWidth,
    }, width, listHeight,_sellerListIdsByGameAndServerId, gameid, gameserverid){

        const sellerListIds = _sellerListIdsByGameAndServerId[index];
        const seller = this.props.sellerMap[sellerListIds];
        const isOpen = GLOBAL.getVProviderIsOpen(seller);
        
        return (
            <CupoySrefView 
                statename={CupoyRouter.STATES.SELLDETAIL_PRODUCT}
                stylecomponent={TransactionList}
                params={{
                    gameid: seller.gameid,
                    serverid: seller.gameserverid,
                    viprovid: seller.viprovid,
                }}
                style={style}
                key={index}
            >
                <Bullets>
                    <BulletsRound online={isOpen} />
                </Bullets>
                <TransactionInfo>
                    <TransactionName>
                        {GLOBAL.VITEM_DISPLAY_TEXT}會員：{seller.nickname}
                        <StarWrapper>
                            <StarStyle color="#00BCD4"></StarStyle>
                        </StarWrapper>
                    </TransactionName>
                    <div>

                        <NumberFormat value={seller.inventory} displayType={'text'} thousandSeparator={true}  renderText={value => <Stock>庫存：{value}</Stock>} />
                        <ExchangeRate>
                            比例(台幣：虛幣)
                            <RateNumber>
                            <NumberFormat value={seller.ntdollar} displayType={'text'} thousandSeparator={true} />
                            :
                            <NumberFormat value={seller.vitemdollar} displayType={'text'} thousandSeparator={true} />
                            </RateNumber>
                        </ExchangeRate>
                    </div>
                </TransactionInfo>
                <Arrow>
                    <i className="icon-chevron-right"></i>
                </Arrow>
            </CupoySrefView>
        )
    }
    loadMoreList(_sellerListIdsByGameAndServerId, gameid, gameserverid){

        if(this.state.isLoadingList) {
            return;
        }

        this.setState({
            isLoadingList: true,
        }, () => {
            this.props.vitemActions.getVItemSellerListForGame({
                start: _sellerListIdsByGameAndServerId.length - this.state.topLen,
                gameid,
                gameserverid,
            }).then(() => {
                this.setState({
                    isLoadingList: false,
                })
            });
        })

        
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        sellerMap: state.vitem.sellerMap,
        isloading: state.vitem.isloading,
        sellerListIdsByGameAndServerId: state.vitem.sellerListIdsByGameAndServerId,
        error: state.vitem.error,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        vitemActions: bindActionCreators(actionCreators.vitemActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(SellView);