/*=====================================
    會員中心

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle, Colors, MemberStyle } from 'stylecomponents';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import styled from 'styled-components';
import GLOBAL from 'nonnmwebglobal';
import CupoyImage from 'components/commons/CupoyImage';
import CupoyLink from 'components/commons/CupoyLink';

/*--------------------------
    Style Component
--------------------------*/
const TopContainer = styled.div`
    display : flex;
    flex-direction : column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 180px;
    background-color: ${Colors.Orange}; 
    z-index: ${GlobalStyle.TopbarZindex};
    transform: translateZ(0);
`;
const LogoIcon = styled.div`
    position: relative;
    width: 60px;
    height: 60px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #eee;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: ${({ src }) => src ? `url(${src})` : `url('about:blank')` };
`;
const VipTip = styled.div`
    display: flex;
    position: absolute;
    right: -10px;
    bottom: -4px;
    width: 30px;
    height: 30px;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    background-image: linear-gradient(-135deg, #FAF361 0%, #F79B1C 100%);
    color: #fff;
    font-size: 12px;
    transform: scale(${10/14});
    align-items: center;
    justify-content: center;
`;
const TopBarContent = styled.div`
    margin : ${(props) => props.margin ? props.margin : '8px 0 0 0'};
    width: 100%;
    height: ${(props) => props.height ? props.height : '22px'};
    font-size: ${(props) => props.size ? props.size : '16px'};
    background-color: ${Colors.Orange};
    color : #FFF; 
    text-align : center;
    z-index: ${GlobalStyle.TopbarZindex};
    
`;
const Title = styled.div`
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    line-height: 25px;
    width : 100%;
    height : 25px;
    font-weight : bold;
`;
const Slogan = styled.div`
    margin : 8px 0 0 0;
    font-size: 13px;
    color: #FFFFFF;
    text-align: center;    
`;
const BtnContainer = styled.div`
    margin : 16px 0 0 0;
    display : flex;
    flex-direction : row;
    justify-content: center;
`
const BtnWhite = styled.div`
    margin : 0 16px 0 0;
    height : 36px;
    width : 120px;
    line-height : 36px;
    background: #FFFFFF;
    border-radius: 3px;
    font-size: 14px;
    color: #F75C2F;
    text-align: center;
    cursor:pointer;
`;
const BtnOrange = styled.div`
    height : 36px;
    width : 120px;
    line-height : 36px;
    border: 1px solid #FFFFFF;
    border-radius: 3px;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    cursor:pointer;
`;
const Contact = styled.div`
    color: #4A4A4A;
    font-size: 14px;
    padding: 15px;
    background-color: #FFF;
    margin-top: 10px;
`;
const ContactTitle = styled.div`
    font-weight: 600;
    margin-bottom: 10px;
`;
const ContactItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;

    > a {
        color: inherit;
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
`;
const SocialLink = styled.div`
    display: block;
    margin-right: 6px;
`;
const SocialImg = styled.div`
    width: 35px;
    min-width: 35px;
    height: 35px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
`;

const ContactBlock = () => (
    <Contact>
        <ContactTitle>聯絡我們</ContactTitle>
        <ContactItem>週一至週五 10:00-19:00</ContactItem>
        <ContactItem>週六至週日 14:00-22:00</ContactItem>
        <ContactItem>客服專線：(02) 7731-2321</ContactItem>
        <ContactItem>傳真電話：(02) 8227-8089</ContactItem>
        <ContactItem>
            客服信箱：
            <CupoyLink
                target='_blank'
                rel='noopener noreferrer'
                href='mailto:service@9199.com.tw'
            >
                service@9199.com.tw
            </CupoyLink>
        </ContactItem>
        <ContactItem>
            聯絡我們：
            <SocialLink>
                <CupoyLink
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://www.facebook.com/9199fan/'
                >
                    <CupoyImage 
                        src={`${GLOBAL.URL_PREFIX}/images/icons/fb.png`}
                        stylecomponent={SocialImg}
                        isBackgroundImage
                    />
                </CupoyLink>
            </SocialLink>
            <SocialLink>
                <CupoyLink
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://bit.ly/2werpZ5'
                >
                    <CupoyImage 
                        src={`${GLOBAL.URL_PREFIX}/images/icons/telegram.png`}
                        stylecomponent={SocialImg}
                        isBackgroundImage
                    />
                </CupoyLink>
            </SocialLink>
            <SocialLink>
                <CupoyLink
                    target='_blank'
                    rel='noopener noreferrer'
                    href='mailto:service@9199.com.tw'
                >
                    <CupoyImage 
                        src={`${GLOBAL.URL_PREFIX}/images/icons/mail.png`}
                        stylecomponent={SocialImg}
                        isBackgroundImage
                    />
                </CupoyLink>
            </SocialLink>
            <SocialLink>
                <CupoyLink
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://www.youtube.com/@9199online'
                >
                    <CupoyImage 
                        src={`${GLOBAL.URL_PREFIX}/images/youtube.png`}
                        stylecomponent={SocialImg}
                        isBackgroundImage
                    />
                </CupoyLink>
            </SocialLink>
            <SocialLink>
                <CupoyLink
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://lin.ee/gA63j5z'
                >
                    <CupoyImage 
                        src={`${GLOBAL.URL_PREFIX}/images/line.png`}
                        stylecomponent={SocialImg}
                        isBackgroundImage
                    />
                </CupoyLink>
            </SocialLink>
        </ContactItem>
    </Contact>
);

/*--------------------------
    Main Component
--------------------------*/
class MemberView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);
        this.state = {
        };
        
        this.logout = this.logout.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }
    
    // ------------------------------
    // 登出
    // ------------------------------
    logout() {

        this.props.webActions.openConfirmDialog({
            title: '登出 9199',
            description: '確定要登出？',
            confirmCallback: () => {
                this.props.memberActions.logout();
            },
            confirmName: '登出',
            cancelName: '取消',
        })
    }

    // ------------------------------
    // 修改密碼
    // ------------------------------
    changePassword() {

        const {
            memberinfo,
        } = this.props;

        this.props.webActions.openConfirmDialog({
            title: '修改密碼',
            description: '系統將會發送一封密碼重設定信件，幫助您重新設定您的密碼，確定嗎？',
            confirmCallback: () => {
                this.props.memberActions.forgetPassword({email : memberinfo.email}).then((result) => {
                    this.props.webActions.openAlertDialog({
                        title: '修改密碼申請成功',
                        description: '密碼重設定信件已寄出，請儘速至您的信箱確認。',
                    });
                }, () => {
                    this.props.webActions.openAlertDialog({
                        title: '發生錯誤',
                        description: '系統忙碌中，請稍後再試。',
                    });
                })
            },
            confirmName: '確定',
            cancelName: '取消',
        })
    }

    renderAvatar = (src, isvip) => {
            
        return (
            <CupoySrefView statename={CupoyRouter.STATES.UPGRADE_MEMBERSHIP}>
                <LogoIcon src={src}>
                    {isvip && (
                        <VipTip><i className="icon-vip"/></VipTip>
                    )}
                </LogoIcon>
            </CupoySrefView>
        );
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {
        
        const {
            memberinfo,
        } = this.props;

        const {
            isvip, // 是否是vip
            sex, // gender: male: 1, female: 0
            isemailauth, // 是否已經通過mail認證
        } = memberinfo;

        // var vitemOrderCount = 0,
        //     cardOrderCount = 0;

        // if(memberinfo) {
        //     vitemOrderCount = memberinfo.vitemorder_notprocess + memberinfo.vitemorder_processing;
        //     cardOrderCount = memberinfo.propsorder_notprocess + memberinfo.propsorder_processing;
        // }

        let avatarRsc = 'about:blank';
        // images/vip_member_female_avatar.svg
        if (sex === 0){
            if (isvip)
            avatarRsc = `${GLOBAL.URL_PREFIX}/images/vip_member_female_avatar.svg`; // 女性vip會員
            else if (isemailauth)
            avatarRsc = `${GLOBAL.URL_PREFIX}/images/full_member_female_avatar.svg`; // 女性正式會員(mail已認證)
            else 
            avatarRsc = `${GLOBAL.URL_PREFIX}/images/general_member_female_avatar.svg`; // 女性一般會員(mail未認證)
        } else if (sex === 1){
            if (isvip)
            avatarRsc = `${GLOBAL.URL_PREFIX}/images/vip_member_male_avatar.svg`; // 男性vip會員
            else if (isemailauth)
            avatarRsc = `${GLOBAL.URL_PREFIX}/images/full_member_male_avatar.svg`; // 男性正式會員(mail已認證)
            else
            avatarRsc = `${GLOBAL.URL_PREFIX}/images/general_member_male_avatar.svg`; // 男性一般會員(mail未認證)
        }

        return (
            <GlobalStyle.Container hasFixedTabbar={true}  backgroundColor="#f5f0f0">
                <TopContainer>
                    {this.renderAvatar(avatarRsc, isvip)}
                    <TopBarContent>
                        {memberinfo.shownametype === 1 ? memberinfo.membername : memberinfo.nickname}
                    </TopBarContent>
                    <TopBarContent margin="2px 0 0 0" size="14px" height="20px" >
                        發財金：{memberinfo.bonusremain ? memberinfo.bonusremain : 0}
                    </TopBarContent>
                    <TopBarContent margin='8px 8px 0' size='14px'>
                        {isvip ? 'VIP會員' : '正式會員'}
                    </TopBarContent>
                </TopContainer>
                {/* <CupoySrefView 
                    statename={CupoyRouter.STATES.MYCOMMODITY}
                >
                    <MemberStyle.RowItemContainer isToprow name="top">
                        <MemberStyle.RowItemLeftContainer>
                            <MemberStyle.LightOrangeIcon >
                                <i className="icon-star" style={{color:"#fff"}}/>
                            </MemberStyle.LightOrangeIcon>
                            <MemberStyle.FuntionTitle>常用商品</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            <div style={{margin:"0 15px 0 0",color:"#6A6A6A",size:"12px"}}></div>
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView> */}

                <CupoySrefView 
                    statename={CupoyRouter.STATES.MEMBER_CARD_ORDER_LIST_FINISH}
                >
                    <MemberStyle.RowItemContainer name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.LightGreenIcon>
                                <i className="icon-gift" style={{color:"#fff"}}/>
                            </MemberStyle.LightGreenIcon>
                            <MemberStyle.FuntionTitle>點卡產包</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            {/* {false && cardOrderCount && cardOrderCount > 0 ?
                                <MemberStyle.FuntionTagStatusContent>
                                    {cardOrderCount}
                                </MemberStyle.FuntionTagStatusContent>
                                :
                                null
                            } */}
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>
                {/* <CupoySrefView 
                    statename={CupoyRouter.STATES.MEMBER_VITEM_ORDER_LIST_SELL}
                >
                    <MemberStyle.RowItemContainer name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.LightBlueIcon>
                                <i className="icon-market" style={{color:"#fff"}}/>
                            </MemberStyle.LightBlueIcon>
                            <MemberStyle.FuntionTitle>{GLOBAL.VITEM_DISPLAY_TEXT}</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right"> */}
                            {/* {vitemOrderCount && vitemOrderCount > 0 ?
                                <MemberStyle.FuntionTagStatusContent>
                                    {vitemOrderCount}
                                </MemberStyle.FuntionTagStatusContent>
                                :
                                null
                            } */}
                            {/* <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/> */}

                <CupoySrefView 
                    statename={CupoyRouter.STATES.MEMBER_BONUS}
                >
                    <MemberStyle.RowItemContainer isToprow name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.OrangeIcon>
                                <i className="icon-coin" style={{color:"#fff"}}/>
                            </MemberStyle.OrangeIcon>
                            <MemberStyle.FuntionTitle>我的發財金</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            {memberinfo && memberinfo.bonusremain ?
                                <MemberStyle.FuntionTextStatusContent>
                                    {memberinfo.bonusremain}
                                </MemberStyle.FuntionTextStatusContent>
                                :
                                null
                            }
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>
                <CupoySrefView 
                    statename={CupoyRouter.STATES.MEMBER_REFUND}
                >
                    <MemberStyle.RowItemContainer name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.PinkIcon>
                                <i className="icon-coin" style={{color:"#fff"}}/>
                            </MemberStyle.PinkIcon>
                            <MemberStyle.FuntionTitle>帳戶退款</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>
                
                <CupoySrefView 
                    statename={CupoyRouter.STATES.MEMBER_INVOICE}
                >
                    <MemberStyle.RowItemContainer name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.BlueIcon>
                                <i className="icon-ticket" style={{color:"#fff"}}/>
                            </MemberStyle.BlueIcon>
                            <MemberStyle.FuntionTitle>發票管理</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>
                <CupoySrefView 
                    statename={CupoyRouter.STATES.SYSTEMMESSAGE}
                >
                    <MemberStyle.RowItemContainer name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.GrayIcon>
                                <i className="icon-ring" style={{color:"#fff"}}/>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>系統訊息</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            {memberinfo && memberinfo.membermsgcnt > 0 ?
                                <MemberStyle.FuntionTagStatusContent>
                                    {memberinfo.membermsgcnt}
                                </MemberStyle.FuntionTagStatusContent>
                                :
                                null
                            }
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>
                <CupoySrefView 
                    statename={CupoyRouter.STATES.HISTORYRECORD}
                >
                    <MemberStyle.RowItemContainer name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.GrayIcon>
                                <i className="icon-fold" style={{color:"#fff"}}/>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>歷程紀錄</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>
                <CupoySrefView statename={CupoyRouter.STATES.MEMBER_INFO}>
                    <MemberStyle.RowItemContainer isToprow name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.PurpleIcon>
                                <i className="icon-user" style={{color:"#fff"}}/>
                            </MemberStyle.PurpleIcon>
                            <MemberStyle.FuntionTitle>修改個人資訊</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            {memberinfo && (!memberinfo.isemailauth || !memberinfo.issmsauth) ?
                                <MemberStyle.FuntionTagStatusContent>
                                    未驗證
                                </MemberStyle.FuntionTagStatusContent>
                                :
                                null
                            }
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>
                <MemberStyle.RowItemContainer name="top" onClick={() => this.changePassword()}>
                    <MemberStyle.RowItemLeftContainer>
                        <MemberStyle.LightPurpleIcon>
                            <i className="icon-lock" style={{color:"#fff"}}/>
                        </MemberStyle.LightPurpleIcon>
                        <MemberStyle.FuntionTitle>修改登入密碼</MemberStyle.FuntionTitle>
                    </MemberStyle.RowItemLeftContainer>
                    <MemberStyle.RowItemRightContainer name="right">
                        <i className="icon-chevron-right" />
                    </MemberStyle.RowItemRightContainer >
                </MemberStyle.RowItemContainer>
                <MemberStyle.RowItemBorder/>

                <MemberStyle.RowItemBorder/>
                <CupoySrefView 
                    statename={CupoyRouter.STATES.MEMBER_CONTACT_SERVICE}
                >
                    <MemberStyle.RowItemContainer isToprow name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.GrayIcon>
                                <i className="icon-phone" style={{color:"#fff"}}/>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>聯絡客服</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            {memberinfo && memberinfo.csmsgcnt > 0 ?
                                <MemberStyle.FuntionTagStatusContent>
                                    {memberinfo.csmsgcnt}
                                </MemberStyle.FuntionTagStatusContent>
                                :
                                null
                            }
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>

                <CupoySrefView statename={CupoyRouter.STATES.HELP}>
                    <MemberStyle.RowItemContainer name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.GrayIcon>
                                <i className="zmdi zmdi-file" style={{color:"#fff", fontSize: 17}}/>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>幫助中心</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>

                <CupoySrefView 
                    statename={CupoyRouter.STATES.ABOUT}
                >
                    <MemberStyle.RowItemContainer name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.GrayIcon>
                                <i className="zmdi zmdi-city" style={{color:"#fff", fontSize: 17}}/>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>關於9199</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>
                <CupoySrefView 
                    statename={CupoyRouter.STATES.DISCLAIMER}
                >
                    <MemberStyle.RowItemContainer isToprow name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.GrayIcon>
                                <i className="icon-info" style={{color:"#fff"}}/>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>免責聲明</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>
                <CupoySrefView 
                    statename={CupoyRouter.STATES.MEMBER_TERMS}
                >
                    <MemberStyle.RowItemContainer name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.GrayIcon>
                                <i className="zmdi zmdi-accounts-alt" style={{color:"#fff", fontSize: 18}}/>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>會員條款</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>
                <CupoySrefView 
                    statename={CupoyRouter.STATES.PRIVACY_POLICY}
                >
                    <MemberStyle.RowItemContainer name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.GrayIcon>
                                <i className="icon-info" style={{color:"#fff"}}/>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>隱私權聲明</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>
                <MemberStyle.RowItemContainer isToprow name="top" onClick={this.logout}>
                    <MemberStyle.RowItemLeftContainer name="left">
                        <MemberStyle.GrayIcon>
                            <i className="icon-power" style={{color:"#fff"}}/>
                        </MemberStyle.GrayIcon>
                        <MemberStyle.FuntionTitle>安全登出</MemberStyle.FuntionTitle>
                    </MemberStyle.RowItemLeftContainer>
                    <MemberStyle.RowItemRightContainer name="right">
                        <i className="icon-chevron-right" />
                    </MemberStyle.RowItemRightContainer >
                </MemberStyle.RowItemContainer>
                <MemberStyle.RowItemBorder/>

                <ContactBlock />

            </GlobalStyle.Container>
        );
    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return (
            <GlobalStyle.Container hasFixedTopbar={false} hasFixedTabbar={true}  backgroundColor="#f5f0f0">
                <TopContainer>

                    <Title>
                        立即加入9199會員
                    </Title>
                    <Slogan>
                        線上遊藝、交易首選
                    </Slogan>
                    <BtnContainer>
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.REGISTER}
                        >
                            <BtnWhite>註冊會員</BtnWhite>
                        </CupoySrefView>
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBERLOGIN}
                        >
                            <BtnOrange>會員登入</BtnOrange>
                        </CupoySrefView>
                    </BtnContainer>
                </TopContainer>
                
                <CupoySrefView statename={CupoyRouter.STATES.HELP}>
                    <MemberStyle.RowItemContainer name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.GrayIcon>
                                <i className="zmdi zmdi-file" style={{color:"#fff", fontSize: 17}}/>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>幫助中心</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>

                <CupoySrefView 
                    statename={CupoyRouter.STATES.ABOUT}
                >
                    <MemberStyle.RowItemContainer name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.GrayIcon>
                                <i className="zmdi zmdi-city" style={{color:"#fff", fontSize: 17}}/>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>關於9199</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>
                <CupoySrefView 
                    statename={CupoyRouter.STATES.DISCLAIMER}
                >
                    <MemberStyle.RowItemContainer isToprow name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.GrayIcon>
                                <i className="icon-info" style={{color:"#fff"}}/>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>免責聲明</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>
                <CupoySrefView 
                    statename={CupoyRouter.STATES.MEMBER_TERMS}
                >
                    <MemberStyle.RowItemContainer name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.GrayIcon>
                                <i className="zmdi zmdi-accounts-alt" style={{color:"#fff", fontSize: 18}}/>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>會員條款</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>
                <CupoySrefView 
                    statename={CupoyRouter.STATES.PRIVACY_POLICY}
                >
                    <MemberStyle.RowItemContainer name="top">
                        <MemberStyle.RowItemLeftContainer name="left">
                            <MemberStyle.GrayIcon>
                                <i className="icon-info" style={{color:"#fff"}}/>
                            </MemberStyle.GrayIcon>
                            <MemberStyle.FuntionTitle>隱私權聲明</MemberStyle.FuntionTitle>
                        </MemberStyle.RowItemLeftContainer>
                        <MemberStyle.RowItemRightContainer name="right">
                            <i className="icon-chevron-right" />
                        </MemberStyle.RowItemRightContainer >
                    </MemberStyle.RowItemContainer>
                </CupoySrefView>
                <MemberStyle.RowItemBorder/>

                <ContactBlock />

            </GlobalStyle.Container>
        )
    }

    render() {

        if(this.props.haslogin) {
            return this.renderLoginContent();
        } else {
            return this.renderNoLoginContent();
        }
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        memberinfo : state.member.memberinfo,
        haslogin : state.member.haslogin,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions : bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberView);