/*=====================================
    虛寶市集

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, {PureComponent} from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import actionCreators from 'actions/creators';
import {GlobalStyle, StepBottomBar} from 'stylecomponents';
import styled from 'styled-components';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import GLOBAL from 'nonnmwebglobal';
import NumberFormat from 'react-number-format';
import bankJSON from 'json/bank.json';

/*--------------------------
    Styled Component
--------------------------*/

const TransactionList = styled.div `

    display: flex;
    justify-content: center;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #EEEEEE, inset 0 1px 0 0 #EEEEEE;
`;

const TransactionImage = styled.div `

    min-width: 86px;
    height: 66px;
    margin-right: 16px;
    background-image: url('${props => props.imageUrl ? props.imageUrl : null}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;

const TransactionInfo = styled.div `

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

`;

const TransactionName = styled.div `

    padding-bottom: 8px;
    font-size: 16px;
    color: #4A4A4A;

`;

const ExchangeRate = styled.div `

    display: inline-block;
    font-size: 13px;
    color: #565151;

`;

const RateNumber = styled.span `

    margin-left: 8px;
    color: #F75C2F;
    font-weight: bolder;
    font-size: 16px;

`;

const BuyQuantityList = styled.div `

    display: flex;
    justify-content: space-between;
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;

`;

const BuyTitle = styled.div `

    font-size: 14px;
    color: #333333;

`;

const BuyConversion = styled.div `

    font-size: 12px;
    color: #969494;

`;

const InfoContentWrapper = styled.div `
    padding: 16px;
    font-size: 13px;
    color: #969494;
`;

const InfoTitle = styled.div `
    font-weight: bolder;
    color: #777777;
`;

const InfoContent = styled.div `
    margin-bottom: 16px;
`;

const Amount = styled.input `

    min-width: 96px;
    height: 36px;
    border-radius: 3px;
    background-color: #ffffff;
    font-size: 14px;
    color: #333333;
    text-align: center;
    border: 1px solid ${props => props.error
    ? 'red'
    : '#CCCCCC'};

`;

const FromWrapper = styled.div `

    padding: 16px;
    background-color: #fff;
    font-size: 12px;
    color: #232423;
    
`;

const FormInput = styled.input `

    &::placeholder{
        opacity: 0.5;
        font-size: 15px;
        color: #222328;
    }
    margin-top: 8px;
    margin-bottom: 16px;
    padding: 12px;
    width: 100%;
    height: 44px;
    background: #FAFAFA;
    border-radius: 2px;
    border: 1px solid ${props => props.error
    ? 'red'
    : '#DDDDDD'};

`;

const FormSelector = styled.select `

    font-size: 15px;
    color: rgba(34,35,40,0.5);
    margin-top: 8px;
    margin-bottom: 16px;
    padding: 12px;
    width: 100%;
    height: 44px;
    background: #FAFAFA;
    border: 1px solid #DDDDDD;
    border-radius: 2px;

`;

const NarrativeTextarea = styled
    .textarea
    .attrs({placeholder: '請注意！伺服器內角色所填寫資料,如與實際交易角色ID不同,供應商將取消該筆訂單'})`
    &::placeholder{
        opacity: 0.5;
        font-size: 15px;
        color: #222328;
    }
    margin-top: 8px;
    padding: 12px;
    width: 100%;
    height: 86px;
    background: #FAFAFA;
    border: 1px solid ${props => props.error
    ? 'red'
    : '#DDDDDD'};
    border-radius: 2px;
    
`;

const BankFormWrapper = styled.div `

    padding: 16px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;

`;

const BankFormTitle = styled.div `

    margin-bottom: 16px;
    padding-bottom: 16px;
    font-size: 20px;
    color: #333333;
    border-bottom: 1px solid #DDDDDD;
    
`;

const BackFormItem = styled.div ``;

const EvaluationTitle = styled.div `

    display: flex;
    align-items: center;
    margin-top: 16px;
    padding-bottom: 16px;
    font-size: 20px;
    color: #333333;
    border-bottom: 1px solid #DDDDDD;

`;

const EvaluationComments = styled.span `

    padding-left: 12px;
    font-size: 12px;
    color: #595A59;

`;

const ChooseEvaluation = styled.div `

    display: flex;
    justify-content: space-around;
    padding-top: 16px;
    padding-bottom: 16px;

`;

const CheckWrapper = styled.div `

    display: none;
    position: absolute;
    top: -4px;
    right: 0;
    font-size: 24px;
    color: #F75C2F;
    border-radius: 0 3px 0 3px;

`;

const ChooseItem = styled.div `

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 161px;
    height: 44px;
    border: ${props => props.active
    ? '1px solid #F75C2F'
    : '1px solid #CCCCCC'};
    border-radius: 3px;
    cursor: pointer;

    ${CheckWrapper}{
        display: ${props => props.active
        ? 'block'
        : 'none'};
    }
`;

const AgreeTitle = styled.div `

    margin-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #DDDDDD;
    font-size: 20px;
    color: #333333;

`;

const AgreeWrapper = styled.div `
    position: absolute;
    top: -5px;
    left: -1px;
    width: 28px;
    height: 28px;
    color: #F75C2F;
    font-size: 36px;
`;

const AgreeBlock = styled.div `

    display: flex;
    align-items: center;
    height: 112px;
    margin-top: 16px;
    padding: 16px 16px 16px 12px;
    background: #FAFAFA;
    border: 1px solid #D6D6D6;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.10);
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid ${props => props.error
    ? 'red'
    : 'none'};
    ${AgreeWrapper}{
        display: ${props => props.active
        ? 'block'
        : 'none'};
    }

`;

const AgreeCheckbox = styled.div `

    position: relative;
    min-width: 28px;
    height: 28px;
    margin-right: 12px;
    background: #FFFFFF;
    border: 2px solid #C1C1C1;
    border-radius: 2px;

`;

const AgreeText = styled.div `

    font-size: 14px;
    color: #000000;

`;

const SendOrder = styled.div `

    height: 44px;
    margin-top: 32px;
    line-height: 44px;
    text-align: center;
    background: #F75C2F;
    border-radius: 2px;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;

`;

/*--------------------------
    Main Component
--------------------------*/
class BuyDetailView extends PureComponent {

    // ------------------------------ constructor ------------------------------
    constructor(props) {
        super(props);

        this.state = {
            dollar: '',
            ntdollar: undefined,
            vitemdollar: undefined,
            conversion: undefined,
            proportion: undefined,
            minlimit: undefined,
            maxlimit: undefined,
            error_dollarInput: false,
            error_input: '',
            isPurchasePage: false,
            viprovname: '',
            serverrole: '',
            txtcontent: '',
            orderprocess: '',
            ordertime: '',
            bankaccount: '',
            bankData: [],
            currentBankCode: '',
            currentBank: '',
            currentSubBankCode: '',
            currentSubBank: '',
            defaultgrade: 0,
            bankcode: '',
            bank: '',
            subbank: '',
            subname: '',
            subcode: '',
            agree: false,
            isValidationError: true,
            isServerroleError: false,
            isOrdertimeError: false,
            isOrderprocessError: false,
            isBankcodeError: false,
            isSubcodeError: false,
            isBankaccountError: false,
            isAgreeError: false
        };

        this.error_focus = this
            .error_focus
            .bind(this);
        this.updateTxtContent = this
            .updateTxtContent
            .bind(this);
        this.initDollar = this
            .initDollar
            .bind(this);
        this.validateDollar = this
            .validateDollar
            .bind(this);
        this.chooseEvaluation = this
            .chooseEvaluation
            .bind(this);
        this.clickAgree = this
            .clickAgree
            .bind(this);
        this.onBankChange = this
            .onBankChange
            .bind(this);
        this.onSubBankChange = this
            .onSubBankChange
            .bind(this);
        this.orderValidate = this
            .orderValidate
            .bind(this);
    }

    // ------------------------------ componentDidMount
    // ------------------------------
    componentDidMount() {
        const {gameid, gameserverid, viprovid, purchaseid} = this.props.match.params;
        const purchaseMap = this.props.purchaseMap;
        const gameListMap = this.props.gameListMap;

        if (Object.keys(gameListMap).length === 0) {
            this
                .props
                .gameActions
                .listGames();
        }
        if (Object.keys(purchaseMap).length === 0) {
            this
                .props
                .vitemActions
                .listPurchases({gameid, gameserverid})
                .then((result) => {
                    this.initDollar();
                });
        } else {
            this.initDollar();
        }

        if (this.props.haslogin){
            this
                .props
                .vitemActions
                .initPurchaseOrder({gameid, gameserverid, viprovid, purchaseid})
                .then((result) => {
                    // console.log(result);
                    this.setState({isValidationError: false})
                }, error => {
                    var errorMsg = '請重新登入';
                    var errorDescription = '';

                    if (error && error.code) {
                        switch (error.code) {
                            case 'error.privilege.noLogin':
                                return;
                            default:
                                errorMsg = error.message + '，無法建立訂單！';
                                break;
                        }
                    }

                    this
                        .props
                        .webActions
                        .openAlertDialog({title: errorMsg, description: errorDescription});
                });

            this.setState({bankData: bankJSON});
        }
    }

    initDollar() {

        const purchaseMap = this.props.purchaseMap;
        const {gameid, gameserverid, vprovmrscid} = this.props.match.params;

        const {ntdollar, vitemdollar, maxlimit, minlimit} = purchaseMap[`${vprovmrscid},${gameid},${gameserverid}`];

        const proportion = vitemdollar / ntdollar;

        this.setState({
            ntdollar,
            vitemdollar,
            conversion: Math.round(minlimit * proportion),
            proportion,
            maxlimit,
            minlimit
        });
    }

    error_focus() {
        this
            .numberInput
            .focus();
    }

    updateTxtContent(event, updateInput) {
        if (updateInput === 'dollar') {
            const {proportion} = this.state;

            this.setState({
                [updateInput]: Number(event.target.value),
                conversion: Math.round(Number(event.target.value) * proportion)
            });
        } else if (updateInput === "bankcode") {
            const bankcode = event.target.value;
            if (bankcode && bankcode.length >= 3) {
                const _bankcode = bankcode.substring(0, 3);
                const bankData = this.state.bankData;
                for (let index in bankData) {
                    const bank = bankData[index];
                    if (bank.bankcode.indexOf(_bankcode) === 0) {
                        this.setState({currentBank: bank, currentBankCode: bank.bankcode, currentSubBank: '', currentSubBankCode: ''});

                        // for loop 找尋分行
                        if (bankcode.length >= 7) {
                            if (Array.isArray(bank.subbank)) {

                                for (let index in bank.subbank) {
                                    const subbank = bank.subbank[index];
                                    const combBankCode = bank.bankcode + subbank.subcode;
                                    if (combBankCode === bankcode) {
                                        this.setState({currentSubBank: subbank, currentSubBankCode: subbank.subcode});
                                    }
                                }
                            }
                        } else {
                            this.setState({currentSubBank: '', currentSubBankCode: ''});
                        }
                    }
                }
            }

            // if(bankcode && bankcode.length > 7) {     return; }

            this.setState({[updateInput]: event.target.value});
        } else {
            this.setState({[updateInput]: event.target.value});
        }

    }
    validateDollar(isOpen) {

        if(!isOpen) {
            return;
        }

        const {
            dollar,
            maxlimit,
            minlimit,
            ntdollar,
        } = this.state;

        const { purchaseMap } = this.props;
        const { gameid, gameserverid, vprovmrscid } = this.props.match.params;
        const purchase = purchaseMap[`${vprovmrscid},${gameid},${gameserverid}`];

        const {
            selllimittype,
            selllimitval,
            hadBoughtVitem,
        } = purchase;

        const sellLimit = selllimittype > 0 ? selllimittype : selllimitval;

        if ((sellLimit > 0) && !hadBoughtVitem){
            this.props.webActions.openAlertDialog({
                title: '錯誤訊息',
                description: `此『${GLOBAL.VITEM_DISPLAY_TEXT}-遊戲幣收購區』會員已設定【${selllimittype > 0 ? selllimittype : selllimitval}週內】購買遊戲幣的週期限制，因您於『${GLOBAL.VITEM_DISPLAY_TEXT}-遊戲幣出售區』無購買紀錄所以無法販售商品，不予收購此筆交易!`,
                closeName: '關閉',
            });
            return;
        }

        if ((dollar <= maxlimit && dollar >= minlimit) && (dollar % ntdollar === 0)) {

            this.setState({error_dollarInput: false, isPurchasePage: true});

        } else {
            this.setState({error_dollarInput: true});
            this
                .props
                .webActions
                .openAlertDialog({
                    title: '錯誤訊息',
                    description: '出售金額只能大於' + minlimit + '小於' + maxlimit + '且為' + ntdollar + '的倍數',
                    closeName: '關閉',
                    closeCallback: () => this.error_focus()
                });

        }
    }

    orderValidate() {
        const {
            dollar, // 交易金額(台幣)
            maxlimit, // 最大交易金額(台幣)
            minlimit, // 最小交易金額(台幣)
            ntdollar, // 交易金額台幣比例
            serverrole, // 角色名稱
            ordertime, // 交易時間
            orderprocess, // 交易過程詳述
            currentBank, // 銀行資料  *
            currentSubBank, // 分行資料 *
            bankaccount, // 銀行帳號
            defaultgrade, // 評價
            agree, // 同意交易
        } = this.state;

        const {gameid, gameserverid, purchaseid, vprovmrscid} = this.props.match.params
        const {memberid: vprovmemberid} = this.props.purchaseMap[`${vprovmrscid},${gameid},${gameserverid}`]
        const {addPurchaseOrder} = this.props.vitemActions;

        let isDollarError = false;
        let isError = false;
        let isServerroleError = false;
        let isOrdertimeError = false;
        let isOrderprocessError = false;
        let isBankcodeError = false;
        let isBankaccountError = false;
        let isAgreeError = false;

        if (!(dollar <= maxlimit && dollar >= minlimit) || !(dollar % ntdollar === 0)) { // 出售金額檢查
            isError = true;
            isDollarError = true;
        }
        if (!serverrole.trim()) {
            isError = true;
            isServerroleError = true;
        }
        if (!ordertime.trim()) {
            isError = true;
            isOrdertimeError = true;
        }
        if (!orderprocess.trim()) {
            isError = true;
            isOrderprocessError = true;
        }
        if (!currentBank.bankcode || !currentSubBank.subcode) {
            isError = true;
            isBankcodeError = true;
        }
        if (!(bankaccount && bankaccount.length <= 14)) {
            isError = true;
            isBankaccountError = true;
        }
        if (!agree) {
            isError = true;
            isAgreeError = true;
        }

        this.setState({
            error_dollarInput: isDollarError,
            isServerroleError,
            isOrdertimeError,
            isOrderprocessError,
            isBankcodeError,
            isBankaccountError,
            isAgreeError
        });

        if (isError) {

            if (isDollarError) {
                this
                    .numberInput
                    .focus();

            } else if (isServerroleError) {
                this
                    .serverroleInput
                    .focus();

            } else if (isOrdertimeError) {
                this
                    .ordertimeInput
                    .focus();

            } else if (isOrderprocessError) {
                this
                    .orderprocessInput
                    .focus();

            } else if (isBankcodeError) {
                this
                    .bankcodeInput
                    .focus();

            } else if (isBankaccountError) {
                this
                    .bankaccountInput
                    .focus();
            }
        } else {

            addPurchaseOrder({ // 需要建立訂單 執行addPurchaseOrder
                'purchaseid': purchaseid,
                'gameid': gameid,
                'itemprice': dollar,
                'vprovmemberid': vprovmemberid.trim(),
                'vprovmrscid': vprovmrscid,
                'defaultgrade': defaultgrade,
                'serverrole': serverrole,
                'ordertime': ordertime,
                'orderprocess': orderprocess,
                'bankcode': currentBank.bankcode,
                'bank': currentBank.bank,
                'subbank': currentSubBank.subcode,
                'bankaccount': bankaccount
            }).then((result) => {
                // console.log(result);
                this
                    .props
                    .webActions
                    .openAlertDialog({title: "", description: `交易完成後後請至會員資訊內『${GLOBAL.VITEM_DISPLAY_TEXT}＞收購區＞指定收購區』進行供貨商評價。`});
                this
                    .props
                    .webActions
                    .cupoyStateGo(CupoyRouter.STATES.MEMBER_VITEM_ORDER_LIST_BUY);

            }, (error) => {
                var errorMsg = '請重新登入';
                var errorDescription = '';

                if (error && error.code) {
                    switch (error.code) {
                        case 'error.privilege.noLogin':
                            return;
                        default:
                            errorMsg = error.message;
                            break;
                    }
                }

                this
                    .props
                    .webActions
                    .openAlertDialog({title: errorMsg, description: errorDescription});
            });
        }
    }

    chooseEvaluation(event, evaluation) {
        this.setState({defaultgrade: evaluation});
    }

    clickAgree() {
        this.setState(prevState => ({
            agree: !prevState.agree
        }));
    }

    onBankChange(event) {
        const bankCode = event.target.value;
        const bankData = this.state.bankData;
        for (let index in bankData) {
            const bank = bankData[index];
            if (bank.bankcode === bankCode) {
                this.setState({currentBank: bank, currentBankCode: bank.bankcode, currentSubBank: '', currentSubBankCode: '', bankcode: bank.bankcode});
                break;
            }
        }
    }

    onSubBankChange(event) {
        const bankCode = event.target.value;
        const currentBank = this.state.currentBank;
        for (let index in currentBank.subbank) {
            const bank = currentBank.subbank[index];
            if (bank.subcode === bankCode) {
                this.setState({
                    currentSubBank: bank,
                    currentSubBankCode: bank.subcode,
                    bankcode: this.state.currentBankCode + bank.subcode
                });
                break;
            }
        }
    }

    // ------------------------------ render ------------------------------
    render() {
        const {gameid, gameserverid, vprovmrscid} = this.props.match.params;

        const purchaseMap = this.props.purchaseMap;
        const gameListMap = this.props.gameListMap;
        const haslogin = this.props.haslogin;

        if (Object.keys(gameListMap).length === 0) {
            return null;
        }
        if (Object.keys(purchaseMap).length === 0) {
            return null;
        }

        const { thumbimg } = gameListMap[gameid];

        const purchaser = purchaseMap[`${vprovmrscid},${gameid},${gameserverid}`]

        const {
            gamename,
            nickname,
            notice,
            selllimittype,
            selllimitval,
        } = purchaser;

        const isOpen = GLOBAL.getVProviderIsOpen(purchaser);
        
        const {
            dollar,
            ntdollar,
            vitemdollar,
            minlimit,
            maxlimit,
            error_dollarInput,
            isPurchasePage,
            defaultgrade,
            serverrole,
            orderprocess,
            bankcode,
            conversion,
            agree,
            bankData,
            currentBankCode,
            currentBank,
            currentSubBankCode,
            ordertime,
            isServerroleError,
            isOrdertimeError,
            isOrderprocessError,
            isBankcodeError,
            isBankaccountError,
            isAgreeError,
            bankaccount
        } = this.state;

        const sellLimit = selllimittype > 0 ? selllimittype : selllimitval;

        return (
            <GlobalStyle.Container hasFixedTopbar={true} backgroundColor='#f5f5f5'>
                <TopbarView
                    isFixed={true}
                    leftComponent={<CupoySrefView statename = {
                    CupoyRouter.STATES.TRANSACTIONLIST_BUY
                }
                stylecomponent = {
                    GlobalStyle.TopbarButton
                }
                params = {{ gameid, serverid: gameserverid, }} > <GlobalStyle.TopbarIcon>
                    <i className="zmdi zmdi-chevron-left"></i>
                </GlobalStyle.TopbarIcon>收購區 </CupoySrefView>}
                    middleComponent={gamename}/>

                <TransactionList>
                    {!isPurchasePage
                        ? <TransactionImage
                                imageUrl={GLOBAL.imgSrcRootURL + `/gameimgs/${gameid}/${thumbimg}`}/>
                        : null
}
                    <TransactionInfo>
                        <TransactionName>遊戲暱稱：{nickname}</TransactionName>
                        <div>
                            <ExchangeRate>
                                比例(台幣：虛幣)
                                <RateNumber>{ntdollar}:{vitemdollar}</RateNumber>
                            </ExchangeRate>
                        </div>
                    </TransactionInfo>
                </TransactionList>
                <BuyQuantityList>
                    <div>
                        <BuyTitle>出售金額（台幣）</BuyTitle>
                        <BuyConversion>
                            換算為虛幣金額
                            <NumberFormat
                                value={conversion
                                ? conversion
                                : 0}
                                displayType={'text'}
                                thousandSeparator={true}/>
                        </BuyConversion>
                    </div>
                    <Amount
                        type="number"
                        value={dollar}
                        min={minlimit}
                        max={maxlimit}
                        step={ntdollar}
                        error={error_dollarInput}
                        ref={(input) => {
                        this.numberInput = input;
                    }}
                        onChange={(event) => this.updateTxtContent(event, 'dollar')}/>
                </BuyQuantityList>
                {!isPurchasePage
                    ? <div>
                            <InfoContentWrapper>
                                <InfoTitle>交易時間與地點：</InfoTitle>
                                <InfoContent>{notice}</InfoContent>
                                <InfoTitle>提示：</InfoTitle>
                                <InfoContent>
                                    出售金額下限(台幣NT) {minlimit}元<br/>
                                    出售金額上限(台幣NT) {maxlimit}元
                                </InfoContent>
                                {
                                    (sellLimit > 0) && (
                                        <InfoContent>
                                            此『{GLOBAL.VITEM_DISPLAY_TEXT}-遊戲幣收購區』會員已設定【{selllimittype > 0 ? selllimittype : selllimitval}週內】購買遊戲幣的週期限制
                                            因您於『{GLOBAL.VITEM_DISPLAY_TEXT}-遊戲幣出售區』無購買紀錄所以無法販售商品，不予收購此筆交易!
                                        </InfoContent>
                                    )
                                }
                            </InfoContentWrapper>

                            <StepBottomBar.StepBarWrapper>
                                <StepBottomBar.StepBarText>
                                    總價：
                                    <NumberFormat
                                        value={dollar
                                        ? dollar
                                        : 0}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        renderText={value =>< StepBottomBar.StepBarPrice > {
                                        value
                                    }
                                    元 </StepBottomBar.StepBarPrice>}/>

                                </StepBottomBar.StepBarText>

                                {haslogin?
                                    <StepBottomBar.StepBarButton
                                        onClick={() => this.validateDollar(isOpen)}
                                        disable={!isOpen}>
                                            立刻出售
                                    </StepBottomBar.StepBarButton>
                                : 
                                    <StepBottomBar.StepBarButton
                                        onClick={() => {
                                        this
                                            .props
                                            .webActions
                                            .cupoyStateGo(CupoyRouter.STATES.MEMBERLOGIN);
                                    }}>
                                        請先登入
                                    </StepBottomBar.StepBarButton>
                                }

                            </StepBottomBar.StepBarWrapper>
                        </div>
                    : null
}

                {isPurchasePage
                    ? <div>
                            <FromWrapper>
                                伺服器內角色
                                <FormInput
                                    value={serverrole}
                                    error={isServerroleError}
                                    onChange={event => this.updateTxtContent(event, 'serverrole')}
                                    ref={_serverroleInput => this.serverroleInput = _serverroleInput}/>
                                交易時間
                                <FormInput
                                    placeholder="請輸入交易時間"
                                    value={ordertime}
                                    error={isOrdertimeError}
                                    onChange={event => this.updateTxtContent(event, 'ordertime')}
                                    ref={_ordertimeInput => this.ordertimeInput = _ordertimeInput}/>
                                交易過程詳述
                                <NarrativeTextarea
                                    value={orderprocess}
                                    error={isOrderprocessError}
                                    onChange={event => this.updateTxtContent(event, 'orderprocess')}
                                    ref={_orderprocessInput => this.orderprocessInput = _orderprocessInput}/>
                            </FromWrapper>
                            <BankFormWrapper>
                                <BankFormTitle>轉入銀行</BankFormTitle>

                                <BackFormItem>
                                    銀行總稱
                                    <FormSelector
                                        value={currentBankCode}
                                        onChange={event => this.onBankChange(event)}>
                                        <option>銀行別</option>
                                        {bankData.map((bank, index) => {
                                            return (
                                                <option key={index} value={bank.bankcode}>{bank.bankcode} {bank.bank}</option>
                                            )
                                        })}
                                    </FormSelector>
                                </BackFormItem>

                                {currentBank && currentBank.subbank
                                    ? <BackFormItem>
                                            分行名稱
                                            <FormSelector
                                                value={currentSubBankCode}
                                                onChange={event => this.onSubBankChange(event)}>
                                                <option>分行別</option>
                                                {currentBank
                                                    .subbank
                                                    .map((bank, index) => {
                                                        return (
                                                            <option key={index} value={bank.subcode}>{bank.subcode} {bank.subname}</option>
                                                        )
                                                    })}
                                            </FormSelector>
                                        </BackFormItem>
                                    : null
}
                                <BackFormItem>
                                    銀行代碼
                                    <FormInput
                                        type="tel"
                                        maxLength="7"
                                        placeholder="請輸入銀行代碼7碼"
                                        error={isBankcodeError}
                                        onChange={event => this.updateTxtContent(event, 'bankcode')}
                                        ref={_bankcodeInput => this.bankcodeInput = _bankcodeInput}
                                        value={bankcode}/>
                                </BackFormItem>
                                <BackFormItem>
                                    銀行帳號
                                    <FormInput
                                        type="tel"
                                        maxLength="14"
                                        min="0"
                                        max="99999999999999"
                                        placeholder="最多14碼"
                                        error={isBankaccountError}
                                        onChange={event => this.updateTxtContent(event, 'bankaccount')}
                                        ref={_bankaccountInput => this.bankaccountInput = _bankaccountInput}
                                        value={bankaccount}/>
                                </BackFormItem>
                                <EvaluationTitle>
                                    評價
                                    <EvaluationComments>
                                        (若買方48小時內未付款取消交易時，自動給予評價)
                                    </EvaluationComments>
                                </EvaluationTitle>
                                <ChooseEvaluation>
                                    <ChooseItem
                                        active={defaultgrade === 0}
                                        onClick={event => this.chooseEvaluation(event, 0)}>
                                        中立評價
                                        <CheckWrapper>
                                            <i className="zmdi zmdi-check-square"></i>
                                        </CheckWrapper>
                                    </ChooseItem>
                                    <ChooseItem
                                        active={defaultgrade === 1}
                                        onClick={event => this.chooseEvaluation(event, 1)}>
                                        負面評價
                                        <CheckWrapper>
                                            <i className="zmdi zmdi-check-square"></i>
                                        </CheckWrapper>
                                    </ChooseItem>
                                </ChooseEvaluation>
                                <AgreeTitle>注意事項</AgreeTitle>
                                <AgreeBlock error={isAgreeError} active={agree} onClick={this.clickAgree}>
                                    <AgreeCheckbox>
                                        <AgreeWrapper>
                                            <i className="zmdi zmdi-check-square"></i>
                                        </AgreeWrapper>
                                    </AgreeCheckbox>
                                    <AgreeText>
                                        我同意：9199僅提供雙方聯繫服務，下單後買家將與您聯繫交易，雙方交易商品、金額流程均與9199無任何關聯，請雙方確實進行誠信交易！
                                    </AgreeText>
                                </AgreeBlock>
                                <SendOrder onClick={this.orderValidate}>送出訂單</SendOrder>
                            </BankFormWrapper>
                        </div>
                    : null
}
                {this.props.isloading
                    ? <GlobalStyle.LoadingContainer>
                            <GlobalStyle.Spin/>
                        </GlobalStyle.LoadingContainer>
                    : null
}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function (state) {
    return {haslogin: state.member.haslogin, purchaseMap: state.vitem.purchaseMap, gameListMap: state.game.gameListMap};
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function (dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        vitemActions: bindActionCreators(actionCreators.vitemActionCreators, dispatch),
        gameActions: bindActionCreators(actionCreators.gameActionCreators, dispatch)
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {forwardRef: true})(BuyDetailView);