/*=====================================
    忘記密碼

    Author: Eileen Liao
    createtime: 2018 / 02 / 28
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle,MemberStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import styled from 'styled-components';
import GLOBAL from 'nonnmwebglobal';

/*--------------------------
    Style Component
--------------------------*/
const SubContainer = styled.div`
    margin: ${props => props.margin ? props.margin : '0 auto'};
    width: 100%;
    display : flex;
    flex-direction : column;
    justify-content: flex-start;
    
    flex :  ${props => props.flex ? props.flex : 1};

`;
/*--------------------------
    Main Component
--------------------------*/
class ResetPwdView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);
        this.state = {
            newpwd : '',
            chknewpwd : '',
            token: '',
            mrscid: '',
        }
        this.onChange = this.onChange.bind(this);
        this.updPasswd = this.updPasswd.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        const current = CupoyRouter.getCurrent();

        const {
            params,
        } = current;

        const {
            token,
            mrscid,
        } = params;

        if(!token || !mrscid) {
            this.props.webActions.cupoyStateGoOtherwise(true);
            return;
        }

        this.setState({
            token: token,
            mrscid: mrscid,
        })
    }

    onChange(event){
        let value = event.target.value;
        let name = event.target.name;
        switch(name){
            case "newpwd":
                this.setState({newpwd :value});
                break;
            case "chknewpwd":
                this.setState({chknewpwd :value});
                break;
            default:
                break;
        };
    }

    updPasswd(event){

        const {
            token,
            mrscid,
            newpwd,
            chknewpwd,
        } = this.state;

        if(!newpwd || !chknewpwd || newpwd !== chknewpwd){
            this.props.webActions.openAlertDialog({
                title: '請再次確認您設定的密碼。',
            });
            return;
        }

        if (!GLOBAL.chkPassword(newpwd)) {
            this.props.webActions.openAlertDialog({
                title: '密碼請輸入6-10數字、英文字',
            });
            return;
        }
        
        this.props.memberActions
            .updPassword({
                mrscid : mrscid,
                token: token,
                passwd : newpwd
            }).then((result) => {
                this.props.webActions.openAlertDialog({
                    title: '密碼修改成功',
                    closeName: '確定',
                    closeCallback: () => {
                        this.props.webActions.cupoyStateGoOtherwise(true);
                    }
                });
            }
        )
        .catch(error => { 
            this.setState({
                disableChgPhone : false,
            })
            
            var errorMsg = '系統發生無法預期的錯誤';

            if(error && error.message) {
                errorMsg = error.message;
            }

            this.props.webActions.openAlertDialog({                    
                title: '錯誤訊息',
                description : errorMsg,
                closeName: '確定',
                closeCallback: () => {

                }
            });
         });
    }    

    // ------------------------------
    // render
    // ------------------------------
    render() {
        return (
            <GlobalStyle.Container  hasFixedTopbar={true} hasFixedTabbar={false} >
            
                <TopbarView
                isFixed={true}
                leftComponent={
                    <CupoySrefView 
                        statename={CupoyRouter.STATES.HOME}
                        stylecomponent={GlobalStyle.TopbarButton}
                    >   
                        <GlobalStyle.TopbarIcon>
                            <i className="zmdi zmdi-chevron-left"></i>
                        </GlobalStyle.TopbarIcon>
                        首頁
                    </CupoySrefView>
                }
                middleComponent="重設密碼"
            />
                <MemberStyle.MemberContent>
                    <SubContainer>
                        <MemberStyle.FieldTitle>新密碼</MemberStyle.FieldTitle>
                        <MemberStyle.MemberInputBox2  type="password" name="newpwd" onChange={this.onChange}/>
                    </SubContainer>
                    <SubContainer margin="12px 0 0 0">
                        <MemberStyle.FieldTitle>確認密碼</MemberStyle.FieldTitle>
                        <MemberStyle.MemberInputBox2  type="password" name="chknewpwd" onChange={this.onChange}/>
                    </SubContainer>
                    
                    <MemberStyle.BottomBar style={{flex:1,margin:"0 0 20px 0"}}>
                    <MemberStyle.ConfirmBtn onClick={this.updPasswd}>完成</MemberStyle.ConfirmBtn>
                </MemberStyle.BottomBar>

                </MemberStyle.MemberContent>
            </GlobalStyle.Container>
            );
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions : bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(ResetPwdView);