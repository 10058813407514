/*=====================================
    重設Email

    Author: Eileen Liao
    createtime: 2018 / 03 / 02
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import GLOBAL from 'nonnmwebglobal';
import { GlobalStyle,MemberStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import styled from 'styled-components';


/*--------------------------
    Style Component
--------------------------*/
const NoteDescription =  styled.div`
    display:flex;
    margin : 10px 0 0 0;
    height : 40px;
    width : 100%;
    font-size: 12px;
    color: #595A59;
    justify-content: left;
    align-items : center;
`;


/*--------------------------
    Main Component
--------------------------*/

class ResetMailView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {
        super(props);

        const { memberinfo } = this.props;

        const { email = '', isemailauth } = memberinfo;

        this.state = {
            memberinfo: [],
            email : email && !isemailauth ? email.trim() : '',
            auth : '',
            isFinishCheckEmail: email && !isemailauth,
        };
        this.onChange = this.onChange.bind(this);

        this.emailCodeChange = this.emailCodeChange.bind(this);
        this.sendVerifycode = this.sendVerifycode.bind(this);
        this.verifyEMailCode = this.verifyEMailCode.bind(this);

    }
    onChange(event) {

        this.setState({
            email: event.target.value,
            isFinishCheckEmail: false,
            auth: '',
        });

    }
    emailCodeChange(event){

        this.setState({
            auth: event.target.value,
        });

    }
    sendVerifycode(){

        //check 手機號碼
        let emailValid  = GLOBAL.isEmail(this.state.email);
        if (!emailValid){
            alert("電子郵件輸入錯誤");
            return;
        }
        //for test
        this.props.memberActions.updEMailAndSendAuth({email: this.state.email}).then((result) => {

            this.props.webActions.openAlertDialog({
                title: '驗證碼已發送至您的信箱，請收信取得驗證碼',
                closeName: '確定',
            });

        },(error) => {
            if(error && error.code) {
                var errorMsg = '';
                switch(error.code) {
                    case 'error.account.accountNotExist':
                        errorMsg = "此帳號不存在";
                        break;
                    default:
                        errorMsg = error.message;
                        break;
                }
                this.props.webActions.openAlertDialog({
                    title: '錯誤訊息',
                    description : errorMsg,
                    closeName: '確定',
                });
            }
        })
    }

    verifyEMailCode(){

        const { auth = '' } = this.state;

        if(!auth.trim()) return;

        this.props.memberActions.verifyEmailAuth({
            emailauth:this.state.auth,
            email:this.state.email,
            mrscid:this.props.memberinfo.mrscid
        }).then(result => {
            // 重新取得會員資料
            this.props.memberActions.getMemberAcntInfo();

            this.props.webActions.openAlertDialog({
                title: '驗證成功',
                closeName: '確定',
                closeCallback: () => {
                    this.props.webActions.cupoyStateGo(CupoyRouter.STATES.MEMBER);
                }
            });

        }).catch(error => {
            if(error && error.code) {
                let errorMsg = '';
                switch(error.code) {
                    case 'error.privilege.tokenFailed':
                        errorMsg = "驗證碼錯誤";
                        break;
                    default:
                        errorMsg = error.message;
                        break;
                };
                this.props.webActions.openAlertDialog({
                    title: '錯誤訊息',
                    description : errorMsg,
                    closeName: '確定',
                    closeCallback: () => {
                        this.emailInput.focus();
                    }
                });
            }
        });
    }

    chkEMail = () => {

        const { email = '', isFinishCheckEmail }  = this.state;

        if(!email.trim() || isFinishCheckEmail) return;

        if(!GLOBAL.isEmail(email)){
            this.setState({
                isFinishCheckEmail: false,
            })

            this.props.webActions.openAlertDialog({
                title: "電子信箱輸入錯誤",
            });
            return;
        }

        this.props.registerActions.checkEmail({ email }).then((result) => {
            if(result){
                const errorMap = Object.assign({}, this.state.errorMap, {
                    email: false,
                })

                this.setState({
                    isFinishCheckEmail: true,
                    errorMap: errorMap,
                });
                this.props.webActions.openAlertDialog({
                    title: "EMail可使用",
                });
            } else {
                this.setState({
                    isFinishCheckEmail: false,
                })
                this.props.webActions.openAlertDialog({
                    title: "EMail已被註冊",
                });
            }
        }, () =>{
            this.setState({
                isFinishCheckEmail: false,
            })
            this.props.webActions.openAlertDialog({
                title: "EMail已被註冊",
            });
        });
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const { isFinishCheckEmail, auth = '', email = '' } = this.state;

        return (
            <GlobalStyle.Container hasFixedTopbar hasFixedTabbar={false}>
                <TopbarView
                    isFixed
                    leftComponent={
                        <CupoySrefView
                            statename={CupoyRouter.STATES.MEMBER_INFO}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            個人資料
                        </CupoySrefView>
                    }
                    middleComponent="重設電子信箱"
                />
                <MemberStyle.MemberContent>
                    <MemberStyle.MemberInputBox
                        placeholder="請輸入電子郵件"
                        type="text"
                        ref={(input) => { this.emailInput = input; }}
                        onChange={this.onChange}
                        margin="0"
                        value={email}
                    />
                    {
                        isFinishCheckEmail ?
                        <React.Fragment>
                            <MemberStyle.ConfirmBtn onClick={this.sendVerifycode}>寄送驗證碼</MemberStyle.ConfirmBtn>
                            <MemberStyle.FieldTitle >電子郵件驗證碼</MemberStyle.FieldTitle>
                            <MemberStyle.InputContainer flexdirection="row" margin="0" >
                                <MemberStyle.MemberInputBox2 type="text" width="calc(100% - 100px)" onChange={this.emailCodeChange} value={auth} />
                                <MemberStyle.ConfirmBtn disable={!auth.trim()} margin="8px 0 0 10px" width="100px" onClick={this.verifyEMailCode}>確定</MemberStyle.ConfirmBtn>
                            </MemberStyle.InputContainer>
                        </React.Fragment>
                        :
                        <MemberStyle.ConfirmBtn disable={!email.trim()} onClick={this.chkEMail}>檢查信箱是否已使用</MemberStyle.ConfirmBtn>
                    }
                    <NoteDescription>提示：點擊「發送驗證碼」後，我們會寄送驗證碼到您的信箱，或點開email中的app專用連結完成見證。</NoteDescription>
                </MemberStyle.MemberContent>
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        memberinfo : state.member.memberinfo,
        haslogin : state.member.haslogin,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions : bindActionCreators(actionCreators.memberActionCreators, dispatch),
        registerActions : bindActionCreators(actionCreators.registerActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(ResetMailView);