/*=====================================
    MemberInvoice

    Author: Gray
    CreateTime: 2018 / 03 / 13
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import styled from 'styled-components';
import { GlobalStyle, Colors, MemberStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
import TimeFormat from "components/commons/TimeFormat";
import GLOBAL from 'nonnmwebglobal';
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
    padding: 0 0 10px 0;
`;
const SettingIcon = styled.div`
    line-height: 0;
    font-size: 18px;
`;
const PrizeText = styled.span`
    background-color: ${Colors.Red};
    color: #FFF;
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 5px;
    font-weight: 600;
    margin: 0 6px;
    white-space: normal;
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberInvoiceView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            start: Array.isArray(this.props.invoiceList) ? this.props.invoiceList.length : 0,
            isLoading: false,
            isError: false,
            isEmpty: false,
            isNoMore: false,
        };

        this.listInvoices = this.listInvoices.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
        this.getCarriertypeDesc = this.getCarriertypeDesc.bind(this);
        this.showInvoiceDetail = this.showInvoiceDetail.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        if(prevProps.haslogin !== this.props.haslogin) {
            this.init(this.props);
        }
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props) {

        const {
            haslogin,
            invoiceList,
        } = this.props;

        if(haslogin && ( !Array.isArray(invoiceList) || invoiceList.length === 0)) {
            this.listInvoices();
        }
    }

    // ------------------------------
    // 取得發財金logs
    // ------------------------------
    listInvoices() {

        const {
            start,
            isLoading,
            isEmpty,
            isNoMore,
            isError,
        } = this.state;

        if(isLoading || isEmpty || isNoMore || isError) {
            return;
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.memberActions.listInvoices({
                start: start,
            }).then((result) => {
                const invoiceList = this.props.invoiceList;

                var newState = {
                    isLoading: false,
                    start: invoiceList.length,
                };

                if(!Array.isArray(invoiceList) || invoiceList.length === 0) {
                    newState.isEmpty = true;
                } else if(start === newState.start) {
                    newState.isNoMore = true;
                }

                this.setState(newState);
            }, (error) => {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            })
        })
    }
    /**
     * 取得載具名稱
     * @param {*} isdonate      是否捐贈
     * @param {*} carriertype   載具類別
     * @param {*} carrierid     載具ID
     */
    getCarriertypeDesc(isdonate,carriertype,carrierid){
        if(isdonate)
            return "捐贈碼";
        else
        {
            if(carriertype === 1)
            return "會員載具";
            else if(!GLOBAL.isEmpty(carrierid))
            {
            if( carrierid.length === 8)
                return "手機載具";
            else if(carrierid.length === 16)
                return "自然人憑證載具";
            else if (carriertype === 2)
                return "共通性載具";
            }
            else
                return "";
        }
    }

    showInvoiceDetail(pdtinfo){
        this.props.webActions.openInvoiceDetailDialog({
            invoiceDetail : pdtinfo,
        });
    }

    // ------------------------------
    // rowRenderer
    // ------------------------------
    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it),
        parent,
        measure,
        columnIndex,
        updateRowSize,
        measureCache,
        listWidth,
    }, width, listHeight){

        const {
            invoiceList,
        } = this.props;

        const invoice = invoiceList[index];

        if(!invoice) {
            return null;
        }

        const {
            pdtinfo,
            orderid,
            receipttime,
            istarttag,
            invoicenumber,
            isdonate,
            carriertype,
            carrierid,
            // checkcode,
            amount,
            istatus,
            sendtime,
        } = invoice;

        const havePdtInfo = !!pdtinfo;
        const _invoiceNumber = isdonate
            ? GLOBAL.formatDonatedInvoiceNumber(invoicenumber)
            : invoicenumber;

        const isPrize = istatus === 3 && carriertype === GLOBAL.CARRIER_TYPE.MEMBER;

        return (
            <div
                key={index}
                style={style}
            >
                <MemberStyle.LogsItem link={havePdtInfo}  onClick={() => havePdtInfo ?  this.showInvoiceDetail(pdtinfo) : null}>
                    <MemberStyle.LogsTitle >
                        訂單編號: {orderid}
                    </MemberStyle.LogsTitle>
                    <MemberStyle.LogsDescription>
                        發票日期: <TimeFormat format="simpleFullTime" time={receipttime}/>
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription>
                        發票號碼: {istarttag}-{_invoiceNumber}
                        {isPrize && (
                            <PrizeText>
                                中獎
                                {isPrize && sendtime > 0 && (
                                    <>
                                        &nbsp;
                                        (
                                        <TimeFormat
                                            format='timeString'
                                            time={sendtime}
                                        />
                                        前寄出)
                                    </>
                                )}
                            </PrizeText>
                        )}
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription>載具名稱: {this.getCarriertypeDesc(isdonate, carriertype, carrierid)}</MemberStyle.LogsDescription>
                    {/* <MemberStyle.LogsDescription isLast>四位隨機碼: {checkcode}</MemberStyle.LogsDescription> */}
                    {amount > 0 && <MemberStyle.LogsStatus color={Colors.Orange}>發票金額: {amount} 元</MemberStyle.LogsStatus>}
                </MemberStyle.LogsItem>
            </div>
        )
    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return <NeedLoginBlock />;
    }
    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            isLoading,
            isError,
            isEmpty,
        } = this.state;

        const {
            invoiceList,
        } = this.props;

        return (
            <Content>
                {Array.isArray(invoiceList) && invoiceList.length > 0 ?
                    <CupoyListRepeat
                        listId={this.constructor.name}
                        rowRenderer={this.rowRenderer}
                        items={invoiceList}
                        isLoading={isLoading}
                        useWindowScroll
                        restoreWindowScroll
                        loadMoreRows={() => this.listInvoices()}
                    />
                    :
                    null
                }

                {isLoading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }

                {isError ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            發生錯誤
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無法取得發票明細，請重新整理後再嘗試。
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }

                {isEmpty ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            無任何發票明細
                        </GlobalStyle.ErrorContainerTitle>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }
            </Content>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container
                hasFixedTopbar
                hasFixedTabbar={false}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed
                    leftComponent={
                        <CupoySrefView
                            statename={CupoyRouter.STATES.MEMBER}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            返回
                        </CupoySrefView>
                    }
                    middleComponent="發票管理"
                    rightComponent={
                        <CupoySrefView
                            statename={CupoyRouter.STATES.UPDATE_INVOICE}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >
                            <SettingIcon>
                                <i className="zmdi zmdi-settings"></i>
                            </SettingIcon>
                        </CupoySrefView>
                    }
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        invoiceList: state.member.invoiceList,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberInvoiceView);