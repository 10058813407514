import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle, MemberStyle, Colors } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import styled from 'styled-components';
import GLOBAL from 'nonnmwebglobal';

/**
 * Variables
 */
const monthopt = [
    { label:"01",value:"01" },
    { label:"02",value:"02" },
    { label:"03",value:"03" },
    { label:"04",value:"04" },
    { label:"05",value:"05" },
    { label:"06",value:"06" },
    { label:"07",value:"07" },
    { label:"08",value:"08" },
    { label:"09",value:"09" },
    { label:"10",value:"10" },
    { label:"11",value:"11" },
    { label:"12",value:"12" },
];
const dayopt = [
    { label:"01",value:"01" },
    { label:"02",value:"02" },
    { label:"03",value:"03" },
    { label:"04",value:"04" },
    { label:"05",value:"05" },
    { label:"06",value:"06" },
    { label:"07",value:"07" },
    { label:"08",value:"08" },
    { label:"09",value:"09" },
    { label:"10",value:"10" },
    { label:"11",value:"11" },
    { label:"12",value:"12" },
    { label:"13",value:"13" },
    { label:"14",value:"14" },
    { label:"15",value:"15" },
    { label:"16",value:"16" },
    { label:"17",value:"17" },
    { label:"18",value:"18" },
    { label:"19",value:"19" },
    { label:"20",value:"20" },
    { label:"21",value:"21" },
    { label:"22",value:"22" },
    { label:"23",value:"23" },
    { label:"24",value:"24" },
    { label:"25",value:"25" },
    { label:"26",value:"26" },
    { label:"27",value:"27" },
    { label:"28",value:"28" },
    { label:"29",value:"29" },
    { label:"30",value:"30" },
    { label:"31",value:"31" },
];

/**
 * Styled
 */
const SelectWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 44px;
    margin: 0 0 0 12px;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    background-color: #FAFAFA;

    ${(props) => {
        if(props.error) {
            return `
                border: 1px solid ${Colors.Red};
            `;
        }
    }}

    &:first-child {
        margin: 0;
    }
`;
const Select = styled.select`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 8px;
    border: none;
    background-color: #FAFAFA;
    font-size: 15px;
    color: #222328;
`;
const LoadingWrapper = styled.div`
    margin-right: 10px;
`;

/**
 * 會員重設生日
 * @author Ac
 * @time 2020 / 05 / 06
 */
class ResetBirthdayView extends PureComponent {

    constructor(props) {
        super(props);

        this.alertNeedLogin = this.alertNeedLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBirthday_yy = this.handleChange.bind(this, 'birthday_yy');
        this.handleBirthday_mm = this.handleChange.bind(this, 'birthday_mm');
        this.handleBirthday_dd = this.handleChange.bind(this, 'birthday_dd');
        this.updateMemberInfo = this.updateMemberInfo.bind(this);

        const {
            memberinfo,
        } = props;

        let {
            birthday = '',
        } = memberinfo;

        birthday = birthday.trim();

        this.state = {
            isLoading: false,
            birthday_yy: birthday.slice(0, 4),
            birthday_mm: birthday.slice(4, 6),
            birthday_dd: birthday.slice(6, 8),
        };
    }

    componentDidMount() {
        if(!this.props.haslogin){
            this.alertNeedLogin();
            return;
        }
    }

    componentDidUpdate(prevProps) {
        if((prevProps.haslogin !== this.props.haslogin) && !this.props.haslogin){
            this.alertNeedLogin()
            return;
        }
    }

    alertNeedLogin() {
        this.props.webActions.openAlertDialog({
            title: '錯誤訊息',
            description : '請先登入會員',
            closeName: '關閉',
            closeCallback: () => {
                this.props.webActions.cupoyStateGo(CupoyRouter.STATES.MEMBERLOGIN);
            }
        });
    }

    handleChange(key, event) {

        if(!key) {
            return;
        }

        this.setState({
            [key]: event.target.value,
        });
    }

    updateMemberInfo() {

        const {
            birthday_yy,
            birthday_mm,
            birthday_dd,
        } = this.state;

        if (this.state.isLoading) {
            return;
        }

        if(
            GLOBAL.isEmpty(birthday_yy) ||
            GLOBAL.isEmpty(birthday_mm) ||
            GLOBAL.isEmpty(birthday_dd)
        ) {
            this.props.webActions.openAlertDialog({
                title: '發生錯誤',
                description: '請輸入正確的生日日期',
                closeName: '確定',
            });
            return;
        }

        if(
            !GLOBAL.isEmpty(birthday_yy) &&
            !GLOBAL.isEmpty(birthday_mm) &&
            !GLOBAL.isEmpty(birthday_dd)
        ) {

            const timestamp = Date.parse(`${birthday_yy}-${birthday_mm}-${birthday_dd}`);

            if(!GLOBAL.hasOver18(timestamp)) {
                this.props.webActions.openAlertDialog({
                    title: '發生錯誤',
                    description: '出生日期距今尚未滿18歲',
                    closeName: '確定',
                });
                return;
            }
        }

        this.setState({
            isLoading: true,
        }, () => {

            const {
                memberinfo,
            } = this.props;

            const {
                membername = '',
                nickname = '',
                sex,
                address: _address,
                isdonate,
                npoban,
                carrierid,
                carriertype,
                shownametype,
            } = memberinfo;

            const {
                address,
                city,
                region,
                zipcode,
            } = GLOBAL.parseAddress(_address);

            this.props.memberActions.updMember({
                name: membername.trim(),
                nickname: nickname.trim(),
                birthday: `${birthday_yy}${birthday_mm}${birthday_dd}`,
                city,
                region,
                postalcode: zipcode,
                addreGss: address,
                isdonate,
                npoban,
                carrierid,
                carriertype,
                invoicetype: 1,
                crn: '',
                companytitle: '',
                sex,
                shownametype,
            }).then(() => {
                this.setState({
                    isLoading: false,
                }, () => {
                    this.props.webActions.openAlertDialog({
                        title: "資料更新成功",
                        closeName: '確定',
                    }, () => {
                        this.props.memberActions.getMemberAcntInfo();
                    });
                });
            }).catch(error => {

                this.setState({
                    isLoading: false,
                });

                this.props.webActions.openAlertDialog({
                    title: "錯誤訊息",
                    description : error && error.message
                        ? error.message
                        : '發生無法預期的錯誤，請洽客服人員',
                    closeName: '確定',
                });
            });

        });
    }

    render() {

        const {
            isLoading,
            birthday_yy,
            birthday_mm,
            birthday_dd,
        } = this.state;

        return (
            <GlobalStyle.Container
                hasFixedTopbar
                hasFixedTabbar={false}
            >
                <TopbarView
                    isFixed
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER_INFO}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            個人資料
                        </CupoySrefView>
                    }
                    middleComponent='重設出生日期'
                />
                <MemberStyle.MemberContent>
                    <MemberStyle.InputContainer
                        flexdirection="row"
                        justify="space-between"
                        style={{alignContent:"flex-start"}}
                    >
                        <SelectWrapper>
                            <Select
                                value={birthday_yy}
                                onChange={this.handleBirthday_yy}
                            >
                                <option value={''}>西元年</option>
                                {GLOBAL.getYear(1910).map((year, index) => {
                                    return (
                                        <option key={index} value={year}>
                                            {year}
                                        </option>
                                    );
                                })}
                            </Select>
                        </SelectWrapper>
                        <SelectWrapper>
                            <Select
                                value={birthday_mm}
                                onChange={this.handleBirthday_mm}
                            >
                                <option value={''}>月份</option>
                                {monthopt.map((month, index) => {
                                    return (
                                        <option key={index} value={month.value}>
                                            {month.label}
                                        </option>
                                    );
                                })}
                            </Select>
                        </SelectWrapper>
                        <SelectWrapper>
                            <Select
                                value={birthday_dd}
                                onChange={this.handleBirthday_dd}
                            >
                                <option value={''}>日期</option>
                                {dayopt.map((day, index) => {
                                    return (
                                        <option key={index} value={day.value}>
                                            {day.label}
                                        </option>
                                    );
                                })}
                            </Select>
                        </SelectWrapper>
                    </MemberStyle.InputContainer>
                </MemberStyle.MemberContent>

                <MemberStyle.BottomBar>
                    <MemberStyle.ConfirmBtn
                        margin="0"
                        disable={isLoading}
                        onClick={this.updateMemberInfo}
                    >
                        {isLoading && (
                            <LoadingWrapper>
                                <GlobalStyle.Spin color="#fff"/>
                            </LoadingWrapper>
                        )}
                        完成
                    </MemberStyle.ConfirmBtn>
                </MemberStyle.BottomBar>

            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        memberinfo : state.member.memberinfo,
        haslogin : state.member.haslogin,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions : bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps)(ResetBirthdayView);