/*=====================================
    MemberCardOrderDetail

    Author: Gray
    CreateTime: 2018 / 03 / 15
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import styled from 'styled-components';
import { GlobalStyle, Colors } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import TimeFormat from "components/commons/TimeFormat";
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
`;
const DetailContent = styled.div`
    background-color: #fff;
`;
const MainInfoContent = styled.div`
    padding: 16px;
`;
const MainTitle = styled.div`
    line-height: 21px;
    font-size: 15px;
    color: ${Colors.Dark3};
`;
const MainDescription = styled.div`
    line-height: 17px;
    margin: 4px 0 0 0;
    font-size: 12px;
    color: ${Colors.Dark5};
`;
const InfoContent = styled.div`
    padding: 16px;
    box-shadow: inset 0 -1px 0 0 #EEEEEE;
`;
const InfoTitle = styled.div`
    line-height: 18px;
    margin: 0 0 4px 0;
    font-size: 13px;
    color: #777777;
    font-weight: 600;
`;
const InfoText = styled.div`
    line-height: 20px;
    font-size: 13px;
    color: #777777;
`;
const SwitchContent = styled.div`
	display: flex;
	padding: 0 16px;
	align-items: center;
`;
const SwitchWrapper = styled.div`
	display: inline-flex;
	margin: 8px 0 8px auto;
	align-items: center;
`;
const SwitchTitle = styled.span`
	line-height: 18px;
    font-size: 13px;
    color: #777777;
    font-weight: 600;
`;
const SwitchLabel = styled.span`
	margin: 0 6px 0 0;
	font-size: 12px;
    color: #777777;
`;
const SwitchDot = styled.div`
	position: absolute;
	top: 1px;
	width: 28px;
	height: 28px;
	border-radius: 50%;
	background-color: #fff;
	transition: all 0.3s;
`;
const Switch = styled.div`
	position: relative;
	width: 60px;
	height: 30px;
	border-radius: 100px;
	background-color: ${(props) => props.isOn ? Colors.Orange : '#ddd'};
	cursor: pointer;

	${SwitchDot} {
		${(props) => props.isOn ? 'right: 1px;' : 'left: 1px;'}
	}
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberCardOrderDetailView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            id: '',
            jcard: undefined,
            isused: false,
            isLoading: false,
            isError: false,
            isLoaded: false,
        };

        this.setCardUsedFlag = this.setCardUsedFlag.bind(this);
        this.renderDetailContent = this.renderDetailContent.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        
        this.init(this.props, true);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {
        if(this.props.location !== prevProps.location)
            this.init(this.props);
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props, isInit) {

        const current = CupoyRouter.getCurrent();
        const {
            params,
        } = current;

        const id = params.id;

        if (this.state.isLoaded) {
            return;
        }

        this.initJCard(id,  props);
    }

    // ------------------------------
    // initJCard
    // ------------------------------
    initJCard(id,  props) {


        this.setState({
            id: id,
            jcard: undefined,
            start: 0,
            isLoading: true,
        }, () => {
            this.qyCardOrderDetail();
        })
    }

    // ------------------------------
    // qyCardOrderDetail
    // ------------------------------
    qyCardOrderDetail() {

        const {
        	id,
        	start,
        } = this.state;

        this.props.cardOrderActions.qyCardOrderDetail({
        	orderid: id,
            start: start,
        }).then((result) => {
            const jcard = result.list[0];
            this.setState({
                jcard,
                isused: jcard.isused,
                isLoading: false,
                isError: false,
            })
        
        }, (error) => {
            this.setState({
                isLoading: false,
                isError: true,
            });
        })
    }

    // ------------------------------
    // setCardUsedFlag
    // ------------------------------
    setCardUsedFlag() {
        
        const {
        	jcard,
        	isused,
        } = this.state;

        const new_isused = !isused;

        this.setState({
            isused: new_isused,
        }, () => {
            this.props.cardOrderActions.setCardUsedFlag({
                jcardid: jcard.jcardid,
                used: new_isused,
            });
        });
    }

    // ------------------------------
    // renderDetailContent
    // ------------------------------
    renderDetailContent() {

        const {
        	jcard,
        	isused,
        } = this.state;

        if (!jcard) {
            return null;
        }

        return (
            <DetailContent>
                <MainInfoContent>
                    <MainTitle>
                        {jcard.productname}
                    </MainTitle>
                    <MainDescription>
                        訂單編號: {jcard.orderid}
                    </MainDescription>
                    <MainDescription>
                        點數編號: {jcard.jcardid}
                    </MainDescription>
                </MainInfoContent>
                <InfoContent>
                    <InfoTitle>購買資訊</InfoTitle>
                    <InfoText>
                        有效期限: {jcard.expiredtime > -1 ? <TimeFormat format="simpleFullTime" time={jcard.expiredtime} /> : '無有效期限'}<br/>
                        {jcard.cardfields.map((cardfield, idx)=>{
                            return (<span key={idx}>{cardfield} : {jcard.cardtexts[idx]}<br/></span>)
                        })}
                    </InfoText>
                </InfoContent>
                <SwitchContent>
                	<SwitchTitle>是否使用</SwitchTitle>
                	<SwitchWrapper>
                		<SwitchLabel>{isused ? '已使用' : '未使用'}</SwitchLabel>
                		<Switch isOn={isused} onClick={() => this.setCardUsedFlag()}>
                			<SwitchDot/>
                		</Switch>
                	</SwitchWrapper>
                </SwitchContent>
            </DetailContent>
        );
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            isLoading,
            isError,
        } = this.state;

        return (
            <Content>

                {!isLoading && !isError ?
                    this.renderDetailContent()
                    :
                    null
                }

                {isLoading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }

                {isError ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            發生錯誤
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無法取得訂單資訊，請重新整理後再嘗試。
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }
            </Content>
        )
    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return <NeedLoginBlock />;
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

    	const {
    		id,
    	} = this.state;

    	if(!id) {
    		return null;
    	}

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
                backgroundColor='#F5F5F5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER_BONUS_ORDER}
                            params={{
                            	id: id,
                            }}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            發財金商品
                        </CupoySrefView>
                    }
                    middleComponent="商品詳情"
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        cardOrderActions: bindActionCreators(actionCreators.cardOrderActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberCardOrderDetailView);