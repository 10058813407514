/*=====================================
    系統訊息

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle } from 'stylecomponents';
import styled from 'styled-components';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
import TimeFormat from "components/commons/TimeFormat";
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    styled
--------------------------*/
const Content = styled.div`
    padding: 0 0 10px 0;
`;

const MessageList = styled.div`

    margin-top: 10px;
    padding: 16px;
    min-height: 100px;
    background: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #EEEEEE, inset 0 1px 0 0 #EEEEEE;

`;

const MessageText = styled.div`

    font-size: 16px;
    color: #333333;
    white-space: pre-line;

`;

const CreateTime = styled.div`

    margin-top: 7px;
    font-size: 12px;
    color: #565151;

`;

/*--------------------------
    Main Component
--------------------------*/
class SystemMessageView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            start: Array.isArray(this.props.msgLogIds) ? this.props.msgLogIds.length : 0,
            isLoading: false,
            isError: false,
            isEmpty: false,
            isNoMore: false,
        };

        this.listMemberMsgLogs = this.listMemberMsgLogs.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        if(prevProps.haslogin !== this.props.haslogin) {
            this.init(this.props);
        }
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props) {

        const {
            haslogin,
            msgLogIds,
        } = this.props;

        if(haslogin && ( !Array.isArray(msgLogIds) || msgLogIds.length === 0)) {
            this.listMemberMsgLogs();
        }
    }

    // ------------------------------
    // listMemberMsgLogs
    // ------------------------------
    listMemberMsgLogs() {

        const {
            start,
            isLoading,
            isEmpty,
            isNoMore,
            isError,
        } = this.state;

        if(isLoading || isEmpty || isNoMore || isError) {
            return;
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.memberActions.listMemberMsgLogs({
                start: start,
            }).then(({ list: msgLogs = [] } = {}) => {

                const msgLogIds = this.props.msgLogIds;

                this.setMemberMsgLogRead(
                    msgLogs.filter(({ isRead } = {}) => isRead ? false : true)
                );

                var newState = {
                    isLoading: false,
                    start: msgLogIds.length,
                };

                if(!Array.isArray(msgLogIds) || msgLogIds.length === 0) {
                    newState.isEmpty = true;
                } else if(start === newState.start) {
                    newState.isNoMore = true;
                }

                this.setState(newState);
            }, (error) => {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            })
        })
    }

    setMemberMsgLogRead = msgLogs => {

        const msgids = msgLogs.reduce((arr, message = {}) => {

            const { msgid, isRead } = message;
            return isRead ? arr : [...arr, msgid];
        }, []);

        if(msgids.length === 0) return;

        this.props.memberActions.setMemberMsgLogRead({
            msgids,
        });
    }

    // ------------------------------
    // rowRenderer
    // ------------------------------
    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it),
        parent,
        measure,
        columnIndex,
        updateRowSize,
        measureCache,
        listWidth,
    }, width, listHeight){

        const {
            msgLogIds,
            msgLogMap,
        } = this.props;

        const msgLogId = msgLogIds[index];
        const msgLog = msgLogMap[msgLogId];

        if(!msgLog) {
            return null;
        }

        return (
            <div
                key={index}
                style={style}
            >
                <MessageList>
                    <MessageText dangerouslySetInnerHTML={{__html: msgLog.txtcontent}} />
                    <CreateTime>
                        建立時間: <TimeFormat format="simpleFullTime" time={msgLog.createtime}/>
                    </CreateTime>
                </MessageList>
            </div>
        )
    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {
        return <NeedLoginBlock />;
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            isLoading,
            isError,
            isEmpty,
        } = this.state;

        const {
            msgLogIds,
        } = this.props;

        return (
            <Content>
                {Array.isArray(msgLogIds) && msgLogIds.length > 0 ?
                    <CupoyListRepeat
                        listId={this.constructor.name}
                        rowRenderer={this.rowRenderer}
                        items={msgLogIds}
                        isLoading={isLoading}
                        useWindowScroll={true}
                        restoreWindowScroll={true}
                        loadMoreRows={() => this.listMemberMsgLogs()}
                    />
                    :
                    null
                }

                {isLoading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }

                {isError ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            發生錯誤
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無法取得系統訊息，請重新整理後再嘗試。
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }

                {isEmpty ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            無任何系統訊息
                        </GlobalStyle.ErrorContainerTitle>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }
            </Content>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            返回
                        </CupoySrefView>
                    }
                    middleComponent="系統訊息"
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        msgLogMap: state.member.msgLogMap,
        msgLogIds: state.member.msgLogIds,
        memberinfo : state.member.memberinfo,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(SystemMessageView);