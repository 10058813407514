/*=====================================
    MemberBonusLogs

    Author: Gray
    CreateTime: 2018 / 03 / 12
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import GLOBAL from 'nonnmwebglobal';
import styled from 'styled-components';
import { GlobalStyle, Colors, MemberStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
import TimeFormat from "components/commons/TimeFormat";
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
    padding: 0 0 10px 0;
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberBonusLogsView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            start: Array.isArray(this.props.bonusLogs) ? this.props.bonusLogs.length : 0,
            isLoading: false,
            isError: false,
            isEmpty: false,
            isNoMore: false,
        };

        this.listBonusLogs = this.listBonusLogs.bind(this);
        this.getBonusOptypeString = this.getBonusOptypeString.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
        this.gotoDetail = this.gotoDetail.bind(this);
        
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        window.scrollTo(0, 0);
        this.listBonusLogs({ refresh: true });
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------

    //-----------------------------
    // 根據明細類別連結到各個訂單明細
    //-----------------------------
    gotoDetail(event,itemid,optype){
        let path;
        let parameters = {};
        switch(optype){
            case 1 : // 旗艦館-出售區(自動入點)
                path = CupoyRouter.STATES.MEMBER_VITEM_ORDER_SELL;
                parameters = {id:itemid};
                break;
		    
            case 3 : // 交易扣點(兌換發財商城商品)
            case 7 : // 買點數卡(自動入點) (orderid)
                path = CupoyRouter.STATES.MEMBER_CARD_ORDER_DETAIL_LIST;
                parameters = {id:itemid};
                break;
            case 10: // 買點數卡(自動入點) (cartid)
                path = CupoyRouter.STATES.MEMBER_CARD_ORDER_LIST_FINISH;
                parameters = {cartid:itemid};
                this.props.webActions.cupoyStateGo(CupoyRouter.STATES.MEMBER_CARD_ORDER_LIST_FINISH,{},parameters);
                return;
            default :
                return;               

        }
        this.props.webActions.cupoyStateGo(path,parameters);
        return;

    }
    // ------------------------------
    // 取得發財金logs
    // ------------------------------
    listBonusLogs({ refresh = false } = {}) {

        const {
            haslogin,
        } = this.props;

        const {
            start,
            isLoading,
            isEmpty,
            isNoMore,
            isError,
        } = this.state;

        if (!haslogin) {
            return;
        }

        if(!refresh && (isLoading || isEmpty || isNoMore || isError)) {
            return;
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.bonusActions.listBonusLogs({
                start,
                refresh,
            }).then((result) => {
                const bonusLogs = this.props.bonusLogs;

                var newState = {
                    isLoading: false,
                    start: bonusLogs.length,
                };

                if(!Array.isArray(bonusLogs) || bonusLogs.length === 0) {
                    newState.isEmpty = true;
                } else if(start === newState.start) {
                    newState.isNoMore = true;
                }

                this.setState(newState);
            }, (error) => {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            })
        })
    }

    // ------------------------------
    // 取得發財金類型
    // ------------------------------
    getBonusOptypeString(optype) {

        var result = GLOBAL.BONUS_OPTYPES_STRING[optype] || '';

        return result;
    }

    // ------------------------------
    // rowRenderer
    // ------------------------------
    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        style, // Style object to be applied to row (to position it),
    }){

        const {
            bonusLogs,
        } = this.props;

        const bonusLog = bonusLogs[index];

        if(!bonusLog) {
            return null;
        }

        return (
            <div
                key={key}
                style={style}
            >
                <MemberStyle.LogsItem  link={bonusLog.itemid > 0 ? true : false} onClick={(event) => this.gotoDetail(event,bonusLog.itemid,bonusLog.optype)}>
                    <MemberStyle.LogsTitle>
                        交易類型: {this.getBonusOptypeString(bonusLog.optype)}
                    </MemberStyle.LogsTitle>
                    <MemberStyle.LogsDescription>
                        交易時間: <TimeFormat format="simpleFullTime" time={bonusLog.createtime}/>
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription isLast={true}  >
                        明細編號: {bonusLog.itemid > 0 ? bonusLog.itemid : ''}
                    </MemberStyle.LogsDescription>
                    {bonusLog.amount > 0 ?
                        <MemberStyle.LogsStatus color={Colors.Orange}>獲得 {bonusLog.amount} 發財金</MemberStyle.LogsStatus>
                        :
                        <MemberStyle.LogsStatus color={Colors.Blue}>扣除 {-bonusLog.amount} 發財金</MemberStyle.LogsStatus>
                    }
                </MemberStyle.LogsItem>
            </div>
        )
    }
    
    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return <NeedLoginBlock />;
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            isLoading,
            isError,
            isEmpty,
        } = this.state;

        const {
            bonusLogs,
        } = this.props;

        return (
            <Content>
                {Array.isArray(bonusLogs) && bonusLogs.length > 0 ?
                    <CupoyListRepeat
                        listId={this.constructor.name}
                        rowRenderer={this.rowRenderer}
                        items={bonusLogs}
                        isLoading={isLoading}
                        useWindowScroll={true}
                        restoreWindowScroll={true}
                        loadMoreRows={this.listBonusLogs}
                    />
                    :
                    null
                }

                {isLoading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }

                {isError ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            發生錯誤
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無法取得發財金明細，請重新整理後再嘗試。
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }

                {isEmpty ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            無任何發財金明細
                        </GlobalStyle.ErrorContainerTitle>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }
            </Content>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER_BONUS}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            我的發財
                        </CupoySrefView>
                    }
                    middleComponent="發財金明細"
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        bonusLogs: state.bonus.logs,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        bonusActions: bindActionCreators(actionCreators.bonusActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberBonusLogsView);