/*=====================================
    PreventPageScrollingScrollbars

    Author: Gray
    createtime: 2018 / 02 / 23 (copy from cupoy)
=====================================*/

import React, { PureComponent } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

export default class PreventPageScrollingScrollbars extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      autoHide: this.props.autoHide
    };
  }

  // componentDidMount() {
  //   window.addEventListener('mousewheel', this.handleWindowWheel, false);
  // }
  // componentWillUnmount() {
  //   window.removeEventListener('mousewheel', this.handleWindowWheel);
  // }
  handleWindowWheel = event => {
    if (!this.scrollbars) return;
    const scrollBarsValues = this.scrollbars.getValues();
    // console.log(scrollBarsValues);
    const { top, left, clientHeight, clientWidth, scrollHeight, scrollWidth } = scrollBarsValues; // Get this from your ref
    // When the bottom is reached and we're scrolling down, prevent scrolling of the window
    // if (top >= 1 && event.deltaY > 0) event.preventDefault();
    if (this.props.horizontal) {
      if(event.type === 'wheel' || event.type === 'mousewheel') {
        event.preventDefault();
        event.stopPropagation();

        const maxscroll = scrollWidth - clientWidth;
        const newLeft = left * maxscroll + event.deltaY;
        this.scrollbars.view.scrollLeft = newLeft;
      }
    } else {
      if (
        (top >= 1 && event.deltaY > 0) ||
        (top <= 0 && event.deltaY < 0 && !clientHeight >= scrollHeight)
      ) {
        event.preventDefault();
        event.stopPropagation();
      }      
    }

  };

  registerRef = ref => {
    if (!ref || !ref.view) return;
    this.scrollbars = ref;
    // debugger;
    this.scrollbars.view.addEventListener(
      'mousewheel',
      this.handleWindowWheel,
      false
    );
    this.scrollbars.view.addEventListener(
      'scroll',
      this.handleWindowWheel,
      false
    );
    this.scrollbars.view.addEventListener(
      'wheel',
      this.handleWindowWheel,
      false
    );

    this.scrollbars.view.addEventListener('mouseenter', event => {
      if (this.props.autoHide) {
        this.setState({
          autoHide: false
        });
      }
    });
    this.scrollbars.view.addEventListener('mouseleave', event => {
      if (this.props.autoHide) {
        this.setState({
          autoHide: true
        });
      }
    });
  };

  render() {
    const { autoHide, horizontal, ...otherProps } = this.props;

    return (
      <Scrollbars
        ref={this.registerRef}
        autoHide={this.state.autoHide}
        {...otherProps}
      />
    );
  }
}
