/*=====================================
    MemberVitemOrderListBuy

    Author: Gray
    CreateTime: 2018 / 03 / 15
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import GLOBAL from 'nonnmwebglobal';
import styled from 'styled-components';
import { GlobalStyle, Colors, MemberStyle } from 'stylecomponents';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
import TimeFormat from "components/commons/TimeFormat";
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
    padding: 0 0 10px 0;
`;
const ColorText = styled.span`
    color: ${(props) => props.color ? props.color : 'inherit'};
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberVitemOrderListBuyView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            start: Array.isArray(this.props.purchaseOrderIds) ? this.props.purchaseOrderIds.length : 0,
            isLoading: false,
            isError: false,
            isEmpty: false,
            isNoMore: false,
        };

        this.listPurchaseOrders = this.listPurchaseOrders.bind(this);
        this.renderPurchaseOrderTag = this.renderPurchaseOrderTag.bind(this);
        this.renderPurchaseOrderStatus = this.renderPurchaseOrderStatus.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        if (prevProps.haslogin !== this.props.haslogin) {
            this.init(this.props);
        }
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props) {

        const {
            haslogin,
            purchaseOrderIds,
        } = this.props;

        if (haslogin && (!Array.isArray(purchaseOrderIds) || purchaseOrderIds.length === 0)) {
            this.listPurchaseOrders();
        }
    }

    // ------------------------------
    // 取得虛寶收購區列表
    // ------------------------------
    listPurchaseOrders() {

        const {
            start,
            isLoading,
            isEmpty,
            isNoMore,
            isError,
        } = this.state;

        if (isLoading || isEmpty || isNoMore || isError) {
            return;
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.vitemOrderActions.listPurchaseOrders({
                start: start,
            }).then((result) => {
                const purchaseOrderIds = this.props.purchaseOrderIds;
                
                var newState = {
                    isLoading: false,
                    start: purchaseOrderIds.length,
                };

                if (!Array.isArray(purchaseOrderIds) || purchaseOrderIds.length === 0) {
                    newState.isEmpty = true;
                } else if (start === newState.start) {
                    newState.isNoMore = true;
                }

                this.setState(newState);
            }, (error) => {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            })
        })
    }

    // ------------------------------
    // renderPurchaseOrderTag
    // ------------------------------
    renderPurchaseOrderTag(purchaseOrder) {

        const int_orderstatus = Number(purchaseOrder.orderstatus);
        const text = GLOBAL.C2C_PURCHASE_ORDERSTATUS_STRING[int_orderstatus];

        switch(int_orderstatus) {
            case GLOBAL.C2C_PURCHASE_ORDERSTATUS.BUYORDER:
                return <MemberStyle.LogsTag color={Colors.Red}>{text}</MemberStyle.LogsTag>;
            default:
                return '';
        }
    }

    // ------------------------------
    // renderPurchaseOrderStatus
    // ------------------------------
    renderPurchaseOrderStatus(purchaseOrder) {

        const int_orderstatus = Number(purchaseOrder.orderstatus);
        const text = GLOBAL.C2C_PURCHASE_ORDERSTATUS_STRING[int_orderstatus];
        var color = undefined;

        switch(int_orderstatus) {
            case GLOBAL.C2C_PURCHASE_ORDERSTATUS.BUYORDER:
                color = Colors.Red;
                break;
            case GLOBAL.C2C_PURCHASE_ORDERSTATUS.WAITFORBUYER:
            case GLOBAL.C2C_PURCHASE_ORDERSTATUS.ORDERFINISH_9199:
            case GLOBAL.C2C_PURCHASE_ORDERSTATUS.ORDERFINISH:
            case GLOBAL.C2C_PURCHASE_ORDERSTATUS.ORDERREVENUE:
                color = Colors.Green;
                break;
            default:
                break;
        }

        return (<ColorText color={color}>{text}</ColorText>);
    }

    // ------------------------------
    // rowRenderer
    // ------------------------------
    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it),
        parent,
        measure,
        columnIndex,
        updateRowSize,
        measureCache,
        listWidth,
    }, width, listHeight) {

        const {
            purchaseOrderIds,
            purchaseOrderMap,
        } = this.props;

        const purchaseOrderId = purchaseOrderIds[index];
        const purchaseOrder = purchaseOrderMap[purchaseOrderId];

        if (!purchaseOrder) {
            return null;
        }

        return (
            <CupoySrefView
                statename={CupoyRouter.STATES.MEMBER_VITEM_ORDER_BUY}
                params={{
                    id: purchaseOrder.itemid,
                }}
                key={index}
                style={style}
            >
                <MemberStyle.LogsItem hasArrow={true}>
                    <MemberStyle.LogsTitle>
                        {purchaseOrder.gamename ? purchaseOrder.gamename.trim() : null}
                        {purchaseOrder.servername ? '-' + purchaseOrder.servername : null}
                    </MemberStyle.LogsTitle>
                    <MemberStyle.LogsDescription>
                        下單時間: <TimeFormat format="simpleFullTime" time={purchaseOrder.buytime} />
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription>
                        訂單編號: {purchaseOrder.itemid}
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription>
                        買家: {purchaseOrder.vprovnickname} {purchaseOrder.buyerphone}
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription isLast={true}>
                        訂單狀態: {this.renderPurchaseOrderStatus(purchaseOrder)}
                    </MemberStyle.LogsDescription>
                    {this.renderPurchaseOrderTag(purchaseOrder)}
                    {purchaseOrder.itemprice > 0 ?
                        <MemberStyle.LogsStatus>金額：{purchaseOrder.itemprice} 元</MemberStyle.LogsStatus>
                        :
                        null
                    }
                    <MemberStyle.LogsRightArrow><i className="icon-chevron-right"/></MemberStyle.LogsRightArrow>
                </MemberStyle.LogsItem>
            </CupoySrefView>
        )
    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return <NeedLoginBlock />;
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            isLoading,
            isError,
            isEmpty,
        } = this.state;

        const {
            purchaseOrderIds,
        } = this.props;

        return (
            <Content>
                {Array.isArray(purchaseOrderIds) && purchaseOrderIds.length > 0 ?
                    <CupoyListRepeat
                        listId={this.constructor.name}
                        rowRenderer={this.rowRenderer}
                        items={purchaseOrderIds}
                        isLoading={isLoading}
                        useWindowScroll={true}
                        restoreWindowScroll={true}
                        loadMoreRows={() => this.listPurchaseOrders()}
                    />
                    :
                    null
                }

                {isLoading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }

                {isError ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            發生錯誤
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無法取得出售區訂單，請重新整理後再嘗試。
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }

                {isEmpty ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            無任何出售區訂單
                        </GlobalStyle.ErrorContainerTitle>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }
            </Content>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        if (haslogin) {
            return this.renderLoginContent();
        } else {
            return this.renderNoLoginContent();
        }
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        purchaseOrderMap: state.vitemorder.purchaseOrderMap,
        purchaseOrderIds: state.vitemorder.purchaseOrderIds,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        vitemOrderActions: bindActionCreators(actionCreators.vitemOrderActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberVitemOrderListBuyView);