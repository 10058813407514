import React, {
    useRef,
    useState,
    useEffect,
} from 'react';
import styled, { keyframes, css } from 'styled-components';
import { createSelector } from 'reselect';
import useRedux from 'actions/hooks/useRedux';
import { Colors } from 'stylecomponents';

/**
 * Styled
 */
const ANIMATION_STATUS = {
    ENTER: 'enter',
    EXIT: 'exit',
};

/**
 * Keyframes
 */
const animationShow = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;
const animationHide = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
`;

/**
 * Styled
 */
const Container = styled.div`
    position: fixed;
    z-index: 9999;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    max-width: calc(100% - 40px);
    background-color: ${Colors.Dark};
    color: #FFF;
    font-size: 16px;
    line-height: 1.7empx;
    padding: 10px 12px;
    border-radius: 5px;
    opacity: 0;
    user-select: none;
    pointer-events: none;
    transition: opacity 250ms ease-in-out;
    white-space: pre-line;
    animation: ${({ animationStatus }) => 
        animationStatus === ANIMATION_STATUS.ENTER
        ? css`${animationShow} 200ms ease-out forwards`
        : animationStatus === ANIMATION_STATUS.EXIT
            ? css`${animationHide} 200ms ease-out forwards`
            : 0
    };
`;

/**
 * Main Component
 */
const SimpleAlertDialog = () => {

    const selector = createSelector(
        state => state.web.simpleAlertDialog,
        simpleAlertDialog => ({
            timestamp: simpleAlertDialog.timestamp, // 當呼叫顯示此Dialog時，兩次都是相同文字且相同時間代表不需要重複顯示
            text: simpleAlertDialog.text,
        })
    );

    const [{
        timestamp,
        text,
    }] = useRedux(selector);

    const hideTimer = useRef();
    const [animationStatus, setAanimationStatus] = useState(null);

    const hide = () => {
        setAanimationStatus(prev => 
            prev === ANIMATION_STATUS.ENTER
                ? ANIMATION_STATUS.EXIT
                : null
        );
    };

    const show = () => {

        if (!text) {
            return;
        }

        if (hideTimer.current) {
            clearTimeout(hideTimer.current);
        }

        setTimeout(() => {
            setAanimationStatus(ANIMATION_STATUS.ENTER);
            hideTimer.current = setTimeout(hide, 2500);
        }, 100);
    };

    useEffect(() => {
        show();
        return hide;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text, timestamp]);

    return (
        <Container animationStatus={animationStatus}>
            {text}
        </Container>
    );
};

export default SimpleAlertDialog;