/*=====================================
    重設暱稱

    Author: Eileen Liao
    createtime: 2018 / 05 / 02
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import styled from 'styled-components';
import { GlobalStyle, MemberStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import GLOBAL from 'nonnmwebglobal';
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Style Component
--------------------------*/
const SubmitLoading = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    left: 16px;
    height: 100%;
    align-items: center;
    justify-content: center; 
`;
/*--------------------------
    Main Component
--------------------------*/
class ResetNicknameView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {
        
        super(props);
        this.state = {
            nickname : '',
            isError : false,
            isLoading: false,
        }
        this.handleNicknameChange = this.handleNicknameChange.bind(this);
        this.resetNickname = this.resetNickname.bind(this);
        
    }


    handleNicknameChange = event => {
        this.setState ({ nickname : event.target.value });
    }

    resetNickname(){

        const {
            membername,
            birthday,
            isdonate,
            npoban,
            carrierid,
            carriertype,
            invoicetype,
            crn,
            companytitle,
            shownametype,
            address,
            sex,
        } = this.props.memberinfo;

        const {
            nickname,
        } = this.state;

        if (
            GLOBAL.isEmpty(nickname)
            || GLOBAL.checkStringHaveSymbol(nickname)
        ) {
            this.props.webActions.openAlertDialog({                    
                title: '錯誤訊息',
                description : '暱稱限中文、英文、數字組成',
                closeName: '確定',
            });
            return;
        }

        let addressMap = GLOBAL.parseAddress(address);

        this.setState({ isLoading: true }, () => {

            this.props.memberActions.updMember({
                city: addressMap.city,
                region: addressMap.region,
                postalcode: addressMap.zipcode,
                addreGss: addressMap.address,
                name: membername,
                nickname,
                birthday,
                isdonate,
                npoban,
                carrierid,
                carriertype,
                invoicetype,
                crn,
                companytitle,
                shownametype,
                sex,
            }).then((result) => {
                this.setState({ isLoading: false }, () => {
                    if(result)
                    this.props.webActions.openAlertDialog({
                        title: '暱稱修改成功',
                        closeCallback: () => {
                            this.props.webActions.cupoyStateGo(CupoyRouter.STATES.MEMBER_INFO);
                        }
                    });
                });
            }).catch(() => { 
                this.setState({
                    isError: true,
                    isLoading: false
                }, () => {
                    this.props.webActions.openAlertDialog({
                        title: '發生錯誤',
                        description: '無法成功修改暱稱，請稍後再嘗試。',
                    });
                });
            });
        });
    }
    
    init(props){
        if(props.memberinfo.nickname){
            this.setState({ nickname: props.memberinfo.nickname });
        }
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        this.init(this.props);
    }
    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {
        return <NeedLoginBlock />;
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const { nickname, isLoading } = this.state;

        return (
            <GlobalStyle.Container  hasFixedTopbar  hasFixedTabbar={false}>
            
                <TopbarView
                    isFixed
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER_INFO}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            個人資料
                        </CupoySrefView>
                    }
                    middleComponent="重設暱稱"
                />

                <MemberStyle.MemberContent>

                    <MemberStyle.MemberInputBox2
                        placeholder="暱稱"
                        // ref="nickname"
                        type="text"
                        width="100%"
                        onChange={this.handleNicknameChange}
                        value={nickname}
                    />

                </MemberStyle.MemberContent>
                <MemberStyle.BottomBar>
                    <MemberStyle.ConfirmBtn
                        margin="0"
                        onClick={this.resetNickname}
                        disable={isLoading || !nickname.trim()}
                    >
                    {
                        isLoading && (
                            <SubmitLoading>
                                <GlobalStyle.Spin color="#fff"/>
                            </SubmitLoading>
                        )
                    }
                        完成
                    </MemberStyle.ConfirmBtn>
                </MemberStyle.BottomBar>
            </GlobalStyle.Container>
            
        )
    };

}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {

    return {
            memberinfo : state.member.memberinfo,
            haslogin: state.member.haslogin,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(ResetNicknameView);