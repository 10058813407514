import {
    useState,
    useEffect,
    useCallback,
} from 'react';
import usePrevious from 'components/hooks/usePrevious';


const useInput = (initValue = '') => {

    const [value, setValue] = useState(initValue);
    const prev_initValue = usePrevious(initValue);

    const handler = useCallback(
        event => {
            setValue(event.target.value);
        },
        [],
    );

    const setter = useCallback(
        value => {
            setValue(value);
        },
        []
    );

    const clean = useCallback(
        () => {
            setValue('');
        },
        []
    );

    useEffect(() => {
        if (initValue !== prev_initValue) {
            setValue(initValue);
        }
    }, [initValue, prev_initValue]);

    return {
        value,
        handler,
        setter,
        clean,
    };
};

export default useInput;
