/**
|--------------------------------------------------
| CupoyLink
| @description 處理站外連結的component
| @author Ac
| @time 2019/10/02
|--------------------------------------------------
*/
import React from 'react';
import PropTypes from 'prop-types';
import mobileUtil from 'util/mobileUtil';
import GLOBAL from 'nonnmwebglobal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';

const CupoyLink = React.memo(props => {

    const {
        children,
        type,
        rel,
        referrerPolicy,
        ping,
        media,
        hrefLang,
        href,
        download,
        onClick,
    } = props;

    const hostNameEquals = GLOBAL.equalsRootURL(href);
    // 站外連結才要開新分頁，由於有些url資料是從DB來的，所以統一在此判斷
    const _target = hostNameEquals ? '_self' : '_blank';
    const newRel = getLinkRel(rel);

    /**
     * 檢查rel，如果是站外連結必須要加上noopener & noreferrer & nofollow
     * @param {string} rel
     * @return {string}
     */
    function getLinkRel(rel = '') {

        let _rel = rel;

        // 非站外連結，使用舊有值
        if (hostNameEquals) {
            return _rel;
        }

        if (!_rel.includes('noopener')) {
            _rel += ` noopener`;
        }
    
        if (!_rel.includes('noreferrer')) {
            _rel += ` noreferrer`;
        }

        if (!_rel.includes('nofollow')) {
            _rel += ` nofollow`;
        }

        return _rel;
    }

    function handleClick(event) {
        onClick && onClick(event);        
        mobileUtil.handleOpenLinkBridge(event, props.webActions);
    }

    return (
        <a
            type={type}
            target={_target}
            rel={newRel}
            referrerPolicy={referrerPolicy}
            ping={ping}
            media={media}
            hrefLang={hrefLang}
            href={href}
            download={download}
            onClick={handleClick}
        >
            {children}
        </a>
    );
});

CupoyLink.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    href: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    type: PropTypes.string,
    rel: PropTypes.string,
    referrerPolicy: PropTypes.string,
    ping: PropTypes.string,
    media: PropTypes.string,
    hrefLang: PropTypes.string,
    download: PropTypes.string,
};

CupoyLink.defaultProps = {
    rel: '',
};

/*--------------------------
    Redux Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
    };
};

/*--------------------------
    Redux Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(CupoyLink);