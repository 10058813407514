/*=====================================
    首頁 Banner carousel

    Author: ac
    createtime: 2018 / 03 / 07
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, {
    useRef,
    useEffect,
    Component,
} from 'react';
import OwlCarousel from 'react-owl-carousel';
import styled from 'styled-components';
import GLOBAL from '../../nonnmwebglobal';
import CupoyLink from 'components/commons/CupoyLink';
import actionCreators from 'actions/creators';
import useActions from 'actions/hooks/useActions';

/*--------------------------
    Style Component
--------------------------*/

const BannerWrapper = styled.div`

    position: relative;

    margin-top: ${props => props.hasSearchbar ? '64px' : 0};

`;

const Banner = styled.div`

    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

`;

const BannerImage = styled.div`

    display: block;
    width: 100%;
    height: 0;
    padding-bottom: ${190 / 545 * 100}%;
    background-image: url('${props => props.imageUrl ? props.imageUrl : null }');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;

`;

const DotContent = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 999;
    width: 100%;
    height: 30px;
    padding-bottom: 5px;

`;
const DotButton = styled.div`

    opacity: 0.5;
    background-color: #FFFFFF;
    border-radius: 100px;
    width: 8px;
    height: 8px;
    margin-left: 3px;
    margin-right: 3px;
    cursor: pointer;
    z-index: 99999;

    &.active {
        opacity: 1;
        background-image: linear-gradient(-180deg, #FFA511 0%, #FF9211 100%);
    }
`;

/**
 * Components
 */
const BannerItem = props => {

    const {
        adItem,
    } = props;

    const {
        advid,
        filepath,
        clickurl_mweb,
        advname,
        createtime,
        updatetime,
    } = adItem;
    let ttime = createtime;
    if(updatetime > 0)ttime = updatetime;
    const {
        adActions,
    } = useActions({
        adActions: actionCreators.adActionCreators,
    });

    const adClick = event => {
        adActions.adClick(advid);
    };

    const adImpress = event => {
        adActions.adImpress(advid);
    };

    const imageUrl = `${GLOBAL.imgSrcRootURL}/adimg/${advid}/${filepath}?t=${ttime}`;
    const nodeRef = useRef(null);

    useEffect(() => {

        const observer = new IntersectionObserver(entries => {
            const firstEntry = entries[0];
            if (firstEntry.isIntersecting) {
                adImpress();
            }
        });

        observer.observe(nodeRef.current);

        return () => {
            observer.unobserve(nodeRef.current);
        };
    }, []);

    return (
        <CupoyLink
            href={clickurl_mweb}
            target='_blank'
            rel='noopener noreferrer'
        >
            <BannerImage
                ref={nodeRef}
                imageUrl={imageUrl}
                alt={advname}
                onClick={adClick}
            />
        </CupoyLink>
    );
};

/*--------------------------
    Main Component
--------------------------*/
class BannerView extends Component {

    // ------------------------------
    // shouldComponentUpdate
    // ------------------------------
    shouldComponentUpdate() {
        return false;
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

    	return (
            <BannerWrapper>
                <Banner>
                    <OwlCarousel
                        className="owl-theme"
                        loop
                        items={1}
                        autoplay
                        autoplayHoverPause
                        dotsContainer={'#dotsId'}
                    >
                        {this.props.topADList.map((adItem, index) => {

                            return (
                                <BannerItem
                                    key={index}
                                    index={index}
                                    adItem={adItem}
                                />
                            );
                        })}
                    </OwlCarousel>
                    <DotContent id="dotsId">
                        {this.props.topADList.map((adItem, index)=>{

                            return (
                                <DotButton key={index} />
                            )
                        })}
                    </DotContent>
                </Banner>
            </BannerWrapper>
    	)
    }
}

/*--------------------------
    export
--------------------------*/
export default BannerView;