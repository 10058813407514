import actionTypes from "actions/types/gameActionTypes";
import GLOBAL from '../../nonnmwebglobal';
import customAxios from 'api/customAxios';

const API_URL = GLOBAL.URL_PREFIX + '/GameAction.do';

function listGamesStarted(payload) {
    return { type: actionTypes.listGamesStarted, payload };
}
function listGamesSuccess(payload) {
    return { type: actionTypes.listGamesSuccess, payload };
}
function listGamesFailed(payload) {
    return { type: actionTypes.listGamesFailed, payload };
}
function listGames(payload = {}) {
    return dispatch => {

        dispatch(listGamesStarted(payload)); // dispatch synced action to update loading status
        const {
            start,
            len = 50,
        } = payload;


        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'listGames');
            form.append('start', start);
            form.append('len', len);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listGamesSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listGamesFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listGamesFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function getGameServerListStarted(payload) {
    return { type: actionTypes.getGameServerListStarted, payload };
}
function getGameServerListSuccess(payload) {
    return { type: actionTypes.getGameServerListSuccess, payload };
}
function getGameServerListFailed(payload) {
    return { type: actionTypes.getGameServerListFailed, payload };
}
function getGameServerList(payload = {}) {
    return dispatch => {

        dispatch(getGameServerListStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            // TODO: 記得改API_URL與form內容
            let form = new FormData();
            form.append('op', 'getGameServerList');
            form.append('gameid', payload.gameid);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(getGameServerListSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(getGameServerListFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(getGameServerListFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

/**
|--------------------------------------------------
| productclassid: 
| 1. 點數卡
| 2. 虛寶包
| 3. 免費序號
| 4. 發財商城
| 5. 抽獎商品
|--------------------------------------------------
*/
function getGameMappingProductsCacheKey({ gameid, pclassid } = {}) {
    return () => {

        const key = `${pclassid}-${gameid}`;

        return key;
    };
}

function getGameMappingProductsStarted(payload) {
    return {
        type: actionTypes.getGameMappingProductsStarted,
        payload,
    };
}

function getGameMappingProductsSuccess(payload) {
    return {
        type: actionTypes.getGameMappingProductsSuccess,
        payload,
    };
}

function getGameMappingProductsFailed(payload) {
    return {
        type: actionTypes.getGameMappingProductsFailed,
        payload,
    };
}

function getGameMappingProducts(payload = {}) {
    return dispatch => {

        const {
            gameid,
            pclassid,
        } = payload;

        const url = `${API_URL}?op=getGameMappingProducts&gameid=${gameid}&pclassid=${pclassid}`;
        const key = dispatch(getGameMappingProductsCacheKey({ gameid, pclassid }));

        dispatch(getGameMappingProductsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            customAxios(url).then(response => {

                if (response.status === 503) {
                    // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                    reject({
                        error: 'error.503error',
                        message: '伺服器沒有開啟'
                    });

                    return;
                }
                response.json().then(result => {
                    if (!result) {
                        reject();
                        return;
                    }

                    if(result.error) {
                        reject(result.error);
                        return 
                    }

                    dispatch(getGameMappingProductsSuccess({ result, ...payload, key }));
                    resolve(result);
                })
                .catch(error => {
                    dispatch(getGameMappingProductsFailed(error));
                    reject(error);
                });
            })
            .catch(error => {
                // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                dispatch(getGameMappingProductsFailed(error));
                reject(error);
            });
        });
        return promise;
    }
}

const actions = {
 
    listGames, // 列表遊戲清單
    getGameServerList, // 列表遊戲伺服器清單
    getGameMappingProducts, // 取得遊戲下的某分類商品列表 ( 發財商城, 點數卡, 虛寶包, 免費序號 )
    getGameMappingProductsCacheKey, // 取得遊戲下的某分類商品列表cache key
};

export default actions;