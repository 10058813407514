/*=====================================
    MemberBonusOrder

    Author: Gray
    CreateTime: 2018 / 03 / 12
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import styled from 'styled-components';
import { GlobalStyle, Colors, MemberStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
import TimeFormat from "components/commons/TimeFormat";
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
    padding: 0 0 10px 0;
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberBonusOrderView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            start: Array.isArray(this.props.bonusOrderIds) ? this.props.bonusOrderIds.length : 0,
            isLoading: false,
            isError: false,
            isEmpty: false,
            isNoMore: false,
        };

        this.listBonusOrders = this.listBonusOrders.bind(this);
        this.refreshBonusOrders = this.refreshBonusOrders.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        window.scrollTo(0, 0);

        this.init();
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {
        if(prevProps.haslogin !== this.props.haslogin) {
            this.init(this.props);
        }
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init() {
        this.refreshBonusOrders();
    }

    // ------------------------------
    // 取得發財金兌換logs
    // ------------------------------
    listBonusOrders({ refresh = false } = {}) {

        const {
            haslogin,
        } = this.props;

        const {
            start,
            isLoading,
            isEmpty,
            isNoMore,
            isError,
        } = this.state;

        if (!haslogin) {
            return;
        }

        if(!refresh && (isLoading || isEmpty || isNoMore || isError)) {
            return;
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.cardOrderActions.listBonusOrders(
                start,
                { refresh }
            ).then((result) => {
                const bonusOrderIds = this.props.bonusOrderIds;

                var newState = {
                    isLoading: false,
                    start: bonusOrderIds.length,
                };

                if(!Array.isArray(bonusOrderIds) || bonusOrderIds.length === 0) {
                    newState.isEmpty = true;
                } else if(start === newState.start) {
                    newState.isNoMore = true;
                }

                this.setState(newState);
            }, (error) => {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            })
        })
    }

    refreshBonusOrders() {
        this.listBonusOrders({ refresh: true });
    }

    // ------------------------------
    // rowRenderer
    // ------------------------------
    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        style, // Style object to be applied to row (to position it),
    }){

        const {
            bonusOrderIds,
            orderMap,
        } = this.props;

        const bonusOrderId = bonusOrderIds[index];
        const bonusOrder = orderMap[bonusOrderId];

        if(!bonusOrder) {
            return null;
        }

        return (
            <CupoySrefView
                statename={CupoyRouter.STATES.MEMBER_BONUS_ORDER_DETAIL}
                params={{
                    id: bonusOrderId,
                }}
                key={key}
                style={style}
            >            

                <MemberStyle.LogsItem>
                    <MemberStyle.LogsTitle>{bonusOrder.productname}</MemberStyle.LogsTitle>
                    <MemberStyle.LogsDescription>
                        購買時間: <TimeFormat format="simpleFullTime" time={bonusOrder.saletime}/>
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription>產品市價: {bonusOrder.marketprice}元</MemberStyle.LogsDescription>
                    <MemberStyle.LogsStatus color={Colors.Orange}>使用 {bonusOrder.memberprice} 發財金</MemberStyle.LogsStatus>
                    <MemberStyle.LogsRightArrow><i className="icon-chevron-right"/></MemberStyle.LogsRightArrow>
                </MemberStyle.LogsItem>
            </CupoySrefView>
        )
    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return <NeedLoginBlock />;
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            isLoading,
            isError,
            isEmpty,
        } = this.state;

        const {
            bonusOrderIds,
        } = this.props;

        return (
            <Content>
                {Array.isArray(bonusOrderIds) && bonusOrderIds.length > 0 ?
                    <CupoyListRepeat
                        listId={this.constructor.name}
                        rowRenderer={this.rowRenderer}
                        items={bonusOrderIds}
                        isLoading={isLoading}
                        useWindowScroll={true}
                        restoreWindowScroll={true}
                        loadMoreRows={this.listBonusOrders}
                    />
                    :
                    null
                }

                {isLoading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }

                {isError ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            發生錯誤
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無法取得發財金商品，請重新整理後再嘗試。
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }

                {isEmpty ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            無任何發財金商品
                        </GlobalStyle.ErrorContainerTitle>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }
            </Content>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER_BONUS}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            我的發財金
                        </CupoySrefView>
                    }
                    middleComponent="發財金商品"
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        orderMap: state.cardorder.orderMap,
        bonusOrderIds: state.cardorder.bonusOrderIds,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        cardOrderActions: bindActionCreators(actionCreators.cardOrderActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberBonusOrderView);