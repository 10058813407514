/**
|--------------------------------------------------
| 繳費說明
| @author Ac
|--------------------------------------------------
*/
/*--------------------------
    Import
--------------------------*/
import React, { useState, /*useRef, useEffect*/ } from 'react';
import styled from 'styled-components';
import CupoyImage from 'components/commons/CupoyImage';
import CupoyRouter from 'components/commons/router/CupoyRouter';
import CupoySrefView from 'components/commons/router/CupoySrefView';
import TopbarView from "components/commons/TopbarView";
import CupoyLink from 'components/commons/CupoyLink';
import GLOBAL from 'nonnmwebglobal';
import {
  GlobalStyle,
  HelpStyle,
  Colors,
} from 'stylecomponents';

/**
|--------------------------------------------------
| Variables
|--------------------------------------------------
*/
const TYPE = {
  atm: 'atm',
  famiport: 'famiport',
  ibon: 'ibon',
  hilife: 'hilife',
  creditcard: 'creditcard',
};
/**
|--------------------------------------------------
| Styled
|--------------------------------------------------
*/
const TabBar = styled.ul`
  position: fixed;
  z-index: ${GlobalStyle.TabbarZindex};
  top: ${GlobalStyle.TopbarHeight}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  width: 100%;
  height: ${GlobalStyle.TabbarHeight}px;
  background-color: #fff;
  box-shadow: inset 0 -1px 0 0 #DDD;
  overflow-x: auto;
`;
const TabBarItem = styled.li`
  /* min-width: 90px; */
  white-space: nowrap;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  font-size: 14px;
  color: ${({ active }) => active ? Colors.Main : Colors.Dark5};
  cursor: pointer;
`;
const Container = styled.div`
  padding-top: ${GlobalStyle.TopbarHeight}px;
  background-color: #fff;
`;
const Table = styled.table`
  td, th {
    border: 1px solid #CCC;
  }
`;
const Th = styled.th`
  width: ${({ isRow }) => isRow ? '100%' : '80px'};
`;
const HowToImg = styled(HelpStyle.Image)`
  width: 100%;
  margin: 0 auto;
`;
/*--------------------------
    Main Component
--------------------------*/
const TransactionOrderView = () => {

  // const { querys = {} } = CupoyRouter.getCurrent();
  // const { type: queryType } = querys;
  
  // useState
  const [type, setType] = /*TYPE.hasOwnProperty(queryType) ? useState(queryType) :*/ useState(TYPE.atm);

  // createRef
  // const atmRef = useRef(null);
  // const famiportRef = useRef(null);
  // const ibonRef = useRef(null);
  // const hilifeRef = useRef(null);
  // const creditcardRef = useRef(null);

  // useEffect
  // useEffect(() => {
  //   switch (type) {
  //     case TYPE.atm:
  //       atmRef.current.scrollIntoView();
  //       break;
  //     case TYPE.famiport:
  //       famiportRef.current.scrollIntoView();
  //       break;
  //     case TYPE.ibon:
  //       ibonRef.current.scrollIntoView();
  //       break;
  //     case TYPE.hilife:
  //       hilifeRef.current.scrollIntoView();
  //       break;
  //     case TYPE.creditcard:
  //       creditcardRef.current.scrollIntoView();
  //       break;
  //     default:
  //       break;
  //   }
  // });

  return (
    <GlobalStyle.Container
      hasFixedTopbar
      hasFixedTabbar={false}
      backgroundColor='#f5f5f5'
    >
      <TopbarView
        isFixed
        leftComponent={
          <CupoySrefView
            statename={CupoyRouter.STATES.HELP}
            stylecomponent={GlobalStyle.TopbarButton}
          >
            <GlobalStyle.TopbarIcon>
              <i className="zmdi zmdi-chevron-left"></i>
            </GlobalStyle.TopbarIcon>
            返回
          </CupoySrefView>
        }
        middleComponent="繳費說明" />
        <TabBar>
          <TabBarItem
            // ref={atmRef}
            active={type === TYPE.atm}
            onClick={() => {
              setType(TYPE.atm);
              window.scrollTo({ top: 0 });
            }}>
            ATM 提款機
          </TabBarItem>
          <TabBarItem
            // ref={famiportRef}
            active={type === TYPE.famiport}
            onClick={() => {
              setType(TYPE.famiport);
              window.scrollTo({ top: 0 });
            }}>
            全家 FamiPort
          </TabBarItem>
          <TabBarItem
            // ref={ibonRef}
            active={type === TYPE.ibon}
            onClick={() => {
              setType(TYPE.ibon);
              window.scrollTo({ top: 0 });
            }}>
            7-11 ibon
          </TabBarItem>
          <TabBarItem
            // ref={hilifeRef}
            active={type === TYPE.hilife}
            onClick={() => {
              setType(TYPE.hilife);
              window.scrollTo({ top: 0 });
            }}>
            萊爾富 Life-ET
          </TabBarItem>
          <TabBarItem
            // ref={creditcardRef}
            active={type === TYPE.creditcard}
            onClick={() => {
              setType(TYPE.creditcard);
              window.scrollTo({ top: 0 });
            }}>
            信用卡
          </TabBarItem>
        </TabBar>
        <Container>
          <HelpStyle.Container>
            {
              type === TYPE.atm && (
                <HelpStyle.SubSection>
                  <HelpStyle.BoldText>【ATM 提款機繳費】說明</HelpStyle.BoldText>
                  <Table>
                    <colgroup>
                      <col style={{ width: 70 }} />
                      <col style={{ width: 'auto' }} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td colSpan='2'>
                          重要提醒 : <br/>
                          <HelpStyle.RedText>
                            ■ 繳費前請先確認您的【訂單虛擬帳號】，是否正確，避免繳錯帳戶被系統卡款導致無法購買。<br/>
                            ■ 轉帳限額為各家銀行之規定，非本公司之規定；若您欲提高轉帳限額，請致電各銀行申請。
                          </HelpStyle.RedText>
                        </td>
                      </tr>
                      <tr>
                        <Th>步驟一 :</Th>
                        <td>請找任何一台atm提款機，插入您的提款卡，輸入【密碼】。</td>
                      </tr>
                      <tr>
                        <Th>步驟二 :</Th>
                        <td>選擇服務：請尋找【跨行轉帳】或是【轉帳】字眼的服務。</td>
                      </tr>
                      <tr>
                        <Th>步驟三 :</Th>
                        <td>
                          大部份atm都會問，轉入的帳戶是【約定帳戶】或是【非約定帳戶】？請選擇【
                          <HelpStyle.BlueText>非約定帳戶</HelpStyle.BlueText>
                          】，因為您是要匯給9199虛擬專屬帳戶，如果您要匯給自已名下的帳戶才選【約定帳戶】。
                        </td>
                      </tr>
                      <tr>
                        <Th>步驟四 :</Th>
                        <td>{`【選擇購買商品】→【確認交易數量與金額】→產生該筆繳款訂單之 【銀行虛擬帳號】(限${GLOBAL.PAYMENT_LIMIT_HOUR_TEXT}內完成繳費)`}</td>
                      </tr>
                      <tr>
                        <Th>步驟五 :</Th>
                        <td>請進入該筆訂單 【銀行虛擬帳號】 共<HelpStyle.BlueText>14</HelpStyle.BlueText>碼，並按【正確】鈕。</td>
                      </tr>
                      <tr>
                        <Th>步驟六 :</Th>
                        <td>請輸入【轉帳金額】並按【正確】鈕，atm 轉帳銀行將收取15元手續費用，請確認餘額至少有15元可供atm提款機扣款。</td>
                      </tr>
                      <tr>
                        <Th>步驟七 :</Th>
                        <td>轉帳交易成功後，請取出交易明細表供留存備查使用。</td>
                      </tr>
                      <tr>
                        <Th>步驟八 :</Th>
                        <td>匯款轉帳後30分鐘內即可繳費成功，您可至【會員中心>點數卡交易查詢】查詢您的交易序號。</td>
                      </tr>
                    </tbody>
                  </Table>
                </HelpStyle.SubSection>
              )
            }

            {
              type === TYPE.famiport && (
                <HelpStyle.SubSection>
                  <HelpStyle.BoldText>【全家 FamiPort 代碼繳款】說明</HelpStyle.BoldText>
                  <Table>
                    <colgroup>
                      <col style={{ width: 70 }} />
                      <col style={{ width: 'auto' }} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td colSpan='2'>
                          重要提醒 : <br/>
                          <HelpStyle.RedText>
                            ■ 每次繳款全家會另外收取25元手續費。<br/>
                            ■ 全家繳費(每日)最低金額新台幣100元，最高金額10,000元整。<br/>
                            ■ 全家繳費(單筆)金額新台幣100元，最高金額5,000元整。 
                          </HelpStyle.RedText>
                        </td>
                      </tr>
                        <tr>
                          <Th>步驟一 :</Th>
                          <td>
                            請至全省裝有FamiPort繳款機器的全家超商門市（
                            <CupoyLink href="http://www.family.com.tw/marketing/inquiry.aspx" target='_blank' rel='noopener noreferrer'>
                              查詢全家門市
                            </CupoyLink>
                            ），並進入超商繳款系統。
                          </td>
                        </tr>
                        <tr>
                          <Th colSpan='2'>步驟二 :</Th>
                        </tr>
                        <tr>
                          <td colSpan='2'>
                            <CupoyImage
                              src={`${GLOBAL.URL_PREFIX}/images/famiport_howto.jpg`}
                              stylecomponent={HowToImg}
                            />
                          </td>
                        </tr>
                        <tr>
                          <Th>步驟三 :</Th>
                          <td>
                            超商將傳送繳費資料給9199，於收到繳費資料後 1小時 內即可入帳繳費交易保證金。
                          </td>
                        </tr>
                        <tr>
                          <Th>步驟四 :</Th>
                          <td>{`列印繳款單後請在${GLOBAL.PAYMENT_LIMIT_HOUR_TEXT}內到櫃檯繳費，繳費完畢請妥善保留超商繳費收據以備查。`}</td>
                        </tr>
                        <tr>
                          <Th>備註 :</Th>
                          <td>
                            1. 交易完成後門市恕不接受現場退費。<br/>
                            2. 繳費時請確認是否為會員本人。<br/>
                            {`3. 列印繳費單後，如${GLOBAL.PAYMENT_LIMIT_HOUR_TEXT}內未完成繳款，該繳款代碼將自動失效。`}
                          </td>
                        </tr>
                    </tbody>
                  </Table>
                </HelpStyle.SubSection>
              )
            }

            {
              type === TYPE.ibon && (
                <HelpStyle.SubSection>
                  <HelpStyle.BoldText>【7-11 ibon 繳款】說明</HelpStyle.BoldText>
                  <Table>
                    <colgroup>
                      <col style={{ width: 70 }} />
                      <col style={{ width: 'auto' }} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td colSpan='2'>
                          提醒《7-11 ibon 繳款》無法購買點數卡。
                          {/* <CupoySrefView
                            statename={CupoyRouter.STATES.NEWSCONTENT}
                            params={{ id: '1329559889700' }}
                          >
                            詳細說明請見公告
                          </CupoySrefView> */}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan='2'>
                          重要提醒 : <br/>
                          <HelpStyle.RedText>
                            ■ 每次繳款7-11會另外收取25元手續費。<br/>
                            ■ 7-11繳費最低金額新台幣100元，最高金額10,000元整。
                          </HelpStyle.RedText>
                        </td>
                      </tr>
                      <tr>
                        <Th>步驟一 :</Th>
                        <td>登入9199確認購買商品數量與金額 > 產生該筆 [訂單明細]</td>
                      </tr>
                      <tr>
                        <Th>步驟二 :</Th>
                        <td>
                          請至全省裝有ibon繳款機器的7-11超商門市（
                          <CupoyLink href="https://emap.pcsc.com.tw" target='_blank' rel='noopener noreferrer'>
                              查詢7-11門市
                          </CupoyLink>
                          ），並進入超商繳款系統。點選「代碼輸入」→ 「輸入廠商代號 919」。
                        </td>
                      </tr>
                      <tr>
                        <Th>步驟三 :</Th>
                        <td>
                          輸入您註冊9199正式會員的「會員帳號、身分證字號、行動電話」，最後確認繳費[訂單明細]的內容與金額是否正確，按下確認並列印繳款單。
                        </td>
                      </tr>
                      <tr>
                        <Th colSpan='2'>步驟四 :</Th>
                      </tr>
                      <tr>
                        <td colSpan='2'>
                          <CupoyImage
                            src={`${GLOBAL.URL_PREFIX}/images/ibon_howto.jpg`}
                            stylecomponent={HowToImg}
                          />
                        </td>
                      </tr>
                      <tr>
                        <Th>步驟五 :</Th>
                        <td>超商將傳送繳費資料給9199，於收到繳費資料後 <HelpStyle.BlueText>1小時</HelpStyle.BlueText> 內即可入帳產生訂單。</td>
                      </tr>
                      <tr>
                        <Th>步驟六 :</Th>
                        <td>列印繳款單後請在
                          <HelpStyle.BlueText>{`${GLOBAL.PAYMENT_LIMIT_HOUR_TEXT}`}</HelpStyle.BlueText>
                          內到櫃檯繳費，繳費完畢請妥善保留超商繳費收據以備查。
                        </td>
                      </tr>
                      <tr>
                        <Th>備註 :</Th>
                        <td>
                          1. 交易完成後門市恕不接受現場退費。<br/>
                          2. 繳費時請確認是否為會員本人。<br/>
                          {`3. 列印繳費單後，如${GLOBAL.PAYMENT_LIMIT_HOUR_TEXT}內未完成繳款，該繳款代碼將自動失效。`}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </HelpStyle.SubSection>
              )
            }

            {
              type === TYPE.hilife && (
                <HelpStyle.SubSection>
                  <HelpStyle.BoldText>【萊爾富 Life-ET 繳款】說明</HelpStyle.BoldText>
                  <Table>
                    <colgroup>
                        <col style={{ width: 70 }} />
                        <col style={{ width: 'auto' }} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td colSpan='2'>
                          重要提醒 : <br/>
                          <HelpStyle.RedText>
                            ■ 每次繳款萊爾富會另外收取25元手續費。<br/>
                            ■ 萊爾富繳費每筆最低金額不得少於新台幣100元，最高金額10,000元整。
                          </HelpStyle.RedText>
                        </td>
                      </tr>
                      <tr>
                        <Th>步驟一 :</Th>
                        <td>登入9199確認購買商品數量與金額 > 產生該筆訂單 [繳費代碼]</td>
                      </tr>
                      <tr>
                        <Th>步驟二 :</Th>
                        <td>
                          請至全省裝有 Life-ET 繳款機器的萊爾富超商門市(
                          <CupoyLink href="http://www.hilife.com.tw/storeInquiry_street.aspx" target='_blank' rel='noopener noreferrer'>
                            查詢萊爾富門市
                          </CupoyLink>
                          )，並進入超商繳款系統。點選「繳費．代收」→「網路交易」→「代碼繳費」→確認閱讀條款後，輸入[繳費代碼]，確認代碼並列印繳款單。
                        </td>
                      </tr>
                      <tr>
                        <Th colSpan='2'>步驟三 :</Th>
                      </tr>
                      <tr>
                        <td colSpan='2'>
                          <CupoyImage
                            src={`${GLOBAL.URL_PREFIX}/images/9199_hilife.jpg`}
                            stylecomponent={HowToImg}
                          />
                        </td>
                      </tr>
                      <tr>
                        <Th>步驟四 :</Th>
                        <td>超商將傳送繳費資料給9199，於收到繳費資料後 <HelpStyle.BlueText>1小時</HelpStyle.BlueText> 內即可入帳完成交易。</td>
                      </tr>
                      <tr>
                        <Th>步驟五 :</Th>
                        <td>
                          列印繳款單後請在
                          <HelpStyle.BlueText>{`${GLOBAL.PAYMENT_LIMIT_HOUR_TEXT}`}</HelpStyle.BlueText>
                          內到櫃檯繳費，繳費完畢請妥善保留超商繳費收據以備查。
                        </td>
                      </tr>
                      <tr>
                        <Th>備註 :</Th>
                        <td>
                          1. 交易完成後門市恕不接受現場退費。 <br/>
                          2. 繳費時請確認輸入資料是否為會員本人。 <br/>
                          {`3. 列印繳費單後，如${GLOBAL.PAYMENT_LIMIT_HOUR_TEXT}內未完成繳款，該繳款代碼將自動失效。`}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
              </HelpStyle.SubSection>
              )
            }

            {
              type === TYPE.creditcard && (
                <HelpStyle.SubSection>
                  <HelpStyle.BoldText>【信用卡繳款】說明</HelpStyle.BoldText>
                  <Table>
                    <colgroup>
                      <col style={{ width: 70 }} />
                      <col style={{ width: 'auto' }} />
                    </colgroup>
                    <tbody>
                    {/*
                      <tr>
                        <td colSpan='2'>
                          重要提醒 : <br/>
                          <HelpStyle.RedText>
                            ■ 新會員30天內不能使用信用卡。<br/>
                            ■ 正式會員24小時內信用卡購買上限1萬元整。<br/>
                            {`■ 產生本付款訂單後，請於${GLOBAL.PAYMENT_LIMIT_HOUR_TEXT}內完成付款(逾期失效)。`}<br/>
                            ■ 信用卡服務僅限於購買點數卡、產品包類商品，道具卡未開放。<br/>
                          </HelpStyle.RedText>
                        </td>
                      </tr>
                      */}
                      <tr>
                        <Th>步驟一 :</Th>
                        <td>登入9199正式會員確認點數卡、產品包購買商品。</td>
                      </tr>
                      <tr>
                        <Th>步驟二 :</Th>
                        <td>【選擇購買商品名稱】→【確認交易數量與金額】</td>
                      </tr>
                      <tr>
                        <Th>步驟三 :</Th>
                        <td>
                          {`選擇【信用卡付款】產生該筆繳款訂單 (限${GLOBAL.PAYMENT_LIMIT_HOUR_TEXT}內完成繳費)`}
                        </td>
                      </tr>
                      <tr>
                        <Th>步驟四 :</Th>
                        <td>【請輸入信用卡資訊】→【刷卡簡訊驗證】→【完成付款】</td>
                      </tr>
                      <tr>
                        <Th>步驟五 :</Th>
                        <td>進入【會員中心】→【點數卡交易查詢】查看您購買商品訂單內容</td>
                      </tr>
                      <tr>
                        <Th>備註 :</Th>
                        <td>
                          <HelpStyle.RedText>需有3D發卡行的信用卡才可使用 : </HelpStyle.RedText><br/>
                          國泰世華商業銀行、永豐商業銀行、聯邦商業銀行、台灣新光商業銀行、第一商業銀行、<br/>
                          華南商業銀行、兆豐國際商業銀行、合作金庫商業銀行、渣打國際商業銀行、台灣中小企業銀行、<br/>
                          彰化商業銀行、日盛國際商業銀行、台灣銀行、台灣永旺信用卡(股)公司、台灣土地銀行、台新銀行、<br/>
                          上海商業儲蓄銀行、中國信託、富邦銀行。
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </HelpStyle.SubSection>
              )
            }
          </HelpStyle.Container>
        </Container>
    </GlobalStyle.Container>
  );
};

/*--------------------------
  Export
--------------------------*/
export default TransactionOrderView;
