/*=====================================
    首頁

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import TopbarView from 'components/commons/TopbarView';
import { GlobalStyle, Colors } from 'stylecomponents';
import styled from 'styled-components';
import CupoyRouter from 'components/commons/router/CupoyRouter';
import CupoySrefView from 'components/commons/router/CupoySrefView';
import CupoyNoteContent from 'components/commons/CupoyNoteContent';
import TimeFormat from 'components/commons/TimeFormat';
import MarkdownContentView from 'cupoy-md-editor/lib/preview';

/*--------------------------
    styled
--------------------------*/

const BulletinContent = styled.div`

    min-height: calc(100vh - 44px);
    background-color: #fff;
    overflow-y: scroll;
    padding: 16px;

`;

const Title = styled.div`
    font-size: 24px;
    line-height: 1.5em;
    color: ${Colors.Dark3};
    font-weight: 500;
`;
const Time = styled.div`
    color: ${Colors.Dark5};
    font-size: 14px;
    line-height: 1.5em;
    margin: 10px 0 20px;
    font-weight: normal;
`;

const TopbarMiddle = styled.h3`

    height: 100%;
    line-height: 44px;
    margin: 0;
    font-size: 16px;
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
    color: #FFFFFF;
    text-align: center;

`;

const LoadingContent = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 44px);
    background-color: #fff;

`;

/* 最新消息: */
/*--------------------------
    Main Component
--------------------------*/

class NewsContentView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);
        
        this.getInitState = this.getInitState.bind(this);
        this.sliceBulletinTitle = this.sliceBulletinTitle.bind(this);
        this.getBulletinDetail = this.getBulletinDetail.bind(this);

        this.state = this.getInitState();
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        this.getBulletinDetail();
    }

    getInitState() {
        return {
            isLoading: false,
            isEmpty: false,
            isError: false,
        };
    }

    getBulletinDetail() {

        const {
            itemMap,
        } = this.props;

        const {
            isLoading,
            isEmpty,
        } = this.state;

        const bulletin = itemMap[this.props.match.params.id];

        if (bulletin || isLoading || isEmpty) {
            return;
        }

        this.setState({
            isLoading: true,
            isEmpty: false,
            isError: false,
        }, () => {
            this.props.bulletinActions.getBulletinDetail({
                bulletinid: this.props.match.params.id,
            }).then(result => {
                this.setState({
                    isLoading: false,
                    isEmpty: result.resultSize === 0,
                });
            }).catch(error => {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            });
        });
    }

    sliceBulletinTitle(actionType, title){

        if(!title){
            return null;
        }

        switch (actionType) {

            case 'getTagName':
                return title.slice(1,3);
            case 'getTitle':
                return title.slice(4);
            default:
                return title;
        }

    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            itemMap,
        } = this.props;

        const {
            isLoading,
            isError,
            isEmpty,
        } = this.state;

        const bulletin = itemMap[this.props.match.params.id] || {};

        const {
            title = '',
            bulletincontent = '',
            publishtime,
            note_editor_type,
            markdown
        } = bulletin;
        
        return (
            <GlobalStyle.Container
                hasFixedTopbar
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.NEWSLIST}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            最新消息
                        </CupoySrefView>
                    }
                    middleComponent={
                        <TopbarMiddle>
                            {this.sliceBulletinTitle('getTitle', title)}
                        </TopbarMiddle>
                    }
                />
                <BulletinContent>

                    <Title>{title}</Title>

                    {publishtime && (
                        <Time>
                            發布日期：
                            <TimeFormat
                                format='timeString'
                                time={publishtime}
                            />
                        </Time>
                    )}

                    {!!bulletin && (note_editor_type === 1 ?
                        <MarkdownContentView mdcontent={markdown} />      
                        :<CupoyNoteContent html={bulletincontent} />
                    )}

                    {/* {!!bulletin && (
                        <CupoyNoteContent html={bulletincontent}/>
                    )} */}

                    {isLoading && (
                        <LoadingContent>
                            <GlobalStyle.Spin />
                        </LoadingContent>
                    )}

                    {isEmpty && (
                        <GlobalStyle.ErrorContainer>
                            <GlobalStyle.ErrorContainerTitle>
                                找不到新聞
                            </GlobalStyle.ErrorContainerTitle>
                            <GlobalStyle.ErrorContainerDesription>
                                沒有此篇新聞，請重新整理後再嘗試。
                            </GlobalStyle.ErrorContainerDesription>
                        </GlobalStyle.ErrorContainer>
                    )}

                    {isError && (
                        <GlobalStyle.ErrorContainer>
                            <GlobalStyle.ErrorContainerTitle>
                                發生錯誤
                            </GlobalStyle.ErrorContainerTitle>
                            <GlobalStyle.ErrorContainerDesription>
                                無法取得內容，請重新整理後再嘗試。
                            </GlobalStyle.ErrorContainerDesription>
                        </GlobalStyle.ErrorContainer>
                    )}

                </BulletinContent>
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        itemMap: state.bulletin.itemMap,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        bulletinActions: bindActionCreators(actionCreators.bulletinActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(NewsContentView);