import actionTypes from 'actions/types/systemMsgActionTypes';
import convetDBResultSetToArray from "actions/util/convetDBResultSetToArray";

/*==========================
    Reducers
==========================*/
const reducers = {
    [actionTypes.getUnreadSystemMsgsSuccess]: function(state, action) {
        return getUnreadSystemMsgsSuccess(state, action);
    },
};

function getUnreadSystemMsgsSuccess(state, { payload }) {

    const {
        result,
    } = payload;

    const dbresult = convetDBResultSetToArray(result);
    const new_unreadSystemTextMsgList = dbresult;

    const newState = {
        ...state,
        unreadSystemTextMsgList: new_unreadSystemTextMsgList,
    };
    
    return newState;
}

/*==========================
    Export
==========================*/
export default function createReducers(initialState) {
	return function reducer(state = initialState, action) {
		if(reducers.hasOwnProperty(action.type)) {
			return reducers[action.type](state, action);
		} else {
			return state;
		}
	};
}