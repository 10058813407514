import actionTypes from "actions/types/guestbookActionTypes";
import convetDBResultSetToArray from "actions/util/convetDBResultSetToArray";
// import {uniq} from 'lodash';
/*==========================
    Reducers
==========================*/
const reducers = {
    [actionTypes.listVprovMsgContentsSuccess]: function(state, action) {
        return listVprovMsgContentsSuccess(state, action);
    },
    [actionTypes.addVprovMsgContentSuccess]: function(state, action) {
        return addVprovMsgContentSuccess(state, action);
    },

};



/*==========================
    Method
==========================*/
// msgMap: {},
// msgIdsByVprovIds: {},
function listVprovMsgContentsSuccess (state, { payload }) {

    const { viprovid, refresh, result } = payload;
    const dbresult = convetDBResultSetToArray(result);

    const {
        msgIdsByVprovIds: msgIdsByVprovIdsCache,
        msgMap,
    } = state;

    const old_msgIdsByVprovIds = msgIdsByVprovIdsCache[viprovid] || [];

    let new_msgMap = Object.assign({}, msgMap);

    const new_msgIdsByVprovIds = dbresult.reduce((arr, message) => {

        const { msgid, visible } = message;
        
        if (visible){
            new_msgMap = Object.assign({}, new_msgMap, {
                [msgid]: message,
            });
        }

        return visible ? [...arr, msgid] : arr;
    }, []);

    const new_msgIdsByVprovIdsCache = Object.assign({}, msgIdsByVprovIdsCache, {
        [viprovid]: refresh ? new_msgIdsByVprovIds : [...new Set([...old_msgIdsByVprovIds, ...new_msgIdsByVprovIds])],
    });

    return Object.assign({}, state, {
        msgIdsByVprovIds: new_msgIdsByVprovIdsCache,
        msgMap: new_msgMap,
        isloading: false,
	});
}
function addVprovMsgContentSuccess (state, action) {
    const { viprovid } = action.payload;
    const newids = [];

    const newmsgIdsByVprovIds = Object.assign({}, state.msgIdsByVprovIds, {[viprovid]: newids});
    return Object.assign({}, state, {
        msgIdsByVprovIds: newmsgIdsByVprovIds,
        isloading: false,
	});
}



// 操作失敗
function actionFailed (state, action) {

    if(state.error === action.payload){
        return state;
    }

    const error = action.payload;

    return Object.assign({}, state, {
        isloading: false,
        error,
	});


}


// 操作中
function actionStarted (state, action) {

    if(state.isloading){
        return state;
    }

    return Object.assign({}, state, {
        isloading: true,
	});


}


/*==========================
    Export
==========================*/
export default function createReducers (initialState) {
	return function reducer(state = initialState, action) {
        if(!actionTypes.hasOwnProperty(action.type)){
            return state;
        }        
		if (reducers.hasOwnProperty(action.type)) {
            return reducers[action.type](state, action);
		} else if(action.type.lastIndexOf('Started') !== -1){
			return actionStarted(state, action);            
		} else if(action.type.lastIndexOf('Failed') !== -1){
			return actionFailed(state, action);            
        } else {
			return state;
		}
	};
}