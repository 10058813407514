/*=====================================
    MemberVitemOrderList

    Author: Gray
    CreateTime: 2018 / 03 / 15
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { Route } from "react-router-dom";
import { connect } from 'react-redux';
import styled from 'styled-components';
import { GlobalStyle, Colors } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import GLOBAL from 'nonnmwebglobal';
import MemberVitemOrderListSellViewComponent from 'components/member/vitem/MemberVitemOrderListSellView';
import MemberVitemOrderListBuyViewComponent from 'components/member/vitem/MemberVitemOrderListBuyView';
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/
const HeaderHeight = 50;
const Header = styled.div`
    display: flex;
    position: fixed;
    top: ${GlobalStyle.TopbarHeight}px;
    left: 0;
    width: 100%;
    height: ${HeaderHeight}px;
    background-color: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #DDDDDD;
    z-index: 1;
`;
const NavItem = styled.div`
    display: inline-block;
    width: 50%;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: ${(props) => props.active ? Colors.Orange : Colors.Dark5};
    text-align: center;
    cursor: pointer;
`;
const SubContent = styled.div`
    padding: ${HeaderHeight}px 0 0 0;
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberVitemOrderListView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            MemberVitemOrderListSellViewComponent,
            MemberVitemOrderListBuyViewComponent,
        };
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    // componentDidMount() {

    //     this.init(this.props);
    // }

    // // ------------------------------
    // // componentWillReceiveProps
    // // ------------------------------
    // componentDidUpdate(prevProps) {

    //     if(prevProps.haslogin !== this.props.haslogin) {
    //         this.init(this.props);
    //     }
    // }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    // init(props) {

    //     if(props.haslogin) {
    //         this.lazyLoadingComponent();
    //     }
    // }
    
    // ------------------------------
    // lazyLoadingComponent
    // ------------------------------
    // lazyLoadingComponent() {

    //     import('components/member/vitem/MemberVitemOrderListSellView').then((MemberVitemOrderListSellModule) => {
    //         this.setState({ MemberVitemOrderListSellViewComponent: MemberVitemOrderListSellModule.default });
    //     }).catch(err => { console.log(err) });
    //     import('components/member/vitem/MemberVitemOrderListBuyView').then((MemberVitemOrderListBuyModule) => {
    //         this.setState({ MemberVitemOrderListBuyViewComponent: MemberVitemOrderListBuyModule.default });
    //     }).catch(err => { console.log(err) });
    // }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return <NeedLoginBlock />;
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            MemberVitemOrderListSellViewComponent,
            MemberVitemOrderListBuyViewComponent,
        } = this.state;

        return (
            <div>
                <Header>
                    <CupoySrefView 
                        statename={CupoyRouter.STATES.MEMBER_VITEM_ORDER_LIST_SELL}
                        stylecomponent={NavItem}
                        active={CupoyRouter.isActive(CupoyRouter.STATES.MEMBER_VITEM_ORDER_LIST_SELL)}
                    >
                        出售區
                    </CupoySrefView>
                    <CupoySrefView 
                        statename={CupoyRouter.STATES.MEMBER_VITEM_ORDER_LIST_BUY}
                        stylecomponent={NavItem}
                        active={CupoyRouter.isActive(CupoyRouter.STATES.MEMBER_VITEM_ORDER_LIST_BUY)}
                    >
                        收購區
                    </CupoySrefView>
                </Header>
                <SubContent>
                    {MemberVitemOrderListBuyViewComponent ? <Route path={CupoyRouter.PATHS.MEMBER_VITEM_ORDER_LIST_BUY} component={MemberVitemOrderListBuyViewComponent} exact/> : null}
                    {MemberVitemOrderListSellViewComponent ? <Route path={CupoyRouter.PATHS.MEMBER_VITEM_ORDER_LIST_SELL} component={MemberVitemOrderListSellViewComponent} exact/> : null}
                </SubContent>
            </div>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            返回
                        </CupoySrefView>
                    }
                    middleComponent={GLOBAL.VITEM_DISPLAY_TEXT}
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberVitemOrderListView);