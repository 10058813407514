/*=====================================
    全域共用樣式

    Author: Gray
    createtime: 2017 / 12 / 21
=====================================*/

/*--------------------------
    Import 
--------------------------*/
import styled, { keyframes } from 'styled-components';
import Colors from './colors';
import Button from './button';

/*--------------------------
    Style Variables
--------------------------*/
const TopbarHeight = 44;
const TabbarHeight = 50;
const TopbarZindex = 960;
const TabbarZindex = 950;
const ViewSpace = 12; // 預設的留白空間

/*--------------------------
    Style Keyframes
--------------------------*/
const rotate360 = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`;

/*--------------------------
    Style Component
--------------------------*/
const Topbar = styled.div`
    position: ${(props) => props.isFixed ? 'fixed' : 'static'};
    top: 0;
    left: 0;
    right: 0;
    height: ${TopbarHeight}px;
    background-color: ${Colors.Main};
    z-index: ${TopbarZindex};
    transform: translateZ(0);
`;
const TopbarContent = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`;
const TopbarOtherPannel = styled.div`
    display: inline-block;
	min-width: 84px;
    height: 100%;
    color: #fff;
	text-align: ${(props) => props.isRight ? 'right' : 'left'};
`;
const TopbarMiddlePannel = styled.div`
	max-width: calc(100% - 180px);
    display: inline-flex;
    height: 100%;
    margin: 0 0 0;
    align-items: center;
    justify-content: center;
    flex: 1;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
`;
const TopbarButton = styled.div`
	display: inline-flex;
	height: 100%;
	padding: 0 12px;
	color: #fff;
    font-size: 12px;
    align-items: center;
    cursor: pointer;
`;
const TopbarIcon = styled.span`
	margin: ${(props) => props.margin ? props.margin : '0 4px 0 0'};;
    font-size: ${(props) => props.size ? props.size : '28px'};
    color: #fff;
`;
const Container = styled.div`
	position: relative;
	width: 100%;
	min-height: 100vh;
	height: 100%;
	padding-top: ${(props) => props.hasFixedTopbar ? TopbarHeight : 0}px;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: ${(props) => {
			switch(props.hasFixedTabbar){
				case true:
					return TabbarHeight;
				case 'stepBottomBar':
					return 60;
				default:
					return 0;
			}
		}
	}px;
	background-color: ${(props) => props.backgroundColor ? props.backgroundColor : '#fff'};
	z-index: ${(props) => props.hasFixedTabbar ? 'auto' : TabbarZindex + 1};
`;
const Tabbar = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${TabbarHeight}px;
    border-top: 1px solid #DDDDDD;
    background-color: #fff;
    z-index: ${TabbarZindex};
    transform: translateZ(0);
`;
const Spin = styled.div`
	position: relative;
	vertical-align: sub;
	display: ${props => props.display ? props.display : 'inline-block'};
	width: ${props => props.size ? props.size : '20px'};
	height: ${props => props.size ? props.size : '20px'};
	margin: ${props => props.margin ? props.margin : '0 auto'};
	border-width: ${props => props.borderWidth ? props.borderWidth : '2px'};
	border-style: solid;
	border-top-color: ${props => props.bgColor ? props.bgColor : 'transparent'};
	border-left-color: ${props => props.bgColor ? props.bgColor : 'transparent'};
	border-right-color: ${props => props.color ? props.color : Colors.Main};
	border-bottom-color: ${props => props.color ? props.color : Colors.Main};
	border-radius: 100%;
	animation: ${rotate360} 0.6s linear infinite;
`;
const LoadingContainer = styled.div`
	display: flex;
	width: 100%;
	height: 112px;
	align-items: center;
	justify-content: center;
`;
const ErrorContainer = styled.div`
	width: 100%;	
	text-align: center;
	
	${(props) => {
		if(props.padding) {
			return `
				padding: ${props.padding};
			`
		}
		return `padding: 160px 0;`;
	}}
`;
const ErrorContainerTitle = styled.div`
	max-width: 500px;
	margin: 0 auto 10px auto;
	padding: 0 20px;
	color: ${Colors.Dark2};
	letter-spacing: 0;
	font-size: 20px;
	font-weight: 600;
`;
const ErrorContainerDesription = styled.div`
	max-width: 500px;
	line-height: 24px;
	margin: 0 auto;
	padding: 0 20px;
	color: ${Colors.Dark3};
	letter-spacing: 0;
	font-size: 16px;
`;
const ErrorContainerBtn = styled(Button.Orange)`
	margin: 10px 0 0 0;
`;


const GlobalStyle = {
	/*--------------------------
		Variables
	--------------------------*/
	TopbarHeight,
	TabbarHeight,
	TopbarZindex,
	TabbarZindex,
	ViewSpace,

	/*--------------------------
		Component
	--------------------------*/
	Topbar,
	TopbarContent,
	TopbarOtherPannel,
	TopbarMiddlePannel,
	TopbarButton,
	TopbarIcon,
	Container,
	Tabbar,
	Spin,
	LoadingContainer,
	ErrorContainer,
	ErrorContainerTitle,
	ErrorContainerDesription,
	ErrorContainerBtn,
}

export default GlobalStyle;