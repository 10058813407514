/*--------------------------
    Import 
--------------------------*/
import styled from 'styled-components';

/*--------------------------
    Style Variables
--------------------------*/

/*--------------------------
    Style Component
--------------------------*/

const TabBarWrapper = styled.div`

    display: flex;
    background: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #DDDDDD;
`;

const TabBarItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 50px;
    color: ${props => props.active ? '#F75C2F' : '#777777'};
    font-size: ${props => props.more ? '26px' : '14px'};
    cursor: pointer;
`;





const TabBar = {

    TabBarWrapper,
    TabBarItem,

}

export default TabBar;
