/*=====================================
    MemberBonusDeposit

    Author: Gray
    CreateTime: 2018 / 03 / 12
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import styled from 'styled-components';
import Recaptcha from "react-grecaptcha";
import MediaQuery from 'react-responsive';
import GLOBAL from '../../../nonnmwebglobal';
import { GlobalStyle, Colors, Button } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
    padding: 16px;
`;
const Label = styled.div`
    margin: 0 0 8px 0;
    font-size: 12px;
    color: #232423;
`;
const Input = styled.input`
    width: 100%;
    height: 44px;
    margin: 0 0 16px 0;
    padding: 0 12px;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    background-color: #FAFAFA;
    font-size: 15px;
    color: #222328;

    ${(props) => {

        if(!props.error) {
            return ``;
        }

        return `
            border: 1px solid ${Colors.Red};
            color: ${Colors.Red};
        `;
    }}
`;
const GoogleCertified = styled.div`
    margin: 0 0 32px 0;
`;
const SubmitButton = styled(Button.Orange)`
    position: relative;
    display: block;
    width: 100%;
    height: 44px;
    line-height: 44px;
    margin: 0 0 32px 0;
    border-radius: 2px;
    font-size: 16px;
    cursor: pointer;
`;
const SubmitLoading = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    left: 16px;
    height: 100%;
    align-items: center;
    justify-content: center; 
`;
const HelpInfo = styled.div`
    position: relative;
    display: inline-block;
    line-height: 18px;
    margin: 0 0 10px 0;
    padding: 0 0 0 20px;
    font-size: 13px;
    color: #777777;

    &:before {
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        width: 10px;
        height: 10px;
        border: 1px solid #777777;
        border-radius: 50%;
    }
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberBonusDepositView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            bcardid: '',
            serialnum: '',
            recaptchaResponse: '',
            isLoading: false,
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.callback = this.callback.bind(this);
        this.expiredCallback = this.expiredCallback.bind(this);
        this.submit = this.submit.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        this.init(this.props);
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props) {

    }

    // ------------------------------
    // Input value change
    // ------------------------------
    onInputChange(event, key) {

        this.setState({
            [key]: event.target.value,
        })
    }

    // ------------------------------
    // google callback
    // ------------------------------
    callback(response) {

        this.setState({
            recaptchaResponse: response,
        });
    }

    // ------------------------------
    // google expiredCallback
    // ------------------------------
    expiredCallback() {
        
        this.props.webActions.openAlertDialog({
            title: '安全認證已過期',
        });
    }

    // ------------------------------
    // submit
    // ------------------------------
    submit() {

        const {
            bcardid,
            serialnum,
            recaptchaResponse,
            isLoading,
        } = this.state;

        const disableSubmit = !bcardid || !serialnum || !bcardid.trim() || !serialnum.trim() || isLoading || (!GLOBAL.isAppFile && !recaptchaResponse);

        if(disableSubmit) {
            return;
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.bonusActions.depositBonusPaidCard({
                bcardid: bcardid,
                serialnum: serialnum,
                passcode: recaptchaResponse,
            }).then((result) => {

                this.setState({
                    isLoading: false,
                });

                this.props.webActions.openAlertDialog({
                    title: '兌換成功',
                    closeName: '查看結果',
                    closeCallback: () => {
                        this.props.webActions.cupoyStateGo(CupoyRouter.STATES.MEMBER_BONUS_LOGS)
                    }
                });
                
            }, (error) => {

                var errorMsg = '發生無法預期的錯誤';
                var errorDescription = '';

                if(error && error.code) {
                    switch(error.code) {
                        case 'error.formValidator.antiPassCodeFailed':
                            errorMsg = "驗證錯誤";
                            break;
                        case 'error.rscNotExist':
                            errorMsg = "此卡號、序號不存在，請洽客服人員。";
                            break;
                        case 'error.nonnDepositBonusCardOncePerDayError':
                            errorMsg = "兌換失敗";
                            errorDescription = '每位VIP會員一天只能兌換一次發財金!';
                            break;
                        default:
                            errorMsg = error.message;
                            break;
                    }
                }

                this.props.webActions.openAlertDialog({
                    title: errorMsg,
                    description: errorDescription,
                });

                this.setState({
                    isLoading: false,
                });
            });
        })
    }    

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {
        return <NeedLoginBlock />;
    }
    
    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            bcardid,
            serialnum,
            recaptchaResponse,
            isLoading,
        } = this.state;

        const disableSubmit = !bcardid || !serialnum || !bcardid.trim() || !serialnum.trim() || isLoading || (!GLOBAL.isAppFile && !recaptchaResponse);

        return (
            <Content>
                <Label>兌換卡 - 卡號</Label>
                <Input
                    type="text"
                    placeholder="請輸入卡號"
                    value={bcardid}
                    onChange={(event) => this.onInputChange(event, 'bcardid')}
                    error={false}
                />
                <Label>兌換卡 - 序號</Label>
                <Input
                    type="text"
                    placeholder="請輸入序號"
                    value={serialnum}
                    onChange={(event) => this.onInputChange(event, 'serialnum')}
                    error={false}
                />
                {GLOBAL.isAppFile ?
                    null
                    :
                    <div>
                        <Label>安全性驗證</Label>
                        <GoogleCertified>
                            <MediaQuery minWidth={0} maxWidth={360}>
                                <Recaptcha
                                    sitekey={GLOBAL.grecaptcha_key}
                                    callback={this.callback}
                                    expiredCallback={this.expiredCallback}
                                    locale="zh-TW"
                                    className="g-recaptcha"
                                    style={{
                                        transform: 'scale(0.9)',
                                        WebkitTransform: 'scale(0.9)',
                                        transformOrigin: '0 0',
                                        WebkitTransformOrigin: '0 0',
                                    }}
                                />

                            </MediaQuery>
                            <MediaQuery minWidth={361}>
                                <Recaptcha
                                    sitekey={GLOBAL.grecaptcha_key}
                                    callback={this.callback}
                                    expiredCallback={this.expiredCallback}
                                    locale="zh-TW"
                                    className="g-recaptcha"
                                />
                            </MediaQuery>
                        </GoogleCertified>
                    </div>
                }
                <SubmitButton
                    onClick={() => this.submit()}
                    disable={disableSubmit}
                >
                    {isLoading ?
                        <SubmitLoading>
                            <GlobalStyle.Spin color="#fff"/>
                        </SubmitLoading>
                        :
                        null
                    }
                    立刻兌換
                </SubmitButton>
                <HelpInfo>具有VIP資格的會員才可兌換發財金。</HelpInfo>
                <HelpInfo>請輸入卡號、序號，輸入完畢後點擊【立即兌換】，發財金隨即入點。</HelpInfo>
                <HelpInfo>若輸入之兌換卡已超過有效期限，此兌換卡無法兌換。</HelpInfo>
                <HelpInfo>每位VIP會員一天只能兌換一次發財金。</HelpInfo>
                <HelpInfo>免費領取發財金序號，請下載《9173揪一起上》。</HelpInfo>
            </Content>
        );
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER_BONUS}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            我的發財
                        </CupoySrefView>
                    }
                    middleComponent="兌換發財金"
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        bonusActions: bindActionCreators(actionCreators.bonusActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberBonusDepositView);