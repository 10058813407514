/**
|--------------------------------------------------
| 發財金說明
| @author Ac
|--------------------------------------------------
*/
import React from 'react';
import styled from 'styled-components';
import CupoyRouter from 'components/commons/router/CupoyRouter';
import CupoySrefView from 'components/commons/router/CupoySrefView';
import TopbarView from "components/commons/TopbarView";
import CupoyImage from 'components/commons/CupoyImage';
import GLOBAL from 'nonnmwebglobal';
import {
  HelpStyle,
  GlobalStyle,
} from 'stylecomponents';

/**
|--------------------------------------------------
| Styled
|--------------------------------------------------
*/
const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const MemberAvatar = styled.image`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 8px;
`;
/**
|--------------------------------------------------
| Components
|--------------------------------------------------
*/
const WhatIsBonus = () => {
  return (
    <HelpStyle.Container>
      <HelpStyle.Title>何謂【發財金】</HelpStyle.Title>
      <ul>
        <li>
          <HelpStyle.Section>
            1. 【發財金】紅利酬賓計劃，是由『9199交易久久』網站策劃，<br/>
            針對VIP會員提供將消費額回饋紅利點數的服務，限「遊戲點數卡、產品包」類商品，<br/>
            只要擁有【發財金】即可在『
            <CupoySrefView statename={CupoyRouter.STATES.CARD_BONUSPRODUCTS}>發財商城</CupoySrefView>
            』專區兌換超值商品。

            <HelpStyle.Table>
              <tbody>
                <tr>
                  <td>交易館別</td>
                  <td>點數卡、虛寶包</td>
                </tr>
                <tr>
                  <th>負擔手續費</th>
                  <td>無</td>
                </tr>
                <tr>
                  <th>
                    發財金積點(限
                    <HelpStyle.BlueText>VIP會員</HelpStyle.BlueText>
                    )
                  </th>
                  <td>
                    <HelpStyle.BlueText>VIP會員</HelpStyle.BlueText>
                    (
                    <HelpStyle.RedText>買家</HelpStyle.RedText>
                    )單次消費金額每滿100點即可自動累積1點
                    發財金
                    ，未滿交易保證金100點部分則不予計算，亦無法累計至下次消費(含以後)合併計算。
                  </td>
                </tr>
                <tr>
                  <th>開立發票對象(<HelpStyle.BlueText>索取者</HelpStyle.BlueText>)</th>
                  <td><HelpStyle.RedText>買家</HelpStyle.RedText></td>
                </tr>
              </tbody>
            </HelpStyle.Table>

          </HelpStyle.Section>
        </li>
        <li>
          <HelpStyle.Section>
            2. 只要<HelpStyle.BlueText>VIP會員</HelpStyle.BlueText>在9199網站中每購買「遊戲點數卡、產品包」商品，金額滿100元即可獲得1點發財金。
          </HelpStyle.Section>
        </li>
      </ul>
    </HelpStyle.Container>
  );
};

const BonusCalcIntroView = () => {
  return (
    <HelpStyle.Container>
      <HelpStyle.Title>【發財金】之計算</HelpStyle.Title> 
      <HelpStyle.Section>
        <HelpStyle.RedText>單次消費金額每滿100點即可自動累積1點發財金，未滿交易保證金100點部份則不予計算，亦無法累計至下次消費(含以後)合併計算。</HelpStyle.RedText>
      </HelpStyle.Section>
    </HelpStyle.Container>
  );
};

const BonusEligibilityView = () => {
  return (
    <HelpStyle.Container>
      <HelpStyle.Title>【發財金】紅利酬賓計劃，參加資格</HelpStyle.Title> 
      <ul>
        <li>
          <HelpStyle.Section>
              1. 【發財金】限9199之VIP會員才享有領取與使用資格，
              {/* 請參考『
              <CupoySrefView statename={CupoyRouter.STATES.HELP_UPGRADE_MEMBERSHIP}>
                如何升級VIP會員
              </CupoySrefView>
              』，<br/> */}
              只要會員於9199消費「遊戲點數卡或產品包」累計3,000元金額，隔日將自動升級VIP會員。
              <HelpStyle.Table>
                <tbody>
                  <tr>
                    <th rowSpan="4">新台幣</th>
                    <th rowSpan="4">交易保證金</th>
                    <th>VIP會員</th>
                    <th>一般會員</th>
                  </tr>
                  <tr>
                    <td>
                      <ImageWrapper>
                        <CupoyImage
                          stylecomponent={MemberAvatar}
                          src={`${GLOBAL.URL_PREFIX}/images/vip_member_male_avatar.svg`}
                        />
                        <CupoyImage
                          stylecomponent={MemberAvatar}
                          src={`${GLOBAL.URL_PREFIX}/images/vip_member_female_avatar.svg`}
                        />
                      </ImageWrapper>
                    </td>
                    <td>
                      <ImageWrapper>
                        <CupoyImage
                          stylecomponent={MemberAvatar}
                          src={`${GLOBAL.URL_PREFIX}/images/general_member_male_avatar.svg`}
                        />
                        <CupoyImage
                          stylecomponent={MemberAvatar}
                          src={`${GLOBAL.URL_PREFIX}/images/general_member_female_avatar.svg`}
                        />
                      </ImageWrapper>
                    </td>
                  </tr>
                  <tr>
                    <td>購卡滿3000元</td>
                    <td>未購卡滿3000元</td>
                  </tr>
                  <tr>
                    <td colSpan="2">發財金</td>
                  </tr>
                  <tr>
                    <td>100元</td>
                    <td>100點</td>
                    <td>1點</td>
                    <td>0點</td>
                  </tr>
                </tbody>
            </HelpStyle.Table>
          </HelpStyle.Section>
        </li>
        <li>
          <HelpStyle.Section>
            2. VIP會員可登入會員後，至『
            <CupoySrefView statename={CupoyRouter.STATES.MEMBER_BONUS_LOGS}>
              會員中心 &gt; 我的發財金 &gt; 發財金明細
            </CupoySrefView>
            』內查看相關點數累積與使用記錄。
          </HelpStyle.Section>
        </li>
        <li>
          <HelpStyle.Section>
            3. 為維護VIP會員之權益，避免他人冒用VIP會員名義進行兌換， <br/>
            所有實體商品或憑證之寄送地址一律以VIP會員之註冊地址為寄送地為準， <br/>
            且不接受非VIP會員本人為收件人，實體商品配送區域僅限於台灣地區（含外島）。
          </HelpStyle.Section>
        </li>
        <li>
          <HelpStyle.Section>
            4. VIP會員於兌換『
            <CupoySrefView statename={CupoyRouter.STATES.CARD_BONUSPRODUCTS}>發財商城</CupoySrefView>
            』專區商品或服務，即視為同意9199及提供商品之廠商其所提供之個人資料於兌換作業範圍內，予以電腦處理。
          </HelpStyle.Section>
        </li>
      </ul>
    </HelpStyle.Container>
  );
};

const UseBonus = () => {
  return (
    <HelpStyle.Container>
      <HelpStyle.Title>【發財金】使用</HelpStyle.Title> 
      <ul>
        <li>
          <HelpStyle.Section>
          1. VIP會員可至『
          <CupoySrefView statename={CupoyRouter.STATES.CARD_BONUSPRODUCTS}>發財商城</CupoySrefView>
          』專區，使用發財金兌換限量回饋商品，<br/>
          包含：遊戲點數卡、虛寶包、票券…等實體與虛擬商品，並可參加與使用9199限定之發財金相關活動，<br/>
          若以發財金全數兌換實體商品與票券者，一經兌換除因商品具有瑕疵外，一律不受理退換服務。
          </HelpStyle.Section>
        </li>
        <li>
          <HelpStyle.Section>
            2. 每位VIP會員
            <HelpStyle.RedText>
              於『發財商城』專區每天只限兌換三次商品(凌晨零時起算)，<br/>
              無法於一天內兌換商品四次以上，需於隔日才可再兌換一次。
            </HelpStyle.RedText>
          </HelpStyle.Section>
        </li>
        <li>
          <HelpStyle.Section>
            3. 【發財金】紅利積點，僅可供該名會員依於【發財金】使用規範使用，<br/>
            不得要求9199轉換現金或其他非回饋項目之給付，也不能轉移或合併於其他會員或任何第三人，<br/>
            9199除法律上不得排除之責任外（
            <CupoySrefView statename={CupoyRouter.STATES.DISCLAIMER}>免責聲明</CupoySrefView>
            ），無須負擔兌換『
            <CupoySrefView statename={CupoyRouter.STATES.CARD_BONUSPRODUCTS}>發財商城</CupoySrefView>
            』專區商品或活動發生之任何損失或個人傷害之責任，<br/>
            且9199保有修改發財金使用方式與相關活動辦法之權利。
          </HelpStyle.Section>
        </li>
        <li>
          <HelpStyle.Section>
            4. 9199得保留修正、暫停與終止本『發財金』點數、『
            <CupoySrefView statename={CupoyRouter.STATES.CARD_BONUSPRODUCTS}>發財商城</CupoySrefView>
            』兌換專區之使用與活動之權利，並刊登於本站相關公告、電子報、系統簡訊。
          </HelpStyle.Section>
        </li>
        <li>
          <HelpStyle.Section>
            5. 於『
            <CupoySrefView statename={CupoyRouter.STATES.CARD_BONUSPRODUCTS}>發財商城</CupoySrefView>
            』專區兌換之商品「一經兌換完成恕無法取消或進行退換貨」，<br/>
            兌換前請VIP會員確認無誤，如果您兌換之商品有瑕疵或無法使用時，<br/>
            請務必連絡9199，將代為向廠商提出換貨服務或必要處理。
          </HelpStyle.Section>
        </li>
      </ul>
    </HelpStyle.Container>
  );
};
/**
|--------------------------------------------------
| Main Components
|--------------------------------------------------
*/
const BonusManualView = () => {
  return (
    <GlobalStyle.Container
      hasFixedTopbar
      hasFixedTabbar={false}
      backgroundColor='#f5f5f5'
    >
      <TopbarView
        isFixed
        leftComponent={
          <CupoySrefView
            statename={CupoyRouter.STATES.HELP}
            stylecomponent={GlobalStyle.TopbarButton}
          >
            <GlobalStyle.TopbarIcon>
              <i className="zmdi zmdi-chevron-left"></i>
            </GlobalStyle.TopbarIcon>
            返回
          </CupoySrefView>
        }
        middleComponent="發財金說明"
      />

      <WhatIsBonus />

      <BonusCalcIntroView />

      <BonusEligibilityView/>

      <UseBonus />

    </GlobalStyle.Container>
  );
};

/**
|--------------------------------------------------
| Export
|--------------------------------------------------
*/
export default BonusManualView;
