/*=====================================
    虛寶市集

    Author: Gray
    createtime: 2018 / 02 / 23
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle } from 'stylecomponents';
import styled from 'styled-components';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import NumberFormat from 'react-number-format';

/*--------------------------
    Styled Component
--------------------------*/

const TransactionList = styled.div`

    display: flex;
    justify-content: center;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #EEEEEE, inset 0 1px 0 0 #EEEEEE;
`;

const TransactionInfo = styled.div`

    width: 100%;

`;

const TransactionName = styled.div`

    padding-bottom: 8px;
    font-size: 16px;
    color: #4A4A4A;

`;

const ExchangeRate = styled.div`

    display: inline-block;
    font-size: 13px;
    color: #565151;

`;

// const RateNumber = styled.span`

//     margin-left: 8px;
//     color: #F75C2F;
//     font-weight: bolder;
//     font-size: 16px;

// `;

const BuyQuantityList = styled.div`

    display: flex;
    justify-content: space-between;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #EEEEEE;

`;

const BuyTitle = styled.div`

    font-size: 14px;
    color: #333333;

`;

const BuyConversion = styled.div`

    font-size: 12px;
    color: #969494;

`;

const FromWrapper = styled.div`

    padding: 16px;
    background-color: #fff;
    font-size: 12px;
    color: #232423;
    
`;

const CharacterInput = styled.input.attrs({

})`
    &::placeholder{
        opacity: 0.5;
        font-size: 15px;
        color: #222328;
    }
    margin-top: 8px;
    margin-bottom: 16px;
    padding: 12px;
    width: 100%;
    height: 44px;
    background: #FAFAFA;
    border: 1px solid #DDDDDD;
    border-radius: 2px;

`;

const NarrativeTextarea = styled.textarea.attrs({

    placeholder: '請注意！伺服器內角色所填寫資料,如與實際交易角色ID不同,供應商將取消該筆訂單'
})`
    &::placeholder{
        opacity: 0.5;
        font-size: 15px;
        color: #222328;
    }
    margin-top: 8px;
    margin-bottom: 16px;
    padding: 12px;
    width: 100%;
    height: 86px;
    background: #FAFAFA;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    
`;

const ToCheckout = styled.div`

    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #F75C2F;
    border-radius: 2px;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;

`;

const TopbarMiddle = styled.h3`

    height: 100%;
    line-height: 44px;
    margin: 0;
    font-size: 16px;
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
    color: #FFFFFF;
    text-align: center;

`;
const Amount = styled.input`

    min-width: 96px;
    height: 36px;
    border-radius: 3px;
    background-color: #ffffff;
    font-size: 14px;
    color: #333333;
    text-align: center;
    border: 1px solid #CCCCCC;
`;


/*--------------------------
    Main Component
--------------------------*/
class PurchaseInfoView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);
        
        this.state = {
            amount: props.purchaseFormData ? props.purchaseFormData.amount : 0,
            viprovname: "",
            txtcontent: "",
        };
        
        this.sendVItemCartOrder = this.sendVItemCartOrder.bind(this);
        this.inputDollar = this.inputDollar.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        
        // if(!this.props.purchaseFormData) {
        //     this.props.webActions.cupoyStateGo(CupoyRouter.STATES.HOME);
        // }
        // this.props.vitemActions.initOrder({gameid, gameserverid, viprovid});
        this.props.vitemActions.payVItemCart({
            paytype: 1,
        });
    }

    
    inputDollar(event){
        this.setState({
            amount: Number(event.target.value),
        });
    }
    sendVItemCartOrder(payload){
        // const cartOrderData = Object.assign({},this.state,payload);
        // this.props.vitemActions.buildVItemCartOrder();
        // console.log(cartOrderData);
        
    }
    // ------------------------------
    // render
    // ------------------------------
    render() {
        const purchaseFormData = this.props.purchaseFormData;
        
        if(!purchaseFormData){
            return null
        }
        
        const {
            inventory,
            gamename,
            nickname,
            ntdollar,
            vitemdollar,
            gameid,
            gameserverid,
            viprovid,
        } = purchaseFormData;
        const maxDollar = Math.floor(inventory / vitemdollar);
        const totalPrice = this.state.amount * vitemdollar;
        
        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.SELLDETAIL_PRODUCT}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            商品詳情
                        </CupoySrefView>
                    }
                    middleComponent={
                        <TopbarMiddle>
                            {gamename}
                        </TopbarMiddle>
                    }
                />
                <TransactionList>
                    <TransactionInfo>
                        <TransactionName>遊戲暱稱：{nickname}</TransactionName>
                        <div>
                            <ExchangeRate>
                                比例(台幣：虛幣)
                                <NumberFormat value={ntdollar} displayType={'text'} thousandSeparator={true} />
                                    :
                                    <NumberFormat value={vitemdollar} displayType={'text'} thousandSeparator={true} />
                                
                            </ExchangeRate>
                        </div>
                    </TransactionInfo>
                </TransactionList>
                <BuyQuantityList>
                    <div>
                        <BuyTitle>購買金額（台幣）</BuyTitle>
                        <BuyConversion>
                            換算為虛幣金額
                            <NumberFormat value={totalPrice} displayType={'text'} thousandSeparator={true} />
                        </BuyConversion>
                    </div>
                    <Amount 
                        type="number"
                        min={100}
                        max={maxDollar} 
                        value={this.state.amount}
                        step={ntdollar} 
                        onChange={(event) => this.inputDollar(event)}
                    />
                </BuyQuantityList>
                <FromWrapper>
                    伺服器內角色
                    <CharacterInput
                        onChange={(event)=> this.updateViprovName(event)}
                    />
                    交易過程詳述
                    <NarrativeTextarea
                        onChange={(event)=> this.updateTxtContent(event)}
                    />
                    <ToCheckout onClick={()=> this.sendVItemCartOrder({
                        vitemamount: totalPrice,
                        gameid,
                        gamename,
                        gameserverid,
                        viprovid,
                    })}>
                        前往結帳
                    </ToCheckout>
                </FromWrapper>
            </GlobalStyle.Container>
        );
    }
}




/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        purchaseFormData: state.vitem.purchaseFormData,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        vitemActions: bindActionCreators(actionCreators.vitemActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(PurchaseInfoView);