import React, { Component } from 'react';
import { Provider } from 'react-redux'
import RootView from './components/RootView';
import actionStore from 'actions/store/configureStore';
import 'intersection-observer';

class App extends Component {
  componentDidMount() {

    // 關閉瀏覽器自行管理scroll位置歷史紀錄的功能
    if (
      typeof window !== 'undefined' &&
      'scrollRestoration' in window.history
    ) {
      window.history.scrollRestoration = 'manual';
    }
    
  }

  render() {
    return (
      <Provider store={actionStore}>
        <RootView />
      </Provider>
    );
  }
}

export default App;
