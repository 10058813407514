/*=====================================
    MemberAddComplaint

    Author: Gray
    CreateTime: 2018 / 03 / 13
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import styled from 'styled-components';
import Recaptcha from "react-grecaptcha";
import MediaQuery from 'react-responsive';
import GLOBAL from '../../../nonnmwebglobal';
import { GlobalStyle, Colors, Button } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
    padding: 16px;
`;
const Label = styled.div`
    display: flex;
    margin: 0 0 8px 0;
    font-size: 12px;
    color: ${Colors.Dark2};
    letter-spacing: 0;
    align-items: center;
`;
const Input = styled.input`
    width: 100%;
    height: 44px;
    margin: 0 0 16px 0;
    padding: 0 12px;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    background-color: #FAFAFA;
    font-size: 15px;
    color: #222328;

    ${(props) => {

        if(!props.error) {
            return ``;
        }

        return `
            border: 1px solid ${Colors.Red};
            color: ${Colors.Red};
        `;
    }}
`;
const Textarea = styled.textarea`
    width: 100%;
    height: 160px;
    margin: 0 0 16px 0;
    padding: 12px;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    background-color: #FAFAFA;
    font-size: 15px;
    color: #222328;
    overflow: auto;

    ${(props) => {

        if(!props.error) {
            return ``;
        }

        return `
            border: 1px solid ${Colors.Red};
            color: ${Colors.Red};
        `;
    }}
`;
const SelectWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 44px;
    margin: 0 0 16px 0;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    background-color: #FAFAFA;
`;
const Select = styled.select`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 8px;
    border: none;
    background-color: #FAFAFA;
    font-size: 15px;
    color: #222328;
`;
const GoogleCertified = styled.div`
    margin: 0 0 32px 0;
`;
const SubmitButton = styled(Button.Orange)`
    width: 100%;
    height: 44px;
    line-height: 44px;
    margin: 0;
    font-size: 16px;
`;
const SubmitLoading = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    left: 16px;
    height: 100%;
    align-items: center;
    justify-content: center; 
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberAddComplaintView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            mainCsclassid: '',
            csclassid: '',
            description: '',
            name: '',
            phone: '',
            recaptchaResponse: '',
            isLoading: false,
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.callback = this.callback.bind(this);
        this.expiredCallback = this.expiredCallback.bind(this);
        this.submit = this.submit.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props, true);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {
        if(prevProps.haslogin !== this.props.haslogin) {
            this.init(this.props);
        }
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props, isInit) {

        const {
            csClassMap,
        } = this.props;

        if(isInit && !csClassMap[0]) {
            this.props.memberActions.listCustomerCaseClasses();
        }
    }

    // ------------------------------
    // Input value change
    // ------------------------------
    onInputChange(event, key) {

        this.setState({
            [key]: event.target.value,
        })
    }

    // ------------------------------
    // onMainSelectChange
    // ------------------------------
    onMainSelectChange(event) {

        const mainCsclassid = event.target.value;
        var csclassid = '';

        const {
            csClassMap,
        } = this.props;

        const currentCategory = csClassMap[mainCsclassid];
        var index = -1;

        if(currentCategory && currentCategory.subids) {
            index = currentCategory.subids.indexOf(this.state.csclassid);
        }

        if(index > 0) {
            csclassid = this.state.csclassid;
        }

        this.setState({
            mainCsclassid: mainCsclassid,
            csclassid: csclassid,
        })
    }

    // ------------------------------
    // google callback
    // ------------------------------
    callback(response) {

        this.setState({
            recaptchaResponse: response,
        });
    }

    // ------------------------------
    // google expiredCallback
    // ------------------------------
    expiredCallback() {

        this.props.webActions.openAlertDialog({
            title: '安全認證已過期',
        });
    }

    // ------------------------------
    // submit
    // ------------------------------
    submit() {

        const {
            mainCsclassid = '',
            csclassid = '',
            description = '',
            name = '',
            phone = '',
            recaptchaResponse = null,
            isLoading = false,
        } = this.state;
                                
        // 先檢查除了問題選項以外的資料是否正確 & loading & 機器人驗證
        if (!description.trim() || !name.trim() || !phone.trim() ||
            isLoading || (!GLOBAL.isAppFile && !recaptchaResponse)
        ){
            return;
        }

        // mainCsclassid: 1 為問題總類[ 其他 ] 就不會有子分類
        if (!mainCsclassid.trim() || ((mainCsclassid.trim() !== '1') && !csclassid.trim())){
            return;
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.memberActions.createCustomerCase({
                passcode: recaptchaResponse,
                csclassid: csclassid,
                contactperson: name,
                contactphone: phone,
                comment: description,
            }).then((result) => {

                this.setState({
                    mainCsclassid: '',
                    csclassid: '',
                    description: '',
                    name: '',
                    phone: '',
                    recaptchaResponse: '',
                    isLoading: false,
                });

                this.props.webActions.openAlertDialog({
                    title: '您的問題已送出',
                    description: '我們將會儘速處理您的問題。',
                    closeName: '關閉',
                    closeCallback: ()=> {this.props.webActions.cupoyStateGo(CupoyRouter.STATES.MEMBER_CONTACT_SERVICE)},
                    
                });

            }, (error) => {

                var errorMsg = '發生無法預期的錯誤';
                var errorDescription = '';

                if(error && error.code) {
                    errorMsg = error.message;
                }

                this.props.webActions.openAlertDialog({
                    title: errorMsg,
                    description: errorDescription,
                });

                this.setState({
                    isLoading: false,
                });
            });
        })
    }    

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return <NeedLoginBlock />;
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {
        const {
            mainCsclassid,
            csclassid,
            description,
            name,
            phone,
            recaptchaResponse,
            isLoading,
        } = this.state;

        const {
            csClassMap,
        } = this.props;

        if(!csClassMap[0]) {
            return null;
        }

        let disableSubmit = false;

        // 先檢查除了問題選項以外的資料是否正確 & loading & 機器人驗證
        if (!description.trim() || !name.trim() || !phone.trim() ||
            isLoading || (!GLOBAL.isAppFile && !recaptchaResponse)
        ){
            disableSubmit = true;
        }

        // mainCsclassid: 1 為問題總類[ 其他 ] 就不會有子分類
        if (!mainCsclassid.trim() || ((mainCsclassid.trim() !== '1') && !csclassid.trim())){
            disableSubmit = true;
        }

        const mainCategory = csClassMap[0];
        const mainOptions = mainCategory.subids;
        const currentCategory = csClassMap[mainCsclassid];

        var subOptions = [];
        if(currentCategory && currentCategory.subids) {
            subOptions = currentCategory.subids;
        }


        return (
            <Content>
                <Label>問題總類</Label>
                <SelectWrapper>
                    <Select
                        value={mainCsclassid}
                        onChange={(event) => this.onMainSelectChange(event)}
                    >
                        <option value={''}>請選擇</option>
                        {mainOptions.map((id, index) => {
                            const category = csClassMap[id];

                            if(!category) {
                                return null;
                            }
                            
                            return (<option key={id} value={id}>{category.csclassname}</option>);
                        })}
                    </Select>
                </SelectWrapper>
                {
                    subOptions.length > 0
                        ?
                        <>
                            <Label>問題項目</Label>
                            <SelectWrapper>
                                <Select
                                    value={csclassid}
                                    onChange={(event) => this.onInputChange(event, 'csclassid')}
                                >
                                    {subOptions.length > 0 ? 
                                        <option value={''}>請選擇</option>
                                        :
                                        <option value={''}>---</option>
                                    }

                                    {subOptions.map((id, index) => {
                                        const category = csClassMap[id];

                                        if(!category) {
                                            return null;
                                        }

                                        return (<option key={id} value={id}>{category.csclassname}</option>);
                                    })}
                                </Select>
                            </SelectWrapper>
                        </>
                        :
                        undefined
                }
                <Label>問題描述</Label>
                <Textarea
                    type="text"
                    placeholder="請描述您的問題"
                    value={description}
                    onChange={(event) => this.onInputChange(event, 'description')}
                    error={false}
                />
                <Label>聯絡人</Label>
                <Input
                    type="text"
                    placeholder="請填寫聯絡人名稱"
                    value={name}
                    onChange={(event) => this.onInputChange(event, 'name')}
                    error={false}
                />
                <Label>聯絡電話</Label>
                <Input
                    type="tel"
                    placeholder="請填寫聯絡電話"
                    value={phone}
                    onChange={(event) => this.onInputChange(event, 'phone')}
                    error={false}
                />
                {GLOBAL.isAppFile ?
                    null
                    :
                    <div>
                        <Label>安全性驗證</Label>
                        <GoogleCertified>
                            <MediaQuery minWidth={0} maxWidth={360}>
                                <Recaptcha
                                    sitekey={GLOBAL.grecaptcha_key}
                                    callback={this.callback}
                                    expiredCallback={this.expiredCallback}
                                    locale="zh-TW"
                                    className="g-recaptcha"
                                    style={{
                                        transform: 'scale(0.9)',
                                        WebkitTransform: 'scale(0.9)',
                                        transformOrigin: '0 0',
                                        WebkitTransformOrigin: '0 0',
                                    }}
                                />

                            </MediaQuery>
                            <MediaQuery minWidth={361}>
                                <Recaptcha
                                    sitekey={GLOBAL.grecaptcha_key}
                                    callback={this.callback}
                                    expiredCallback={this.expiredCallback}
                                    locale="zh-TW"
                                    className="g-recaptcha"
                                />
                            </MediaQuery>
                        </GoogleCertified>
                    </div>
                }
                
                <SubmitButton
                    onClick={() => this.submit()}
                    disable={disableSubmit}
                >
                    {isLoading ?
                        <SubmitLoading>
                            <GlobalStyle.Spin color="#fff"/>
                        </SubmitLoading>
                        :
                        null
                    }
                    送出
                </SubmitButton>
            </Content>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER_CONTACT_SERVICE}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            會員客訴
                        </CupoySrefView>
                    }
                    middleComponent="填寫客訴單"
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        csClassMap: state.member.csClassMap,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberAddComplaintView);