/*=====================================
    會員資訊頁

    Author: Eileen Liao
    createtime: 2018 / 03 / 16
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import { GlobalStyle, MemberStyle, Button } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import styled from 'styled-components';
import GLOBAL from 'nonnmwebglobal';
import CupoyClipboard from 'components/commons/CupoyClipboard';


/*--------------------------
    Style Component
--------------------------*/

const RowItemContainer =styled.div`
    display : flex;
    width : 100%;
    border-bottom : 1px inset #EEEEEE;
    justify-content:space-between;
    background-color:#FFF;
`;
const RowItemLeftContainer = styled.div`
    display : flex;
    margin : 16px;
    flex-direction : column;
    justify-content: center;
    flex: 1;
`;
const RowItemRightContainer =styled.div`
    display : flex;
    margin : 16px 16px 16px 0;
    align-items:center;
    font-size: 12px;
    color: #9B9B9B;
`;

const FieldTitle = styled.div`
    margin : 0 0 0 0;
    font-size: 16px;
    color : #333333;
    line-height : 1.7em;
`
const FieldContent = styled.div`
    padding : 7px 0 0 0;
    font-size: 13px;
    color : #565151;
    line-height : 1.7em;
`
const CopyBtn = styled(Button.Orange)`
    width: 100%;
    height: 28px;
    line-height: 26px;
    user-select: none;
    margin: 10px 0 0;
`;

/*--------------------------
    Main Component
--------------------------*/


class MemberInfoView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);
        this.state = {
            sex : props.memberinfo.sex,
            address : props.memberinfo.address,
            mobilephone : props.memberinfo.mobilephone,
            email : props.memberinfo.email,
            shownametype : props.memberinfo.shownametype,
        };
        // this.change_sex = this.change_sex.bind(this);
        this.change_showname = this.change_showname.bind(this);

    }

    // change_sex(event){
    //     this.props.webActions.openConfirmDialog({
    //         title: '性別',
    //         //description: '是否捐贈發票？',
    //         confirmCallback: () => {
    //             this.updateMember('sex',1);
    //             this.setState({sex : 1});
    //         },
    //         cancelCallback: () => {
    //             this.updateMember('sex',0);
    //             this.setState({sex : 0});
    //         },
    //         confirmName: '男',
    //         cancelName: '女',
    //     });
    
    // }
    change_showname(event){
        this.props.webActions.openConfirmDialog({
            title: '姓名顯示',
            confirmCallback: () => {
                
                this.updateMember('showname',1);
                this.setState({shownametype : 1});
            },
            cancelCallback: () => {
                //this.updeteMemberIsDonate(false);
                this.updateMember('showname',2);
                this.setState({shownametype : 2});
            },
            confirmName: '真實姓名',
            cancelName: '顯示暱稱',
        });
        
    }
    updateMember(field,value){

        var {
            membername,
            nickname,
            birthday,
            isdonate,
            npoban,
            carrierid,
            carriertype,
            invoicetype,
            crn,
            companytitle,
            shownametype,
            sex,
        } = this.props.memberinfo

        if(field === 'sex'){
            sex = value;
        }
        else if(field === 'showname'){
            shownametype = value;
        }

        const {
            address,
        } = this.state;

        var city = '',
            region = '',
            postalcode = '',
            shortAddress = '';
        if(address && address.trim()) {
            const addressList = address.trim().split(" ");

            city = addressList[1] || '';
            region = addressList[2] || '';
            postalcode = addressList[0] || '';
            shortAddress = addressList[3] || '';
        }

        this.props.memberActions.updMember({
            city: city,
            region: region,
            postalcode: postalcode,
            addreGss: shortAddress,
            name: membername,
            nickname: nickname,
            birthday: birthday,
            isdonate: isdonate,
            npoban: npoban,
            carrierid: carrierid,
            carriertype: carriertype,
            invoicetype: invoicetype,
            crn: crn,
            companytitle: companytitle,
            shownametype: shownametype,
            sex: sex,
        }).then((result) => {
            this.setState({sex : sex, shownametype : shownametype});
            return;
        },(error) =>{
            if(error && error.code) {
                this.props.webActions.openAlertDialog({                    
                    title: '錯誤訊息',
                    description : '系統錯誤，請稍候再試。',
                    closeName: '確定',
                    closeCallback: () => {
                        this.props.webActions.cupoyStateGo(CupoyRouter.STATES.MEMBERLOGIN);
                    }
                });
            }
        })
        .catch(err => { 
            console.log(err) ;
            this.setState.isError = true;
        });        
    }

    init(props){
        const {
            sex,
            address,
            mobilephone,
            email,
        } = this.props.memberinfo;
        this.setState({
            sex : sex,
            address : address,
            mobilephone :mobilephone ,
            email :email,
        });
    }
    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

    }
    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        this.init(this.props);
    }



    // ------------------------------
    // render
    // ------------------------------
    render() {
        
        const {
            membername,
            birthday,
            idnumber,
            nickname,
            isemailauth,
            issmsauth,
            memberid,
            mrscid,
            isdonate,
            npoban,
            carriertype,
            carrierid,
        } = this.props.memberinfo;

        const {
            email,
            sex,
            mobilephone,
            address,
            shownametype,
        } = this.state;

        const birthdaydesc = GLOBAL.formatDate(birthday);
        
        return (
            <GlobalStyle.Container  hasFixedTopbar={true} hasFixedTabbar={false}>
            
            <TopbarView
                isFixed={true}
                leftComponent={
                    <CupoySrefView 
                        statename={CupoyRouter.STATES.MEMBER}
                        stylecomponent={GlobalStyle.TopbarButton}
                    >   
                        <GlobalStyle.TopbarIcon>
                            <i className="zmdi zmdi-chevron-left"></i>
                        </GlobalStyle.TopbarIcon>
                        返回
                    </CupoySrefView>
                }
                middleComponent="個人資料"
            />
            <RowItemContainer >
                <RowItemLeftContainer>
                    <FieldTitle>真實姓名</FieldTitle>
                    <FieldContent>{membername}</FieldContent>
                </RowItemLeftContainer>
            </RowItemContainer>
            
            <CupoySrefView 
                    statename={CupoyRouter.STATES.RESETNICKNAME}
            >

            <RowItemContainer >
                <RowItemLeftContainer>
                    <FieldTitle>暱稱</FieldTitle>
                    <FieldContent>{nickname}</FieldContent>
                </RowItemLeftContainer>
                <RowItemRightContainer name="right">
                    <i className="icon-chevron-right" />
                </RowItemRightContainer >
            </RowItemContainer>
            </CupoySrefView>
            <RowItemContainer >
                <RowItemLeftContainer>
                    <FieldTitle>會員帳號</FieldTitle>
                    <FieldContent>{memberid}</FieldContent>
                </RowItemLeftContainer>
            </RowItemContainer>
            <RowItemContainer >
                <RowItemLeftContainer>
                    <FieldTitle>會員編號</FieldTitle>
                    <FieldContent>No.{mrscid}</FieldContent>
                    <CupoyClipboard
                        text={mrscid}
                        copiedText={`已複製會員編號:\n${mrscid}`}
                    >
                        <CopyBtn>複製會員編號</CopyBtn>
                    </CupoyClipboard>
                </RowItemLeftContainer>
            </RowItemContainer>                        
            
            <RowItemContainer onClick={this.change_showname} >
                <RowItemLeftContainer>
                    <FieldTitle>姓名顯示</FieldTitle>
                    <FieldContent>{shownametype === 1 ? "真實姓名" : "暱稱"}</FieldContent>
                </RowItemLeftContainer>
                <RowItemRightContainer name="right">
                    <i className="icon-chevron-right" />
                </RowItemRightContainer >
            </RowItemContainer>

            <RowItemContainer>
                <RowItemLeftContainer>
                    <FieldTitle>性別</FieldTitle>
                    <FieldContent>{sex === 1 ? "男" : "女"}</FieldContent>
                </RowItemLeftContainer>
            </RowItemContainer>

            {idnumber ?
                <RowItemContainer>
                    <RowItemLeftContainer>
                        <FieldTitle>身分證字號</FieldTitle>
                        <FieldContent>{idnumber}</FieldContent>
                    </RowItemLeftContainer>
                </RowItemContainer>
                :
                <CupoySrefView 
                    statename={CupoyRouter.STATES.RESET_ID_NUMBER}
                >
                    <RowItemContainer>
                        <RowItemLeftContainer>
                            <FieldTitle>身分證字號</FieldTitle>
                            <FieldContent />
                        </RowItemLeftContainer>
                        <RowItemRightContainer name="right">
                            <MemberStyle.FuntionTagStatusContent>
                                未驗證
                            </MemberStyle.FuntionTagStatusContent>
                            <i className="icon-chevron-right" />
                        </RowItemRightContainer>
                    </RowItemContainer>
                </CupoySrefView>
            }

            <CupoySrefView 
                statename={CupoyRouter.STATES.RESETMOBILE}
            >
                <RowItemContainer >
                    <RowItemLeftContainer>
                        <FieldTitle>行動電話</FieldTitle>
                        <FieldContent>{mobilephone}</FieldContent>
                    </RowItemLeftContainer>
                    <RowItemRightContainer name="right">
                        {!issmsauth ?
                            <MemberStyle.FuntionTagStatusContent>
                                未驗證
                            </MemberStyle.FuntionTagStatusContent>
                            :
                            null
                        }                    
                        <i className="icon-chevron-right" />
                    </RowItemRightContainer>
                </RowItemContainer>
            </CupoySrefView>
            <CupoySrefView 
                statename={CupoyRouter.STATES.RESETMAIL}
            >
                <RowItemContainer >
                    <RowItemLeftContainer>
                        <FieldTitle>電子信箱</FieldTitle>
                        <FieldContent>{email}</FieldContent>
                    </RowItemLeftContainer>
                    <RowItemRightContainer name="right">
                        {!isemailauth ?
                            <MemberStyle.FuntionTagStatusContent>
                                未驗證
                            </MemberStyle.FuntionTagStatusContent>
                            :
                            null
                        }                    

                        <i className="icon-chevron-right" />
                    </RowItemRightContainer >
                </RowItemContainer>
            </CupoySrefView>

            <CupoySrefView statename={CupoyRouter.STATES.RESETADDRESS}>
                <RowItemContainer>
                    <RowItemLeftContainer>
                        <FieldTitle>住址</FieldTitle>
                        <FieldContent>{address}</FieldContent>
                    </RowItemLeftContainer>
                    <RowItemRightContainer name="right">
                        <i className="icon-chevron-right" />
                    </RowItemRightContainer >
                </RowItemContainer>
            </CupoySrefView>

            <CupoySrefView statename={CupoyRouter.STATES.RESET_BIRTHDAY}>
                <RowItemContainer>
                    <RowItemLeftContainer>
                        <FieldTitle>出生日期</FieldTitle>
                        <FieldContent>{birthdaydesc}</FieldContent>
                    </RowItemLeftContainer>
                </RowItemContainer>
            </CupoySrefView>
            
            <RowItemContainer>
                <RowItemLeftContainer>
                    <FieldTitle>發票資訊</FieldTitle>
                    <FieldContent>
                        {isdonate
                            ? `捐贈碼：${npoban}`
                            : carriertype === GLOBAL.CARRIER_TYPE.MEMBER
                                ? `會員載具：${mrscid}`
                                : `載具ID：${carrierid}`}
                    </FieldContent>
                </RowItemLeftContainer>
            </RowItemContainer>

        </GlobalStyle.Container>
            
        );
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        memberinfo : state.member.memberinfo,
        haslogin : state.member.haslogin,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberInfoView);