/*=====================================
    重設身分證

    Author: Ac
    createtime: 2018 / 12 / 13
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, {
    useEffect,
    useCallback,
} from 'react';
import { createSelector } from 'reselect';
import useRedux from 'actions/hooks/useRedux';
import actionCreators from 'actions/creators';
import { GlobalStyle,MemberStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import styled from 'styled-components';
import MemberIdNumberVerifier from 'components/member/commons/MemberIdNumberVerifier';

/**
 * Styled
 */
const Warning = styled.div`
    text-align: center;
`;

/**
 * Selector
 */
const selector = createSelector(
    state => state.member.haslogin,
    state => state.member.memberinfo,
    (haslogin, memberinfo) => ({
        haslogin,
        memberinfo,
    })
);

/*--------------------------
    Main Component
--------------------------*/
const ResetIdNumberView = props => {

    const [{
        haslogin,
        memberinfo,
    }, {
        webActions,
        memberActions,
    }] = useRedux(selector, {
        webActions: actionCreators.webActionCreators,
        memberActions : actionCreators.memberActionCreators,
    });

    const handleIdNumber = useCallback(
        idNumber => {
            memberActions.updIDNumber(idNumber).then(() => {
                memberActions.getMemberAcntInfo();
                webActions.cupoyStateGo(CupoyRouter.STATES.MEMBER_INFO);
            }).catch(error => {
                webActions.openAlertDialog({
                    title: '身分證更新失敗',
                    description: error && error.message
                        ? error.message
                        : '發生無法預期的錯誤，請聯絡客服或稍後再試',
                    closeName: '關閉',
                });
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        if (!haslogin) {
            webActions.openAlertDialog({
                title: '錯誤訊息',
                description : '請登入會員',
                closeName: '關閉',
                closeCallback: () => webActions.cupoyStateGo(CupoyRouter.STATES.MEMBERLOGIN),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [haslogin]);

    return (
        <GlobalStyle.Container hasFixedTopbar hasFixedTabbar={false}>
            <TopbarView
                isFixed
                leftComponent={
                    <CupoySrefView 
                        statename={CupoyRouter.STATES.MEMBER_INFO}
                        stylecomponent={GlobalStyle.TopbarButton}
                    >   
                        <GlobalStyle.TopbarIcon>
                            <i className="zmdi zmdi-chevron-left"></i>
                        </GlobalStyle.TopbarIcon>
                        個人資料
                    </CupoySrefView>
                }
                middleComponent='重新驗證身分證'
            />
            <MemberStyle.MemberContent>
                {memberinfo.idnumber
                    ? <Warning>沒有權限進行身分證修改，請聯絡客服</Warning>
                    : <MemberIdNumberVerifier onChange={handleIdNumber} />
                }
            </MemberStyle.MemberContent>
        </GlobalStyle.Container>
    );
};

/*--------------------------
    export
--------------------------*/
export default ResetIdNumberView;