/*=====================================
    MemberVitemOrderBuy

    Author: Gray
    CreateTime: 2018 / 03 / 15
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import styled from 'styled-components';
import Recaptcha from "react-grecaptcha";
import MediaQuery from 'react-responsive';
import GLOBAL from '../../../nonnmwebglobal';
import { GlobalStyle, Colors, Button } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
import TimeFormat from "components/commons/TimeFormat";
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/
const HeaderHeight = 50;
const Header = styled.div`
    display: flex;
    position: fixed;
    top: ${GlobalStyle.TopbarHeight}px;
    left: 0;
    width: 100%;
    height: ${HeaderHeight}px;
    background-color: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #DDDDDD;
    z-index: 1;
`;
const NavItem = styled.div`
    display: inline-block;
    width: 50%;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: ${(props) => props.active ? Colors.Orange : Colors.Dark5};
    text-align: center;
    cursor: pointer;
`;
const SubContent = styled.div`
    padding: ${HeaderHeight}px 0 0 0;
`;
const DetailContent = styled.div`
    background-color: #fff;
`;
const MainInfoContent = styled.div`
    padding: 16px;
`;
const MainTitle = styled.div`
    line-height: 21px;
    font-size: 15px;
    color: ${Colors.Dark3};
`;
const MainDescription = styled.div`
    line-height: 17px;
    margin: 4px 0 0 0;
    font-size: 12px;
    color: ${Colors.Dark5};
`;
const InfoContent = styled.div`
    padding: 16px;
    box-shadow: inset 0 -1px 0 0 #EEEEEE;
`;
const InfoTitle = styled.div`
    line-height: 18px;
    margin: 0 0 4px 0;
    font-size: 13px;
    color: #777777;
    font-weight: 600;
`;
const InfoText = styled.div`
    line-height: 20px;
    font-size: 13px;
    color: #777777;
`;
const PaymentList = styled.div`
    display: flex;
`;
const PaymentRightText = styled.div`
    margin: 0 0 0 auto;
`;
const PriceLabel = styled.span`
    font-size: 12px;
    color: ${Colors.Orange};
`;
const PriceNumber = styled.span`
    font-size: 15px;
    color: ${Colors.Orange};
    font-weight: 600;
`;
const ColorText = styled.span`
    color: ${(props) => props.color ? props.color : 'inherit'};
`;
const GradeTitle = styled.div`
    padding: 0 0 16px 0;
    border-bottom: 1px solid #DDD;
    font-size: 20px;
    color: #333333;
`;
const SelectTitle = styled.div`
    margin: 16px 0 0 0;
    font-size: 12px;
    color: #232423;
`;
const SelectWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 44px;
    margin: 8px 0 0 0;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    background-color: #FAFAFA;
`;
const Select = styled.select`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 8px;
    border: none;
    background-color: #FAFAFA;
    font-size: 15px;
    color: #222328;
`;
const Textarea = styled.textarea`
    width: 100%;
    height: 80px;
    margin: 8px 0 0 0;
    padding: 12px;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    background-color: #FAFAFA;
    font-size: 15px;
    color: #222328;
    overflow: auto;

    ${(props) => {

        if(!props.error) {
            return ``;
        }

        return `
            border: 1px solid ${Colors.Red};
            color: ${Colors.Red};
        `;
    }}
`;
const SubmitButton = styled(Button.Orange)`
    position: relative;
    width: 100%;
    height: 44px;
    line-height: 44px;
    margin: 32px 0 0 0;
    font-size: 16px;
`;
const SubmitLoading = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    left: 16px;
    height: 100%;
    align-items: center;
    justify-content: center; 
`;
const CommentWrapper = styled.div`
    margin: 0 0 10px 0;
    padding: 16px;
    background-color: #FFFFFF;
`;
const CommentTitle = styled.div`
    font-size: 12px;
    color: #232423;
`;
const CommentTextarea = styled.textarea`
    width: 100%;
    height: 120px;
    margin: 8px 0 0 0;
    padding: 12px;
    border: 1px solid ${props => props.error ? Colors.Red : '#DDDDDD'};
    border-radius: 2px;
    background-color: #FAFAFA;
    font-size: 15px;
    color: ${props => props.error ? Colors.Red : '#222328'};;
`;
const GoogleCertified = styled.div`
    margin: 24px 0 0 0;
`;
const CommentList = styled.div`
    padding: 0 16px;
    background-color: #FFFFFF;  
`;
const CommentItem = styled.div`
    padding: 10px 0 18px 0;   
    border-top: ${props => props.index === 0 ? 'none' : '1px solid #DDDDDD'};
`;
const CommentInfo = styled.div`
    display: flex;
    margin: 0 0 6px 0;
    font-size: 11px;
    color: #757474;
    justify-content: space-between;
`;
const CommentTime = styled.span`
    font-size: 11px;
    color: #B0ADAD;
`;
const CommentText = styled.div`
    font-size: 12px;
    color: #4A4A4A;
`;
const ErrorContainer = styled.div`
    width: 100%;
    padding: 30px 0;
    text-align: center;
`;
const ErrorContainerTitle = styled.div`
    padding: 0 20px;
    color: #4A4A4A;
    font-size: 14px;
`;

/*--------------------------
    Variable
--------------------------*/
const LAYOUT_TYPE = {
    DETAIL: 'detail',
    COMMENT: 'comment',
};
const REMARKTEMP = {
    1: '推薦動作迅速的賣家',
    2: '信用良好!',
    3: '效率差,不推!',
};

/*--------------------------
    Main Component
--------------------------*/
class MemberVitemOrderBuyView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            id: '',
            layout: '',
            grade: 1,
            remarkkey: 1,
            remark: REMARKTEMP[1],
            orderstatus: GLOBAL.C2C_PURCHASE_ORDERSTATUS.WAITFORBUYER,
            comment: '',
            recaptchaResponse: '',

            isLoading: false,
            isError: false,
            isLoaded: false,
            isUpdateOrderStatus: false,
            isRemarkEmpty: false,
            isAddComment: false,
            isOrderLogsLoading: false,
            isOrderLogsEmpty: false,
            isOrderLogsNoMore: false,
            isOrderLogsError: false,
        };

        this.getPurchaseOrderLogs = this.getPurchaseOrderLogs.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onRemarkkeyChange = this.onRemarkkeyChange.bind(this);
        this.onOrderstatusChange = this.onOrderstatusChange.bind(this);
        this.updPurchaseOrderStatus = this.updPurchaseOrderStatus.bind(this);
        this.grecaptchaCallback = this.grecaptchaCallback.bind(this);
        this.grecaptchaExpiredCallback = this.grecaptchaExpiredCallback.bind(this);
        this.addComment = this.addComment.bind(this);
        this.renderDetailContent = this.renderDetailContent.bind(this);
        this.renderUpdateStatusContent = this.renderUpdateStatusContent.bind(this);
        this.renderCommentContent = this.renderCommentContent.bind(this);
        this.renderCommentList = this.renderCommentList.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props, true);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        this.init(this.props);
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props, isInit) {

        const current = CupoyRouter.getCurrent();
        const {
            params,
            querys,
        } = current;

        const id = params.id;
        var layout = '';

        if(querys) {
            layout = querys.layout;
        }

        switch(layout) {
            case LAYOUT_TYPE.DETAIL:
            case LAYOUT_TYPE.COMMENT:
                if(this.state.layout !== layout) {
                    this.setState({
                        layout: layout,
                    });
                }
                break;
            default:
                this.changeLayout(id, LAYOUT_TYPE.DETAIL);
                return;
        }

        if(this.state.isLoaded) {
            return;
        }

        if(!props.haslogin) {
            this.setState({
                id: id,
            });
        } else {
            this.initOrder(id);
        }
    }

    // ------------------------------
    // initOrder
    // ------------------------------
    initOrder(id) {

        if(this.state.isLoading || this.state.isError) {
            return;
        }

        this.setState({
            id: id,
            isLoading: true,
        }, () => {
            this.props.vitemOrderActions.getPurchaseOrderInfo({
                itemid: id,
            }).then((result) => {

                this.setState({
                    isLoading: false,
                    isLoaded: true,
                });

                this.getPurchaseOrderLogs();
            }, (error) => {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            });
        })
    }

    // ------------------------------
    // 取得留言
    // ------------------------------
    getPurchaseOrderLogs() {

        const {
            id,
            isOrderLogsLoading,
            isOrderLogsEmpty,
            isOrderLogsNoMore,
            isOrderLogsError,
        } = this.state;

        if (isOrderLogsLoading || isOrderLogsEmpty || isOrderLogsNoMore || isOrderLogsError) {
            return;
        }

        const {
            purchaseOrderMap,
        } = this.props;

        const purchaseOrder = purchaseOrderMap[id];

        if(!purchaseOrder) {
            return;
        }

        var start = 0;

        if(Array.isArray(purchaseOrder.logids)) {
            start = purchaseOrder.logids.length;
        }

        this.setState({
            isOrderLogsLoading: true,
        }, () => {

            this.props.vitemOrderActions.getPurchaseOrderLogs({
                itemid: id,
                start: start,
            }).then((result) => {

                const {
                    purchaseOrderMap,
                } = this.props;

                const purchaseOrder = purchaseOrderMap[id];
                const logids = purchaseOrder.logids;

                var newState = {
                    isOrderLogsLoading: false,
                };

                if (!Array.isArray(logids) || logids.length === 0) {
                    newState.isOrderLogsEmpty = true;
                } else if (start === newState.start) {
                    newState.isOrderLogsNoMore = true;
                }

                this.setState(newState);
            }, (error) => {
                this.setState({
                    isOrderLogsLoading: false,
                    isOrderLogsError: true,
                });
            });
        });
    }

    // ------------------------------
    // [private] 切換顯示類型
    // ------------------------------
    changeLayout(id, layout) {

        const params = {
            id: id,
        };

        const querys = {
            layout: layout,
        };

        this.props.webActions.cupoyStateReplace(CupoyRouter.STATES.MEMBER_VITEM_ORDER_BUY, params, querys);
    }

    // ------------------------------
    // Input value change
    // ------------------------------
    onInputChange(event, key) {

        this.setState({
            [key]: event.target.value,
        })
    }

    // ------------------------------
    // onRemarkkeyChange
    // ------------------------------
    onRemarkkeyChange(event) {

        const remarkkey = event.target.value;
        const remark = REMARKTEMP[remarkkey] || '';

        this.setState({
            remarkkey: Number(remarkkey),
            remark: remark,
        })
    }

    // ------------------------------
    // onOrderstatusChange
    // ------------------------------
    onOrderstatusChange(event) {

        const orderstatus = event.target.value;

        this.setState({
            orderstatus: Number(orderstatus),
        })
    }

    // ------------------------------
    // updPurchaseOrderStatus
    // ------------------------------
    updPurchaseOrderStatus() {
        
        const {
            id,
            isUpdateOrderStatus,
            grade,
            remark,
            orderstatus,
        } = this.state;

        const {
            purchaseOrderMap,
        } = this.props;

        const purchaseOrder = purchaseOrderMap[id];

        if(isUpdateOrderStatus || !purchaseOrder) {
            return;
        }

        const {
            gamename,
            servername,
        } = purchaseOrder;
        
        if(orderstatus === GLOBAL.C2C_PURCHASE_ORDERSTATUS.WAITFORBUYER && (!remark || !remark.trim())) {
            this.setState({
                isRemarkEmpty: true,
            })
            return;
        }

        this.setState({
            isUpdateOrderStatus: true,
            isRemarkEmpty: false,
        }, () => {
            this.props.vitemOrderActions.updPurchaseOrderStatus({
                itemid: id,
                orderstatus: orderstatus,
                gamename: gamename,
                servername: servername,
                grade: grade,
                gradecontent: remark,
            }).then((result) => {

                this.setState({
                    isUpdateOrderStatus: false
                });

                // 更新資料
                this.props.vitemOrderActions.getPurchaseOrderInfo({
                    itemid: id,
                })

                this.props.webActions.openAlertDialog({
                    title: '訂單已更新',
                    closeName: '關閉',
                });

            }, (error) => {

                var errorMsg = '發生無法預期的錯誤';
                var errorDescription = '';

                if(error && error.code) {
                    errorMsg = error.message;
                }

                this.props.webActions.openAlertDialog({
                    title: errorMsg,
                    description: errorDescription,
                });

                this.setState({
                    isUpdateOrderStatus: false,
                });
            });
        })
    }

    // ------------------------------
    // google callback
    // ------------------------------
    grecaptchaCallback(response) {

        this.setState({
            recaptchaResponse: response,
        });
    }

    // ------------------------------
    // google expiredCallback
    // ------------------------------
    grecaptchaExpiredCallback() {

        this.props.webActions.openAlertDialog({
            title: '安全認證已過期',
        });
    }

    // ------------------------------
    // addComment
    // ------------------------------
    addComment() {

        const {
            id,
            isAddComment,
            recaptchaResponse,
            comment,
        } = this.state;

        if(isAddComment || !comment || (!GLOBAL.isAppFile && !recaptchaResponse)) {
            return;
        }

        this.setState({
            isAddComment: true,
        }, () => {
            this.props.vitemOrderActions.addPurchaseOrderLog({
                itemid: id,
                txtcontent: comment,
            }).then(() => {
                this.setState({
                    isAddComment: false,
                    isOrderLogsEmpty: false,
                    isOrderLogsNoMore: false,
                    recaptchaResponse: '',
                    comment: '',
                }, () => {
                    !GLOBAL.isAppFile && window.grecaptcha.reset();
                });
            }, () => {

                this.setState({
                    isAddComment: false,
                });
            });
        });
    }

    // ------------------------------
    // renderPurchaseOrderStatus
    // ------------------------------
    renderPurchaseOrderStatus(purchaseOrder) {

        const int_orderstatus = Number(purchaseOrder.orderstatus);
        const text = GLOBAL.C2C_PURCHASE_ORDERSTATUS_STRING[int_orderstatus];
        var color = undefined;

        switch(int_orderstatus) {
            case GLOBAL.C2C_PURCHASE_ORDERSTATUS.BUYORDER:
                color = Colors.Red;
                break;
            case GLOBAL.C2C_PURCHASE_ORDERSTATUS.WAITFORBUYER:
            case GLOBAL.C2C_PURCHASE_ORDERSTATUS.ORDERFINISH_9199:
            case GLOBAL.C2C_PURCHASE_ORDERSTATUS.ORDERFINISH:
            case GLOBAL.C2C_PURCHASE_ORDERSTATUS.ORDERREVENUE:
                color = Colors.Green;
                break;
            default:
                break;
        }

        return (<ColorText color={color}>{text}</ColorText>);
    }

    // ------------------------------
    // renderDetailContent
    // ------------------------------
    renderDetailContent() {

        const {
            id,
        } = this.state;

        const {
            purchaseOrderMap,
        } = this.props;

        const purchaseOrder = purchaseOrderMap[id];

        if(!purchaseOrder) {
            return null;
        }

        return (
            <DetailContent>
                <MainInfoContent>
                    <MainTitle>
                        {purchaseOrder.gamename}
                    </MainTitle>
                    <MainDescription>
                        出售時間: <TimeFormat format="simpleFullTime" time={purchaseOrder.buytime} />
                    </MainDescription>
                    <MainDescription>
                        訂單編號: {purchaseOrder.itemid}
                    </MainDescription>
                    <MainDescription>
                        訂單狀態: {this.renderPurchaseOrderStatus(purchaseOrder)}
                    </MainDescription>
                </MainInfoContent>
                <InfoContent>
                    <InfoTitle>訂單資訊</InfoTitle>
                    <InfoText>
                        買家: {purchaseOrder.vprovnickname}<br/>
                        買家電話: {purchaseOrder.buyerphone}<br/>
                        比例(台幣：虛幣): {purchaseOrder.ntdollar}:{purchaseOrder.vitemdollar}<br/>
                        <br/>
                        <ColorText color={Colors.Red}>交易密碼: {purchaseOrder.orderpwd}</ColorText> (與供應商於遊戲內交易時，雙方請進行交易密碼確認，以保障交易安全！)<br/>
                        <br/>
                        遊戲伺服器: {purchaseOrder.servername}<br/>
                        伺服器內角色: {purchaseOrder.serverrole}<br/>
                        交易時間: {purchaseOrder.ordertime}<br/>
                        交易過程詳述: {purchaseOrder.orderprocess}<br/>
                        銀行名稱 / 分行名稱: {purchaseOrder.bank} / {purchaseOrder.subbank}<br/>
                        入款銀行代號 / 帳號: {purchaseOrder.bankcode} / {purchaseOrder.bankaccount}<br/>
                    </InfoText>
                </InfoContent>
                <InfoContent>
                    <InfoTitle>交易明細: </InfoTitle>
                    <InfoText>
                        <PaymentList>
                            訂單金額:
                            <PaymentRightText>
                                <PriceLabel>總價: </PriceLabel><PriceNumber>{purchaseOrder.itemprice}元</PriceNumber>
                            </PaymentRightText>
                        </PaymentList>
                    </InfoText>
                </InfoContent>
                {this.renderUpdateStatusContent(purchaseOrder)}
            </DetailContent>
        );
    }
    
    // ------------------------------
    // renderUpdateStatusContent
    // ------------------------------
    renderUpdateStatusContent(purchaseOrder) {

        const {
            grade,
            remarkkey,
            remark,
            orderstatus,
            isUpdateOrderStatus,
            isRemarkEmpty,
        } = this.state;

        const int_orderstatus = Number(purchaseOrder.orderstatus);
        
        if(int_orderstatus !== GLOBAL.C2C_PURCHASE_ORDERSTATUS.BUYORDER) {
            return null;
        }

        return (
            <InfoContent>
                <GradeTitle>訂單操作</GradeTitle>
                <SelectTitle>訂單狀態</SelectTitle>
                <SelectWrapper>
                    <Select
                        value={orderstatus}
                        onChange={(event) => this.onOrderstatusChange(event)}
                    >
                        <option value={GLOBAL.C2C_PURCHASE_ORDERSTATUS.WAITFORBUYER}>商品出貨</option>
                        <option value={GLOBAL.C2C_PURCHASE_ORDERSTATUS.CANCEL_SALER}>交易取消</option>
                    </Select>
                </SelectWrapper>

                {orderstatus === GLOBAL.C2C_PURCHASE_ORDERSTATUS.WAITFORBUYER ?
                    <div>
                        <SelectTitle>供應商評價</SelectTitle>
                        <SelectWrapper>
                            <Select
                                value={grade}
                                onChange={(event) => this.onInputChange(event, 'grade')}
                            >
                                <option value="1">良好 (+1)</option>
                                <option value="0">普通 (+0)</option>
                                <option value="-1">待加強(-1)</option>
                            </Select>
                        </SelectWrapper>
                        <SelectTitle>評價留言</SelectTitle>
                        <SelectWrapper>
                            <Select
                                value={remarkkey}
                                onChange={(event) => this.onRemarkkeyChange(event)}
                            >
                                <option value={1}>{REMARKTEMP[1]}</option>
                                <option value={2}>{REMARKTEMP[2]}</option>
                                <option value={3}>{REMARKTEMP[3]}</option>
                                <option value={-1}>其他(自行輸入)</option>
                            </Select>
                        </SelectWrapper>
                        {remarkkey === -1 ?
                            <Textarea
                                type="text"
                                placeholder="請寫下您的評價留言"
                                value={remark}
                                onChange={(event) => this.onInputChange(event, 'remark')}
                                error={isRemarkEmpty}
                            />
                            :
                            null
                        }
                        <SubmitButton
                            onClick={() => this.updPurchaseOrderStatus()}
                            disable={isUpdateOrderStatus}
                        >
                            {isUpdateOrderStatus ?
                                <SubmitLoading>
                                    <GlobalStyle.Spin color="#fff"/>
                                </SubmitLoading>
                                :
                                null
                            }
                            確定商品出貨
                        </SubmitButton>
                    </div>
                    :
                    <SubmitButton
                        onClick={() => this.updPurchaseOrderStatus()}
                        disable={isUpdateOrderStatus}
                    >
                        {isUpdateOrderStatus ?
                            <SubmitLoading>
                                <GlobalStyle.Spin color="#fff"/>
                            </SubmitLoading>
                            :
                            null
                        }
                        確定交易取消
                    </SubmitButton>
                }
            </InfoContent>
        )
    }

    // ------------------------------
    // renderCommentContent
    // ------------------------------
    renderCommentContent() {

        const {
            id,
            comment,
            isCommentEmpty,
            recaptchaResponse,
            isAddComment,
        } = this.state;

        const {
            purchaseOrderMap,
        } = this.props;

        const purchaseOrder = purchaseOrderMap[id];

        if(!purchaseOrder) {
            return null;
        }

        const int_orderstatus = Number(purchaseOrder.orderstatus);
        var canComment = true;

        switch(int_orderstatus) {
            case GLOBAL.C2C_PURCHASE_ORDERSTATUS.CANCEL_BUYER:
            case GLOBAL.C2C_PURCHASE_ORDERSTATUS.CANCEL_SALER:
            case GLOBAL.C2C_PURCHASE_ORDERSTATUS.CANCEL_9199:
            case GLOBAL.C2C_PURCHASE_ORDERSTATUS.ORDERFINISH_9199:
            case GLOBAL.C2C_PURCHASE_ORDERSTATUS.ORDERFINISH:
            case GLOBAL.C2C_PURCHASE_ORDERSTATUS.ORDERREVENUE:
                canComment = false;
                break;
            default:
                break;
        }        

        return (
            <div>
                {canComment ?
                    <CommentWrapper>
                        <CommentTitle>我要留言</CommentTitle>
                        <CommentTextarea
                            value={comment}
                            onChange={(event)=> this.onInputChange(event, 'comment')}
                            error={isCommentEmpty}
                        />
                        {GLOBAL.isAppFile ?
                            null
                            :
                            <GoogleCertified>
                                <MediaQuery minWidth={0} maxWidth={360}>
                                    <Recaptcha
                                        sitekey={GLOBAL.grecaptcha_key}
                                        callback={this.grecaptchaCallback}
                                        expiredCallback={this.grecaptchaExpiredCallback}
                                        locale="zh-TW"
                                        className="g-recaptcha"
                                        style={{
                                            transform: 'scale(0.9)',
                                            WebkitTransform: 'scale(0.9)',
                                            transformOrigin: '0 0',
                                            WebkitTransformOrigin: '0 0',
                                        }}
                                    />

                                </MediaQuery>
                                <MediaQuery minWidth={361}>
                                    <Recaptcha
                                        sitekey={GLOBAL.grecaptcha_key}
                                        callback={this.grecaptchaCallback}
                                        expiredCallback={this.grecaptchaExpiredCallback}
                                        locale="zh-TW"
                                        className="g-recaptcha"
                                    />
                                </MediaQuery>
                            </GoogleCertified>
                        }
                        <SubmitButton
                            onClick={this.addComment}
                            disable={isAddComment || !comment || (!GLOBAL.isAppFile && !recaptchaResponse)}
                        >
                            {isAddComment ?
                                <SubmitLoading>
                                    <GlobalStyle.Spin color="#fff"/>
                                </SubmitLoading>
                                :
                                null
                            }
                            送出留言
                        </SubmitButton>
                    </CommentWrapper>
                    :
                    null
                }

                {this.renderCommentList()}
            </div>
        );
    }

    // ------------------------------
    // renderCommentList
    // ------------------------------
    renderCommentList(){
        
        const {
            id,
            isOrderLogsLoading,
            isOrderLogsEmpty,
            isOrderLogsError,
        } = this.state;

        const {
            purchaseOrderMap,
        } = this.props;

        const purchaseOrder = purchaseOrderMap[id];

        if(!purchaseOrder || !Array.isArray(purchaseOrder.logids)) {
            return null;
        }

        const logids = purchaseOrder.logids;

        return (
            <CommentList>
                {Array.isArray(logids) && logids.length > 0 ?
                    <CupoyListRepeat
                        listId={this.constructor.name}
                        rowRenderer={this.rowRenderer}
                        items={logids}
                        isLoading={isOrderLogsLoading}
                        useWindowScroll={true}
                        restoreWindowScroll={true}
                        loadMoreRows={() => this.getPurchaseOrderLogs()}
                    />
                    :
                    null
                }

                {isOrderLogsLoading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }

                {isOrderLogsError ?
                    <ErrorContainer>
                        <ErrorContainerTitle>
                            無法取得留言，請重新整理後再嘗試。
                        </ErrorContainerTitle>
                    </ErrorContainer>
                    :
                    null
                }

                {isOrderLogsEmpty ?
                    <ErrorContainer>
                        <ErrorContainerTitle>
                            無留言紀錄。
                        </ErrorContainerTitle>
                    </ErrorContainer>
                    :
                    null
                }
            </CommentList>
        )
    }

    // ------------------------------
    // rowRenderer
    // ------------------------------
    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it),
        parent,
        measure,
        columnIndex,
        updateRowSize,
        measureCache,
        listWidth,
    }, width, listHeight) {

        const {
            id,
        } = this.state;

        const {
            purchaseOrderMap,
        } = this.props;

        const purchaseOrder = purchaseOrderMap[id];

        if(!purchaseOrder || !purchaseOrder.logmap || !Array.isArray(purchaseOrder.logids)) {
            return null;
        }

        const logid = purchaseOrder.logids[index];
        const log = purchaseOrder.logmap[logid]

        return (
            <div
                key={index}
                style={style}
            >
                <CommentItem index={index}>
                    <CommentInfo>
                        留言者：{log.salerid ? 'NO.' + log.salerid : log.buyerid}                        
                        <CommentTime>
                            <TimeFormat
                                format="timeStringDash"
                                time={log.createtime}
                            />
                        </CommentTime>
                    </CommentInfo>
                    <CommentText>{log.txtcontent}</CommentText>
                </CommentItem>
            </div>
        )
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            id,
            layout,
            isLoading,
            isError,
        } = this.state;

        var renderSubContent;

        switch(layout) {
            case LAYOUT_TYPE.DETAIL:
                renderSubContent = this.renderDetailContent;
                break;
            case LAYOUT_TYPE.COMMENT:
                renderSubContent = this.renderCommentContent;
                break;
            default:
                renderSubContent = this.renderDetailContent;
                break;
        }

        return (
            <div>
                <Header>
                    <CupoySrefView
                        statename={CupoyRouter.STATES.MEMBER_VITEM_ORDER_BUY}
                        stylecomponent={NavItem}
                        active={layout === LAYOUT_TYPE.DETAIL}
                        params={{
                            id: id,
                        }}
                        querys={{
                            layout: LAYOUT_TYPE.DETAIL,
                        }}
                        replace
                    >
                        訂單詳情
                    </CupoySrefView>
                    <CupoySrefView
                        statename={CupoyRouter.STATES.MEMBER_VITEM_ORDER_BUY}
                        stylecomponent={NavItem}
                        active={layout === LAYOUT_TYPE.COMMENT}
                        params={{
                            id: id,
                        }}
                        querys={{
                            layout: LAYOUT_TYPE.COMMENT,
                        }}
                        replace
                    >
                        留言
                    </CupoySrefView>
                </Header>
                <SubContent>

                    {!isLoading && !isError ?
                        renderSubContent()
                        :
                        null
                    }

                    {isLoading ?
                        <GlobalStyle.LoadingContainer>
                            <GlobalStyle.Spin />
                        </GlobalStyle.LoadingContainer>
                        :
                        null
                    }

                    {isError ?
                        <GlobalStyle.ErrorContainer>
                            <GlobalStyle.ErrorContainerTitle>
                                發生錯誤
                            </GlobalStyle.ErrorContainerTitle>
                            <GlobalStyle.ErrorContainerDesription>
                                無法取得訂單資訊，請重新整理後再嘗試。
                            </GlobalStyle.ErrorContainerDesription>
                        </GlobalStyle.ErrorContainer>
                        :
                        null
                    }
                </SubContent>
            </div>
        )
    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return <NeedLoginBlock />;
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
                backgroundColor='#F5F5F5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER_VITEM_ORDER_LIST_BUY}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            虛寶收購
                        </CupoySrefView>
                    }
                    middleComponent="訂單詳情"
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        purchaseOrderMap: state.vitemorder.purchaseOrderMap,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        vitemOrderActions: bindActionCreators(actionCreators.vitemOrderActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberVitemOrderBuyView);