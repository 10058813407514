/*=====================================
    MemberComplaintLogs

    Author: Gray
    CreateTime: 2018 / 03 / 13
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import GLOBAL from 'nonnmwebglobal';
import styled from 'styled-components';
import { GlobalStyle, Colors, MemberStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
import TimeFormat from "components/commons/TimeFormat";
import convetDBResultSetToArray from "actions/util/convetDBResultSetToArray";
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
    padding: 0 0 10px 0;
`;
const ColorText = styled.span`
    color: ${(props) => props.color ? props.color : 'inherit'};
`;
const NewTag = styled(MemberStyle.LogsTag)`
    right: ${({ hadRight }) => hadRight ? '100px' : '40px'} !important;
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberComplaintLogsView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            csCaseList: [],
            start: 0,
            isLoading: false,
            isError: false,
            isEmpty: false,
            isNoMore: false,
        };

        this.listCustomerCases = this.listCustomerCases.bind(this);
        this.renderCaseClass = this.renderCaseClass.bind(this);
        this.renderCaseTag = this.renderCaseTag.bind(this);
        this.renderCaseStatus = this.renderCaseStatus.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props, true);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        if(prevProps.haslogin !== this.props.haslogin) {
            this.init(this.props);
        }
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props, isInit) {

        const {
            haslogin,
            csClassMap,
        } = this.props;

        const {
            csCaseList,
        } = this.state;

        if(haslogin && (csCaseList.length === 0)) {
            this.listCustomerCases();
        }

        if(isInit && !csClassMap[0]) {
            this.props.memberActions.listCustomerCaseClasses();
        }
    }
    
    // ------------------------------
    // 取得客訴單列表
    // ------------------------------
    listCustomerCases() {

        const {
            csCaseList,
            start,
            isLoading,
            isEmpty,
            isNoMore,
            isError,
        } = this.state;

        if(isLoading || isEmpty || isNoMore || isError) {
            return;
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.memberActions.listCustomerCases({
                start: start,
            }).then((result) => {

                const _result = convetDBResultSetToArray(result) || [];

                let newState = {
                    csCaseList: [...csCaseList, ..._result],
                    isLoading: false,
                    start: start + _result.length,
                };

                if((_result.length === 0) && (csCaseList.length === 0)) {
                    newState.isEmpty = true;
                } else if((_result.length === 0) && (csCaseList.length > 0)) {
                    newState.isNoMore = true;
                }

                this.setState(newState);
            }, (error) => {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            })
        })
    }

    // ------------------------------
    // renderCaseClass
    // ------------------------------
    renderCaseClass(csCase) {

        const {
            csClassMap,
        } = this.props;

        const csclassid = csCase.csclassid;

        if(!csClassMap[0]) {
            return '';
        }

        var csclass = csClassMap[csclassid];
        var result = '';

        if(csclass) {
            result = csclass.csclassname;

            if(csclass.bindclassid > 0) {
                var parent_csclass =  csClassMap[csclass.bindclassid];

                if(parent_csclass) {
                    result = parent_csclass.csclassname + ' > ' + result;
                }
            }
        }

        return result;
    }

    // ------------------------------
    // renderCaseTag
    // ------------------------------
    renderCaseTag(csCase) {

        const int_casestatus = Number(csCase.casestatus);
        const text = GLOBAL.CASESTATUS_STRING[int_casestatus];
        const isNew = csCase.readtime < 0;

        switch(int_casestatus) {
            case GLOBAL.CASESTATUS.NOTPROCESSED:
                return (
                    <React.Fragment>
                        {isNew && <NewTag hadRight color={Colors.Red}>New</NewTag>}
                        <MemberStyle.LogsTag color={Colors.Red}>{text}</MemberStyle.LogsTag>
                    </React.Fragment>
                );
            default:
                return isNew && <NewTag color={Colors.Red}>New</NewTag>;
        }
    }

    // ------------------------------
    // 取得客訴狀態
    // ------------------------------
    renderCaseStatus(csCase) {

        const int_casestatus = Number(csCase.casestatus);
        const text = GLOBAL.CASESTATUS_STRING[int_casestatus];
        var color = undefined;

        switch(int_casestatus) {
            case GLOBAL.CASESTATUS.NOTPROCESSED:
                color = Colors.Red;
                break;
            default:
                color = Colors.Green;
                break;
        }

        return (<ColorText color={color}>{text}</ColorText>);
    }
    

    // ------------------------------
    // rowRenderer
    // ------------------------------
    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it),
        parent,
        measure,
        columnIndex,
        updateRowSize,
        measureCache,
        listWidth,
    }, width, listHeight){

        const {
            csCaseList,
        } = this.state;

        const csCase = csCaseList[index];

        if(!csCase) {
            return null;
        }

        const {
            caseid,
            createtime,
            comment,
        } = csCase;

        return (
            <CupoySrefView
                key={index}
                style={style}
                statename={CupoyRouter.STATES.MEMBER_COMPLAINT}
                params={{
                    id: caseid,
                    createtime,
                }}
            >
                <MemberStyle.LogsItem hasArrow>
                    <MemberStyle.LogsTitle>
                        客訴編號: {caseid}
                    </MemberStyle.LogsTitle>
                    <MemberStyle.LogsDescription>
                        更新時間: <TimeFormat format="simpleFullTime" time={createtime}/>
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription>
                        處理狀態: {this.renderCaseStatus(csCase)}
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription>
                        問題種類: {this.renderCaseClass(csCase)}
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription isLast>
                        問題描述: {comment}
                    </MemberStyle.LogsDescription>
                    {this.renderCaseTag(csCase)}
                    <MemberStyle.LogsRightArrow><i className="icon-chevron-right"/></MemberStyle.LogsRightArrow>
                </MemberStyle.LogsItem>
            </CupoySrefView>
        )
    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return <NeedLoginBlock />;
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            isLoading,
            isError,
            isEmpty,
            csCaseList,
        } = this.state;

        return (
            <Content>
                {Array.isArray(csCaseList) && csCaseList.length > 0 ?
                    <CupoyListRepeat
                        listId={this.constructor.name}
                        rowRenderer={this.rowRenderer}
                        items={csCaseList}
                        isLoading={isLoading}
                        useWindowScroll
                        restoreWindowScroll
                        loadMoreRows={this.listCustomerCases}
                    />
                    :
                    null
                }

                {isLoading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }

                {isError ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            發生錯誤
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無法取得客訴記錄，請重新整理後再嘗試。
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }

                {isEmpty ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            無任何客訴記錄
                        </GlobalStyle.ErrorContainerTitle>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }
            </Content>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        let content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar 
                hasFixedTabbar={false}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER_CONTACT_SERVICE}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            會員客訴
                        </CupoySrefView>
                    }
                    middleComponent="客訴記錄"
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        csClassMap: state.member.csClassMap,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberComplaintLogsView);