import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import CupoyRouter from "components/commons/router/CupoyRouter";

/**
 * ReactRouter NavLink HOC
 * @author Ac
 * @time 2020 / 05 / 08
 */
const CupoySrefView = props => {

    const {
        statename,
        params,
        querys,
        children,
        locationState,
        stylecomponent,
        ...otherProps
    } = props;

    const Component = useRef(stylecomponent ? stylecomponent : NavLink).current;
    const url = CupoyRouter.getPathname(statename, params);
    const search = CupoyRouter.getQueryUrl(querys);

    let to = {
        pathname: url,
        search,
        state: locationState,
    };

    // 全空的時候，要用空字串，才會正確連到root
    if(!url && !search && !locationState) {
        to = '';
    }

    return (
        <Component
            {...otherProps}
            as={stylecomponent ? NavLink : null}
            to={to}
        >
            {children}
        </Component>
    );
};

CupoySrefView.propTypes = {
    statename: PropTypes.string.isRequired,
    params: PropTypes.object,
    querys: PropTypes.object,
    children: PropTypes.node,
    locationState: PropTypes.object,
    stylecomponent: PropTypes.any,
};

/*--------------------------
    export
--------------------------*/
export default React.memo(CupoySrefView);