// @flow
import { push, replace } from 'react-router-redux';
import webActionTypes from "actions/types/webActionTypes";
import CupoyRouter from "components/commons/router/CupoyRouter";
import GLOBAL from 'nonnmwebglobal';
import WebViewBridgeUtil from '../../components/commons/WebViewBridgeUtil';
import customAxios from 'api/customAxios';

/*==========================
    private method
==========================*/

/*==========================
    public method
==========================*/

// ------------------------------
// Cupoy State 轉址
// ------------------------------
function cupoyStateGo(statename, params, querys, locationState) {

    return function(dispatch, getState) {

        if(!CupoyRouter.hasState(statename)) {
            throw new Error('CupoyState has not state: ' + statename);
        }

        const url = CupoyRouter.getPathname(statename, params);
        const search = CupoyRouter.getQueryUrl(querys);

        let location =  {
            pathname: url,
            search: search,
            state: locationState,
        };

        return dispatch(push(location));
    };
}

// ------------------------------
// Cupoy State 轉址（不會記錄上下頁）
// ------------------------------
function cupoyStateReplace(statename, params, querys, locationState) {

    return function(dispatch, getState) {

        if(!CupoyRouter.hasState(statename)) {
            throw new Error('CupoyState has not state: ' + statename);
        }

        const url = CupoyRouter.getPathname(statename, params);
        const search = CupoyRouter.getQueryUrl(querys);

        let location =  {
            pathname: url,
            search: search,
            state: locationState,
        };

        return dispatch(replace(location));
    };
}

// ------------------------------
// Cupoy State 轉至預設頁
// ------------------------------
function cupoyStateGoOtherwise(isNoReplace) {

    return function(dispatch, getState) {

        const otherwise = CupoyRouter.getOtherwise();

        if(!otherwise) {
            return;
        }
        if(isNoReplace) {
            return dispatch(push(otherwise));
        } else {
            return dispatch(replace(otherwise));
        }
    };
}

// ------------------------------
// 需要登入
// ------------------------------
function needLogin() {
    return function(dispatch, getState) {
        return dispatch(openConfirmDialog({
            title: '錯誤訊息',
            description : '您尚未登入，或是登入逾時，請前往登入。',
            confirmName: '前往登入',
            closeName: '關閉',
            confirmCallback: () => {
                dispatch(webActions.cupoyStateReplace(CupoyRouter.STATES.MEMBERLOGIN));
            },
            closeCallback: () => {

            }
        }));
    }
}


/*==========================
    reducer 呼叫
==========================*/

// ------------------------------
// 紀錄每個列表最後scroll到的位置(position)
// ------------------------------
function storeScrollPosition(key, scrollTop) {

    const payload = {
        key,
        scrollTop
    };

    return { type: webActionTypes.storeScrollPosition, payload };
}

// ------------------------------
// 紀錄每個列表最後scroll到的位置(index)
// ------------------------------
function storeRenderedIndex(key, event) {

    const payload = {
        key,
        event
    };

    return { type: webActionTypes.storeRenderedIndex, payload };
}

// ------------------------------
// 開啟確認Dialog
// ------------------------------

type openConfirmDialogPayload = {
    title: string,
    description?: string,
    isPromise?: boolean,
    confirmCallback?: Function,
    confirmPromise?: Promise,
    cancelCallback?: Function,
    closeCallback?: Function,
    confirmName: string,
    cancelName?: string,
}

function openConfirmDialog(payload: openConfirmDialogPayload): any {

    // const payload = {
    //     title: data.title,
    //     description: data.description,
    //     isPromise: data.isPromise,
    //     confirmCallback: data.confirmCallback,
    //     confirmPromise: data.confirmPromise,
    //     cancelCallback: data.cancelCallback,
    //     closeCallback: data.closeCallback,
    //     confirmName: data.confirmName,
    //     cancelName: data.cancelName,
    // };

    return { type: webActionTypes.openConfirmDialog, payload};
}

// ------------------------------
// 關閉確認Dialog
// ------------------------------
function closeConfirmDialog(payload) {
    return { type: webActionTypes.closeConfirmDialog, payload };
}

// ------------------------------
// 開啟提示視窗
// ------------------------------
function openAlertDialog(data) {

    const payload = {
        title: data.title,
        description: data.description,
        renderDescription: data.renderDescription,
        imageUrl: data.imageUrl,
        closeName: data.closeName,
        closeCallback: data.closeCallback,
    };
    return { type: webActionTypes.openAlertDialog, payload };
}

// ------------------------------
// 關閉提示視窗
// ------------------------------
function closeAlertDialog(payload) {
    return { type: webActionTypes.closeAlertDialog, payload };
}

// ------------------------------
// 信用卡付款
// ------------------------------
function payByCreditCard(payload) {

    let {
        MerchantID,
        MerchantTradeNo,
        MerchantTradeDate,
        PaymentType,
        TotalAmount,
        TradeDesc,
        ItemName,
        ReturnURL,
        ChoosePayment,
        CheckMacValue,
        ClientBackURL,
        NeedExtraPaidInfo,
    } = payload;

    return () => {

        const promise = new Promise(resolve => {

            if(GLOBAL.isAppFile) {
                const form = {
                    method: "POST",
                    action: GLOBAL.allPayCheckOutURL,
                    MerchantID: MerchantID,
                    MerchantTradeNo: MerchantTradeNo,
                    MerchantTradeDate: MerchantTradeDate,
                    PaymentType: PaymentType,
                    TotalAmount: TotalAmount,
                    TradeDesc: TradeDesc,
                    ItemName: ItemName,
                    ReturnURL: ReturnURL,
                    ChoosePayment: ChoosePayment,
                    CheckMacValue: CheckMacValue,
                    ClientBackURL: ClientBackURL,
                    NeedExtraPaidInfo: NeedExtraPaidInfo,
                }

                WebViewBridgeUtil.postPayByCreditCardMessage(form);

                resolve(true);
            } else {
                
                const form = document.createElement("form");

                form.method = "POST";
                form.action = GLOBAL.allPayCheckOutURL;  

                var input_01 = document.createElement("input"); 
                input_01.value= MerchantID;
                input_01.name= "MerchantID";
                form.appendChild(input_01);

                var input_02 = document.createElement("input"); 
                input_02.value= MerchantTradeNo;
                input_02.name= "MerchantTradeNo";
                form.appendChild(input_02);

                var input_03 = document.createElement("input"); 
                input_03.value= MerchantTradeDate;
                input_03.name= "MerchantTradeDate";
                form.appendChild(input_03);

                var input_04 = document.createElement("input"); 
                input_04.value= PaymentType;
                input_04.name= "PaymentType";
                form.appendChild(input_04);

                var input_05 = document.createElement("input"); 
                input_05.value= TotalAmount;
                input_05.name= "TotalAmount";
                form.appendChild(input_05);

                var input_06 = document.createElement("input"); 
                input_06.value= TradeDesc;
                input_06.name= "TradeDesc";
                form.appendChild(input_06);

                var input_07 = document.createElement("input"); 
                input_07.value= ItemName;
                input_07.name= "ItemName";
                form.appendChild(input_07);

                var input_08 = document.createElement("input"); 
                input_08.value= ReturnURL;
                input_08.name= "ReturnURL";
                form.appendChild(input_08);

                var input_09 = document.createElement("input"); 
                input_09.value= ChoosePayment;
                input_09.name= "ChoosePayment";
                form.appendChild(input_09);

                var input_10 = document.createElement("input"); 
                input_10.value= CheckMacValue;
                input_10.name= "CheckMacValue";
                form.appendChild(input_10);

                var input_11 = document.createElement("input"); 
                input_11.value= ClientBackURL;
                input_11.name= "ClientBackURL";
                form.appendChild(input_11);

                var input_12 = document.createElement("input"); 
                input_12.value= NeedExtraPaidInfo;
                input_12.name= "NeedExtraPaidInfo";
                form.appendChild(input_12);

                document.body.appendChild(form);
                form.submit();

                resolve(true);
            }
        });
        return promise;
    };
}
// ------------------------------
// 綠界ATM
// ------------------------------

function payByECPayATM(formInfo) {
    return () => {

        let _formInfo = formInfo;

        if (typeof _formInfo === 'string') {
            _formInfo = JSON.parse(formInfo);
        }

        const promise = new Promise(resolve => {

            if(GLOBAL.isAppFile) {

                WebViewBridgeUtil.postPayByECPayMessage(_formInfo);

                resolve(true);
            } else {

                const form = document.createElement('form');
                form.setAttribute('id', 'allPayAPIForm');
                form.setAttribute('action', _formInfo.aioCheckoutUrl);
                form.setAttribute('method', 'post');
            
                Object.keys(_formInfo).forEach(key => {

                    if(key !== 'aioCheckoutUrl') {
                        const input = document.createElement('input');
                        const value = _formInfo[key];
                        input.setAttribute('name', key);
                        input.setAttribute('value', value);
                        input.setAttribute('type', 'hidden');
                        form.appendChild(input);
                    }
                });
            
                document.body.appendChild(form);

                form.submit();

                resolve(true);
            }
        });
        return promise;
    };
}

// ------------------------------
// 開啟發票明細視窗
// ------------------------------
function openInvoiceDetailDialog(data) {

    const payload = {
        invoiceDetail : data.invoiceDetail,
        closeName: data.closeName,
        closeCallback: data.closeCallback,
    };
    return { type: webActionTypes.openInvoiceDetailDialog, payload };
}

// ------------------------------
// 關閉提示視窗
// ------------------------------
function closeInvoiceDetailDialog(payload) {
    return { type: webActionTypes.closeInvoiceDetailDialog, payload };
}

function checkWebStateSuccess(payload) {
    return {
        type: webActionTypes.checkWebStateSuccess,
        payload,
    }
}

function checkWebState() {
    return dispatch => {

        const url = `${GLOBAL.URL_PREFIX}/WebSationSwitchAction.do?op=listAllWebSationSwitch`;

        const promise = new Promise((resolve, reject) => {

            customAxios(url).then(response => {
                if (response.status === 503) {
                    // AWS server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                    reject();
                    return;
                }

                response.json().then(result => {
                    if (!result || result.error) {
                        reject(result && result.error);
                        return;
                    }
                    dispatch(checkWebStateSuccess({ result }));
                    resolve(result);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                reject(error);
            });
        });

        return promise;
    };
}

function checkEinvoiceMobileCarrier(payload) {
    return () => {

        const {
            carrierid,
            cancelToken,
        } = payload;

        const url = `${GLOBAL.URL_PREFIX}/MemberEInvoiceActoin.do?op=checkEinvoiceMobileCarrier&carrierid=${window.encodeURIComponent(carrierid)}`;

        const promise = new Promise((resolve, reject) => {

            customAxios(url, {
                cancelToken,
            }).then(response => {
                if (response.status === 503) {
                    // AWS server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                    reject();
                    return;
                }

                response.json().then(result => {
                    if (!result || result.error) {
                        reject(result && result.error);
                        return;
                    }
                    resolve(result);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                // abort
                if (error.name === 'AbortError') {
                    return;
                }
                // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                reject(error);
            });
        });

        return promise;
    };
}

function openBuyBonusPdtDialog(productid, jsdproductid) {
    return {
        type: webActionTypes.openBuyBonusPdtDialog,
        payload: {
            productid,
            jsdproductid,
        },
    };
}

function closeBuyBonusPdtDialog() {
    return {
        type: webActionTypes.closeBuyBonusPdtDialog,
    };
}

function showSimpleAlertDialog(text) {
    return {
        type: webActionTypes.showSimpleAlertDialog,
        payload: {
            text,
        },
    };
}

function setPlatform(platform) {
    return {
        type: webActionTypes.setPlatform,
        payload: {
            platform,
        },
    };
}

const webActions = {
    /*==========================
        Method
    ==========================*/
    cupoyStateGo, // Cupoy State 轉址
    cupoyStateReplace, // Cupoy State 轉址（不會記錄上下頁）
    cupoyStateGoOtherwise, // Cupoy State 轉至預設頁
    payByCreditCard, // 信用卡付款
    payByECPayATM,  //綠界ATM付款
    needLogin, // 需要登入
    checkWebState, // 取得web各種狀態 (金流開啟狀態與mobile是否維修)
    checkEinvoiceMobileCarrier, // 檢查手機載具是否存在
    /*==========================
        reducer 呼叫
    ==========================*/
    storeScrollPosition, // 紀錄每個列表最後scroll到的位置(position)
    storeRenderedIndex, // 紀錄每個列表最後scroll到的位置(index)
    openConfirmDialog, // 開啟確認Dialog
    closeConfirmDialog, // 關閉確認Dialog
    openAlertDialog, // 開啟提示視窗
    closeAlertDialog, // 關閉提示視窗
    openInvoiceDetailDialog,  //開啟發票明細視窗
    closeInvoiceDetailDialog, //關閉發票明細視窗

    openBuyBonusPdtDialog, // 開啟 購買發財商品 dialog
    closeBuyBonusPdtDialog, // 關閉 購買發財商品 dialog

    showSimpleAlertDialog, // 開啟 Simple Alert Dialog

    setPlatform, // 設置開啟的平台
}

export default webActions;