/*=====================================
    RepairView

    Author: Ac
    CreateTime: 2018 / 12 / 14
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import GLOBAL from 'nonnmwebglobal';
import WebViewBridgeUtil from './commons/WebViewBridgeUtil';

/*--------------------------
    Styled
--------------------------*/
const Container = styled.div`
    width: fill-available;
    height: fill-available;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
`;
const MaintainImg = styled.img`
    max-width: 100%;
    width: 100%;
`;
const MaintainText = styled.p`
    margin: 0 auto;
    font-size: 14px;
    color: #09263C;
    padding: 0 30px;
    line-height: 18px;
    width: 100%;
    text-align: center;
`;
const ReloadText = styled.p`
    font-size: 14px;
    color: #6A6A6A;
    margin: 16px 0 0;
`;
const ReloadButton = styled.button`
    width: 120px;
    background-color: #F75C2F;
    color: #FFF;
    border-radius: 3px;
    border: 0;
    outline: 0;
    font-size: 16px;
    margin-top: 20px;
    height: 38px;
`;

/*--------------------------
    Main Component
--------------------------*/
const RepairView = () => {

    const [count, setCount] = useState(60);

    useEffect(
        () => {
            setInterval(() => {
                setCount(prev => {

                    const newCount = prev - 1;

                    if (newCount < 0) {
                        return 0;
                    }

                    return newCount;
                });
            }, 1000);
        },
        []
    );

    const reload = () => {
        GLOBAL.isAppFile
        ? WebViewBridgeUtil.postReloadAppMessage()
        : window.location.reload();
    };

    useEffect(() => {
        if (count === 0) {
            reload();
        }
    }, [count]);

    return (
        <Container id='maintain'>
            <MaintainImg
                src={`${GLOBAL.URL_PREFIX}/images/maintain.svg`}
                alt='maintain' />
            <MaintainText>
                客服電話：(02) 7731-2321 <br/>
                <span>(週一至週五 10:00~18:00)</span> <br/><br/>
                <span>
                    週六日請使用<br/>
                    <a
                        href='mailto:service@9199.com.tw'
                        rel='noopener noreferrer'
                        title='9199客服信箱'>
                        Mail至 service@9199.com.tw
                    </a>
                </span>
            </MaintainText>

            <ReloadText>
                {count}&nbsp;秒後自動重整..
            </ReloadText>

            <ReloadButton onClick={reload}>
                立即重整
            </ReloadButton>
        </Container>
    );
};

/*--------------------------
    Export
--------------------------*/
export default RepairView;