/**
|--------------------------------------------------
| 空狀態 | 錯誤狀態 Block
| @author Ac
| @time 2019 / 09 / 18
|--------------------------------------------------
*/
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    Button,
    Colors,
} from 'stylecomponents';

/**
|--------------------------------------------------
| Styled
|--------------------------------------------------
*/
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({ BGColor }) => BGColor};
    padding: ${({ padding }) => padding};
`;
const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: ${({ BGColor }) => BGColor};
    color: ${({ iconColor }) => iconColor};
    user-select: none;

    > i {
        font-size: ${({ iconSize }) => `${iconSize}px`};   
    }
`;
const Desc = styled.div`
    color: ${({ textColor }) => textColor};
    font-size: 20px;
    line-height: 26px;
    margin: 20px 0;
`;
export const ActionRedBtn = styled(Button.Red)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 140px;
    height: 40px;
    margin: 0;

    > i {
        font-size: 20px;
        margin-right: 10px;
    }
`;
/**
|--------------------------------------------------
| Main Component
|--------------------------------------------------
*/
const StatusBlock = props => {

    const {
        iconElement,
        textElement,
        btnElement,
        containerPadding,
        containerBGColor,
        iconContentBGColor,
        textColor,
        iconSize,
        iconColor,
    } = props;

    return (
        <Container
            padding={containerPadding}
            BGColor={containerBGColor}
        >

            <IconContainer
                BGColor={iconContentBGColor}
                iconSize={iconSize}
                iconColor={iconColor}
            >
                {iconElement}
            </IconContainer>

            <Desc textColor={textColor}>
                {textElement}
            </Desc>

            {btnElement}

        </Container>
    );
};

StatusBlock.propTypes = {
    iconElement: PropTypes.node.isRequired,
    textElement: PropTypes.node.isRequired,
    btnElement: PropTypes.node,
    containerPadding: PropTypes.string, // 容器padding
    containerBGColor: PropTypes.string, // 背景顏色
    iconContentBGColor: PropTypes.string, // Icon容器背景顏色
    iconSize: PropTypes.number, // icon大小
    iconColor: PropTypes.string, // icon顏色
    textColor: PropTypes.string, // 文字顏色
};

StatusBlock.defaultProps = {
    containerPadding: '60px 0',
    containerBGColor: '#fff',
    iconContentBGColor: '#F5F5F5',
    iconSize: 36,
    textColor: Colors.Dark3,
    iconColor: Colors.Dark4,
};

function areEqual(prevProps, nextProps) {
    return true;
}

export default React.memo(StatusBlock, areEqual);