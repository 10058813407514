/*=====================================
    About

    Author: Gray
    CreateTime: 2018 / 03 / 13
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import styled from 'styled-components';
import { GlobalStyle, Colors } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyImage from "components/commons/CupoyImage";
import CupoyLink from 'components/commons/CupoyLink';
import GLOBAL from 'nonnmwebglobal';

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
    padding: 16px;
`;
const Title = styled.h5`
    line-height: 1.65em;
    margin: 0 0 12px 0;
    font-size: 16px;
    color: ${Colors.Dark2};
    font-weight: 600;
`;
const Description = styled.p`
    line-height: 1.65em;
    margin: 0 0 16px 0;
    font-size: 12px;
    color: ${Colors.Dark2};
`;
const Link = styled.span`
    color: ${Colors.Blue};

    &:hover {
        color: ${Colors.Blue};
        text-decoration: underline;
    }
`;
const Image = styled.img`
    display: block;
    max-width: 100%;
    width: 100%;
    margin: auto;
`;
const CertificateImage = styled(Image)`
    margin: 20px auto;
`;

/*--------------------------
    Main Component
--------------------------*/
class AboutView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {

        };
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        this.init(this.props);
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props) {

    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            返回
                        </CupoySrefView>
                    }
                    middleComponent="關於9199"
                />
                <Content>
                    <CupoyImage
                        src={`${GLOBAL.URL_PREFIX}/images/socool.png`}
                        stylecomponent={Image}
                    />
                    <Title>公司簡介</Title>
                    <Description>
                        公司名稱：淞果數位股份有限公司<br/>
                        網站名稱：9199 交易久久 – 安全平台交易久久！<br/>
                        統一編號：25006966<br/>
                        {/* 聯絡電話：(02)8227-8256<br/>
                        傳真電話：(02)8227-8089<br/> */}
                        營業地址：235 新北市中和區中正路700號12樓 (遠東世紀廣場K棟)<br/>
                    </Description>
                    
                    <Title>經營理念</Title>
                    <Description>
                        線上遊戲產業蓬勃發展，宅經濟持續發燒，遊戲詐騙與交易糾紛事件頻傳，玩家對於虛寶交易的安全需求日益提升，因此淞果數位成立『9199交易久久』平台，提供玩家線上快速安全金流繳費服務，且為保障遊戲商品來源安全性，所販售之遊戲點數卡與遊戲相關商品，均經過本公司嚴格審查之合作廠商供貨，請玩家安心使用『9199交易久久』– 安全平台交易久久！
                    </Description>
                    <Title>營業項目</Title>
                    <Description>
                        001 F218010 資訊軟體零售業<br/>
                        002 F399040 無店面零售業<br/>
                        003 I301010 資訊軟體服務業<br/>
                        004 I301020 資料處理服務業<br/>
                        005 I301030 電子資訊供應服務業<br/>
                        006 I401010 一般廣告服務業<br/>
                        007 J399010 軟體出版業<br/>
                        008 ZZ99999 除許可業務外，得經營法令非禁止或限制之業務<br/>
                    </Description>
                    <Title>法律顧問</Title>
                    <Description>
                        <CupoyImage
                            src={`${GLOBAL.URL_PREFIX}/images/9199law.jpg`}
                            stylecomponent={CertificateImage}
                        />
                    </Description>
                    <Title>社會公益</Title>
                    <Description>
                        <CupoyLink href="http://lxy6882711.blogspot.com/" target="_blank">
                            <Link>蓮心園社會福利慈善事業基金會</Link>
                        </CupoyLink>
                        &nbsp;感謝狀（蓮心園謝字第99056號）
                        <CupoyImage
                            src={`${GLOBAL.URL_PREFIX}/images/certificate/99056.png`}
                            stylecomponent={CertificateImage}
                        />
                        <CupoyLink href="http://www.sunshine.org.tw/" target="_blank">
                            <Link>陽光社會福利基金會</Link>
                        </CupoyLink>
                        &nbsp;感謝狀
                        <CupoyImage
                            src={`${GLOBAL.URL_PREFIX}/images/certificate/sunshine.png`}
                            stylecomponent={CertificateImage}
                        />
                        <CupoyLink href="http://www.eden.org.tw/" target="_blank">
                            <Link>財團法人伊甸社會福利基金會</Link>
                        </CupoyLink>
                        &nbsp;感謝狀
                        <CupoyImage
                            src={`${GLOBAL.URL_PREFIX}/images/certificate/eden.png`}
                            stylecomponent={CertificateImage}
                        />
                        <CupoyLink href="http://www.ccf.org.tw/" target="_blank">
                            <Link>台灣兒童暨家庭扶助基金會</Link>
                        </CupoyLink>
                        &nbsp;感謝狀
                        <CupoyImage
                            src={`${GLOBAL.URL_PREFIX}/images/certificate/ccf.png`}
                            stylecomponent={CertificateImage}
                        />
                        <CupoyLink href="http://www.nncf.org/" target="_blank">
                            <Link>財團法人羅慧夫顱顏基金會</Link>
                        </CupoyLink>
                        &nbsp;感謝狀
                        <CupoyImage
                            src={`${GLOBAL.URL_PREFIX}/images/certificate/nncf.jpg`}
                            stylecomponent={CertificateImage}
                        />
                    </Description>

                    <Title>【國稅局績優營業人】</Title>
                    <Description>
                        <CupoyImage
                            src='images/certificate/ntb-prize-z.jpg'
                            stylecomponent={CertificateImage}
                        />
                        <CupoyImage
                            src='images/certificate/ntb-prize-c.jpg'
                            stylecomponent={CertificateImage}
                        />
                    </Description>
                </Content>
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Export
--------------------------*/
export default AboutView;